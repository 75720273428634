import type { FC } from 'react';

import { BatchKeys } from 'app-constants';
import { getServerPageGetGamesCategories } from 'gql/cms/queries/getGamesCategories.cms';
import { WithMainApolloClientContext } from 'services/apollo';
import { GetServerSidePageProps } from 'types';
import { GetGamesCategories } from 'types/gql.cms';
import FooterCategoriesLinksContent from './FooterCategoriesLinksContent';
import useFooterCategoriesLinks from './useFooterCategoriesLinks';

interface Props {
    categories?: GetGamesCategories['gamesCategories'];
}

const FooterCategoriesLinks: FC<Props> = ({
    categories: gamesCategories = [],
}) => {
    const { categories } = useFooterCategoriesLinks();

    const dataForRender = categories?.length ? categories : gamesCategories;

    if (!dataForRender?.length) {
        return null;
    }

    return <FooterCategoriesLinksContent categories={dataForRender} />;
};

export const getFooterGamesLinksSideProps = async (
    ctx: WithMainApolloClientContext
): Promise<GetServerSidePageProps> => {
    const gameCategoriesResponse = await getServerPageGetGamesCategories({
        context: { batchKey: BatchKeys.GetGamesCategories },
    })(ctx);

    return {
        props: {
            gsspData: {
                gamesCategories: gameCategoriesResponse,
            },
        },
    };
};

export default FooterCategoriesLinks;
