export const EXCLUDED_GQL_REQUEST_HEADERS = new Set([
    // NOTE: incorrect host fails CMS request: "failed, reason: self signed certificate"
    'host',
    'cache-control',
    'connection',
    'content-length',
    'accept',
    'keep-alive',
]);

export const REQUEST_ID_HEADER = 'x-request-id' as const;
export const IGNORE_REGEX = /(\.(.*)|\/maintenance)$/; // ignore files and maintenance request
