import type { Logger } from 'pino';

import { isSsr } from 'utils/isSsr';
import { LogErrorInput, LogErrorOutput } from './types';

const LOGGER_REQUEST_ID_HEADER = 'x-request-id';

export const nodeLogger: Logger =
    // eslint-disable-next-line no-nested-ternary
    isSsr()
        ? process.env.NODE_ENV === 'development'
            ? // eslint-disable-next-line @typescript-eslint/no-var-requires,global-require
              require('../../../next-logger.config').logger({
                  transport: {
                      target: 'pino-pretty',
                      options: {
                          colorize: true,
                          ignore: 'pid,time,hostname,variables,requestId,user_agent',
                      },
                  },
              })
            : // eslint-disable-next-line global-require,@typescript-eslint/no-var-requires
              require('../../../next-logger.config').logger({})
        : () => ({});

// Tree shaking required because Node.js API used inside block statement
export const logError = <T = unknown>({
    message,
    err,
    request,
    requestId,
}: LogErrorInput<T>): LogErrorOutput => {
    if (typeof window === 'undefined') {
        const headerRequestId = request?.headers?.[LOGGER_REQUEST_ID_HEADER];

        const user_agent = request?.headers?.['user-agent'];

        const resolvedRequestId = headerRequestId || requestId;

        const stringRequestId =
            typeof resolvedRequestId === 'string'
                ? resolvedRequestId
                : undefined;

        const resolvedError =
            typeof err === 'object' ? { ...err } : { error: err };

        if (process.env.NEXT_RUNTIME !== 'edge') {
            // eslint-disable-next-line @typescript-eslint/no-var-requires,global-require
            require('node:inspector').console.error({
                message,
                err,
                requestId: stringRequestId,
                user_agent,
            });
        }

        nodeLogger.error({
            message: `[next-error] ${message}`,
            resolvedError,
            err,
            requestId: stringRequestId,
            user_agent,
        });

        return;
    }

    return window.console.error.bind(window.console);
};

export const initNextLogger = (): void => {
    // Required to add 'next-logger' to production backend build
    // And to enable it on deploy to GKE
    // Can be refactored after https://github.com/vercel/next.js/pull/22587
    // Tree shaking required because Node.js API used inside block statement
    if (typeof window === 'undefined' && process.env.noExistVarForWebpack) {
        // eslint-disable-next-line global-require
        require('next-logger');
    }
};

export const logMetrics = (label: string, value: number): void => {
    // eslint-disable-next-line no-console
    console.log(
        `%c[Next Metric] ${label} %c${(value / 1000).toFixed(2)}s`,
        'color: green;',
        'color: grey'
    );
};
