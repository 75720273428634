import React, { FC, memo } from 'react';

import FooterSportListContent from './FooterSportLinksContent';
import type { FooterSportListProps as Props } from './types';
import useFooterSportLinks from './useFooterSportLinks';

const FooterSportLinksClient: FC<Props> = ({ id, sportList }) => {
    const { sportLinks } = useFooterSportLinks({ id });

    const dataForRender = sportLinks || sportList;

    if (!dataForRender.length) return null;

    return <FooterSportListContent sportList={dataForRender} />;
};

export default memo(FooterSportLinksClient);
