/* eslint-disable */

import { IncomingHttpHeaders } from 'http';

interface RefCodeTrackerProps {
    referer: IncomingHttpHeaders['referer'];
}

const MAP: Record<string, string> = {
    daum: 'q',
    eniro: 'search_word',
    naver: 'query',
    pchome: 'q',
    'images.google': 'q',
    google: 'q',
    yahoo: 'p',
    msn: 'q',
    bing: 'q',
    aol: 'q',
    lycos: 'q',
    ask: 'q',
    netscape: 'query',
    cnn: 'query',
    about: 'terms',
    mamma: 'q',
    voila: 'rdata',
    virgilio: 'qs',
    live: 'q',
    baidu: 'wd',
    alice: 'qs',
    yandex: 'text',
    najdi: 'q',
    seznam: 'q',
    rakuten: 'qt',
    biglobe: 'q',
    'goo.ne': 'MT',
    wp: 'szukaj',
    onet: 'qt',
    yam: 'k',
    kvasir: 'q',
    ozu: 'q',
    terra: 'query',
    rambler: 'query',
    conduit: 'q',
    babylon: 'q',
    'search-results': 'q',
    avg: 'q',
    comcast: 'q',
    incredimail: 'q',
    startsiden: 'q',
    'go.mail.ru': 'q',
    'search.centrum.cz': 'q',
    '360.cn': 'q',
};

function refCodeTracker({ referer }: RefCodeTrackerProps): null | string {
    return getFromReferrer(referer);
}

const createByHostName = (hostName: string) => {
    for (const [name] of Object.entries(MAP)) {
        if (hostName.includes(name)) {
            return name;
        }
    }

    return null;
};

const getSearchPhrase = (query: string, queryParameterName: string) => {
    if (query === '') {
        return '-';
    }

    const queryParts = Object.fromEntries(new URLSearchParams(query).entries());
    const searchPhrase = queryParts[queryParameterName] || '';

    if (searchPhrase === '' || typeof searchPhrase !== 'string') {
        return '-';
    }

    const regexValidation = /^[\u0000-\u007F]*$/.test(searchPhrase);

    // Validate UTF-8
    if (!regexValidation) {
        return '-';
    }

    return searchPhrase;
};

const getFromReferrer = (referrer: string | undefined) => {
    if (!referrer) {
        return null;
    }

    const referer = new URL(referrer);
    const refererHost = referer.host || '';

    if (refererHost === '' || refererHost === 'localhost') {
        return null;
    }

    const engine = createByHostName(refererHost);

    if (engine === null) {
        return null;
    }

    const refererQueryString = referer.searchParams.toString();
    const searchPhrase = getSearchPhrase(refererQueryString, MAP[engine]);

    return `seo_${engine}_${searchPhrase}`;
};

export default refCodeTracker;
