import { FC } from 'react';
import cn from 'classnames';

import { MainLayoutType } from 'app-constants';
import AppBanner from 'components/AppBanner';
import BroadcastBanner from 'components/BroadcastBanner';
import ErrorBoundary from 'components/ErrorBoundary';
import { useRouter } from 'components/HybridRouter';
import { visibleHeaderDesktopModalHash } from 'components/modals/constants';
import { getModalHash } from 'components/modals/utils';
import VerificationBanner from 'components/VerificationBanner';
import { getServerPageGetTournamentByFilters } from 'gql/cms/queries/tournaments/getTournamentByFilters.cms';
import { useResponsive } from 'layouts/MainLayout/ResponsiveLayoutContext/useResponsive';
import { useLayoutContext } from 'layouts/Root/LayoutTypeContext';
import { WithMainApolloClientContext } from 'services/apollo';
import { GetServerSidePageProps } from 'types';
import HeaderBetting from './HeaderBetting';
import { getHeaderButtonServerSideProps } from './HeaderButtons';
import HeaderCasino from './HeaderCasino';
import HeaderControls from './HeaderControls';
import HeaderLogo, { getHeaderLogoServerSideProps } from './HeaderLogo';
import useHeader from './useHeader';
import useHeaderResizeObserver from './useHeaderResizeObserver';

const Header: FC = () => {
    const { headerRef } = useHeader();
    const { until } = useResponsive();
    const { mainLayoutType } = useLayoutContext();
    const { asPath } = useRouter();

    const { elementRef: containerRef } =
        useHeaderResizeObserver<HTMLDivElement>({
            elementName: 'containerOffset',
            options: { widthType: 'clientWidth' },
        });

    const { elementRef: logoRef } = useHeaderResizeObserver<HTMLDivElement>({
        elementName: 'logoOffset',
    });

    const isMobile = until('md');

    const isBettingLayout = mainLayoutType === MainLayoutType.Betting;
    const isCasinoLayout = mainLayoutType === MainLayoutType.Casino;

    const hash = getModalHash(asPath);

    const isModalVisibleHeader =
        visibleHeaderDesktopModalHash.includes(hash) && !isMobile;

    return (
        <header
            className={cn(
                'sticky top-0',
                isModalVisibleHeader ? 'z-100' : 'z-80'
            )}
            data-test="header"
        >
            <ErrorBoundary>
                <VerificationBanner />

                {isMobile && <AppBanner />}

                <BroadcastBanner />

                <div
                    className={cn('bg-surface-dark py-2', {
                        'flex justify-center': !isMobile,
                    })}
                    ref={headerRef}
                    data-test="header__wrapper"
                >
                    <div
                        ref={containerRef}
                        className="mx-auto flex w-[90%] items-center"
                        data-test="header__container"
                    >
                        <div ref={logoRef} data-test="header__logo">
                            <HeaderLogo className="mr-4" />
                        </div>
                        <div
                            className="flex h-8 w-full justify-end md:justify-between"
                            data-test="header__content"
                        >
                            {isBettingLayout && !isMobile && <HeaderBetting />}
                            {isCasinoLayout && !isMobile && <HeaderCasino />}
                            <HeaderControls />
                        </div>
                    </div>
                </div>
            </ErrorBoundary>
        </header>
    );
};

export const getHeaderServerSideProps = [
    getHeaderButtonServerSideProps,
    getHeaderLogoServerSideProps,
    async (
        ctx: WithMainApolloClientContext
    ): Promise<GetServerSidePageProps> => {
        try {
            const tournamentsByFiltersResponse =
                await getServerPageGetTournamentByFilters({
                    variables: {
                        types: ['betting'],
                        active: true,
                        isNeedPrize: false,
                    },
                })(ctx);

            return {
                props: {
                    gsspData: {
                        tournamentsByFilters: tournamentsByFiltersResponse,
                    },
                },
            };
        } catch (e) {
            console.error(e);
        }

        return {
            props: {},
        };
    },
];

export default Header;
