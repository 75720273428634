import { includes } from 'lodash';

import { AppLink } from 'types/applink';

export const isSimpleRoute = (route: string): boolean => {
    return includes(
        [
            AppLink.N404,
            AppLink._ERROR,
            AppLink.MAINTENANCE,
            AppLink.DUMMYCOUNTRY,
        ],
        route
    );
};
