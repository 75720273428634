import { useOnKycConfirmStatus } from 'gql/platform/subscriptions/onKYCConfirmStatus.ptm';
import { useAuthorization } from 'hooks';
import { useCmsApolloClient } from 'services/apollo';
import { KycStatus } from 'types/gql.ptm';

const useOnKycConfirmStatusSubscription = (): void => {
    const { isAuthorized } = useAuthorization();
    const { client } = useCmsApolloClient();

    useOnKycConfirmStatus({
        onData({ data: { data } }) {
            const { newStatus } = data?.KYCConfirmStatus || {
                newStatus: KycStatus.Unknown,
            };

            client.cache.modify({
                fields: {
                    kyc(cachedKYC) {
                        return {
                            ...cachedKYC,
                            confirmStatus: newStatus === KycStatus.ApprovedFull,
                            status: newStatus.toLowerCase(),
                        };
                    },
                },
            });
        },
        skip: !isAuthorized,
    });
};

export default useOnKycConfirmStatusSubscription;
