interface FormatWidgetInput {
    template: string;
    replace?: string;
    replaceValue?: string;
}

export function formatWidget({
    template,
    replace,
    replaceValue,
}: FormatWidgetInput): string {
    return replace && replaceValue
        ? template.replace(replace, replaceValue)
        : template;
}
