import {
    createContext,
    FC,
    memo,
    PropsWithChildren,
    useContext,
    useMemo,
} from 'react';
import { noop } from 'lodash';

import { DEFAULT_LOCALE } from 'app-constants';
import type { RouterPush } from './types';
import useNavigationPush from './useNavigationPush';

interface Props extends PropsWithChildren {
    locale: string | undefined;
}

const RouterPushProvider: FC<Props> = ({
    children,
    locale = DEFAULT_LOCALE,
}) => {
    const { push } = useNavigationPush({ locale });

    const value = useMemo(() => ({ push }), [push]);

    return (
        <RouterPushContext.Provider value={value}>
            {children}
        </RouterPushContext.Provider>
    );
};

export const RouterPushContext = createContext<RouterPush>({
    push: noop,
});

export const useRouterPush = (): RouterPush => useContext(RouterPushContext);

export default memo(RouterPushProvider);
