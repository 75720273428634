import { getPageUrl } from 'components/utilities/GetPageUrl';
import { getServerPageGetSeoHeaders } from 'layouts/MainLayout/getSeoHeaders.cms';
import { WithMainApolloClientContext } from 'services/apollo';
import { GetServerSidePageProps } from 'types';
import { getPathnameFromResolvedUrl } from 'utils';

async function getSeoHeaders(
    ctx: WithMainApolloClientContext,
    headers?: Record<string, any>
): Promise<GetServerSidePageProps> {
    const pathname = getPathnameFromResolvedUrl(ctx.resolvedUrl);

    const { seoHeaders } = await getServerPageGetSeoHeaders({
        variables: { path: getPageUrl(pathname, ctx.locale) },
        context: { headers },
    })(ctx);

    seoHeaders?.forEach((item) => {
        ctx.res.setHeader(item.key, item.value);
    });

    return {
        props: {},
    };
}

export default getSeoHeaders;
