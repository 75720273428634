import { FC, useMemo } from 'react';
import cn from 'classnames';
import { useTranslations } from 'next-intl';

import Button from 'components/Button';
import MenuTabDesktop from 'components/MenuTabDesktop';
import { IconsPack } from 'components/PackIcon';
import SvgIcon from 'components/SvgIcon';
import { withClientRender } from 'hocs';
import { useClickOutside, useToggle } from 'hooks';
import { getTransformedDataTestId } from 'utils';
import type { LinkOptions } from '../types';

type Props = {
    linksArray: LinkOptions[];
    checkActiveLink: (link: string) => boolean;
};

const HeaderMore: FC<Props> = ({ linksArray, checkActiveLink }) => {
    const [isOpen, toggleDropdown, closeDropdown] = useToggle();

    const clickOutsideRef = useClickOutside(closeDropdown);
    const dropdownAttributes = useMemo(
        () =>
            getTransformedDataTestId({
                defaultAttribute: 'header-more__dropdown',
                isActive: isOpen,
            }),
        [isOpen]
    );

    const t = useTranslations('HeaderNavigation') as any;

    return (
        <div
            className="relative z-2 mr-2 h-8"
            ref={clickOutsideRef}
            data-test="header-more"
        >
            <Button
                iconId="more-horiz"
                pack={IconsPack.NavigationIcons}
                color={Button.Color.SurfaceDark}
                size={Button.Size.Small}
                onClick={toggleDropdown}
                iconClassName={cn(
                    'transition duration-300',
                    isOpen ? 'fill-primary-orange-toxic' : 'fill-primary-white'
                )}
                dataTest={dropdownAttributes}
            />

            <div
                className={cn(
                    'absolute left-1/2 top-8 flex -translate-x-1/2 flex-col bg-surface-dark p-1 shadow-dropdown',
                    { hidden: !isOpen }
                )}
                data-test="header-more__dropdown"
            >
                {linksArray.map(({ textKey, link, icon }) => (
                    <MenuTabDesktop
                        id={textKey}
                        key={textKey}
                        actionHandler={link}
                        isSelected={checkActiveLink(link)}
                        iconsLeft={icon && <SvgIcon icon={icon} />}
                        dataTest="header-more__dropdown-item"
                    >
                        {t(`gincms.menu_item.${textKey}`)}
                    </MenuTabDesktop>
                ))}
            </div>
        </div>
    );
};

export default withClientRender(HeaderMore);
