import { FC, useCallback, useEffect } from 'react';
import { CookieValueTypes, deleteCookie, getCookie } from 'cookies-next';
import { useTranslations } from 'next-intl';
import { isAuthMessage } from 'translations/utils';

import { CookiesType } from 'app-constants';
import useAlertMessages from 'components/AlertManager/useAlertMessages';
import { withClientRender } from 'hocs';

const AppAlert: FC = () => {
    const t = useTranslations('General');
    const { errorAlert, successAlert, warningAlert } = useAlertMessages();

    const getMessageFromCookies = useCallback(() => {
        const messageInfo: CookieValueTypes | undefined = getCookie(
            CookiesType.MessageInfo
        );

        if (!messageInfo || typeof messageInfo !== 'string') return;

        const data = JSON.parse(messageInfo);

        const { code } = data;

        const message = isAuthMessage(code) ? t(`gincms.${code}`) : code;

        switch (data.type) {
            case 'error':
                errorAlert(message);
                break;
            case 'warning':
                warningAlert(message);
                break;
            case 'success':
                successAlert(message);
                break;
            default:
                break;
        }

        deleteCookie(CookiesType.MessageInfo);
    }, [errorAlert, successAlert, warningAlert, t]);

    useEffect(getMessageFromCookies, [getMessageFromCookies]);

    return null;
};

export default withClientRender(AppAlert);
