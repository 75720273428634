import type { Locale } from 'date-fns';
import de from 'date-fns/locale/de';
import enCA from 'date-fns/locale/en-CA';
import en from 'date-fns/locale/en-GB';
import es from 'date-fns/locale/es';
import frCA from 'date-fns/locale/fr-CA';
import hi from 'date-fns/locale/hi';
import hu from 'date-fns/locale/hu';
import ja from 'date-fns/locale/ja';
import pl from 'date-fns/locale/pl';
import pt from 'date-fns/locale/pt';
import ro from 'date-fns/locale/ro';
import ru from 'date-fns/locale/ru';
import th from 'date-fns/locale/th';
import uk from 'date-fns/locale/uk';
import vi from 'date-fns/locale/vi';
import cn from 'date-fns/locale/zh-CN';

const mapLocalesByI18nCodes: Record<string, Locale> = {
    'tl-ph': en,
    'en-ca': enCA,
    'fr-ca': frCA,
    'uk-ua': uk,
    de,
    en,
    cn,
    es,
    hi,
    hu,
    ja,
    pl,
    pt,
    ro,
    ru,
    th,
    vi,
};

const getDateFnsLocale = (localeCode: string): Locale => {
    const dateFnsLocale = mapLocalesByI18nCodes[localeCode] || en;

    return dateFnsLocale;
};

export default getDateFnsLocale;
