/*
 * DO NOT MANUALLY EDIT! This file is autogenerated. Run "yarn gen:next-links" to update.
 */

import type { NextPage } from 'next';
import dynamic from 'next/dynamic';

import { LayoutType } from 'app-constants';
import { PageProps } from 'types';
import { AppLink } from 'types/applink';

const clientRoutes = {
    [AppLink.CATCHALL_PAGES]: withSettings(
        dynamic(() => import('../../pages/[...pages]/index.page'), {
            ssr: false,
        }),
        {
            layoutType: LayoutType.BettingStatic,
            messages: [
                'News',
                'BettingBonuses',
                'betting-global',
                'betting-components',
                'betting-specifiers',
                'betting-filters',
            ],
        }
    ),
    [AppLink.APP]: withSettings(
        dynamic(() => import('../../pages/app/index.page'), { ssr: false }),
        {
            layoutType: LayoutType.BettingStatic,
        }
    ),
    [AppLink.BETS]: withSettings(
        dynamic(() => import('../../pages/bets/index.page'), { ssr: false }),
        {
            layoutType: LayoutType.Betting,
            messages: ['betting-filters', 'betting-matchlist'],
        }
    ),
    [AppLink.BETTINGPROMOTIONS]: withSettings(
        dynamic(() => import('../../pages/betting-promotions/index.page'), {
            ssr: false,
        }),
        {
            layoutType: LayoutType.BettingStatic,
            messages: ['BettingBonuses'],
        }
    ),
    [AppLink.BETTINGTOURNAMENTS_SLUG]: withSettings(
        dynamic(
            () => import('../../pages/betting-tournaments/[slug]/index.page'),
            { ssr: false }
        ),
        {
            layoutType: LayoutType.BettingStatic,
            messages: ['Tournament'],
        }
    ),
    [AppLink.BONUSCHEST]: withSettings(
        dynamic(() => import('../../pages/bonus-chest/index.page'), {
            ssr: false,
        }),
        {
            layoutType: LayoutType.BettingStatic,
        }
    ),
    [AppLink.CASINO_CATEGORY_SLUG]: withSettings(
        dynamic(() => import('../../pages/casino/index.page'), { ssr: false }),
        {
            layoutType: LayoutType.Casino,
            messages: [
                'CasinoBetsHistory',
                'CasinoBetsHistoryHeader',
                'CasinoBonusPrograms',
                'GamesList',
                'GamesThemes',
                'GamesThemesFilter',
                'GamesThemeCard',
                'WinnersList',
            ],
        }
    ),
    [AppLink.CASINO_GAME_SLUG]: withSettings(
        dynamic(() => import('../../pages/casino/game/[slug]/index.page'), {
            ssr: false,
        }),
        {
            layoutType: LayoutType.Game,
        }
    ),
    [AppLink.CASINO_GAMEDEMO_SLUG]: withSettings(
        dynamic(() => import('../../pages/casino/game/[slug]/index.page'), {
            ssr: false,
        }),
        {
            layoutType: LayoutType.Game,
        }
    ),
    [AppLink.CASINO]: withSettings(
        dynamic(() => import('../../pages/casino/index.page'), { ssr: false }),
        {
            layoutType: LayoutType.Casino,
            messages: [
                'CasinoBetsHistory',
                'CasinoBetsHistoryHeader',
                'CasinoBonusPrograms',
                'GamesList',
                'GamesThemes',
                'GamesThemesFilter',
                'GamesThemeCard',
                'WinnersList',
            ],
        }
    ),
    [AppLink.CASINOPROMOTIONS_SLUG]: withSettings(
        dynamic(
            () => import('../../pages/casino-promotions/[slug]/index.page'),
            { ssr: false }
        ),
        {
            layoutType: LayoutType.CasinoStatic,
        }
    ),
    [AppLink.CASINOPROMOTIONS]: withSettings(
        dynamic(() => import('../../pages/casino-promotions/index.page'), {
            ssr: false,
        }),
        {
            layoutType: LayoutType.CasinoStatic,
            messages: [
                'BettingBonuses',
                'NetworkPromotionCard',
                'NetworkPromotionList',
            ],
        }
    ),
    [AppLink.CATEGORY_FAVORITE]: withSettings(
        dynamic(() => import('../../pages/category/favorite/index.page'), {
            ssr: false,
        }),
        {
            layoutType: LayoutType.Casino,
        }
    ),
    [AppLink.CATEGORY_LASTPLAYED]: withSettings(
        dynamic(() => import('../../pages/category/last-played/index.page'), {
            ssr: false,
        }),
        {
            layoutType: LayoutType.Casino,
            messages: [],
        }
    ),
    [AppLink.COLLECTION_COLLECTION_SLUG]: withSettings(
        dynamic(
            () => import('../../pages/collection/[collectionSlug]/index.page'),
            { ssr: false }
        ),
        {
            layoutType: LayoutType.Casino,
            messages: ['GamesList', 'GamesThemesFilter'],
        }
    ),
    [AppLink.COLLECTIONS]: withSettings(
        dynamic(() => import('../../pages/collections/index.page'), {
            ssr: false,
        }),
        {
            layoutType: LayoutType.Casino,
            messages: ['GamesThemes', 'GamesThemesFilter', 'GamesThemeCard'],
        }
    ),
    [AppLink.DUMMYCOUNTRY]: withSettings(
        dynamic(() => import('../../pages/dummy-country/index.page'), {
            ssr: false,
        }),
        {
            layoutType: LayoutType.None,
            messages: [],
        }
    ),
    [AppLink.ESPORTS_SPORT_SLUG_PLAYER_SLUG]: withSettings(
        dynamic(
            () =>
                import(
                    '../../pages/sports/[sportSlug]/player/[slug]/index.page'
                ),
            { ssr: false }
        ),
        {
            layoutType: LayoutType.Betting,
        }
    ),
    [AppLink.ESPORTS_SPORT_SLUG_TEAM_SLUG]: withSettings(
        dynamic(
            () =>
                import('../../pages/sports/[sportSlug]/team/[slug]/index.page'),
            { ssr: false }
        ),
        {
            layoutType: LayoutType.Betting,
        }
    ),
    [AppLink.ESPORTS]: withSettings(
        dynamic(() => import('../../pages/sports/index.page'), { ssr: false }),
        {
            layoutType: LayoutType.Betting,
            messages: ['betting-filters', 'betting-matchlist'],
        }
    ),
    [AppLink.ESPORTS_MATCH_SLUG]: withSettings(
        dynamic(() => import('../../pages/sports/match/[slug]/index.page'), {
            ssr: false,
        }),
        {
            layoutType: LayoutType.Betting,
            messages: ['betting-match', 'betting-sportEventPart'],
        }
    ),
    [AppLink.ESPORTS_OUTRIGHT_SLUG]: withSettings(
        dynamic(() => import('../../pages/sports/outright/[slug]/index.page'), {
            ssr: false,
        }),
        {
            layoutType: LayoutType.Betting,
            messages: ['betting-specifiers'],
        }
    ),
    [AppLink.ESPORTS_RESULT_SLUG]: withSettings(
        dynamic(() => import('../../pages/sports/result/[slug]/index.page'), {
            ssr: false,
        }),
        {
            layoutType: LayoutType.Betting,
            messages: ['betting-match', 'betting-sportEventPart'],
        }
    ),
    [AppLink.ESPORTS_TOURNAMENT_SLUG]: withSettings(
        dynamic(
            () => import('../../pages/sports/tournament/[slug]/index.page'),
            { ssr: false }
        ),
        {
            layoutType: LayoutType.Betting,
            messages: ['betting-filters', 'betting-matchlist', 'News'],
        }
    ),
    [AppLink.EUROCUP]: withSettings(
        dynamic(() => import('../../pages/euro-cup/index.page'), {
            ssr: false,
        }),
        {
            layoutType: LayoutType.BettingStatic,
            messages: ['betting-euro'],
        }
    ),
    [AppLink.FAVORITE_OUTRIGHTS]: withSettings(
        dynamic(() => import('../../pages/favorite/outrights/index.page'), {
            ssr: false,
        }),
        {
            layoutType: LayoutType.Betting,
        }
    ),
    [AppLink.FAVORITE_RESULTS]: withSettings(
        dynamic(() => import('../../pages/favorite/results/index.page'), {
            ssr: false,
        }),
        {
            layoutType: LayoutType.Betting,
        }
    ),
    [AppLink.FAVORITE_UPCOMING]: withSettings(
        dynamic(() => import('../../pages/favorite/upcoming/index.page'), {
            ssr: false,
        }),
        {
            layoutType: LayoutType.Betting,
        }
    ),
    [AppLink.FREEBETS]: withSettings(
        dynamic(() => import('../../pages/freebets/index.page'), {
            ssr: false,
        }),
        {
            layoutType: LayoutType.Betting,
            messages: ['betting-freebet', 'betting-matchlist'],
        }
    ),
    [AppLink.ROOT]: withSettings(
        dynamic(() => import('../../pages/index.page'), { ssr: false }),
        {
            layoutType: LayoutType.BettingStatic,
            messages: [
                'HomePage',
                'GamesThemeCard',
                'GamesThemes',
                'betting-specifiers',
                'betting-global',
                'betting-countries',
                'betting-euro',
            ],
        }
    ),
    [AppLink.INPUTS]: withSettings(
        dynamic(() => import('../../pages/inputs/index.page'), { ssr: false }),
        {
            layoutType: LayoutType.BettingStatic,
        }
    ),
    [AppLink.LIVE]: withSettings(
        dynamic(() => import('../../pages/live/index.page'), { ssr: false }),
        {
            layoutType: LayoutType.Betting,
            messages: ['betting-filters', 'betting-matchlist'],
        }
    ),
    [AppLink.MAINTENANCE]: withSettings(
        dynamic(() => import('../../pages/maintenance/index.page'), {
            ssr: false,
        }),
        {
            layoutType: LayoutType.None,
        }
    ),
    [AppLink.NETWORKPROMOTIONS_SLUG]: withSettings(
        dynamic(
            () => import('../../pages/network-promotions/[slug]/index.page'),
            { ssr: false }
        ),
        {
            layoutType: LayoutType.Casino,
            messages: [
                'NetworkPromotionCard',
                'NetworkPromotionList',
                'NetworkPromotionDetailsGames',
            ],
        }
    ),
    [AppLink.NEXTTOGO]: withSettings(
        dynamic(() => import('../../pages/next-to-go/index.page'), {
            ssr: false,
        }),
        {
            layoutType: LayoutType.Betting,
            messages: ['betting-filters', 'betting-matchlist'],
        }
    ),
    [AppLink.OUTRIGHTS]: withSettings(
        dynamic(() => import('../../pages/outrights/index.page'), {
            ssr: false,
        }),
        {
            layoutType: LayoutType.Betting,
        }
    ),
    [AppLink.PROMOTION_SLUG]: withSettings(
        dynamic(() => import('../../pages/promotion/[slug]/index.page'), {
            ssr: false,
        }),
        {
            layoutType: LayoutType.Casino,
        }
    ),
    [AppLink.RESULTS]: withSettings(
        dynamic(() => import('../../pages/results/index.page'), { ssr: false }),
        {
            layoutType: LayoutType.Betting,
            messages: ['betting-filters', 'betting-matchlist'],
        }
    ),
    [AppLink.SENTRY]: withSettings(
        dynamic(() => import('../../pages/sentry/index.page'), { ssr: false }),
        {
            layoutType: LayoutType.BettingStatic,
        }
    ),
    [AppLink.SPORTSTATISTICS]: withSettings(
        dynamic(() => import('../../pages/sport-statistics/index.page'), {
            ssr: false,
        }),
        {
            layoutType: LayoutType.BettingStatic,
        }
    ),
    [AppLink.SPORTS_SPORT_SLUG_PLAYER_SLUG]: withSettings(
        dynamic(
            () =>
                import(
                    '../../pages/sports/[sportSlug]/player/[slug]/index.page'
                ),
            { ssr: false }
        ),
        {
            layoutType: LayoutType.Betting,
        }
    ),
    [AppLink.SPORTS_SPORT_SLUG_TEAM_SLUG]: withSettings(
        dynamic(
            () =>
                import('../../pages/sports/[sportSlug]/team/[slug]/index.page'),
            { ssr: false }
        ),
        {
            layoutType: LayoutType.Betting,
        }
    ),
    [AppLink.SPORTS]: withSettings(
        dynamic(() => import('../../pages/sports/index.page'), { ssr: false }),
        {
            layoutType: LayoutType.Betting,
            messages: ['betting-filters', 'betting-matchlist'],
        }
    ),
    [AppLink.SPORTS_MATCH_SLUG]: withSettings(
        dynamic(() => import('../../pages/sports/match/[slug]/index.page'), {
            ssr: false,
        }),
        {
            layoutType: LayoutType.Betting,
            messages: ['betting-match', 'betting-sportEventPart'],
        }
    ),
    [AppLink.SPORTS_OUTRIGHT_SLUG]: withSettings(
        dynamic(() => import('../../pages/sports/outright/[slug]/index.page'), {
            ssr: false,
        }),
        {
            layoutType: LayoutType.Betting,
            messages: ['betting-specifiers'],
        }
    ),
    [AppLink.SPORTS_RESULT_SLUG]: withSettings(
        dynamic(() => import('../../pages/sports/result/[slug]/index.page'), {
            ssr: false,
        }),
        {
            layoutType: LayoutType.Betting,
            messages: ['betting-match', 'betting-sportEventPart'],
        }
    ),
    [AppLink.SPORTS_TOURNAMENT_SLUG]: withSettings(
        dynamic(
            () => import('../../pages/sports/tournament/[slug]/index.page'),
            { ssr: false }
        ),
        {
            layoutType: LayoutType.Betting,
            messages: ['betting-filters', 'betting-matchlist', 'News'],
        }
    ),
    [AppLink.TERMSANDCONDITIONSBONUSES]: withSettings(
        dynamic(
            () => import('../../pages/terms-and-conditions-bonuses/index.page'),
            { ssr: false }
        ),
        {
            layoutType: LayoutType.BettingStatic,
        }
    ),
    [AppLink.TOURNAMENTBRACKET_SLUG]: withSettings(
        dynamic(() => import('../../pages/tournament-bracket/index.page'), {
            ssr: false,
        }),
        {
            layoutType: LayoutType.BettingStatic,
            messages: ['betting-bracket', 'PickemTournament'],
        }
    ),
    [AppLink.TOURNAMENTBRACKET]: withSettings(
        dynamic(() => import('../../pages/tournament-bracket/index.page'), {
            ssr: false,
        }),
        {
            layoutType: LayoutType.BettingStatic,
            messages: ['betting-bracket', 'PickemTournament'],
        }
    ),
    [AppLink.TOURNAMENTS_SLUG]: withSettings(
        dynamic(() => import('../../pages/tournaments/index.page'), {
            ssr: false,
        }),
        {
            layoutType: LayoutType.Casino,
            messages: ['Tournament', 'GamesList'],
        }
    ),
    [AppLink.TOURNAMENTS]: withSettings(
        dynamic(() => import('../../pages/tournaments/index.page'), {
            ssr: false,
        }),
        {
            layoutType: LayoutType.Casino,
            messages: ['Tournament', 'GamesList'],
        }
    ),
    [AppLink.WHEEL]: withSettings(
        dynamic(() => import('../../pages/wheel/index.page'), { ssr: false }),
        {
            layoutType: LayoutType.Casino,
            messages: ['WheelOfFortune'],
        }
    ),
} as any as Record<AppLink, NextPage<PageProps>>;

function withSettings(Component: any, settings: NextPage['settings']) {
    // eslint-disable-next-line
    Component.settings = settings;

    return Component;
}

export default clientRoutes;
