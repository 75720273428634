import React from 'react';
import cn from 'classnames';
import { useTranslations } from 'next-intl';

import { AppLink } from 'app-constants';
import SmartLink from 'components/SmartLink';

import styles from './notFound.module.css';

const NotFound: React.FC = () => {
    const t = useTranslations('ErrorPage');

    return (
        <main className={cn('relative h-screen', styles.page404)}>
            <div className="absolute inset-x-0 bottom-0 top-1/2 m-auto -translate-y-1/2 text-center">
                <div
                    className={cn(
                        'm-auto h-[206px] w-[520px] text-center',
                        styles.logo
                    )}
                />

                <div className="text-primary-white">
                    <div className="text-lg-2 font-bold uppercase">
                        {t('gincms.page404.title')}
                    </div>

                    <div className="text-base">{t('gincms.page404.text')}</div>

                    <SmartLink
                        href={AppLink.ROOT}
                        className={cn(
                            'mt-10 inline-block rounded-default bg-[#e08900] px-6 py-3 text-base',
                            'font-bold uppercase text-primary-white no-underline',
                            'hover:bg-[#ff9c00] active:bg-[#ff9c00]'
                        )}
                    >
                        {t('gincms.page404.go_main')}
                    </SmartLink>
                </div>
            </div>
        </main>
    );
};

export default NotFound;
