import { useCallback } from 'react';

import useRouter from 'components/HybridRouter/useRouter';
import { AnalyticAttrs, Category, useAnalyticAttrs } from '../GoogleAnalytics';
import { GTMEvent } from './constants';
import { sendGTM } from './utils';

interface Output {
    analytic: AnalyticAttrs;
    bannerClickHandlerGTME?: (bannerId: string) => VoidFunction;
}

const useBannerGTME = (): Output => {
    const { locale } = useRouter();
    const analytic = useAnalyticAttrs();

    const category = analytic['data-category'] || Category.BannerTopSlider;

    const bannerClickHandlerGTME = useCallback(
        (bannerId: string) => () => {
            sendGTM({
                event: GTMEvent.TopBannerClick,
                banner_info: `[${locale}] ${bannerId}`,
            });
        },
        [locale]
    );

    return {
        analytic,
        bannerClickHandlerGTME:
            category === Category.BannerTopSlider
                ? bannerClickHandlerGTME
                : undefined,
    };
};

export default useBannerGTME;
