import { FC, memo } from 'react';
import { useReactiveVar } from '@apollo/client';
import cn from 'classnames';

import Spinner from 'components/Spinner';
import { mainLoaderState } from './mainLoaderState';

const MainLoader: FC = () => {
    const isShowLoader = useReactiveVar(mainLoaderState);

    return (
        <div
            className={cn(
                ' fixed left-0 top-0 z-max flex h-screen w-full items-center justify-center bg-surface-dark  transition-all',
                isShowLoader ? 'visible opacity-100' : 'invisible opacity-0'
            )}
        >
            <Spinner />
        </div>
    );
};

export default memo(MainLoader);
