import { offsetLimitPagination } from '@apollo/client/utilities';
import { differenceBy, keys, omit } from 'lodash';

import { UNIQUE_ID_KEY } from 'app-constants';
import { betslipQueryStateTypePolicy } from 'components/betting/Betslip/betslipState';
import type { SportEvent } from 'types/gql.bet';
import { StrictTypedTypePolicies } from 'types/typePolicies.bet';
import {
    QueryFieldPolicyWithGetFragment,
    TypePolicies,
    TypePolicyTypeParent,
} from '../types';
import { mergeData } from '../utils';
import makeGenPath from '../utils/makeGenPath';

const typePolicies: TypePolicies<StrictTypedTypePolicies> &
    QueryFieldPolicyWithGetFragment = {
    Query: {
        fields: {
            restrictions: {
                keyArgs: false,
            },
            sports: {
                keyArgs: false,
            },
            sportEventsByIds: {
                keyArgs: (args) => keys(omit(args, ['marketTypes'])),
            },
            sportEventsByFilters: {
                ...offsetLimitPagination(),
                keyArgs: (args) =>
                    keys(omit(args, ['limit', 'offset', 'order', 'dateFrom'])),
            },
            sportEventListByFilters: {
                keyArgs: (args) =>
                    keys(omit(args, ['limit', 'offset', 'order', 'dateFrom'])),
                merge: mergeSportEventListByFilters,
            },
            filterSportEvents: {
                ...offsetLimitPagination(),
                keyArgs: (args) => keys(omit(args, ['limit', 'offset'])),
            },
            bets: {
                keyArgs: ['input', ['statuses']],
                // TODO need create function offsetLimitPagination with offset on all levels object GINFE-3276
                merge(existing, incoming, { args }) {
                    if (args?.input.resetCache) {
                        return [];
                    }

                    const merged = existing ? [...existing] : [];

                    if (incoming && args?.input) {
                        const { offset } = args.input;

                        // eslint-disable-next-line no-plusplus
                        for (let i = 0; i < incoming.length; ++i) {
                            merged[offset + i] = incoming[i];
                        }
                    }

                    if (!incoming) {
                        // eslint-disable-next-line prefer-spread
                        merged.push.apply(merged, incoming);
                    }

                    return merged;
                },
            },
            bannerZones: {
                keyArgs: ['attributes', ['platforms', 'pageTypes']],
                merge(existing, incoming) {
                    const existingArray = existing || [];
                    const diff = differenceBy(incoming, existingArray, '__ref');

                    if (diff.length === 0) return existing;

                    return existingArray.concat(diff);
                },
            },
            betStakeSuggest: {
                keyArgs: ['minStake'],
            },
            getFragment: (_, { args, toReference }) => {
                return toReference({
                    __typename: args?.__typename,
                    path: args?.id,
                    id: args?.id,
                });
            },
            ...betslipQueryStateTypePolicy,
        },
    },

    SportEventFixture: {
        merge(existing, incoming) {
            return mergeData([existing || {}, incoming]);
        },
    },

    SportEvent: {
        fields: {
            markets: {
                keyArgs: (args) => keys(omit(args, ['limit'])),
            },
        },
    },

    Odd: {
        keyFields: [UNIQUE_ID_KEY],

        parents: [
            'Market',
            // @ts-ignore: error TS2590: Expression produces a union type that is too complex to represent.
            // TODO: Extract scoreboard types into a separate types file
            makeTypeParent({
                typename: 'BetOdd',
                genPath: makeGenPath([
                    { typename: 'Bet', keys: ['id'] },
                    {
                        typename: 'BetOdd',
                        keys: ['sportEvent.id', 'market.id', 'odd.id'],
                    },
                ]),
            }),
        ],
    },

    Market: {
        keyFields: [UNIQUE_ID_KEY],
        parents: [
            'SportEvent',
            makeTypeParent({ typename: 'BetOdd', nodePath: 'sportEvent' }),
            makeTypeParent({
                typename: 'CompiledMarketsTab',
                nodePath: 'sportEvent',
            }),
            makeTypeParent({
                typename: 'BetTips',
                nodePath: 'sportEvent',
                injectParentTypename: true,
            }),
        ],
    },

    SportEventCompetitor: {
        keyFields: [UNIQUE_ID_KEY],
        parents: ['SportEvent'],
    },

    CompetitorScore: {
        keyFields: [UNIQUE_ID_KEY],
        parents: ['SportEventCompetitor'],
    },

    Stream: {
        keyFields: [UNIQUE_ID_KEY],
        parents: ['SportEvent'],
    },

    Sport: {
        keyFields: false,
    },
};

function makeTypeParent<Typename extends keyof StrictTypedTypePolicies>(
    parentConfig: TypePolicyTypeParent<StrictTypedTypePolicies, Typename>
) {
    return parentConfig;
}

type InputSportEventListByFilters = {
    count: number;
    sportEvents: SportEvent[];
};

function mergeSportEventListByFilters(
    existing: InputSportEventListByFilters,
    incoming: InputSportEventListByFilters,
    { args }: any
): InputSportEventListByFilters {
    const merged = existing?.sportEvents.length
        ? [...existing.sportEvents]
        : [];

    if (incoming.sportEvents?.length && args) {
        const { offset } = args;

        incoming.sportEvents.forEach((sportEvent, idx: number) => {
            merged[offset + idx] = sportEvent;
        });
    }

    return { count: incoming?.count || 0, sportEvents: merged };
}

export default typePolicies;
