import { MENU_KEY_QUERY } from 'components/MenuNav/constants';
import { getScrollBarWidth } from 'utils';
import {
    authorizationModalsHash,
    visibleHeaderDesktopModalHash,
    visibleNavbarHeaderMobileModalHash,
    visibleNavbarMobileModalHash,
} from './constants';
import { modals } from './modals';

export { bannedCountryValidate } from 'components/modals/utils/bannedCountryValidate';
export { phoneMaskValidate } from 'components/modals/utils/phoneMaskValidate';

const EXCLUDED_SEARCH_PARAMS = [MENU_KEY_QUERY];

export function disableScroll(): void {
    const { body } = document;
    const scrollBarWidth = getScrollBarWidth();

    if (!body.classList.contains('overflow-hidden')) {
        body.classList.add('overflow-hidden');
        body.removeAttribute('style');
    }

    if (scrollBarWidth) {
        body.style.paddingRight = `${scrollBarWidth}px`;
    }
}

export function enableScroll(): void {
    const { body } = document;

    if (body.classList.contains('overflow-hidden')) {
        body.classList.remove('overflow-hidden');
    }

    if (body.style.paddingRight) {
        body.style.paddingRight = '';
    }
}

export function parsedSearchParams(search?: string): string {
    if (!search) return '';

    const searchParams = new URLSearchParams(search);

    EXCLUDED_SEARCH_PARAMS.forEach((param) => searchParams.delete(param));

    return `?${searchParams.toString()}`;
}

export const getModalHash = (asPath: string): keyof typeof modals => {
    return asPath.split('#!')[1]?.split('?')[0] as keyof typeof modals;
};

export function dynamicModalClasses(
    hash: keyof typeof modals,
    options: {
        isShowAppBanner: boolean;
        isMobile: boolean;
        isGamePage: boolean;
    }
): string {
    const { isMobile, isShowAppBanner, isGamePage } = options;

    const offsetWithoutHeaderHeight =
        'top-[calc(theme(spacing.header-group-offset)-theme(spacing.header-offset))] bottom-12';

    if (hash === '/message/inbox' && !isMobile) {
        return 'top-header-group-offset bottom-0';
    }

    if (visibleHeaderDesktopModalHash.includes(hash) && !isMobile) {
        return 'top-header-group-offset bottom-0';
    }

    if (visibleNavbarHeaderMobileModalHash.includes(hash) && isMobile) {
        return 'top-[theme(spacing.header-group-offset)] bottom-12';
    }

    if (authorizationModalsHash.includes(hash) && isMobile) {
        return 'top-[calc(theme(spacing.header-group-offset)-theme(spacing.header-offset))] h-[calc(100vh-theme(spacing.header-group-offset)+theme(spacing.header-offset))]';
    }

    if (visibleNavbarMobileModalHash.includes(hash) && isMobile) {
        return isGamePage ? 'bottom-0' : offsetWithoutHeaderHeight;
    }

    if (isShowAppBanner && isMobile) {
        return offsetWithoutHeaderHeight;
    }

    return 'h-screen';
}
