import { RefObject, useCallback } from 'react';

interface Input {
    ref: RefObject<HTMLDivElement>;
    options?: ScrollIntoViewOptions;
}

interface Output {
    scrollToHandler: VoidFunction;
}

const useScrollTo = ({ ref, options }: Input): Output => {
    const scrollToHandler = useCallback(() => {
        ref.current?.scrollIntoView(options);
    }, [ref, options]);

    return { scrollToHandler };
};

export default useScrollTo;
