import type { FC } from 'react';
import { memo, useCallback, useEffect } from 'react';

import { useRouter } from 'components/HybridRouter';
import { useIsMobile } from 'components/utilities/MobileDetect';
import { withClientRender } from 'hocs';
import { usePreviousRef } from 'hooks';
import { Action, AnalyticsAttr, Category } from './constants';
import { formattedPathname, send } from './utils';

const GoogleAnalytics: FC = () => {
    const { isMobile } = useIsMobile();
    const { pathname, query } = useRouter();

    const prevUrl = usePreviousRef(formattedPathname({ pathname, query }));

    const handleClickEvent: EventListener = useCallback((event: Event) => {
        const { target } = event;

        if (!target) return;

        const dataset = (target as Element)?.closest<HTMLElement>(
            `[${AnalyticsAttr.category}]` as const
        )?.dataset;

        if (!dataset) return;

        const { category, action, label } = dataset;

        if (!category || !action) return;

        const fieldsObject = {
            eventCategory: category,
            eventAction: action,
        };
        send({ fieldsObject, label });
    }, []);

    useEffect(() => {
        const eventType = isMobile ? 'click' : 'mousedown';

        document.body.addEventListener(eventType, handleClickEvent);

        return () => {
            document.body.removeEventListener(eventType, handleClickEvent);
        };
    }, [handleClickEvent, isMobile]);

    useEffect(() => {
        if (!prevUrl.current) return;

        const label = `From: ${prevUrl.current}; To: ${formattedPathname({
            pathname,
            query,
        })}`;

        const fieldsObject = {
            eventCategory: Category.AppCategory,
            eventAction: Action.ChangePage,
        };
        send({ fieldsObject, label });
    }, [pathname, prevUrl, query]);

    return null;
};

export default memo(withClientRender(GoogleAnalytics));
