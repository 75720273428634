import cn from 'classnames';

import { BonusBubbleType } from './types';

export const bonusBubbleClasses = {
    [BonusBubbleType.HEADER]: cn(
        'ml-2 inline-flex h-4 w-4 items-center justify-center rounded-full',
        'bg-primary-black pr-[1px] text-xs font-medium text-primary-white'
    ),
    [BonusBubbleType.LOBBY]: cn('mr-2 h-[6px] w-[6px] rounded-full bg-red-500'),
    [BonusBubbleType.LOBBY_TOP]: cn(
        'absolute right-3 top-[2px] z-1 h-[6px] w-[6px] rounded-full',
        'bg-red-500'
    ),
    [BonusBubbleType.LOBBY_COUNT]: cn('bg-[inherit]'),
};
