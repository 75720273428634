import { useMemo } from 'react';
import { ApolloClient, QueryResult, useQuery } from '@apollo/client';
import { QueryHookOptions } from '@apollo/client/react/types/types';
import { getFragmentDefinitions } from '@apollo/client/utilities';
import { DocumentNode, print } from 'graphql';
import gql from 'graphql-tag';

// get idea from https://github.com/abhiaiyer91/apollo-fragment

type FragmentQueryData<TData = any> = {
    getFragment?: TData;
};

type ApolloFragmentResult<TData = any> = Omit<
    QueryResult<FragmentQueryData<TData>>,
    'data'
> & {
    data: TData | undefined;
};

type Fragment = {
    fragmentQuery: DocumentNode;
    id: string;
    typename: string;
    client: ApolloClient<any>;
    options?: QueryHookOptions;
};

export function useFragment<TData = any>({
    fragment,
    id,
    options,
    typename,
    client,
}: {
    fragment: DocumentNode;
} & Omit<Fragment, 'fragmentQuery'>): ApolloFragmentResult<TData> {
    const fragmentQuery = useMemo(
        () => buildFragmentQuery(fragment),
        [fragment]
    );

    const { data, ...rest } = useQuery<FragmentQueryData<TData>>(
        fragmentQuery,
        {
            ...options,
            client,
            fetchPolicy: 'cache-only',
            variables: {
                ...options?.variables,
                id,
                typename,
            },
        }
    );

    const fragmentData = data && data.getFragment;

    if (!fragmentData) {
        checkDataCompleteness({
            fragmentQuery,
            id,
            options,
            client,
            typename,
        });
    }

    return {
        ...rest,
        data: fragmentData,
    };
}

function getDiff({ fragmentQuery, id, options, client, typename }: Fragment) {
    return client.cache.diff<any>({
        ...options,
        query: fragmentQuery,
        variables: {
            id,
            typename,
        },
        previousResult: undefined,
        optimistic: true,
    });
}

function checkDataCompleteness({
    fragmentQuery,
    id,
    options,
    client,
    typename,
}: Fragment): void {
    // Only perform completeness check for non-production code
    if (process.env.NODE_ENV === 'production') {
        return;
    }

    const diff = getDiff({
        fragmentQuery,
        id,
        options,
        client,
        typename,
    });

    if (diff.complete === true) {
        return;
    }

    const fragmentData = diff.result && diff.result.getFragment;
    const noData = Object.keys(fragmentData || {}).length === 0;

    if (noData) {
        return;
    }

    const dataWithoutTypename = { ...fragmentData, __typename: undefined };

    const errorMessage = `
        Unable to resolve fragment fields for ${id}:
        ${print(fragmentQuery)}
        Available data:
        ${JSON.stringify(dataWithoutTypename, null, 2)}
        Make sure that the fields requested in the fragment are fetched by some query`;

    console.error(errorMessage);
}

export function buildFragmentQuery(fragment: DocumentNode): DocumentNode {
    const fragmentDefinitions = getFragmentDefinitions(fragment);

    return gql`
        query getFragment(
            $id: ID, $typename: String!,
        ) {
            getFragment(
                id: $id, __typename: $typename,
            ) {
                ...${fragmentDefinitions[0].name.value}
            }
        }
        ${print(fragment)}
    `;
}
