import { FC, memo, useCallback } from 'react';

import Button from 'components/Button';
import useModal from 'components/modals/useModal';
import { IconsPack } from 'components/PackIcon';

interface Props {
    icon?: JSX.Element;
    title?: string;
    image?: JSX.Element;
    onClose?: VoidFunction;
    hasCloseButton: boolean;
}

const ModalBaseLayoutHeader: FC<Props> = ({
    icon,
    title,
    image,
    hasCloseButton,
    onClose,
}) => {
    const { closeModal } = useModal();

    const onCloseCallback = useCallback(() => {
        if (onClose) {
            onClose();

            return;
        }

        closeModal();
    }, [closeModal, onClose]);

    return (
        <div
            className="relative shrink-0 border-b-2 border-b-surface-dark bg-surface-middle"
            data-test="modal-base__header"
        >
            {icon && (
                <div className="flex p-6">
                    <div
                        className="inline-block w-[fit-content] rounded-full bg-surface-light p-4"
                        data-test="modal-base__header-icon"
                    >
                        {icon}
                    </div>
                </div>
            )}

            {title && (
                <div className="p-6">
                    <h1
                        className="break-words pr-14 text-lg-2 font-medium text-primary-white"
                        data-test="modal-base__header-title"
                    >
                        {title}
                    </h1>
                </div>
            )}

            {image && <>{image}</>}

            {hasCloseButton && (
                <Button
                    isRounded={true}
                    iconId="close"
                    pack={IconsPack.ActionIcons}
                    color={Button.Color.SurfaceDark}
                    size={Button.Size.Small}
                    className="absolute right-6 top-6"
                    onClick={onCloseCallback}
                    dataTest="modal-base__header-close"
                />
            )}
        </div>
    );
};

export default memo(ModalBaseLayoutHeader);
