import type { FC, PropsWithChildren } from 'react';
import { memo } from 'react';
import cn from 'classnames';

import SmartLink from 'components/SmartLink';
import { Id } from 'types';
import useMenuTabDesktop, {
    ActionHandlerType,
    IconType,
} from './useMenuTabDesktop';

interface Props extends PropsWithChildren {
    id: Id;
    iconsLeft?: IconType;
    iconsRight?: IconType;
    actionHandler?: ActionHandlerType;
    className?: string;
    isSelected?: boolean;
    typo?: string;
    dataTest?: string;
}

const MenuTabDesktop: FC<Props> = ({
    id,
    iconsLeft,
    iconsRight,
    children,
    actionHandler,
    className,
    isSelected,
    typo = 'typo-sm-2',
    dataTest,
}) => {
    const {
        isModal,
        handleOnClick,
        openModalHandler,
        menuTabClasses,
        dataTestAttributes,
    } = useMenuTabDesktop({
        actionHandler,
        id,
        iconsLeft,
        iconsRight,
        className,
        dataTest,
        isSelected,
    });

    const content = (
        <>
            {iconsLeft}

            {children && (
                <span
                    className={cn(
                        'ml-1 grow whitespace-nowrap uppercase',
                        typo
                    )}
                    data-test="menu-tab-desktop__content"
                >
                    {children}
                </span>
            )}

            {iconsRight}
        </>
    );

    if (isModal) {
        return (
            <div
                className={menuTabClasses}
                onClick={openModalHandler}
                data-test={dataTestAttributes}
            >
                {content}
            </div>
        );
    }

    if (actionHandler && typeof actionHandler !== 'function') {
        return (
            <SmartLink
                href={actionHandler}
                className={cn(
                    'no-underline focus-visible:fill-grey-300 focus-visible:text-grey-300 focus-visible:outline-none',
                    menuTabClasses
                )}
                dataTest={dataTestAttributes}
            >
                {content}
            </SmartLink>
        );
    }

    return (
        <div
            className={menuTabClasses}
            onClick={handleOnClick}
            data-test={dataTestAttributes}
        >
            {content}
        </div>
    );
};

export default memo(MenuTabDesktop);
