enum TimeUnit {
    Day = 24 * 60 * 60,
    Hour = 60 * 60,
    Minute = 60,
    Second = 1,
}

function zeroFill(n: number): string {
    if (n < 10) return `0${n}`;

    return `${n}`;
}

export function convertSecondsToTimerFormat(
    seconds: number,
    dividers: TimeUnit[],
    dividerIndex: number = 0
): string {
    const currentDivider = dividers[dividerIndex];

    if (!currentDivider) return '';

    const wholePart = Math.abs(Math.trunc(seconds / currentDivider));
    const fractionPart = seconds % currentDivider;

    if (dividers.length === dividerIndex + 1) return zeroFill(wholePart);

    const sign = dividerIndex === 0 && seconds < 0 ? '-' : '';

    return (
        sign +
        zeroFill(wholePart)
            .concat(':')
            .concat(
                convertSecondsToTimerFormat(
                    fractionPart,
                    dividers,
                    dividerIndex + 1
                )
            )
    );
}
