export enum GamblerSetting {
    ODD_FORMAT = 'oddFormat',
    SHOW_ODDS_STATISTIC = 'showOddsStatistics',
    ODD_STATISTIC_VIEW = 'oddStatisticView',
    SHOW_ODD_HISTORY = 'showOddHistory',
    FAVORITE_MARKETS_ID = 'favoriteMarketsId',
    DEEPLINK_MATCH_DATA = 'deeplinkMatchData',
    ODD_ACCEPT_STRATEGY = 'oddAcceptStrategy',
}

export enum OddStatisticView {
    Market = 'MARKET',
    Match = 'MATCH',
}
