import type { GetPagesUrlBySlug } from 'types/gql.cms';
import { footerInfoLinks, providerIds } from './constants';
import { useGetPagesUrlBySlug } from './getPagesUrlBySlug.cms';

interface Input {
    typePage: string;
    skip?: boolean;
}
interface Output {
    data: GetPagesUrlBySlug | undefined;
}

const usePagesUrls = ({ typePage, skip }: Input): Output => {
    function getVariables() {
        switch (typePage) {
            case 'providers':
                return providerIds;
            case 'betting':
            case 'casino':
            default:
                return footerInfoLinks;
        }
    }

    const slugs = getVariables();

    const { data } = useGetPagesUrlBySlug(() => ({
        variables: {
            slugs,
        },
        skip,
    }));

    return {
        data,
    };
};

export default usePagesUrls;
