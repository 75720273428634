import { ApolloError } from '@apollo/client';

import formatNetworkErrorResult from 'utils/formatNetworkErrorResult';

export enum BettingErrorType {
    Forbidden = 'forbidden',
    Aborted = 'aborted',
}

export type BettingInitError = {
    code?: string;
    message: string;
    type: BettingErrorType;
};

const FORBIDDEN_CODE = 'betting.forbidden';

export const getBettingInitError = (e: ApolloError): BettingInitError => {
    let bettingError: BettingInitError = {
        type: BettingErrorType.Aborted,
        message: 'Betting initialization failed',
    };

    if (e.networkError && 'result' in e.networkError) {
        const result = formatNetworkErrorResult(e.networkError.result);

        result.forEach(({ errors }) => {
            if (!errors) {
                throw new Error(
                    '[getBettingInitError] Error: response no errors'
                );
            }

            const { extensions, message } = errors[0];

            const isForbidden = extensions.code === FORBIDDEN_CODE;

            bettingError = {
                type: isForbidden
                    ? BettingErrorType.Forbidden
                    : BettingErrorType.Aborted,
                code: extensions.code,
                message,
            };
        });
    }

    return bettingError;
};
