import { BetType, PlaceBetInsurance } from 'types/gql.bet';
import idPathParser from 'utils/idPathParser';
import {
    addAnalyticData,
    clearAnalyticData,
    removeAnalyticData,
} from './betslipAnalyticVars';
import {
    activeBetslipFreebetIdVar,
    activeBetslipInsuranceVar,
    betslipVars,
    initialState,
} from './betslipState';
import { BetslipStatus } from './constants';
import type { BetslipError } from './types';

const setDefaultState = (): void => {
    const { mode, stake, betId, status } = initialState;

    betslipVars(initialState);

    localStorage.setItem('betslip.mode', mode);
    localStorage.setItem('betslip.stake', stake);
    localStorage.setItem('betslip.betId', betId);
    localStorage.setItem('betslip.status', status);
};

export const persistBetslip = (): void => {
    const oddIds = localStorage.getItem('betslip.oddIds');
    const mode = localStorage.getItem('betslip.mode') as BetType;
    const stake = localStorage.getItem('betslip.stake');
    const betId = localStorage.getItem('betslip.betId');
    const status: BetslipStatus = localStorage.getItem(
        'betslip.status'
    ) as BetslipStatus;

    if (status && status !== BetslipStatus.Initial) {
        setDefaultState();

        return;
    }

    if (oddIds && oddIds.length) {
        addAnalyticData({ odd: JSON.parse(oddIds), time: Date.now() });
    }

    betslipVars({
        ...betslipVars(),
        oddIds: oddIds ? JSON.parse(oddIds) : [],
        mode: mode || BetType.Single,
        stake: stake || '',
        betId: betId || '',
        status: BetslipStatus.Initial,
    });
};

export const setMinBet = (minBet: number): void => {
    betslipVars({
        ...betslipVars(),
        minBet,
    });
};

export const setSystemSize = (systemSize: number): void => {
    betslipVars({
        ...betslipVars(),
        systemSize: systemSize || 1,
    });
};

export const setBetErrors = (
    restrictions: BetslipError[],
    declineReason?: string
): void => {
    betslipVars({
        ...betslipVars(),
        betErrors: {
            restrictions,
            declineReason,
        },
    });
};

export const clearBetErrors = (): void => {
    if (!betslipVars().betErrors) return;

    betslipVars({
        ...betslipVars(),
        betErrors: null,
    });
};

export const setMinStakeError = (message: BetslipError | null): void => {
    betslipVars({
        ...betslipVars(),
        validationErrors: {
            ...betslipVars().validationErrors,
            minStakeError: message,
        },
    });
};

export const setMaxStakeError = (message: BetslipError | null): void => {
    betslipVars({
        ...betslipVars(),
        validationErrors: {
            ...betslipVars().validationErrors,
            maxStakeError: message,
        },
    });
};

export const setRestrictionValidationErrors = (
    restrictions: BetslipError[]
): void => {
    betslipVars({
        ...betslipVars(),
        validationErrors: {
            ...betslipVars().validationErrors,
            restrictionError: restrictions,
        },
    });
};

type InputStake = ((s: string) => string) | string;

export const saveStake = (input: InputStake): void => {
    if (betslipVars().status === BetslipStatus.Processing) return;

    if (typeof input === 'string') {
        betslipVars({ ...betslipVars(), stake: input });

        localStorage.setItem('betslip.stake', input);
    }

    if (typeof input === 'function') {
        const stake = input(betslipVars().stake);

        betslipVars({ ...betslipVars(), stake });

        localStorage.setItem('betslip.stake', stake);
    }
};

export const setBetId = (betId: string): void => {
    betslipVars({ ...betslipVars(), betId });

    localStorage.setItem('betslip.betId', betId);
};

export const setStatus = (status: BetslipStatus): void => {
    if (status === betslipVars().status) return;

    betslipVars({ ...betslipVars(), status });

    localStorage.setItem('betslip.status', status);
};

export const changeBetslipMode = (mode: BetType): void => {
    const { status, mode: currentMode } = betslipVars();

    if (status === BetslipStatus.Processing || currentMode === mode) return;

    betslipVars({ ...betslipVars(), mode });
    localStorage.setItem('betslip.mode', mode);
};

export const deleteOdd = (path: string): void => {
    if (betslipVars().status === BetslipStatus.Processing) return;

    const oddIds = betslipVars().oddIds.filter((id) => id !== path);
    removeAnalyticData({ odd: path });

    if (!oddIds.length) {
        saveStake('');
    }

    betslipVars({ ...betslipVars(), oddIds });
    localStorage.setItem('betslip.oddIds', JSON.stringify(oddIds));
};

export const clearOdds = (): void => {
    if (!betslipVars().oddIds.length) return;

    clearAnalyticData();
    // NOTE: Status should be reset first
    setStatus(BetslipStatus.Initial);
    changeBetslipMode(BetType.Single);
    saveStake('');
    betslipVars({ ...betslipVars(), oddIds: [] });
    localStorage.setItem('betslip.oddIds', JSON.stringify([]));
};

export const addOdd = (path: string): void => {
    if (betslipVars().status === BetslipStatus.Processing) return;

    const oddIds = [...betslipVars().oddIds, path];
    addAnalyticData({ odd: path, time: Date.now() });
    betslipVars({ ...betslipVars(), oddIds });
    localStorage.setItem('betslip.oddIds', JSON.stringify(oddIds));
};

export const changeBetslipOddIds = (path: string): void => {
    if (!path || betslipVars().status === BetslipStatus.Processing) return;

    if (betslipVars().oddIds.includes(path)) {
        deleteOdd(path);

        return;
    }

    const { sportEventId } = idPathParser.split('Odd', path);

    const isSomeSportEventOddSelected = !!betslipVars().oddIds.find((id) => {
        const { sportEventId: mathId } = idPathParser.split('Odd', id);

        return mathId === sportEventId;
    });

    if (!betslipVars().oddIds.length || !isSomeSportEventOddSelected)
        return addOdd(path);

    const oddIds = betslipVars().oddIds.reduce<string[]>((acc, oddId) => {
        const { sportEventId: mathId } = idPathParser.split('Odd', oddId);

        acc.push(mathId === sportEventId ? path : oddId);

        return acc;
    }, []);

    betslipVars({ ...betslipVars(), oddIds });
    localStorage.setItem('betslip.oddIds', JSON.stringify(oddIds));
};

export const addInactiveOddsList = (oddId: string): void => {
    const { inactiveOdds, status } = betslipVars();

    if (status === BetslipStatus.Processing) return;

    betslipVars({ ...betslipVars(), inactiveOdds: [...inactiveOdds, oddId] });
};

export const deleteInactiveOddsList = (oddId: string): void => {
    const { inactiveOdds } = betslipVars();
    betslipVars({
        ...betslipVars(),
        inactiveOdds: inactiveOdds.filter((i) => i !== oddId),
    });
};

export const handleActiveBetslipFreebetId = ({
    freebetId,
    stake,
}: {
    freebetId?: string;
    stake: number | string;
}): void => {
    activeBetslipFreebetIdVar(freebetId);

    saveStake(`${stake}`);

    activeBetslipInsuranceVar(null);
};

export const handleActiveBetslipInsurance = (
    insurance: PlaceBetInsurance | null
): void => {
    activeBetslipInsuranceVar(insurance);

    activeBetslipFreebetIdVar('');
};
