import React from 'react';
import type { LinkProps as NextLinkProps } from 'next/link';

import { IconsPack } from 'components/PackIcon';
import type { IconId } from 'components/PackIcon/PackIcon';

export type LinkIconProps = BaseProps & IconProps & LinkProps;
export type ButtonIconProps = BaseProps & IconProps & ButtonProps;

export type LinkProps = BaseProps & {
    href?: NextLinkProps['href'];
    shallow?: NextLinkProps['shallow'];
};

export type ButtonProps = BaseProps & {
    onClick?: (e: React.MouseEvent) => void | Promise<void>;
    type?: 'button' | 'submit';
};

interface IconProps {
    iconClassName?: string;
    iconId?: IconId;
    pack?: IconsPack;
}

export interface Props extends BaseProps {
    href?: NextLinkProps['href'];
    shallow?: NextLinkProps['shallow'];
    type?: 'button' | 'submit';
    iconClassName?: string;
    iconSize?: number;
}

interface BaseProps {
    children?: React.ReactNode;
    color?: Color;
    size?: Size;
    width?: Width;
    pack?: IconsPack;
    iconId?: IconId;
    iconPosition?: 'left' | 'right';
    iconsList?: JSX.Element | JSX.Element[];
    isDisabled?: boolean;
    isOutlined?: boolean;
    isLoading?: boolean;
    isRounded?: boolean;
    isTransparent?: boolean;
    isTransparentIcon?: boolean;
    className?: string;
    dataCategory?: string;
    dataAction?: string;
    analyticsAttrs?: object;
    dataTest?: string;
    onClick?: (e: React.MouseEvent) => void | Promise<void>;
    classNameText?: string;
}

export enum Color {
    Green = 'is-green',
    Orange = 'is-orange',
    Surface = 'is-surface',
    SurfaceDark = 'is-surface-dark',
    SurfaceMiddle = 'is-surface-middle',
    Turquoise = 'is-turquoise',
    Crimson = 'is-crimson',
    Grey = 'is-grey',
    White = 'is-white',
}

export enum Size {
    ExtraSmall = 'is-extra-small',
    Small = 'is-small',
    Default = 'is-default',
    Medium = 'is-medium',
    Large = 'is-large',
}

export enum Width {
    Min = 'is-min',
    Full = 'is-full',
    Fit = 'is-fit',
}
