export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
    [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    /** Custom scalar type that must always be in ISO 4217 currency formatting. */
    Currency: any;
    /** Custom scalar type for date, where there is no need in time. Must be in `YYYY-MM-DD` format. */
    Date: any;
    ECUPSPaymentMethod: any;
    /**
     * Custom scalar for language notation.
     * Please note that it must implement an [IETF specification](https://en.wikipedia.org/wiki/IETF_language_tag).
     * For example `ru_RU`, `en`, `uk-UA` etc.
     */
    Locale: any;
    /** Custom scalar type that must always be in RFC3339 time formatting. */
    Time: any;
    Upload: any;
};

export enum ActionType {
    AcceptBet = 'ACCEPT_BET',
    DeclineBet = 'DECLINE_BET',
    PlaceBet = 'PLACE_BET',
    SettleLoss = 'SETTLE_LOSS',
    SettleRefund = 'SETTLE_REFUND',
    SettleWin = 'SETTLE_WIN',
    Unsettle = 'UNSETTLE',
}

export type AddIdentificationInput = {
    documentType: ProfileIdentificationDocument;
    expiresAt: Scalars['Time'];
    issuedOn: Scalars['Time'];
    issuer: IdentificationIssuerInput;
    number: Scalars['String'];
    series: Scalars['String'];
};

/** Be sure that `from, to` fields are formatted to RFC3339. */
export type AuthJournalInput = {
    brandID: Scalars['Int'];
    from: Scalars['Time'];
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    playerID: Scalars['String'];
    to: Scalars['Time'];
};

export enum BalanceType {
    Bonus = 'BONUS',
    Daily = 'DAILY',
    Free = 'FREE',
    Immune = 'IMMUNE',
    OnlyWagering = 'ONLY_WAGERING',
    Real = 'REAL',
}

export enum BetType {
    Fault = 'FAULT',
    Free = 'FREE',
    Paid = 'PAID',
}

export enum BonusBalanceFeatureType {
    BetAmount = 'bet_amount',
    MaxTransfer = 'max_transfer',
    OnlyWagering = 'only_wagering',
    PartialTransfer = 'partial_transfer',
    PayoutImmune = 'payout_immune',
    WageringAmount = 'wagering_amount',
    WageringCoefficient = 'wagering_coefficient',
    WageringStrategy = 'wagering_strategy',
}

export enum BonusPlayerStatus {
    Canceled = 'canceled',
    Completed = 'completed',
    Expired = 'expired',
    Failed = 'failed',
    Processing = 'processing',
}

export enum BonusRewardType {
    Action = 'action',
    BonusBalance = 'bonus_balance',
    FreeSpinsBetsoft = 'free_spins_betsoft',
    FreeSpinsHabanero = 'free_spins_habanero',
    FreeSpinsHhs = 'free_spins_hhs',
    FreeSpinsMicrogaming = 'free_spins_microgaming',
    FreeSpinsNetent = 'free_spins_netent',
    FreeSpinsPlayngo = 'free_spins_playngo',
    FreeSpinsYggdrasil = 'free_spins_yggdrasil',
    LoyaltyMultiplier = 'loyalty_multiplier',
    LoyaltyPoints = 'loyalty_points',
    RealBalance = 'real_balance',
    ScratchLottery = 'scratch_lottery',
    WheelOfFortune = 'wheel_of_fortune',
}

export enum BonusTaskBettingBetDetailsType {
    Express = 'express',
    Single = 'single',
    System = 'system',
}

export enum BonusTaskDataStatus {
    Completed = 'completed',
    Expired = 'expired',
    Failed = 'failed',
    Processing = 'processing',
}

export enum BonusTaskTimeFramePeriodType {
    Day = 'day',
    Month = 'month',
    Week = 'week',
}

export enum BonusTaskTimeFrameType {
    Fixed = 'fixed',
    Interval = 'interval',
    Period = 'period',
}

export enum BonusTaskType {
    Bet = 'bet',
    Betting = 'betting',
    Composite = 'composite',
    ConfirmEmail = 'confirm_email',
    Deposit = 'deposit',
    Manual = 'manual',
    Win = 'win',
}

export type ChangeLocaleInput = {
    locale: Scalars['Locale'];
};

export type CheckEsiaIdentificationInput = {
    token: Scalars['String'];
};

export type ConfirmPhoneSmsVerificationInput = {
    confirmationCode: Scalars['String'];
    phone: Scalars['String'];
};

export enum CredentialsType {
    Email = 'EMAIL',
    Login = 'LOGIN',
    Multi = 'MULTI',
    Phone = 'PHONE',
    Social = 'SOCIAL',
    Token = 'TOKEN',
}

export enum DepositTaskPaymentMethodAction {
    Exclude = 'exclude',
    Include = 'include',
}

export enum EcupsIdentificationBindingStatus {
    Failed = 'FAILED',
    InProcess = 'IN_PROCESS',
    Success = 'SUCCESS',
}

export enum EcupsIdentificationRequestStatus {
    Fail = 'FAIL',
    InProcess = 'IN_PROCESS',
    Success = 'SUCCESS',
}

export type EcupsPlayerBindInput = {
    birthDate: Scalars['Date'];
    phone: Scalars['String'];
};

export type EcupsStartSimpleIdentificationInput = {
    birthDate: Scalars['Date'];
    firstName: Scalars['String'];
    inn: Scalars['String'];
    lastName: Scalars['String'];
    passportNumber: Scalars['String'];
    passportSeries: Scalars['String'];
    /** `patronymic` field is not required, empty string is passed by default */
    patronymic?: Scalars['String'];
};

export enum EcupsVerificationState {
    Alternative = 'ALTERNATIVE',
    Full = 'FULL',
    Simple = 'SIMPLE',
    Unknown = 'UNKNOWN',
}

export type GameActionsInput = {
    brandID: Scalars['Int'];
    from: Scalars['Time'];
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    playerID: Scalars['String'];
    to: Scalars['Time'];
};

export type GameDemoUrlInput = {
    extraData?: Array<GameUrlExtraDataElement>;
    gameID: Scalars['String'];
    license?: Scalars['String'];
    locale?: Scalars['String'];
};

export type GameRealUrlInput = {
    extraData?: Array<GameUrlExtraDataElement>;
    gameID: Scalars['String'];
    locale?: Scalars['String'];
};

export type GameUrlExtraDataElement = {
    Key: Scalars['String'];
    Value: Scalars['String'];
};

export enum Gender {
    Female = 'female',
    Male = 'male',
    Other = 'other',
    Unknown = 'unknown',
}

export type GetTournamentsInput = {
    limit?: Scalars['Int'];
    offset?: Scalars['Int'];
};

export type IdentificationIssuerInput = {
    code: Scalars['String'];
    country: Scalars['String'];
    name: Scalars['String'];
};

export type InternalPlayerBalancesInput = {
    brandID: Scalars['Int'];
    playerID: Scalars['String'];
};

export type InternalPlayerStatisticsInput = {
    brandID: Scalars['Int'];
    playerID: Scalars['String'];
};

export type InternalPlayerTransactionsInput = {
    brandID: Scalars['Int'];
    playerID: Scalars['String'];
};

export enum KycDocumentStatus {
    Approved = 'approved',
    Declined = 'declined',
    Undefined = 'undefined',
}

export enum KycDocumentType {
    AddressProof = 'address_proof',
    DrivingLicence = 'driving_licence',
    IdPass = 'id_pass',
    NationalIdentityCard = 'national_identity_card',
    Other = 'other',
    Passport = 'passport',
    PaymentMethod = 'payment_method',
}

export enum KycStatus {
    ApprovedFull = 'APPROVED_FULL',
    ApprovedSimple = 'APPROVED_SIMPLE',
    AwaitingApproval = 'AWAITING_APPROVAL',
    Declined = 'DECLINED',
    Unknown = 'UNKNOWN',
}

export enum License {
    Cps2 = 'cps2',
    General = 'general',
}

export enum LimitType {
    ActivityTime = 'ACTIVITY_TIME',
    BreakTime = 'BREAK_TIME',
    Deposit = 'DEPOSIT',
    GamingTime = 'GAMING_TIME',
    Losses = 'LOSSES',
    Wager = 'WAGER',
}

export enum LiveContactSchema {
    Discord = 'discord',
    Skype = 'skype',
    Telegram = 'telegram',
    Viber = 'viber',
    Whatsapp = 'whatsapp',
}

export enum OddMatchStrategy {
    All = 'all',
    LeastOne = 'least_one',
}

export enum PaymentLinkType {
    Iframe = 'iframe',
    Local = 'local',
    Multiple = 'multiple',
    Sms = 'sms',
    Url = 'url',
}

export type PaymentNewTransactionFields = {
    amount: Scalars['String'];
    balanceID: Scalars['Int'];
    currency: Scalars['String'];
    layoutTheme?: InputMaybe<Scalars['String']>;
    /**
     * optionType is an optional field for choosing the response type:
     * If METHOD is chosen, a list of payment methods by chosen system will be returned.
     * If SYSTEM is chosen, an iframe link with all available payment methods will be returned.
     *
     * By default a METHOD option is chosen.
     */
    optionType?: InputMaybe<PaymentOptionType>;
    paySystem: Scalars['String'];
    /** Redirect URLs are not mandatory and used for iFrame systems. See `GINBE-6581` for more details. */
    redirectBackURL?: InputMaybe<Scalars['String']>;
    redirectFailURL?: InputMaybe<Scalars['String']>;
    redirectProgressURL?: InputMaybe<Scalars['String']>;
    redirectSuccessURL?: InputMaybe<Scalars['String']>;
    refCode?: InputMaybe<Scalars['String']>;
    requisites?: InputMaybe<PaymentRequisitesFields>;
    systemName: Scalars['String'];
};

export enum PaymentOptionType {
    Method = 'METHOD',
    System = 'SYSTEM',
}

export type PaymentRequisitesFields = {
    account?: InputMaybe<Scalars['String']>;
    address?: InputMaybe<Scalars['String']>;
    card?: InputMaybe<Scalars['String']>;
    cardMasked?: InputMaybe<Scalars['String']>;
    cardMonth?: InputMaybe<Scalars['String']>;
    cardNumber?: InputMaybe<Scalars['String']>;
    cardYear?: InputMaybe<Scalars['String']>;
    email?: InputMaybe<Scalars['String']>;
    fname?: InputMaybe<Scalars['String']>;
    lname?: InputMaybe<Scalars['String']>;
    mname?: InputMaybe<Scalars['String']>;
    passport?: InputMaybe<Scalars['String']>;
    phone?: InputMaybe<Scalars['String']>;
    purse?: InputMaybe<Scalars['String']>;
    seconddocnumber?: InputMaybe<Scalars['String']>;
};

export type PlayerBonusHistoryListInput = {
    limit?: Scalars['Int'];
    offset?: Scalars['Int'];
    templateIDs?: InputMaybe<Array<Scalars['String']>>;
};

export type PlayerCredentialsInput = {
    brandID: Scalars['Int'];
    credentialsType?: InputMaybe<CredentialsType>;
    playerID: Scalars['String'];
};

export type PlayerRangesInput = {
    brandID: Scalars['Int'];
};

export type PlayersByRangeInput = {
    brandID: Scalars['Int'];
    from: Scalars['String'];
    to: Scalars['String'];
};

export type ProfileAddressInput = {
    city?: InputMaybe<Scalars['String']>;
    country?: InputMaybe<Scalars['String']>;
    street?: InputMaybe<Scalars['String']>;
};

export enum ProfileIdentificationDocument {
    Immigration = 'IMMIGRATION',
    Passport = 'PASSPORT',
    Residence = 'RESIDENCE',
    Temporary = 'TEMPORARY',
    Visa = 'VISA',
}

export type ProfileInput = {
    brandID: Scalars['Int'];
    playerID: Scalars['String'];
};

export type ProfileResidenceInput = {
    building?: InputMaybe<Scalars['String']>;
    city?: InputMaybe<Scalars['String']>;
    flat?: InputMaybe<Scalars['String']>;
    house?: InputMaybe<Scalars['String']>;
    region?: InputMaybe<Scalars['String']>;
    street?: InputMaybe<Scalars['String']>;
    zipCode?: InputMaybe<Scalars['String']>;
};

export type ProfileUpdate = {
    address?: InputMaybe<ProfileAddressInput>;
    citizenship?: InputMaybe<ProfileUpdateCitizenship>;
    dob?: InputMaybe<Scalars['Date']>;
    email?: InputMaybe<Scalars['String']>;
    emailObj?: InputMaybe<ProfileUpdateEmail>;
    emails?: InputMaybe<Scalars['String']>;
    gender?: InputMaybe<Gender>;
    liveContact?: InputMaybe<ProfileUpdateLiveContact>;
    name?: InputMaybe<ProfileUpdateName>;
    passport?: InputMaybe<ProfileUpdatePassport>;
    phones?: InputMaybe<Array<InputMaybe<ProfileUpdatePhone>>>;
    residence?: InputMaybe<ProfileResidenceInput>;
};

export type ProfileUpdateCitizenship = {
    country: Scalars['String'];
};

export type ProfileUpdateEmail = {
    email: Scalars['String'];
    optOut?: Scalars['Boolean'];
};

export type ProfileUpdateLiveContact = {
    login: Scalars['String'];
    /**  Please note that date must be in RFC3339 format.  */
    preferredCallDate: Scalars['String'];
    system: LiveContactSchema;
};

export type ProfileUpdateName = {
    first: Scalars['String'];
    last: Scalars['String'];
    middle?: InputMaybe<Scalars['String']>;
    patronymic: Scalars['String'];
};

export type ProfileUpdatePassport = {
    country?: InputMaybe<Scalars['String']>;
    number: Scalars['String'];
    series: Scalars['String'];
};

export type ProfileUpdatePhone = {
    isPrimary: Scalars['Boolean'];
    phone: Scalars['String'];
};

export enum Role {
    Internal = 'INTERNAL',
    User = 'USER',
}

export enum SourceId {
    DesktopWebsite = 'desktop_website',
    MobileAndroid = 'mobile_android',
    MobileIos = 'mobile_ios',
    MobileWebsite = 'mobile_website',
}

export type StartEsiaIdentificationInput = {
    redirectURL: Scalars['String'];
};

export type StartPhoneSmsVerificationInput = {
    phone: Scalars['String'];
};

export type StartSimpleIdentificationInput = {
    birthDate: Scalars['String'];
    firstName: Scalars['String'];
    inn: Scalars['String'];
    lastName: Scalars['String'];
    passportNumber: Scalars['String'];
    passportSeries: Scalars['String'];
    patronymic: Scalars['String'];
};

export enum TransactionStatus {
    Finished = 'finished',
    Fresh = 'fresh',
    LocalCanceled = 'local_canceled',
    LocalConfirmed = 'local_confirmed',
    LocalProcessing = 'local_processing',
    Manual = 'manual',
    New = 'new',
    PlayerConfirm = 'player_confirm',
    PlayerPending = 'player_pending',
    Rejected = 'rejected',
    RemoteCanceled = 'remote_canceled',
    RemoteConfirmed = 'remote_confirmed',
    RemoteProcessing = 'remote_processing',
    Unregistered = 'unregistered',
}

export enum TransactionType {
    Deposit = 'deposit',
    Payout = 'payout',
}

export type CreateFirebaseTokenInput = {
    /**  Optional App name.  */
    appName?: InputMaybe<Scalars['String']>;
    /**  Unique device fingerprint.  */
    deviceID: Scalars['String'];
    /**  Registration token.  */
    registrationToken: Scalars['String'];
    /**  User agent must be passed explicitly.  */
    userAgent: Scalars['String'];
};

export type IsConfirmedKycInput = {
    brandID: Scalars['Int'];
    playerID: Scalars['String'];
};

export type PlayerBetsFilters = {
    createdFrom?: InputMaybe<Scalars['Time']>;
    createdTo?: InputMaybe<Scalars['Time']>;
    type?: InputMaybe<BetType>;
};

export type PlayerBetsInput = {
    brandID: Scalars['Int'];
    filters?: InputMaybe<PlayerBetsFilters>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    playerID: Scalars['String'];
};

export type PlayerLimitsInput = {
    limit?: InputMaybe<Scalars['Int']>;
    limitType?: InputMaybe<LimitType>;
    offset?: InputMaybe<Scalars['Int']>;
};

export type OnNewMessagesVariables = Exact<{ [key: string]: never }>;

export type OnNewMessages = {
    __typename?: 'Subscription';
    newMessages?: { __typename?: 'MessageNotification'; id: string } | null;
};

export type GetTempRequestVariables = Exact<{ [key: string]: never }>;

export type GetTempRequest = {
    __typename?: 'Query';
    getMessageInfo: { __typename?: 'MessageInfo'; position: number };
};

export type OnKycChangeDocumentRequiredStatusVariables = Exact<{
    [key: string]: never;
}>;

export type OnKycChangeDocumentRequiredStatus = {
    __typename?: 'Subscription';
    KYCChangeDocumentRequiredStatus: {
        __typename?: 'kycChangeDocumentRequiredStatus';
        status: string;
    };
};

export type OnKycConfirmStatusVariables = Exact<{ [key: string]: never }>;

export type OnKycConfirmStatus = {
    __typename?: 'Subscription';
    KYCConfirmStatus: {
        __typename?: 'kycConfirmStatusSubPayload';
        previousStatus: KycStatus;
        newStatus: KycStatus;
    };
};

export type OnKycaidChangeVerificationStatusVariables = Exact<{
    [key: string]: never;
}>;

export type OnKycaidChangeVerificationStatus = {
    __typename?: 'Subscription';
    KycaidChangeVerificationStatus: {
        __typename?: 'kycaidChangeVerificationStatus';
        status: string;
    };
};

export type OnProgramActionVariables = Exact<{ [key: string]: never }>;

export type OnProgramAction = {
    __typename?: 'Subscription';
    programAction:
        | { __typename?: 'ProgramChangeAction'; templateID: string }
        | { __typename?: 'ProgramCreateAction'; templateID: string }
        | { __typename?: 'ProgramExpireAction'; templateID: string }
        | { __typename?: 'ProgramFinishAction'; templateID: string };
};

export type OnSignOutVariables = Exact<{ [key: string]: never }>;

export type OnSignOut = { __typename?: 'Subscription'; signOut: boolean };

export type OnTournamentLeaderboardUpdateVariables = Exact<{
    [key: string]: never;
}>;

export type OnTournamentLeaderboardUpdate = {
    __typename?: 'Subscription';
    TournamentLeaderboardUpdate?: {
        __typename?: 'TournamentLeaderboardUpdatePayload';
        prizePool?: {
            __typename?: 'TournamentPrizePool';
            brandID: number;
            tournamentID: string;
        } | null;
        participants?: Array<{
            __typename?: 'TournamentParticipant';
            tournamentID: string;
            currencyCode: string;
            displayName: string;
            qualified: boolean;
            totalBetAmount: string;
            totalWinAmount: string;
            totalBetCount: number;
            totalWinCount: number;
            lastGameID: string;
            playerID: string;
            points: string;
        } | null> | null;
    } | null;
};

export type OnTournamentParticipantUpdateVariables = Exact<{
    [key: string]: never;
}>;

export type OnTournamentParticipantUpdate = {
    __typename?: 'Subscription';
    TournamentParticipantUpdate: {
        __typename?: 'TournamentParticipantUpdatePayload';
        tournamentID: string;
        displayName: string;
        points: string;
        currencyCode: string;
        qualified: boolean;
        totalBetAmount: string;
        totalWinAmount: string;
        totalBetCount: number;
        totalWinCount: number;
        lastGameID: string;
    };
};

export type OnUpdateBonusTransferVariables = Exact<{ [key: string]: never }>;

export type OnUpdateBonusTransfer = {
    __typename?: 'Subscription';
    bonusTransfer: { __typename?: 'BonusTransfer'; playerID: number };
};

export type OnUpdateChangeBalanceVariables = Exact<{ [key: string]: never }>;

export type OnUpdateChangeBalance = {
    __typename?: 'Subscription';
    changeBalance: {
        __typename?: 'BalanceChangedPayload';
        id: number;
        balance: number;
        refundAfterAmount: number;
        typeId: BalanceType;
        refundSum: number;
    };
};

export type OnUpdateFreeSpinsVariables = Exact<{ [key: string]: never }>;

export type OnUpdateFreeSpins = {
    __typename?: 'Subscription';
    freespins?: {
        __typename?: 'FreespinsCreatedPayload';
        numberSpins: number;
        gamesIDs: Array<string>;
    } | null;
};

export type OnUpdateProgramActionVariables = Exact<{ [key: string]: never }>;

export type OnUpdateProgramAction = {
    __typename?: 'Subscription';
    programAction:
        | {
              __typename?: 'ProgramChangeAction';
              playerID: string;
              templateID: string;
          }
        | {
              __typename?: 'ProgramCreateAction';
              playerID: string;
              templateID: string;
          }
        | {
              __typename?: 'ProgramExpireAction';
              playerID: string;
              templateID: string;
          }
        | {
              __typename?: 'ProgramFinishAction';
              playerID: string;
              templateID: string;
          };
};

export type OnUpdateRechargeBalanceVariables = Exact<{ [key: string]: never }>;

export type OnUpdateRechargeBalance = {
    __typename?: 'Subscription';
    rechargeBalance: {
        __typename?: 'PlayerBalanceRechargeResult';
        id: string;
        currencyCode: string;
        sum: number;
    };
};
