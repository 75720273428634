import { createContext, useContext } from 'react';
import type { GetServerSidePageData, PageProps } from 'types';

const PagePropsContext = createContext<PageProps>({} as PageProps);

const usePagePropsContext = (): PageProps => useContext(PagePropsContext);

const useGsspData = (): GetServerSidePageData => {
    const { gsspData } = usePagePropsContext();

    return gsspData || {};
};

export { PagePropsContext, useGsspData, usePagePropsContext };
