import { useEffect, useState } from 'react';
import cn from 'classnames';

import useDebugInfo from './useDebugInfo';
import { isDebugEnabled } from './utils';

interface Props {
    text: string | undefined;
    className?: string;
}

const DebugInfo: React.FC<Props> = ({ text, className }) => {
    const { isCopied, onClickHandler } = useDebugInfo({ text });

    const [isEnable, setIsEnable] = useState<boolean>(false);

    useEffect(() => {
        setIsEnable(isDebugEnabled());
    }, []);

    if (!isEnable || !text) return null;

    return (
        <div
            className={cn(
                'absolute right-0 top-0 whitespace-nowrap',
                className
            )}
        >
            <div
                onClick={onClickHandler}
                title="Copy to clipboard"
                className="text-red-500 typo-sm-2"
            >
                {text}
            </div>
            {isCopied && <div>Copied to clipboard!</div>}
        </div>
    );
};

export default DebugInfo;
