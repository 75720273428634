import { BANNED_COUNTRY, phones } from 'components/Forms/LoginInput/Phone';

export const bannedCountryValidate = (value: string): boolean => {
    const normalizeNumber = value?.replace(/\D/g, '');

    const findPhoneData = phones.find(({ phone, countryCode }) => {
        if (countryCode === 'WW') return;

        const normalizeCode = phone.code.replace(/\D/g, '');

        const countryRegex = phone.regex || `^${normalizeCode}`;
        const regex = new RegExp(countryRegex, 'g');

        return regex.test(normalizeNumber);
    });

    return Boolean(
        findPhoneData && BANNED_COUNTRY.includes(findPhoneData?.countryCode)
    );
};
