import { useGetGeoIpLocation } from 'gql/cms/queries/getGeoIpLocation.cms';

type Output = { isoCode: string };

const useCountryByUserIp = (): Output => {
    const { data } = useGetGeoIpLocation();

    return {
        isoCode: data?.geoIpLocation.countryIsoCode ?? '',
    };
};

export default useCountryByUserIp;
