import { useCallback } from 'react';
import isPast from 'date-fns/isPast';

interface Output {
    checkIsExpiredDate: (date: Date) => boolean;
}

const useCheckExpiredDate = (): Output => {
    const checkIsExpiredDate = useCallback((date: Date) => {
        if (!date) return false;

        return isPast(new Date(date));
    }, []);

    return { checkIsExpiredDate };
};

export default useCheckExpiredDate;
