import { FC, memo } from 'react';
import { useTranslations } from 'next-intl';

import { AppLink } from 'app-constants';
import PackIcon, { IconsPack } from 'components/PackIcon';
import BalanceItem from '../BalanceItem';
import type { Freebets } from '../types';

interface Props {
    currency: string;
    freebets: Freebets;
    dataTest?: string;
}

const FreebetsItem: FC<Props> = ({ currency, freebets, dataTest }) => {
    const t = useTranslations('Balance');

    if (!freebets.length) return null;

    return (
        <>
            {freebets.length <= 2 &&
                freebets.map((freebet) => {
                    return (
                        <BalanceItem
                            key={freebet.id}
                            title={t('gincms.header_balance.freebet')}
                            href={AppLink.FREEBETS}
                            classNames={{
                                title: 'text-turquoise-700',
                                balance: 'text-turquoise-700',
                            }}
                            leftIcon={
                                <PackIcon
                                    id="promo-freebets"
                                    pack={IconsPack.SpriteIcons}
                                    className="fill-turquoise-700"
                                />
                            }
                            balance={freebet.amount}
                            currency={currency}
                            dataTest={dataTest}
                        />
                    );
                })}

            {freebets.length > 2 && (
                <BalanceItem
                    title={t('gincms.header_balance.freebet')}
                    href={AppLink.FREEBETS}
                    classNames={{
                        title: 'text-turquoise-700',
                    }}
                    leftIcon={
                        <PackIcon
                            id="promo-freebets"
                            pack={IconsPack.SpriteIcons}
                            className="fill-turquoise-700"
                        />
                    }
                    rightIcon={
                        <div className="h-4 w-4 rounded-full bg-turquoise-700 text-center text-surface-dark">
                            {freebets.length}
                        </div>
                    }
                    dataTest={dataTest}
                />
            )}
        </>
    );
};

export default memo(FreebetsItem);
