import { useMemo } from 'react';

import { useGetProcessingBonusProgram } from 'gql/cms/queries/bonus-program/getProcessingBonusProgram.cms';
import { GetProcessingBonusProgram } from 'types/gql.cms';
import useAuthorization from './useAuthorization';

interface Input {
    category: 'betting' | 'casino';
    displayIn?: string;
}

export interface Output {
    activeBonusPrograms: GetProcessingBonusProgram['bonusProgramsFullProcessing'];
    inactiveProcessingBonusPrograms: GetProcessingBonusProgram['bonusProgramsFullProcessing'];
    loading: boolean;
    refetch: ReturnType<typeof useGetProcessingBonusProgram>['refetch'];
}

const useProcessingBonusProgram = ({
    category,
    displayIn = 'profile',
}: Input): Output => {
    const { isRegistrationFinished } = useAuthorization();

    const { data, refetch, loading } = useGetProcessingBonusProgram(() => ({
        variables: {
            filter: {
                category,
                displayInType: displayIn,
            },
        },

        skip: !isRegistrationFinished,
    }));

    const inactiveProcessingBonusPrograms = useMemo(() => {
        return data?.bonusProgramsFullProcessing.filter(
            (bonus) =>
                bonus.type === 'processing' &&
                !(
                    bonus.bonusProgramData.active ||
                    !bonus.bonusProgramData.allowedSetActive
                )
        );
    }, [data]);

    const activeBonusPrograms = useMemo(() => {
        return data?.bonusProgramsFullProcessing.filter(
            (bonus) =>
                bonus.type === 'processing' &&
                (bonus.bonusProgramData.active ||
                    !bonus.bonusProgramData.allowedSetActive)
        );
    }, [data]);

    return {
        activeBonusPrograms: activeBonusPrograms || [],
        inactiveProcessingBonusPrograms: inactiveProcessingBonusPrograms || [],
        refetch,
        loading: loading || (!loading && !data),
    };
};

export default useProcessingBonusProgram;
