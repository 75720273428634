import merge from 'deepmerge';

function mergeData(caches: object[]): object {
    return merge.all(caches, {
        // TODO need to watch how work with replace array. Because now add extra items
        // arrayMerge: (destinationArray, sourceArray) => [
        //     ...sourceArray,
        //     ...destinationArray.filter((d) =>
        //         sourceArray.every((s) => !isEqual(d, s))
        //     ),
        // ],
        arrayMerge: (_, sourceArray) => sourceArray,
    });
}

export default mergeData;
