import { SyntheticEvent, useCallback, useState } from 'react';
import { fillLink } from 'cl-fill-link';

import { useRouter } from 'components/HybridRouter';
import { useLocales, useToggle } from 'hooks';
import { useGetSeoRulesByPage } from 'layouts/MainLayout/getSeoRulesByPage.cms';
import { AppLink } from 'types/applink';
import type { GetSeoRulesByPage } from 'types/gql.cms';

import styles from './footerSeo.module.css';

interface Output {
    pageHeader: string;
    texts: NonNullable<GetSeoRulesByPage['seoRulesByPage']['seo']>['texts'];
    isOpen: boolean;
    toggleSeoBlock: VoidFunction;
    scrollHandler: (e: SyntheticEvent<HTMLDivElement>) => void;
}
const useFooterSeo = (): Output | null => {
    const [isOpen, toggleSeoBlock] = useToggle(true);
    const { pathname, query } = useRouter();
    const { activeLocale } = useLocales();
    const [isFullyScrolled, setIsFullyScrolled] = useState<boolean>(false);

    const link = fillLink(pathname, query);
    const pageUrl = `/${activeLocale}${link === AppLink.ROOT ? '' : link}`;

    const scrollHandler = useCallback(
        (e: React.SyntheticEvent<HTMLDivElement>) => {
            const element = e.target as HTMLDivElement;

            if (!element) return;

            if (isFullyScrolled) {
                element.classList.remove(styles['fully-scrolled']);
                setIsFullyScrolled(false);

                return;
            }

            if (
                element.scrollTop + element.clientHeight >=
                element.scrollHeight
            ) {
                element.classList.add(styles['fully-scrolled']);
                setIsFullyScrolled(true);
            }
        },
        [isFullyScrolled]
    );

    const { data } = useGetSeoRulesByPage(() => ({
        fetchPolicy: 'cache-only',
        variables: {
            pageUrl,
        },
        pagePropsKey: 'seoRulesByPage',
    }));

    const dataForRender = data?.seoRulesByPage.seo;

    if (!dataForRender) return null;

    const { pageHeader, texts } = dataForRender;

    return {
        pageHeader,
        texts,
        toggleSeoBlock,
        isOpen,
        scrollHandler,
    };
};

export default useFooterSeo;
