/* eslint-disable @typescript-eslint/no-var-requires,global-require */

import {
    ApolloLink,
    DefaultContext,
    FetchResult,
    Operation,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import format from 'date-fns/format';
import type {
    DocumentNode,
    GraphQLError,
    OperationDefinitionNode,
} from 'graphql';
import { omit } from 'lodash';
import { GetServerSidePropsContext } from 'next';

import { captureSentryException } from 'services/sentry';
import { REQUEST_ID_HEADER } from '../../../constants';
import { logError, NetworkError, nodeLogger } from '../../../logger';
import { isAuthSessionGraphQL } from '../expiredTokenLink/utils/isAuthSessionGraphQL';
import { isAuthSessionNetworkError } from '../expiredTokenLink/utils/isAuthSessionNetworkError';
import isExcludeError from './isExcludeError';

const excludeHeaders = ['cookie', 'x-paseto', 'x-auth-token'];

const errorLoggerLink = onError(
    ({ graphQLErrors, networkError, operation }) => {
        const { operationName } = operation;
        const context = operation.getContext();
        const requestId = getRequestIdFromContext(context);

        const { LOG_HEADERS_ON_ERROR } = process.env;

        const request = {
            host: context.response?.url,
            queryString: operation.query?.loc?.source?.body,
            variables: operation.variables,
            headers: { [REQUEST_ID_HEADER]: requestId },
        };

        if (LOG_HEADERS_ON_ERROR && +LOG_HEADERS_ON_ERROR) {
            request.headers = {
                ...request.headers,
                ...omit(context.headers, excludeHeaders),
            };
        }

        if (graphQLErrors) {
            const isAuthError =
                isAuthSessionGraphQL(graphQLErrors, operationName, requestId) ||
                isAuthSessionNetworkError(networkError) ||
                graphQLErrors.some((e) => e.message === 'unauthorized');

            const skipErrorMessages: string[] =
                context.skipGQLErrorCapturing?.messages || [];

            graphQLErrors.forEach((err) => {
                logError<GraphQLError>({
                    message: `GraphQL error in "${operationName}": ${err.message} \n`,
                    err,
                    request,
                });

                captureSentryException({
                    label: 'GQL Error',
                    message: `GraphQL error in "${operationName}"`,
                    additionalData: request,
                    skip:
                        skipErrorMessages.includes(err.message) || isAuthError,
                });
            });
        }

        if (networkError && !context.isNetworkErrorHidden) {
            const statusCode =
                'statusCode' in networkError ? networkError.statusCode : null;
            const label = statusCode ? `${statusCode}` : 'Network Error';

            const errorResult = {
                networkError,
                request,
            };

            if (isExcludeError(networkError)) {
                nodeLogger.info?.(
                    `Network warning in "${operationName}": ${networkError.message} \n`,
                    errorResult
                );

                return;
            }

            const skipCondition = statusCode
                ? statusCode >= 300 && statusCode < 400
                : false;

            captureSentryException({
                label,
                message: networkError.message,
                additionalData: request,
                skip: skipCondition,
            });

            logError<NetworkError>({
                message: `Network error in "${operationName}": ${networkError.message} \n`,
                err: errorResult,
                request,
            });
        }
    }
);

const getResultSize = (result: FetchResult): string => {
    const bytes = new TextEncoder().encode(JSON.stringify(result)).length;

    return `${(bytes / 1024).toFixed(2)} Kb`;
};

const makeLogLink = (req?: GetServerSidePropsContext['req']) =>
    new ApolloLink((operation, forward) => {
        const startTime = new Date().getTime();
        const formattedStartTime = format(new Date(), 'hh:mm:ss:SSS');

        const operationQuery = operation?.query;

        return forward(operation).map((result) => {
            const operationType = (
                operationQuery?.definitions[0] as OperationDefinitionNode
            ).operation;

            const elapsed = new Date().getTime() - startTime;
            const context = operation.getContext();
            const requestId = getRequestIdFromContext(context);
            const size = getResultSize(result);
            const group = formatMessage(
                operationType,
                operation,
                elapsed,
                size,
                formattedStartTime,
                requestId
            );
            const loggingInfo = {
                group,
                operation,
                operationQuery,
                result,
            };

            // Tree shaking required because Node.js API used inside block statement
            if (typeof window === 'undefined') {
                const { operationName, variables } = operation;
                // const operationMessage = `apollo ${operationType} ${operationName} (${formattedStartTime} in ${elapsed} ms) ${size} requestId: ${requestId}`;
                nodeLogger.info({
                    operationType,
                    operationName,
                    variables,
                    requestTime: `${elapsed} ms`,
                    requestId,
                    user_agent: req?.headers['user-agent'],
                });
                // nodeLogger.debug({ variables });

                if (process.env.NEXT_RUNTIME !== 'edge') {
                    logOperationInfo(
                        makeLogging(require('node:inspector').console),
                        loggingInfo
                    );
                }
            } else {
                logOperationInfo(makeLogging(window.console), loggingInfo);
            }

            return result;
        });
    });

const logOperationInfo = (
    logging: ReturnType<typeof makeLogging>,
    info: {
        group: string[];
        operation: Operation;
        operationQuery: DocumentNode;
        result: FetchResult;
    }
) => {
    const { group, operation, operationQuery, result } = info;

    logging.groupCollapsed(...group);
    logging.log('INIT', {
        ...operation,
        context: operation.getContext(),
        queryString: operationQuery?.loc?.source.body || '',
    });
    logging.log('RESULT', result);
    logging.groupEnd(...group);
};

const bindToConsole = (
    consoleMethod: Function | undefined,
    polyfill: Function
) => {
    return consoleMethod ? consoleMethod.bind(console) : polyfill;
};

interface LoggingConsole {
    log: Function;
    error: Function;
    info?: Function;
    group?: Function;
    groupCollapsed?: Function;
    groupEnd?: Function;
}

const makeLogging = (console: LoggingConsole) => {
    let prefix = '';

    const consoleLog = (...args: any[]) => {
        console.log(prefix, ...args);
    };

    const consoleError = (...args: any[]) => {
        console.error(prefix, ...args);
    };

    const consoleGroup = (...args: any[]) => {
        consoleLog(...args);
        prefix += '> ';
    };

    const consoleGroupEnd = () => {
        prefix = prefix.slice(0, -2);
    };

    return {
        log: consoleLog,
        error: consoleError,
        group: bindToConsole(console.group, consoleGroup),
        groupCollapsed: bindToConsole(console.groupCollapsed, consoleGroup),
        groupEnd: bindToConsole(console.groupEnd, consoleGroupEnd),
    };
};

const formatMessage = (
    operationType: string,
    operation: Operation,
    elapsed: number,
    size: string,
    startTime: string,
    requestId?: string
) => {
    const headerCss = [
        'color: gray; font-weight: lighter', // title
        `color: ${operationType === 'query' ? '#03A9F4' : 'red'}; `, // operationType
        'color: inherit;', // operationName
    ];

    const parts = [
        '%c apollo',
        `%c${operationType} `,
        `%c${operation.operationName} `,
    ];

    if (operationType !== 'subscription') {
        parts.push(`%c(${startTime} in ${elapsed} ms) ${size} `);

        if (requestId) {
            parts.push(`requestId: ${requestId} `);
        }

        headerCss.push('color: gray; font-weight: lighter;'); // time and size
    }

    return [parts.join(' '), ...headerCss];
};

const makeLoggerLink = (
    isLogger: boolean,
    req?: GetServerSidePropsContext['req']
): ApolloLink =>
    ApolloLink.from([errorLoggerLink, ...(isLogger ? [makeLogLink(req)] : [])]);

const getRequestIdFromContext = (context: DefaultContext) => {
    const headersResponse = context?.response?.headers || null;
    const headersRequest = context?.headers || null;

    return `${headersRequest && headersRequest[REQUEST_ID_HEADER]},${
        headersResponse && headersResponse?.get(REQUEST_ID_HEADER)
    }`;
};

export default makeLoggerLink;
