import * as GQL from 'types/gql.ptm';

import * as Operations from './onSignOut.ptm.gql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'services/apollo/platform';
const defaultOptions = {} as const;

/**
 * __useOnSignOut__
 *
 * To run a query within a React component, call `useOnSignOut` and pass it any options that fit your needs.
 * When your component renders, `useOnSignOut` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnSignOut({
 *   variables: {
 *   },
 * });
 */
export function useOnSignOut(
    baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
        GQL.OnSignOut,
        GQL.OnSignOutVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSubscription<
        GQL.OnSignOut,
        GQL.OnSignOutVariables
    >(Operations.OnSignOut, options);
}
export type OnSignOutHookResult = ReturnType<typeof useOnSignOut>;
export type OnSignOutSubscriptionResult =
    Apollo.SubscriptionResult<GQL.OnSignOut>;
