import { fillLink } from 'cl-fill-link';

import { AppLink } from 'app-constants';
import { useRouter } from 'components/HybridRouter';
import { useLocales } from 'hooks';
import type { GetSeoRulesByPage } from 'types/gql.cms';
import { useGetSeoRulesByPage } from '../getSeoRulesByPage.cms';

interface Output {
    seo?: GetSeoRulesByPage['seoRulesByPage']['seo'];
    info?: GetSeoRulesByPage['seoRulesByPage']['info'];
}
const useSeoTags = (): Output => {
    const { pathname, query } = useRouter();
    const { activeLocale } = useLocales();
    const link = fillLink(pathname, query);
    const pageUrl = `/${activeLocale}${link === AppLink.ROOT ? '' : link}`;

    const { data } = useGetSeoRulesByPage(() => ({
        variables: {
            pageUrl,
        },
        pagePropsKey: 'seoRulesByPage',
    }));

    const seoData = data?.seoRulesByPage;

    return {
        seo: seoData?.seo,
        info: seoData?.info,
    };
};

export default useSeoTags;
