import { SportType } from 'app-constants';
import { SportTag } from '../types/api.bet';

export function getSportTypeBySportTag(
    sportTag: SportTag | undefined
): SportType | undefined {
    if (!sportTag) return undefined;

    return sportTag === SportTag.Classic ? SportType.Sports : SportType.Esports;
}

export default getSportTypeBySportTag;
