import gql from 'graphql-tag';

import { BettingApolloClient } from 'services/apollo';
import { Odd } from 'types/gql.bet';
import idPathParser from '../idPathParser';

export const getOddByFragment = (
    oddId: string,
    client: BettingApolloClient
): Odd | null => {
    return client.cache.readFragment<Odd>({
        fragment: gql`
            fragment OddFragment on Odd {
                value
                path
            }
        `,
        id: idPathParser.addPrefix('Odd', oddId),
        fragmentName: 'OddFragment',
    });
};

export default getOddByFragment;
