import { RefObject, useCallback, useEffect, useRef } from 'react';

import { ModalsHash } from 'app-constants';
import useModal from 'components/modals/useModal';
import { useElementResize } from 'hooks';
import { setVerificationBannerHeight } from '../verificationBannerState';

interface Output {
    bannerRef: RefObject<HTMLDivElement>;
    openKYC: VoidFunction;
}

const headerHeightCssVariable = '--verification-banner-height';
const useVerificationBannerContent = (): Output => {
    const { openModal } = useModal();
    const bannerRef = useRef<HTMLDivElement>(null);

    const { height } = useElementResize({
        ref: bannerRef,
    });

    useEffect(() => {
        setVerificationBannerHeight(height);

        document.documentElement.style.setProperty(
            headerHeightCssVariable,
            `${height}px`
        );
    }, [height]);

    useEffect(() => {
        return () => {
            setVerificationBannerHeight(0);

            document.documentElement.style.setProperty(
                headerHeightCssVariable,
                `${0}px`
            );
        };
    }, []);

    const openKYC = useCallback(() => {
        openModal({ hash: ModalsHash.KYC });
    }, [openModal]);

    return { bannerRef, openKYC };
};

export default useVerificationBannerContent;
