import type { FC } from 'react';

import withServerRender from 'hocs/withServerRender';
import { useIsEnableSSR } from 'layouts/Root/EnableSSRProvider/EnableSSRContext';
import { useLayoutContext } from 'layouts/Root/LayoutTypeContext';
import { WithMainApolloClientContext } from 'services/apollo';
import { GetServerSidePageProps } from 'types';
import { GetPagesUrlBySlug } from 'types/gql.cms';
import { footerInfoLinks } from '../constants';
import { getFooterSupportServerSideProps } from '../FooterSupport';
import { getServerPageGetPagesUrlBySlug } from '../getPagesUrlBySlug.cms';
import usePageGetsUrls from '../usePageUrls';
import FooterInfoLinksContent from './FooterInfoLinksContent';

interface Props {
    pages: GetPagesUrlBySlug['pagesBySlugs'];
}
const FooterInfoLinks: FC<Props> = ({ pages }) => {
    const { mainLayoutType: typePage } = useLayoutContext();
    const isSSREnable = useIsEnableSSR();

    const { data } = usePageGetsUrls({
        typePage,
        skip: isSSREnable ? pages.length > 0 : false,
    });

    const pagesForRender = data?.pagesBySlugs || pages;

    if (!pagesForRender) return null;

    return <FooterInfoLinksContent pages={pagesForRender} />;
};

export const getFooterInfoServerSideProps = [
    async (
        ctx: WithMainApolloClientContext
    ): Promise<GetServerSidePageProps> => {
        const pageUrlsResponse = await getServerPageGetPagesUrlBySlug({
            variables: {
                slugs: footerInfoLinks,
            },
        })(ctx);

        if (!pageUrlsResponse) {
            return {
                props: {},
            };
        }

        return {
            props: {
                gsspData: {
                    footerInfoLinks: pageUrlsResponse,
                },
            },
        };
    },
    ...getFooterSupportServerSideProps,
];

export default withServerRender(FooterInfoLinks, ({ pages }) => (
    <FooterInfoLinksContent pages={pages} />
));
