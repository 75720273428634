import { ModalsHash } from 'app-constants';
import useModal from 'components/modals/useModal';
import getFreeSpins from 'gql/cms/queries/getFreeSpins.cms.gql';
import { useOnUpdateFreeSpins } from 'gql/platform/subscriptions/onUpdateFreeSpins.ptm';
import { useAuthorization } from 'hooks';
import { useLazyQuery } from 'services/apollo/cms';
import { GetFreeSpins, GetFreeSpinsVariables } from 'types/gql.cms';

const useOnUpdateFreeSpinsSubscription = (): void => {
    const { isAuthorized } = useAuthorization();
    const { openModal } = useModal();

    const [fetchFreeSpins] = useLazyQuery<GetFreeSpins, GetFreeSpinsVariables>(
        getFreeSpins,
        { fetchPolicy: 'network-only' }
    );

    useOnUpdateFreeSpins({
        async onData({ data: { data } }) {
            await fetchFreeSpins();

            openModal({ hash: ModalsHash.GamesFreeSpin, data });
        },
        skip: !isAuthorized,
    });
};

export default useOnUpdateFreeSpinsSubscription;
