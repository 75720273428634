import { WithMainApolloClientContext } from 'services/apollo';
import { GetServerSidePageProps } from 'types';
import getActiveLocale from './getActiveLocale';
import { getMessages } from './getMessages';

export const mapLocalesByI18nCodes: { [key: string]: string } = {
    cn: 'zh',
    'en-ca': 'ca',
    'fr-ca': 'fr',
    'uk-ua': 'uk',
    'tl-ph': 'en',
};

export const getServerSideCountriesTranslation = async (
    ctx: WithMainApolloClientContext,
    namespaces: IntlMessagesNamespace[] = []
): Promise<GetServerSidePageProps> => {
    const activeLocale = getActiveLocale(ctx);

    const lang = mapLocalesByI18nCodes[activeLocale] || activeLocale;
    const data = await import(`i18n-iso-countries/langs/${lang}.json`);

    const countryTranslations = Object.keys(mapLocalesByI18nCodes).includes(
        activeLocale
    )
        ? {
              ...data.default,
              locale: activeLocale,
          }
        : data.default;

    return {
        props: {
            gsspData: { countryTranslations },
            messages: await getMessages(ctx, [
                'betting-countries',
                ...namespaces,
            ]),
        },
    };
};
