import type { FC, HTMLAttributes } from 'react';
import { memo } from 'react';
import cn from 'classnames';

import PackIcon, { IconsPack } from 'components/PackIcon';
import SmartLink from 'components/SmartLink';
import useListItemMobile, {
    ClickHandlerType,
    IconType,
} from './useListItemMobile';

interface Props extends HTMLAttributes<HTMLElement> {
    leftIcons?: IconType;
    rightIcons?: IconType;
    leftLabel: string;
    rightLabel?: string;
    link?: string | object;
    locale?: string;
    labelColor?: string;
    isShowArrow?: boolean;
    clickHandler?: ClickHandlerType;
    dataTest?: string;
}

const ListItemMobile: FC<Props> = ({
    leftIcons,
    rightIcons,
    leftLabel,
    rightLabel,
    link,
    className,
    locale,
    labelColor = 'text-primary-white',
    isShowArrow = false,
    clickHandler,
    id,
    dataTest,
}) => {
    const { isModal, mainDataTestAttr, onClickHandler, openModalHandler } =
        useListItemMobile({
            link,
            clickHandler,
            dataTest,
            id,
        });

    const itemStyles = cn(
        'flex cursor-pointer items-center justify-between bg-surface-middle px-6 typo-sm-2',
        leftIcons || rightIcons || isShowArrow ? 'py-2' : 'py-3',
        labelColor,
        className
    );

    const content: JSX.Element = (
        <>
            <div
                className="flex items-center"
                data-test="list-item-mobile__left"
            >
                {leftIcons}

                <span className="mr-2" data-test="list-item-mobile__left-label">
                    {leftLabel}
                </span>
            </div>

            <div
                className="flex items-center"
                data-test="list-item-mobile__right"
            >
                {rightLabel && (
                    <span
                        className="mr-2"
                        data-test="list-item-mobile__right-label"
                    >
                        {rightLabel}
                    </span>
                )}

                {rightIcons}

                {isShowArrow && (
                    <PackIcon
                        id="branch-chevron-right"
                        pack={IconsPack.SpriteIcons}
                        className="fill-primary-white"
                    />
                )}
            </div>
        </>
    );

    if (isModal) {
        return (
            <div className={itemStyles} onClick={openModalHandler}>
                {content}
            </div>
        );
    }

    if (link) {
        if (locale) {
            return (
                <a
                    href={`/${locale}/${link}`}
                    className={itemStyles}
                    data-test={mainDataTestAttr}
                >
                    {content}
                </a>
            );
        }

        return (
            <SmartLink
                href={link}
                className={itemStyles}
                dataTest={mainDataTestAttr}
            >
                {content}
            </SmartLink>
        );
    }

    return (
        <div
            className={itemStyles}
            onClick={onClickHandler}
            data-test={mainDataTestAttr}
        >
            {content}
        </div>
    );
};

export default memo(ListItemMobile);
