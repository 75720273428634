import { FC, memo, useCallback } from 'react';
import { useTranslations } from 'next-intl';

import { AppLink, MainLayoutType, ModalsHash } from 'app-constants';
import BonusBubble, { BonusBubbleType } from 'components/BonusBubble';
import { BonusBubbleCategory } from 'components/BonusBubble/types';
import Button from 'components/Button';
import { useRouter, useRouterPush } from 'components/HybridRouter';
import useModal from 'components/modals/useModal';
import PackIcon, { IconsPack } from 'components/PackIcon';
import { useResponsive } from 'layouts/MainLayout/ResponsiveLayoutContext';
import { useLayoutContext } from 'layouts/Root/LayoutTypeContext';
import useHeaderAuthorizationButtons from './useHeaderAuthorizationButtons';

const HeaderAuthorizationButtons: FC = () => {
    const t = useTranslations('HeaderButtons');
    const { openModal } = useModal();
    const { isShowDepositText, isLoaded } = useHeaderAuthorizationButtons();
    const { mainLayoutType } = useLayoutContext();
    const { push } = useRouterPush();
    const { pathname } = useRouter();

    const { is } = useResponsive();

    const destinationPage = pathname.includes(AppLink.CASINO_GAME_SLUG)
        ? AppLink.CASINO
        : undefined;

    const openDeposit = useCallback(() => {
        openModal({ hash: ModalsHash.Deposit, destinationPage });
    }, [openModal, destinationPage]);

    const openCasinoBonuses = useCallback(() => {
        push(AppLink.CASINOPROMOTIONS);
    }, [push]);

    const isMobile = is('xs');

    return (
        <>
            <Button
                color={Button.Color.Green}
                size={Button.Size.Small}
                onClick={openDeposit}
                dataTest="header-deposit"
            >
                <span
                    className="flex items-center"
                    data-test="header-deposit__wrapper"
                >
                    {isShowDepositText && isLoaded && !isMobile && (
                        <span className="mr-2" data-test="header-deposit__text">
                            {t('gincms.header_deposit').toUpperCase()}
                        </span>
                    )}

                    <PackIcon id="operation-add" pack={IconsPack.SpriteIcons} />
                </span>
            </Button>
            {mainLayoutType === MainLayoutType.Casino && !isMobile && (
                <Button
                    color={Button.Color.Orange}
                    size={Button.Size.Small}
                    className="ml-2"
                    onClick={openCasinoBonuses}
                    dataTest="header-casino-bonus-program"
                >
                    {t('gincms.header_casino_bonus_program').toUpperCase()}
                    <BonusBubble
                        type={BonusBubbleType.HEADER}
                        category={BonusBubbleCategory.CASINO}
                        dataTest="header-casino-bonus-program__bubble"
                    />
                </Button>
            )}
        </>
    );
};

export default memo(HeaderAuthorizationButtons);
