import { hasCookie } from 'cookies-next';

import { CookiesType } from 'app-constants';
import {
    authSessionVar,
    getUnknownAuthSessionStatus,
} from 'components/AuthorizationManager/authSessionState';

export const resetClientToken = (): void => {
    if (hasCookie(CookiesType.Paseto) && !getUnknownAuthSessionStatus()) {
        authSessionVar(true);
    }
};
