import { useMemo } from 'react';

import { ACTIVE_MARKET_STATUSES } from 'app-constants';
import { useGetMatchesByIds } from 'gql/betting/queries/getMatchesByIds.bet';
import { useBettingApolloClient } from 'services/apollo';
import type { QueryHookOptions } from 'services/apollo/betting';
import type { GetMatchesByIds } from 'types/gql.bet';
import getOddByFragment from 'utils/fragment-readers';
import idPathParser from 'utils/idPathParser';

interface Input {
    oddIds?: string[];
    fetchPolicy?: QueryHookOptions['fetchPolicy'];
}

interface Output {
    missingMatches: GetMatchesByIds['matches'] | undefined;
    loading: boolean;
}

function useGetMissingMatches({ oddIds = [], fetchPolicy }: Input): Output {
    const { client } = useBettingApolloClient();
    const odds = useMemo(
        () =>
            oddIds?.reduce<string[]>((acc, oddId) => {
                const odd = getOddByFragment(oddId, client);

                if (!odd) acc.push(oddId);

                return acc;
            }, []),
        [oddIds, client]
    );

    const variables = {
        sportEventsIds: [] as string[],
        marketIds: [] as string[],
    };

    odds?.forEach((oddId) => {
        const { sportEventId, marketId } = idPathParser.split('Odd', oddId);

        variables.sportEventsIds.push(sportEventId);
        variables.marketIds.push(marketId);
    });

    const skip = variables.sportEventsIds.length === 0;

    const { data: matchesResponse, loading } = useGetMatchesByIds(() => ({
        variables: {
            ...variables,
            marketStatuses: ACTIVE_MARKET_STATUSES,
        },
        fetchPolicy,
        skip,
    }));

    return {
        missingMatches: skip ? [] : matchesResponse?.matches,
        loading,
    };
}

export default useGetMissingMatches;
