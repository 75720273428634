import { ApolloQueryResult, QueryResult } from '@apollo/client';
import { get } from 'lodash';
import type { PageProps } from 'types';

import type { PagePropsKeys } from 'services/apollo';

const getStubUseQuery = <T>(
    pageProps: PageProps,
    key?: PagePropsKeys
): QueryResult<T> => {
    const data = get(pageProps.gsspData, key || '') as T;

    const defaultQueryResult: ApolloQueryResult<T> = {
        data,
        loading: false,
        networkStatus: 1,
        error: undefined,
        errors: [],
        partial: true,
    };

    return {
        ...defaultQueryResult,
        called: false,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        fetchMore: async () => defaultQueryResult,
        refetch: async () => defaultQueryResult,
        updateQuery: async () => {},
    };
};

export default getStubUseQuery;
