import { DEFAULT_LOCALE, DEFAULT_NEXT_LOCALE } from 'app-constants';

const getActiveLocale = <T extends { locale?: string }>(ctx: T): string => {
    const { locale = DEFAULT_LOCALE } = ctx;

    return locale === DEFAULT_NEXT_LOCALE
        ? DEFAULT_LOCALE
        : locale || DEFAULT_LOCALE;
};

export default getActiveLocale;
