import { IconsPack, PackIds } from 'components/PackIcon';
import { Message } from 'types/gql.cms';
import { NotificationSystemType } from '../constants';

interface CardImgArgs {
    mainImage?: NonNullable<Message['media']>['mainImage'];
    onlyDefault?: boolean;
    isMobile: boolean;
}

export const getMessageIconColor = (systemType: string): string => {
    switch (systemType) {
        case NotificationSystemType.Bonus:
        case NotificationSystemType.BetSettled:
        case NotificationSystemType.BetSettle:
            return 'fill-green-700';
        case NotificationSystemType.Support:
            return 'fill-yellow-700';
        case NotificationSystemType.System:
            return 'fill-turquoise-300';
        default:
            return 'fill-primary-blue-toxic';
    }
};

export const getMessageIcon = (
    systemType: string
): PackIds[IconsPack.SpriteIcons] => {
    switch (systemType) {
        case NotificationSystemType.Bonus:
            return 'promo-sub-bonus';
        case NotificationSystemType.Support:
            return 'warning-icon';
        case NotificationSystemType.SportEvent:
            return 'notification-event-feature';
        case NotificationSystemType.BetSettled:
        case NotificationSystemType.BetSettle:
            return 'notification-coupon-check';
        default:
            return 'notification-message';
    }
};

export const getMessageCardImg = ({
    mainImage,
    isMobile,
    onlyDefault,
}: CardImgArgs): string => {
    if (!mainImage) return '';

    if (onlyDefault) return mainImage.default;

    if (mainImage.mobileWebsite && isMobile) {
        return mainImage.mobileWebsite;
    }

    if (mainImage.desktopWebsite && !isMobile) {
        return mainImage.desktopWebsite;
    }

    return mainImage.default;
};
