import { GraphQLErrors } from '@apollo/client/errors';
import { GraphQLError } from 'graphql';

import { AUTH_SESSION_UNKNOWN_MESSAGES } from 'app-constants';
import { logError } from 'services/logger';

export const isAuthSessionGraphQL = (
    graphQLErrors: GraphQLErrors | undefined,
    operationName: string,
    requestId: string
): boolean => {
    if (!graphQLErrors) {
        return false;
    }

    let isAuthSessionError = false;

    graphQLErrors.forEach((err) => {
        isAuthSessionError = !!AUTH_SESSION_UNKNOWN_MESSAGES.find((m) => {
            return err.message.includes(m);
        });

        if (isAuthSessionError) {
            logError<GraphQLError>({
                message: `GraphQL error in "${operationName}": ${err.message} \n`,
                err,
                requestId,
            });
        }
    });

    return isAuthSessionError;
};
