import React from 'react';
import cn from 'classnames';

import { MainLayoutType, WidgetNames } from 'app-constants';
import Widget, { makeGetServerSidePropsWidgetByName } from 'components/Widget';
import { useLayoutContext } from 'layouts/Root/LayoutTypeContext';
import { useGsspData } from 'layouts/Root/PagePropsContext';

import styles from '../footerLinks.module.css';

const FooterPr: React.FC = () => {
    const { mainLayoutType } = useLayoutContext();
    const { widgets } = useGsspData();

    const typePage =
        mainLayoutType === MainLayoutType.Betting
            ? WidgetNames.FOOTER_PR_BETTING
            : WidgetNames.FOOTER_PR_CASINO;

    return (
        <div className="mt-8">
            <Widget
                name={typePage}
                className={cn('grid grid-cols-1', styles.links)}
                widgetData={widgets?.[typePage]?.widgetByName?.template}
            />
        </div>
    );
};

export const getFooterPrServerSideProps = [
    makeGetServerSidePropsWidgetByName(WidgetNames.FOOTER_PR_BETTING),
    makeGetServerSidePropsWidgetByName(WidgetNames.FOOTER_PR_CASINO),
];

export default FooterPr;
