import { first } from 'lodash';

import { BannerZoneLocation } from 'components/betting/Promo/BannerZones/constants';
import { LinkedSportBanner } from '../components/betting/Promo/Banners/types';
import useBanners from '../components/betting/Promo/Banners/useBanners';
import { useBannerZones } from '../components/betting/Promo/BannerZones';
import { Feature, useFeatureFlags } from '../services/features';

interface Input {
    sportId: string | undefined;
}

interface Output {
    linkedSportId: string | undefined;
}

const useLinkedSportId = ({ sportId }: Input): Output => {
    const { features } = useFeatureFlags();
    const isSubSportFeature = features.isEnabled(Feature.MatchListSubSport);
    const { data: bannerZoneData } = useBannerZones({
        variables: {
            pageLocations: [BannerZoneLocation.LINKED_SPORT],
        },
        skip: !sportId || !isSubSportFeature,
    });

    const zoneId = first(bannerZoneData?.bannerZones)?.id;

    const { linkedSportBanners } = useBanners({ zoneId });

    const linkedSportId = linkedSportBanners
        .filter(
            (banner): banner is LinkedSportBanner =>
                banner?.__typename === 'BannerBettingLinkedSport'
        )
        .find((item) => item.sport.id === sportId)?.linkedSport.id;

    return {
        linkedSportId,
    };
};

export default useLinkedSportId;
