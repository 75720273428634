import { makeVar } from '@apollo/client';

import { OddFormat } from 'app-constants';
import { OddAcceptStrategy } from 'types/api.bet';
import { isSsr } from 'utils/isSsr';
import { GamblerSetting, OddStatisticView } from './constants';
import {
    DeeplinkMatchData,
    FavoriteMarkets,
    GamblerSettingsVar,
    LocalStorageGamblerSettings,
} from './types';

export const gamblerSettingsVar = makeVar<GamblerSettingsVar>({
    [GamblerSetting.ODD_FORMAT]: OddFormat.Decimal,
    [GamblerSetting.SHOW_ODD_HISTORY]: false,
    [GamblerSetting.ODD_STATISTIC_VIEW]: OddStatisticView.Market,
    [GamblerSetting.SHOW_ODDS_STATISTIC]: false,
});

export const favoriteMarketsIdVar = makeVar<Array<FavoriteMarkets>>([]);

export const deeplinkMatchDataVar = makeVar<DeeplinkMatchData>(null);

export const oddAcceptStrategyVar = makeVar<OddAcceptStrategy>(
    OddAcceptStrategy.AlwaysAllowed
);

export const getLocalStorageGamblerSettings = ():
    | LocalStorageGamblerSettings
    | undefined => {
    if (isSsr()) return;

    const gamblerSettings = localStorage.getItem('persist:gambler');

    if (!gamblerSettings) return;

    try {
        return JSON.parse(gamblerSettings);
    } catch (e) {
        console.error('GamblerSettings localstorage parse error', e);
    }
};

export const persistGamblerSettings = (): void => {
    const localStorageGamblerSettings = getLocalStorageGamblerSettings();

    if (!localStorageGamblerSettings) return;

    const {
        oddAcceptStrategy = oddAcceptStrategyVar(),
        deeplinkMatchData = deeplinkMatchDataVar(),
        ...gamblerSettings
    } = localStorageGamblerSettings;

    deeplinkMatchDataVar(deeplinkMatchData);
    oddAcceptStrategyVar(oddAcceptStrategy);
    gamblerSettingsVar({
        ...gamblerSettingsVar(),
        ...gamblerSettings,
    });
};

const updateLocalStorageGamblerSettings = (
    settings: Partial<LocalStorageGamblerSettings>
) => {
    const storage: LocalStorageGamblerSettings = {
        ...gamblerSettingsVar(),
        oddAcceptStrategy: oddAcceptStrategyVar(),
        deeplinkMatchData: deeplinkMatchDataVar(),
        ...settings,
    };

    localStorage.setItem('persist:gambler', JSON.stringify(storage));
};

export const updateClientGamblerSettings = (
    settings: Partial<GamblerSettingsVar>
): void => {
    const updatedGamblerSettings = {
        ...gamblerSettingsVar(),
        ...settings,
    };

    gamblerSettingsVar(updatedGamblerSettings);
    updateLocalStorageGamblerSettings(updatedGamblerSettings);
};

export const updateClientOddAcceptStrategy = (
    oddAcceptStrategy: OddAcceptStrategy
): void => {
    oddAcceptStrategyVar(oddAcceptStrategy);
    updateLocalStorageGamblerSettings({
        oddAcceptStrategy,
    });
};

export const updateClientDeeplinkMatchData = (
    deeplinkMatchData: DeeplinkMatchData
): void => {
    deeplinkMatchDataVar(deeplinkMatchData);
    updateLocalStorageGamblerSettings({
        deeplinkMatchData,
    });
};
