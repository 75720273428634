import { FC, memo, PropsWithChildren } from 'react';
import type { PageProps } from 'types';

import { PagePropsContext } from './PagePropsContext';

interface Props extends PropsWithChildren {
    pageProps: PageProps;
}

const PagePropsProvider: FC<Props> = ({ children, pageProps }) => {
    return (
        <PagePropsContext.Provider value={pageProps}>
            {children}
        </PagePropsContext.Provider>
    );
};

export default memo(PagePropsProvider);
