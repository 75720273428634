/* eslint-disable class-methods-use-this */
/* eslint-disable no-useless-constructor */
/* eslint-disable @typescript-eslint/no-empty-function */
import { getCookie } from 'cookies-next';
import { includes, reduce } from 'lodash';

import { CookiesType } from 'app-constants';
import { isSsr } from 'utils/isSsr';
import { Feature, featureValues, TRUTHY_VALUES } from './constants';
import type {
    FeatureFlags,
    FeatureType,
    Options,
    ParticipantFeature,
    PrepareFeaturesForClientInput,
    PrepareFeaturesForClientOutput,
} from './types';

export interface FeatureFlagsManagerInterface {
    parseAndSetFeatures(
        features: {
            features: FeatureType[];
            participantFeatures: ParticipantFeature[];
        },
        options?: Options
    ): FeatureFlagsManager;
    set(features: FeatureFlags): FeatureFlagsManager;
    isEnabled(featureName: string): boolean;
    getAll(): FeatureFlags;
    getNotSupportedFeatures(): Set<string>;
}

class FeatureFlagsManager implements FeatureFlagsManagerInterface {
    private static instance: FeatureFlagsManager | null = null;

    protected featureFlags?: FeatureFlags;

    private supportedFeatureNames = featureValues;

    private notSupportedFeaturesNames: Set<string> = new Set();

    protected constructor() {}

    public static init(): FeatureFlagsManager {
        if (!isSsr()) {
            if (!FeatureFlagsManager.instance) {
                FeatureFlagsManager.instance = new FeatureFlagsManager();
            }

            return FeatureFlagsManager.instance;
        }

        return new FeatureFlagsManager();
    }

    public getAll(): FeatureFlags {
        return this.featureFlags || {};
    }

    public getNotSupportedFeatures(): Set<string> {
        return this.notSupportedFeaturesNames;
    }

    private setNotSupportedFeatures(feature: string): void {
        if (this.notSupportedFeaturesNames.has(feature)) return;

        this.notSupportedFeaturesNames.add(feature);
    }

    public set(features: FeatureFlags): FeatureFlagsManager {
        this.featureFlags = { ...this.featureFlags, ...features };

        return this;
    }

    public parseAndSetFeatures(
        input: {
            features: FeatureType[];
            participantFeatures: ParticipantFeature[];
        },
        options?: Options
    ): FeatureFlagsManager {
        const { parsedFeatures: features } = this.prepareFeaturesForClient(
            {
                features: input.features,
                participantFeatures: input.participantFeatures,
            },
            options
        );

        const formattedFeatures = features.reduce(
            (acc: FeatureFlags, { name, enabled }) => {
                if (!options?.isDebug && !this.isSupported(name)) {
                    this.setNotSupportedFeatures(name);

                    return acc;
                }

                acc[name] = enabled;

                return acc;
            },
            {}
        );
        this.featureFlags = { ...this.featureFlags, ...formattedFeatures };

        return this;
    }

    private isSupported(featureName: string): boolean {
        const isSupported = this.supportedFeatureNames.includes(
            featureName as Feature
        );

        return isSupported;
    }

    public isEnabled(featureName: Feature): boolean {
        return this.featureFlags?.[featureName] ?? false;
    }

    private prepareFeaturesForClient(
        { participantFeatures, features }: PrepareFeaturesForClientInput,
        options?: Options
    ): PrepareFeaturesForClientOutput {
        const isDebug = !!options?.isDebug;
        const req = options?.req;

        let parserCookies: FeatureFlags = {};

        if (isDebug) {
            const featuresFromCookie = getCookie(CookiesType.Features, { req });

            try {
                parserCookies = JSON.parse(featuresFromCookie as string);
            } catch (e) {
                console.error(
                    `Features From Cookie is not valid JSON \n Feature flags from Cookie: \n ${featuresFromCookie
                        ?.toString()
                        .replaceAll(',', '\n')}`
                );
            }
        }

        const convertedParticipantFeatures =
            this.participantFeaturesToCommonFeatures(participantFeatures);

        const mergedFeatures = convertedParticipantFeatures.reduce(
            (acc, participantFeature) => {
                const index = acc.findIndex(
                    (i) => i.name === participantFeature.name
                );

                if (index !== -1) {
                    acc.splice(index, 1, participantFeature);
                } else {
                    acc.push(participantFeature);
                }

                return acc;
            },
            [...features]
        );

        const initialFeatures = mergedFeatures.reduce(
            (acc: FeatureType[], feature) => {
                if (parserCookies[feature.name] === undefined) {
                    acc.push(feature);
                } else {
                    acc.push({
                        ...feature,
                        enabled: !!parserCookies[feature.name],
                    });
                }

                return acc;
            },
            []
        );

        const customFeatures = reduce(
            parserCookies,
            (acc: FeatureType[], _, name) => {
                if (initialFeatures.find((i) => i.name === name)) {
                    return acc;
                }

                acc.push({ name, enabled: parserCookies[name] });

                return acc;
            },
            []
        );

        return { parsedFeatures: [...initialFeatures, ...customFeatures] };
    }

    private participantFeaturesToCommonFeatures(
        participantFeatures: ParticipantFeature[]
    ): FeatureType[] {
        if (!participantFeatures.length) return [];

        return participantFeatures.map(({ featureName, featureVariant }) => ({
            name: featureName,
            enabled: includes(TRUTHY_VALUES, featureVariant.toLowerCase()),
        }));
    }
}

export default FeatureFlagsManager;
