import { QueryFunctionOptions } from '@apollo/client';

import { DATE_FROM } from 'components/inboxCenter/constants';
import { useGetMessagesInfo } from 'gql/cms/queries/messages/getMessagesInfo.cms';
import { Exact, GetMessagesInfo, Message } from 'types/gql.cms';
import useAuthorization from './useAuthorization';

interface Input
    extends Pick<
        QueryFunctionOptions<GetMessagesInfo, Exact<{ createdFrom?: any }>>,
        'skip' | 'onCompleted'
    > {}

interface Output {
    unread?: number;
    message?: Message | null;
}

const useNotificationsInfo = ({ skip, onCompleted }: Input = {}): Output => {
    const { isAuthorized } = useAuthorization();
    const { data: messagesInfoData } = useGetMessagesInfo(() => {
        return {
            variables: {
                createdFrom: new Date(DATE_FROM),
            },
            skip: !isAuthorized || skip,
            onCompleted,
        };
    });

    const { unread, message } = messagesInfoData?.messagesInfo || {};

    return {
        unread,
        message,
    };
};

export default useNotificationsInfo;
