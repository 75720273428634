import { useState } from 'react';
import setDefaultOptions from 'date-fns/setDefaultOptions';
import type { NextComponentType, NextPage } from 'next';
import { AppContext } from 'next/app';
import type { Router } from 'next/router';
import { NextIntlProvider } from 'next-intl';
import type { PageProps } from 'types';

import { AppEnvProvider } from 'components/AppEnv';
import CriticalSeoSSRPage from 'components/CriticalSeoSSRPage';
import ErrorBoundary from 'components/ErrorBoundary';
import { useMount, useReportWebVitals, useSetFirstEntrypoint } from 'hooks';
import Root from 'layouts/Root';
import ClientRenderProvider from 'layouts/Root/ClientRenderProvider';
import ClientRouter from 'layouts/Root/ClientRouter';
import EnableSSRProvider from 'layouts/Root/EnableSSRProvider/EnableSSRProvider';
import PagePropsProvider from 'layouts/Root/PagePropsProvider';
import { FeatureFlagsProvider } from 'services/features';
import { useToggleDebugAnalytic } from 'services/GoogleAnalytics';
import { initNextLogger } from 'services/logger';
import { getDateFnsLocale } from 'utils';
import { isSimpleRoute } from 'utils/isSimpleRoute';

import 'styles/globals.css';

type Props = {
    Component: NextPage<PageProps>;
    router: Router;
    pageProps: PageProps;
};

type AppType = NextComponentType<AppContext, { pageProps: PageProps }, Props>;

initNextLogger();

const App: AppType = (props): JSX.Element => {
    const { Component, pageProps } = props;
    const { locale, route } = props.router;

    const isSSREnabled = pageProps.env.CMS_SSR_ENABLE === 'true';

    useSetFirstEntrypoint(props.router);
    useToggleDebugAnalytic();
    useReportWebVitals();

    const [isClientRendered, setClientRendered] = useState<boolean>(false);

    useMount(() => {
        setClientRendered(true);

        // NOTE:We reset the history so that Next doesn't react to the page on which the user initialized the application.
        // For example, this fixes the problem of the SSR running again when returning to the page from which it started.
        window.history.replaceState({ idx: 0 }, '');
    });

    if (locale) {
        setDefaultOptions({
            locale: getDateFnsLocale(locale),
        });
    }

    if (isSSREnabled && isSimpleRoute(route)) {
        return (
            <ErrorBoundary>
                <AppEnvProvider envs={{ APP_ENV: pageProps.env?.APP_ENV }}>
                    <NextIntlProvider
                        locale={locale}
                        messages={pageProps.messages}
                    >
                        <Component {...pageProps} />
                    </NextIntlProvider>
                </AppEnvProvider>
            </ErrorBoundary>
        );
    }

    const rootProps = {
        pageProps,
        locale,
    };

    if (!isSSREnabled && !isClientRendered) {
        return (
            <AppEnvProvider envs={{ APP_ENV: pageProps.env?.APP_ENV }}>
                <CriticalSeoSSRPage pageProps={pageProps} />
            </AppEnvProvider>
        );
    }

    if (isSimpleRoute(route)) {
        return (
            <ErrorBoundary>
                <AppEnvProvider envs={{ APP_ENV: pageProps.env?.APP_ENV }}>
                    <NextIntlProvider
                        locale={locale}
                        messages={pageProps.messages}
                    >
                        <Component {...pageProps} />
                    </NextIntlProvider>
                </AppEnvProvider>
            </ErrorBoundary>
        );
    }

    return (
        <ErrorBoundary>
            <AppEnvProvider envs={{ APP_ENV: pageProps.env?.APP_ENV }}>
                <EnableSSRProvider isSSREnabled={isSSREnabled}>
                    <PagePropsProvider pageProps={pageProps}>
                        <FeatureFlagsProvider
                            parsedFeatures={pageProps.parsedFeatures}
                            notSupportedFeatures={
                                pageProps.notSupportedFeatures
                            }
                            cmsClientOptions={pageProps.cmsClientOptions}
                            endpoint={
                                pageProps.env.PUBLIC_CMS_GQL_CLIENT_ENDPOINT
                            }
                        >
                            {isClientRendered ? (
                                <ClientRenderProvider>
                                    <ClientRouter {...rootProps} />
                                </ClientRenderProvider>
                            ) : (
                                <Root Page={Component} {...rootProps} />
                            )}
                        </FeatureFlagsProvider>
                    </PagePropsProvider>
                </EnableSSRProvider>
            </AppEnvProvider>
        </ErrorBoundary>
    );
};

export default App;
