import { DEFAULT_LOCALE } from 'app-constants';

export const getPageUrl = (
    path: string,
    locale: string = DEFAULT_LOCALE
): string => {
    const url = path === '/' ? '' : path;

    return `/${locale}${url}`;
};
