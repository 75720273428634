import { useMemo } from 'react';

import { MainLayoutType } from 'app-constants';
import { useGetInsurances } from 'gql/betting/queries/getInsurances.bet';
import type { FormattedInsuranceItem } from '../types';

interface Input {
    mainLayoutType: MainLayoutType;
}

interface Output {
    formattedInsurance: FormattedInsuranceItem[];
}

const useHeaderBalancesInsurance = ({ mainLayoutType }: Input): Output => {
    const { data: insurancesData } = useGetInsurances(() => ({
        variables: {
            filter: {
                onlyActual: true,
            },
        },
        skip: mainLayoutType !== MainLayoutType.Betting,
    }));

    const { insurances } = insurancesData || { insurances: [] };

    const formattedInsurance = useMemo(() => {
        return insurances.reduce(
            (
                acc: FormattedInsuranceItem[],
                { id, currencyCode, refundRules }
            ) => {
                if (
                    refundRules.amount.__typename === 'RefundAmountAmountRule'
                ) {
                    const amount = `${Number(refundRules.amount.amount).toFixed(
                        2
                    )} ${currencyCode}`;

                    acc.push({
                        id,
                        amount,
                    });
                }

                if (
                    refundRules.amount.__typename === 'RefundAmountPercentRule'
                ) {
                    acc.push({
                        id,
                        amount: `${Number(refundRules.amount.percent)}%`,
                    });
                }

                return acc;
            },
            []
        );
    }, [insurances]);

    return {
        formattedInsurance,
    };
};

export default useHeaderBalancesInsurance;
