import { FC, memo } from 'react';
import cn from 'classnames';

import styles from './spinner.module.css';

type Size = 'small' | 'medium' | 'large';

const spinnerSize: Record<Size, string> = {
    large: styles.large,
    medium: styles.medium,
    small: styles.small,
};

interface Props {
    size?: Size;
    className?: string;
}

const Spinner: FC<Props> = ({ size = 'large', className }) => {
    return (
        <div className={cn(styles.loader, spinnerSize[size], className)}>
            <div className={styles['loader-circle']}>
                <div className={styles['loader-container']}>
                    <div
                        className={cn(styles['loader-spinner'], styles.orange)}
                    >
                        <div
                            className={cn(
                                styles['loader-spinner'],
                                styles.blue
                            )}
                        >
                            <div
                                className={cn(
                                    styles['loader-spinner'],
                                    styles.green
                                )}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default memo(Spinner);
