import type { IncomingHttpHeaders } from 'http';
import type { GetServerSidePropsContext } from 'next';
import type { NextRequest } from 'next/server';
import requestIp from 'request-ip';
import { v4 as createUuid } from 'uuid';

interface Options {
    injectHost?: boolean;
    injectXFF?: boolean;
    injectReferer?: boolean;
}

type GetServerSidePropsRequest = GetServerSidePropsContext['req'];

const APOLLO_REQUEST_ID_HEADER = 'x-request-id';
const APOLLO_EXCLUDED_GQL_REQUEST_HEADERS = new Set([
    // NOTE: incorrect host fails CMS request: "failed, reason: self signed certificate"
    'host',
    'cache-control',
    'connection',
    'content-length',
    'accept',
    'content-type',
    'keep-alive',
]);

const getHeadersFromRequest = (
    req: GetServerSidePropsRequest | NextRequest | undefined,
    options?: Options
): Record<string, any> => {
    if (!req) {
        return {};
    }

    const {
        injectHost = false,
        injectXFF = true,
        injectReferer = false,
    } = options || {};

    const headers: Record<string, any> = {};

    iterateHeaders(req.headers, (value, key) => {
        if (APOLLO_EXCLUDED_GQL_REQUEST_HEADERS.has(key)) return;

        headers[key] = value;
    });

    if (!headers[APOLLO_REQUEST_ID_HEADER]) {
        headers[APOLLO_REQUEST_ID_HEADER] = createUuid();
    }

    let hostToInject = null;

    if (injectHost) {
        hostToInject =
            getHeader(req.headers, 'x-forwarded-host') ||
            getHeader(req.headers, 'host') ||
            null;

        // Prevent problem about self-signed certificate from using localhost and `{host}`-templated endpoints
        // ToDo: refactor to more clear way without hardcode one local domain
        if (
            hostToInject !== null &&
            typeof hostToInject === 'string' &&
            hostToInject.startsWith('localhost:')
        ) {
            hostToInject = null;
        }
    }

    if (hostToInject) {
        // IMPORTANT!!! This line should not be altered as it impacts API queries.
        headers.host = hostToInject;
    }

    if (injectXFF && !isIterableHeaders(req.headers)) {
        headers['x-forwarded-for'] = requestIp.getClientIp(
            req as GetServerSidePropsRequest
        );
    }

    if (injectReferer) {
        headers.referer = req.url;
    }

    return headers;
};

function iterateHeaders(
    headers: IncomingHttpHeaders | Headers,
    iteratee: (value: any, key: string) => void
): void {
    if (isIterableHeaders(headers)) {
        headers.forEach(iteratee);

        return;
    }

    Object.entries(headers).forEach(([key, value]) => iteratee(value, key));
}

function getHeader(headers: IncomingHttpHeaders | Headers, key: string) {
    if (isIterableHeaders(headers)) {
        return headers.get(key);
    }

    return headers[key];
}

function isIterableHeaders(headers: any): headers is Headers {
    return headers != null && typeof headers[Symbol.iterator] === 'function';
}

export default getHeadersFromRequest;
