import { FC, memo, useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';

import { ModalsHash } from 'app-constants';
import useModal from 'components/modals/useModal';
import { withClientRender } from 'hocs';
import { useMount } from 'hooks';
import { cmsApolloRedirectVar } from 'services/apollo/cms/errorHandler';
import { getAppModalRedirect } from './utils';

const AppRedirect: FC = () => {
    const { openModal } = useModal();

    useMount(() => {
        const data = getAppModalRedirect();

        if (!data) return;

        openModal({ hash: data as ModalsHash });
    });

    // handle cms apollo errors
    const apolloRedirect = useReactiveVar(cmsApolloRedirectVar);
    useEffect(() => {
        if (!apolloRedirect.pathTo) {
            return;
        }

        if (!apolloRedirect.shouldRedirect) {
            openModal({
                destinationPage: apolloRedirect.pathname,
                hash: apolloRedirect.pathTo as ModalsHash,
            });
        }

        return () => {
            cmsApolloRedirectVar({});
        };
    }, [openModal, apolloRedirect]);

    return null;
};

export default memo(withClientRender(AppRedirect));
