import React, { FC, memo } from 'react';
import type { FooterSportLinks } from 'types';

import Image from 'components/Image';
import FooterSportLink from './FooterSportLink';

interface Props {
    sportList: FooterSportLinks;
}

const FooterSportLinksContent: FC<Props> = ({ sportList }) => {
    if (!sportList.length) return null;

    return (
        <>
            {sportList.map((sport) => (
                <FooterSportLink
                    key={sport.anchor}
                    name={sport.anchor}
                    icon={getIcon(sport.imageId)}
                    linkHref={`/${sport.url}`}
                />
            ))}
        </>
    );
};

export function getIcon(imageId: string | undefined): JSX.Element | undefined {
    if (!imageId) return;

    return (
        <Image
            src={`/file/${imageId}`}
            alt=""
            unoptimized={true}
            width={24}
            height={24}
        />
    );
}

export default memo(FooterSportLinksContent);
