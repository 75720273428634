import { NetworkError } from '@apollo/client/errors';

import { AUTH_SESSION_UNKNOWN_MESSAGES } from 'app-constants';
import formatNetworkErrorResult from 'utils/formatNetworkErrorResult';

export const isAuthSessionNetworkError = (
    networkError: NetworkError | undefined
): boolean => {
    if (!networkError) {
        return false;
    }

    if (networkError && 'result' in networkError) {
        const result = formatNetworkErrorResult(networkError.result);

        return result.some(({ errors }) => {
            if (!errors) return false;

            return AUTH_SESSION_UNKNOWN_MESSAGES.includes(errors[0].message);
        });
    }

    return false;
};
