import { isSsr } from 'utils/isSsr';

export const getScrollBarWidth = (): number => {
    if (isSsr()) return 0;

    const el = document.createElement('div');
    el.style.cssText = 'overflow:scroll; visibility:hidden; position:absolute;';
    document.body.appendChild(el);
    const width = el.offsetWidth - el.clientWidth;
    el.remove();

    return width;
};
