import React, { useEffect, useState } from 'react';
import { captureException } from '@sentry/nextjs';
import cn from 'classnames';
import { get } from 'lodash';

import SvgIcon, { SvgIconSize } from 'components/SvgIcon';
import PackIconStub from './PackIconStub';

import classicDefault from 'assets/svg/sprite/classic_default.svg';
import esportDefault from 'assets/svg/sprite/cybersport_default.svg';

export enum IconsPack {
    CasinoMenuIcons = 'casino-menu-icons-pack',
    CategoryIcons = 'category-icons-pack',
    SocialIcons = 'social-icons-pack',
    NavigationIcons = 'navigation-icons-pack',
    ActionIcons = 'action-icons-pack',
    SpriteIcons = 'sprite-icons-pack',
    ColoredIcons = 'colored-icons-pack',
    BetHistoryIcons = 'bet-history-icons-pack',
}

export type CasinoMenuIconsPack =
    (typeof import('assets/svg/casino-menu-icons-pack'))['default'];
export type CategoryIconsPack =
    (typeof import('assets/svg/category-icons-pack'))['default'];
export type SocialIconsPack =
    (typeof import('assets/svg/social-icons-pack'))['default'];
export type ActionIconsPack =
    (typeof import('assets/svg/action-icons-pack'))['default'];
export type NavigationIconsPack =
    (typeof import('assets/svg/navigation-icons-pack'))['default'];
export type SpriteIconsPack =
    (typeof import('assets/svg/sprite-icons-pack'))['default'];
export type ColoredIconsPack =
    (typeof import('assets/svg/colored-icons-pack'))['default'];
export type BetHistoryIconsPack =
    (typeof import('assets/svg/bet-history-icons-pack'))['default'];

export interface Packs {
    [IconsPack.CasinoMenuIcons]: CasinoMenuIconsPack | null;
    [IconsPack.CategoryIcons]: CategoryIconsPack | null;
    [IconsPack.SocialIcons]: SocialIconsPack | null;
    [IconsPack.NavigationIcons]: NavigationIconsPack | null;
    [IconsPack.ActionIcons]: ActionIconsPack | null;
    [IconsPack.SpriteIcons]: SpriteIconsPack | null;
    [IconsPack.ColoredIcons]: ColoredIconsPack | null;
    [IconsPack.BetHistoryIcons]: BetHistoryIconsPack | null;
}

export type PackIds = { [K in IconsPack]: keyof NonNullable<Packs[K]> };

export type IconId = PackIds[keyof PackIds];

interface Props<T extends IconsPack> {
    id: PackIds[T];
    pack: T;
    isDefaultIcon?: boolean;
    className?: string;
    dataTest?: string;
    size?: SvgIconSize;
    onClick?: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
}

const packIcons: Packs = {
    [IconsPack.CasinoMenuIcons]: null,
    [IconsPack.CategoryIcons]: null,
    [IconsPack.SocialIcons]: null,
    [IconsPack.NavigationIcons]: null,
    [IconsPack.ActionIcons]: null,
    [IconsPack.SpriteIcons]: null,
    [IconsPack.ColoredIcons]: null,
    [IconsPack.BetHistoryIcons]: null,
};

const PackIcon = <T extends IconsPack>({
    isDefaultIcon,
    pack,
    id,
    className,
    dataTest,
    size,
    onClick,
}: Props<T>): JSX.Element | null => {
    const [, forceUpdate] = useState<number>(0);

    useEffect(() => {
        import(/* webpackChunkName: "icon-[request]" */ `assets/svg/${pack}`)
            .then((svgPack) => {
                packIcons[pack] = svgPack.default;
            })
            .finally(() => forceUpdate((s) => s + 1))
            .catch((err) => {
                captureException(err);
                throw new Error('Pack not Found');
            });
    }, [pack]);

    const icon = get(packIcons[pack], id);

    if (icon) {
        return (
            <SvgIcon
                clickHandler={onClick}
                icon={icon}
                size={size}
                className={className}
                dataTest={dataTest}
            />
        );
    }

    if (!icon) {
        if (!isDefaultIcon) {
            return <PackIconStub className={className} />;
        }

        const defaultIcon =
            pack === IconsPack.SpriteIcons ? esportDefault : classicDefault;

        return (
            <SvgIcon
                icon={defaultIcon}
                className={cn('fill-grey-500', className)}
                size={size}
                clickHandler={onClick}
                dataTest={dataTest}
            />
        );
    }

    return null;
};

export default React.memo(PackIcon) as typeof PackIcon;
