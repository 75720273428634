export enum NotificationSystemType {
    Popup = 'popup',
    System = 'system',
    Bonus = 'bonus',
    Support = 'support',
    SportEvent = 'sport_event',
    BetSettled = 'bet_settled',
    BetSettle = 'bet_settle',
    EuroIntro = 'euro_intro',
    EuroStageStart = 'euro_stage_start',
    EuroQualified = 'euro_qualified',
    EuroRicePrizePosition = 'euro_rice_prize_position',
    EuroFirstBet = 'euro_first_bet',
    EuroGotPrize = 'euro_got_prize',
    EuroSemifinal = 'euro_semifinal',
    EuroFinal = 'euro_final',
    EuroOnboarding = 'euro_onboarding',
}

export const DATE_FROM = new Date('2022-04-26'); // Deadline date from which messages and messages info can be requested
