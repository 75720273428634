import { makeVar } from '@apollo/client';
import { omit } from 'lodash';

type BetslipAnalyticVars = Record<string, number>;

export const betslipAnalyticVars = makeVar<BetslipAnalyticVars>({});

interface AddAnalyticDataProps {
    odd: string | string[];
    time: number;
}

export function addAnalyticData({ odd, time }: AddAnalyticDataProps): void {
    if (Array.isArray(odd) && odd.length) {
        const data = odd.reduce<BetslipAnalyticVars>((acc, oddId) => {
            acc[oddId] = time;

            return acc;
        }, {});

        betslipAnalyticVars(data);

        return;
    }

    const prevData = betslipAnalyticVars();

    if (odd.length && typeof odd === 'string') {
        betslipAnalyticVars({ ...prevData, [odd]: time });
    }
}

interface RemoveAnalyticDataProps {
    odd: string;
}

export function removeAnalyticData({ odd }: RemoveAnalyticDataProps): void {
    const prevData = betslipAnalyticVars();
    betslipAnalyticVars(omit(prevData, odd));
}

export function clearAnalyticData(): void {
    betslipAnalyticVars({});
}
