import { ApolloLink, NextLink, Observable } from '@apollo/client';

const wsNormalizationLink = new ApolloLink((operation, forward: NextLink) => {
    const result = forward(operation);

    // detect WebSocketLink ugly observable, convert to zen
    return typeof result.map !== 'function' ? Observable.from(result) : result;
});

export default wsNormalizationLink;
