import { FC } from 'react';

import NotFound from 'components/NotFound';
import { MessagesProvider } from 'layouts/IntlMessages';

interface Props {
    locale?: string;
}
const NotFoundWithMessages: FC<Props> = ({ locale }) => {
    return (
        <MessagesProvider locale={locale} initialMessages={['ErrorPage']}>
            <NotFound />
        </MessagesProvider>
    );
};

export default NotFoundWithMessages;
