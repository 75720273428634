import { IPhone } from 'types';

export const BANNED_COUNTRY = ['US', 'NL'];

export const BASE_WW_PHONE: IPhone = {
    countryCode: 'WW-CMS',
    countryID: 0,
    phone: {
        code: 'XXXXXXXXXXXXXXX',
        mask: '+XXXXXXXXXXXXXXX',
        min: 9,
        max: 16,
    },
};

export const phones: IPhone[] = [
    {
        countryCode: 'JP',
        countryID: 392,
        phone: {
            code: '81',
            mask: '+81XXXXXXXXXX',
            min: 12,
            max: 13,
        },
    },
    {
        countryCode: 'AD',
        countryID: 20,
        phone: {
            code: '376',
            mask: '+376-XXX-XXX',
        },
    },
    {
        countryCode: 'AE',
        countryID: 784,
        phone: {
            code: '971',
            mask: '+971XXXXXXXXXX',
            min: 12,
            max: 13,
        },
    },
    {
        countryCode: 'AF',
        countryID: 4,
        phone: {
            code: '93',
            mask: '+93-XX-XXX-XXXX',
        },
    },
    {
        countryCode: 'AG',
        countryID: 28,
        phone: {
            code: '1(268)',
            mask: '+1(268)XXX-XXXX',
        },
    },
    {
        countryCode: 'AI',
        countryID: 660,
        phone: {
            code: '1(264)',
            mask: '+1(264)XXX-XXXX',
        },
    },
    {
        countryCode: 'AL',
        countryID: 8,
        phone: {
            code: '1(264)',
            mask: '+1(264)XXX-XXXX',
        },
    },
    {
        countryCode: 'AM',
        countryID: 51,
        phone: {
            code: '374',
            mask: '+374-XX-XXX-XXX',
        },
    },
    {
        countryCode: 'AO',
        countryID: 24,
        phone: {
            code: '244',
            mask: '+244(XXX)XXX-XXX',
        },
    },
    {
        countryCode: 'AR',
        countryID: 32,
        phone: {
            code: '54',
            mask: '+54(XXX)XXX-XXXX',
        },
    },
    {
        countryCode: 'AS',
        countryID: 16,
        phone: {
            code: '1(684)',
            mask: '+1(684)XXX-XXXX',
        },
    },
    {
        countryCode: 'AT',
        countryID: 40,
        phone: {
            code: '43',
            mask: '+43(XXX)XXX-XXXXXXXX',
            min: 13,
            max: 16,
        },
    },
    {
        countryCode: 'AU',
        countryID: 36,
        phone: {
            code: '61',
            mask: '+61-X-XXXX-XXXX',
        },
    },
    {
        countryCode: 'AW',
        countryID: 533,
        phone: {
            code: '297',
            mask: '+297-XXX-XXXX',
        },
    },
    {
        countryCode: 'AZ',
        countryID: 31,
        phone: {
            code: '994',
            mask: '+994-XX-XXX-XX-XX',
        },
    },
    {
        countryCode: 'BA',
        countryID: 70,
        phone: {
            code: '387',
            mask: '+387-XX-XXX-XXX',
        },
    },
    {
        countryCode: 'BB',
        countryID: 52,
        phone: {
            code: '1(246)',
            mask: '+1(246)XXX-XXXX',
        },
    },
    {
        countryCode: 'BD',
        countryID: 50,
        phone: {
            code: '880',
            mask: '+880-XX-XXX-XXXXXX',
            min: 12,
            max: 14,
        },
    },
    {
        countryCode: 'BE',
        countryID: 56,
        phone: {
            code: '32',
            mask: '+32(XXX)XXX-XXX',
        },
    },
    {
        countryCode: 'BF',
        countryID: 854,
        phone: {
            code: '226',
            mask: '+226-XX-XX-XXXX',
        },
    },
    {
        countryCode: 'BG',
        countryID: 100,
        phone: {
            code: '359',
            mask: '+359(XXX)XXX-XXX',
        },
    },
    {
        countryCode: 'BH',
        countryID: 48,
        phone: {
            code: '973',
            mask: '+973-XXXX-XXXX',
        },
    },
    {
        countryCode: 'BI',
        countryID: 108,
        phone: {
            code: '257',
            mask: '+257-XX-XX-XXXX',
        },
    },
    {
        countryCode: 'BJ',
        countryID: 204,
        phone: {
            code: '229',
            mask: '+229-XX-XX-XXXX',
        },
    },
    {
        countryCode: 'BM',
        countryID: 60,
        phone: {
            code: '1(441)',
            mask: '+1(441)XXX-XXXX',
        },
    },
    {
        countryCode: 'BN',
        countryID: 96,
        phone: {
            code: '673',
            mask: '+673-XXX-XXXX',
        },
    },
    {
        countryCode: 'BO',
        countryID: 68,
        phone: {
            code: '591',
            mask: '+591-X-XXX-XXXX',
        },
    },
    {
        countryCode: 'BR',
        countryID: 76,
        phone: {
            code: '55',
            mask: '+55XXXXXXXXXXXXX',
            min: 14,
            max: 15,
        },
    },
    {
        countryCode: 'BS',
        countryID: 44,
        phone: {
            code: '1(242)',
            mask: '+1(242)XXX-XXXX',
        },
    },
    {
        countryCode: 'BT',
        countryID: 64,
        phone: {
            code: '975',
            mask: '+975-X-XXX-XXX',
        },
    },
    {
        countryCode: 'BW',
        countryID: 72,
        phone: {
            code: '267',
            mask: '+267-XX-XXX-XXX',
        },
    },
    {
        countryCode: 'BY',
        countryID: 112,
        phone: {
            code: '375',
            mask: '+375(XX)XXX-XX-XX',
        },
    },
    {
        countryCode: 'BZ',
        countryID: 84,
        phone: {
            code: '501',
            mask: '+501-XXX-XXXX',
        },
    },
    {
        countryCode: 'CA',
        countryID: 124,
        phone: {
            code: '1',
            // eslint-disable-next-line max-len
            regex: /^1(204|226|249|289|250|306|343|403|416|418|438|450|506|514|579|581|587|604|709|778|780|819|867|519|613|647|705|807|902|905)/,
            mask: '+1(XXX)XXX-XXXX',
        },
    },
    {
        countryCode: 'CD',
        countryID: 180,
        phone: {
            code: '243',
            mask: '+243(XXX)XXX-XXX',
        },
    },
    {
        countryCode: 'CF',
        countryID: 140,
        phone: {
            code: '236',
            mask: '+236-XX-XX-XXXX',
        },
    },
    {
        countryCode: 'CG',
        countryID: 178,
        phone: {
            code: '242',
            mask: '+242-XX-XXX-XXXX',
        },
    },
    {
        countryCode: 'CH',
        countryID: 756,
        phone: {
            code: '41',
            mask: '+41-XX-XXX-XXXX',
        },
    },
    {
        countryCode: 'CI',
        countryID: 384,
        phone: {
            code: '225',
            mask: '+225-XX-XXX-XXX',
        },
    },
    {
        countryCode: 'CK',
        countryID: 184,
        phone: {
            code: '682',
            mask: '+682-XX-XXX',
        },
    },
    {
        countryCode: 'CL',
        countryID: 152,
        phone: {
            code: '56',
            mask: '+56-X-XXXX-XXXX',
        },
    },
    {
        countryCode: 'CM',
        countryID: 120,
        phone: {
            code: '237',
            mask: '+237-XXXX-XXXX',
        },
    },
    {
        countryCode: 'CN',
        countryID: 156,
        phone: {
            code: '86',
            mask: '+86XXXXXXXXXXXX',
            min: 13,
            max: 14,
        },
    },
    {
        countryCode: 'CO',
        countryID: 170,
        phone: {
            code: '57',
            mask: '+57(XXX)XXX-XXXX',
        },
    },
    {
        countryCode: 'CR',
        countryID: 188,
        phone: {
            code: '506',
            mask: '+506-XXXX-XXXX',
        },
    },
    {
        countryCode: 'CU',
        countryID: 192,
        phone: {
            code: '53',
            mask: '+53-X-XXX-XXXX',
        },
    },
    {
        countryCode: 'CV',
        countryID: 132,
        phone: {
            code: '238',
            mask: '+238(XXX)XX-XX',
        },
    },
    {
        countryCode: 'CY',
        countryID: 196,
        phone: {
            code: '357',
            mask: '+357-XX-XXX-XXX',
        },
    },
    {
        countryCode: 'CZ',
        countryID: 203,
        phone: {
            code: '420',
            mask: '+420(XXX)XXX-XXX',
        },
    },
    {
        countryCode: 'DE',
        countryID: 276,
        phone: {
            code: '49',
            mask: '+49-XX-XXX-XXXXXX',
            min: 12,
            max: 13,
        },
    },
    {
        countryCode: 'DJ',
        countryID: 262,
        phone: {
            code: '253',
            mask: '+253-XX-XX-XX-XX',
        },
    },
    {
        countryCode: 'DK',
        countryID: 208,
        phone: {
            code: '45',
            mask: '+45-XX-XX-XX-XX',
        },
    },
    {
        countryCode: 'DM',
        countryID: 212,
        phone: {
            code: '1(767)',
            mask: '+1(767)XXX-XXXX',
        },
    },
    {
        countryCode: 'DO',
        countryID: 214,
        phone: {
            code: '18',
            mask: '+18XXXXX-XXXX',
        },
    },
    {
        countryCode: 'DZ',
        countryID: 12,
        phone: {
            code: '213',
            mask: '+213-XX-XXX-XXXX',
        },
    },
    {
        countryCode: 'EC',
        countryID: 218,
        phone: {
            code: '593',
            mask: '+593-XX-XXX-XXXXX',
            min: 12,
            max: 13,
        },
    },
    {
        countryCode: 'EE',
        countryID: 233,
        phone: {
            code: '372',
            mask: '+372-XXXX-XXXXX',
            min: 11,
            max: 12,
        },
    },
    {
        countryCode: 'EG',
        countryID: 818,
        phone: {
            code: '7',
            regex: /^7[67]/,
            mask: '+7(XXX)XXX-XX-XX',
        },
    },
    {
        countryCode: 'ES',
        countryID: 724,
        phone: {
            code: '34',
            mask: '+34(XXX)XXX-XXX',
        },
    },
    {
        countryCode: 'ET',
        countryID: 231,
        phone: {
            code: '251',
            mask: '+251-XX-XXX-XXXX',
        },
    },
    {
        countryCode: 'FI',
        countryID: 246,
        phone: {
            code: '358',
            mask: '+358(XXX)XXX-XXXXX',
            min: 13,
            max: 14,
        },
    },
    {
        countryCode: 'FJ',
        countryID: 242,
        phone: {
            code: '679',
            mask: '+679-XX-XXXXX',
        },
    },
    {
        countryCode: 'FK',
        countryID: 238,
        phone: {
            code: '500',
            mask: '+500-XXXXX',
        },
    },
    {
        countryCode: 'FO',
        countryID: 234,
        phone: {
            code: '298',
            mask: '+298-XXX-XXX',
        },
    },
    {
        countryCode: 'FR',
        countryID: 250,
        phone: {
            code: '33',
            mask: '+33(XXX)XXX-XXX',
        },
    },
    {
        countryCode: 'GA',
        countryID: 266,
        phone: {
            code: '241',
            mask: '+241-X-XX-XX-XX',
        },
    },
    {
        countryCode: 'GB',
        countryID: 826,
        phone: {
            code: '44',
            mask: '+44-XX-XXXX-XXXX',
        },
    },
    {
        countryCode: 'GD',
        countryID: 308,
        phone: {
            code: '1(473)',
            mask: '+1(473)XXX-XXXX',
        },
    },
    {
        countryCode: 'GE',
        countryID: 268,
        phone: {
            code: '995',
            mask: '+995(XXX)XXX-XXX',
        },
    },
    {
        countryCode: 'GF',
        countryID: 254,
        phone: {
            code: '594',
            mask: '+594-XXXXX-XXXX',
        },
    },
    {
        countryCode: 'GH',
        countryID: 288,
        phone: {
            code: '233',
            mask: '+233(XXX)XXX-XXX',
        },
    },
    {
        countryCode: 'GI',
        countryID: 292,
        phone: {
            code: '350',
            mask: '+350-XXX-XXXXX',
        },
    },
    {
        countryCode: 'GL',
        countryID: 304,
        phone: {
            code: '299',
            mask: '+299-XX-XX-XX',
        },
    },
    {
        countryCode: 'GM',
        countryID: 270,
        phone: {
            code: '220',
            mask: '+220(XXX)XX-XX',
        },
    },
    {
        countryCode: 'GN',
        countryID: 324,
        phone: {
            code: '224',
            mask: '+224-XX-XXX-XXX',
        },
    },
    {
        countryCode: 'GP',
        countryID: 312,
        phone: {
            code: '590',
            mask: '+590(XXX)XXX-XXX',
        },
    },
    {
        countryCode: 'GQ',
        countryID: 226,
        phone: {
            code: '240',
            mask: '+240-XX-XXX-XXXX',
        },
    },
    {
        countryCode: 'GR',
        countryID: 300,
        phone: {
            code: '30',
            mask: '+30(XXX)XXX-XXXX',
        },
    },
    {
        countryCode: 'GT',
        countryID: 320,
        phone: {
            code: '502',
            mask: '+502-X-XXX-XXXX',
        },
    },
    {
        countryCode: 'GU',
        countryID: 316,
        phone: {
            code: '1(671)',
            mask: '+1(671)XXX-XXXX',
        },
    },
    {
        countryCode: 'GW',
        countryID: 624,
        phone: {
            code: '245',
            mask: '+245-X-XXXXXX',
        },
    },
    {
        countryCode: 'GY',
        countryID: 328,
        phone: {
            code: '592',
            mask: '+592-XXX-XXXX',
        },
    },
    {
        countryCode: 'HK',
        countryID: 344,
        phone: {
            code: '852',
            mask: '+852-XXXX-XXXX',
        },
    },
    {
        countryCode: 'HN',
        countryID: 340,
        phone: {
            code: '504',
            mask: '+504-XXXX-XXXX',
        },
    },
    {
        countryCode: 'HR',
        countryID: 191,
        phone: {
            code: '385',
            mask: '+385-XX-XXX-XXXXX',
            min: 12,
            max: 13,
        },
    },
    {
        countryCode: 'HT',
        countryID: 332,
        phone: {
            code: '509',
            mask: '+509-XX-XX-XXXX',
        },
    },
    {
        countryCode: 'HU',
        countryID: 348,
        phone: {
            code: '36',
            mask: '+36(XXX)XXX-XXX',
        },
    },
    {
        countryCode: 'ID',
        countryID: 360,
        phone: {
            code: '62',
            mask: '+62XXXXXXXXXXXX',
            min: 10,
            max: 14,
        },
    },
    {
        countryCode: 'IE',
        countryID: 372,
        phone: {
            code: '353',
            mask: '+353(XXX)XXX-XXX',
        },
    },
    {
        countryCode: 'IL',
        countryID: 376,
        phone: {
            code: '972',
            mask: '+972-X-XXXX-XXXXX',
            min: 12,
            max: 13,
        },
    },
    {
        countryCode: 'IN',
        countryID: 356,
        phone: {
            code: '91',
            mask: '+91(XXXX)XXX-XXX',
        },
    },
    {
        countryCode: 'IQ',
        countryID: 368,
        phone: {
            code: '964',
            mask: '+964(XXX)XXX-XXXX',
        },
    },
    {
        countryCode: 'IR',
        countryID: 364,
        phone: {
            code: '98',
            mask: '+98(XXX)XXX-XXXX',
        },
    },
    {
        countryCode: 'IS',
        countryID: 352,
        phone: {
            code: '354',
            mask: '+354-XXX-XXXX',
        },
    },
    {
        countryCode: 'IT',
        countryID: 380,
        phone: {
            code: '39',
            mask: '+39(XXX)XXXX-XXX',
        },
    },
    {
        countryCode: 'JM',
        countryID: 388,
        phone: {
            code: '1(876)',
            mask: '+1(876)XXX-XXXX',
        },
    },
    {
        countryCode: 'JO',
        countryID: 400,
        phone: {
            code: '962',
            mask: '+962-X-XXXX-XXXX',
        },
    },
    {
        countryCode: 'KE',
        countryID: 404,
        phone: {
            code: '254',
            mask: '+254-XXX-XXXXXX',
        },
    },
    {
        countryCode: 'KG',
        countryID: 417,
        phone: {
            code: '996',
            mask: '+996(XXX)XXX-XXX',
        },
    },
    {
        countryCode: 'KH',
        countryID: 116,
        phone: {
            code: '855',
            mask: '+855-XX-XXX-XXX',
        },
    },
    {
        countryCode: 'KN',
        countryID: 659,
        phone: {
            code: '1(869)',
            mask: '+1(869)XXX-XXXX',
        },
    },
    {
        countryCode: 'KP',
        countryID: 408,
        phone: {
            code: '850',
            mask: '+850XXXXXXXXXXXXXXXXXX',
            min: 10,
            max: 21,
        },
    },
    {
        countryCode: 'KR',
        countryID: 410,
        phone: {
            code: '82',
            mask: '+82-XX-XXX-XXXXXX',
            min: 12,
            max: 13,
        },
    },
    {
        countryCode: 'KW',
        countryID: 414,
        phone: {
            code: '965',
            mask: '+965-XXXX-XXXX',
        },
    },
    {
        countryCode: 'KY',
        countryID: 136,
        phone: {
            code: '1(345)',
            mask: '+1(345)XXX-XXXX',
        },
    },
    {
        countryCode: 'KZ',
        countryID: 398,
        phone: {
            code: '7',
            regex: /^7[67]/,
            mask: '+7(XXX)XXX-XX-XX',
        },
    },
    {
        countryCode: 'LA',
        countryID: 418,
        phone: {
            code: '856',
            mask: '+856XXXXXXXXXXX',
            min: 12,
            max: 14,
        },
    },
    {
        countryCode: 'LB',
        countryID: 422,
        phone: {
            code: '961',
            mask: '+961-XX-XXX-XXXX',
            min: 11,
            max: 12,
        },
    },
    {
        countryCode: 'LC',
        countryID: 662,
        phone: {
            code: '1(758)',
            mask: '+1(758)XXX-XXXX',
        },
    },
    {
        countryCode: 'LI',
        countryID: 438,
        phone: {
            code: '423',
            mask: '+423(XXX)XXX-XXXX',
        },
    },
    {
        countryCode: 'LK',
        countryID: 144,
        phone: {
            code: '94',
            mask: '+94-XX-XXX-XXXX',
        },
    },
    {
        countryCode: 'LR',
        countryID: 430,
        phone: {
            code: '231',
            mask: '+231-XX-XXX-XXX',
        },
    },
    {
        countryCode: 'LS',
        countryID: 426,
        phone: {
            code: '266',
            mask: '+266-X-XXX-XXXX',
        },
    },
    {
        countryCode: 'LT',
        countryID: 440,
        phone: {
            code: '370',
            mask: '+370(XXX)XX-XXX',
        },
    },
    {
        countryCode: 'LU',
        countryID: 442,
        phone: {
            code: '352',
            mask: '+352(XXX)XXX-XXX',
        },
    },
    {
        countryCode: 'LV',
        countryID: 428,
        phone: {
            code: '371',
            mask: '+371-XX-XXX-XXX',
        },
    },
    {
        countryCode: 'LY',
        countryID: 434,
        phone: {
            code: '218',
            mask: '+218-XX-XXX-XXXXX',
            min: 12,
            max: 13,
        },
    },
    {
        countryCode: 'MA',
        countryID: 504,
        phone: {
            code: '212',
            mask: '+212-XX-XXXX-XXX',
        },
    },
    {
        countryCode: 'MC',
        countryID: 492,
        phone: {
            code: '377',
            mask: '+377XXXXXXXXXX',
            min: 12,
            max: 13,
        },
    },
    {
        countryCode: 'MD',
        countryID: 498,
        phone: {
            code: '373',
            mask: '+373-XXXX-XXXX',
        },
    },
    {
        countryCode: 'ME',
        countryID: 499,
        phone: {
            code: '382',
            mask: '+382-XX-XXX-XXX',
        },
    },
    {
        countryCode: 'MG',
        countryID: 450,
        phone: {
            code: '261',
            mask: '+261-XX-XX-XXXXX',
        },
    },
    {
        countryCode: 'MK',
        countryID: 807,
        phone: {
            code: '389',
            mask: '+389-XX-XXX-XXX',
        },
    },
    {
        countryCode: 'ML',
        countryID: 466,
        phone: {
            code: '223',
            mask: '+223-XX-XX-XXXX',
        },
    },
    {
        countryCode: 'MM',
        countryID: 104,
        phone: {
            code: '95',
            mask: '+95-XXX-XXXXXXXX',
            min: 8,
            max: 13,
        },
    },
    {
        countryCode: 'MN',
        countryID: 496,
        phone: {
            code: '976',
            mask: '+976-XX-XX-XXXX',
        },
    },
    {
        countryCode: 'MO',
        countryID: 446,
        phone: {
            code: '853',
            mask: '+853-XXXX-XXXX',
        },
    },
    {
        countryCode: 'MQ',
        countryID: 474,
        phone: {
            code: '596',
            mask: '+596(XXX)XX-XX-XX',
        },
    },
    {
        countryCode: 'MR',
        countryID: 478,
        phone: {
            code: '222',
            mask: '+222-XX-XX-XXXX',
        },
    },
    {
        countryCode: 'MS',
        countryID: 500,
        phone: {
            code: '1(664)',
            mask: '+1(664)XXX-XXXX',
        },
    },
    {
        countryCode: 'MT',
        countryID: 470,
        phone: {
            code: '356',
            mask: '+356-XXXX-XXXX',
        },
    },
    {
        countryCode: 'MU',
        countryID: 480,
        phone: {
            code: '230',
            mask: '+230-XXX-XXXX',
        },
    },
    {
        countryCode: 'MV',
        countryID: 462,
        phone: {
            code: '960',
            mask: '+960-XXX-XXXX',
        },
    },
    {
        countryCode: 'MW',
        countryID: 454,
        phone: {
            code: '265',
            mask: '+265-X-XXXX-XXXXX',
            min: 11,
            max: 13,
        },
    },
    {
        countryCode: 'MX',
        countryID: 484,
        phone: {
            code: '52',
            mask: '+52XXXXXXXXXXX',
            min: 11,
            max: 13,
        },
    },
    {
        countryCode: 'MY',
        countryID: 458,
        phone: {
            code: '60',
            mask: '+60XXXXXXXXXX',
            min: 10,
            max: 12,
        },
    },
    {
        countryCode: 'MZ',
        countryID: 508,
        phone: {
            code: '258',
            mask: '+258-XX-XXX-XXX',
        },
    },
    {
        countryCode: 'NA',
        countryID: 516,
        phone: {
            code: '264',
            mask: '+264-XX-XXX-XXXX',
        },
    },
    {
        countryCode: 'NC',
        countryID: 540,
        phone: {
            code: '687',
            mask: '+687-XX-XXXX',
        },
    },
    {
        countryCode: 'NE',
        countryID: 562,
        phone: {
            code: '227',
            mask: '+227-XX-XX-XXXX',
        },
    },
    {
        countryCode: 'NG',
        countryID: 566,
        phone: {
            code: '234',
            mask: '+234XXXXXXXXXXX',
            min: 11,
            max: 14,
        },
    },
    {
        countryCode: 'NI',
        countryID: 558,
        phone: {
            code: '505',
            mask: '+505-XXXX-XXXX',
        },
    },
    {
        countryCode: 'NL',
        countryID: 528,
        phone: {
            code: '31',
            mask: '+31-XX-XXX-XXXX',
        },
    },
    {
        countryCode: 'NO',
        countryID: 578,
        phone: {
            code: '47',
            mask: '+47(XXX)XX-XXX',
        },
    },
    {
        countryCode: 'NP',
        countryID: 524,
        phone: {
            code: '977',
            mask: '+977-XX-XXX-XXX',
        },
    },
    {
        countryCode: 'NR',
        countryID: 520,
        phone: {
            code: '674',
            mask: '+674-XXX-XXXX',
        },
    },
    {
        countryCode: 'NZ',
        countryID: 554,
        phone: {
            code: '64',
            mask: '+64XXXXXXXXXXX',
            min: 11,
            max: 13,
        },
    },
    {
        countryCode: 'OM',
        countryID: 512,
        phone: {
            code: '968',
            mask: '+968-XX-XXX-XXX',
        },
    },
    {
        countryCode: 'PA',
        countryID: 591,
        phone: {
            code: '507',
            mask: '+507-XXX-XXXX',
        },
    },
    {
        countryCode: 'PE',
        countryID: 604,
        phone: {
            code: '51',
            mask: '+51(XXX)XXX-XXX',
        },
    },
    {
        countryCode: 'PF',
        countryID: 258,
        phone: {
            code: '689',
            mask: '+689-XX-XX-XX',
        },
    },
    {
        countryCode: 'PG',
        countryID: 598,
        phone: {
            code: '675',
            mask: '+675(XXX)XX-XXX',
        },
    },
    {
        countryCode: 'PH',
        countryID: 608,
        phone: {
            code: '63',
            mask: '+63(XXX)XXX-XXXX',
        },
    },
    {
        countryCode: 'PK',
        countryID: 586,
        phone: {
            code: '92',
            mask: '+92(XXX)XXX-XXXX',
        },
    },
    {
        countryCode: 'PL',
        countryID: 616,
        phone: {
            code: '48',
            mask: '+48(XXX)XXX-XXX',
        },
    },
    {
        countryCode: 'PR',
        countryID: 630,
        phone: {
            code: '1787',
            mask: '',
        },
    },
    {
        countryCode: 'PS',
        countryID: 275,
        phone: {
            code: '970',
            mask: '+970-XX-XXX-XXXX',
        },
    },
    {
        countryCode: 'PT',
        countryID: 620,
        phone: {
            code: '351',
            mask: '+351-XX-XXX-XXXX',
        },
    },
    {
        countryCode: 'PY',
        countryID: 600,
        phone: {
            code: '595',
            mask: '+595(XXX)XXX-XXX',
        },
    },
    {
        countryCode: 'QA',
        countryID: 634,
        phone: {
            code: '974',
            mask: '+974-XXXX-XXXX',
        },
    },
    {
        countryCode: 'RE',
        countryID: 638,
        phone: {
            code: '262',
            mask: '+262-XXXXX-XXXX',
        },
    },
    {
        countryCode: 'RO',
        countryID: 642,
        phone: {
            code: '40',
            mask: '+40-XX-XXX-XXXX',
        },
    },
    {
        countryCode: 'RS',
        countryID: 688,
        phone: {
            code: '381',
            mask: '+381-XX-XXX-XXXX',
        },
    },
    {
        countryCode: 'RW',
        countryID: 646,
        phone: {
            code: '250',
            mask: '+250(XXX)XXX-XXX',
        },
    },
    {
        countryCode: 'SA',
        countryID: 682,
        phone: {
            code: '966',
            mask: '+966-X-XXXX-XXXXX',
            min: 12,
            max: 13,
        },
    },
    {
        countryCode: 'SB',
        countryID: 90,
        phone: {
            code: '677',
            mask: '+677-XXX-XXXXX',
            min: 9,
            max: 11,
        },
    },
    {
        countryCode: 'SC',
        countryID: 690,
        phone: {
            code: '248',
            mask: '+248-X-XXX-XXX',
        },
    },
    {
        countryCode: 'SD',
        countryID: 729,
        phone: {
            code: '249',
            mask: '+249-XX-XXX-XXXX',
        },
    },
    {
        countryCode: 'SE',
        countryID: 752,
        phone: {
            code: '46',
            mask: '+46-XX-XXX-XXXX',
        },
    },
    {
        countryCode: 'SG',
        countryID: 702,
        phone: {
            code: '65',
            mask: '+65-XXXX-XXXX',
        },
    },
    {
        countryCode: 'SI',
        countryID: 705,
        phone: {
            code: '386',
            mask: '+386-XX-XXX-XXX',
        },
    },
    {
        countryCode: 'SK',
        countryID: 703,
        phone: {
            code: '421',
            mask: '+421(XXX)XXX-XXX',
        },
    },
    {
        countryCode: 'SL',
        countryID: 694,
        phone: {
            code: '232',
            mask: '+232-XX-XXXXXX',
        },
    },
    {
        countryCode: 'SM',
        countryID: 674,
        phone: {
            code: '378',
            mask: '+378-XXXX-XXXXXX',
        },
    },
    {
        countryCode: 'SN',
        countryID: 686,
        phone: {
            code: '221',
            mask: '+221-XX-XXX-XXXX',
        },
    },
    {
        countryCode: 'SO',
        countryID: 706,
        phone: {
            code: '252',
            mask: '+252-XX-XXX-XXXX',
            min: 11,
            max: 12,
        },
    },
    {
        countryCode: 'SR',
        countryID: 740,
        phone: {
            code: '597',
            mask: '+597-XXX-XXXXX',
            min: 10,
            max: 11,
        },
    },
    {
        countryCode: 'ST',
        countryID: 678,
        phone: {
            code: '239',
            mask: '+239-XX-XXXXX',
        },
    },
    {
        countryCode: 'SV',
        countryID: 222,
        phone: {
            code: '503',
            mask: '+503-XX-XX-XXXX',
        },
    },
    {
        countryCode: 'SY',
        countryID: 760,
        phone: {
            code: '963',
            mask: '+963-XX-XXXX-XXX',
        },
    },
    {
        countryCode: 'SZ',
        countryID: 748,
        phone: {
            code: '268',
            mask: '+268-XX-XX-XXXX',
        },
    },
    {
        countryCode: 'TC',
        countryID: 796,
        phone: {
            code: '1(649)',
            mask: '+1(649)XXX-XXXX',
        },
    },
    {
        countryCode: 'TD',
        countryID: 148,
        phone: {
            code: '235',
            mask: '+235-XX-XX-XX-XX',
        },
    },
    {
        countryCode: 'TG',
        countryID: 768,
        phone: {
            code: '228',
            mask: '+228-XX-XXX-XXX',
        },
    },
    {
        countryCode: 'TH',
        countryID: 764,
        phone: {
            code: '66',
            mask: '+66-XX-XXX-XXXXX',
            min: 11,
            max: 12,
        },
    },
    {
        countryCode: 'TJ',
        countryID: 762,
        phone: {
            code: '992',
            mask: '+992-XX-XXX-XXXX',
        },
    },
    {
        countryCode: 'TL',
        countryID: 626,
        phone: {
            code: '670',
            mask: '+670-XXX-XXXXXX',
            min: 11,
            max: 12,
        },
    },
    {
        countryCode: 'TM',
        countryID: 795,
        phone: {
            code: '993',
            mask: '+993-X-XXX-XXXX',
        },
    },
    {
        countryCode: 'TN',
        countryID: 788,
        phone: {
            code: '216',
            mask: '+216-XX-XXX-XXX',
        },
    },
    {
        countryCode: 'TO',
        countryID: 776,
        phone: {
            code: '676',
            mask: '+676-XXXXX',
        },
    },
    {
        countryCode: 'TR',
        countryID: 792,
        phone: {
            code: '90',
            mask: '+90(XXX)XXX-XXXX',
        },
    },
    {
        countryCode: 'TT',
        countryID: 780,
        phone: {
            code: '1(868)',
            mask: '+1(868)XXX-XXXX',
        },
    },
    {
        countryCode: 'TW',
        countryID: 158,
        phone: {
            code: '886',
            mask: '+886XXXXXXXXXX',
            min: 12,
            max: 13,
        },
    },
    {
        countryCode: 'TZ',
        countryID: 834,
        phone: {
            code: '255',
            mask: '+255-XX-XXX-XXXX',
        },
    },
    {
        countryCode: 'UA',
        countryID: 804,
        phone: {
            code: '380',
            mask: '+380(XX)XXX-XX-XX',
        },
    },
    {
        countryCode: 'UG',
        countryID: 800,
        phone: {
            code: '256',
            mask: '+256(XXX)XXX-XXX',
        },
    },
    {
        countryCode: 'UY',
        countryID: 858,
        phone: {
            code: '598',
            mask: '+598-X-XXX-XX-XX',
        },
    },
    {
        countryCode: 'UZ',
        countryID: 860,
        phone: {
            code: '998',
            mask: '+998-XX-XXX-XXXX',
        },
    },
    {
        countryCode: 'VC',
        countryID: 670,
        phone: {
            code: '1(784)',
            mask: '+1(784)XXX-XXXX',
        },
    },
    {
        countryCode: 'VE',
        countryID: 862,
        phone: {
            code: '58',
            mask: '+58(XXX)XXX-XXXX',
        },
    },
    {
        countryCode: 'VG',
        countryID: 92,
        phone: {
            code: '1(284)',
            mask: '+1(284)XXX-XXXX',
        },
    },
    {
        countryCode: 'VN',
        countryID: 704,
        phone: {
            code: '84',
            mask: '+84XXXXXXXXXXX',
            min: 11,
            max: 13,
        },
    },
    {
        countryCode: 'VU',
        countryID: 548,
        phone: {
            code: '678',
            mask: '+678XXXXXXXX',
            min: 9,
            max: 11,
        },
    },
    {
        countryCode: 'YE',
        countryID: 887,
        phone: {
            code: '967',
            mask: '+967XXXXXXXXXX',
            min: 11,
            max: 13,
        },
    },
    {
        countryCode: 'YT',
        countryID: 175,
        phone: {
            code: '262',
            mask: '+262-XXXXX-XXXX',
        },
    },
    {
        countryCode: 'ZA',
        countryID: 710,
        phone: {
            code: '27',
            mask: '+27-XX-XXX-XXXX',
        },
    },
    {
        countryCode: 'ZM',
        countryID: 894,
        phone: {
            code: '260',
            mask: '+260-XX-XXX-XXXX',
        },
    },
    {
        countryCode: 'ZW',
        countryID: 716,
        phone: {
            code: '263',
            mask: '+263-X-XXXXXX',
        },
    },
    {
        countryCode: 'US',
        countryID: 840,
        phone: {
            code: '1',
            mask: '+1(XXX)XXX-XXXX',
        },
    },
    {
        ...BASE_WW_PHONE,
    },
];

export const getPhoneDataByCountryCode = (code: string): IPhone => {
    if (BANNED_COUNTRY.includes(code.toUpperCase())) return BASE_WW_PHONE;

    const findPhoneData = phones.find(
        (phone) => phone.countryCode.toLowerCase() === code.toLowerCase()
    );

    return findPhoneData || BASE_WW_PHONE;
};

export const getPhoneDataByPhoneNumber = (number: string): IPhone => {
    const normalizeNumber = number.replace(/\D/g, '');

    const findPhoneData = phones.find(({ phone }) => {
        if (!phone.code) return;

        if (phone.regex) {
            return phone.regex.test(normalizeNumber);
        }

        const normalizeCode = phone.code.replace(/\D/g, '');

        const strRegExPattern = `^${normalizeCode}`;

        return normalizeNumber.match(new RegExp(strRegExPattern, 'g'));
    });

    return findPhoneData || BASE_WW_PHONE;
};
