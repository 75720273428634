import { ApolloClient, InMemoryCache } from '@apollo/client';
import { GetServerSidePropsContext } from 'next';

import { isSsr } from 'utils/isSsr';
import makeCmsLink from './makeCmsLink';
import possibleTypes from './possibleTypes';
import typePolicies from './typePolicies';

export interface CmsClientOptions {
    endpoint: string;
    locale: string;
    isDebugMode: boolean;
    headersCookieObj: Record<string | number, unknown>;
    req?: GetServerSidePropsContext['req'];
    res?: GetServerSidePropsContext['res'];
}

class CmsApolloClient extends ApolloClient<any> {
    /**
     * @param {string} endpoint Example: "//host/graphql" or "http://host/graphql"
     */
    constructor({
        locale,
        endpoint,
        isDebugMode,
        headersCookieObj,
        req,
    }: CmsClientOptions) {
        super({
            ssrMode: isSsr(),
            cache: new InMemoryCache({
                possibleTypes: possibleTypes.possibleTypes,
                typePolicies,
            }),
            connectToDevTools: true,
            link: makeCmsLink({
                locale,
                endpoint,
                isDebugMode,
                headersCookieObj,
                req,
            }),
        });
    }
}

export { CmsApolloClient };
