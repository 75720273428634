import { findKey } from 'lodash';

import { AppLink } from 'app-constants';
import { TupleSplitString } from 'types';

const localeRegex = /^(\/[a-z-]+)(\/|$)/; // `remove locale: input -`/en/slug`, output `/slug`
const dynamicPartRegex = /\[(sportSlug|categorySlug|slug)]/; // if value has dynamic part like slug/categorySLug/sportsSlug

export function getNameFromUrl(url: string): string {
    const splitUrlWithoutQuery = url.split('?') as TupleSplitString;
    const urlWithoutQuery = splitUrlWithoutQuery[0];
    const regexCache: { [key: string]: RegExp } = {};

    return (
        findKey(AppLink, (value) => {
            let replacedUrl = urlWithoutQuery.replace(localeRegex, '/');

            if (dynamicPartRegex.test(value)) {
                const regex =
                    regexCache[value] ||
                    (regexCache[value] = new RegExp(
                        value.replace(/\[.*?\]/g, '([^/]+)')
                    ));

                if (regex.test(replacedUrl)) {
                    replacedUrl = replacedUrl.replace(regex, value);
                }
            }

            return replacedUrl === value;
        }) || 'Dynamic Page'
    );
}
