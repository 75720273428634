import { useEffect, useState } from 'react';

import { useIsEnableSSR } from 'layouts/Root/EnableSSRProvider/EnableSSRContext';
import { CmsClientOptions } from 'services/apollo/cms/CmsApolloClient';
import makeCmsLink from 'services/apollo/cms/makeCmsLink';
import { createEndpoint } from 'services/apollo/cms/utils/createEndpoint';
import { logError } from 'services/logger';
import FeatureFlagsManager from './FeatureFlagsManager';
import FeatureFlagsManagerStub from './FeatureFlagsManagerStub';
import { FeatureFlags } from './types';
import { settleFeatureFlags } from './utils';

interface Input {
    parsedFeatures: FeatureFlags;
    cmsClientOptions: Omit<CmsClientOptions, 'req' | 'endpoint'>;
    endpoint: string;
}

interface Output {
    features: FeatureFlagsManager;
}

const useClientInitFeatureFlags = ({
    parsedFeatures,
    cmsClientOptions,
    endpoint,
}: Input): Output => {
    const isSSREnabled = useIsEnableSSR();

    const shouldInitFF = !(parsedFeatures || isSSREnabled);

    const [features, setFeatures] = useState<FeatureFlagsManager>(
        !shouldInitFF
            ? FeatureFlagsManager.init().set(parsedFeatures)
            : (FeatureFlagsManagerStub.init() as FeatureFlagsManager)
    );

    useEffect(() => {
        if (!shouldInitFF || !cmsClientOptions) return;

        const apolloCmsLinks = makeCmsLink({
            ...cmsClientOptions,
            endpoint: createEndpoint({
                endpoint,
                host: window.location.host,
                protocol: 'http',
            }),
        });

        const realFeatureFlagManager = FeatureFlagsManager.init();
        settleFeatureFlags({
            context: {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                featureFlagManager: realFeatureFlagManager,
                apolloCmsLinks,
            },
        })
            .then(() => {
                setFeatures(realFeatureFlagManager);
            })
            .catch((e) => {
                logError(e);
            });
    }, [cmsClientOptions, endpoint, shouldInitFF]);

    return { features };
};

export default useClientInitFeatureFlags;
