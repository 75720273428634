import { useCallback } from 'react';
import { getCookie } from 'cookies-next';

import { CookiesType } from 'app-constants';
import { useLogout as useLogoutMutation } from 'gql/cms/mutations/logout.cms';
import { useLogout as useLogoutHook, useMainLoader } from 'hooks';

interface Output {
    logoutCallback: VoidFunction;
}

const useLogout = (): Output => {
    const [handleLogoutCMS] = useLogoutMutation();
    const { logoutHandler } = useLogoutHook();
    const { showLoader, hideLoader } = useMainLoader();

    const logoutCallback = useCallback(() => {
        const authToken = getCookie(CookiesType.AuthToken);
        const token = typeof authToken === 'string' ? authToken : null;

        showLoader();
        handleLogoutCMS({ variables: { token } })
            .then(logoutHandler)
            .catch((err) => {
                console.error(err);
                hideLoader();
            });
    }, [handleLogoutCMS, hideLoader, logoutHandler, showLoader]);

    return {
        logoutCallback,
    };
};

export default useLogout;
