import { useCallback } from 'react';
import { useMatches, useNavigate } from 'react-router-dom';

import { useClientContext } from 'layouts/Root/ClientRenderContext';
import { RouterPush } from './types';
import { navigateLocalized, removeQueryParamsFromUrl } from './utils';

interface Input {
    locale: string;
}

interface Output extends RouterPush {}

const useNavigationPush = ({ locale }: Input): Output => {
    const isClient = useClientContext();
    const navigate = useNavigate();
    const [{ params }] = useMatches();
    // NOTE: Rerender push useCallback exactly when params are changed
    const stringParams = JSON.stringify(params);

    const push: RouterPush['push'] = useCallback(
        (url, options) => {
            if (!isClient) {
                console.warn(
                    'Navigate is not ready for push! This url was not pushed',
                    url
                );

                return;
            }

            navigateLocalized(
                navigate,
                locale,
                removeQueryParamsFromUrl(url, JSON.parse(stringParams)),
                {
                    ...options,
                    replace: options?.replace || false,
                    state: options?.data || {},
                }
            );
        },
        [isClient, locale, navigate, stringParams]
    );

    return {
        push,
    };
};

export default useNavigationPush;
