import { useEffect, useState } from 'react';

interface Input {
    date: Date;
    timeout?: number;
}

export interface Output {
    days: string;
    hours: string;
    minutes: string;
    seconds: string;
    isExpiredDate?: boolean;
}

const SECOND = 1000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;

const useCountdown = ({ date, timeout = MINUTE }: Input): Output => {
    const countDownDate = new Date(date).getTime();
    const now = +new Date();
    const past = +new Date(date);
    const isExpiredDate = now > past;

    const [countDown, setCountDown] = useState(
        countDownDate - new Date().getTime()
    );

    useEffect(() => {
        const interval = setInterval(() => {
            setCountDown(countDownDate - new Date().getTime());
        }, timeout);

        if (countDownDate - new Date().getTime() < 0) {
            clearInterval(interval);
        }

        return () => clearInterval(interval);
    }, [countDownDate, timeout]);

    const resultValues = isExpiredDate
        ? { days: '00', hours: '00', minutes: '00', seconds: '00' }
        : getReturnValues(countDown);

    return { ...resultValues, isExpiredDate };
};

const getReturnValues = (countDown: number) => {
    // calculate time left
    const days = Math.floor(countDown / DAY)
        .toString()
        .padStart(2, '0');
    const hours = Math.floor((countDown % DAY) / HOUR)
        .toString()
        .padStart(2, '0');
    const minutes = Math.floor((countDown % HOUR) / MINUTE)
        .toString()
        .padStart(2, '0');
    const seconds = Math.floor((countDown % MINUTE) / SECOND)
        .toString()
        .padStart(2, '0');

    return { days, hours, minutes, seconds };
};

export default useCountdown;
