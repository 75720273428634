import { useMemo } from 'react';
import { includes } from 'lodash';

import { KYC_APPROVED_STATUSES, KYC_STATUS } from 'app-constants';
import { useGetDocumentsStatus } from 'gql/cms/queries/kyc/getDocumentsStatus.cms';
import { useGetFilesHistory } from 'gql/cms/queries/kyc/getFilesHistory.cms';
import { useGetKyc } from 'gql/cms/queries/kyc/getKyc.cms';
import { RequiredDocumentStatusTypeEnum } from 'types/gql.cms';
import useAuthorization from './useAuthorization';

interface Output {
    isShowKYC: boolean;
    isApprovedStatus: boolean;
    kycStatus: string;
    documentsStatus?: RequiredDocumentStatusTypeEnum;
}

const useKYC = (): Output => {
    const { isRegistrationFinished } = useAuthorization();
    const skip = !isRegistrationFinished;

    const { data: kycData } = useGetKyc(() => ({
        skip,
    }));
    const { data: documentsStatusData } = useGetDocumentsStatus(() => ({
        skip,
    }));
    const { data: filesHistoryData } = useGetFilesHistory(() => ({
        skip,
    }));

    const kycStatus = kycData?.kyc.status || '';
    const documentsStatus =
        documentsStatusData?.requiredDocumentsStatus?.status;
    const hasDocuments = Boolean(filesHistoryData?.documents?.documents.length);
    const isApprovedStatus = includes(KYC_APPROVED_STATUSES, kycStatus);

    const isShowKYC: boolean = useMemo(() => {
        return (
            hasDocuments ||
            kycStatus === KYC_STATUS.Declined ||
            (documentsStatus ===
                RequiredDocumentStatusTypeEnum.StatusWaitingRequest &&
                isApprovedStatus) ||
            documentsStatus !==
                RequiredDocumentStatusTypeEnum.StatusWaitingRequest
        );
    }, [hasDocuments, kycStatus, documentsStatus, isApprovedStatus]);

    return { isShowKYC, isApprovedStatus, kycStatus, documentsStatus };
};

export default useKYC;
