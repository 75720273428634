export enum Category {
    FiltersLive = 'Filters - Live',
    FiltersNextToGo = 'Filters - Next-to-go',
    FiltersFeatured = 'Filters - Featured',
    FiltersCategorizer = 'Filters - Categorizer',
    BannerTopSlider = 'Banner - Top slider',
    HomeMainBanner = 'main_banner_click',
    BannerTopTournamentSlider = 'Banner - Top Tournament slider',
    BannerRight = 'Banner - Right',
    BannerOutright = 'Banner - Outright',
    BannerOverview = 'Banner - Overview',
    BetHistoryRecommendationBlockBet = 'BetHistory-RecommendationBlockBet',
    LineOutright = 'Line - Outright',
    LineSportEvent = 'Line - Sport event',
    MatchHeader = 'Match - Header',
    MatchMarkets = 'Match - Markets',
    AppCategory = 'App',
    Betslip = 'Betslip',
    Unknown = 'Unknown',
    HeaderLine = 'Header line',
    SignIn = 'Sign In line',
    SignUp = 'Sign Up line',
    FreezedBanner = 'Freezed_banner',
    Footer = 'Footer',
    ToHomeFrom = 'To home from',
    Language = 'language',
    NewsMatchBlock = 'News - Match block',
    NewsTournamentBlock = 'News - Tournament block',
    Applend = 'Applend',
    RgComplete = 'RG complete from Account',
}

export enum Action {
    ClickOnLink = 'Click on link',
    // Live and Next to Go filters
    Open = 'Open',
    FiltersTime = 'Filter by time',
    //  Featured filters
    SelectTournament = 'Select tournament',
    DeselectTournament = 'Deselect tournament',
    ToggleMore = 'Toggle more',
    // Categorizer filters
    SelectSport = 'Select sport',
    DeselectSport = 'Deselect sport',
    SelectSubgroup = 'Select subgroup',
    DeselectSubgroup = 'Deselect subgroup',

    OpenSport = 'Open sport',
    CloseSport = 'Close sport',
    OpenSubgroup = 'Open subgroup',
    CloseSubgroup = 'Close subgroup',
    SelectAllTournamentsSport = 'Select all tournaments in sport',
    ClearTournamentsSport = 'Clear tournaments in sport',
    // Top sport events
    FilterSport = 'Filter by sport',
    SelectOdd = 'Select odd',
    SelectMoreMarkets = 'Select more markets',
    ClickOnTitle = 'Click on title',
    // Banner
    ClickOnImage = 'Click on image',
    ClickOnButton = 'Click on button',
    ChangeSlide = 'Change slide',
    RedirectToSportEvent = 'Redirect to sport event',
    // Line sports
    OpenFullscreen = 'Open fullscreen',
    HidePromo = 'Hide promo',
    WatchPromo = 'Watch promo',
    SelectPromoOdd = 'Select promo odd',
    PlayStream = 'Play stream',
    PauseStream = 'Pause stream',
    ToggleFavorite = 'Toggle favorite',
    // Match-header
    GoBack = 'Go back',
    ChangeTab = 'Change tab',
    StatisticsBtnClick = 'match statistic button click',
    LineSorting = 'Line sorting',
    // Match markets
    ToggleMarketGroup = 'Toggle market group',
    BetboosterOdd = 'Betbooster Odd',
    // Location Change
    LocationChange = 'Location Change',
    ChangePage = 'Change Page',
    GoToEventView = 'Go to event view',
    // Betslip
    PlaceBet = 'Place Bet',
    DeclineBet = 'Decline Bet',
    AcceptedBet = 'Accepted Bet',
    PlaceBetMultiSingle = 'Place Bet Multi Single',
    SelectSuggest = 'Select suggest',
    PlaceOdd = 'Place Odd',
    OpenSignIn = 'Open sign in popup',
    OpenSignUp = 'Open sign up popup',
    ForgotPassword = 'Click.log.in.form.forgot.password',
    Click = 'click',
    ClickSocialNetw = 'click Social network',
    RegistrationSuccess = 'Registration success',
    ToggleCountrySelect = 'Toggle select choose country',
    CloseLanguageSwitcher = 'Close language switcher',
    OpenLanguageSwitcher = 'Open language switcher',
    RegistrationSocial = 'Registration social network',
    AuthorizationSocial = 'Authorization social network',
    AuthorizationVia = 'Authorization via',
    LoginButton = 'Login button',
    RegistrationButton = 'Registration button',
    LoginSuccess = 'Login success',
    LoginError = 'Login error',
    ToLoginFromRegistration = 'To login from registration',
    ToRegistrationFromLogin = 'To registration from login',
    CloseRegistration = 'Close registration',
    CloseLogin = 'Close login',
    PasswordRecoverySubmit = 'Click.forgot.password.form.submit',
    PasswordRecoveryFormCancel = 'Click.forgot.password.form.cancel',
    PasswordRecoveryFormNewCancel = 'Click.new.password.form.cancel',
    PasswordRecoveryNewSubmit = 'Click.new.password.form.submit',
    PasswordRecoveryNewOpen = 'Open.new.password.form',
    PasswordRecoveryFormInput = 'Click.forgot.password.form.input',
    PasswordRecoveryResend = 'Click.password.recovery.code.form.resend',
    PasswordRecoveryNext = 'Click.password.recovery.code.form.next',
    PasswordRecoveryIncorrectCode = 'Click.password.recovery.code.form.incorrect.code',
    PasswordRecoveryError = 'Click.forgot.password.form.error.cannot.be.sent.more.than.twice.per.hour',
    PasswordRecoverySuccess = 'Click.forgot.password.form.success',
    ClickForgotInput = 'Click.forgot.password.input',
    DownloadFromBanner = 'app-notification-download-button',
    DownloadFromMenu = 'download-app-from-menu',
    DownloadIos = 'download-ios-app',
    DownloadApk = 'download-android-app',
    DailyDeposit = 'Daily deposit limit:',
    WeeklyDeposit = 'Weekly deposit limit:',
    MonthlyDeposit = 'Monthly deposit limit:',
    DailyGaming = 'Daily gaming time limit:',
    WeeklyGaming = 'Weekly gaming time limit:',
    RgFromCabinet = 'from cabinet',
}
export enum Label {
    Open = 'open',
    Close = 'close',
    Remove = 'remove',
    Add = 'add',
    Daily = 'daily',
    Weekly = 'weekly',
    Monthly = 'monthly',
    MaxBet = 'max bet button',
}

export enum AnalyticsAttr {
    category = 'data-category',
    action = 'data-action',
    label = 'data-label',
}

export const SearchParamsKeys = [
    'sportId',
    'overlayType',
    'tournamentId',
] as const;
