import { useCallback } from 'react';
import { setCookie } from 'cookies-next';

import { CookiesType, ModalsHash } from 'app-constants';
import { useRouter } from 'components/HybridRouter';
import useModal from 'components/modals/useModal';
import getFirstDepositFlow from 'gql/cms/queries/getFirstDepositFlow.cms.gql';
import getGtmDataInfo from 'gql/cms/queries/getGtmDataInfo.cms.gql';
import { useOnUpdateRechargeBalance } from 'gql/platform/subscriptions/onUpdateRechargeBalance.ptm';
import { useAuthorization } from 'hooks';
import { usePagePropsContext } from 'layouts/Root/PagePropsContext';
import { useLazyQuery } from 'services/apollo/cms';
import { GTMEvent, sendGTM } from 'services/GoogleTagManager';
import {
    GetFirstDepositFlow,
    GetFirstDepositFlowVariables,
    GetGtmDataInfo,
    GetGtmDataInfoVariables,
} from 'types/gql.cms';
import { OnUpdateRechargeBalance } from 'types/gql.ptm';

const useOnUpdateRechargeBalanceSubscription = (): void => {
    const { isAuthorized, profileInfo } = useAuthorization();
    const { uuid } = usePagePropsContext();
    const { openModal } = useModal();
    const { asPathObj } = useRouter();
    const hash = asPathObj?.hash || '';

    const [fetchGtmDataInfo] = useLazyQuery<
        GetGtmDataInfo,
        GetGtmDataInfoVariables
    >(getGtmDataInfo);

    const playerId: string =
        profileInfo?.user?.__typename === 'Player' ? profileInfo.user.id : '';

    const handleSportRadar = useCallback(
        async (depositData?: OnUpdateRechargeBalance['rechargeBalance']) => {
            if (!depositData) return;

            try {
                const { data } = await fetchGtmDataInfo();
                const { sum, currencyCode } = depositData;
                const { totalDepositCount = 0 } = data?.gtmDataInfo.stats || {};
                const depositEvents = data?.gtmDataInfo.events.filter(
                    ({ type }) => type === 'payment.player.deposit_successful'
                );

                const sortedDepositEvents = depositEvents?.sort((a, b) =>
                    b.createdAt && a.createdAt
                        ? new Date(b.createdAt).getTime() -
                          new Date(a.createdAt).getTime()
                        : 0
                );

                const transactionId =
                    sortedDepositEvents?.[0]?.data?.transactionId?.local;

                if (!transactionId) return;

                if (totalDepositCount === 1) {
                    window.srtmCommands?.push({
                        event: 'track.betting.deposit',
                        payload: {
                            action: 'created',
                            label: 'first_deposit',
                            userId: playerId,
                            transactionId,
                            amount: sum,
                            currency: currencyCode,
                        },
                    });
                }

                window.srtmCommands?.push({
                    event: 'track.betting.deposit',
                    payload: {
                        action: 'created',
                        userId: playerId,
                        transactionId,
                        amount: sum,
                        currency: currencyCode,
                    },
                });

                sendGTM({
                    event: GTMEvent.Dep,
                    transactionId,
                    uuid,
                });
            } catch (e) {
                console.error(e);
            }
        },
        [fetchGtmDataInfo, playerId, uuid]
    );

    const [fetchFirstDepositFLow] = useLazyQuery<
        GetFirstDepositFlow,
        GetFirstDepositFlowVariables
    >(getFirstDepositFlow, { fetchPolicy: 'cache-and-network' });

    useOnUpdateRechargeBalance({
        async onData({ data: { data } }) {
            const firstDeposit = await fetchFirstDepositFLow();

            const paseto =
                firstDeposit?.data?.firstRequiredDepositFlow.paseto || '';

            if (paseto) {
                setCookie(CookiesType.Paseto, paseto);
            }

            if (hash !== ModalsHash.Deposit) {
                openModal({ hash: ModalsHash.TransactionSuccess, data });
            }

            handleSportRadar(data?.rechargeBalance);
        },
        skip: !isAuthorized,
    });
};

export default useOnUpdateRechargeBalanceSubscription;
