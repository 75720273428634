import { useCallback, useEffect, useMemo, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { getCookie, setCookies } from 'cookies-next';
import addDays from 'date-fns/addDays';

import {
    CookiesType,
    CustomAppLink,
    KeyValue,
    WidgetNames,
} from 'app-constants';
import { useWidget } from 'components/Widget';
import useKeyValue from 'hooks/useKeyValue';
import { appBannerVisibleVar, setAppBannerVisibleVar } from './appBannerState';

interface Output {
    isIos: boolean;
    hideBanner: VoidFunction;
    isShowAppBanner: boolean;
    widget: string;
    linkToApp: string;
}

const BANNER_SHOW_TIME = 60;
const IOS_REGEX = /%ios:([^%]+)%/i;
const ANDROID_REGEX = /%android:([^%]+)%/i;

const useAppBanner = (): Output => {
    const isShowAppBanner = useReactiveVar(appBannerVisibleVar);
    const [isIos, setIsIos] = useState<boolean>(false);
    const [timeLeft, setTimeLeft] = useState<number>(BANNER_SHOW_TIME);
    const [showBanner, setShowBanner] = useState<boolean>(false);
    const isActiveTimer = timeLeft > 0;

    const { value: showBannerAndroid } = useKeyValue({
        key: KeyValue.SHOW_BANNER_ANDROID_APP,
    });
    const { value: showBannerIos } = useKeyValue({
        key: KeyValue.SHOW_BANNER_IOS_APP,
    });

    const { widget: initialWidget } = useWidget({
        name: WidgetNames.APP_BANNER_TEXT,
    });

    const linkToApp = useMemo(() => {
        const params = getCookie(CookiesType.LinkParams);

        return params ? `${CustomAppLink.APP}?${params}` : CustomAppLink.APP;
    }, []);

    const hideBanner = useCallback(() => {
        setShowBanner(false);

        setCookies(CookiesType.AppBanner, 1, {
            path: '/',
            expires: addDays(new Date(), 3650),
        });
    }, []);

    useEffect(() => {
        const showOnIos = isIos && Number(showBannerIos);
        const showOnAndroid = !isIos && Number(showBannerAndroid);

        const isAppBannerVisible = Boolean(
            (showOnIos || showOnAndroid) && showBanner && !isActiveTimer
        );

        setAppBannerVisibleVar(isAppBannerVisible);
    }, [isIos, showBanner, showBannerIos, isActiveTimer, showBannerAndroid]);

    useEffect(() => {
        const { userAgent } = navigator;
        const isAppleDevice = /iphone|ipad|ipod/i.test(userAgent);

        if (!isAppleDevice) return;

        setIsIos(isAppleDevice);
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            if (timeLeft <= 0) {
                clearInterval(interval);

                return;
            }

            setTimeLeft((prev) => prev - 1);
        }, 1000);

        return () => clearInterval(interval);
    }, [timeLeft]);

    useEffect(() => {
        const bannerCookies = getCookie(CookiesType.AppBanner);

        if (bannerCookies) return;

        setShowBanner(true);

        return () => {
            setAppBannerVisibleVar(false);
        };
    }, []);

    const widget = useMemo(() => {
        const regexTemplate = isIos ? IOS_REGEX : ANDROID_REGEX;

        return extractText(initialWidget, regexTemplate);
    }, [initialWidget, isIos]);

    return {
        isIos,
        hideBanner,
        isShowAppBanner,
        widget,
        linkToApp,
    };
};

const extractText = (text: string, regex: RegExp) => {
    const match = regex.exec(text);

    return match && match.length ? match[1].trim() : '';
};

export default useAppBanner;
