import type { MutableRefObject } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { omit } from 'lodash';

import type { AlertHandlers, IAlertManager } from './context';

const ALERT_NODE_ID = 'alert';

interface Output {
    alertHandlers: AlertHandlers;
    alertElements: IAlertManager;
    ref: MutableRefObject<HTMLElement | null>;
    elements: Record<string, JSX.Element>;
}

const useAlertManager = (): Output => {
    const ref = useRef<HTMLElement | null>(null);
    const [elements, setElements] = useState<Record<string, JSX.Element>>({});

    const addAlertHandler = useCallback((id: string, alert: JSX.Element) => {
        setElements((prevElements) => ({
            ...prevElements,
            [id]: alert,
        }));
    }, []);

    const removeAlertHandler = useCallback((id: string) => {
        setElements((prevElements) => ({
            ...omit(prevElements, [id]),
        }));
    }, []);

    const alertElements: IAlertManager = useMemo(
        () => ({ elements }),
        [elements]
    );

    const alertHandlers: AlertHandlers = useMemo(
        () => ({
            addAlertHandler,
            removeAlertHandler,
        }),
        [addAlertHandler, removeAlertHandler]
    );

    useEffect(() => {
        const alertElement = document.getElementById(ALERT_NODE_ID);

        if (alertElement) ref.current = alertElement;
    }, []);

    return {
        alertHandlers,
        alertElements,
        ref,
        elements,
    };
};

export default useAlertManager;
