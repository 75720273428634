import { FetchPolicy } from '@apollo/client';
import { CookieValueTypes, getCookie } from 'cookies-next';
import type { BettingOptions } from 'types';

import { BatchKeys, CookiesType, StorageKey } from 'app-constants';
import { getServerPageGetBettingSettings } from 'gql/betting/queries/getBettingSettings.cms';
import type { WithMainApolloClientContext } from 'services/apollo';
import { getBettingOptionsResult } from '../../utils';
import { getServerPageGetBettingData } from './getBettingData.cms';

interface Params {
    context: WithMainApolloClientContext;
    headers?: Record<string, string | undefined>;
    fetchPolicy?: FetchPolicy;
}
async function getServerBettingOptions({
    headers,
    context,
}: Params): Promise<BettingOptions> {
    const { req } = context;

    const pasetoToken: CookieValueTypes | undefined = getCookie(
        CookiesType.Paseto,
        {
            req,
        }
    );
    const [bettingData, bettingSettings] = await Promise.all([
        getServerPageGetBettingData({
            context: {
                token: pasetoToken,
                batchKey: BatchKeys.InitBetting,
                headers,
            },
        })(context),
        getServerPageGetBettingSettings({
            context: {
                batchKey: BatchKeys.InitBetting,
            },
        })(context),
    ]);

    const isDebugMode = Boolean(
        getCookie(StorageKey.DEBUG_INFO, {
            req,
        })
    );

    return getBettingOptionsResult(bettingData, bettingSettings, isDebugMode);
}

export default getServerBettingOptions;
