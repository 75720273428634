import React from 'react';
import cn from 'classnames';
import { getCookie } from 'cookies-next';
import type { GetServerSideProps, PreviewData } from 'next';
import { ParsedUrlQuery } from 'querystring';

import {
    BatchKeys,
    CookiesType,
    KeyValue,
    LayoutType,
    MAIN_LAYOUT_MESSAGES,
} from 'app-constants';
import AlertManager from 'components/AlertManager';
import AnalyticsSettings from 'components/AnalyticsSettings';
import AppAlert from 'components/AppAlert';
import AppRedirect from 'components/AppRedirect';
import { AuthorizationManager } from 'components/AuthorizationManager';
import BonusProgram from 'components/betting/BonusProgram';
import { getServerPageGetActiveMessages } from 'components/BroadcastBanner/getActiveMessages.cms';
import CookiesAgreements from 'components/CookiesAgreements';
import DeepLinkMatchData from 'components/DeepLinkMatchData';
import FirstRequiredDeposit from 'components/FirstRequiredDeposit';
import Helper from 'components/Helper';
import InboxCenterPreview from 'components/inboxCenter/InboxCenterPreview';
import KycRedirect from 'components/KycRedirect';
import MainLoader from 'components/MainLoader/MainLoader';
import MenuNav from 'components/MenuNav';
import ModalManager from 'components/modals';
import SupportButton from 'components/SupportButton';
import { getTypeDevice } from 'components/utilities/MobileDetect';
import { getServerPageGetKeyValue } from 'gql/cms/queries/getKeyValue.cms';
import {
    GetServerSidePropsWithApolloClients,
    GetServerSidePropsWithMainApolloClient,
    makeWithServerProps,
    withCmsClient,
    WithMainApolloClientContext,
} from 'services/apollo';
import { AppCache } from 'services/cache';
import GoogleAnalytics from 'services/GoogleAnalytics';
import { getServerSideMessages } from 'utils/intl';
import { isSsr } from 'utils/isSsr';
import Footer, {
    getFooterBettingServerSideProps,
    getFooterCasinoServerSideProps,
} from './Footer';
import { getServerPageGetRelevantCurrency } from './getRelevantCurrency.cms';
import Header, { getHeaderServerSideProps } from './Header';
import HeadSeoTags, { getSeoRulesBySideProps } from './HeadSeoTags';
import MainSubscriptions from './subscription';
import useMainLayout from './useMainLayout';

type Props = {
    children: React.ReactNode;
};

type MakeWithMainLayoutServerPropsInput = {
    gsspHandler?: GetServerSidePropsWithApolloClients;
    layout?: LayoutType;
};

const MainLayout: React.FC<Props> = ({ children }) => {
    const {
        isBlur,
        isMobileGameLayout,
        isAuthorized,
        layoutType,
        mainLayoutType,
        isBonusProgram,
        isMobile,
        profileInfo,
    } = useMainLayout();

    return (
        <>
            <GoogleAnalytics />
            <AlertManager>
                <AuthorizationManager>
                    {isAuthorized && <FirstRequiredDeposit />}
                    <div
                        className={cn('mx-auto', {
                            'max-w-[1984px]': layoutType !== LayoutType.Game,
                        })}
                    >
                        <HeadSeoTags />

                        {!isMobileGameLayout && <Header />}

                        <InboxCenterPreview type={layoutType} />

                        {!!profileInfo && (
                            <KycRedirect profileInfo={profileInfo} />
                        )}

                        <DeepLinkMatchData />

                        <main
                            className={cn('mb-8 min-h-[50vh]', {
                                'blur-[2px]': isBlur && !isMobile,
                            })}
                        >
                            {isBonusProgram ? (
                                <div className="h-[calc(100vh-100px)]">
                                    <BonusProgram />
                                </div>
                            ) : (
                                children
                            )}
                        </main>
                        {!isSsr() && (
                            <ModalManager layoutType={mainLayoutType} />
                        )}
                        {layoutType !== LayoutType.Game && isMobile && (
                            <MenuNav />
                        )}

                        {!isMobile && <SupportButton />}

                        {layoutType !== LayoutType.Game && <Footer />}

                        <CookiesAgreements />
                    </div>
                    <AppAlert />
                    <MainSubscriptions />
                </AuthorizationManager>
            </AlertManager>
            <Helper />
            <AppRedirect />
            <AnalyticsSettings />
            <MainLoader />
        </>
    );
};

export const getPageSettingsServerSideProps: GetServerSideProps = async (
    ctx
) => {
    const { isMobile } = getTypeDevice(ctx);

    return {
        props: {
            isMobile,
        },
    };
};

async function getServerPageSentryConfig(ctx: WithMainApolloClientContext) {
    try {
        const sentryConfig = await getServerPageGetKeyValue({
            variables: {
                key: KeyValue.SENTRY_CONFIG,
            },
            context: {
                batchKey: BatchKeys.GetKeyValue,
            },
        })(ctx);

        const {
            sampleRate,
            replaysOnErrorSampleRate,
            replaysSessionSampleRate,
        } = JSON.parse(sentryConfig.keyValue.value);

        return {
            props: {
                sentryConfig: {
                    replaysOnErrorSampleRate,
                    sampleRate,
                    replaysSessionSampleRate,
                },
                gsspData: {
                    keyValue: {
                        [KeyValue.SENTRY_CONFIG]: sentryConfig,
                    },
                },
            },
        };
    } catch (e) {
        console.error(e);

        return {
            props: {},
        };
    }
}

export const makeWithMainLayoutServerProps = ({
    layout = LayoutType.Betting,
    gsspHandler,
}: MakeWithMainLayoutServerPropsInput): GetServerSideProps => {
    const handlers: (
        | GetServerSideProps<
              {
                  [key: string]: any;
              },
              ParsedUrlQuery,
              PreviewData
          >
        | GetServerSidePropsWithMainApolloClient<Dict<any>>
    )[] = [
        getServerPageSentryConfig,
        async (context: WithMainApolloClientContext) => {
            try {
                const relevantCurrency = await getServerPageGetRelevantCurrency(
                    async (ctx) => {
                        const token = getCookie(CookiesType.Paseto, {
                            req: ctx.req,
                        });

                        return {
                            context: {
                                token,
                            },
                        };
                    }
                )(context);

                return {
                    props: {
                        gsspData: { relevantCurrency },
                    },
                };
            } catch (e) {
                console.error(e);
            }

            return {
                props: {},
            };
        },
        async (context: WithMainApolloClientContext) => {
            const cacheOptions = await getServerPageGetKeyValue({
                variables: {
                    key: KeyValue.CACHE_OPTIONS,
                    default: '',
                },
                context: {
                    batchKey: BatchKeys.GetKeyValue,
                    skipGlobalCache: true,
                },
                fetchPolicy: 'no-cache',
            })(context);

            const cache = AppCache.getInstance();
            cache.setOptions(cacheOptions?.keyValue.value);

            return {
                props: {},
            };
        },
        async (context: WithMainApolloClientContext) => {
            try {
                const activeMessages =
                    await getServerPageGetActiveMessages()(context);

                return {
                    props: {
                        gsspData: { activeMessages },
                    },
                };
            } catch (e) {
                console.error(e);
            }

            return {
                props: {},
            };
        },
        // NOTE: We can't check correct breakpoint in server side. Now we download whole messages('MobileNavbar','MobileMenu')
        getServerSideMessages(MAIN_LAYOUT_MESSAGES),
        getPageSettingsServerSideProps,
        async (ctx: WithMainApolloClientContext) => {
            return {
                props: {
                    host: ctx.req.headers.host,
                },
            };
        },
    ];

    const gsspBylayoutType =
        layout === LayoutType.Casino
            ? getFooterCasinoServerSideProps
            : getFooterBettingServerSideProps;

    return withCmsClient(
        makeWithServerProps(
            handlers.concat(
                getSeoRulesBySideProps,
                getHeaderServerSideProps,
                gsspBylayoutType
            )
        ),
        layout
    )(gsspHandler);
};

export default MainLayout;
