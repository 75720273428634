import { FC, memo } from 'react';
import { GetServerSideProps } from 'next';

import { withClientRender } from 'hocs';
import { getServerSideMessages } from 'utils';
import HeaderAuthorizationButtons from './HeaderAuthorizationButtons';
import HeaderGuestButtons from './HeaderGuestButtons';

interface Props {
    isAuthorized: boolean;
}

const HeaderButtons: FC<Props> = ({ isAuthorized }) =>
    isAuthorized ? <HeaderAuthorizationButtons /> : <HeaderGuestButtons />;

export const getHeaderButtonServerSideProps: GetServerSideProps =
    getServerSideMessages(['HeaderButtons']);

export default memo(withClientRender(HeaderButtons));
