import { ApolloLink } from '@apollo/client';
import { GetServerSidePropsContext, NextApiRequest } from 'next';
import { NextRequest } from 'next/server';

import { isSsr } from 'utils/isSsr';
import createCookieHeader from '../utils/createCookieHeader';

type Request =
    | GetServerSidePropsContext['req']
    | NextRequest
    | NextApiRequest
    | undefined;

const makeHeadersLink = (
    headers: Record<string, any>,
    req?: Request
): ApolloLink =>
    new ApolloLink((operation, forward) => {
        let cookie = '';

        if (isSsr() && req) {
            const hasCookieHeader = Object.entries(headers).find(
                ([key]) => key.toLowerCase() === 'cookie'
            );
            cookie = !hasCookieHeader ? createCookieHeader(req.cookies) : '';
        }

        operation.setContext((context: Record<string, any>) => ({
            ...context,
            headers: {
                cookie,
                ...headers,
                ...context.headers,
            },
        }));

        return forward(operation);
    });

export default makeHeadersLink;
