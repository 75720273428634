import { useEffect, useState } from 'react';
import { debounce } from 'lodash';

interface Output {
    showImage: boolean;
    showPopup: boolean;
}

const IMAGE_TIMEOUT = 200;
const POPUP_TIMEOUT = 400;

const useEuroPopup = (): Output => {
    const [showImage, setShowImage] = useState(false);
    const [showPopup, setShowPopup] = useState(false);

    const debouncedImage = debounce(() => setShowImage(true), IMAGE_TIMEOUT);
    const debouncedPopup = debounce(() => setShowPopup(true), POPUP_TIMEOUT);

    useEffect(() => {
        debouncedImage();
        debouncedPopup();

        return () => {
            debouncedImage.cancel();
            debouncedPopup.cancel();
        };
    }, [debouncedImage, debouncedPopup]);

    return {
        showImage,
        showPopup,
    };
};

export default useEuroPopup;
