import { AppLink, CustomAppLink, SportTypeLink } from 'app-constants';
import { LinkOptions } from '../types';

export const initialDefaultMenuLinks: LinkOptions[] = [
    {
        textKey: 'bets',
        link: AppLink.BETS,
        iconId: 'bets',
        isDefault: true,
    },
];

export const initialMenuLinks: LinkOptions[] = [
    {
        textKey: 'casino',
        link: AppLink.CASINO,
        iconId: 'casino',
    },
    {
        textKey: 'promotions',
        link: AppLink.BETTINGPROMOTIONS,
    },
    {
        textKey: 'tournament',
        link: AppLink.BETTINGTOURNAMENTS_SLUG,
    },
    {
        textKey: 'result',
        link: AppLink.RESULTS,
    },
    {
        textKey: 'out_rights',
        link: AppLink.OUTRIGHTS,
    },
    {
        textKey: 'statistics',
        link: AppLink.SPORTSTATISTICS,
    },
];

export const initialHiddenMenuLinks: LinkOptions[] = [
    {
        textKey: 'news',
        link: CustomAppLink.NEWS,
        isDefault: true,
    },
    {
        textKey: 'sport',
        link: SportTypeLink.Sports,
        isDefault: true,
    },
    {
        textKey: 'e_sport',
        link: SportTypeLink.Esports,
        isDefault: true,
    },
];

export const HEADER_BETTING_SETTINGS_ID = 'betting-settings';
