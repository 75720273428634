import { createContext } from 'react';
import { noop } from 'lodash';

export interface IAuthorization {
    authenticated: boolean;
    setAuthenticated: (isAuth: boolean) => void;
    handleRefetch: VoidFunction;
}

const AuthorizationContext = createContext<IAuthorization>({
    authenticated: false,
    setAuthenticated: () => noop,
    handleRefetch: () => noop,
});

export { AuthorizationContext };
