import { makeVar } from '@apollo/client';

import { Message } from 'types/gql.cms';

export const isProgressAnimatingVar = makeVar<boolean>(false);

export const isPreviewAnimatingVar = makeVar<boolean>(false);

export const previewMessageVar = makeVar<Message | null>(null);

export const setIsProgressAnimatingVar = (flag: boolean): void => {
    isProgressAnimatingVar(flag);
};

export const setIsPreviewAnimatingVar = (flag: boolean): void => {
    isPreviewAnimatingVar(flag);
};

export const setPreviewMessageVar = (message: Message | null): void => {
    previewMessageVar(message);
};
