import { useCallback, useContext } from 'react';

import { AuthorizationContext } from 'components/AuthorizationManager/context';
import { useGetProfile } from 'gql/cms/queries/getProfile.cms';
import type { GetProfile } from 'types/gql.cms';

interface Output {
    isAuthorized: boolean;
    isRegistrationFinished: boolean;
    profileLoading: boolean;
    profileInfo?: GetProfile;
    handleLogin: VoidFunction;
    handleLogout: VoidFunction;
    handleRefetch: VoidFunction;
}

const useAuthorization = (): Output => {
    const { data: profile, loading: profileLoading } = useGetProfile();

    const { authenticated, setAuthenticated, handleRefetch } =
        useContext(AuthorizationContext);

    const handleLogin = useCallback(() => {
        setAuthenticated(true);
    }, [setAuthenticated]);

    const handleLogout = useCallback(() => {
        setAuthenticated(false);
    }, [setAuthenticated]);

    const isAuthorized = authenticated || Boolean(profile?.user);

    const isPlayerRole = Boolean(
        profile?.user?.roles.find((role) => role === 'ROLE_PLAYER')
    );

    const isRegistrationFinished = authenticated && isPlayerRole;

    return {
        isAuthorized,
        isRegistrationFinished,
        profileLoading,
        profileInfo: profile,
        handleLogin,
        handleLogout,
        handleRefetch,
    };
};

export default useAuthorization;
