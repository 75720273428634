import React from 'react';
import type { LocaleData } from 'i18n-iso-countries';
import { GetServerSidePropsResult, Redirect } from 'next';

import {
    CookiesType,
    KeyValue,
    LayoutType,
    OverlayType,
    SportType,
    StorageKey,
    WidgetNames,
} from 'app-constants';
import type { IPhoneData } from 'components/modals/RegistrationModal/RegistrationForm/types';
import { SvgIconSize } from 'components/SvgIcon';
import { SportListId } from 'layouts/MainLayout/Footer/types';
import { WithMainApolloClientContext } from 'services/apollo';
import type { BettingApolloClientOptions } from 'services/apollo/betting/BettingApolloClient';
import type { BettingInitError } from 'services/apollo/betting/utils/getBettingInitError';
import type { CmsClientOptions } from 'services/apollo/cms/CmsApolloClient';
import type { FeatureFlags } from 'services/features';
import type {
    AmericanFootball,
    Basketball,
    BrawlStars,
    Cricket,
    Csgo,
    Dota2,
    EBasketball,
    EFootball,
    EStreetball,
    ETennis,
    EVolleyball,
    Football,
    Futsal,
    GetCategorizer,
    GetFeaturedTournaments,
    GetMatchBySlug,
    GetOutrightBySlug,
    GetOutrights,
    GetOutrightSportsList,
    GetPlayerBySlug,
    GetSportEventListByFilters,
    GetTeamBySlug,
    GetTournamentBySlug,
    Hearthstone,
    IceHockey,
    IndoorSoccer,
    KingOfGlory,
    Lol,
    LolWildrift,
    Mma,
    MortalKombat,
    Overwatch,
    StarCraft,
    StarCraft1,
    TableTennis,
    Tennis,
    Volleyball,
} from 'types/gql.bet';
import {
    GetActiveMessages,
    GetBettingSportById,
    GetCurrentMirror,
    GetDynamicRouteByPath,
    GetGameBySlug,
    GetGames,
    GetGamesCategories,
    GetGamesVendors,
    GetGameThemes,
    GetKeyValue,
    GetLocaleMenu,
    GetNews,
    GetNewsDetails,
    GetPagesUrlBySlug,
    GetPrevNextNewsDetails,
    GetRecommendedGames,
    GetRelevantCurrency,
    GetSeoRulesByPage,
    GetStaticPageData,
    GetSubLogo,
    GetTournamentByFilters,
    GetWidgetByName,
    GetWinners,
    GetWinnersByPeriod,
} from 'types/gql.cms';
import { EnvValues } from 'utils/getInitialServerSideProps';

export interface Icon {
    id: string;
    viewBox: string;
}

export interface IconData {
    icon: Icon;
    clickHandler?: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
    className?: string;
    size?: SvgIconSize;
    dataTest?: string;
}

export type Id = string | number;

export interface BettingRouterQuery {
    sportId?: string;
    sportSlug?: string;
    tournamentId?: string;
    tournamentIds?: string | string[];
    slug?: string;
    overlayType?: OverlayType;
    deeplink?: string;
    search?: string;
}

export type Primitive =
    | null
    | undefined
    | string
    | number
    | boolean
    | symbol
    | bigint;

export type ExtraProps<T extends object, U = T[keyof T]> = T & {
    [key: string]: U | undefined;
};

export type OmitExtraProps<T> = T extends ExtraProps<infer U> ? U : T;

export interface CmsRouterQuery {
    pages?: string[];
    collectionSlug?: string;
}

export interface RouterQuery extends BettingRouterQuery, CmsRouterQuery {}

export interface IPhone {
    countryCode: string;
    countryID: number;
    phone: IPhoneData;
}

export interface IModalData {
    data?: any;
    layoutType: LayoutType;
}

export const CookiesKeys = {
    ...StorageKey,
    ...CookiesType,
};

export type Cookies = {
    [key in (typeof CookiesKeys)[keyof typeof CookiesKeys]]?: string;
};

export interface Sport {
    icon?: string;
    sportType: SportType;
}

export type Sports = Record<string, Sport>;

export type Overview =
    | AmericanFootball
    | Basketball
    | BrawlStars
    | Csgo
    | Dota2
    | EBasketball
    | EFootball
    | ETennis
    | EVolleyball
    | Football
    | IceHockey
    | IndoorSoccer
    | KingOfGlory
    | Lol
    | LolWildrift
    | TableTennis
    | Tennis
    | Volleyball
    | Futsal
    | EStreetball
    | Mma
    | StarCraft1
    | StarCraft
    | Cricket
    | Overwatch
    | Hearthstone
    | MortalKombat;

export interface AppLend {
    mainH1: string;
    description: string;
    appLink: string;
    descriptionQr: string;
    qrCode: string;
    phoneImage: string;
    phoneMobImage: string;
    isoCode: string;
}

export interface HowToInstallApp {
    title: string;
    subtitle: string;
    steps: string[];
}

export interface AppIosNotAllowed {
    mainH1: string;
    manualList: AppManualItem[];
    phoneImage: string;
    phoneMobImage: string;
}

export interface AppManualItem {
    description: string;
    image: string;
}

export type DataLoaderPayload =
    | {
          messages?: Dict<{}>;
      }
    | undefined
    | null;

export interface PublicEnv {
    PUBLIC_CMS_GQL_CLIENT_ENDPOINT: string;
    PUBLIC_PLATFORM_GQL_CLIENT_ENDPOINT: string;
    PUBLIC_NEXT_GA_KEY: string;
    APP_VERSION: string;
    APP_ENV: 'development' | 'production';
    SENTRY_ENVIRONMENT: string;
    CMS_GQL_SSR_BATCHING_ENABLE: string;
    CMS_SSR_ENABLE: string;
    CMS_SSR_CASINO_ENABLE: string;
}

export type FooterSportLinks = NonNullable<
    GetLocaleMenu['localeMenu']
>['items'];
export type FooterSportListByIds = Record<SportListId, FooterSportLinks>;

export interface GetServerSidePageData {
    sportEvents?: GetSportEventListByFilters | null;
    pinnedSportEvents?: GetSportEventListByFilters | null;
    outrights?: GetOutrights;
    outrightBySlug?: GetOutrightBySlug;
    categorizer?: GetCategorizer;
    result?: GetMatchBySlug;
    currentMatch?: GetMatchBySlug;
    tournament?: GetTournamentBySlug;
    player?: GetPlayerBySlug;
    outrightSportsList?: GetOutrightSportsList;
    dynamicPageProps?: GetDynamicRouteByPath['dynamicRouteByPath'];
    casinoGames?: GetGames | null;
    popularGames?: GetGames | null;
    newGames?: GetGames | null;
    liveGames?: GetGames | null;
    liveCasinoGames?: GetGames | null;
    slotsGames?: GetGames | null;
    tableGames?: GetGames | null;
    instaGames?: GetGames | null;
    casinoGameBySlug?: GetGameBySlug;
    gameThemes?: GetGameThemes | null;
    currentMirror?: GetCurrentMirror;
    countryTranslations?: LocaleData;
    layoutType?: LayoutType | null;
    widgets?: Partial<Record<WidgetNames, GetWidgetByName>>;
    gamesCategories?: GetGamesCategories | null;
    footerSportListByIds?: FooterSportListByIds;
    footerProvidersListByIds?: NonNullable<
        GetLocaleMenu['localeMenu']
    >['items'];
    footerInfoLinks?: GetPagesUrlBySlug;
    dynamicRouteByPath?: GetDynamicRouteByPath;
    staticPageData?: GetStaticPageData;
    news?: GetNews;
    newsDetailsPrev?: GetPrevNextNewsDetails | null;
    newsDetailsNext?: GetPrevNextNewsDetails | null;
    newsDetail?: GetNewsDetails;
    subLogo?: GetSubLogo;
    tournamentsByFilters?: GetTournamentByFilters;
    relevantCurrency?: GetRelevantCurrency;
    activeMessages?: GetActiveMessages;
    seoRulesByPage?: GetSeoRulesByPage;
    playerTeamSeo?: {
        seoText: GetBettingSportById['bettingSportById'];
        sport: {
            id: string;
            name: string;
        };
    };
    team?: GetTeamBySlug;
    resultedMatchList?: GetSportEventListByFilters | null;
    upcomingMatchList?: GetSportEventListByFilters | null;
    featuredTournaments?: GetFeaturedTournaments | null;
    keyValue?: Partial<Record<KeyValue, GetKeyValue>>;
    winners?: GetWinners | null;
    winnersByPeriod?: GetWinnersByPeriod | null;
    recommendedGames?: GetRecommendedGames;
    gamesVendors?: GetGamesVendors | null;
}

export interface PageProps {
    isAuthorized: boolean;
    bettingClientOptions: BettingApolloClientOptions;
    bettingInitError?: BettingInitError;
    cmsClientOptions: Omit<CmsClientOptions, 'req' | 'endpoint'>;
    uuid: string;
    refCode: string;
    affData: string;
    env: PublicEnv;
    isDebug: boolean;
    isMobile: boolean;
    messages: Partial<Messages>;
    notSupportedFeatures: string[];
    parsedFeatures: FeatureFlags;
    sportIds: string[];
    host: string;
    gsspData?: GetServerSidePageData;

    sentryConfig?: {
        replaysOnErrorSampleRate: number;
        sampleRate: number;
        replaysSessionSampleRate: number;
    };
    mirrors_setup_first?: string;
    mirrors_setup_second?: string;
}

export type GetServerSidePagePropsData = {
    gsspData?: PageProps['gsspData'];
    messages?: PageProps['messages'] | null;
    env?: EnvValues;
};

export type MakeGSSPArray<
    C extends WithMainApolloClientContext = WithMainApolloClientContext,
> = (...args: any[]) => Array<
    (ctx: C) => Promise<{
        props?:
            | GetServerSidePagePropsData
            | Promise<GetServerSidePagePropsData>
            | undefined;
        redirect?: Redirect;
        notFound?: boolean | undefined;
    }>
>;

export type GetServerSidePageProps =
    GetServerSidePropsResult<GetServerSidePagePropsData>;

export type SportEventsInList = NonNullable<
    GetSportEventListByFilters['matches']['sportEvents']
>;

export type PartialMatch = SportEventsInList[number];

export type PartialMatchMarkets = PartialMatch['markets'];
export type PartialMatchMarket = PartialMatchMarkets[number];
export type PartialMatchOdds = PartialMatchMarket['odds'];

export type OutrightType = GetOutrights['sportEventsByFilters'];

export type PartialOutright = OutrightType[number];
export type PartialCompetitor =
    PartialOutright['fixture']['competitors'][number];
export type PartialOutrightMarkets = PartialOutright['markets'];
export type PartialOutrightMarket = PartialOutright['markets'][number];
export type OutrightCompetitors =
    | GetOutrights['sportEventsByFilters'][number]['fixture']['competitors']
    | undefined;

export type CompetitorsList = Record<string, PartialCompetitor>;

export interface BettingOptions {
    bettingClientOptions: BettingApolloClientOptions;
    sportIds: string[];
}

export type TupleSplitString = [string, ...string[]];
