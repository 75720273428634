import { FC } from 'react';
import cn from 'classnames';
import { useTranslations } from 'next-intl';

import { useMount } from 'hooks';
import { useGsspData } from 'layouts/Root/PagePropsContext';
import { useBettingApolloClient } from 'services/apollo';
import FooterSportLinks from '../FooterSportLinks';
import { SportListId } from '../types';

export const footerSportListIds = [
    SportListId.FooterBettingESports,
    SportListId.FooterBettingSports,
];

const FooterSportList: FC = () => {
    const t = useTranslations('Footer');
    const { initBettingClient } = useBettingApolloClient();
    const { footerSportListByIds } = useGsspData();

    useMount(() => {
        initBettingClient();
    });

    const container = cn(
        'mt-8 grid w-full grid-cols-1 border-b-default border-solid border-surface-middle pb-8 md:grid-cols-2'
    );
    const list = cn('grid grid-cols-1 sm:grid-cols-2');
    const wrapper = cn('mb-6 text-md font-bold text-primary-orange-toxic');

    return (
        <div className={container} data-test="footer-sport-list">
            {footerSportListIds.map((id) => (
                <div key={id} data-test={`footer-sport-list__item ${id}`}>
                    <div
                        className={wrapper}
                        data-test="footer-sport-list__title"
                    >
                        {id === SportListId.FooterBettingESports
                            ? t('gincms.footer.title.e_sports')
                            : t('gincms.footer.title.sports')}
                    </div>
                    <div className={list}>
                        <FooterSportLinks
                            sportList={footerSportListByIds?.[id] || []}
                            id={id}
                        />
                    </div>
                </div>
            ))}
        </div>
    );
};

export default FooterSportList;
