import * as GQL from 'types/gql.bet';

import * as Operations from './onUpdateEVolleyballOverviews.bet.gql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'services/apollo/betting';
const defaultOptions = {} as const;

/**
 * __useOnUpdateEVolleyballOverviews__
 *
 * To run a query within a React component, call `useOnUpdateEVolleyballOverviews` and pass it any options that fit your needs.
 * When your component renders, `useOnUpdateEVolleyballOverviews` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnUpdateEVolleyballOverviews({
 *   variables: {
 *      sportEventIDs: // value for 'sportEventIDs'
 *   },
 * });
 */
export function useOnUpdateEVolleyballOverviews(
    baseOptions: ApolloReactHooks.SubscriptionHookOptions<
        GQL.OnUpdateEVolleyballOverviews,
        GQL.OnUpdateEVolleyballOverviewsVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSubscription<
        GQL.OnUpdateEVolleyballOverviews,
        GQL.OnUpdateEVolleyballOverviewsVariables
    >(Operations.OnUpdateEVolleyballOverviews, options);
}
export type OnUpdateEVolleyballOverviewsHookResult = ReturnType<
    typeof useOnUpdateEVolleyballOverviews
>;
export type OnUpdateEVolleyballOverviewsSubscriptionResult =
    Apollo.SubscriptionResult<GQL.OnUpdateEVolleyballOverviews>;
