export const APP_ENV_STATUS = {
    DEVELOPMENT: 'development',
    PRODUCTION: 'production',
} as const;

const APP_ENV = process.env.APP_ENV;

type OutputType = {
    isDevelopment: boolean;
    isProduction: boolean;
};

export const verifyEnvironment = (): OutputType => {
    const isDevelopment = APP_ENV === APP_ENV_STATUS.DEVELOPMENT;
    const isProd = APP_ENV === APP_ENV_STATUS.PRODUCTION;

    return {
        isDevelopment,
        isProduction: isProd,
    };
};
