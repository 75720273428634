import { useCallback, useRef } from 'react';

interface Output {
    isReady: boolean;
    toReady: VoidFunction;
}

const useReady = (): Output => {
    const isReadyRef = useRef<boolean>(false);

    const toReady = useCallback(() => {
        isReadyRef.current = true;
    }, []);

    return { isReady: isReadyRef.current, toReady };
};

export default useReady;
