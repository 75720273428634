import type { GetServerSidePropsResult } from 'next';

import { logError } from 'services/logger';
import { captureSentryException } from 'services/sentry';
import { getBettingTranslationsServerSideProps } from 'utils/intl';
import { APOLLO_BETTING_LINK } from '../constants';
import type {
    GetServerSidePropsWithApolloClients,
    WithApolloClientsContext,
} from '../types';
import setExtendedContext from '../utils/setExtendedContext';
import { lazyInjectBettingOptionsToContext } from './lazyInjectBettingOptionsToContext';
import { makeBettingLink } from './makeBettingLink';

export default function withBettingClient(
    handler: GetServerSidePropsWithApolloClients
): GetServerSidePropsWithApolloClients {
    return async function nextGetServerSidePropsHandlerWrapped(
        context: WithApolloClientsContext
    ): Promise<GetServerSidePropsResult<Dict>> {
        if (context.bettingInitError) {
            return {
                props: {
                    bettingInitError: context.bettingInitError,
                },
            };
        }

        try {
            if (!context.bettingOptions) {
                await lazyInjectBettingOptionsToContext({
                    context,
                });
            }

            const bettingClientOpts =
                context?.bettingOptions?.bettingClientOptions;
            const sportIds = context?.bettingOptions?.sportIds;

            const apolloBettingLinkOpts = makeBettingLink({
                ...bettingClientOpts,
                endpoint:
                    process.env.BETTING_SSR_ENDPOINT ||
                    bettingClientOpts?.endpoint,
                req: context.req,
            });

            setExtendedContext(
                context,
                APOLLO_BETTING_LINK,
                apolloBettingLinkOpts
            );

            setExtendedContext(context, 'sportIds', sportIds);

            const result = await handler(context as WithApolloClientsContext);

            let resultProps = {};

            if ('props' in result) {
                resultProps = result.props;
            }

            const notFound = 'notFound' in result ? result.notFound : undefined;
            const redirect = 'redirect' in result ? result.redirect : undefined;

            return {
                notFound,
                redirect,
                props: {
                    ...resultProps,
                    bettingClientOptions: bettingClientOpts,
                    sportIds,
                },
            };
        } catch (err) {
            const parsetError = err as Error;

            logError({
                message: 'Betting initialization aborted',
                err,
                request: context.req,
            });

            captureSentryException({
                label: 'withBettingClient',
                message:
                    parsetError?.message || 'Betting initialization aborted',
            });

            return getBettingTranslationsServerSideProps(context);
        }
    };
}
