import { onError } from '@apollo/client/link/error';

import isHandledBackendLimitExceptions from '../cms/errorHandler/isHandledBackendLimitExceptions';

const responseErrorLink = onError(({ networkError }) => {
    if (!networkError) return;

    isHandledBackendLimitExceptions(networkError);
});

export default responseErrorLink;
