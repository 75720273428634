import { FC } from 'react';

import Spinner from 'components/Spinner';
import FooterSeoContent from 'layouts/MainLayout/Footer/FooterSeo/FooterSeoContent';
import HeadSeoTagsContent from 'layouts/MainLayout/HeadSeoTags/HeadSeoTagsContent';
import { PageProps } from 'types';
import { GetSeoRulesByPage } from 'types/gql.cms';

interface Input {
    pageProps: PageProps;
}

const CriticalSeoSSRPage: FC<Input> = ({ pageProps }) => {
    const seoRulesByPage = pageProps.gsspData?.seoRulesByPage;
    const currentMirror = pageProps.gsspData?.currentMirror;

    return (
        <div className="flex h-full flex-col justify-between">
            <HeadSeoTagsContent
                seo={seoRulesByPage?.seoRulesByPage.seo}
                info={seoRulesByPage?.seoRulesByPage.info}
                currentMirrorInfo={currentMirror?.currentMirror}
            />
            <div className="flex h-screen items-center justify-center self-center">
                <Spinner />
            </div>

            {!!seoRulesByPage && (
                <MinimizedFooter seoRulesByPage={seoRulesByPage} />
            )}
        </div>
    );
};

function MinimizedFooter({
    seoRulesByPage,
}: {
    seoRulesByPage: GetSeoRulesByPage;
}) {
    const texts = seoRulesByPage.seoRulesByPage.seo?.texts || [];
    const pageHeader = seoRulesByPage.seoRulesByPage.seo?.pageHeader || '';

    return (
        <FooterSeoContent texts={texts} pageHeader={pageHeader} isOpen={true} />
    );
}

export default CriticalSeoSSRPage;
