import { useCallback, useMemo } from 'react';

import { ModalsHash } from 'app-constants';
import { useRouter, useRouterPush } from 'components/HybridRouter';
import useRouterData from 'hooks/useRouterData';
import { removeLocaleFromUrl } from '../HybridRouter/utils';
import { parsedSearchParams } from './utils';

interface OpenModalInput {
    hash: ModalsHash;
    data?: any;
    redirect?: string;
    destinationPage?: string;
}

type OpenModal = (payload: OpenModalInput) => void;
type CloseModal = (shouldRedirect?: boolean) => void;
type ModalActions = () => {
    openModal: OpenModal;
    closeModal: CloseModal;
    modalsHash: typeof ModalsHash;
};

const useModal: ModalActions = () => {
    const { asPathObj } = useRouter();
    const { search, pathname } = asPathObj || {};
    const { query } = useRouterData();

    const { push } = useRouterPush();

    const searchParams = useMemo(() => parsedSearchParams(search), [search]);

    const openModal: OpenModal = useCallback(
        ({ hash, data, redirect = '', destinationPage }) => {
            const encodedRedirectPath = encodeURIComponent(
                removeLocaleFromUrl(redirect)
            );
            const modalPath = redirect
                ? `${hash}?r=${encodedRedirectPath}`
                : hash;

            const targetPathName = destinationPage || pathname;

            push(
                {
                    query: searchParams,
                    hash: modalPath,
                    pathname: targetPathName,
                },
                { replace: true, data }
            );
        },
        [pathname, push, searchParams]
    );

    const closeModal: CloseModal = useCallback(
        (shouldRedirect = false) => {
            const redirect =
                typeof query.r === 'string'
                    ? decodeURIComponent(removeLocaleFromUrl(query.r))
                    : '';

            if (!shouldRedirect || !redirect) {
                push({ query: search, pathname }, { replace: true });

                return;
            }

            push(redirect, { replace: true });
        },
        [query.r, push, search, pathname]
    );

    return {
        openModal,
        closeModal,
        modalsHash: ModalsHash,
    };
};

export default useModal;
