export interface PossibleTypesResultData {
    possibleTypes: {
        [key: string]: string[];
    };
}
const result: PossibleTypesResultData = {
    possibleTypes: {
        Banner: [
            'BannerBettingLinkedSport',
            'BannerBettingLiveOfTheDay',
            'BannerBettingMatchOfTheDay',
            'BannerBettingSportEvent',
            'BannerBettingSportEventGeo',
            'BannerBettingTournament',
            'BannerBettingTournamentGeo',
            'BannerCMS',
        ],
        BetCondition: [
            'BetCompetitorCondition',
            'BetCountryCondition',
            'BetDetailsConditionAggregate',
            'BetMarketTypeCondition',
            'BetOddValueCondition',
            'BetSportCondition',
            'BetSportEventCondition',
            'BetTournamentCondition',
            'BetTypeCondition',
        ],
        BetDeclineContextItem: ['BetDeclineContextRestrictions'],
        BetDetailCondition: [
            'BetCombinationsCountBetDetailsCondition',
            'BetCompetitorBetDetailsCondition',
            'BetMarketTypeBetDetailsCondition',
            'BetOddsCoefficientBetDetailsCondition',
            'BetOddsCountBetDetailsCondition',
            'BetSportBetDetailsCondition',
            'BetSportEventBetDetailsCondition',
            'BetStakeAmountRangeBetDetailsCondition',
            'BetSystemSizeBetDetailsCondition',
            'BetTotalOddsCoefficientBetDetailsCondition',
            'BetTournamentBetDetailsCondition',
        ],
        CashOutRestriction: [
            'BaselineSelectionExistenceRestriction',
            'BetProviderRestriction',
            'BetSelectionExistenceRestriction',
            'BetStatusRestriction',
            'CashOutAmountLimitRestriction',
            'CashOutBetSelectionsMismatchRestriction',
            'CashOutBetTypeRestriction',
            'CashOutOrderFreebetRestriction',
            'CashOutOrderStatusRestriction',
            'CashOutRefundAmountRestriction',
            'CashOutUnavailableRestriction',
            'MarketDefectiveRestriction',
            'MarketExistenceRestriction',
            'MarketStatusRestriction',
            'OddExistenceRestriction',
            'OddStatusRestriction',
            'PlayerLimitRestriction',
            'SelectionValueRestriction',
            'SportEventBetStopRestriction',
            'SportEventExistenceRestriction',
            'SportEventStatusRestriction',
            'SportEventTypeRestriction',
        ],
        Competitor: ['Player', 'Team'],
        EmbeddedVideoLinkResult: [
            'EmbeddedVideoLink',
            'EmbeddedVideoLinkForbiddenError',
        ],
        FeaturedZoneConfigProvider: [
            'FeaturedZoneCustomListProvider',
            'FeaturedZoneTournamentProvider',
        ],
        FreebetBetDetailCondition: [
            'FreebetCombinationsCountBetDetailsCondition',
            'FreebetCompetitorBetDetailsCondition',
            'FreebetMarketTypeBetDetailsCondition',
            'FreebetOddsCoefficientBetDetailsCondition',
            'FreebetOddsCountBetDetailsCondition',
            'FreebetSportBetDetailsCondition',
            'FreebetSportEventBetDetailsCondition',
            'FreebetSystemSizeBetDetailsCondition',
            'FreebetTotalOddsCoefficientBetDetailsCondition',
            'FreebetTournamentBetDetailsCondition',
        ],
        FreebetCondition: [
            'FreebetBetDetailsConditionAggregate',
            'FreebetBetTypeCondition',
            'FreebetCompetitorCondition',
            'FreebetCountryCondition',
            'FreebetMarketTypeCondition',
            'FreebetOddValueCondition',
            'FreebetSportCondition',
            'FreebetSportEventCondition',
            'FreebetTournamentCondition',
        ],
        GridEmbedVideoTokenResult: [
            'GridEmbedVideoToken',
            'GridEmbedVideoTokenForbiddenError',
        ],
        Overview: [
            'AmericanFootballOverview',
            'BadmintonOverview',
            'BaseballOverview',
            'BasketballOverview',
            'BattlegroundsMultiCompetitiveOverview',
            'BattlegroundsOverview',
            'BeachVolleyballOverview',
            'BrawlStarsOverview',
            'CSGOOverview',
            'CallOfDutyWarzoneOverview',
            'CricketOverview',
            'Dota2Overview',
            'EBasketballOverview',
            'EFootballOverview',
            'EIceHockeyOverview',
            'EStreetballOverview',
            'ETennisOverview',
            'EUFCOverview',
            'EVolleyballOverview',
            'FootballOverview',
            'FortniteOverview',
            'FutsalOverview',
            'HandballOverview',
            'HearthstoneOverview',
            'IceHockeyOverview',
            'IndoorSoccerOverview',
            'KabaddiOverview',
            'KingOfGloryOverview',
            'LOLOverview',
            'LOLWildriftOverview',
            'MMAOverview',
            'MortalKombatOverview',
            'OverwatchOverview',
            'RainbowSixOverview',
            'RocketLeagueOverview',
            'StarCraft1Overview',
            'StarCraftOverview',
            'TableTennisOverview',
            'TennisOverview',
            'ValorantOverview',
            'VolleyballOverview',
        ],
        PlayerStatistic: [
            'PlayerStatisticCSGO',
            'PlayerStatisticDota2',
            'PlayerStatisticHOTS',
            'PlayerStatisticHearthstone',
            'PlayerStatisticLOL',
            'PlayerStatisticOverwatch',
            'PlayerStatisticPUBG',
            'PlayerStatisticTotal',
        ],
        RefundAmountRule: ['RefundAmountAmountRule', 'RefundAmountPercentRule'],
        RefundStrategyRule: ['MaxOddsCountLoseStrategyRule'],
        Restriction: [
            'BetDelayRestriction',
            'BetIntervalRestriction',
            'BetProviderRestriction',
            'BetTournamentDuplicateCompetitorIDsRestriction',
            'BetTournamentIncompatibleMarketIDsRestriction',
            'BetTypeRestriction',
            'FreebetAmountRestriction',
            'FreebetNotApplicableRestriction',
            'FreebetStatusRestriction',
            'InsuranceNotApplicableRestriction',
            'InsuranceStatusRestriction',
            'InsuranceValueRestriction',
            'InternalErrorRestriction',
            'MarketDefectiveRestriction',
            'MarketExistenceRestriction',
            'MarketStatusRestriction',
            'MaxBetRestriction',
            'MinBetRestriction',
            'NotEnoughBalanceRestriction',
            'OddExistenceRestriction',
            'OddStatusRestriction',
            'PlayerLimitRestriction',
            'SelectionValueRestriction',
            'SelectionsCountRestriction',
            'SportEventBetStopRestriction',
            'SportEventExistenceRestriction',
            'SportEventStatusRestriction',
            'WLDefinedRestriction',
        ],
        TeamStatistic: [
            'TeamStatisticCSGO',
            'TeamStatisticDota2',
            'TeamStatisticHearthstone',
            'TeamStatisticLOL',
            'TeamStatisticOverwatch',
            'TeamStatisticPUBG',
            'TeamStatisticTotal',
        ],
        TournamentBracket: [
            'DoubleEliminationTournamentBracket',
            'SingleEliminationTournamentBracket',
        ],
    },
};
export default result;
