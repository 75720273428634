import { useCallback, useRef, useState } from 'react';

import { useGetPublicBonusProgram } from 'gql/cms/queries/bonus-program/getPublicBonusProgram.cms';
import { GetPublicBonusProgram } from 'types/gql.cms';
import useAuthorization from './useAuthorization';

interface Input {
    category: 'betting' | 'casino';
    displayIn?: string;
    limit?: number;
    offset?: number;
}

export interface Output {
    loading: boolean;
    publicBonusPrograms: GetPublicBonusProgram['publicBonusPrograms'];
    refetch: ReturnType<typeof useGetPublicBonusProgram>['refetch'];
    loadMorePublicBonusProgramHandler: () => Promise<void>;
    isFetchMorePublicBonusProgram: boolean;
    isLoadedAllPublicBonusProgram: boolean;
}

const usePublicBonusProgram = ({
    category,
    displayIn = 'profile',
    limit = 20,
    offset = 0,
}: Input): Output => {
    const { isRegistrationFinished } = useAuthorization();

    const [isFetchMorePublicBonusProgram, setIsFetchMorePublicBonusProgram] =
        useState<boolean>(false);
    const offsetPublicBonusProgram = useRef<number>(offset);
    const [isLoadedAllPublicBonusProgram, setIsLoadedAllPublicBonusProgram] =
        useState<boolean>(false);

    const { data, refetch, loading, fetchMore } = useGetPublicBonusProgram(
        () => ({
            variables: {
                filter: {
                    category,
                    displayInType: displayIn,
                },
                limit,
                offset,
            },
            skip: isRegistrationFinished,
        })
    );

    const loadMorePublicBonusProgramHandler = useCallback(async () => {
        setIsFetchMorePublicBonusProgram(true);
        offsetPublicBonusProgram.current += limit;

        const response = await fetchMore({
            variables: {
                limit,
                offset: offsetPublicBonusProgram.current,
            },
        });

        if (!response.data.publicBonusPrograms.length) {
            setIsLoadedAllPublicBonusProgram(true);
        }

        setIsFetchMorePublicBonusProgram(false);
    }, [fetchMore, limit]);

    return {
        loading: loading || (!loading && !data),
        publicBonusPrograms: data?.publicBonusPrograms || [],
        refetch,
        loadMorePublicBonusProgramHandler,
        isFetchMorePublicBonusProgram,
        isLoadedAllPublicBonusProgram,
    };
};

export default usePublicBonusProgram;
