import { FC, HTMLAttributes, memo } from 'react';
import cn from 'classnames';

import Button from 'components/Button';
import PackIcon, { IconsPack } from 'components/PackIcon';
import { Message } from 'types/gql.cms';
import InboxCenterMessageIcon from './InboxCenterMessageIcon';
import useInboxCenterMessage from './useInboxCenterMessage';

interface Props extends HTMLAttributes<HTMLElement> {
    message: Message;
    handleMessageClick?: (payload: { id: string; isRead: boolean }) => void;
    handleMessageClose?: (id: string) => void;
    isPreview?: boolean;
    maxSubtitleHeight?: number;
}

const InboxCenterMessage: FC<Props> = ({
    message,
    className,
    handleMessageClick,
    handleMessageClose,
    isPreview,
    maxSubtitleHeight = 80,
}) => {
    const {
        id,
        createdAt,
        title,
        content,
        media,
        preview,
        isRead,
        systemType,
    } = message;
    const {
        timeLeft,
        isOpen,
        toggleMessage,
        subtitleRef,
        isTextLarge,
        onMediaButton,
        onMessageDelete,
    } = useInboxCenterMessage({
        id,
        createdAt,
        isRead,
        maxSubtitleHeight,
        handleMessageClick,
        handleMessageClose,
    });

    const displayContent = !isOpen && preview ? preview : content;
    const isShowExpandArrow =
        !isPreview && (Boolean(preview && content) || isTextLarge);
    const isShowOverlay = isTextLarge && !isOpen;

    return (
        <div
            className={cn(
                'relative',
                isRead ? 'bg-grey-900' : 'bg-surface-middle',
                className
            )}
            onClick={toggleMessage}
            data-test="inbox-center-message"
        >
            <div
                className={cn(
                    'flex p-4',
                    isShowExpandArrow ? 'pr-[80px]' : 'pr-12'
                )}
            >
                <InboxCenterMessageIcon
                    systemType={systemType}
                    isRead={isRead}
                    mainImage={media?.mainImage}
                />
                <div className="overflow-hidden">
                    <div
                        className="mb-1 line-clamp-2 break-words text-primary-white typo-md-2"
                        data-test="inbox-center-message__title"
                    >
                        {title}
                    </div>
                    <div
                        ref={subtitleRef}
                        className={cn(
                            'relative break-words text-grey-500 typo-sm-1',
                            isShowOverlay &&
                                'overflow-hidden after:absolute after:inset-0',
                            isShowOverlay &&
                                !isRead &&
                                'after:bg-[linear-gradient(180deg,rgba(20,23,28,0)_0%,_theme(colors.surface.middle)_100%)]',
                            isShowOverlay &&
                                isRead &&
                                'after:bg-[linear-gradient(180deg,rgba(14,16,19,0)_0%,theme(colors.grey.900)_100%)]'
                        )}
                        style={{
                            maxHeight:
                                isTextLarge && !isOpen
                                    ? `${maxSubtitleHeight}px`
                                    : 'unset',
                        }}
                        /* eslint-disable-next-line react/no-danger */
                        dangerouslySetInnerHTML={{
                            __html: displayContent,
                        }}
                    />

                    {!isPreview && (
                        <div
                            className="mt-2 text-primary-white typo-sm-2"
                            data-test="inbox-center-message__time-left"
                        >
                            {timeLeft}
                        </div>
                    )}
                </div>
            </div>
            <div
                className="absolute right-4 top-4 cursor-pointer"
                onClick={onMessageDelete}
            >
                <PackIcon
                    id="close"
                    pack={IconsPack.ActionIcons}
                    className={cn('fill-grey-500')}
                />
            </div>
            {isShowExpandArrow && (
                <div className="absolute right-12 top-4 cursor-pointer">
                    <PackIcon
                        id="branch-chevron-right"
                        pack={IconsPack.SpriteIcons}
                        className={cn(
                            'fill-grey-500',
                            isOpen ? '-rotate-90' : 'rotate-90'
                        )}
                    />
                </div>
            )}

            {!isPreview && media?.buttons && media.buttons.length > 0 && (
                <div className="flex gap-x-4 border-t-default border-t-[#1a1a1a] p-4">
                    {media.buttons[1] && (
                        <Button
                            size={Button.Size.Small}
                            width={Button.Width.Full}
                            color={Button.Color.SurfaceDark}
                            className="!min-w-0 flex-1 bg-transparent hover:bg-transparent"
                            type="button"
                            onClick={(e) =>
                                onMediaButton(e, media.buttons[1].url)
                            }
                            dataTest="inbox-center-message__btn"
                        >
                            {media.buttons[1].text}
                        </Button>
                    )}
                    {media.buttons[0] && (
                        <Button
                            size={Button.Size.Small}
                            width={Button.Width.Full}
                            color={Button.Color.Orange}
                            className="!min-w-0 flex-1"
                            type="button"
                            onClick={(e) =>
                                onMediaButton(e, media.buttons[0].url)
                            }
                            dataTest="inbox-center-message__btn"
                        >
                            {media.buttons[0].text}
                        </Button>
                    )}
                </div>
            )}
        </div>
    );
};

export default memo(InboxCenterMessage);
