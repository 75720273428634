import type { FC } from 'react';
import cn from 'classnames';

import SmartLink from 'components/SmartLink';

interface Props {
    name: string;
    linkHref: string;
    icon: JSX.Element | undefined;
}

const FooterSportLink: FC<Props> = ({ name, linkHref, icon }) => {
    return (
        <SmartLink
            href={linkHref}
            className={cn(
                'flex max-w-fit items-center gap-3 py-1 pl-1 pr-2 text-base text-primary-white hover:bg-surface-middle'
            )}
            dataTest={name}
        >
            {icon}
            {name}
        </SmartLink>
    );
};

export default FooterSportLink;
