import { useEffect } from 'react';
import { hasCookie, setCookie } from 'cookies-next';
import addDays from 'date-fns/addDays';
import type { Router } from 'next/router';

import { BackendTrackerName } from 'services/apollo/cms/utils/trackers/createCookieTracker';
import { AppLink } from 'types/applink';

const useSetFirstEntrypoint = (router: Router): void => {
    const { locale, route, asPath, components } = router;

    useEffect(() => {
        const hasFirstEntrypoint = hasCookie(
            BackendTrackerName.FirstEntrypoint
        );

        if (hasFirstEntrypoint || components[AppLink.N404]) return;

        const base64Endpoint = Buffer.from(`/${locale}${asPath}`).toString(
            'base64'
        );

        setCookie(BackendTrackerName.FirstEntrypoint, base64Endpoint, {
            expires: addDays(new Date(), 14),
        });
    }, [asPath, components, locale, route]);
};

export default useSetFirstEntrypoint;
