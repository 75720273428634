import { DEFAULT_LOCALE, DEFAULT_NEXT_LOCALE } from 'app-constants';
import useRouter from 'components/HybridRouter/useRouter';
import { useGetLocales } from 'gql/cms/queries/getLocales.cms';
import getActiveLocale from 'utils/intl/getActiveLocale';

export interface Output {
    locales: string[];
    activeLocale: string;
    asPath: string;
}

const useLocales = (): Output => {
    const router = useRouter();
    const { data: cmsLocalesData } = useGetLocales();
    const { asPath } = router;
    const cmsLocales = cmsLocalesData?.locales;

    return {
        locales: cmsLocales?.filter(
            (local) => local !== DEFAULT_NEXT_LOCALE
        ) || [DEFAULT_LOCALE],
        activeLocale: getActiveLocale(router),
        asPath,
    };
};

export default useLocales;
