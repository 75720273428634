import React, { useCallback, useContext } from 'react';
import { uniqueId } from 'lodash';

import Alert from '../Alert';
import { AlertType } from '../Alert/constants';
import { AlertHandlersContext } from './context';

type AlertAction = (message: string) => void;
interface Output {
    successAlert: AlertAction;
    errorAlert: AlertAction;
    warningAlert: AlertAction;
}

const useAlertMessages = (): Output => {
    const { addAlertHandler } = useContext(AlertHandlersContext);

    const successAlert: AlertAction = useCallback(
        (message) => {
            const id = getId();

            addAlertHandler(
                id,
                React.createElement(Alert, {
                    type: AlertType.Success,
                    message,
                    id,
                    key: id,
                })
            );
        },
        [addAlertHandler]
    );

    const errorAlert: AlertAction = useCallback(
        (message) => {
            const id = getId();

            addAlertHandler(
                id,
                React.createElement(Alert, {
                    type: AlertType.Error,
                    message,
                    id,
                    key: id,
                })
            );
        },
        [addAlertHandler]
    );

    const warningAlert: AlertAction = useCallback(
        (message) => {
            const id = getId();

            addAlertHandler(
                id,
                React.createElement(Alert, {
                    type: AlertType.Warning,
                    message,
                    id,
                    key: id,
                })
            );
        },
        [addAlertHandler]
    );

    return {
        successAlert,
        errorAlert,
        warningAlert,
    };
};

const getId = (): string => uniqueId('alert_');

export default useAlertMessages;
