import { useState } from 'react';

import { useGetPlayerBalances } from 'gql/cms/queries/getPlayerBalances.cms';
import getPlayerBalances from 'gql/cms/queries/getPlayerBalances.cms.gql';
import { useOnUpdateChangeBalance } from 'gql/platform/subscriptions/onUpdateChangeBalance.ptm';
import { useCmsApolloClient } from 'services/apollo';
import type { GetPlayerBalances } from 'types/gql.cms';
import useAuthorization from './useAuthorization';

enum BalanceTypes {
    Real = 'real',
    Betting = 'betting',
    Casino = 'casino',
    InstGames = 'instant-games',
    LiveCasino = 'live-casino',
    BonusGroup = 'bonus-group',
}

interface Output {
    currencyCode: string;
    realBalance: number;
    bettingBonuses: number;
    casinoBonuses: number;
    instaBonuses: number;
    totalBalance: number;
    liveCasinoBonuses: number;
    groupBonuses: number;
    isBalancesReady: boolean;
    bonusBalances: NonNullable<
        GetPlayerBalances['playerBalances']
    >['bonusBalances'];
}

const usePlayerBalances = (): Output | null => {
    const { isAuthorized } = useAuthorization();
    const [isBalancesReady, setIsBalancesReady] = useState<boolean>(false);
    const { data: balancesData, refetch } = useGetPlayerBalances(() => ({
        onCompleted: () => {
            if (isBalancesReady) return;

            setIsBalancesReady(true);
        },
        skip: !isAuthorized,
    }));

    const {
        client: { cache },
    } = useCmsApolloClient();

    useOnUpdateChangeBalance({
        onData: async ({ data: { data } }) => {
            if (!data) return;

            const balanceType =
                data.changeBalance.typeId === 'BONUS'
                    ? 'BonusBalanceImpl'
                    : 'BalanceImpl';

            const { id } = data.changeBalance;

            const balancesFromCache = cache.readQuery<GetPlayerBalances>({
                query: getPlayerBalances,
            });

            const bonusBalanceFromCache =
                balancesFromCache?.playerBalances?.bonusBalances.find(
                    (balance) => balance.id === id
                );

            if (
                data.changeBalance.typeId === 'BONUS' &&
                (!bonusBalanceFromCache ||
                    data.changeBalance.refundAfterAmount > 0)
            ) {
                await refetch();
            } else {
                cache.modify({
                    id: `${balanceType}:${id}`,
                    fields: {
                        balance() {
                            return data.changeBalance.balance || 0;
                        },
                        refundSum() {
                            return data.changeBalance.refundSum || 0;
                        },
                    },
                });
            }
        },
        skip: !isAuthorized,
    });

    if (!balancesData?.playerBalances) return null;

    const { bonusBalances } = balancesData.playerBalances;

    const getBonusBalance = (type: BalanceTypes): number => {
        const bonusBalance = bonusBalances.find((bonus) => {
            const activeBonusGroups = bonus.groups?.filter(
                (bonusGroup) => bonusGroup.isActive
            );

            if (!activeBonusGroups?.length) return false;

            if (type === BalanceTypes.BonusGroup)
                return activeBonusGroups.length > 1;

            if (activeBonusGroups.length > 1) return false;

            return activeBonusGroups.some(
                (bonusGroup) => bonusGroup.name === type
            );
        });

        return bonusBalance?.balance || 0;
    };

    const currencyCode = balancesData.playerBalances.currency.code;
    const realBalance = balancesData.playerBalances.realBalance.balance;
    const bettingBonuses = getBonusBalance(BalanceTypes.Betting);
    const casinoBonuses = getBonusBalance(BalanceTypes.Casino);
    const instaBonuses = getBonusBalance(BalanceTypes.InstGames);
    const liveCasinoBonuses = getBonusBalance(BalanceTypes.LiveCasino);
    const groupBonuses = getBonusBalance(BalanceTypes.BonusGroup);

    const totalBalance =
        realBalance +
        bettingBonuses +
        casinoBonuses +
        instaBonuses +
        liveCasinoBonuses +
        groupBonuses;

    return {
        realBalance,
        totalBalance,
        currencyCode,
        bettingBonuses,
        casinoBonuses,
        instaBonuses,
        liveCasinoBonuses,
        groupBonuses,
        isBalancesReady,
        bonusBalances,
    };
};

export default usePlayerBalances;
