import { useMemo } from 'react';

import { LinkOptions } from '../types';
import { linksWithCheckedOptions } from './utils';

interface Input {
    isAuthorized: boolean;
    menuLinks: LinkOptions[];
    hiddenMenuLinks: LinkOptions[];
}

interface Output {
    menuLinksWithOptions: LinkOptions[];
    hiddenMenuLinksWithOptions: LinkOptions[];
}

const useLinksWithOptions = ({
    isAuthorized,
    menuLinks,
    hiddenMenuLinks,
}: Input): Output => {
    const menuLinksWithOptions = useMemo(
        () =>
            linksWithCheckedOptions({
                links: menuLinks,
                options: { isAuthorized },
            }),
        [isAuthorized, menuLinks]
    );

    const hiddenMenuLinksWithOptions = useMemo(
        () =>
            linksWithCheckedOptions({
                links: hiddenMenuLinks,
                options: { isAuthorized },
            }),
        [isAuthorized, hiddenMenuLinks]
    );

    return { menuLinksWithOptions, hiddenMenuLinksWithOptions };
};

export default useLinksWithOptions;
