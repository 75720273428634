import { useContext, useEffect, useState } from 'react';

import { useGetMinBet } from 'gql/betting/queries/getMinBet.bet';
import { useAuthorization } from 'hooks';
import usePlayerBalances from 'hooks/usePlayerBalances';
import { BettingApolloClientContext } from 'services/apollo/betting/BettingApolloProvider';

interface Output {
    isShowDepositText?: boolean;
    isLoaded?: boolean;
}

const useHeaderButtons = (): Output => {
    const [isMinBetReady, setIsMinBetReady] = useState<boolean>(false);
    const { initBettingClient } = useContext(BettingApolloClientContext);

    const { isAuthorized } = useAuthorization();
    const { data: minBetResponse } =
        useGetMinBet(() => ({
            onCompleted: () => {
                if (isMinBetReady) return;

                setIsMinBetReady(true);
            },
            skip: !isAuthorized,
        })) || {};
    const minBet = Number(minBetResponse?.minBet.minBet);
    const playerBalances = usePlayerBalances();
    const { realBalance = 0, isBalancesReady } = playerBalances || {};

    const isLoaded = isMinBetReady && isBalancesReady;
    const isShowDepositText = isLoaded && realBalance < minBet;

    useEffect(() => {
        initBettingClient();
    }, [initBettingClient]);

    return {
        isShowDepositText,
        isLoaded,
    };
};

export default useHeaderButtons;
