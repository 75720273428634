import { isEqual, keys, last, reduce } from 'lodash';
import { GetServerSidePropsContext, NextApiRequest } from 'next';
import { NextRequest } from 'next/server';

export type Cookie =
    | GetServerSidePropsContext['req']['cookies']
    | NextApiRequest['cookies']
    | NextRequest['cookies'];

function createCookieHeader(cookies: Cookie): string {
    const cookieHeader = reduce(
        cookies,
        (acc, value, key) => {
            if (value !== undefined) {
                const cookiePart = `${encodeURIComponent(
                    key
                )}=${encodeURIComponent(String(value))}`;
                const collectionKeys = keys(cookies);
                const isLastElement = isEqual(key, last(collectionKeys));

                return `${acc}${cookiePart}${isLastElement ? '' : '; '}`;
            }

            return acc;
        },
        ''
    );

    return cookieHeader;
}

export default createCookieHeader;
