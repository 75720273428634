import {
    ApolloClient,
    DocumentNode,
    useLazyQuery,
    useMutation,
    useQuery,
    useSubscription,
} from '@apollo/client';
import { capitalize, forEach } from 'lodash';

import { captureSentryException } from 'services/sentry';
import getStubUseSubscription from 'utils/getStubUseSubscription';
import { isSsr } from 'utils/isSsr';
import setStubUseMutation from 'utils/setStubUseMutation';
import { logError } from '../../logger';
import { useBettingApolloClient } from '../betting/BettingApolloProvider';
import { useCmsApolloClient } from '../cms/CmsApolloProvider';
import { ApolloClientName } from '../constants';
import { usePtmApolloClient } from '../platform/PlatformApolloProvider';

interface Hooks {
    useBettingQuery: typeof useQuery;
    useBettingLazyQuery: typeof useLazyQuery;
    useBettingMutation: typeof useMutation;
    useBettingSubscription: typeof useSubscription;
    useCmsLazyQuery: typeof useLazyQuery;
    useCmsQuery: typeof useQuery;
    useCmsMutation: typeof useMutation;
    useCmsSubscription: typeof useSubscription;
    usePlatformQuery: typeof useQuery;
    usePlatformMutation: typeof useMutation;
    usePlatformSubscription: typeof useSubscription;
}

type HookType = 'Query' | 'Mutation' | 'Subscription';

const apolloHooks = {
    useQuery,
    useMutation,
    useSubscription,
    useLazyQuery,
} as const;

const clientNames = [
    ApolloClientName.Betting,
    ApolloClientName.Cms,
    ApolloClientName.Platform,
];

export const {
    useBettingQuery,
    useBettingLazyQuery,
    useBettingMutation,
    useBettingSubscription,
    useCmsQuery,
    useCmsLazyQuery,
    useCmsMutation,
    useCmsSubscription,
    usePlatformMutation,
    usePlatformQuery,
    usePlatformSubscription,
}: Hooks = clientNames.reduce((acc, clientName) => {
    forEach(apolloHooks, (apolloHook, apolloHookName) => {
        const hookType = apolloHookName.split('use')[1] as HookType;
        const capitalizedClientName = capitalize(
            clientName
        ) as Capitalize<ApolloClientName>;

        const hookName = `use${capitalizedClientName}${hookType}` as const;

        const isSubscriptionOnSsr = isSsr() && hookType === 'Subscription';
        const isMutationSsr = isSsr() && hookType === 'Mutation';

        // NOTE: subscriptions are disabled on the server due to non-obvious behavior
        // eslint-disable-next-line no-nested-ternary
        acc[hookName] = isSubscriptionOnSsr
            ? getStubUseSubscription
            : isMutationSsr
              ? setStubUseMutation
              : attachClientToApolloHook.bind(null, apolloHook, clientName);
    });

    return acc;
}, {} as Hooks);

interface AttachClientError {
    error: any;
    doc: DocumentNode;
    options?: AttachClientOption;
}

type AttachClientOption = { client?: ApolloClient<object> } | undefined;

function attachClientToApolloHook(
    apolloHook: Function,
    clientName: ApolloClientName,
    doc: DocumentNode,
    options: AttachClientOption
) {
    try {
        switch (clientName) {
            case ApolloClientName.Betting: {
                // eslint-disable-next-line react-hooks/rules-of-hooks
                const { client } = useBettingApolloClient();

                return apolloHook(doc, { ...options, client });
            }

            case ApolloClientName.Cms: {
                // eslint-disable-next-line react-hooks/rules-of-hooks
                const { client } = useCmsApolloClient();

                return apolloHook(doc, { ...options, client });
            }

            case ApolloClientName.Platform: {
                // eslint-disable-next-line react-hooks/rules-of-hooks
                const { client } = usePtmApolloClient();

                return apolloHook(doc, { ...options, client });
            }

            default:
                throw Error('No existing apollo client name!');
        }
    } catch (error: any) {
        logError<AttachClientError>({
            message: `Error attach client to apollo hook "${clientName}"`,
            err: { error, doc, options },
        });

        captureSentryException({
            label: 'attachClientToApolloHook',
            message:
                error?.message ||
                `Error attach client to apollo hook "${clientName}"`,
            additionalData: {
                doc,
                options,
            },
        });
    }
}
