export interface PossibleTypesResultData {
    possibleTypes: {
        [key: string]: string[];
    };
}
const result: PossibleTypesResultData = {
    possibleTypes: {
        ArchivedBonusProgramData: ['ArchivedBonusProgramDataImpl'],
        AuthLoginTaskSettings: ['AuthLoginTaskSettingsImpl'],
        AvailabilityCondition: ['AvailabilityConditionImpl'],
        Balance: ['BalanceImpl'],
        BalanceJournalEntry: ['BalanceJournalEntryImpl'],
        BankIdVerificationRequest: ['BankIdVerificationRequestImpl'],
        BestPlayerData: ['BestPlayerDataImpl'],
        BetTaskSettings: ['BetTaskSettingsImpl'],
        BettingFeature: ['BettingFeatureImpl'],
        BettingMatchTracker: ['BettingMatchTrackerImpl'],
        BettingSettings: ['BettingSettingsImpl'],
        BettingSport: ['BettingSportImpl'],
        BettingSsrPageRule: ['BettingSsrPageRuleImpl'],
        BettingTaskSettings: ['BettingTaskSettingsImpl'],
        BettingTournaments: ['BettingTournamentsImpl'],
        BonusBalance: ['BonusBalanceImpl'],
        BonusBalanceFeature: [
            'BonusBalanceFeatureImpl',
            'BonusBalanceFeatureMaxTransfer',
        ],
        BonusBalanceGroup: ['BonusBalanceGroupImpl'],
        BonusProgramData: ['BonusProgramDataImpl'],
        BonusProgramDataBettingTaskDataBet: [
            'BonusProgramDataBettingTaskDataBetImpl',
        ],
        BonusProgramDataTask: ['BonusProgramDataTaskImpl'],
        BonusProgramDataTaskDataStatus: ['BonusProgramDataTaskDataStatusImpl'],
        BonusProgramImage: ['BonusProgramImageImpl'],
        CaptchaOptionsInterface: ['CaptchaOptionsImpl', 'ReCaptchaOptions'],
        CashbackCoefficient: ['CashbackCoefficientImpl'],
        Chat: ['ChatImpl', 'YhelperChat'],
        Clarification: [
            'ClarificationImpl',
            'PayoutCommissionClaricationProfitTaxType',
            'PayoutCommissionClaricationWagerBalanceType',
        ],
        CompositeTaskSettings: ['CompositeTaskSettingsImpl'],
        ConditionRuleInterface: [
            'BetCoefficientGreaterThanConditionRule',
            'BonusCompletedCountConditionRule',
            'ConditionRule',
            'DepositAmountGreaterThanConditionRule',
            'EverydayLoginCountConditionRule',
            'PlaySlotCountConditionRule',
            'PlaySpinCountConditionRule',
            'ProfileUpdateConditionRule',
            'RelationalConditionRule',
            'TypeAcceptBetCountConditionRule',
        ],
        ConfirmEmailTaskSettings: ['ConfirmEmailTaskSettingsImpl'],
        ConfirmPhoneTaskSettings: ['ConfirmPhoneTaskSettingsImpl'],
        Country: ['CountryImpl'],
        CryptoWalletAddressResponse: ['CryptoWalletAddressResponseImpl'],
        Currency: ['CurrencyImpl'],
        DataState: ['DataStateImpl'],
        DataTransaction: ['DataTransactionImpl'],
        DecodedBanner: ['DecodedBannerImpl'],
        DepositTaskSettings: ['DepositTaskSettingsImpl'],
        DiiaDeeplink: ['DiiaDeeplinkImpl'],
        Document: ['DocumentImpl'],
        DummyTaskSettings: ['DummyTaskSettingsImpl'],
        Event: ['EventImpl'],
        EventData: ['EventDataImpl'],
        FreeSpin: ['FreeSpinImpl'],
        FrontendServiceResult: ['FrontendServiceResultImpl'],
        FrontendServiceResultLog: ['FrontendServiceResultLogImpl'],
        GameApi: ['GameApiImpl'],
        GameJournalEntry: ['GameJournalEntryImpl'],
        GameJournalEntryBalance: ['GameJournalEntryBalanceImpl'],
        GameJournalRound: ['GameJournalRoundImpl'],
        GameSizeOptions: ['GameSizeOptionsImpl'],
        GamesList: ['GamesListImpl'],
        GamesSearchParams: ['GamesSearchParamsImpl'],
        GeoIpCountry: ['GeoIpCountryImpl'],
        GinDecimal: ['GinDecimalImpl'],
        IdGovUaIntegrationServiceGenerateLinkResult: [
            'IdGovUaIntegrationServiceGenerateLinkResultImpl',
        ],
        Image: ['ImageImpl'],
        KycApproveTaskSettings: ['KycApproveTaskSettingsImpl'],
        Limit: ['LimitImpl'],
        LimitHistory: ['LimitHistoryImpl'],
        LimitMoney: ['LimitMoneyImpl'],
        LocalizedMenu: ['LocalizedMenuImpl'],
        LocalizedMenuItem: ['LocalizedMenuItemImpl'],
        Login4playResponse: ['Login4playResponseImpl'],
        LoyaltyPointsMultiplier: ['LoyaltyPointsMultiplierImpl'],
        LoyaltyProgramExchangeRate: ['LoyaltyProgramExchangeRateImpl'],
        LoyaltyProgramLevel: ['LoyaltyProgramLevelImpl'],
        LoyaltyProgramParticipant: ['LoyaltyProgramParticipantImpl'],
        LoyaltyProgramPlayerData: ['LoyaltyProgramPlayerDataImpl'],
        LoyaltyProgramPlayerDataPoint: ['LoyaltyProgramPlayerDataPointImpl'],
        LoyaltyProgramReward: ['LoyaltyProgramRewardImpl'],
        LoyaltyProgramSettings: ['LoyaltyProgramSettingsImpl'],
        LoyaltyProgramSettingsExchangePoint: [
            'LoyaltyProgramSettingsExchangePointImpl',
        ],
        LoyaltyProgramSettingsExchangePointTargetBalance: [
            'LoyaltyProgramSettingsExchangePointTargetBalanceImpl',
        ],
        LoyaltyProgramSettingsGambleRate: [
            'LoyaltyProgramSettingsGambleRateImpl',
        ],
        LoyaltyProgramSettingsGambleRateDistribution: [
            'LoyaltyProgramSettingsGambleRateDistributionImpl',
        ],
        LoyaltyProgramSettingsGambleRateRate: [
            'LoyaltyProgramSettingsGambleRateRateImpl',
        ],
        LoyaltyProgramStatus: ['LoyaltyProgramStatusImpl'],
        LoyaltyProgramTotalData: ['LoyaltyProgramTotalDataImpl'],
        ManualTaskSettings: ['ManualTaskSettingsImpl'],
        Message: ['MessageImpl'],
        MessageMedia: ['MessageMediaImpl'],
        MessageMediaButton: ['MessageMediaButtonImpl'],
        MessageMediaMainImage: ['MessageMediaMainImageImpl'],
        MessagesInfoResult: ['MessagesInfoResultImpl'],
        Mirror: ['MirrorImpl'],
        Participant: ['ParticipantImpl'],
        PaymentHistoryResult: ['PaymentHistoryResultImpl'],
        PaymentLink: [
            'IframeLink',
            'LocalLink',
            'MultipleLink',
            'PaymentLinkImpl',
            'SMSLink',
            'UrlLink',
        ],
        PaymentMethodLocalization: [
            'PaymentHistoryTransaction',
            'PaymentMethodLocalizationImpl',
            'PaymentTransaction',
        ],
        PaymentOption: ['PaymentOptionImpl'],
        PaymentPlayerBalancesInfoDto: ['PaymentPlayerBalancesInfoDtoImpl'],
        PaymentSettingDto: ['PaymentSettingDtoImpl'],
        PaymentTransactionAmountDto: ['PaymentTransactionAmountDtoImpl'],
        PaymentTransactionDto: ['PaymentTransactionDtoImpl'],
        PaymentTransactionIdDto: ['PaymentTransactionIdDtoImpl'],
        PaymentTransactionInterface: [
            'PaymentHistoryTransaction',
            'PaymentTransaction',
            'PaymentTransactionImpl',
        ],
        PaymentTransactionStatusDto: ['PaymentTransactionStatusDtoImpl'],
        PlayData: [
            'AleaPlayPlayData',
            'BetGamesPlayData',
            'NetEntPlayData',
            'PlayDataImpl',
            'SlotoplexPlayData',
            'StakelogicPlayData',
            'TvBetPlayData',
        ],
        PlayerAchievementProgressInterface: [
            'CountEventsPlayerAchievementProgress',
            'CountUniqueEventsPlayerAchievementProgress',
            'EverydayEventsPlayerAchievementProgress',
            'MoneySumPlayerAchievementProgress',
            'PlayerAchievementProgress',
            'SlotsCountPlayerAchievementProgress',
        ],
        PlayerBalanceJournalEntry: ['PlayerBalanceJournalEntryImpl'],
        PlayerStatistics: ['PlayerStatisticsImpl'],
        ProcessingBonusProgramData: ['ProcessingBonusProgramDataImpl'],
        ProfileInfo: ['ProfileInfoImpl'],
        PromotionMap: ['PromotionMapImpl'],
        Referral: ['ReferralImpl'],
        ReferralInvitesResponse: ['ReferralInvitesResponseImpl'],
        RewardsBasicSettings: [
            'RewardsBasicSettingsImpl',
            'RewardsBonusBalanceSettings',
            'RewardsFreeSpinsSettings',
            'RewardsLoyaltyMultiplierSettings',
            'RewardsLoyaltyPointsSettings',
            'RewardsRealBalanceSettings',
            'RewardsScratchLotterySettings',
            'RewardsWheelOfFortuneSettings',
        ],
        Route: ['RouteImpl'],
        RouteDisplaySettings: ['RouteDisplaySettingsImpl'],
        RouteDisplaySettingsValue: [
            'RouteDisplaySettingsStringValue',
            'RouteDisplaySettingsValueImpl',
        ],
        RoutingRelatedEntityInfo: [
            'GameLocalizedPlatformedRoutingRelatedEntity',
            'NewsLocalizedRoutingRelatedEntity',
            'PageLocalizedRoutingRelatedEntity',
            'RoutingRelatedEntityInfoImpl',
            'TournamentLocalizedRoutingRelatedEntity',
        ],
        Seo: ['SeoImpl'],
        SeoInfo: ['SeoInfoImpl'],
        SeoMetaTag: ['SeoMetaTagImpl'],
        SeoText: ['SeoTextImpl'],
        SettingsCurrencyMap: ['SettingsCurrencyMapImpl'],
        Slider: ['SliderImpl'],
        SubLogoImage: ['SubLogoImageImpl'],
        SymfonyUserInterface: ['Player', 'SymfonyUserImpl', 'User'],
        TaskConnection: ['TaskConnectionImpl'],
        TaskSettingsCollection: ['TaskSettingsCollectionImpl'],
        TaskSettingsRewards: ['TaskSettingsRewardsImpl'],
        TaskTypeImage: ['TaskTypeImageImpl'],
        TimeLine: ['TimeLineImpl'],
        TournamentLocalizedText: ['TournamentLocalizedTextImpl'],
        TournamentMetadataCalculation: [
            'TournamentMetadataCalculationBetting',
            'TournamentMetadataCalculationBettingRatio',
            'TournamentMetadataCalculationBettingStake',
            'TournamentMetadataCalculationBettingTurnover',
            'TournamentMetadataCalculationImpl',
            'TournamentMetadataCalculationMultiplier',
            'TournamentMetadataCalculationScoring',
            'TournamentMetadataCalculationTurnover',
        ],
        TournamentMetadataCalculationBettingStakeAmount: [
            'TournamentMetadataCalculationBettingStakeAmountImpl',
        ],
        TournamentMetadataCalculationMoney: [
            'TournamentMetadataCalculationMoneyImpl',
        ],
        TournamentMetadataCalculationMultiplierSegment: [
            'TournamentMetadataCalculationMultiplierSegmentImpl',
        ],
        TournamentMetadataFilterMinBetsItem: [
            'TournamentMetadataFilterMinBetsItemImpl',
        ],
        TournamentMetadataFilters: [
            'TournamentMetadataFilterMinBets',
            'TournamentMetadataFiltersImpl',
        ],
        TournamentMetadataPlaces: ['TournamentMetadataPlacesImpl'],
        TournamentMetadataPlacesActionPrizeRewardPayload: [
            'TournamentMetadataPlacesActionPrizeRewardPayloadImpl',
        ],
        TournamentMetadataPlacesPrize: ['TournamentMetadataPlacesPrizeImpl'],
        TournamentMetadataTemplateDataInterface: [
            'TournamentMetadataTemplateDataImpl',
        ],
        TournamentParticipant: ['TournamentParticipantImpl'],
        TournamentPlace: ['TournamentPlaceImpl'],
        TournamentPlatformMetadata: ['TournamentPlatformMetadataImpl'],
        TournamentTeamRaceParticipant: ['TournamentTeamRaceParticipantImpl'],
        TournamentTeamRaceParticipants: ['TournamentTeamRaceParticipantsImpl'],
        TournamentTeamRaceRound: ['TournamentTeamRaceRoundImpl'],
        TournamentTeamRaceRounds: ['TournamentTeamRaceRoundsImpl'],
        TournamentWinner: ['TournamentWinnerImpl'],
        TournamentWinnerPrize: ['TournamentWinnerPrizeImpl'],
        TournamentWinnerPrizeTypeMoney: ['TournamentWinnerPrizeTypeMoneyImpl'],
        UnionBonusProgramDataWinTaskDataBonusProgramDataBetTaskDataBonusProgramDataBettingTaskDataBonusProgramDataDepositTaskDataBonusProgramDataManualTaskDataBonusProgramDataTaskData:
            [
                'BonusProgramDataBetTaskData',
                'BonusProgramDataBettingTaskData',
                'BonusProgramDataDepositTaskData',
                'BonusProgramDataManualTaskData',
                'BonusProgramDataTaskData',
                'BonusProgramDataWinTaskData',
            ],
        UnionGameTournamentLocalizedBonusProgramLocalizedPromotionLocalizedCategoryFrontendGqlLocalizedTheme:
            [
                'BonusProgramLocalized',
                'Category',
                'FrontendGqlLocalizedTheme',
                'Game',
                'PromotionLocalized',
                'TournamentLocalized',
            ],
        UnionTournamentPrizePoolPlaceSinglePrizeTournamentPrizePoolPlaceMultiPrize:
            [
                'TournamentPrizePoolPlaceMultiPrize',
                'TournamentPrizePoolPlaceSinglePrize',
            ],
        UserInterface: ['Player', 'User'],
        WebPushNotificationSettings: ['WebPushNotificationSettingsImpl'],
        WheelOfFortune: ['WheelOfFortuneImpl'],
        WheelOfFortuneFreeGames: ['WheelOfFortuneFreeGamesImpl'],
        WheelOfFortuneGame: ['WheelOfFortuneGameImpl'],
        WheelOfFortuneGameSector: ['WheelOfFortuneGameSectorImpl'],
        WheelOfFortuneGameSectorPrize: ['WheelOfFortuneGameSectorPrizeImpl'],
        WheelOfFortuneGameWheel: ['WheelOfFortuneGameWheelImpl'],
        WidgetKeyValue: ['WidgetKeyValueImpl'],
        WinTaskSettings: ['WinTaskSettingsImpl'],
        Winner: ['WinnerImpl'],
    },
};
export default result;
