import { FC, useMemo } from 'react';
import { useTranslations } from 'next-intl';

import { AppLink, CustomAppLink } from 'app-constants';
import MenuTabDesktop from 'components/MenuTabDesktop';
import PackIcon, { IconsPack } from 'components/PackIcon';
import type { NavigationIconsPack } from 'components/PackIcon/PackIcon';
import { useActiveLink } from 'hooks';
import { LinkOptions } from '../types';

const getInitialCasinoMenuLinks = (): LinkOptions[] => [
    {
        textKey: 'bets',
        link: AppLink.BETS,
        iconId: 'bets',
    },
    {
        textKey: 'casino',
        link: AppLink.CASINO,
        iconId: 'casino',
    },
    {
        textKey: 'live_casino',
        link: CustomAppLink.CASINO_LIVE,
    },
];

const HeaderCasino: FC = () => {
    const { checkActiveLink } = useActiveLink();
    const t = useTranslations('HeaderNavigation') as any;

    const initialCasinoMenuLinks = useMemo(
        () => getInitialCasinoMenuLinks(),
        []
    );

    return (
        <div className="relative z-1 hidden md:flex" data-test="header-casino">
            {initialCasinoMenuLinks.map(({ textKey, link, iconId }) => (
                <MenuTabDesktop
                    id={textKey}
                    actionHandler={link}
                    iconsLeft={
                        iconId ? (
                            <PackIcon
                                id={iconId as keyof NavigationIconsPack}
                                className="mr-1"
                                pack={IconsPack.NavigationIcons}
                            />
                        ) : undefined
                    }
                    key={textKey}
                    className="mr-2"
                    isSelected={checkActiveLink(link)}
                    dataTest="header-casino__item"
                >
                    {t(`gincms.menu_item.${textKey}`)}
                </MenuTabDesktop>
            ))}
        </div>
    );
};

export default HeaderCasino;
