export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
    [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    /** A local date string (i.e., with no associated timezone) in `YYYY-MM-DD` format, e.g. `2020-01-01`. */
    Date: any;
    /** The `DateTime` scalar type represents time data, represented as an ISO-8601 encoded UTC date string. */
    DateTime: any;
    /** The `Decimal` scalar type represents decimal (float with high precision as a string). */
    Decimal: any;
    /**
     * The `Upload` special type represents a file to be uploaded in the same HTTP request as specified by
     *  [graphql-multipart-request-spec](https://github.com/jaydenseric/graphql-multipart-request-spec).
     */
    Upload: any;
};

export type AcceptRuleInput = {
    type: Scalars['String'];
    versions: Array<Scalars['String']>;
};

export type AcceptTermsInput = {
    rules: Array<AcceptRuleInput>;
};

export enum AchievementEnumType {
    Bet = 'BET',
    Bonus = 'BONUS',
    Kyc = 'KYC',
    Login = 'LOGIN',
    LoyaltyProgram = 'LOYALTY_PROGRAM',
    Payment = 'PAYMENT',
    Play = 'PLAY',
    Profile = 'PROFILE',
    TopWinners = 'TOP_WINNERS',
    Tournament = 'TOURNAMENT',
}

export type ActivateBonusBalanceInput = {
    active: Scalars['Boolean'];
    bonusBalanceGroupName: Scalars['String'];
    bonusBalanceId: Scalars['Int'];
};

export enum AuthMethodIdEnum {
    AutologinTokenAuthMethodId = 'AUTOLOGIN_TOKEN_AUTH_METHOD_ID',
    EmailAuthMethodId = 'EMAIL_AUTH_METHOD_ID',
    MultiAuthMethodId = 'MULTI_AUTH_METHOD_ID',
    PhoneAuthMethodId = 'PHONE_AUTH_METHOD_ID',
    RecoveryAuthMethodId = 'RECOVERY_AUTH_METHOD_ID',
    SimpleAuthMethodId = 'SIMPLE_AUTH_METHOD_ID',
    SocialAuthMethodId = 'SOCIAL_AUTH_METHOD_ID',
    TrustlyAuthMethodId = 'TRUSTLY_AUTH_METHOD_ID',
}

export enum BalanceTypeEnumType {
    Bonus = 'BONUS',
    None = 'NONE',
    Real = 'REAL',
}

export type BonusProgramListFilterInput = {
    active?: InputMaybe<Scalars['Boolean']>;
    category?: InputMaybe<Scalars['String']>;
    types?: Array<BonusTypeEnum>;
};

export enum BonusTypeEnum {
    Archived = 'ARCHIVED',
    Available = 'AVAILABLE',
    Processing = 'PROCESSING',
}

export enum CaptchaContext {
    Any = 'ANY',
    Login = 'LOGIN',
    Recovery = 'RECOVERY',
    Registration = 'REGISTRATION',
    Verification = 'VERIFICATION',
}

export type CaptchaTokenInput = {
    refCode?: InputMaybe<Scalars['String']>;
};

export type ChangePasswordInput = {
    newPassword: Scalars['String'];
    newPasswordConfirm: Scalars['String'];
    oldPassword?: InputMaybe<Scalars['String']>;
};

export enum ChatWidgetTypeEnum {
    Yhelper = 'YHELPER',
}

export enum ConditionRuleEnumType {
    BetCoefficientGreaterThan = 'BET_COEFFICIENT_GREATER_THAN',
    BonusCompletedCount = 'BONUS_COMPLETED_COUNT',
    DepositAmountGreaterThan = 'DEPOSIT_AMOUNT_GREATER_THAN',
    EverydayLoginCount = 'EVERYDAY_LOGIN_COUNT',
    KycStatus = 'KYC_STATUS',
    Logical = 'LOGICAL',
    LoyaltyProgramLevel = 'LOYALTY_PROGRAM_LEVEL',
    PlayerList = 'PLAYER_LIST',
    PlaySlotCount = 'PLAY_SLOT_COUNT',
    PlaySpinCount = 'PLAY_SPIN_COUNT',
    ProfileUpdates = 'PROFILE_UPDATES',
    Relational = 'RELATIONAL',
    SearchQuery = 'SEARCH_QUERY',
    TournamentsPlace = 'TOURNAMENTS_PLACE',
    TypeAcceptBetCount = 'TYPE_ACCEPT_BET_COUNT',
    TypeSportBetCount = 'TYPE_SPORT_BET_COUNT',
}

export type CreateSimpleCredentialsInput = {
    login: Scalars['String'];
    password: Scalars['String'];
};

export type CredentialInterfaceInput = {
    type: Scalars['String'];
    value: Scalars['String'];
};

export type CredentialsCollectionInterfaceInput = {
    credentials: Array<CredentialInterfaceInput>;
    password: Scalars['String'];
};

export type CrmNotificationTrackerInput = {
    crmCode: Scalars['String'];
};

export type DepositLinkInput = {
    amount: Scalars['Decimal'];
    paymentMethodId: Scalars['String'];
    recommendAmount?: InputMaybe<Scalars['Float']>;
    recommendMethod?: InputMaybe<Scalars['String']>;
    redirectUrls?: InputMaybe<RedirectUrlsInput>;
    requisitesPhone?: InputMaybe<Scalars['String']>;
    transactionCurrencyCode?: InputMaybe<Scalars['String']>;
};

export enum DiiaRequestStatusGraphqlEnum {
    AuthInProgress = 'AUTH_IN_PROGRESS',
    AuthSuccess = 'AUTH_SUCCESS',
    ShareInProgress = 'SHARE_IN_PROGRESS',
    ShareSuccess = 'SHARE_SUCCESS',
    Unknown = 'UNKNOWN',
}

export type DocumentCreateInputTypeInput = {
    description?: InputMaybe<Scalars['String']>;
    file: Scalars['Upload'];
    type: Scalars['String'];
};

export enum DocumentSourceTypeEnum {
    Player = 'PLAYER',
    System = 'SYSTEM',
    Unknown = 'UNKNOWN',
    User = 'USER',
}

export type FiltersInputTypeInput = {
    authToken?: InputMaybe<Scalars['String']>;
    containedBalanceType?: InputMaybe<Scalars['String']>;
    currencyCode?: InputMaybe<Scalars['String']>;
    gameId?: InputMaybe<Scalars['String']>;
    providerId?: InputMaybe<Scalars['String']>;
    roundId?: InputMaybe<Scalars['String']>;
    roundType?: InputMaybe<Scalars['String']>;
    sessionToken?: InputMaybe<Scalars['String']>;
    totalAmountMax?: InputMaybe<Scalars['Decimal']>;
    totalAmountMin?: InputMaybe<Scalars['Decimal']>;
    type?: InputMaybe<Scalars['String']>;
};

export enum GameSearchStrategyEnum {
    ByNgrams = 'BY_NGRAMS',
    ByPrefix = 'BY_PREFIX',
}

export enum GameStatisticsSortKeyEnum {
    BetCount = 'BET_COUNT',
    RealBetSum = 'REAL_BET_SUM',
    TotalBetSum = 'TOTAL_BET_SUM',
}

export enum GameTypeEnum {
    AndarBahar = 'ANDAR_BAHAR',
    Arcade = 'ARCADE',
    Baccarat = 'BACCARAT',
    Bingo = 'BINGO',
    Blackjack = 'BLACKJACK',
    Card = 'CARD',
    Casual = 'CASUAL',
    CoinFlipping = 'COIN_FLIPPING',
    Craps = 'CRAPS',
    Crash = 'CRASH',
    Dice = 'DICE',
    DragonTiger = 'DRAGON_TIGER',
    Fishing = 'FISHING',
    Fortune = 'FORTUNE',
    HigherLower = 'HIGHER_LOWER',
    Holdem = 'HOLDEM',
    Instant = 'INSTANT',
    Jackpot = 'JACKPOT',
    Keno = 'KENO',
    Live = 'LIVE',
    Lottery = 'LOTTERY',
    Mines = 'MINES',
    Plinko = 'PLINKO',
    Poker = 'POKER',
    Progressive = 'PROGRESSIVE',
    Roulette = 'ROULETTE',
    ScratchCard = 'SCRATCH_CARD',
    Shooting = 'SHOOTING',
    SicBo = 'SIC_BO',
    Slots = 'SLOTS',
    SnakesAndLadders = 'SNAKES_AND_LADDERS',
    Solitaier = 'SOLITAIER',
    Table = 'TABLE',
    ThreeD = 'THREE_D',
    Unknown = 'UNKNOWN',
    Video = 'VIDEO',
    VirtualFootball = 'VIRTUAL_FOOTBALL',
    VirtualGreyhounds = 'VIRTUAL_GREYHOUNDS',
    VirtualHorses = 'VIRTUAL_HORSES',
    VirtualSports = 'VIRTUAL_SPORTS',
    VirtualTennis = 'VIRTUAL_TENNIS',
    WheelOfFortune = 'WHEEL_OF_FORTUNE',
}

export enum KycJournalActionTypeEnum {
    AffConfirm = 'AFF_CONFIRM',
    AffDecline = 'AFF_DECLINE',
    DApprove = 'D_APPROVE',
    DCreate = 'D_CREATE',
    DDecline = 'D_DECLINE',
    DDelete = 'D_DELETE',
    DEdit = 'D_EDIT',
    IncreasedAffConfirm = 'INCREASED_AFF_CONFIRM',
    IncreasedAffDecline = 'INCREASED_AFF_DECLINE',
    KycApproveFull = 'KYC_APPROVE_FULL',
    KycApproveSimple = 'KYC_APPROVE_SIMPLE',
    KycAwaitingApproval = 'KYC_AWAITING_APPROVAL',
    KycDecline = 'KYC_DECLINE',
    KycUnknown = 'KYC_UNKNOWN',
}

export enum KycaidVerificationStatusGraphqlEnum {
    Completed = 'COMPLETED',
    Pending = 'PENDING',
    Unused = 'UNUSED',
}

export enum KycaidVerificationTypeEnum {
    BankId = 'BANK_ID',
    FormUrl = 'FORM_URL',
}

export enum KycaidVerificationTypeGraphqlEnum {
    BankId = 'BANK_ID',
    FormUrl = 'FORM_URL',
    Unknown = 'UNKNOWN',
}

export enum MessageTargetPlatformTypeEnum {
    DesktopWebsite = 'DESKTOP_WEBSITE',
    MobileAndroid = 'MOBILE_ANDROID',
    MobileIos = 'MOBILE_IOS',
    MobileWebsite = 'MOBILE_WEBSITE',
}

export type MobileAppSettingsInput = {
    enabled?: InputMaybe<Scalars['Boolean']>;
    limit?: InputMaybe<Scalars['Int']>;
    name?: InputMaybe<Scalars['String']>;
    offset?: InputMaybe<Scalars['Int']>;
    operationSystem: OperationSystem;
};

export type MobileCreateFirebaseTokenInput = {
    appName?: InputMaybe<Scalars['String']>;
    deviceId: Scalars['String'];
    refCode?: InputMaybe<Scalars['String']>;
    registrationToken: Scalars['String'];
    userAgent: Scalars['String'];
};

export type MobilePushNotificationChangeSubscriptionInput = {
    isSubscribed: Scalars['Boolean'];
};

export enum MobilePushSubscriptionInfoTypeEnumType {
    Fcm = 'FCM',
    Ios = 'IOS',
    Safari = 'SAFARI',
    Undefined = 'UNDEFINED',
    Webpush = 'WEBPUSH',
}

export type MobilePushTokenInput = {
    appName?: InputMaybe<Scalars['String']>;
    deviceId: Scalars['String'];
    refCode?: InputMaybe<Scalars['String']>;
    registrationToken: Scalars['String'];
    userAgent: Scalars['String'];
};

export enum OperationSystem {
    Android = 'ANDROID',
    Ios = 'IOS',
}

export enum PaymentMethodTypeEnum {
    TypeDeposit = 'TYPE_DEPOSIT',
    TypePayout = 'TYPE_PAYOUT',
}

export type PayoutCommissionForSystemInput = {
    systemName: Scalars['String'];
};

export type PayoutLinkInput = {
    amount: Scalars['Float'];
    redirectUrls?: InputMaybe<RedirectUrlsInput>;
    requisitesCard?: InputMaybe<Scalars['String']>;
    requisitesCardMask?: InputMaybe<Scalars['String']>;
    requisitesPhone?: InputMaybe<Scalars['String']>;
    transactionCurrencyCode?: InputMaybe<Scalars['String']>;
    transactionId?: InputMaybe<Scalars['String']>;
    withdrawMethodId: Scalars['String'];
};

export enum Period {
    Month = 'MONTH',
    Week = 'WEEK',
    Year = 'YEAR',
}

export enum Platform {
    DesktopWebsite = 'DESKTOP_WEBSITE',
    MobileWebsite = 'MOBILE_WEBSITE',
}

export enum PlayerAchievementProgressEnumType {
    CountEvents = 'COUNT_EVENTS',
    CountUniqueEvents = 'COUNT_UNIQUE_EVENTS',
    EverydayEvents = 'EVERYDAY_EVENTS',
    MoneySum = 'MONEY_SUM',
    SlotsCount = 'SLOTS_COUNT',
}

export type PlayerLimitsInput = {
    interval?: InputMaybe<Scalars['String']>;
    money?: InputMaybe<Scalars['Float']>;
    period?: InputMaybe<Scalars['String']>;
    reason?: InputMaybe<Scalars['String']>;
    type: Scalars['String'];
};

export enum PlayerProgramStatusTypeEnumType {
    Canceled = 'CANCELED',
    Completed = 'COMPLETED',
    Expired = 'EXPIRED',
    Processing = 'PROCESSING',
    Unknown = 'UNKNOWN',
}

export type PlayerRequisitesTypeInput = {
    city?: InputMaybe<Scalars['String']>;
    countryId?: InputMaybe<Scalars['Int']>;
    currencyId: Scalars['Int'];
    dateOfBirth?: InputMaybe<Scalars['DateTime']>;
    displayName?: InputMaybe<Scalars['String']>;
    email?: InputMaybe<Scalars['String']>;
    firstName?: InputMaybe<Scalars['String']>;
    gender?: InputMaybe<Scalars['String']>;
    hash?: InputMaybe<Scalars['String']>;
    lastName?: InputMaybe<Scalars['String']>;
    maidenLastName?: InputMaybe<Scalars['String']>;
    passportIssuedBy?: InputMaybe<Scalars['String']>;
    passportIssuedOn?: InputMaybe<Scalars['DateTime']>;
    passportNumber?: InputMaybe<Scalars['String']>;
    passportSeries?: InputMaybe<Scalars['String']>;
    patronymic?: InputMaybe<Scalars['String']>;
    phone?: InputMaybe<Scalars['String']>;
    phoneConfirmed?: InputMaybe<Scalars['Boolean']>;
    placeOfBirth?: InputMaybe<Scalars['String']>;
    policyAgree?: InputMaybe<Scalars['Boolean']>;
    priorityTypeOfGambling?: InputMaybe<Scalars['String']>;
    receiveEmails?: InputMaybe<Scalars['Boolean']>;
    receiveSms?: InputMaybe<Scalars['Boolean']>;
    redeemCode?: InputMaybe<Scalars['String']>;
    refCode?: InputMaybe<Scalars['String']>;
    residence?: InputMaybe<ProfileResidenceInput>;
    securityAnswer?: InputMaybe<Scalars['String']>;
    securityQuestion?: InputMaybe<Scalars['String']>;
    selfDeclaredPep?: InputMaybe<Scalars['Boolean']>;
    skype?: InputMaybe<Scalars['String']>;
    street?: InputMaybe<Scalars['String']>;
    taxNumber?: InputMaybe<ProfileTaxNumberInput>;
    zipCode?: InputMaybe<Scalars['String']>;
};

export type ProfileAddressInput = {
    city: Scalars['String'];
    countryId: Scalars['String'];
    street: Scalars['String'];
    zipCode: Scalars['String'];
};

export type ProfileAvatarInput = {
    avatar: Scalars['String'];
    full?: InputMaybe<Scalars['String']>;
    medium?: InputMaybe<Scalars['String']>;
};

export type ProfileCitizenshipInput = {
    countryId: Scalars['String'];
};

export type ProfileDisplayInput = {
    displayName: Scalars['String'];
};

export type ProfileDobInput = {
    dob: Scalars['Date'];
};

export type ProfileEmailInput = {
    email: Scalars['String'];
    optOut: Scalars['Boolean'];
};

export type ProfileGenderInput = {
    gender: Scalars['String'];
};

export type ProfileIdentificationInput = {
    passportCountryId: Scalars['String'];
    passportIssuedBy: Scalars['String'];
    passportIssuedOn: Scalars['DateTime'];
    passportNumber: Scalars['String'];
    passportSeries: Scalars['String'];
};

export type ProfileLiveContactInput = {
    login: Scalars['String'];
    system: Scalars['String'];
};

export type ProfileNameInput = {
    firstName: Scalars['String'];
    lastName: Scalars['String'];
    patronymic: Scalars['String'];
};

export type ProfilePhoneInput = {
    optOut: Scalars['Boolean'];
    phone: Scalars['String'];
};

export type ProfileResidenceInput = {
    building: Scalars['String'];
    city: Scalars['String'];
    flat: Scalars['String'];
    house: Scalars['String'];
    region: Scalars['String'];
    street: Scalars['String'];
    zipCode: Scalars['String'];
};

export type ProfileSecurityInput = {
    answer: Scalars['String'];
    question: Scalars['String'];
};

export type ProfileSkypeInput = {
    skype: Scalars['String'];
};

export type ProfileTaxNumberInput = {
    number: Scalars['String'];
    type: TaxTypeEnum;
};

export type PromoRemoteSignUpInput = {
    currencyCode: Scalars['String'];
    displayName?: InputMaybe<Scalars['String']>;
    email?: InputMaybe<Scalars['String']>;
    login?: InputMaybe<Scalars['String']>;
    password: Scalars['String'];
    phone?: InputMaybe<Scalars['String']>;
    successUrl?: InputMaybe<Scalars['String']>;
    trackers?: InputMaybe<TrackersInput>;
};

export type PromoRemoteTokenSignUpInput = {
    token: Scalars['String'];
};

export type PublicBonusProgramListFilterInput = {
    category?: InputMaybe<Scalars['String']>;
    displayInType?: InputMaybe<Scalars['String']>;
};

export type ReSignInInput = {
    trackers?: InputMaybe<TrackersInput>;
};

export type RedirectUrlsInput = {
    redirectBackUrl?: InputMaybe<Scalars['String']>;
    redirectFailUrl?: InputMaybe<Scalars['String']>;
    redirectProgressUrl?: InputMaybe<Scalars['String']>;
    redirectSuccessUrl?: InputMaybe<Scalars['String']>;
};

export type RegisterWebPushSubscriptionInput = {
    authKey: Scalars['String'];
    endpoint: Scalars['String'];
    p256dhKey: Scalars['String'];
};

export enum RequiredDocumentKycTypeEnum {
    AddressProof = 'ADDRESS_PROOF',
    DrivingLicence = 'DRIVING_LICENCE',
    IdPass = 'ID_PASS',
    NationalIdentityCard = 'NATIONAL_IDENTITY_CARD',
    Other = 'OTHER',
    Passport = 'PASSPORT',
    PaymentMethod = 'PAYMENT_METHOD',
    PermanentResidencePermit = 'PERMANENT_RESIDENCE_PERMIT',
    SelfieWithId = 'SELFIE_WITH_ID',
    SourceOfFunds = 'SOURCE_OF_FUNDS',
    SourceOfWealth = 'SOURCE_OF_WEALTH',
    TaxNumber = 'TAX_NUMBER',
}

export enum RequiredDocumentStatusTypeEnum {
    StatusUnknown = 'STATUS_UNKNOWN',
    StatusWaitingApproval = 'STATUS_WAITING_APPROVAL',
    StatusWaitingDocuments = 'STATUS_WAITING_DOCUMENTS',
    StatusWaitingRequest = 'STATUS_WAITING_REQUEST',
}

export type RequiredDocumentUploadedInput = {
    documentType: Scalars['String'];
    group: Scalars['String'];
    id: Scalars['String'];
};

export enum RewardImageEnumType {
    Basic = 'BASIC',
}

export enum RewardTypeEnumType {
    Basic = 'BASIC',
    BonusBalance = 'BONUS_BALANCE',
    FreeSpins = 'FREE_SPINS',
    LoyaltyMultiplier = 'LOYALTY_MULTIPLIER',
    LoyaltyPoints = 'LOYALTY_POINTS',
    RealBalance = 'REAL_BALANCE',
    ScratchLottery = 'SCRATCH_LOTTERY',
    WheelOfFortune = 'WHEEL_OF_FORTUNE',
}

export type SafariWebPushCreateInput = {
    deviceToken: Scalars['String'];
    domain: Scalars['String'];
};

export enum ScopeEnumType {
    Casino = 'CASINO',
    Sport = 'SPORT',
}

export type SearchNewsCategoriesInputTypeInput = {
    newsIds: Array<Scalars['String']>;
};

export type SearchNewsInputTypeInput = {
    categoryIds?: Array<Scalars['String']>;
    categorySlugs?: Array<Scalars['String']>;
    excludedIds?: Array<Scalars['String']>;
    limit?: InputMaybe<Scalars['Int']>;
    name?: InputMaybe<Scalars['String']>;
    offset?: InputMaybe<Scalars['Int']>;
    tagIds?: Array<Scalars['String']>;
    title?: InputMaybe<Scalars['String']>;
};

export type SelfExclusionLockPlayerInput = {
    period?: InputMaybe<Scalars['String']>;
};

export type SignInByAppleIdInput = {
    trackers?: InputMaybe<TrackersInput>;
};

export type SignInByTokenInput = {
    trackers?: InputMaybe<TrackersInput>;
};

export type SignInInput = {
    login: Scalars['String'];
    password: Scalars['String'];
    trackers?: InputMaybe<TrackersInput>;
};

export type SignInRecoveryInput = {
    trackers?: InputMaybe<TrackersInput>;
};

export type SignUpFinishInput = {
    trackers?: InputMaybe<TrackersInput>;
};

export type SignUpInput = {
    agreement: Scalars['Boolean'];
    authMethod?: InputMaybe<AuthMethodIdEnum>;
    confirmEmailCallbackUrl?: InputMaybe<Scalars['String']>;
    credentials: CredentialsCollectionInterfaceInput;
    redeemCode?: InputMaybe<Scalars['String']>;
    refCode?: InputMaybe<Scalars['String']>;
    seenScratchLottery?: InputMaybe<Scalars['String']>;
    trackers?: InputMaybe<TrackersInput>;
};

export type SocialButtonsLoginParamsInput = {
    currencyCode?: InputMaybe<Scalars['String']>;
    originalLocale?: InputMaybe<Scalars['String']>;
    redeemCode?: InputMaybe<Scalars['String']>;
};

export type SocialLoginInput = {
    currencyCode: Scalars['String'];
    originalLocale?: InputMaybe<Scalars['String']>;
    redeemCode: Scalars['String'];
    token: Scalars['String'];
    trackers?: InputMaybe<TrackersInput>;
};

export type StartEmailVerificationInput = {
    callbackUrl?: InputMaybe<Scalars['String']>;
    email: Scalars['String'];
};

export enum TaxTypeEnum {
    BrCpf = 'BR_CPF',
    Tin = 'TIN',
}

export enum TeamRaceSortTypeEnum {
    Alphabet = 'ALPHABET',
    Points = 'POINTS',
}

export type TournamentParticipantsInput = {
    last: Scalars['Boolean'];
    tournamentId: Scalars['String'];
};

export type TournamentRoundInfoInput = {
    previous: Scalars['Boolean'];
    roundId: Scalars['String'];
    sort: TeamRaceSortTypeEnum;
    tournamentId: Scalars['String'];
};

export type TournamentRoundsInfoInput = {
    last: Scalars['Boolean'];
    sort: TeamRaceSortTypeEnum;
    tournamentId: Scalars['String'];
};

export type TournamentRoundsJoinInput = {
    roundId: Scalars['String'];
    teamId: Scalars['String'];
    tournamentId: Scalars['String'];
};

export type TrackerInterfaceInput = {
    name: Scalars['String'];
    value: Scalars['String'];
};

export type TrackersInput = {
    trackers?: Array<TrackerInterfaceInput>;
};

export type TypedBonusProgramListFilterInput = {
    active?: InputMaybe<Scalars['Boolean']>;
    category?: InputMaybe<Scalars['String']>;
    displayInType?: InputMaybe<Scalars['String']>;
};

export type UpdateProfileInput = {
    address?: InputMaybe<ProfileAddressInput>;
    avatar?: InputMaybe<ProfileAvatarInput>;
    citizenship?: InputMaybe<ProfileCitizenshipInput>;
    display?: InputMaybe<ProfileDisplayInput>;
    dob?: InputMaybe<ProfileDobInput>;
    email?: InputMaybe<ProfileEmailInput>;
    gender?: InputMaybe<ProfileGenderInput>;
    identification?: InputMaybe<ProfileIdentificationInput>;
    liveContact?: InputMaybe<ProfileLiveContactInput>;
    name?: InputMaybe<ProfileNameInput>;
    phone?: InputMaybe<ProfilePhoneInput>;
    residence?: InputMaybe<ProfileResidenceInput>;
    security?: InputMaybe<ProfileSecurityInput>;
    skype?: InputMaybe<ProfileSkypeInput>;
    taxNumber?: InputMaybe<ProfileTaxNumberInput>;
};

export enum VipStatusTypeEnum {
    All = 'ALL',
    NotVip = 'NOT_VIP',
    Vip = 'VIP',
}

export enum WheelOfFortuneGameTypeEnum {
    Free = 'FREE',
    Paid = 'PAID',
}

export enum YhelperClassEnum {
    FastChat = 'FAST_CHAT',
    Yhelper = 'YHELPER',
}

export type GetAnalyticsSettingsVariables = Exact<{ [key: string]: never }>;

export type GetAnalyticsSettings = {
    __typename?: 'Query';
    analyticsSettings: {
        __typename?: 'AnalyticsSettingsResult';
        reportServiceUrl: string;
    };
};

export type GetActiveMessagesVariables = Exact<{ [key: string]: never }>;

export type GetActiveMessages = {
    __typename?: 'Query';
    activeMessages: Array<{
        __typename?: 'BroadcastMessageLocalized';
        locale: string;
        content: string;
        id: string;
        startDate: any;
        endDate: any;
        name: string;
        title: string;
    }>;
};

export type ActivateBonusBalanceVariables = Exact<{
    input: ActivateBonusBalanceInput;
}>;

export type ActivateBonusBalance = {
    __typename?: 'Mutation';
    activateBonusBalance: { __typename?: 'SuccessResult'; message: string };
};

export type GetCountriesVariables = Exact<{ [key: string]: never }>;

export type GetCountries = {
    __typename?: 'Query';
    countries: Array<{
        __typename?: 'CountryImpl';
        id: number;
        name: string;
        alpha2: string;
    }>;
};

export type GetAvailableCurrencyVariables = Exact<{ [key: string]: never }>;

export type GetAvailableCurrency = {
    __typename?: 'Query';
    availableCurrency: Array<{
        __typename?: 'CurrencyImpl';
        id: number;
        title: string;
        code: string;
        isoCode: string;
        symbol: string;
    }>;
};

export type GetNewsDetailsVariables = Exact<{
    id: Scalars['String'];
}>;

export type GetNewsDetails = {
    __typename?: 'Query';
    newsDetail: {
        __typename?: 'NewsLocalized';
        parsedContent: string;
        slug: string;
        title: string;
        detailPictureId: string;
        previewPictureId: string;
        mobilePreviewPictureId: string;
        prevId?: string | null;
        nextId?: string | null;
        publishDate: any;
        name: string;
        url: string;
        categories: Array<
            { __typename?: 'NewsCategoryLocalized' } & NewsCategory
        >;
        bonusPrograms: Array<
            { __typename?: 'BonusProgramLocalized' } & BonusProgramTemplate
        >;
    };
};

export type GetPrevNextNewsDetailsVariables = Exact<{
    id: Scalars['String'];
}>;

export type GetPrevNextNewsDetails = {
    __typename?: 'Query';
    newsDetail: {
        __typename?: 'NewsLocalized';
        title: string;
        publishDate: any;
        url: string;
    };
};

export type GetNewsVariables = Exact<{
    input?: InputMaybe<SearchNewsInputTypeInput>;
    withDescription?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetNews = {
    __typename?: 'Query';
    news: {
        __typename?: 'SearchNewsResultType';
        count: number;
        news: Array<{ __typename?: 'NewsLocalized' } & NewsItem>;
    };
};

export type GetBettingPredictionsVariables = Exact<{
    limit: Scalars['Int'];
    offset: Scalars['Int'];
}>;

export type GetBettingPredictions = {
    __typename?: 'Query';
    bettingPredictions: Array<{
        __typename?: 'BettingPrediction';
        tournamentId: string;
        tournamentOutcome: {
            __typename?: 'BettingTournamentOutcome';
            matchedRounds: number;
            totalRounds: number;
        };
    }>;
};

export type SignInByAppleIdVariables = Exact<{
    uid: Scalars['String'];
}>;

export type SignInByAppleId = {
    __typename?: 'Mutation';
    signInByAppleId: {
        __typename?: 'SignInResultType';
        asyncToken: string;
        paseto: string;
    };
};

export type GetSocialButtonsVariables = Exact<{
    redirectUrl: Scalars['String'];
    params?: InputMaybe<SocialButtonsLoginParamsInput>;
}>;

export type GetSocialButtons = {
    __typename?: 'Query';
    socialButtons: {
        __typename?: 'Login4playDataForAnonymousType';
        socialNetworks: Array<string>;
        login4playData: string;
        login4playHost: string;
    };
};

export type GetChatWidgetVariables = Exact<{ [key: string]: never }>;

export type GetChatWidget = {
    __typename?: 'Query';
    chatWidget:
        | { __typename?: 'ChatImpl' }
        | {
              __typename?: 'YhelperChat';
              scriptUrl: string;
              widgetId: number;
              signature: string;
          };
};

export type GetSliderByIdVariables = Exact<{
    id: Scalars['String'];
}>;

export type GetSliderById = {
    __typename?: 'Query';
    slider?: {
        __typename?: 'SliderImpl';
        slides: Array<{ __typename?: 'Slide'; id: string; content: string }>;
    } | null;
};

export type GetWidgetByNameVariables = Exact<{
    name: Scalars['String'];
}>;

export type GetWidgetByName = {
    __typename?: 'Query';
    widgetByName?: {
        __typename?: 'WidgetLocalized';
        template: string;
        fields: Array<{
            __typename?: 'WidgetKeyValueImpl';
            key: string;
            value: string;
        }>;
    } | null;
};

export type GetTeamRaceTournamentParticipantsVariables = Exact<{
    participantsInput: TournamentParticipantsInput;
}>;

export type GetTeamRaceTournamentParticipants = {
    __typename?: 'Query';
    teamRaceTournamentParticipants: {
        __typename?: 'TournamentTeamRaceParticipantsImpl';
        participantRoundsInfo: Array<{
            __typename?: 'TournamentTeamRaceParticipantImpl';
            participantId: string;
            tournamentId: string;
            roundId: string;
            teamId: string;
            betCount: number;
            qualifiedAt?: string | null;
            createdAt: any;
            updatedAt: any;
        }>;
    };
};

export type GetTeamRaceTournamentRoundInfoVariables = Exact<{
    roundInfoInput: TournamentRoundInfoInput;
}>;

export type GetTeamRaceTournamentRoundInfo = {
    __typename?: 'Query';
    teamRaceTournamentRoundInfo: {
        __typename?: 'TournamentTeamRaceRoundsImpl';
        rounds: Array<{
            __typename?: 'TournamentTeamRaceRoundImpl';
            id: string;
            maxParticipantsCount: number;
            startAt: any;
            finishAt: any;
            places?: Array<{
                __typename?: 'TournamentPlaceImpl';
                points?: string | null;
                participantCount?: number | null;
                multiplier: string;
                position: number;
                teamId?: string | null;
                rewards?: Array<{
                    __typename?: 'TournamentReward';
                    type: string;
                    actionId: string;
                    payload?: {
                        __typename?: 'TournamentRewardPayload';
                        pointsAmount?: number | null;
                    } | null;
                }> | null;
            }> | null;
        }>;
    };
};

export type GetTeamRaceTournamentRoundsInfoVariables = Exact<{
    roundsInfoInput: TournamentRoundsInfoInput;
}>;

export type GetTeamRaceTournamentRoundsInfo = {
    __typename?: 'Query';
    teamRaceTournamentRoundsInfo: {
        __typename?: 'TournamentTeamRaceRoundsImpl';
        rounds: Array<{
            __typename?: 'TournamentTeamRaceRoundImpl';
            id: string;
            maxParticipantsCount: number;
            startAt: any;
            finishAt: any;
            places?: Array<{
                __typename?: 'TournamentPlaceImpl';
                points?: string | null;
                participantCount?: number | null;
                multiplier: string;
                position: number;
                teamId?: string | null;
                rewards?: Array<{
                    __typename?: 'TournamentReward';
                    type: string;
                    actionId: string;
                    payload?: {
                        __typename?: 'TournamentRewardPayload';
                        pointsAmount?: number | null;
                    } | null;
                }> | null;
            }> | null;
        }>;
    };
};

export type TeamRaceTournamentRoundsJoinVariables = Exact<{
    roundsJoinInput: TournamentRoundsJoinInput;
}>;

export type TeamRaceTournamentRoundsJoin = {
    __typename?: 'Mutation';
    teamRaceTournamentRoundsJoin: {
        __typename?: 'SuccessResult';
        message: string;
    };
};

export type GetBettingSportByIdVariables = Exact<{
    sportId: Scalars['String'];
    skipPlayerSeo?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetBettingSportById = {
    __typename?: 'Query';
    bettingSportById?: {
        __typename?: 'BettingSportsData';
        teamText?: string;
        playerText?: string;
    } | null;
};

export type GetCmsBannersVariables = Exact<{
    ids: Array<Scalars['String']> | Scalars['String'];
}>;

export type GetCmsBanners = {
    __typename?: 'Query';
    banners: Array<{ __typename: 'DecodedBannerImpl' } & CmsBanner>;
};

export type GetBettingTournamentSettingsByIdVariables = Exact<{
    id: Scalars['String'];
}>;

export type GetBettingTournamentSettingsById = {
    __typename?: 'Query';
    bettingTournamentSettingsById?: {
        __typename?: 'BettingTournamentsImpl';
        sponsors: string;
        promoUrl: string;
    } | null;
};

export type GetRecommendedGamesVariables = Exact<{
    offset?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    excludeIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
    isAuthorized: Scalars['Boolean'];
    isNeedDemoPlayLink?: InputMaybe<Scalars['Boolean']>;
    isNeedPlayData?: InputMaybe<Scalars['Boolean']>;
    isNeedPlayDemoData?: InputMaybe<Scalars['Boolean']>;
    isClient?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetRecommendedGames = {
    __typename?: 'Query';
    recommendedGames: {
        __typename?: 'GamesListImpl';
        count: number;
        games: Array<{ __typename?: 'Game' } & Game>;
    };
};

export type GetWinnersByPeriodVariables = Exact<{
    period: Scalars['Int'];
    limit?: InputMaybe<Scalars['Int']>;
    gameId?: InputMaybe<Scalars['String']>;
}>;

export type GetWinnersByPeriod = {
    __typename?: 'Query';
    winnersByPeriod: Array<{
        __typename?: 'WinnerImpl';
        gameId: string;
        linked: boolean;
        nickname: string;
        value: number;
        game?: {
            __typename?: 'Game';
            url: string;
            id: string;
            title: string;
            imageId: string;
            balanceGroupName: string;
        } | null;
        currencyData: { __typename?: 'CurrencyImpl'; code: string };
    }>;
};

export type GetRandomGamesVariables = Exact<{
    offset?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    isAuthorized: Scalars['Boolean'];
    isNeedDemoPlayLink?: InputMaybe<Scalars['Boolean']>;
    isNeedPlayData?: InputMaybe<Scalars['Boolean']>;
    isNeedPlayDemoData?: InputMaybe<Scalars['Boolean']>;
    isClient?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetRandomGames = {
    __typename?: 'Query';
    randomGames: {
        __typename?: 'GamesListImpl';
        count: number;
        games: Array<{ __typename?: 'Game' } & Game>;
    };
};

export type GetWinnersVariables = Exact<{
    offset?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
}>;

export type GetWinners = {
    __typename?: 'Query';
    winners: Array<{
        __typename?: 'WinnerImpl';
        gameId: string;
        linked: boolean;
        nickname: string;
        value: number;
        game?: {
            __typename?: 'Game';
            url: string;
            id: string;
            title: string;
            imageId: string;
            balanceGroupName: string;
        } | null;
        currencyData: { __typename?: 'CurrencyImpl'; code: string };
    }>;
};

export type MessageEventVariables = Exact<{
    messageId: Scalars['String'];
    action: Scalars['String'];
}>;

export type MessageEvent = {
    __typename?: 'Mutation';
    messageEvent: { __typename?: 'SuccessResult'; message: string };
};

export type SignInVariables = Exact<{
    signIn: SignInInput;
}>;

export type SignIn = {
    __typename?: 'Mutation';
    signIn: {
        __typename?: 'SignInResultType';
        message: string;
        userName: string;
        token: string;
        id: string;
        asyncToken: string;
        paseto: string;
    };
};

export type GetPlayerPasswordExistVariables = Exact<{ [key: string]: never }>;

export type GetPlayerPasswordExist = {
    __typename?: 'Query';
    playerPasswordExist: {
        __typename?: 'PlayerPasswordResultType';
        playerPasswordExist: boolean;
    };
};

export type UpdatePasswordVariables = Exact<{
    changePassword: ChangePasswordInput;
}>;

export type UpdatePassword = {
    __typename?: 'Mutation';
    updatePassword: { __typename?: 'SuccessResult'; message: string };
};

export type ResetPasswordVariables = Exact<{
    changePassword: ChangePasswordInput;
}>;

export type ResetPassword = {
    __typename?: 'Mutation';
    resetPassword: { __typename?: 'SuccessResult'; message: string };
};

export type CheckRecoveryTokenVariables = Exact<{
    token: Scalars['String'];
}>;

export type CheckRecoveryToken = {
    __typename?: 'Query';
    checkRecoveryToken: { __typename?: 'SuccessResult'; message: string };
};

export type ConfirmRecoveryPasswordVariables = Exact<{
    token: Scalars['String'];
}>;

export type ConfirmRecoveryPassword = {
    __typename?: 'Mutation';
    confirmRecoveryPassword: {
        __typename?: 'SignInResultType';
        userName: string;
        id: string;
        token: string;
        asyncToken: string;
        message: string;
        paseto: string;
    };
};

export type CredentialsRecoveryByEmailVariables = Exact<{
    email: Scalars['String'];
    callbackUrl?: InputMaybe<Scalars['String']>;
}>;

export type CredentialsRecoveryByEmail = {
    __typename?: 'Mutation';
    credentialsRecoveryByEmail: {
        __typename?: 'SuccessResult';
        message: string;
    };
};

export type CredentialsRecoveryByPhoneVariables = Exact<{
    phone: Scalars['String'];
}>;

export type CredentialsRecoveryByPhone = {
    __typename?: 'Mutation';
    credentialsRecoveryByPhone: {
        __typename?: 'SuccessResult';
        message: string;
    };
};

export type SignUpFinishVariables = Exact<{
    playerRequisites: PlayerRequisitesTypeInput;
}>;

export type SignUpFinish = {
    __typename?: 'Mutation';
    signUpFinish: {
        __typename?: 'SignUpResultType';
        userName?: string | null;
        id?: string | null;
        token?: string | null;
        paseto?: string | null;
        message: string;
        needPhoneConfirmation: boolean;
        needEmailConfirmation: boolean;
    };
};

export type SignUpVariables = Exact<{
    signUn: SignUpInput;
    playerRequisites?: InputMaybe<PlayerRequisitesTypeInput>;
}>;

export type SignUp = {
    __typename?: 'Mutation';
    signUp: {
        __typename?: 'SignUpResultType';
        userName?: string | null;
        id?: string | null;
        token?: string | null;
        paseto?: string | null;
        message: string;
        needPhoneConfirmation: boolean;
        needEmailConfirmation: boolean;
        createProfileErrorCode?: string | null;
    };
};

export type AcceptTermsAndConditionsVariables = Exact<{
    input: AcceptTermsInput;
}>;

export type AcceptTermsAndConditions = {
    __typename?: 'Mutation';
    acceptTermsAndConditions: { __typename?: 'SuccessResult'; message: string };
};

export type GetCaptchaVariables = Exact<{
    captchaContext?: InputMaybe<CaptchaContext>;
    contextInput?: InputMaybe<CaptchaTokenInput>;
}>;

export type GetCaptcha = {
    __typename?: 'Query';
    captcha: {
        __typename?: 'CaptchaType';
        shouldShow: boolean;
        contextToken?: { __typename: 'ContextToken'; token: string } | null;
        options:
            | { __typename: 'CaptchaOptionsImpl' }
            | {
                  __typename: 'ReCaptchaOptions';
                  publicKey: string;
                  reCaptchaVersion: number;
              };
    };
};

export type GetBettingSettingsVariables = Exact<{
    withSportsIcon?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetBettingSettings = {
    __typename?: 'Query';
    bettingSettings: {
        __typename?: 'BettingSettingsImpl';
        scoreboardEndpoint: string;
        sports: Array<{
            __typename?: 'BettingSportImpl';
            id: string;
            type: string;
            iconId?: string;
        }>;
    };
};

export type AuthLoginTask = {
    __typename?: 'AuthLoginTaskSettingsImpl';
    platformClient: string;
    id: string;
    taskType: string;
};

export type BetTask = {
    __typename?: 'BetTaskSettingsImpl';
    id: string;
    taskType: string;
    betCount: number;
    totalBetAmount: Array<{
        __typename?: 'SettingsCurrencyMapImpl';
        currencyCode: string;
        min?: any | null;
    }>;
    amountPerBet: Array<{
        __typename?: 'SettingsCurrencyMapImpl';
        currencyCode: string;
        min?: any | null;
        max?: any | null;
    }>;
};

export type BettingTask = {
    __typename?: 'BettingTaskSettingsImpl';
    id: string;
    taskType: string;
    winCount: number;
    lossCount: number;
    betCount: number;
    waitSettled: boolean;
    totalWinAmount: Array<{
        __typename?: 'SettingsCurrencyMapImpl';
        currencyCode: string;
        min?: any | null;
        max?: any | null;
    }>;
    totalLossAmount: Array<{
        __typename?: 'SettingsCurrencyMapImpl';
        currencyCode: string;
        min?: any | null;
        max?: any | null;
    }>;
    totalBetAmount: Array<{
        __typename?: 'SettingsCurrencyMapImpl';
        currencyCode: string;
        min?: any | null;
        max?: any | null;
    }>;
    amountPerBet: Array<{
        __typename?: 'SettingsCurrencyMapImpl';
        currencyCode: string;
        min?: any | null;
        max?: any | null;
    }>;
};

export type BonusProgram = {
    __typename?: 'BonusProgramDataImpl';
    id: string;
    name: string;
    description: string;
    createdAt: any;
    taskConnections: Array<{
        __typename?: 'TaskConnectionImpl';
        tasksConnectedTo: Array<string>;
        taskId: string;
    }>;
};

export type BonusProgramTemplate = {
    __typename?: 'BonusProgramLocalized';
    title: string;
    id: string;
    backgroundColor: string;
    link: string;
    label: string;
    category: string;
    description: string;
    rewardBonus: string;
    isPublic: boolean;
    pageId: string;
    name: string;
    customField: string;
    displayIn: Array<string>;
    promotion?: {
        __typename?: 'PromotionLocalized';
        path: string;
        slug: string;
    } | null;
    news?: { __typename?: 'NewsLocalized'; url: string } | null;
    taskTypes: Array<{
        __typename?: 'TaskTypeLocalized';
        name: string;
        taskType: string;
        images: Array<{
            __typename?: 'TaskTypeImageImpl';
            type: string;
            id: string;
        }>;
        description: Array<{
            __typename?: 'TaskDescription';
            taskId: string;
            text: string;
        }>;
    }>;
    images: Array<{
        __typename?: 'BonusProgramImageImpl';
        imageId: string;
        type: string;
    }>;
    availabilityConditions: Array<{
        __typename?: 'AvailabilityConditionImpl';
        startAt: any;
        finishAt: any;
    }>;
    rewards: Array<{
        __typename?: 'RewardLocalized';
        id: string;
        description: string;
        rewardType: RewardTypeEnumType;
        images: Array<{
            __typename?: 'RewardLocalizedImage';
            type: RewardImageEnumType;
            imageId: string;
        }>;
    }>;
};

export type Category = {
    __typename?: 'Category';
    id: string;
    url: string;
    slug: string;
    title: string;
    imageId: string;
    images: Array<{
        __typename?: 'ImageImpl';
        type: string;
        imageId?: string | null;
    }>;
};

export type CompositeTask = {
    __typename?: 'CompositeTaskSettingsImpl';
    tasks: Array<string>;
    operation: string;
    id: string;
    taskType: string;
};

export type ConfirmEmailTask = {
    __typename?: 'ConfirmEmailTaskSettingsImpl';
    id: string;
    taskType: string;
};

export type ConfirmPhoneTask = {
    __typename?: 'ConfirmPhoneTaskSettingsImpl';
    id: string;
    taskType: string;
};

export type DepositTask = {
    __typename?: 'DepositTaskSettingsImpl';
    id: string;
    taskType: string;
    depositCount: number;
    totalDepositAmount: Array<{
        __typename?: 'SettingsCurrencyMapImpl';
        currencyCode: string;
        min?: any | null;
    }>;
    amountPerDeposit: Array<{
        __typename?: 'SettingsCurrencyMapImpl';
        currencyCode: string;
        min?: any | null;
        max?: any | null;
    }>;
};

export type DummyTask = {
    __typename?: 'DummyTaskSettingsImpl';
    id: string;
    taskType: string;
};

export type Game = {
    __typename?: 'Game';
    url: string;
    demoUrl: string;
    supportDemo: boolean;
    id: string;
    slug: string;
    title: string;
    vendorId: string;
    vendorName?: string;
    providerName: string;
    providerId: string;
    imageId: string;
    description?: string;
    new?: boolean;
    favorite?: boolean;
    volatilityLevel: number;
    returnToPlayer?: string;
    balanceGroupName?: string;
    demoPlayLink?: string;
    vendor?: ({ __typename?: 'GameVendor' } & Vendor) | null;
    categories: Array<{
        __typename?: 'Category';
        id: string;
        title: string;
        url: string;
    }>;
    playData?:
        | ({ __typename?: 'AleaPlayPlayData' } & PlayData_AleaPlayPlayData_)
        | ({ __typename?: 'BetGamesPlayData' } & PlayData_BetGamesPlayData_)
        | ({ __typename?: 'NetEntPlayData' } & PlayData_NetEntPlayData_)
        | ({ __typename?: 'PlayDataImpl' } & PlayData_PlayDataImpl_)
        | ({ __typename?: 'SlotoplexPlayData' } & PlayData_SlotoplexPlayData_)
        | ({ __typename?: 'StakelogicPlayData' } & PlayData_StakelogicPlayData_)
        | ({ __typename?: 'TvBetPlayData' } & PlayData_TvBetPlayData_);
    demoPlayData?:
        | ({ __typename?: 'AleaPlayPlayData' } & PlayData_AleaPlayPlayData_)
        | ({ __typename?: 'BetGamesPlayData' } & PlayData_BetGamesPlayData_)
        | ({ __typename?: 'NetEntPlayData' } & PlayData_NetEntPlayData_)
        | ({ __typename?: 'PlayDataImpl' } & PlayData_PlayDataImpl_)
        | ({ __typename?: 'SlotoplexPlayData' } & PlayData_SlotoplexPlayData_)
        | ({ __typename?: 'StakelogicPlayData' } & PlayData_StakelogicPlayData_)
        | ({ __typename?: 'TvBetPlayData' } & PlayData_TvBetPlayData_);
};

export type Vendor = {
    __typename?: 'GameVendor';
    id: string;
    name: string;
    imageId: string;
    vendors: Array<string>;
};

export type ManualTask = {
    __typename?: 'ManualTaskSettingsImpl';
    checkCompleteStrategy: string;
    id: string;
    taskType: string;
};

export type Message = {
    __typename?: 'MessageImpl';
    id: string;
    title: string;
    systemType: string;
    content: string;
    isRead: boolean;
    createdAt: any;
    preview: string;
    campaignId: string;
    media?: {
        __typename?: 'MessageMediaImpl';
        mainImage: {
            __typename?: 'MessageMediaMainImageImpl';
            default: string;
            desktopWebsite: string;
            mobileWebsite: string;
        };
        buttons: Array<{
            __typename?: 'MessageMediaButtonImpl';
            text: string;
            url: string;
        }>;
    } | null;
};

export type NewsCategory = {
    __typename?: 'NewsCategoryLocalized';
    name: string;
    imageId: string;
    slug: string;
    parentCategory?: {
        __typename?: 'NewsCategoryLocalized';
        slug: string;
    } | null;
};

export type NewsItem = {
    __typename?: 'NewsLocalized';
    id: string;
    name: string;
    title: string;
    publishDate: any;
    endDate?: any | null;
    url: string;
    previewPictureId: string;
    slug: string;
    content: string;
    description?: string;
    categories: Array<{ __typename?: 'NewsCategoryLocalized' } & NewsCategory>;
};

export type Participant = {
    __typename?: 'TournamentParticipantImpl';
    tournamentId: string;
    currencyCode: string;
    displayName: string;
    qualified: boolean;
    totalBetAmount: any;
    totalWinAmount: any;
    totalBetCount: number;
    totalWinCount: number;
    lastGameId?: string | null;
    playerId: string;
    points: any;
};

export type BetGamesPlayData = {
    __typename?: 'BetGamesPlayData';
    server: string;
    partner: string;
    token: string;
    language: string;
    currentGame?: string | null;
    iframeClientServer: string;
    demo: boolean;
    gameId: string;
    gameProviderName: string;
    gameServerUrl: string;
    playUsingBonusBalanceEnabled: boolean;
};

export type NetEntPlayData = {
    __typename?: 'NetEntPlayData';
    gameInclusionJsLink: string;
    minWidth?: number | null;
    minHeight?: number | null;
    maxWidth?: number | null;
    gameProviderName: string;
    demo: boolean;
    gameId: string;
    gameServerUrl: string;
    playUsingBonusBalanceEnabled: boolean;
    launchConfig: {
        __typename?: 'NetEntPlayDataLaunchConfig';
        gameId: string;
        gameServer: string;
        staticServer: string;
        casinoBrand: string;
        sessionId: string;
        lobbyUrl: string;
        language: string;
    };
};

export type SlotoplexPlayData = {
    __typename?: 'SlotoplexPlayData';
    launchScript: string;
};

export type StakelogicPlayData = {
    __typename?: 'StakelogicPlayData';
    gameClientJsLibUrl: string;
    demo: boolean;
    gameId: string;
    gameProviderName: string;
    gameServerUrl: string;
    playUsingBonusBalanceEnabled: boolean;
};

export type TvBetPlayData = {
    __typename?: 'TvBetPlayData';
    lng: string;
    clientId: string;
    tokenAuth: string;
    server: string;
    exitUrl: string;
    demo: boolean;
    gameId: string;
    gameProviderName: string;
    gameServerUrl: string;
    playUsingBonusBalanceEnabled: boolean;
};

type PlayData_AleaPlayPlayData_ = {
    __typename?: 'AleaPlayPlayData';
    demo: boolean;
    gameId: string;
    gameProviderName: string;
    gameServerUrl: string;
    gameSizeOptions: {
        __typename?: 'GameSizeOptionsImpl';
        minWidth?: number | null;
        minHeight?: number | null;
        maxWidth?: number | null;
    };
};

type PlayData_BetGamesPlayData_ = {
    __typename?: 'BetGamesPlayData';
    demo: boolean;
    gameId: string;
    gameProviderName: string;
    gameServerUrl: string;
    gameSizeOptions: {
        __typename?: 'GameSizeOptionsImpl';
        minWidth?: number | null;
        minHeight?: number | null;
        maxWidth?: number | null;
    };
} & BetGamesPlayData;

type PlayData_NetEntPlayData_ = {
    __typename?: 'NetEntPlayData';
    demo: boolean;
    gameId: string;
    gameProviderName: string;
    gameServerUrl: string;
    gameSizeOptions: {
        __typename?: 'GameSizeOptionsImpl';
        minWidth?: number | null;
        minHeight?: number | null;
        maxWidth?: number | null;
    };
} & NetEntPlayData;

type PlayData_PlayDataImpl_ = {
    __typename?: 'PlayDataImpl';
    demo: boolean;
    gameId: string;
    gameProviderName: string;
    gameServerUrl: string;
    gameSizeOptions: {
        __typename?: 'GameSizeOptionsImpl';
        minWidth?: number | null;
        minHeight?: number | null;
        maxWidth?: number | null;
    };
};

type PlayData_SlotoplexPlayData_ = {
    __typename?: 'SlotoplexPlayData';
    demo: boolean;
    gameId: string;
    gameProviderName: string;
    gameServerUrl: string;
    gameSizeOptions: {
        __typename?: 'GameSizeOptionsImpl';
        minWidth?: number | null;
        minHeight?: number | null;
        maxWidth?: number | null;
    };
} & SlotoplexPlayData;

type PlayData_StakelogicPlayData_ = {
    __typename?: 'StakelogicPlayData';
    demo: boolean;
    gameId: string;
    gameProviderName: string;
    gameServerUrl: string;
    gameSizeOptions: {
        __typename?: 'GameSizeOptionsImpl';
        minWidth?: number | null;
        minHeight?: number | null;
        maxWidth?: number | null;
    };
} & StakelogicPlayData;

type PlayData_TvBetPlayData_ = {
    __typename?: 'TvBetPlayData';
    demo: boolean;
    gameId: string;
    gameProviderName: string;
    gameServerUrl: string;
    gameSizeOptions: {
        __typename?: 'GameSizeOptionsImpl';
        minWidth?: number | null;
        minHeight?: number | null;
        maxWidth?: number | null;
    };
} & TvBetPlayData;

export type PlayData =
    | PlayData_AleaPlayPlayData_
    | PlayData_BetGamesPlayData_
    | PlayData_NetEntPlayData_
    | PlayData_PlayDataImpl_
    | PlayData_SlotoplexPlayData_
    | PlayData_StakelogicPlayData_
    | PlayData_TvBetPlayData_;

export type PlayerProgram = {
    __typename?: 'PlayerProgram';
    status: {
        __typename?: 'PlayerProgramStatus';
        type: PlayerProgramStatusTypeEnumType;
    };
};

export type PreviewBonusProgramTemplate = {
    __typename?: 'BonusProgramLocalized';
    id: string;
    taskTypes: Array<{ __typename?: 'TaskTypeLocalized'; taskType: string }>;
};

export type Promotion = {
    __typename?: 'PromotionLocalized';
    id: string;
    type: string;
    title: string;
    titleCurrency: string;
    slug: string;
    path: string;
    bonusPrograms: Array<string>;
    endDate: any;
    texts: Array<{
        __typename?: 'PromotionMapImpl';
        value: string;
        valueCurrency: string;
        key: string;
    }>;
    images: Array<{
        __typename?: 'PromotionMapImpl';
        key: string;
        value: string;
    }>;
};

export type TaskSettings = {
    __typename?: 'TaskSettingsCollectionImpl';
    depositTaskSettings: Array<
        { __typename?: 'DepositTaskSettingsImpl' } & DepositTask
    >;
    bettingTaskSettings: Array<
        { __typename?: 'BettingTaskSettingsImpl' } & BettingTask
    >;
    betTaskSettings: Array<{ __typename?: 'BetTaskSettingsImpl' } & BetTask>;
    winTaskSettings: Array<{ __typename?: 'WinTaskSettingsImpl' } & WinTask>;
    confirmEmailTaskSettings: Array<
        { __typename?: 'ConfirmEmailTaskSettingsImpl' } & ConfirmEmailTask
    >;
    confirmPhoneTaskSettings: Array<
        { __typename?: 'ConfirmPhoneTaskSettingsImpl' } & ConfirmPhoneTask
    >;
    manualTaskSettings: Array<
        { __typename?: 'ManualTaskSettingsImpl' } & ManualTask
    >;
    compositeTaskSettings: Array<
        { __typename?: 'CompositeTaskSettingsImpl' } & CompositeTask
    >;
    authLoginTaskSettings: Array<
        { __typename?: 'AuthLoginTaskSettingsImpl' } & AuthLoginTask
    >;
    dummyTaskSettings: Array<
        { __typename?: 'DummyTaskSettingsImpl' } & DummyTask
    >;
};

export type TournamentLocalized = {
    __typename?: 'TournamentLocalized';
    id: string;
    slug: string;
    description: string;
    imageId: string;
    mobileImageId: string;
    sidebarImageId: string;
    title: string;
    startedAt: any;
    finishedAt: any;
    finished: boolean;
    prize: number;
    roundsToPlay: number;
    participationRequired: boolean;
    participantPlace?: number | null;
    prizePool: {
        __typename?: 'TournamentPrizePool';
        places: Array<{
            __typename?: 'TournamentPrizePoolPlace';
            prize:
                | { __typename?: 'TournamentPrizePoolPlaceMultiPrize' }
                | {
                      __typename?: 'TournamentPrizePoolPlaceSinglePrize';
                      amount: number;
                      currencyCode: string;
                  };
        }>;
    };
    texts: Array<{
        __typename?: 'TournamentLocalizedTextImpl';
        type: string;
        text: string;
    }>;
    participants: Array<
        { __typename?: 'TournamentParticipantImpl' } & Participant
    >;
    participant?:
        | ({ __typename?: 'TournamentParticipantImpl' } & Participant)
        | null;
    games: Array<{ __typename?: 'Game' } & Game>;
};

export type WheelSector = {
    __typename?: 'WheelOfFortuneGameSectorImpl';
    id: string;
    prize: {
        __typename?: 'WheelOfFortuneGameSectorPrizeImpl';
        type: string;
        value: any;
        lifeTime?: number | null;
    };
};

export type WinTask = {
    __typename?: 'WinTaskSettingsImpl';
    id: string;
    taskType: string;
    winCount: number;
    totalWinAmount: Array<{
        __typename?: 'SettingsCurrencyMapImpl';
        currencyCode: string;
        min?: any | null;
    }>;
    amountPerWin: Array<{
        __typename?: 'SettingsCurrencyMapImpl';
        currencyCode: string;
        min?: any | null;
        max?: any | null;
    }>;
};

export type CmsBanner = {
    __typename?: 'DecodedBannerImpl';
    id: string;
    title: string;
    subtitle: string;
    imageHref: string;
    buttonText: string;
    buttonHref: string;
    imageSrc: string;
};

export type AddParticipantVariables = Exact<{
    id: Scalars['String'];
}>;

export type AddParticipant = {
    __typename?: 'Mutation';
    addParticipant: { __typename?: 'SuccessResult'; message: string };
};

export type ActivateAvailableBonusProgramVariables = Exact<{
    id: Scalars['String'];
}>;

export type ActivateAvailableBonusProgram = {
    __typename?: 'Mutation';
    activateAvailableBonusProgram: {
        __typename?: 'SuccessResult';
        message: string;
    };
};

export type CancelBonusProgramVariables = Exact<{
    id: Scalars['String'];
}>;

export type CancelBonusProgram = {
    __typename?: 'Mutation';
    cancelBonusProgram: { __typename?: 'SuccessResult'; message: string };
};

export type SetBonusProgramActiveVariables = Exact<{
    id: Scalars['String'];
}>;

export type SetBonusProgramActive = {
    __typename?: 'Mutation';
    setBonusProgramActive: { __typename?: 'SuccessResult'; message: string };
};

export type UseRedeemCodeVariables = Exact<{
    redeemCode: Scalars['String'];
}>;

export type UseRedeemCode = {
    __typename?: 'Mutation';
    useRedeemCode: { __typename?: 'SuccessResult'; message: string };
};

export type ConfirmEmailVerificationVariables = Exact<{
    email: Scalars['String'];
    token: Scalars['String'];
}>;

export type ConfirmEmailVerification = {
    __typename?: 'Mutation';
    confirmEmailVerification: { __typename?: 'SuccessResult'; message: string };
};

export type ConfirmPhoneVerificationVariables = Exact<{
    code: Scalars['String'];
}>;

export type ConfirmPhoneVerification = {
    __typename?: 'Mutation';
    confirmPhoneVerification: { __typename?: 'SuccessResult'; message: string };
};

export type SetGamesFavoriteVariables = Exact<{
    gameId: Scalars['String'];
    isFavorite: Scalars['Boolean'];
}>;

export type SetGamesFavorite = {
    __typename?: 'Mutation';
    setGamesFavorite: { __typename?: 'SuccessResult'; message: string };
};

export type LogoutVariables = Exact<{
    token?: InputMaybe<Scalars['String']>;
}>;

export type Logout = {
    __typename?: 'Mutation';
    logout: { __typename?: 'LogoutResultType'; result: string };
};

export type DeleteMessageVariables = Exact<{
    id: Scalars['String'];
}>;

export type DeleteMessage = {
    __typename?: 'Mutation';
    deleteMessage: { __typename?: 'SuccessResult'; message: string };
};

export type MarkMessagesAsReadVariables = Exact<{ [key: string]: never }>;

export type MarkMessagesAsRead = {
    __typename?: 'Mutation';
    markMessagesAsRead: { __typename?: 'SuccessResult'; message: string };
};

export type SetPlayerLimitsVariables = Exact<{
    input: PlayerLimitsInput;
}>;

export type SetPlayerLimits = {
    __typename?: 'Mutation';
    setPlayerLimits: boolean;
};

export type SignInByTokenVariables = Exact<{
    token: Scalars['String'];
}>;

export type SignInByToken = {
    __typename?: 'Mutation';
    signInByToken: {
        __typename?: 'SignInResultType';
        paseto: string;
        asyncToken: string;
        token: string;
    };
};

export type SocialAttachVariables = Exact<{
    login4playToken: Scalars['String'];
}>;

export type SocialAttach = {
    __typename?: 'Mutation';
    socialAttach: { __typename?: 'SuccessResult'; message: string };
};

export type SocialLoginVariables = Exact<{
    socialLoginInput: SocialLoginInput;
}>;

export type SocialLogin = {
    __typename?: 'Mutation';
    socialLogin: {
        __typename?: 'SocialLoginResultType';
        userName: string;
        id: string;
        token: string;
        asyncToken: string;
        message: string;
        paseto: string;
        login4playResponse: {
            __typename?: 'Login4playResponseImpl';
            email: string;
            phone: string;
        };
    };
};

export type StartEmailVerificationVariables = Exact<{
    startEmailVerification: StartEmailVerificationInput;
}>;

export type StartEmailVerification = {
    __typename?: 'Mutation';
    startEmailVerification: { __typename?: 'SuccessResult'; message: string };
};

export type StartPhoneVerificationVariables = Exact<{ [key: string]: never }>;

export type StartPhoneVerification = {
    __typename?: 'Mutation';
    startPhoneVerification: { __typename?: 'SuccessResult'; message: string };
};

export type UpdateProfileVariables = Exact<{
    updateProfile?: InputMaybe<UpdateProfileInput>;
}>;

export type UpdateProfile = {
    __typename?: 'Mutation';
    updateProfile: { __typename?: 'SuccessResult'; message: string };
};

export type UpdateProfileLocaleVariables = Exact<{
    locale: Scalars['String'];
}>;

export type UpdateProfileLocale = {
    __typename?: 'Mutation';
    updateProfileLocale: { __typename?: 'SuccessResult'; message: string };
};

export type WheelOfFortuneCloseVariables = Exact<{
    wheelName?: Scalars['String'];
}>;

export type WheelOfFortuneClose = {
    __typename?: 'Mutation';
    wheelOfFortuneClose: { __typename?: 'SuccessResult'; message: string };
};

export type MakeWheelOfFortuneReSpinVariables = Exact<{
    wheelName?: Scalars['String'];
}>;

export type MakeWheelOfFortuneReSpin = {
    __typename?: 'Mutation';
    wheelOfFortuneReSpin?: {
        __typename?: 'WheelOfFortuneImpl';
        game?: {
            __typename?: 'WheelOfFortuneGameImpl';
            wonSector: {
                __typename?: 'WheelOfFortuneGameSectorImpl';
                id: string;
            };
        } | null;
    } | null;
};

export type MakeWheelOfFortuneSpinVariables = Exact<{
    gameType: WheelOfFortuneGameTypeEnum;
    multiplier: Scalars['Decimal'];
    wheelName?: Scalars['String'];
}>;

export type MakeWheelOfFortuneSpin = {
    __typename?: 'Mutation';
    wheelOfFortuneSpin?: {
        __typename?: 'WheelOfFortuneImpl';
        game?: {
            __typename?: 'WheelOfFortuneGameImpl';
            wonSector: {
                __typename?: 'WheelOfFortuneGameSectorImpl';
                id: string;
            };
        } | null;
    } | null;
};

export type GetArchivedBonusProgramVariables = Exact<{
    filter?: InputMaybe<TypedBonusProgramListFilterInput>;
    templateIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
}>;

export type GetArchivedBonusProgram = {
    __typename?: 'Query';
    bonusProgramsFullArchived: {
        __typename?: 'ArchivedBonusProgramListType';
        nextOffset: number;
        list: Array<{
            __typename?: 'ArchivedBonusProgram';
            type: string;
            createdAtForPlayer: any;
            bonusProgramData: {
                __typename?: 'ArchivedBonusProgramDataImpl';
                id?: string | null;
                name: string;
                description: string;
                createdAt?: any | null;
                taskSettings: {
                    __typename?: 'TaskSettingsCollectionImpl';
                } & TaskSettings;
                playerProgram: { __typename?: 'PlayerProgram' } & PlayerProgram;
            };
            programTemplate: {
                __typename?: 'BonusProgramLocalized';
            } & BonusProgramTemplate;
        }>;
    };
};

export type GetAvailableBonusProgramVariables = Exact<{
    filter?: InputMaybe<TypedBonusProgramListFilterInput>;
}>;

export type GetAvailableBonusProgram = {
    __typename?: 'Query';
    bonusProgramsFullAvailable: Array<{
        __typename?: 'AvailableBonusProgram';
        type: string;
        bonusProgramData: {
            __typename?: 'BonusProgramDataImpl';
            taskSettings: {
                __typename?: 'TaskSettingsCollectionImpl';
            } & TaskSettings;
        } & BonusProgram;
        programTemplate: {
            __typename?: 'BonusProgramLocalized';
        } & BonusProgramTemplate;
    }>;
};

export type GetBonusProgramsByIdsVariables = Exact<{
    ids: Array<Scalars['String']> | Scalars['String'];
}>;

export type GetBonusProgramsByIds = {
    __typename?: 'Query';
    bonusProgramsByIds: Array<
        { __typename?: 'BonusProgramLocalized' } & BonusProgramTemplate
    >;
};

export type GetProcessingBonusProgramVariables = Exact<{
    filter?: InputMaybe<TypedBonusProgramListFilterInput>;
}>;

export type GetProcessingBonusProgram = {
    __typename?: 'Query';
    bonusProgramsFullProcessing: Array<{
        __typename?: 'ProcessingBonusProgram';
        type: string;
        bonusProgramData: {
            __typename?: 'ProcessingBonusProgramDataImpl';
            active: boolean;
            allowedSetActive: boolean;
            programPlayerId: string;
            tasks: Array<{
                __typename?: 'BonusProgramDataTaskImpl';
                completed: boolean;
                available: boolean;
                data:
                    | {
                          __typename?: 'BonusProgramDataBetTaskData';
                          id: string;
                          taskType: string;
                          bets: Array<any>;
                      }
                    | {
                          __typename?: 'BonusProgramDataBettingTaskData';
                          id: string;
                          taskType: string;
                          bettingBets: Array<{
                              __typename?: 'BonusProgramDataBettingTaskDataBetImpl';
                              id: string;
                              betAmount?: any | null;
                              winAmount?: any | null;
                          }>;
                          status: {
                              __typename?: 'BonusProgramDataTaskDataStatusImpl';
                              type: string;
                          };
                      }
                    | {
                          __typename?: 'BonusProgramDataDepositTaskData';
                          id: string;
                          taskType: string;
                          deposits: Array<any>;
                          status: {
                              __typename?: 'BonusProgramDataTaskDataStatusImpl';
                              type: string;
                          };
                      }
                    | { __typename?: 'BonusProgramDataManualTaskData' }
                    | {
                          __typename?: 'BonusProgramDataTaskData';
                          id: string;
                          taskType: string;
                          status: {
                              __typename?: 'BonusProgramDataTaskDataStatusImpl';
                              type: string;
                          };
                      }
                    | {
                          __typename?: 'BonusProgramDataWinTaskData';
                          id: string;
                          taskType: string;
                          wins: Array<any>;
                      };
                settings: {
                    __typename?: 'TaskSettingsCollectionImpl';
                } & TaskSettings;
                timeLineInfo: Array<{
                    __typename?: 'TimeLineImpl';
                    expiredAt: any;
                }>;
            }>;
            programTemplate: {
                __typename?: 'BonusProgramDataImpl';
            } & BonusProgram;
        };
        programTemplate: {
            __typename?: 'BonusProgramLocalized';
        } & BonusProgramTemplate;
    }>;
};

export type GetPublicBonusProgramVariables = Exact<{
    filter?: InputMaybe<PublicBonusProgramListFilterInput>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
}>;

export type GetPublicBonusProgram = {
    __typename?: 'Query';
    publicBonusPrograms: Array<
        { __typename?: 'BonusProgramLocalized' } & BonusProgramTemplate
    >;
};

export type GetFavoriteGamesVariables = Exact<{
    offset?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    vendorIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
    search?: InputMaybe<Scalars['String']>;
    isAuthorized: Scalars['Boolean'];
    gameStatisticsSortKey?: InputMaybe<GameStatisticsSortKeyEnum>;
    isNeedPlayData?: InputMaybe<Scalars['Boolean']>;
    isNeedPlayDemoData?: InputMaybe<Scalars['Boolean']>;
    isNeedDemoPlayLink?: InputMaybe<Scalars['Boolean']>;
    isClient?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetFavoriteGames = {
    __typename?: 'Query';
    favoriteGames: {
        __typename?: 'GamesListImpl';
        count: number;
        games: Array<{ __typename?: 'Game' } & Game>;
    };
};

export type GetFavoriteGamesVendorsVariables = Exact<{ [key: string]: never }>;

export type GetFavoriteGamesVendors = {
    __typename?: 'Query';
    favoriteGamesVendors: Array<{ __typename?: 'GameVendor' } & Vendor>;
};

export type GetGameBySlugVariables = Exact<{
    slug: Scalars['String'];
    isNeedDemoPlayLink?: InputMaybe<Scalars['Boolean']>;
    isNeedPlayData?: InputMaybe<Scalars['Boolean']>;
    isNeedPlayDemoData?: InputMaybe<Scalars['Boolean']>;
    isAuthorized?: InputMaybe<Scalars['Boolean']>;
    isClient?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetGameBySlug = {
    __typename?: 'Query';
    gameBySlug?: ({ __typename?: 'Game' } & Game) | null;
};

export type GetGameThemesVariables = Exact<{ [key: string]: never }>;

export type GetGameThemes = {
    __typename?: 'Query';
    gameThemes: Array<{
        __typename?: 'FrontendGqlLocalizedTheme';
        title: string;
        slug: string;
        imageId?: string | null;
    }>;
};

export type GetGamesVariables = Exact<{
    offset?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    vendorIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
    categorySlug?: InputMaybe<Scalars['String']>;
    search?: InputMaybe<Scalars['String']>;
    filterByThemeSlug?: InputMaybe<Scalars['String']>;
    isAuthorized?: InputMaybe<Scalars['Boolean']>;
    gameStatisticsSortKey?: InputMaybe<GameStatisticsSortKeyEnum>;
    isNeedDemoPlayLink?: InputMaybe<Scalars['Boolean']>;
    isNeedPlayData?: InputMaybe<Scalars['Boolean']>;
    isNeedPlayDemoData?: InputMaybe<Scalars['Boolean']>;
    isClient?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetGames = {
    __typename?: 'Query';
    games: {
        __typename?: 'GamesListImpl';
        count: number;
        games: Array<{ __typename?: 'Game' } & Game>;
    };
};

export type GetGamesByIdsVariables = Exact<{
    offset?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    ids: Array<Scalars['String']> | Scalars['String'];
    isAuthorized: Scalars['Boolean'];
    isNeedDemoPlayLink?: InputMaybe<Scalars['Boolean']>;
    isNeedPlayData?: InputMaybe<Scalars['Boolean']>;
    isNeedPlayDemoData?: InputMaybe<Scalars['Boolean']>;
    isClient?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetGamesByIds = {
    __typename?: 'Query';
    gamesByIds: {
        __typename?: 'GamesListImpl';
        count: number;
        games: Array<{ __typename?: 'Game' } & Game>;
    };
};

export type GetGamesVendorsVariables = Exact<{
    categorySlug?: InputMaybe<Scalars['String']>;
}>;

export type GetGamesVendors = {
    __typename?: 'Query';
    gamesVendors: Array<{ __typename?: 'GameVendor' } & Vendor>;
};

export type GetLastPlayedGamesVariables = Exact<{
    offset?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    vendorIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
    search?: InputMaybe<Scalars['String']>;
    isAuthorized?: InputMaybe<Scalars['Boolean']>;
    gameStatisticsSortKey?: InputMaybe<GameStatisticsSortKeyEnum>;
    isNeedPlayData?: InputMaybe<Scalars['Boolean']>;
    isNeedDemoPlayLink?: InputMaybe<Scalars['Boolean']>;
    isNeedPlayDemoData?: InputMaybe<Scalars['Boolean']>;
    isClient?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetLastPlayedGames = {
    __typename?: 'Query';
    lastPlayedGames: {
        __typename?: 'GamesListImpl';
        count: number;
        games: Array<{ __typename?: 'Game' } & Game>;
    };
};

export type GetLastPlayedGamesVendorsVariables = Exact<{
    [key: string]: never;
}>;

export type GetLastPlayedGamesVendors = {
    __typename?: 'Query';
    lastPlayedGamesVendors: Array<{ __typename?: 'GameVendor' } & Vendor>;
};

export type GetPopularGamesVariables = Exact<{
    offset?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    isAuthorized?: InputMaybe<Scalars['Boolean']>;
    isNeedPlayData?: InputMaybe<Scalars['Boolean']>;
    isNeedPlayDemoData?: InputMaybe<Scalars['Boolean']>;
    isNeedDemoPlayLink?: InputMaybe<Scalars['Boolean']>;
    isClient?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetPopularGames = {
    __typename?: 'Query';
    popularGames: {
        __typename?: 'GamesListImpl';
        games: Array<{ __typename?: 'Game' } & Game>;
    };
};

export type GetBannedLocationVariables = Exact<{ [key: string]: never }>;

export type GetBannedLocation = {
    __typename?: 'Query';
    bannedLocation: {
        __typename?: 'BannedLocationResultType';
        locationBlocked: boolean;
    };
};

export type GetBonusProgramByCategoryVariables = Exact<{
    filter: BonusProgramListFilterInput;
}>;

export type GetBonusProgramByCategory = {
    __typename?: 'Query';
    bonusPrograms: Array<{
        __typename?: 'BonusProgramFull';
        type: string;
        bonusProgramData: {
            __typename?: 'BonusProgramDataImpl';
        } & BonusProgram;
        programTemplate: {
            __typename?: 'BonusProgramLocalized';
            title: string;
            description: string;
            imageId: string;
            id: string;
            link: string;
            name: string;
        };
        availableProgramData?:
            | ({
                  __typename?: 'BonusProgramDataImpl';
                  taskSettings: {
                      __typename?: 'TaskSettingsCollectionImpl';
                      order: Array<string>;
                  } & TaskSettings;
              } & BonusProgram)
            | null;
        processingProgramData?: {
            __typename?: 'ProcessingBonusProgramDataImpl';
            active: boolean;
            allowedSetActive: boolean;
            programPlayerId: string;
            tasks: Array<{
                __typename?: 'BonusProgramDataTaskImpl';
                available: boolean;
                data:
                    | {
                          __typename?: 'BonusProgramDataBetTaskData';
                          id: string;
                          taskType: string;
                          bets: Array<any>;
                      }
                    | {
                          __typename?: 'BonusProgramDataBettingTaskData';
                          id: string;
                          taskType: string;
                          bettingBets: Array<{
                              __typename?: 'BonusProgramDataBettingTaskDataBetImpl';
                              id: string;
                              betAmount?: any | null;
                              winAmount?: any | null;
                          }>;
                      }
                    | {
                          __typename?: 'BonusProgramDataDepositTaskData';
                          id: string;
                          taskType: string;
                          deposits: Array<any>;
                      }
                    | { __typename?: 'BonusProgramDataManualTaskData' }
                    | { __typename?: 'BonusProgramDataTaskData' }
                    | {
                          __typename?: 'BonusProgramDataWinTaskData';
                          id: string;
                          taskType: string;
                          wins: Array<any>;
                      };
                settings: {
                    __typename?: 'TaskSettingsCollectionImpl';
                    order: Array<string>;
                } & TaskSettings;
                timeLineInfo: Array<{
                    __typename?: 'TimeLineImpl';
                    expiredAt: any;
                }>;
            }>;
            programTemplate: {
                __typename?: 'BonusProgramDataImpl';
            } & BonusProgram;
        } | null;
    }>;
};

export type GetCurrentMirrorVariables = Exact<{ [key: string]: never }>;

export type GetCurrentMirror = {
    __typename?: 'Query';
    currentMirror?: { __typename?: 'MirrorImpl'; metaTags: string } | null;
};

export type GetDefaultLocaleVariables = Exact<{ [key: string]: never }>;

export type GetDefaultLocale = { __typename?: 'Query'; defaultLocale: string };

export type GetDynamicRouteByPathVariables = Exact<{
    path: Scalars['String'];
    pathWithoutLocale?: InputMaybe<Scalars['String']>;
    withExcludedLocale?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetDynamicRouteByPath = {
    __typename?: 'Query';
    dynamicRouteByPath: {
        __typename?: 'dynamicRouteResult';
        entity?: {
            __typename?: 'dynamicRouteEntity';
            info:
                | {
                      __typename?: 'GameLocalizedPlatformedRoutingRelatedEntity';
                      id: string;
                      entityType: string;
                  }
                | {
                      __typename?: 'NewsLocalizedRoutingRelatedEntity';
                      id: string;
                      entityType: string;
                      entity: {
                          __typename?: 'NewsLocalized';
                          tags: Array<string>;
                      };
                  }
                | {
                      __typename?: 'PageLocalizedRoutingRelatedEntity';
                      id: string;
                      entityType: string;
                      entity: {
                          __typename?: 'PageLocalized';
                          id: string;
                          tags: Array<string>;
                          layoutType: string;
                          content: string;
                      };
                  }
                | {
                      __typename?: 'RoutingRelatedEntityInfoImpl';
                      id: string;
                      entityType: string;
                  }
                | {
                      __typename?: 'TournamentLocalizedRoutingRelatedEntity';
                      id: string;
                      entityType: string;
                  };
        } | null;
    };
    dynamicRouteByPathWithoutLocale: {
        __typename?: 'dynamicRouteResult';
        entity?: {
            __typename?: 'dynamicRouteEntity';
            info:
                | {
                      __typename?: 'GameLocalizedPlatformedRoutingRelatedEntity';
                      id: string;
                      entityType: string;
                  }
                | {
                      __typename?: 'NewsLocalizedRoutingRelatedEntity';
                      id: string;
                      entityType: string;
                  }
                | {
                      __typename?: 'PageLocalizedRoutingRelatedEntity';
                      id: string;
                      entityType: string;
                  }
                | {
                      __typename?: 'RoutingRelatedEntityInfoImpl';
                      id: string;
                      entityType: string;
                  }
                | {
                      __typename?: 'TournamentLocalizedRoutingRelatedEntity';
                      id: string;
                      entityType: string;
                  };
        } | null;
    };
};

export type GetFirstDepositFlowVariables = Exact<{ [key: string]: never }>;

export type GetFirstDepositFlow = {
    __typename?: 'Query';
    firstRequiredDepositFlow: {
        __typename?: 'FirstRequiredDepositFlowType';
        paseto?: string | null;
        firstRequiredDepositFlow?: string | null;
    };
};

export type GetFreeSpinsVariables = Exact<{ [key: string]: never }>;

export type GetFreeSpins = {
    __typename?: 'Query';
    freeSpins: Array<{
        __typename?: 'FreeSpinImpl';
        spinsNumber: number;
        expiresAt?: any | null;
        games: Array<{
            __typename?: 'Game';
            url: string;
            title: string;
            images: Array<{
                __typename?: 'ImageImpl';
                type: string;
                imageId?: string | null;
            }>;
        }>;
    }>;
};

export type GetGamesCategoriesVariables = Exact<{ [key: string]: never }>;

export type GetGamesCategories = {
    __typename?: 'Query';
    gamesCategories: Array<{ __typename?: 'Category' } & Category>;
};

export type GetGeoIpLocationVariables = Exact<{ [key: string]: never }>;

export type GetGeoIpLocation = {
    __typename?: 'Query';
    geoIpLocation: {
        __typename?: 'GeoIpLocationType';
        countryIsoCode?: string | null;
        cityName?: string | null;
    };
};

export type GetGtmDataInfoVariables = Exact<{ [key: string]: never }>;

export type GetGtmDataInfo = {
    __typename?: 'Query';
    gtmDataInfo: {
        __typename?: 'InfoResultType';
        stats: {
            __typename?: 'PlayerStatisticsImpl';
            totalDepositCount: number;
        };
        events: Array<{
            __typename?: 'EventImpl';
            type: string;
            createdAt?: any | null;
            data?: {
                __typename?: 'EventDataImpl';
                transactionId?: {
                    __typename?: 'DataTransactionImpl';
                    local: string;
                } | null;
            } | null;
        }>;
    };
};

export type GetKeyValueVariables = Exact<{
    key: Scalars['String'];
    default?: InputMaybe<Scalars['String']>;
}>;

export type GetKeyValue = {
    __typename?: 'Query';
    keyValue: { __typename?: 'KeyValueType'; value: string };
};

export type GetLocaleMenuVariables = Exact<{
    id: Scalars['String'];
    locale: Scalars['String'];
}>;

export type GetLocaleMenu = {
    __typename?: 'Query';
    localeMenu?: {
        __typename?: 'LocalizedMenuImpl';
        id: string;
        locale: string;
        items: Array<{
            __typename?: 'LocalizedMenuItemImpl';
            url: string;
            anchor: string;
            status: string;
            imageId: string;
            expanded: boolean;
        }>;
    } | null;
};

export type GetLocalesVariables = Exact<{ [key: string]: never }>;

export type GetLocales = { __typename?: 'Query'; locales: Array<string> };

export type GetOwnFeatureFlagsVariables = Exact<{ [key: string]: never }>;

export type GetOwnFeatureFlags = {
    __typename?: 'Query';
    ownFeatureFlags: Array<{
        __typename?: 'OwnFeatureFlag';
        name: string;
        enabled: boolean;
    }>;
};

export type GetParticipantFeaturesVariables = Exact<{ [key: string]: never }>;

export type GetParticipantFeatures = {
    __typename?: 'Query';
    featureFlags: Array<{
        __typename?: 'ParticipantImpl';
        featureVariant: string;
        scenarioIdentifier: string;
        shouldAnalysis: boolean;
        featureName: string;
    }>;
};

export type GetPaymentHistoryVariables = Exact<{
    statuses?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    type?: InputMaybe<PaymentMethodTypeEnum>;
    dateFrom?: InputMaybe<Scalars['DateTime']>;
    dateTo?: InputMaybe<Scalars['DateTime']>;
}>;

export type GetPaymentHistory = {
    __typename?: 'Query';
    paymentHistory: {
        __typename?: 'PaymentHistoryResultImpl';
        hasNextPart: boolean;
        transactions: Array<
            | {
                  __typename?: 'PaymentHistoryTransaction';
                  paymentMethod:
                      | {
                            __typename?: 'PaymentHistoryTransaction';
                            title: string;
                        }
                      | {
                            __typename?: 'PaymentMethodLocalizationImpl';
                            title: string;
                        }
                      | { __typename?: 'PaymentTransaction'; title: string };
                  transaction: {
                      __typename?: 'PaymentTransactionDtoImpl';
                      type: string;
                      id: {
                          __typename?: 'PaymentTransactionIdDtoImpl';
                          local: string;
                      };
                      status: {
                          __typename?: 'PaymentTransactionStatusDtoImpl';
                          type: string;
                          createdAt?: string | null;
                      };
                      amount: {
                          __typename?: 'PaymentTransactionAmountDtoImpl';
                          source: {
                              __typename?: 'PaymentAmountDto';
                              currencyCode: string;
                              value: string;
                          };
                      };
                  };
              }
            | {
                  __typename?: 'PaymentTransaction';
                  paymentMethod:
                      | {
                            __typename?: 'PaymentHistoryTransaction';
                            title: string;
                        }
                      | {
                            __typename?: 'PaymentMethodLocalizationImpl';
                            title: string;
                        }
                      | { __typename?: 'PaymentTransaction'; title: string };
                  transaction: {
                      __typename?: 'PaymentTransactionDtoImpl';
                      type: string;
                      id: {
                          __typename?: 'PaymentTransactionIdDtoImpl';
                          local: string;
                      };
                      status: {
                          __typename?: 'PaymentTransactionStatusDtoImpl';
                          type: string;
                          createdAt?: string | null;
                      };
                      amount: {
                          __typename?: 'PaymentTransactionAmountDtoImpl';
                          source: {
                              __typename?: 'PaymentAmountDto';
                              currencyCode: string;
                              value: string;
                          };
                      };
                  };
              }
            | {
                  __typename?: 'PaymentTransactionImpl';
                  paymentMethod:
                      | {
                            __typename?: 'PaymentHistoryTransaction';
                            title: string;
                        }
                      | {
                            __typename?: 'PaymentMethodLocalizationImpl';
                            title: string;
                        }
                      | { __typename?: 'PaymentTransaction'; title: string };
                  transaction: {
                      __typename?: 'PaymentTransactionDtoImpl';
                      type: string;
                      id: {
                          __typename?: 'PaymentTransactionIdDtoImpl';
                          local: string;
                      };
                      status: {
                          __typename?: 'PaymentTransactionStatusDtoImpl';
                          type: string;
                          createdAt?: string | null;
                      };
                      amount: {
                          __typename?: 'PaymentTransactionAmountDtoImpl';
                          source: {
                              __typename?: 'PaymentAmountDto';
                              currencyCode: string;
                              value: string;
                          };
                      };
                  };
              }
        >;
    };
};

export type GetPlayerActuallyLimitsVariables = Exact<{ [key: string]: never }>;

export type GetPlayerActuallyLimits = {
    __typename?: 'Query';
    playerActuallyLimits: Array<{
        __typename?: 'LimitImpl';
        type: string;
        startAt: any;
        finishAt?: any | null;
        period?: string | null;
        money?: Array<{
            __typename?: 'LimitMoneyImpl';
            field: string;
            value: string;
        }> | null;
        interval?: { __typename?: 'Interval'; h: number } | null;
    }>;
};

export type GetPlayerBalancesVariables = Exact<{ [key: string]: never }>;

export type GetPlayerBalances = {
    __typename?: 'Query';
    playerBalances?: {
        __typename?: 'PlayerBalancesResultType';
        realBalance: {
            __typename?: 'BalanceImpl';
            id: number;
            balance: number;
            refundSum: number;
        };
        bonusBalances: Array<{
            __typename?: 'BonusBalanceImpl';
            id: number;
            name: string;
            percentage: number;
            rechargedRefundSum: number;
            balanceProgramTemplateId: string;
            wager: number;
            balance: number;
            refundSum: number;
            expiredAt: any;
            createdAt: any;
            groups: Array<{
                __typename?: 'BonusBalanceGroupImpl';
                isActive: boolean;
                name: string;
            }>;
        }>;
        currency: { __typename?: 'CurrencyImpl'; id: number; code: string };
    } | null;
};

export type GetProfileVariables = Exact<{ [key: string]: never }>;

export type GetProfile = {
    __typename?: 'Query';
    user?:
        | {
              __typename?: 'Player';
              userName: string;
              roles: Array<string>;
              id: string;
              displayName: string;
              login: string;
              email: string;
              emailConfirmed: boolean;
              emailConfirmedAt?: any | null;
              receiveEmails: boolean;
              phone: string;
              phoneConfirmed: boolean;
              skype: string;
              gender: string;
              dateOfBirthday?: any | null;
              dateOfBirthdayConfirmed: boolean;
              countryId: number;
              placeOfBirth: string;
              priorityTypeOfGambling: string;
              loginBlocked: boolean;
              sanctioned: boolean;
              pep: boolean;
              selfDeclaredPep: boolean;
              onfidoCheckedAt?: any | null;
              refCode: string;
              updatedAt: any;
              createdAt: any;
              license: string;
              name?: {
                  __typename?: 'NameElement';
                  first: string;
                  last: string;
                  middle: string;
                  maiden: string;
                  patronymic: string;
              } | null;
              address?: {
                  __typename?: 'AddressElement';
                  street: string;
                  zipCode: string;
                  city: string;
                  country: string;
              } | null;
              avatar?: {
                  __typename?: 'AvatarElement';
                  avatar: string;
                  medium: string;
                  full: string;
              } | null;
              wealth?: {
                  __typename?: 'WealthElement';
                  source: string;
                  createdAt: any;
                  updatedAt?: any | null;
              } | null;
          }
        | {
              __typename?: 'SymfonyUserImpl';
              userName: string;
              roles: Array<string>;
          }
        | {
              __typename?: 'User';
              userName: string;
              roles: Array<string>;
              id: string;
          }
        | null;
};

export type GetRelevantLocaleVariables = Exact<{ [key: string]: never }>;

export type GetRelevantLocale = {
    __typename?: 'Query';
    relevantLocale: { __typename?: 'RelevantLocaleResultType'; locale: string };
};

export type GetSliderVariables = Exact<{
    id: Scalars['String'];
}>;

export type GetSlider = {
    __typename?: 'Query';
    slider?: {
        __typename?: 'SliderImpl';
        slides: Array<{
            __typename?: 'Slide';
            id: string;
            content: string;
            name: string;
            fields: Array<{
                __typename?: 'SlideField';
                key: string;
                value: string;
            }>;
        }>;
    } | null;
};

export type GetStaticPageDataVariables = Exact<{
    ids: Array<Scalars['String']> | Scalars['String'];
}>;

export type GetStaticPageData = {
    __typename?: 'Query';
    pagesByIds: Array<{
        __typename?: 'PageLocalized';
        id: string;
        content: string;
        name: string;
        title: string;
    }>;
};

export type GetUnacceptedTermsAndConditionsLogsVariables = Exact<{
    [key: string]: never;
}>;

export type GetUnacceptedTermsAndConditionsLogs = {
    __typename?: 'Query';
    unacceptedTermsAndConditionsLogs: {
        __typename?: 'UnacceptedLogsResultType';
        logs: Array<{
            __typename?: 'FrontendServiceResultImpl';
            type: string;
            logs: Array<{
                __typename?: 'FrontendServiceResultLogImpl';
                id: string;
                log: string;
                version: string;
            }>;
        }>;
    };
};

export type GetDocumentsStatusVariables = Exact<{ [key: string]: never }>;

export type GetDocumentsStatus = {
    __typename?: 'Query';
    requiredDocumentsStatus: {
        __typename?: 'RequiredDocumentStatusType';
        status: RequiredDocumentStatusTypeEnum;
    };
};

export type GetFilesHistoryVariables = Exact<{ [key: string]: never }>;

export type GetFilesHistory = {
    __typename?: 'Query';
    documents: {
        __typename?: 'DocumentListType';
        documents: Array<{
            __typename?: 'DocumentImpl';
            id: string;
            description?: string | null;
            type: string;
            status: string;
            createdAt: any;
        }>;
    };
};

export type GetKycVariables = Exact<{ [key: string]: never }>;

export type GetKyc = {
    __typename?: 'Query';
    kyc: { __typename?: 'KycType'; confirmStatus: boolean; status: string };
};

export type GetKycaidVerificationsVariables = Exact<{ [key: string]: never }>;

export type GetKycaidVerifications = {
    __typename?: 'Query';
    kycaidVerifications: {
        __typename?: 'KycaidVerificationsResult';
        hasCompletedVerification: boolean;
        verifications: Array<{
            __typename?: 'KycaidVerification';
            status: KycaidVerificationStatusGraphqlEnum;
            type: KycaidVerificationTypeGraphqlEnum;
        }>;
    };
};

export type GetMessageVariables = Exact<{
    id: Scalars['String'];
}>;

export type GetMessage = {
    __typename?: 'Query';
    message?: ({ __typename?: 'MessageImpl' } & Message) | null;
};

export type GetMessagesVariables = Exact<{
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    isRead?: InputMaybe<Scalars['Boolean']>;
    createdFrom?: InputMaybe<Scalars['DateTime']>;
}>;

export type GetMessages = {
    __typename?: 'Query';
    messages: Array<{ __typename?: 'MessageImpl' } & Message>;
};

export type GetMessagesInfoVariables = Exact<{
    createdFrom?: InputMaybe<Scalars['DateTime']>;
}>;

export type GetMessagesInfo = {
    __typename?: 'Query';
    messagesInfo: {
        __typename?: 'MessagesInfoResultImpl';
        unread: number;
        total: number;
        message?: ({ __typename?: 'MessageImpl' } & Message) | null;
    };
};

export type GetPromotionByIdVariables = Exact<{
    id: Scalars['String'];
}>;

export type GetPromotionById = {
    __typename?: 'Query';
    promotion?: ({ __typename?: 'PromotionLocalized' } & Promotion) | null;
};

export type GetPromotionListVariables = Exact<{
    type?: InputMaybe<Scalars['String']>;
}>;

export type GetPromotionList = {
    __typename?: 'Query';
    promotionList: {
        __typename?: 'PromotionLocalizedListType';
        list: Array<{ __typename?: 'PromotionLocalized' } & Promotion>;
    };
};

export type GetTournamentByFiltersVariables = Exact<{
    active?: InputMaybe<Scalars['Boolean']>;
    types?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
    started?: InputMaybe<Scalars['Boolean']>;
    finished?: InputMaybe<Scalars['Boolean']>;
    isNeedPrize?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetTournamentByFilters = {
    __typename?: 'Query';
    tournamentsByFilters: Array<{
        __typename?: 'TournamentLocalized';
        id: string;
        slug: string;
        sidebarMiniImageId: string;
        title: string;
        finished: boolean;
        prize?: number;
    }>;
};

export type GetTournamentBySlugVariables = Exact<{
    slug: Scalars['String'];
    isAuthorized?: InputMaybe<Scalars['Boolean']>;
    isNeedPlayData?: InputMaybe<Scalars['Boolean']>;
    isNeedPlayDemoData?: InputMaybe<Scalars['Boolean']>;
    isNeedDemoPlayLink?: InputMaybe<Scalars['Boolean']>;
    isNeedParticipantPlace?: InputMaybe<Scalars['Boolean']>;
    isClient?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetTournamentBySlug = {
    __typename?: 'Query';
    tournamentBySlug?:
        | ({ __typename?: 'TournamentLocalized' } & TournamentLocalized)
        | null;
};

export type GetTournamentParticipantCountVariables = Exact<{
    id: Scalars['String'];
}>;

export type GetTournamentParticipantCount = {
    __typename?: 'Query';
    tournamentParticipantCount: number;
};

export type GetWheelFreeGamesVariables = Exact<{
    wheelName?: InputMaybe<Scalars['String']>;
}>;

export type GetWheelFreeGames = {
    __typename?: 'Query';
    wheelOfFortuneFreeGames?: {
        __typename?: 'WheelOfFortuneFreeGamesImpl';
        quantity: number;
    } | null;
};

export type GetWheelInfoVariables = Exact<{
    multiplier: Scalars['Decimal'];
    currency: Scalars['String'];
    wheelName?: InputMaybe<Scalars['String']>;
}>;

export type GetWheelInfo = {
    __typename?: 'Query';
    wheelOfFortuneInfo?: {
        __typename?: 'WheelOfFortuneImpl';
        gameCost: any;
        requestedWheel?: {
            __typename?: 'WheelOfFortuneGameWheelImpl';
            sectors: Array<
                { __typename?: 'WheelOfFortuneGameSectorImpl' } & WheelSector
            >;
        } | null;
    } | null;
};

export type GetWheelOfFortuneStatusVariables = Exact<{
    gameType?: InputMaybe<WheelOfFortuneGameTypeEnum>;
    multiplier: Scalars['Decimal'];
    wheelName?: InputMaybe<Scalars['String']>;
}>;

export type GetWheelOfFortuneStatus = {
    __typename?: 'Query';
    wheelOfFortuneStatus?: {
        __typename?: 'WheelOfFortuneImpl';
        freeGamesQuantity: number;
        freeGamesTimeToLive: Array<{
            __typename?: 'FreeGamesTimeToLive';
            quantity: number;
            expiredAt: any;
        }>;
    } | null;
};

export type GetPagesUrlBySlugVariables = Exact<{
    slugs: Array<Scalars['String']> | Scalars['String'];
}>;

export type GetPagesUrlBySlug = {
    __typename?: 'Query';
    pagesBySlugs: Array<{
        __typename?: 'PageLocalized';
        id: string;
        url?: string | null;
    }>;
};

export type GetSubLogoVariables = Exact<{ [key: string]: never }>;

export type GetSubLogo = {
    __typename?: 'Query';
    subLogo?: {
        __typename?: 'SubLogoLocalized';
        link: string;
        title: string;
        images: Array<{
            __typename?: 'SubLogoImageImpl';
            type: string;
            imageId?: string | null;
        }>;
    } | null;
};

export type GetRelevantCurrencyVariables = Exact<{ [key: string]: never }>;

export type GetRelevantCurrency = { __typename?: 'Query'; currency: string };

export type GetSeoHeadersVariables = Exact<{
    path: Scalars['String'];
}>;

export type GetSeoHeaders = {
    __typename?: 'Query';
    seoHeaders: Array<{
        __typename?: 'SeoHeaderResultType';
        key: string;
        value: string;
    }>;
};

export type GetSeoRulesByPageVariables = Exact<{
    pageUrl: Scalars['String'];
    pageUrlWithoutLocale?: InputMaybe<Scalars['String']>;
}>;

export type GetSeoRulesByPage = {
    __typename?: 'Query';
    seoRulesByPage: {
        __typename?: 'SeoResult';
        seo?: {
            __typename?: 'SeoImpl';
            title: string;
            description: string;
            pageHeader: string;
            keywords: string;
            texts: Array<{ __typename?: 'SeoTextImpl'; content: string }>;
            metaTags: Array<{
                __typename?: 'SeoMetaTagImpl';
                name: string;
                content: string;
            }>;
        } | null;
        info?: {
            __typename?: 'SeoInfoImpl';
            description: string;
            title: string;
            canonical: string;
            text: string;
            alternateLinkList: Array<{
                __typename?: 'SeoAlternateLinksResult';
                href: string;
                hrefLang: string;
            }>;
        } | null;
        redirect?: {
            __typename?: 'SeoRedirectFromRequest';
            url: string;
            statusCode: number;
        } | null;
    };
    seoRulesByPageWithoutLocale: {
        __typename?: 'SeoResult';
        redirect?: {
            __typename?: 'SeoRedirectFromRequest';
            url: string;
            statusCode: number;
        } | null;
    };
};

export type GetBettingDataVariables = Exact<{ [key: string]: never }>;

export type GetBettingData = {
    __typename?: 'Query';
    bettingData: {
        __typename?: 'BettingData';
        token: string;
        endpoint: string;
    };
};

export type SetCrmTrackerVariables = Exact<{
    crmCode: Scalars['String'];
}>;

export type SetCrmTracker = {
    __typename?: 'Mutation';
    crmNotificationTracker: { __typename?: 'SuccessResult'; message: string };
};
