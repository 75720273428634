import React from 'react';

import ErrorBoundary from 'components/ErrorBoundary';

const withErrorBoundary = <T extends {}>(Component: React.FC<T>) =>
    function WithClientRender(props: T): JSX.Element | null {
        return (
            <ErrorBoundary>
                <Component {...props} />
            </ErrorBoundary>
        );
    };

export default withErrorBoundary;
