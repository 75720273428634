import { useCallback, useMemo } from 'react';
import {
    useInRouterContext,
    useLocation,
    useMatches,
    useParams,
    useSearchParams,
} from 'react-router-dom';
import { noop } from 'lodash';

import HybridRouterContext from './HybridRouterContext';
import RouterHandlersProvider from './RouterPushProvider';
import ServerRouter from './ServerRouter';
import { HybridRouter } from './types';
import {
    convertClientQueryToNextQuery,
    convertClientRouterPathnameToNextPathname,
} from './utils';

interface Props {
    children: React.ReactNode;
    locale?: string;
}

const HybridRouterProvider: React.FC<Props> = ({ children, locale }) => {
    // NOTE: we use the useInRouterContext here so that the logic works after the first render
    const isClientRouter = useInRouterContext();

    if (!isClientRouter) {
        return <ServerRouter>{children}</ServerRouter>;
    }

    return (
        <ClientRouterAdapter locale={locale}>
            {(router) => (
                <RouterHandlersProvider locale={locale}>
                    <HybridRouterContext.Provider value={router}>
                        {children}
                    </HybridRouterContext.Provider>
                </RouterHandlersProvider>
            )}
        </ClientRouterAdapter>
    );
};

interface ClientRouterAdapterProps {
    locale?: string;
    children: (router: HybridRouter) => JSX.Element;
}

const ClientRouterAdapter: React.FC<ClientRouterAdapterProps> = ({
    children,
    locale,
}) => {
    const { search, hash, state } = useLocation();
    const [{ pathname, params }] = useMatches();

    const parsedPathname = useMemo(
        () =>
            convertClientRouterPathnameToNextPathname({
                params,
                pathname,
            }),
        [params, pathname]
    );

    const routerParams = useParams();
    const [searchParams] = useSearchParams();
    const query = useMemo(
        () =>
            convertClientQueryToNextQuery({
                searchParams,
                routerParams,
                convertedPathname: parsedPathname,
            }),
        [searchParams, routerParams, parsedPathname]
    );

    const events = {
        on: noop,
        off: noop,
        emit: noop,
    };

    const back = useCallback(() => {
        window.history.back();
    }, []);

    const reload = useCallback(() => {
        window.location.reload();
    }, []);

    const asPath = `${pathname}${search}${hash}`;

    const asPathObj = {
        pathname,
        search,
        hash,
    };

    const router: HybridRouter = {
        pathname: parsedPathname,
        query,
        asPath,
        back,
        reload,
        prefetch: noop,
        locale,
        events,
        state,
        asPathObj,
    };

    return children(router);
};

export default HybridRouterProvider;
