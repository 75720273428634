import { useMemo } from 'react';

import { useLocales, useRouterData } from 'hooks';
import { useGsspData } from 'layouts/Root/PagePropsContext';
import { GetSeoRulesByPage } from 'types/gql.cms';
import { SportListId } from './types';
import { useShowDynamicPageFooterSeo } from './useShowDynamicPageFooterSeo';

interface Output {
    isShowSeoText: boolean;
    seoRulesByPage?: GetSeoRulesByPage;
}

const useFooter = (): Output => {
    const { pathname } = useRouterData();
    const { activeLocale } = useLocales();
    const { isShowDynamicPageFooterSeo } = useShowDynamicPageFooterSeo();
    const { seoRulesByPage, footerSportListByIds, footerProvidersListByIds } =
        useGsspData();

    const isProviderUrl = useMemo(() => {
        return footerProvidersListByIds
            ? Boolean(
                  footerProvidersListByIds.find((item) => {
                      const currentPathName = `/${activeLocale}${pathname}`;

                      return currentPathName === item.url;
                  })
              )
            : false;
    }, [activeLocale, footerProvidersListByIds, pathname]);

    const isSportOrESportUrl = useMemo(() => {
        return footerSportListByIds
            ? Boolean(
                  footerSportListByIds[SportListId.FooterBettingESports].find(
                      (item) =>
                          pathname.includes(item.url) ||
                          item.url.includes(pathname)
                  )
              ) ||
                  Boolean(
                      footerSportListByIds[
                          SportListId.FooterBettingSports
                      ].find(
                          (item) =>
                              pathname.includes(item.url) ||
                              item.url.includes(pathname)
                      )
                  )
            : false;
    }, [footerSportListByIds, pathname]);

    const isShowSeoText = useMemo(() => {
        return (
            !isProviderUrl && !isSportOrESportUrl && isShowDynamicPageFooterSeo
        );
    }, [isProviderUrl, isShowDynamicPageFooterSeo, isSportOrESportUrl]);

    return {
        seoRulesByPage,
        isShowSeoText,
    };
};

export default useFooter;
