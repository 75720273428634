import { useEffect, useRef, useState } from 'react';
import { isNumber } from 'lodash';

import { CompareStatus } from 'app-constants';
import usePreviousRef from './usePreviousRef';

interface Input {
    value: number | undefined | null;
    delay?: number;
}

interface Output {
    compareStatus: CompareStatus;
}

const useCompare = ({ value, delay = 2200 }: Input): Output => {
    const [compareStatus, setCompareStatus] = useState<CompareStatus>(
        CompareStatus.Equal
    );
    const delayRef = useRef<number>(delay);
    const prevValueRef = usePreviousRef(value);

    useEffect(() => {
        if (!prevValueRef.current || !isNumber(value)) return;

        const isGreater = value > prevValueRef.current;

        setCompareStatus(
            isGreater ? CompareStatus.Greater : CompareStatus.Smaller
        );
    }, [value, prevValueRef]);

    useEffect(() => {
        if (compareStatus === CompareStatus.Equal) return;

        const timerId = setTimeout(() => {
            setCompareStatus(CompareStatus.Equal);
        }, delayRef.current);

        return () => {
            clearTimeout(timerId);
        };
    }, [compareStatus]);

    return { compareStatus };
};

export default useCompare;
