import { useMemo } from 'react';
import cn from 'classnames';

import { getTransformedDataTestId } from 'utils';
import ModalBaseLayoutHeader from './ModalBaseLayoutHeader';
import { Props, PropsWithIcon, PropsWithImage, PropsWithTitle } from './types';

import styles from './modalBaseLayout.module.css';

const wrapperClasses = cn(
    'flex h-full w-full flex-col shadow-[0_0_24px_rgba(0,0,0,0.25)] md:h-auto md:max-h-full'
);

function ModalBaseLayout(props: PropsWithImage): JSX.Element;
function ModalBaseLayout(props: PropsWithTitle): JSX.Element;
function ModalBaseLayout(props: PropsWithIcon): JSX.Element;
function ModalBaseLayout(props: Props): JSX.Element {
    const {
        icon,
        title,
        image,
        buttons = [],
        children,
        hasCloseButton = true,
        onClose,
        hasForm = false,
        formAction,
        containerClassName,
        contentWrapperClassName,
        dataTest,
        origin,
        footerText,
        modalBodyClassName,
        childrenWrapperClassName,
    } = props;

    const modalAttr = useMemo(
        () =>
            getTransformedDataTestId({
                defaultAttribute: 'modal-base',
                propsAttribute: dataTest,
            }),
        [dataTest]
    );

    const buttonsContent = useMemo(() => {
        return buttons.map((button, index) => (
            <div key={index} className="flex-1">
                {button}
            </div>
        ));
    }, [buttons]);

    const wrapperContent = useMemo(() => {
        return (
            <>
                <ModalBaseLayoutHeader
                    icon={icon}
                    title={title}
                    image={image}
                    hasCloseButton={hasCloseButton}
                    onClose={onClose}
                />
                <div
                    className={cn(
                        'h-full min-h-[164px] overflow-hidden bg-surface-middle md:flex md:flex-col',
                        hasForm ? '' : 'px-6',
                        childrenWrapperClassName
                    )}
                    data-test="modal-base__content"
                >
                    <div
                        className={cn(
                            '-mr-3 h-full overflow-y-auto pr-3 text-md text-primary-white',
                            styles['module-base-layout-scroll'],
                            hasForm ? 'pt-6' : 'py-6'
                        )}
                        data-test="modal-base__content-wrapper"
                    >
                        {children}
                    </div>
                </div>
                {!!buttons.length && (
                    <div
                        className={cn(
                            'flex shrink-0 flex-col gap-2 border-t-2 border-t-surface-dark bg-surface-middle p-6',
                            image ? 'sm:flex-row' : 'md:flex-row'
                        )}
                        data-test="modal-base__footer"
                    >
                        {footerText ? (
                            <div className="flex flex-col gap-2">
                                <div className="flex gap-2">
                                    {buttonsContent}
                                </div>
                                <div className="text-grey-500 typo-xs-1">
                                    {footerText}
                                </div>
                            </div>
                        ) : (
                            buttonsContent
                        )}
                    </div>
                )}
            </>
        );
    }, [
        buttons.length,
        buttonsContent,
        children,
        footerText,
        hasCloseButton,
        hasForm,
        icon,
        image,
        onClose,
        title,
        childrenWrapperClassName,
    ]);

    return (
        <div
            className={cn(
                'fixed inset-y-0 left-0 z-90 w-full bg-surface-dark/90 md:overflow-y-auto',
                containerClassName
            )}
            data-test={modalAttr}
        >
            <div
                className={cn(
                    'absolute bottom-0 left-1/2 top-header-offset z-2 w-full',
                    '-translate-x-1/2 md:bottom-footer-offset landscape:overflow-y-auto md:landscape:overflow-y-visible',
                    {
                        'md:max-w-[420px]': !origin,
                        'max-w-[430px]': image,
                        'md:max-w-[760px]': origin === 'RG',
                    },
                    modalBodyClassName
                )}
                data-test="modal-base__wrapper"
            >
                {formAction ? (
                    <form
                        onSubmit={formAction}
                        className={cn(wrapperClasses, contentWrapperClassName)}
                    >
                        {wrapperContent}
                    </form>
                ) : (
                    <div
                        className={cn(wrapperClasses, contentWrapperClassName)}
                    >
                        {wrapperContent}
                    </div>
                )}
            </div>
        </div>
    );
}

export default ModalBaseLayout;
