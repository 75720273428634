import { FC, memo, useEffect, useRef } from 'react';
import { includes } from 'lodash';

import { AppLink, KYC_STATUS, ModalsHash } from 'app-constants';
import { useRouter, useRouterPush } from 'components/HybridRouter';
import { useKYC } from 'hooks';
import { GetProfile, RequiredDocumentStatusTypeEnum } from 'types/gql.cms';

const EXCLUDED_KYC_REDIRECT_ROUTES = [
    AppLink.CATCHALL_PAGES,
    AppLink.CASINOPROMOTIONS_SLUG,
    AppLink.BETTINGPROMOTIONS,
    AppLink.CASINOPROMOTIONS,
];

interface Props {
    profileInfo: GetProfile;
}

const KycRedirect: FC<Props> = ({ profileInfo }) => {
    const { push } = useRouterPush();
    const { pathname } = useRouter();
    const { isApprovedStatus, kycStatus, documentsStatus } = useKYC();
    const isRedirectTriggered = useRef<boolean>(false);

    const isUserEmailConfirmed =
        profileInfo.user?.__typename === 'Player' &&
        profileInfo.user.emailConfirmed;

    const isKycOrDocumentsExisted = !!kycStatus || !!documentsStatus;

    const isWaitingApprovalStatus =
        kycStatus === KYC_STATUS.AwaitingApproval ||
        documentsStatus ===
            RequiredDocumentStatusTypeEnum.StatusWaitingApproval;

    useEffect(() => {
        if (
            !isKycOrDocumentsExisted ||
            isRedirectTriggered.current ||
            ModalsHash.PasswordNew ||
            includes(EXCLUDED_KYC_REDIRECT_ROUTES, pathname)
        ) {
            return;
        }

        if (
            !isApprovedStatus &&
            !isWaitingApprovalStatus &&
            isUserEmailConfirmed
        ) {
            push({ hash: ModalsHash.KYC });
            isRedirectTriggered.current = true;
        }
    }, [
        isUserEmailConfirmed,
        isApprovedStatus,
        isWaitingApprovalStatus,
        pathname,
        push,
        isKycOrDocumentsExisted,
    ]);

    return null;
};

export default memo(KycRedirect);
