import { ApolloClient, InMemoryCache } from '@apollo/client';
import { GetServerSidePropsContext } from 'next';

import { isSsr } from 'utils/isSsr';
import { makeBettingLink } from './makeBettingLink';
import possibleTypes from './possibleTypes';
import typePolicies from './typePolicies';

export interface BettingApolloClientOptions {
    scoreboardEndpoint?: string;
    endpoint?: string;
    token?: string;
    isDebugMode?: boolean;
    req?: GetServerSidePropsContext['req'];
}

class BettingApolloClient extends ApolloClient<any> {
    isStub?: boolean;

    constructor(options: BettingApolloClientOptions) {
        super({
            ssrMode: isSsr(),
            cache: new InMemoryCache({
                typePolicies,
                possibleTypes: possibleTypes.possibleTypes,
            }),
            connectToDevTools: true,
            link: makeBettingLink(options),
            assumeImmutableResults: true,
        });
    }
}

export { BettingApolloClient };
