import { useCallback } from 'react';

import { useAuthorization } from 'hooks';
import { GamblerSetting } from './constants';
import { useDeleteGamblerSetting } from './deleteGamblerSetting.bet';
import {
    favoriteMarketsIdVar,
    updateClientDeeplinkMatchData,
} from './gamblerState';
import { DeleteGamblerSettingsKeys } from './types';

interface Output {
    deleteSettingAction: (key: DeleteGamblerSettingsKeys) => void;
}

const useDeleteSettingAction = (): Output => {
    const { isAuthorized } = useAuthorization();

    const [deleteGamblerSetting] = useDeleteGamblerSetting();

    const deleteSetting = useCallback(
        (key: DeleteGamblerSettingsKeys) => {
            if (isAuthorized) {
                deleteGamblerSetting({
                    variables: {
                        key,
                    },
                });
            }
        },
        [deleteGamblerSetting, isAuthorized]
    );

    const deleteSettingAction = useCallback(
        (key: DeleteGamblerSettingsKeys) => {
            switch (key) {
                case GamblerSetting.DEEPLINK_MATCH_DATA:
                    deleteSetting(GamblerSetting.DEEPLINK_MATCH_DATA);
                    updateClientDeeplinkMatchData(null);

                    return;
                case GamblerSetting.FAVORITE_MARKETS_ID:
                    deleteSetting(GamblerSetting.FAVORITE_MARKETS_ID);
                    favoriteMarketsIdVar([]);

                    return;
                default:
                    throw new Error('Gambler settings delete invalid key');
            }
        },
        [deleteSetting]
    );

    return {
        deleteSettingAction,
    };
};

export default useDeleteSettingAction;
