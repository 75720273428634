import { reduce } from 'lodash';

import tailwind from '../../../../tailwind.config';
import type { Breakpoints } from './types';

export const Sizes = Object.freeze(
    reduce(
        tailwind.theme.screens,
        (result, value, key) => {
            return {
                ...result,
                [key]: Number(value.replace('px', '')),
            };
        },
        {} as Record<Breakpoints, number>
    )
);
