import React, { HTMLAttributes } from 'react';
import cn from 'classnames';

import SmartLink from 'components/SmartLink';
import { Action } from 'services/GoogleAnalytics';
import SubLogo, { getSubLogoServerSideProps } from './SubLogo';
import useHeaderLogo from './useHeaderLogo';

import Logo from 'assets/svg/components/logo-gg.svg';

interface Props extends HTMLAttributes<HTMLDivElement> {
    isShowSubLogo?: boolean;
}

const HeaderLogo: React.FC<Props> = ({ isShowSubLogo = true, className }) => {
    const { dataCategory } = useHeaderLogo();

    const dataAction = Action.Click;

    return (
        <div
            className={cn('flex items-center', className)}
            data-category={dataCategory}
            data-action={dataAction}
            data-test="header-logo"
        >
            <SmartLink href="/" dataTest="header-logo__link">
                <Logo />
            </SmartLink>

            {isShowSubLogo && <SubLogo />}
        </div>
    );
};

export const getHeaderLogoServerSideProps = getSubLogoServerSideProps;

export default React.memo(HeaderLogo);
