import { useCallback, useState } from 'react';

type Input = boolean;

type Output = [state: boolean, toggle: () => void, toFalse: () => void];

const useToggle = (initialState: Input = false): Output => {
    const [state, setState] = useState<boolean>(initialState);

    const toggle = useCallback(() => setState((prevState) => !prevState), []);

    const toFalse = useCallback(() => {
        setState(false);
    }, []);

    return [state, toggle, toFalse];
};

export default useToggle;
