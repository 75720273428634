import { useCallback } from 'react';

import { ModalsHash } from 'app-constants';
import useModal from 'components/modals/useModal';
import { Message } from 'types/gql.cms';
import { setIsProgressAnimatingVar } from '../../inboxCenterState';

interface Input {
    handleMarkRead: (message: Message) => void;
    message: Message;
}

interface Output {
    openMessageHandler: VoidFunction;
    closeMessageHandler: VoidFunction;
}

const useInboxCenterPreviewMessage = ({
    handleMarkRead,
    message,
}: Input): Output => {
    const { openModal } = useModal();

    const openMessageHandler = useCallback(() => {
        handleMarkRead(message);
        setIsProgressAnimatingVar(false);
        openModal({ hash: ModalsHash.Notifications });
    }, [openModal, message, handleMarkRead]);

    const closeMessageHandler = useCallback(() => {
        handleMarkRead(message);
        setIsProgressAnimatingVar(false);
    }, [handleMarkRead, message]);

    return {
        openMessageHandler,
        closeMessageHandler,
    };
};

export default useInboxCenterPreviewMessage;
