import { ApolloLink } from '@apollo/client';

import makeFetchWithPromMetrics from 'services/prometheus/makeFetchWithPromMetrics';
import { isSsr } from '../../../utils/isSsr';
import makeOTELTraceLink from '../../otel/makeOTELTraceLink';
import {
    authLink,
    captchaLink,
    expiredTokenLink,
    makeCacheLink,
    makeHeadersLink,
    makeLoggerLink,
    reloadPageAfterRedirect,
    responseErrorLink,
    responsibleGamingErrorLink,
} from '../links';
import ssrOperationAdapterLink from '../links/ssrOperationAdapterLink';
import {
    getHeadersFromRequest,
    makeTerminalLink,
    wsNormalizationLink,
} from '../utils';
import { CmsClientOptions } from './CmsApolloClient';

const makeCmsLink = ({
    endpoint,
    req,
    headersCookieObj,
    locale,
    isDebugMode,
}: CmsClientOptions): ApolloLink => {
    const logger = makeLoggerLink(
        process.env.NODE_ENV === 'development' || isDebugMode,
        req
    );

    const defaultLinkOptions = {
        uri: endpoint,
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'X-Gc-Locale': locale,
            ...(isSsr() &&
                getHeadersFromRequest(req, {
                    injectHost: true,
                    injectReferer: true,
                })),
            ...headersCookieObj,
        },
        fetch: makeFetchWithPromMetrics('cms_apollo_client'),
        fetchOptions: {
            mode: 'cors',
            redirect: 'manual',
        },
        addTypename: true,
        errorPolicy: 'all',
    };

    const linksList = [
        logger,
        wsNormalizationLink,
        makeHeadersLink(defaultLinkOptions.headers, req),
        responseErrorLink,
        authLink,
        expiredTokenLink,
        responsibleGamingErrorLink,
        captchaLink,
    ];

    if (!isSsr()) {
        linksList.push(reloadPageAfterRedirect);
    } else {
        linksList.push(
            makeOTELTraceLink(),
            makeCacheLink(locale),
            ssrOperationAdapterLink
        );
    }

    linksList.push(makeTerminalLink(defaultLinkOptions));

    return ApolloLink.from(linksList);
};

export default makeCmsLink;
