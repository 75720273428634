import React from 'react';
import { CSSTransition } from 'react-transition-group';
import cn from 'classnames';

import { AlertType } from './constants';
import useAlert from './useAlert';

interface Props {
    id: string;
    message: string;
    type: AlertType;
}

const Alert: React.FC<Props> = ({ id, message, type }) => {
    const { isAlertVisible, background, unmountHandler } = useAlert({
        id,
        type,
    });

    const classes: string = cn(
        'mb-2 w-[290px] rounded-default p-3 last:mb-0',
        background
    );

    return (
        <CSSTransition
            in={isAlertVisible}
            classNames={{
                enter: 'animate-open-alert',
                exit: 'animate-close-alert',
            }}
            unmountOnExit={true}
            onExited={unmountHandler}
            timeout={1000}
        >
            <div className={classes}>
                <span className="typo-md-1">{message}</span>
            </div>
        </CSSTransition>
    );
};

export default Alert;
