import { LayoutType, MessagesByLayoutType } from 'app-constants';
import { WithMainApolloClientContext } from 'services/apollo';
import { GetServerSidePageProps, GetServerSidePagePropsData } from 'types';
import {
    getMessages,
    getServerSideCountriesTranslation,
    getSettledResult,
} from 'utils';
import { getRouterQuery } from 'utils/getRouterQuery';

export const getBettingTranslationsServerSideProps = async (
    context: WithMainApolloClientContext
): Promise<GetServerSidePageProps> => {
    const { sportId } = getRouterQuery(context.query);

    if (!sportId) {
        return {
            props: {
                messages: await getMessages(context, [
                    'betting-countries',
                    ...MessagesByLayoutType[LayoutType.Betting],
                ]),
            },
        };
    }

    const [messages, countriesTranslation] = await getSettledResult<
        [{ [p: string]: {} }, { props: GetServerSidePagePropsData }]
    >({
        promises: [
            getMessages(context, [
                'betting-countries',
                ...MessagesByLayoutType[LayoutType.Betting],
            ]),
            getServerSideCountriesTranslation(context),
        ],
        label: 'Betting Layout',
        errorMessage: 'getBettingTranslationsServerSideProps request failed',
        request: context.req,
    });

    if (countriesTranslation) {
        const {
            props: { messages: countriesMessages, gsspData },
        } = countriesTranslation;

        return {
            props: {
                gsspData,
                messages: {
                    ...countriesMessages,
                    ...messages,
                },
            },
        };
    }

    return {
        props: {
            messages,
        },
    };
};
