import { FC, memo } from 'react';
import { useTranslations } from 'next-intl';

import { LayoutType, MainLayoutType, WidgetNames } from 'app-constants';
import Clock from 'components/Clock';
import Widget, { makeGetServerSidePropsWidgetByName } from 'components/Widget';
import { getServerPageGetGeoIpLocation } from 'gql/cms/queries/getGeoIpLocation.cms';
import { useLayoutContext } from 'layouts/Root/LayoutTypeContext';
import { usePagePropsContext } from 'layouts/Root/PagePropsContext';
import { GetServerSidePropsWithMainApolloClient } from 'services/apollo';

import styles from './footerLicence.module.css';

const { CASINO_WIDGET_LICENCE_TEXT, DEFAULT_WIDGET_LICNCE_TEXT } = WidgetNames;

const FooterLicence: FC = () => {
    const { mainLayoutType } = useLayoutContext();
    const { gsspData } = usePagePropsContext();
    const { widgets } = gsspData || {};
    const t = useTranslations('Footer');

    const widgetName =
        mainLayoutType === MainLayoutType.Casino
            ? CASINO_WIDGET_LICENCE_TEXT
            : DEFAULT_WIDGET_LICNCE_TEXT;

    const year = new Date().getFullYear();

    return (
        <div className="pb-[calc(theme(spacing.footer-offset)+theme(spacing.14))] pt-8 sm:pb-14">
            <Widget
                className={styles['footer-licence-text']}
                name={widgetName}
                replace="%year%"
                replaceValue={year.toString()}
                widgetData={widgets?.[widgetName]?.widgetByName?.template}
            />

            <div className="flex flex-col justify-between gap-4 md:flex-row md:items-center">
                <div className="flex md:max-w-[556px] md:items-center">
                    <div className="mr-2 h-10 w-10 shrink-0 bg-[url('/spa-assets/image/licenses/logo-plus-21.svg')] bg-cover md:mr-4" />

                    <div>
                        <p className="mb-2 text-xs font-normal text-grey-500">
                            {t('gincms.footer.licence_restriction')}
                        </p>

                        <p className="text-xs font-normal text-grey-500">
                            {t('gincms.footer.licence_warning')}
                        </p>
                    </div>
                </div>

                <Clock />
            </div>
        </div>
    );
};

export const getFooterLicenceSideProps = (
    layout: LayoutType
): GetServerSidePropsWithMainApolloClient[] => [
    async (ctx) => {
        try {
            const countryByUserIpResponse =
                await getServerPageGetGeoIpLocation()(ctx);

            return {
                props: {
                    gsspData: { countryByUserIp: countryByUserIpResponse },
                },
            };
        } catch (e) {
            console.error(e);
        }

        return {
            props: {},
        };
    },
    makeGetServerSidePropsWidgetByName(
        layout === LayoutType.Casino
            ? CASINO_WIDGET_LICENCE_TEXT
            : DEFAULT_WIDGET_LICNCE_TEXT
    ),
];

export default memo(FooterLicence);
