import { useCallback, useMemo } from 'react';
import differenceInDays from 'date-fns/differenceInDays';
import differenceInHours from 'date-fns/differenceInHours';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import { useTranslations } from 'next-intl';

interface Input {
    createdAt: string;
}

interface Output {
    timeLeft: string;
}

const useMessageTimeLeft = ({ createdAt }: Input): Output => {
    const t = useTranslations('InboxCenter');

    const daysText = useCallback(
        (days: number) => {
            const restOfDays = days % 10;

            if (
                (restOfDays > 10 && restOfDays <= 20) ||
                restOfDays % 10 >= 5 ||
                restOfDays % 10 === 0
            ) {
                // дней
                return t('gincms.messages.popups.message.days_');
            }

            if (restOfDays % 10 === 1) {
                // день
                return t('gincms.messages.popups.message.day');
            }

            // дня
            return t('gincms.messages.popups.message.days');
        },
        [t]
    );

    const hoursText = useCallback(
        (hours: number) => {
            if (hours >= 5 && hours <= 20) {
                // часов
                return t('gincms.messages.popups.message.hours_');
            }

            if (hours % 10 === 1) {
                // час
                return t('gincms.messages.popups.message.hour');
            }

            // часа
            return t('gincms.messages.popups.message.hours');
        },
        [t]
    );

    const minutesText = useCallback(
        (minutes: number) => {
            if (minutes === 1) {
                return t('gincms.messages.popups.message.one_minute');
            }

            if (
                (minutes > 10 && minutes <= 20) ||
                minutes % 10 >= 5 ||
                minutes % 10 === 0
            ) {
                // минут
                return t('gincms.messages.popups.message.minute');
            }

            if (minutes % 10 === 1) {
                // минуту
                return t('gincms.messages.popups.message.minutes');
            }

            // минуты
            return t('gincms.messages.popups.message.minutes_');
        },
        [t]
    );

    const timeLeft = useMemo(() => {
        const dateNow = new Date();
        const transformedCreatedAt = new Date(createdAt);
        const days = differenceInDays(dateNow, transformedCreatedAt);
        const hours = differenceInHours(dateNow, transformedCreatedAt);
        const minutes = differenceInMinutes(dateNow, transformedCreatedAt);

        if (days >= 31) {
            return t('gincms.messages.popups.message.more_days');
        }

        if (days >= 1) {
            return `${days} ${daysText(days)}`;
        }

        if (hours >= 1) {
            return `${hours} ${hoursText(hours)}`;
        }

        return `${minutes} ${minutesText(minutes)}`;
    }, [createdAt, daysText, hoursText, minutesText, t]);

    return {
        timeLeft,
    };
};

export default useMessageTimeLeft;
