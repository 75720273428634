import React, { FC } from 'react';
import type { FooterSportListByIds, GetServerSidePageProps } from 'types';

import { BatchKeys, LayoutType, MainLayoutType } from 'app-constants';
import { useAppEnv } from 'components/AppEnv/hooks/use-app-env';
import ErrorBoundary from 'components/ErrorBoundary';
import { getServerPageGetLocaleMenu } from 'gql/cms/queries/getLocaleMenu.cms';
import { useLayoutContext } from 'layouts/Root/LayoutTypeContext';
import {
    GetServerSidePropsWithMainApolloClient,
    WithMainApolloClientContext,
} from 'services/apollo';
import { verifyEnvironment } from 'utils/verify-environment';
import { footerSportListIds } from './FooterSportList/FooterSportList';
import FooterBettingInfo from './FooterBettingInfo';
import FooterCasinoInfo from './FooterCasinoInfo';
import FooterGamesList, {
    getFooterGamesListSideProps,
} from './FooterGamesList';
import { getFooterInfoServerSideProps } from './FooterInfoLinks';
import FooterLicence, { getFooterLicenceSideProps } from './FooterLicence';
import FooterPayments from './FooterPayments';
import FooterProviders from './FooterProviders';
import FooterSeo from './FooterSeo';
import FooterSportList from './FooterSportList';
import FooterTop from './FooterTop';
import { SportListId } from './types';
import useFooter from './useFooter';

const Footer: FC = () => {
    const { APP_ENV } = useAppEnv();
    const { mainLayoutType } = useLayoutContext();
    const { isShowSeoText, seoRulesByPage } = useFooter();

    return (
        <ErrorBoundary>
            <footer id="footer" className="w-full bg-surface-dark font-roboto">
                <div className="mx-auto w-90 max-w-1296">
                    <FooterTop />

                    {isShowSeoText && (
                        <FooterSeo seoRulesByPage={seoRulesByPage} />
                    )}

                    {mainLayoutType === MainLayoutType.Casino ? (
                        <>
                            <FooterGamesList />
                            <FooterCasinoInfo />
                        </>
                    ) : (
                        <>
                            <FooterSportList />
                            <FooterBettingInfo />
                        </>
                    )}

                    <FooterPayments />

                    {mainLayoutType === MainLayoutType.Casino && (
                        <FooterProviders />
                    )}

                    {APP_ENV?.isProduction && <FooterLicence />}
                </div>
            </footer>
        </ErrorBoundary>
    );
};

const getServerPageSportFooterList: GetServerSidePropsWithMainApolloClient =
    async (
        ctx: WithMainApolloClientContext
    ): Promise<GetServerSidePageProps> => {
        const GSSPFns = footerSportListIds.map((id) =>
            getServerPageGetLocaleMenu({
                variables: {
                    id,
                    locale: `${ctx.locale}`,
                },
                context: {
                    batchKey: BatchKeys.GetLocaleMenu,
                },
            })(ctx)
        );

        const response = await Promise.all(GSSPFns);

        const footerSportListByIds = response.reduce(
            (acc: FooterSportListByIds, current) => {
                const { localeMenu } = current;
                const id = localeMenu?.id as SportListId;

                const items = localeMenu?.items || [];

                if (id) {
                    acc[id] = items;
                }

                return acc;
            },
            {
                [SportListId.FooterBettingESports]: [],
                [SportListId.FooterBettingSports]: [],
            }
        );

        return {
            props: {
                gsspData: { footerSportListByIds },
            },
        };
    };

const { isProduction } = verifyEnvironment();

export const getFooterCasinoServerSideProps = [
    ...getFooterInfoServerSideProps,
    ...getFooterGamesListSideProps,
    ...(isProduction ? [...getFooterLicenceSideProps(LayoutType.Casino)] : []),
];

export const getFooterBettingServerSideProps = [
    ...getFooterInfoServerSideProps,
    ...(isProduction ? [...getFooterLicenceSideProps(LayoutType.Betting)] : []),
    getServerPageSportFooterList,
];

export default React.memo(Footer);
