import {
    ApolloClient,
    DataProxy,
    InMemoryCache,
    OperationVariables,
} from '@apollo/client';
import type { OperationDefinitionNode } from 'graphql';

class StubApolloClient extends ApolloClient<{}> {
    clientName: string;

    isStub: boolean = true;

    // TODO: Check other methods that can cause problems while working with stub client
    // eslint-disable-next-line
    readQuery<T = any, TVariables = OperationVariables>(
        options: DataProxy.Query<TVariables, T>
    ): T | null {
        const queryName = (
            options.query.definitions[0] as OperationDefinitionNode
        ).name?.value;

        throw Error(
            `Error! Trying to read query "${queryName}" from stub ${this.clientName} client`,
            options as any
        );
    }

    constructor(clientName: string) {
        super({
            cache: new InMemoryCache({}),
        });

        this.clientName = clientName;
    }
}

export default StubApolloClient;
