import * as GQL from 'types/gql.bet';

import * as Operations from './onUpdateOverwatchOverviews.bet.gql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'services/apollo/betting';
const defaultOptions = {} as const;

/**
 * __useOnUpdateOverwatchOverviews__
 *
 * To run a query within a React component, call `useOnUpdateOverwatchOverviews` and pass it any options that fit your needs.
 * When your component renders, `useOnUpdateOverwatchOverviews` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnUpdateOverwatchOverviews({
 *   variables: {
 *      sportEventIDs: // value for 'sportEventIDs'
 *   },
 * });
 */
export function useOnUpdateOverwatchOverviews(
    baseOptions: ApolloReactHooks.SubscriptionHookOptions<
        GQL.OnUpdateOverwatchOverviews,
        GQL.OnUpdateOverwatchOverviewsVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSubscription<
        GQL.OnUpdateOverwatchOverviews,
        GQL.OnUpdateOverwatchOverviewsVariables
    >(Operations.onUpdateOverwatchOverviews, options);
}
export type OnUpdateOverwatchOverviewsHookResult = ReturnType<
    typeof useOnUpdateOverwatchOverviews
>;
export type OnUpdateOverwatchOverviewsSubscriptionResult =
    Apollo.SubscriptionResult<GQL.OnUpdateOverwatchOverviews>;
