import { useCallback } from 'react';
import formatFns from 'date-fns/format';
import formatTz from 'date-fns-tz/format';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';

import { useClientContext } from 'layouts/Root/ClientRenderContext';
import getDateFnsLocale from '../utils/intl/getDateFnsLocale';
import useLocales from './useLocales';

interface Output {
    format: (date: Date, format: string) => string;
}

const useFormat = (): Output => {
    const isClientRouter = useClientContext();
    const { activeLocale } = useLocales();

    const format = useCallback(
        (initialDate: Date, formatString: string) => {
            try {
                const utcDate = utcToZonedTime(initialDate, 'UTC');

                return isClientRouter
                    ? formatFns(initialDate, formatString)
                    : formatTz(utcDate, formatString, {
                          locale: getDateFnsLocale(activeLocale),
                      });
            } catch (e) {
                console.error('Error with formatting date', e);

                return initialDate.toLocaleString();
            }
        },
        [activeLocale, isClientRouter]
    );

    return {
        format,
    };
};

export default useFormat;
