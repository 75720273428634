import React, {
    createContext,
    PropsWithChildren,
    useContext,
    useMemo,
} from 'react';

import { SportType } from 'app-constants';
import { useGetBettingSettings } from 'gql/betting/queries/getBettingSettings.cms';
import { Sports } from 'types';
import { GetBettingSettings } from 'types/gql.cms';
import { isEsportId } from 'utils';
import SyncPollingProvider from './SyncPollingProvider';

interface IBettingLayoutContext {
    sports: Sports;
    sportIds: string[];
}

export const BettingLayoutContext = createContext<IBettingLayoutContext>({
    sports: {}, // NOTE Init from pageProps can`t be undefined or null
    sportIds: [],
});

export const useBettingLayout = (): IBettingLayoutContext =>
    useContext(BettingLayoutContext);

export const BettingLayoutProvider: React.FC<
    PropsWithChildren<{ sportIds: string[] }>
> = ({ children, sportIds }) => {
    const { data } = useGetBettingSettings(() => ({
        variables: { withSportsIcon: true },
    }));

    const contextValue = useMemo(() => {
        const sportsList = !data
            ? getSportsIconStubs(sportIds)
            : getSportIcons(data);

        return {
            sportIds,
            sports: sportsList,
        };
    }, [data, sportIds]);

    return (
        <SyncPollingProvider>
            <BettingLayoutContext.Provider value={contextValue}>
                {children}
            </BettingLayoutContext.Provider>
        </SyncPollingProvider>
    );
};

const getSportTypeBySportId = (sportId: string): SportType => {
    return isEsportId(sportId) ? SportType.Esports : SportType.Sports;
};

const getSportsIconStubs = (sportIds: string[]) =>
    sportIds.reduce<Sports>((acc, id) => {
        acc[id] = {
            sportType: getSportTypeBySportId(id),
        };

        return acc;
    }, {});

const getSportIcons = (data: GetBettingSettings) =>
    data.bettingSettings.sports.reduce<Sports>((acc, sport) => {
        acc[sport.id] = {
            sportType: getSportTypeBySportId(sport.id),
            icon: sport.iconId,
        };

        return acc;
    }, {});
