import { createContext, FC, PropsWithChildren, useContext } from 'react';
import { map } from 'lodash';

import useMount from 'hooks/useMount';
import { CmsClientOptions } from 'services/apollo/cms/CmsApolloClient';
import FeatureFlagsManager from './FeatureFlagsManager';
import type { FeatureFlags } from './types';
import usetInitFeatureFlags from './usetInitFeatureFlags';

export const FeatureFlagsContext = createContext<FeatureFlagsManager>(
    FeatureFlagsManager.init()
);

interface Props extends PropsWithChildren {
    parsedFeatures: FeatureFlags;
    notSupportedFeatures: string[];
    cmsClientOptions: Omit<CmsClientOptions, 'req' | 'endpoint'>;
    endpoint: string;
}

const FeatureFlagsProvider: FC<Props> = ({
    parsedFeatures,
    notSupportedFeatures,
    children,
    cmsClientOptions,
    endpoint,
}) => {
    const { features } = usetInitFeatureFlags({
        parsedFeatures,
        cmsClientOptions,
        endpoint,
    });

    useMount(() => {
        if (!notSupportedFeatures?.length) return;

        const formattedFeatureNames = map(
            features.getAll(),
            (_, name) => `\n- "${name}"`
        );

        const formattedNotSupportedFeatureNames = map(
            notSupportedFeatures,
            (name) => `\n- "${name}"`
        );

        console.warn(
            `[FeatureManager]: Unknown features passed ${formattedNotSupportedFeatureNames}\n\nSupported features: ${formattedFeatureNames}`
        );
    });

    return (
        <FeatureFlagsContext.Provider value={features}>
            {children}
        </FeatureFlagsContext.Provider>
    );
};

interface Output {
    features: FeatureFlagsManager;
}

export const useFeatureFlags = (): Output => {
    const FFManager = useContext(FeatureFlagsContext);

    return {
        features: FFManager,
    };
};

export default FeatureFlagsProvider;
