import { fillLink } from 'cl-fill-link';
import { compact, has, includes, noop, omit, pickBy, reduce } from 'lodash';
import type { ParsedUrlQuery } from 'querystring';
import { stringify } from 'querystring';

import { StorageKey } from 'app-constants';
import type { CmsApolloClient } from 'services/apollo';
import { isSsr } from 'utils/isSsr';
import { SearchParamsKeys } from './constants';
import { participantFeaturesState } from './participantFeaturesState';
import type { FieldsObj } from './types';

const TEST_TRACKER: UniversalAnalytics.Tracker = {
    get: noop,
    set: noop,
    // eslint-disable-next-line no-console
    send: console.log,
};

type SentProps = {
    fieldsObject: FieldsObj;
    label: string | undefined | null;
    cmsApolloClient?: CmsApolloClient;
};

export function getIsTurnOnDebugAnalytic(): boolean {
    let isEnabled = false;

    if (isSsr()) return isEnabled;

    try {
        isEnabled = localStorage.getItem(StorageKey.ANALYTIC) === 'true';
    } catch (err) {
        console.error(err);
    }

    return isEnabled;
}

export function getTracker(): UniversalAnalytics.Tracker | undefined {
    const isAnalyticTurnedOn = getIsTurnOnDebugAnalytic();

    if (isAnalyticTurnedOn) return TEST_TRACKER;

    const getAll = (window.ga as UniversalAnalytics.ga | undefined)?.getAll;

    if (typeof getAll !== 'function') return;

    return getAll()[0];
}

export function send({ fieldsObject, label }: SentProps): void {
    const tracker = getTracker();

    if (!tracker) return;

    const labels = compact([label, participantFeaturesState()]);

    if (labels.length) {
        // eslint-disable-next-line no-param-reassign
        fieldsObject.eventLabel = labels.join('; ');
    }

    tracker.send('%c[GoogleAnalytics]:', 'color: green', fieldsObject);
}

type FormattedPathnameInput = {
    pathname: string;
    query: ParsedUrlQuery;
};

type Search = Record<string, string | string[]>;

export function formattedPathname({
    pathname,
    query,
}: FormattedPathnameInput): string {
    const filledLink = fillLink(
        pathname,
        has(query, 'slug')
            ? {
                  ...omit(query, 'slug'),
                  slug: 'slug',
              }
            : query
    );

    const searchTransform = pickBy(
        reduce(
            query,
            (result: Search, value, key) => {
                return {
                    ...result,
                    [key]: includes(SearchParamsKeys, key)
                        ? (value as string | string[])
                        : '',
                };
            },
            {}
        ),
        Boolean
    );

    const params = stringify(searchTransform);
    const searchParams = params ? `?${params}` : '';

    return `${filledLink}${searchParams}`;
}

type ParticipantFeaturesType = Array<{
    featureVariant: string;
    scenarioIdentifier: string;
    shouldAnalysis: boolean;
}>;

export function formatFeaturesData(
    participantFeatures: ParticipantFeaturesType
): string {
    const formattedFeaturesData = participantFeatures.reduce<
        { name: string; variant: string }[]
    >((acc, { scenarioIdentifier, featureVariant, shouldAnalysis }) => {
        if (!shouldAnalysis) return acc;

        return acc.concat({
            name: scenarioIdentifier,
            variant: featureVariant,
        });
    }, []);

    const formattedFeaturesString = formattedFeaturesData.length
        ? `FF: ${JSON.stringify(formattedFeaturesData)}`
        : '';

    return formattedFeaturesString;
}
