import React from 'react';
import cn from 'classnames';

import { MainLayoutType, WidgetNames } from 'app-constants';
import Widget from 'components/Widget';
import { withClientRender } from 'hocs';
import { useLayoutContext } from 'layouts/Root/LayoutTypeContext';

import styles from './footerPayments.module.css';

const FooterPayments: React.FC = () => {
    const { mainLayoutType } = useLayoutContext();
    const list = cn('border-b-default border-solid border-surface-middle');
    const widgetPartners =
        mainLayoutType === MainLayoutType.Betting
            ? WidgetNames.FOOTER_PAYMENTS
            : WidgetNames.FOOTER_CASINO_PAYMENTS;

    return (
        <Widget
            name={widgetPartners}
            className={cn(
                list,
                mainLayoutType === MainLayoutType.Betting
                    ? styles.betting
                    : styles.casino
            )}
        />
    );
};

export default withClientRender(FooterPayments);
