import { ComponentType, useEffect, useState } from 'react';
import { captureException } from '@sentry/nextjs';
import { difference } from 'lodash';

import { useLocales } from 'hooks';
import { useMessages } from 'layouts/IntlMessages/useMessages';
import { useClientContext } from 'layouts/Root/ClientRenderContext';
import { getMessages } from 'utils';

const withClientMessages = <T extends {}>(
    Component: ComponentType<T>,
    messagesNamespaces: IntlMessagesNamespace[],
    fallback?: (props: T) => React.ReactElement | null
): ((props: T) => JSX.Element | null) => {
    const ComponentWithMessages = (props: T) => {
        const [isLoadedInitial, setIsLoadedInitial] = useState<boolean>(false);
        const [isLoaded, setIsLoaded] = useState<boolean>(isLoadedInitial);
        const { setMessages, messageKeys } = useMessages();
        const { activeLocale, locales } = useLocales();
        const isClientRouter = useClientContext();

        useEffect(() => {
            let isMounted = true;

            if (isLoadedInitial || !isClientRouter) {
                return;
            }

            const filteredMessages = difference(
                messagesNamespaces,
                messageKeys
            );

            if (!filteredMessages.length) {
                if (isMounted) {
                    setIsLoaded(true);
                    setIsLoadedInitial(true);
                }

                return;
            }

            getMessages({ locale: activeLocale, locales }, filteredMessages)
                .then((data) => {
                    setMessages(data);
                    setIsLoadedInitial(true);
                })
                .finally(() => {
                    setIsLoaded(true);
                })
                .catch((err) => {
                    captureException(err);
                    console.error('Messages not found');
                });

            return () => {
                isMounted = false;
            };
        }, [
            activeLocale,
            messageKeys,
            locales,
            setMessages,
            isClientRouter,
            isLoadedInitial,
        ]);

        if (!isLoaded)
            return typeof fallback === 'function' ? fallback(props) : null;

        return <Component {...props} />;
    };

    return ComponentWithMessages;
};

export default withClientMessages;
