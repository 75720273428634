import type { WatchQueryFetchPolicy } from '@apollo/client';

import { BatchKeys } from 'app-constants';
import { Feature, useFeatureFlags } from 'services/features';
import { BannerType, GetBanners } from 'types/gql.bet';
import { useGetBanners } from './getBanners.bet';
import { useGetCmsBanners } from './getCmsBanners.cms';
import { Banner, BettingBanner, LinkedSportBanner } from './types';

interface Input {
    zoneId: string | undefined;
    marketLimit?: number;
    pollInterval?: number;
    onCompleted?: (data: GetBanners) => void;
    fetchPolicy?: WatchQueryFetchPolicy;
}

interface Output {
    banners: Banner[];
    linkedSportBanners: LinkedSportBanner[];
    loading: boolean;
}

const useBanners = ({
    zoneId,
    marketLimit,
    pollInterval,
    onCompleted,
    fetchPolicy,
}: Input): Output => {
    const { features } = useFeatureFlags();
    const isActiveBannerTypeGeo = features.isEnabled(
        Feature.AppEnableBannerTypeGeo
    );
    const { data, loading: loadingBanners } = useGetBanners(() => ({
        variables: { zoneId, marketLimit, isActiveBannerTypeGeo },
        fetchPolicy,
        skip: !zoneId,
        pollInterval,
        onCompleted,
    }));
    const { bettingBanners, linkedSportBanners } = (
        data?.banners || []
    ).reduce<{
        bettingBanners: BettingBanner[];
        linkedSportBanners: LinkedSportBanner[];
    }>(
        (acc, banner) => {
            if (
                [
                    ...(isActiveBannerTypeGeo
                        ? [
                              BannerType.BettingTournamentGeo,
                              BannerType.BettingSportEventGeo,
                          ]
                        : []),
                    BannerType.CmsBanner,
                    BannerType.BettingSportEvent,
                    BannerType.BettingTournament,
                ].includes(banner.type)
            ) {
                acc.bettingBanners.push(banner as BettingBanner);
            }

            if (banner.type === BannerType.BettingLinkedSport) {
                acc.linkedSportBanners.push(banner as LinkedSportBanner);
            }

            return acc;
        },
        { bettingBanners: [], linkedSportBanners: [] }
    );

    const cmsBannerIds = bettingBanners.reduce<string[]>((acc, banner) => {
        if (banner.__typename === 'BannerCMS') {
            acc.push(banner.id);
        }

        return acc;
    }, []);

    const { data: cmsBannersData, loading: loadingCmsBanners } =
        useGetCmsBanners(() => ({
            variables: {
                ids: cmsBannerIds,
            },
            skip: cmsBannerIds.length === 0,
            context: {
                batchKey: BatchKeys.GetCmsBanners,
            },
        }));

    const cmsBanners = cmsBannersData?.banners || [];

    const banners = bettingBanners.reduce<Banner[]>((acc, banner) => {
        if (banner.__typename === 'BannerCMS') {
            const cmsBanner = cmsBanners.find((b) => b.id === banner.id);

            if (cmsBanner) {
                acc.push(cmsBanner);
            }
        } else {
            acc.push(banner);
        }

        return acc;
    }, []);

    return {
        banners,
        linkedSportBanners,
        loading: loadingBanners || loadingCmsBanners,
    };
};

export default useBanners;
