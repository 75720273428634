import { DefaultContext } from '@apollo/client';

import { REQUEST_ID_HEADER } from '../services/constants';

export const getRequestIdFromContext = (context: DefaultContext): string => {
    const headersResponse = context?.response?.headers || null;
    const headersRequest = context?.headers || null;

    return `${headersRequest && headersRequest[REQUEST_ID_HEADER]},${
        headersResponse && headersResponse?.get(REQUEST_ID_HEADER)
    }`;
};
