import * as GQL from 'types/gql.bet';

import * as Operations from './onUpdateKingOfGloryOverviews.bet.gql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'services/apollo/betting';
const defaultOptions = {} as const;

/**
 * __useOnUpdateKingOfGloryOverviews__
 *
 * To run a query within a React component, call `useOnUpdateKingOfGloryOverviews` and pass it any options that fit your needs.
 * When your component renders, `useOnUpdateKingOfGloryOverviews` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnUpdateKingOfGloryOverviews({
 *   variables: {
 *      sportEventIDs: // value for 'sportEventIDs'
 *   },
 * });
 */
export function useOnUpdateKingOfGloryOverviews(
    baseOptions: ApolloReactHooks.SubscriptionHookOptions<
        GQL.OnUpdateKingOfGloryOverviews,
        GQL.OnUpdateKingOfGloryOverviewsVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSubscription<
        GQL.OnUpdateKingOfGloryOverviews,
        GQL.OnUpdateKingOfGloryOverviewsVariables
    >(Operations.onUpdateKingOfGloryOverviews, options);
}
export type OnUpdateKingOfGloryOverviewsHookResult = ReturnType<
    typeof useOnUpdateKingOfGloryOverviews
>;
export type OnUpdateKingOfGloryOverviewsSubscriptionResult =
    Apollo.SubscriptionResult<GQL.OnUpdateKingOfGloryOverviews>;
