import { useCallback, useEffect, useMemo } from 'react';

import { useGetPlayerBalances } from 'gql/cms/queries/getPlayerBalances.cms';
import getPlayerBalancesQuery from 'gql/cms/queries/getPlayerBalances.cms.gql';
import { useCmsApolloClient } from 'services/apollo';
import { GetPlayerBalances } from 'types/gql.cms';

const MAX_EXPIRATION_TIME = 12 * 60 * 60 * 1000; // 12 hours

const useExpiredBonusBalances = (): void => {
    const { data } = useGetPlayerBalances(() => ({
        fetchPolicy: 'cache-only',
    }));

    const {
        client: { cache },
    } = useCmsApolloClient();

    const bonusBalancesWithExpired = useMemo(
        () =>
            data?.playerBalances?.bonusBalances?.reduce<
                { id: number; expiredAt: string | number }[]
            >((acc, { expiredAt, id }) => {
                if (
                    typeof expiredAt !== 'string' &&
                    typeof expiredAt !== 'number'
                ) {
                    return acc;
                }

                acc.push({ expiredAt, id });

                return acc;
            }, []),
        [data?.playerBalances?.bonusBalances]
    );

    const handleClearBonusBalance = useCallback(
        (balanceId: number) => {
            const cacheBalances = cache.readQuery<GetPlayerBalances>({
                query: getPlayerBalancesQuery,
            });

            if (!cacheBalances?.playerBalances) return;

            const filteredBalances =
                cacheBalances.playerBalances.bonusBalances.filter(
                    (balance) => balanceId !== balance.id
                );

            cache.writeQuery<GetPlayerBalances>({
                query: getPlayerBalancesQuery,
                data: {
                    ...cacheBalances,
                    playerBalances: {
                        ...cacheBalances.playerBalances,
                        bonusBalances: filteredBalances,
                    },
                },
            });
        },
        [cache]
    );

    useEffect(() => {
        if (!bonusBalancesWithExpired?.length) return;

        const cleanupTimerIds: NodeJS.Timeout[] = [];

        bonusBalancesWithExpired.forEach(({ expiredAt, id }) => {
            const expirationTime =
                new Date(expiredAt).getTime() - new Date().getTime();

            if (expirationTime > MAX_EXPIRATION_TIME) return;

            const timerId = setTimeout(
                () => handleClearBonusBalance(id),
                expirationTime
            );

            cleanupTimerIds.push(timerId);
        });

        return () => {
            cleanupTimerIds.forEach((id) => clearTimeout(id));
        };
    }, [bonusBalancesWithExpired, handleClearBonusBalance]);
};

export default useExpiredBonusBalances;
