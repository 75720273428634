import { GetServerSideProps } from 'next';

import { getMessages } from './getMessages';

export const getServerSideMessages =
    (namespaces: IntlMessagesNamespace[]): GetServerSideProps =>
    async (ctx) => {
        return {
            props: {
                messages: await getMessages(ctx, namespaces),
            },
        };
    };
