import { useGetAvailableBonusProgram } from 'gql/cms/queries/bonus-program/getAvailableBonusProgram.cms';
import { GetAvailableBonusProgram } from 'types/gql.cms';
import useAuthorization from './useAuthorization';

interface Input {
    category: 'betting' | 'casino';
    displayIn?: string;
}

export interface Output {
    loading: boolean;
    availableBonusPrograms: GetAvailableBonusProgram['bonusProgramsFullAvailable'];
    refetch: ReturnType<typeof useGetAvailableBonusProgram>['refetch'];
}

const useAvailableBonusProgram = ({
    category,
    displayIn = 'profile',
}: Input): Output => {
    const { isRegistrationFinished } = useAuthorization();

    const {
        data: availableBonusPrograms,
        refetch,
        loading,
    } = useGetAvailableBonusProgram(() => ({
        variables: {
            filter: {
                category,
                displayInType: displayIn,
            },
        },

        skip: !isRegistrationFinished,
    }));

    return {
        loading: loading || (!loading && !availableBonusPrograms),
        availableBonusPrograms:
            availableBonusPrograms?.bonusProgramsFullAvailable || [],
        refetch,
    };
};

export default useAvailableBonusProgram;
