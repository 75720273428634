import { FC, memo } from 'react';
import { useReactiveVar } from '@apollo/client';
import cn from 'classnames';

import { Message } from 'types/gql.cms';
import { isProgressAnimatingVar } from '../inboxCenterState';
import useInboxCenterProgress from './useInboxCenterProgress';

interface Props {
    message: Message;
    handleMarkRead: (message: Message) => void;
}

const InboxCenterProgress: FC<Props> = ({ message, handleMarkRead }) => {
    const isProgressAnimating = useReactiveVar(isProgressAnimatingVar);
    const { progressRef } = useInboxCenterProgress({
        message,
        handleMarkRead,
    });

    return (
        <div className="h-[2px] bg-green-500/10">
            <div
                ref={progressRef}
                className={cn('h-full translate-x-[-100%] bg-green-500', {
                    'animate-fill-x': isProgressAnimating,
                })}
            />
        </div>
    );
};

export default memo(InboxCenterProgress);
