import { FC, memo } from 'react';
import { useTranslations } from 'next-intl';

import Button from 'components/Button';
import ListItemMobile from 'components/ListItemMobile';
import PackIcon, { IconsPack } from 'components/PackIcon';
import { useAuthorization } from 'hooks';
import useLogout from './useLogout';

interface Props {
    type?: 'burger';
}

interface MobileLogoutProps {
    logout: VoidFunction;
}

const Logout: FC<Props> = ({ type }) => {
    const { isAuthorized } = useAuthorization();
    const { logoutCallback } = useLogout();

    if (!isAuthorized) return null;

    if (type === 'burger') {
        return <MobileLogout logout={logoutCallback} />;
    }

    return (
        <Button
            color={Button.Color.SurfaceDark}
            size={Button.Size.Small}
            onClick={logoutCallback}
            iconId="logout"
            pack={IconsPack.ActionIcons}
            iconClassName="fill-primary-white"
            className="mr-2"
            dataTest="logout"
        />
    );
};

const MobileLogout: FC<MobileLogoutProps> = ({ logout }) => {
    const t = useTranslations('MobileMenu');

    return (
        <ListItemMobile
            leftLabel={t('gincms.mobile_menu.logout')}
            labelColor="text-red-500"
            rightIcons={
                <PackIcon
                    id="logout"
                    pack={IconsPack.ActionIcons}
                    className="fill-red-500"
                />
            }
            clickHandler={logout}
            className="mb-4 w-full"
            dataTest="mobile-logout"
        />
    );
};

export default memo(Logout);
