import { keys } from 'lodash';

import { Sizes } from './constants';
import type { GetQueryStringInput, MediaQueries } from './types';

export function getQueryString({
    maxWidth,
    minWidth,
}: GetQueryStringInput): string {
    if (!maxWidth && minWidth) {
        return `(min-width: ${minWidth}px)`;
    }

    if (maxWidth && minWidth) {
        return `(min-width: ${minWidth}px) and (max-width: ${maxWidth}px)`;
    }

    return `(max-width: ${maxWidth}px)`;
}

function typedKeys<T>(object: T): Array<keyof T> {
    return keys(object) as Array<keyof T>;
}

export function createMediaQueries(breakpoints: typeof Sizes): MediaQueries {
    const sortedBreakpoints = typedKeys(breakpoints).sort(
        (a, b) => breakpoints[a] - breakpoints[b]
    );

    return sortedBreakpoints.map((breakpoint, index) => {
        const minWidth = !index ? null : breakpoints[breakpoint];
        const nextBreakpoint = sortedBreakpoints[index + 1];
        const maxWidth = nextBreakpoint
            ? breakpoints[nextBreakpoint] - 1
            : null;

        return {
            breakpoint,
            query: getQueryString({ maxWidth, minWidth }),
        };
    });
}
