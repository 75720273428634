import { FC, memo, PropsWithChildren, ReactNode, useMemo } from 'react';
import { useLoaderData } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import { NextIntlProvider } from 'next-intl';

import { DEFAULT_LOCALE } from 'app-constants';
import { useIsEnableSSR } from 'layouts/Root/EnableSSRProvider/EnableSSRContext';
import { DataLoaderPayload } from 'types';
import { useClientContext } from '../Root/ClientRenderContext';
import clientLoadedMessagesVar, {
    setClientMessages as setMessages,
} from './clientLoadedMessagesVar';
import MessagesContext from './useMessages';

interface Props extends PropsWithChildren {
    locale?: string;
    initialMessages: Dict;
}

const MessagesProvider: FC<Props> = ({
    children,
    locale = DEFAULT_LOCALE,
    initialMessages,
}) => {
    const clientLoadedMessages = useReactiveVar(clientLoadedMessagesVar);
    const isClientRouter = useClientContext();
    const isSSREnabled = useIsEnableSSR();

    const value = useMemo(
        () => ({
            setMessages,
            messageKeys: Object.keys(
                clientLoadedMessages
            ) as IntlMessagesNamespace[],
        }),
        [clientLoadedMessages]
    );

    if (isSSREnabled && !isClientRouter) {
        return (
            <NextIntlProvider locale={locale} messages={initialMessages}>
                {children}
            </NextIntlProvider>
        );
    }

    return (
        <UseLoaderData>
            {({ messages }) => (
                <MessagesContext.Provider value={value}>
                    <NextIntlProvider
                        locale={locale}
                        messages={{
                            ...initialMessages,
                            ...messages,
                            ...clientLoadedMessages,
                        }}
                    >
                        {children}
                    </NextIntlProvider>
                </MessagesContext.Provider>
            )}
        </UseLoaderData>
    );
};

interface UseLoaderDataProps {
    children: ({ messages }: { messages: Dict | undefined }) => ReactNode;
}

const UseLoaderData: FC<UseLoaderDataProps> = ({ children }) => {
    const { messages } = (useLoaderData() as DataLoaderPayload) || {};

    return <>{children({ messages })}</>;
};

export default memo(MessagesProvider);
