import { createContext, useContext } from 'react';

import { LayoutType, MainLayoutType } from 'app-constants';

interface LayoutTypeContextType {
    mainLayoutType: MainLayoutType;
    layoutType: LayoutType;
}

const LayoutTypeContext = createContext<LayoutTypeContextType>({
    layoutType: LayoutType.BettingStatic,
    mainLayoutType: MainLayoutType.Betting,
});

const useLayoutContext = (): LayoutTypeContextType =>
    useContext(LayoutTypeContext);

export { LayoutTypeContext, useLayoutContext };
