import { FC, memo, ReactNode, useCallback, useMemo, useState } from 'react';
import type { PageProps } from 'types';

import { BettingLayoutProvider } from 'layouts/BettingLayout/BettingLayoutContext';
import { usePagePropsContext } from 'layouts/Root/PagePropsContext';
import StubApolloClient from 'services/apollo/utils/stubs/StubApolloClient';
import { isSsr } from 'utils/isSsr';
import {
    BettingApolloClient,
    BettingApolloClientOptions,
} from './BettingApolloClient';
import { BettingApolloClientContext } from './BettingApolloProvider';
import useGetBettingOptions from './useGetBettingOptions';

let clientBrowserRef: BettingApolloClient | undefined;

const BettingApolloProvider: FC<{
    pageProps: PageProps;
    children: ReactNode;
}> = ({ pageProps, children }) => {
    const [shouldInitClient, initClient] = useState<boolean>(false);

    const { bettingOptions, bettingInitializationError } = useGetBettingOptions(
        { shouldInitClient }
    );

    const { bettingClientOptions, sportIds } = bettingOptions;

    const createClient = useCallback(() => {
        return isSsr()
            ? new BettingApolloClient(bettingClientOptions)
            : crateClientBrowserRef(bettingClientOptions);
    }, [bettingClientOptions]);

    const client = useMemo(
        () =>
            (bettingClientOptions
                ? createClient()
                : new StubApolloClient('Betting')) as BettingApolloClient,
        [bettingClientOptions, createClient]
    );

    const initBettingClient = useCallback(() => {
        initClient(true);
    }, []);

    const value = useMemo(
        () => ({
            initBettingClient,
            client,
            bettingInitError:
                pageProps.bettingInitError || bettingInitializationError,
        }),
        [
            initBettingClient,
            client,
            pageProps.bettingInitError,
            bettingInitializationError,
        ]
    );

    return (
        <BettingApolloClientContext.Provider value={value}>
            <BettingLayoutProvider sportIds={sportIds || []}>
                {children}
            </BettingLayoutProvider>
        </BettingApolloClientContext.Provider>
    );
};

const BettingApolloProviderSsr: FC<{
    children: ReactNode;
}> = ({ children }) => {
    const { sportIds } = usePagePropsContext();

    return (
        <BettingApolloClientContext.Provider
            value={
                // eslint-disable-next-line react/jsx-no-constructed-context-values
                {
                    initBettingClient: () => {},
                    client: new StubApolloClient('Betting'),
                    bettingInitError: undefined,
                }
            }
        >
            <BettingLayoutProvider sportIds={sportIds || []}>
                {children}
            </BettingLayoutProvider>
        </BettingApolloClientContext.Provider>
    );
};

const LazyBettingApolloProvider = isSsr()
    ? BettingApolloProviderSsr
    : BettingApolloProvider;

const crateClientBrowserRef = (options: BettingApolloClientOptions) => {
    if (!clientBrowserRef) {
        clientBrowserRef = new BettingApolloClient(options);

        return clientBrowserRef;
    }

    return clientBrowserRef;
};

export default memo(LazyBettingApolloProvider);
