import { useGetAnalyticsSettings } from './getAnalyticsSettings.cms';

interface Output {
    reportServiceUrl?: string;
}

const useAnalyticsSettings = (): Output => {
    const { data } = useGetAnalyticsSettings();

    return {
        reportServiceUrl: data?.analyticsSettings.reportServiceUrl,
    };
};

export default useAnalyticsSettings;
