export enum LimitType {
    Deposit = 'deposit',
    GamingTime = 'gaming-time',
}

export enum LimitPeriod {
    Day = 'day',
    Week = 'week',
    Month = 'month',
}

export interface FormInput {
    type: LimitType;
    title: string;
    defaultValue: number;
    period: LimitPeriod;
}
