import { useEffect } from 'react';
import { deleteCookie } from 'cookies-next';

import { ACTIVE_SPORTEVENT_STATUSES, CookiesType } from 'app-constants';
import {
    GamblerSetting,
    useDeleteSettingAction,
} from 'components/betting/GamblerSettings';
import { useOnUpdateSportEvent } from 'gql/betting/subscriptions/onUpdateSportEvent.bet';
import { SportEventStatus } from 'types/gql.bet';
import useGetDeeplinkMatchData from './useGetDeeplinkMatchData';

export const HOURS_24 = 24 * 60 * 60 * 1000;

const useRemoveDeepLinkMatchData = (): void => {
    const { deleteSettingAction } = useDeleteSettingAction();

    const { deeplinkMatchData } = useGetDeeplinkMatchData();

    const { matchId = '', recordDate = 0 } = deeplinkMatchData || {};

    const currentTime = new Date().getTime();
    const isExpired24Hours = currentTime - recordDate >= HOURS_24;

    useOnUpdateSportEvent({
        variables: {
            sportEventId: matchId,
            skipMarkets: true,
        },
        onData: ({ data }) => {
            const status = data.data?.onUpdateSportEvent.fixture
                .status as SportEventStatus;

            if (ACTIVE_SPORTEVENT_STATUSES.includes(status)) return;

            deleteSettingAction(GamblerSetting.DEEPLINK_MATCH_DATA);
            deleteCookie(CookiesType.DeeplinkMatchData);
        },
        skip: !matchId || isExpired24Hours,
    });

    useEffect(() => {
        if (!isExpired24Hours || !matchId) return;

        deleteSettingAction(GamblerSetting.DEEPLINK_MATCH_DATA);
    }, [isExpired24Hours, deleteSettingAction, matchId]);
};

export default useRemoveDeepLinkMatchData;
