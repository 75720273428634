import { SportType } from 'app-constants';
import { useRouter } from 'components/HybridRouter';
import getSportTypeByPath from 'utils/getSportTypeByPath';

type Output = SportType | undefined;

const useGetSportTypeByPath = (): Output => {
    const { pathname } = useRouter();

    return getSportTypeByPath(pathname);
};

export default useGetSportTypeByPath;
