import { fetchWithTimeout } from 'services/apollo/utils';
import { MetricsName } from './constants';

type FetchUrl = RequestInfo | URL;
type FetchOptions = RequestInit | undefined;

type Fetch = (input: FetchUrl, options?: FetchOptions) => Promise<Response>;
type ApolloClientName = 'cms_apollo_client' | 'betting_apollo_client';

function makeFetchWithPromMetrics(apollo_client: ApolloClientName): Fetch {
    if (typeof window === 'undefined') {
        // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
        const { register } = require('prom-client');
        const counter = register.getSingleMetric(MetricsName.RequestCount);
        const histogram = register.getSingleMetric(MetricsName.RequestDuration);

        return async function fetchWithMetrics(
            input: FetchUrl,
            options?: FetchOptions
        ): Promise<Response> {
            const start = performance.now();
            const response = await fetchWithTimeout(input, options);

            const dataByLabelNames = { status: response.status, apollo_client };

            counter.inc(dataByLabelNames);

            const end = performance.now();
            const duration = end - start;

            histogram.observe(dataByLabelNames, duration);

            return response;
        };
    }

    return fetchWithTimeout;
}

export default makeFetchWithPromMetrics;
