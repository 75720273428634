import { getCookie } from 'cookies-next';

import { StorageKey } from 'app-constants';
import { isSsr } from 'utils/isSsr';

export const isDebugEnabled = (): boolean => {
    let isEnabled = false;

    if (isSsr()) return isEnabled;

    try {
        isEnabled = !!getCookie(StorageKey.DEBUG_INFO);
    } catch (err) {
        console.error(err);
    }

    return isEnabled;
};
