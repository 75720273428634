import { deleteCookie, setCookie } from 'cookies-next';
import addMinutes from 'date-fns/addMinutes';

import { CookiesType, LayoutType } from 'app-constants';
import { EuroCampaignId } from 'components/betting/Euro2024/constants';
import { Message } from 'types/gql.cms';
import { NotificationSystemType } from './constants';

const INBOX_CASINO_EXPIRED_TIME = 5; // minutes

type PreviewLayoutType = 'betting' | 'other';

export const setPreviewCookiesAfterSignIn = (type: PreviewLayoutType): void => {
    const expiredTime =
        type !== 'betting'
            ? addMinutes(new Date(), INBOX_CASINO_EXPIRED_TIME)
            : undefined;

    setCookie(CookiesType.InBoxPreviewLogin, 1, { expires: expiredTime });
};

export const removePreviewCookies = (): void => {
    deleteCookie(CookiesType.InBoxPreviewLogin);
};

export const isEuroMessage = (message?: Message | null): boolean =>
    message?.campaignId?.toLowerCase() === EuroCampaignId;

export const isPopupMessage = (
    message: Message | null,
    type: LayoutType
): boolean => {
    return (
        message?.systemType?.toLowerCase() === NotificationSystemType.Popup &&
        type !== LayoutType.Game
    );
};
