import { ResponsibleGamingLimitTypes } from 'app-constants';
import { LimitPeriod, LimitType } from './ResponsibleGaming/types';

export const LIMIT_TYPES = {
    LoginBlocked: 'login-blocked',
    TermsAndConditions: 'terms-and-conditions',
    DeKycDeposit: 'de-kyc-deposit',
    PlayerRequiredLimits: ResponsibleGamingLimitTypes.PlayerRequiredLimits,
    Deposit: ResponsibleGamingLimitTypes.Deposit,
    GamingTime: ResponsibleGamingLimitTypes.GamingTime,
};
export const ALLOWED_TYPES: (typeof LIMIT_TYPES)[keyof typeof LIMIT_TYPES][] = [
    LIMIT_TYPES.TermsAndConditions,
    LIMIT_TYPES.LoginBlocked,
    LIMIT_TYPES.DeKycDeposit,
    LIMIT_TYPES.GamingTime,
    LIMIT_TYPES.Deposit,
    LIMIT_TYPES.PlayerRequiredLimits,
];

export const RG_GA_VALUES_MAP = {
    [LimitType.Deposit]: {
        [LimitPeriod.Day]: 'Daily deposit limit',
        [LimitPeriod.Week]: 'Weekly deposit limit',
        [LimitPeriod.Month]: 'Monthly deposit limit',
    },
    [LimitType.GamingTime]: {
        [LimitPeriod.Day]: 'Daily gaming time limit',
        [LimitPeriod.Week]: 'Weekly gaming time limit',
        [LimitPeriod.Month]: '',
    },
};
