import { FC, PropsWithChildren } from 'react';
import { createPortal } from 'react-dom';
import { map } from 'lodash';

import { AlertHandlersContext, AlertManagerContext } from './context';
import useAlertManager from './useAlertManager';

const AlertManager: FC<PropsWithChildren> = ({ children }) => {
    const { alertHandlers, alertElements, ref, elements } = useAlertManager();

    return (
        <AlertHandlersContext.Provider value={alertHandlers}>
            <AlertManagerContext.Provider value={alertElements}>
                {children}
                {ref.current &&
                    createPortal(
                        elements && map(elements, (item) => item),
                        ref.current
                    )}
            </AlertManagerContext.Provider>
        </AlertHandlersContext.Provider>
    );
};

export default AlertManager;
