import { useContext } from 'react';

import HybridRouterContext from './HybridRouterContext';
import { HybridRouter } from './types';

export const useHybridRouter = (): HybridRouter => {
    return useContext(HybridRouterContext);
};

export default useHybridRouter;
