import { useCallback } from 'react';
import { fillLink } from 'cl-fill-link';

import { AppLink, REGEXP_FOR_HASH } from 'app-constants';
import { useRouter } from 'components/HybridRouter';
import { useClientContext } from 'layouts/Root/ClientRenderContext';

interface Output {
    checkActiveLink: (link: string) => boolean;
}

const useActiveLink = (): Output => {
    const { pathname, query, asPath } = useRouter();

    const isClientRouter = useClientContext();

    const checkActiveLink = useCallback(
        (link: string): boolean => {
            const parseLink = fillLink(pathname, query);
            const hash = asPath.match(REGEXP_FOR_HASH)?.[0];
            const isOutrightPage =
                link === AppLink.OUTRIGHTS &&
                (pathname === AppLink.ESPORTS_OUTRIGHT_SLUG ||
                    pathname === AppLink.SPORTS_OUTRIGHT_SLUG);

            if (isOutrightPage) return isOutrightPage;

            if (isClientRouter && hash) return hash === link;

            return parseLink === link;
        },
        [pathname, query, asPath, isClientRouter]
    );

    return {
        checkActiveLink,
    };
};

export default useActiveLink;
