import { FC, memo, useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import { deleteCookie, setCookie } from 'cookies-next';

import { CookiesType } from 'app-constants';
import {
    GamblerSetting,
    useChangeSettingAction,
} from 'components/betting/GamblerSettings';
import { deeplinkMatchDataVar } from 'components/betting/GamblerSettings/gamblerState';
import { withClientRender, withFeatureFlag } from 'hocs';
import { useAuthorization, useRouterQuery } from 'hooks';
import { Feature } from 'services/features';
import { getParsedDeeplinkMatchData } from './useGetDeeplinkMatchData';
import useRemoveDeepLinkMatchData, {
    HOURS_24,
} from './useRemoveDeepLinkMatchData';

const DeepLinkMatchData: FC = () => {
    useRemoveDeepLinkMatchData();

    const deeplinkMatchData = useReactiveVar(deeplinkMatchDataVar);

    const { deeplink } = useRouterQuery();
    const { isAuthorized } = useAuthorization();

    const { changeSettingAction } = useChangeSettingAction();

    const sportEventId = deeplink?.split('/')[3];

    useEffect(() => {
        if (
            !sportEventId ||
            (deeplinkMatchData && sportEventId === deeplinkMatchData.matchId)
        )
            return;

        setDeeplinkMatchDataToCookies(sportEventId);
    }, [sportEventId, deeplinkMatchData]);

    useEffect(() => {
        if (!isAuthorized) return;

        const parsedDeeplinkMatchData = getParsedDeeplinkMatchData();

        if (!parsedDeeplinkMatchData) return;

        const { matchId, recordDate } = parsedDeeplinkMatchData;

        changeSettingAction(GamblerSetting.DEEPLINK_MATCH_DATA, {
            matchId,
            recordDate,
        });

        deleteCookie(CookiesType.DeeplinkMatchData);
    }, [isAuthorized, changeSettingAction]);

    return null;
};

const setDeeplinkMatchDataToCookies = (matchId: string) => {
    const recordDate = new Date().getTime();
    setCookie(
        CookiesType.DeeplinkMatchData,
        { matchId, recordDate },
        { maxAge: HOURS_24 / 1000 }
    );
};

export default memo(
    withFeatureFlag(
        withClientRender(DeepLinkMatchData),
        Feature.AppDeeplinkForMatchData
    )
);
