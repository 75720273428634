import React from 'react';

import { useGetCurrentMirror } from 'gql/cms/queries/getCurrentMirror.cms';
import withServerRender from 'hocs/withServerRender';
import { useGsspData } from 'layouts/Root/PagePropsContext';
import { GetServerSidePropsWithMainApolloClient } from 'services/apollo';
import getCurrentMirror from './gssp/getCurrentMirror';
import getSeoHeaders from './gssp/getSeoHeaders';
import getSeoRules from './gssp/getSeoRules';
import HeadSeoTagsContent from './HeadSeoTagsContent';
import useSeoTags from './useSeoTags';

const HeadSeoTags: React.FC = () => {
    const { seo, info } = useSeoTags();
    const { data } = useGetCurrentMirror(() => ({
        pagePropsKey: 'currentMirror',
    }));

    return (
        <HeadSeoTagsContent
            seo={seo}
            info={info}
            currentMirrorInfo={data?.currentMirror}
        />
    );
};

export const getSeoRulesBySideProps: GetServerSidePropsWithMainApolloClient[] =
    [getSeoRules, getSeoHeaders, getCurrentMirror];

export default withServerRender(HeadSeoTags, () => {
    const { seoRulesByPage, currentMirror } = useGsspData();

    return (
        <HeadSeoTagsContent
            seo={seoRulesByPage?.seoRulesByPage.seo}
            info={seoRulesByPage?.seoRulesByPage.info}
            currentMirrorInfo={currentMirror?.currentMirror}
        />
    );
});
