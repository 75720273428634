import { useContext, useEffect, useState } from 'react';

import { AlertHandlersContext } from '../AlertManager/context';
import { AlertType } from './constants';

interface Input {
    id: string;
    type: AlertType;
}

interface Output {
    background: string | null;
    isAlertVisible: boolean;
    unmountHandler: VoidFunction;
}

const DURATION = 1000 * 3;
const backgroundByType = {
    [AlertType.Error]: 'bg-red-700',
    [AlertType.Warning]: 'bg-yellow-700',
    [AlertType.Success]: 'bg-green-700',
};

const useAlert = ({ id, type }: Input): Output => {
    const { removeAlertHandler } = useContext(AlertHandlersContext);

    const [isAlertVisible, setIsAlertVisible] = useState<boolean>(false);
    const [timerId, setTimerId] = useState<number>(0);

    const unmountHandler = () => {
        removeAlertHandler(id);
    };

    useEffect(() => {
        setIsAlertVisible(true);
    }, []);

    useEffect(
        () => () => {
            clearTimeout(timerId);
        },
        [timerId]
    );

    useEffect(() => {
        if (!isAlertVisible || !typeof window) return;

        setTimerId(
            window.setTimeout(() => {
                setIsAlertVisible(false);
            }, DURATION)
        );
    }, [isAlertVisible]);

    return {
        isAlertVisible,
        background: backgroundByType[type],
        unmountHandler,
    };
};

export default useAlert;
