import { useEffect, useMemo } from 'react';
import { useReactiveVar } from '@apollo/client';

import { refetchProcessingState } from 'components/Bonuses/bonusProgramSubscriptionState';
import {
    useAuthorization,
    useAvailableBonusProgram,
    useProcessingBonusProgram,
} from 'hooks';
import { BonusBubbleCategory } from './types';

interface Input {
    category: BonusBubbleCategory;
    skip?: boolean;
}

interface Output {
    isAuthorized: boolean;
    count: number;
}

const useBonusBubble = ({ category }: Input): Output => {
    const { isAuthorized } = useAuthorization();
    const isRefetchProcessingState = useReactiveVar(refetchProcessingState);
    const { availableBonusPrograms, refetch: refetchAvailable } =
        useAvailableBonusProgram({ category });
    const { inactiveProcessingBonusPrograms, refetch: refetchProcessing } =
        useProcessingBonusProgram({ category });

    const count = useMemo(() => {
        return (
            [...availableBonusPrograms, ...inactiveProcessingBonusPrograms]
                ?.length || 0
        );
    }, [availableBonusPrograms, inactiveProcessingBonusPrograms]);

    useEffect(() => {
        if (isRefetchProcessingState) {
            refetchAvailable();
            refetchProcessing();
        }
    }, [isRefetchProcessingState, refetchAvailable, refetchProcessing]);

    return { isAuthorized, count };
};

export default useBonusBubble;
