export const TRUTHY_VALUES = ['true', 'enabled'] as const;
export const FALSY_VALUES = ['false', 'disabled'] as const;

export enum Feature {
    AppEnableSentry = 'app.enable-sentry',
    AppEnableMicromarking = 'app.enable-micromarking',
    AppEnableBannerTypeGeo = 'app.enable-banner-type-geo',
    AppBonusChest = 'app.bonus-chest',
    AppTournamentBracket = 'app.tournament-bracket',
    AppTournamentNews = 'app.tournament-news',
    AppNewsPageBettingBlock = 'app.news-page-betting-block',
    AppDeeplinkForMatchData = 'app.deep-link-match-data',
    AppDisableTwichPlayer = 'app.disable-twich-player',
    BonusProgram = 'app.bonus-program',
    AppRestoreBetslipFromUrl = 'app.restore-betslip-from-url',
    FiltersSearchMatches = 'app.search-matches',
    AppNetworkPromotions = 'app.network-promotions',
    AppResponsibleGaming = 'app.responsible-gaming',
    AppResponsibleGaming2 = 'app.responsible-gaming-2',
    AppDepositDisableByKyc = 'app.deposit-disable-by-kyc',
    AppWithdrawVisaNotify = 'app.withdraw-visa-notify',
    AppDisableDiia = 'app.disable-diia',
    AppDisableBankId = 'app.disable-bank-id',
    AppDisableTwilio = 'app.disable-twilio',
    AppDisablePhoneRegistration = 'app.disable-phone-registration',
    AppTransactionAmount = 'app.transaction-amount',
    Euro2024 = 'app.euro2024',
    CasinoSsrEnabled = 'app.casino-ssr',
    SeoSportsSsr = 'ssr.seo-sports',
    FiltersSortEventOrder = 'filters.sort-event-order',
    BetslipBetsHistoryBetting = 'betslip.bets-history-betting',
    BetslipInsurance = 'betslip.insurance',
    BetslipSkipFreebetsValidation = 'betslip.skip-freebet-validation',
    BetsHistoryRecommendation = 'bets-history.recommendation',
    MatchListSubSport = 'match-list.sub-sport',
    MatchListMultiCompetitorsOverview = 'match-list.multi-competitors-overview',
    MatchAccentBackground = 'match.accent-background',
    /* Feature flags for new home page */
    HomePopularMatches = 'home.popular-matches',
    HomePopularCasinoGames = 'home.popular-casino-games',
    HomeMainBanner = 'home.main-banner',
    HomeSportTournaments = 'home.sport-tournaments',
    HomeMainSlot = 'home.main-slot',
    HomeCategoriesLinks = 'home.categories-links',
    HomeMainApplication = 'home.main-application',
}

export const featureValues = Object.values(Feature);
