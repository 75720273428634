import isToday from 'date-fns/isToday';
import isTomorrow from 'date-fns/isTomorrow';

import { useClientContext } from 'layouts/Root/ClientRenderContext';
import { GlobalTranslate } from '../translations/types';
import useFormat from './useFormat';

interface Input {
    startTime: Date;
    translate: GlobalTranslate;
    isTime?: boolean;
}

const useDateText = ({ startTime, isTime, translate }: Input): string => {
    const { format } = useFormat();
    const isClient = useClientContext();
    const time = isTime ? format(startTime, 'HH:mm') : '';

    // TODO: add localization
    let date = format(startTime, 'MMM dd');

    if (isClient && isToday(startTime)) {
        date = translate('todayText');
    }

    if (isClient && isTomorrow(startTime)) {
        date = translate('tomorrowText');
    }

    return isTime ? `${date} ${time}` : date;
};

export default useDateText;
