import { FC, useCallback } from 'react';

import EuroPopup from 'components/betting/Euro2024/EuroPopup/EuroPopup';
import { useGetMessage } from 'gql/cms/queries/messages/getMessage.cms';
import { Message } from 'types/gql.cms';
import { NotificationSystemType } from '../constants';
import { setPreviewMessageVar } from '../inboxCenterState';

interface Props {
    message: Message;
}

const InboxCenterEuroNotify: FC<Props> = ({ message }) => {
    useGetMessage(() => ({ variables: { id: message.id } }));

    const closeMessageHandler = useCallback(() => {
        setPreviewMessageVar(null);
    }, []);

    const [button] = message?.media?.buttons || [];

    return (
        <EuroPopup
            type={message.systemType as NotificationSystemType}
            buttonText={button?.text}
            title={message.title}
            buttonUrl={button?.url}
            content={message.content}
            image={message.media?.mainImage.default}
            onClose={closeMessageHandler}
        />
    );
};

export default InboxCenterEuroNotify;
