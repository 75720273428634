import { FC } from 'react';

import { AppLink } from 'app-constants';
import SmartLink from 'components/SmartLink';

import Logo from 'assets/svg/components/logo-gg.svg';

const FooterTop: FC = () => {
    return (
        <div className="flex w-full flex-col border-b-default border-solid border-surface-middle py-8 sm:flex-row sm:justify-between">
            <SmartLink href={AppLink.ROOT} className="cursor-pointer">
                <Logo className="scale-95" />
            </SmartLink>
        </div>
    );
};

export default FooterTop;
