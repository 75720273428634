import { forEach, get, reduce } from 'lodash';
import { TraverseContext } from 'traverse';

import joinIdPath from './joinIdPath';

interface IConfig<T> {
    typename: T;
    keys: string[];
}

const makeGenPath =
    <T>(configs: IConfig<T>[]) =>
    (item: TraverseContext): string =>
        joinIdPath(
            reduce<IConfig<T>, string[]>(
                configs,
                (acc, config) => acc.concat(...recursivePath(item, config, [])),
                []
            )
        );

const recursivePath = <K>(
    element: TraverseContext,
    config: IConfig<K>,
    ids: string[] = []
): string[] => {
    if (
        element.node.__typename === config.typename ||
        element.parent === undefined
    ) {
        forEach(config.keys, (key) => {
            const id = get(element.node, key);

            if (!id) {
                console.error(
                    `Element ${element.node.__typename} does not contain the ${key} property`
                );

                return;
            }

            ids.push(id);
        });

        return ids;
    }

    return recursivePath(element.parent, config, ids);
};

export default makeGenPath;
