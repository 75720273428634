import { includes } from 'lodash';

const authMessages = [
    'success',
    'confirm_email.success',
    'auth.credentials.wrong',
    'auth.social.registration.banned',
    'auth.social.autorization.banned',
    'auth.player.locked',
] as const;

const recoveryErrorMessages = [
    'email',
    'phone_mask',
    'internal_error',
    'auth.credentials.unknown',
    'auth.credentials.blacklisted',
    'auth.credentials.recovery.unknown',
    'auth.credentials.recovery.send',
    'auth.credentials.recovery.limit',
    'auth.credentials.recovery.limit_with_parameters',
] as const;

const promoCodeErrorMessage = [
    'redeem_code.required',
    'redeem_code.success',
    'redeem_code.email_not_confirmed',
    'redeem_code.incorrect',
    'redeem_code.code_already_used',
    'redeem_code.not_paid_bet',
    'redeem_code.activation_period_expired',
    'redeem_code.ended_number_activations',
    'redeem_code.group_already_used',
    'redeem_code.not_exist_deposit',
    'redeem_code.forbidden_by_condition',
] as const;

const bettingGlobalRefundTitle = ['rollback', 'cashout'] as const;

const bettingComponentsNoFindWithId = [
    'NoMatchWithId',
    'NoPlayerWithId',
    'NoTeamWithId',
    'NoTournamentWithId',
] as const;

const makeIsMessage =
    <T extends ReadonlyArray<string>>(array: T) =>
    (message: string): message is (typeof array)[number] =>
        includes(array, message);

export const isAuthMessage = makeIsMessage(authMessages);
export const isRecoveryErrorMessage = makeIsMessage(recoveryErrorMessages);
export const isBettingGlobalRefundTitle = makeIsMessage(
    bettingGlobalRefundTitle
);
export const isBettingComponentsNoFindWithId = makeIsMessage(
    bettingComponentsNoFindWithId
);
export const isPromoCodeErrorMessage = makeIsMessage(promoCodeErrorMessage);
