import * as GQL from 'types/gql.cms';

import * as Operations from './logout.cms.gql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'services/apollo/cms';
const defaultOptions = {} as const;

export type LogoutMutationFn = Apollo.MutationFunction<
    GQL.Logout,
    GQL.LogoutVariables
>;

/**
 * __useLogout__
 *
 * To run a mutation, you first call `useLogout` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogout` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logout, { data, loading, error }] = useLogout({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useLogout(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQL.Logout,
        GQL.LogoutVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<GQL.Logout, GQL.LogoutVariables>(
        Operations.Logout,
        options
    );
}
export type LogoutHookResult = ReturnType<typeof useLogout>;
export type LogoutMutationResult = Apollo.MutationResult<GQL.Logout>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<
    GQL.Logout,
    GQL.LogoutVariables
>;
