import { FC, memo, useEffect, useState } from 'react';
import { captureException } from '@sentry/nextjs';
import { registerLocale } from 'i18n-iso-countries';

import { DEFAULT_LOCALE } from 'app-constants';
import { useMessages } from 'layouts/IntlMessages/useMessages';
import { getMessages } from 'utils';
import { mapLocalesByI18nCodes } from 'utils/intl/getServerSideCountriesTranslation';

interface Props {
    shouldLoadTranslations: boolean;
    locale?: string;
}

const RegisterCountryTranslations: FC<Props> = ({
    shouldLoadTranslations,
    locale = DEFAULT_LOCALE,
}) => {
    const [isLoaded, setIsLoaded] = useState<boolean>(!shouldLoadTranslations);
    const { setMessages } = useMessages();

    useEffect(() => {
        if (isLoaded) return;

        const lang = mapLocalesByI18nCodes[locale] || locale;

        Promise.all([
            getI18nTranslations(lang, locale),
            getMessages({ locale }, ['betting-countries']),
        ])
            .then((response) => {
                const bettingCountryMessages = response[1];
                setMessages(bettingCountryMessages);
            })
            .finally(() => {
                setIsLoaded(true);
            })
            .catch((e) => {
                captureException(e);
                console.error(e);
            });
    }, [locale, isLoaded, setMessages]);

    return null;
};

async function getI18nTranslations(lang: string, activeLocale: string) {
    import(`i18n-iso-countries/langs/${lang}.json`)
        .then((data) => {
            registerLocale({ ...data, locale: activeLocale });
        })
        .catch((e) => {
            captureException(e);
            console.error(e);
        });
}

export default memo(RegisterCountryTranslations);
