import { useCallback } from 'react';

import { useGetRelevantCurrency } from 'layouts/MainLayout/getRelevantCurrency.cms';
import useLocales from './useLocales';

interface Output {
    transformRelevantCurrency: (content: string) => string | undefined;
    transformRelevantCurrencyInReverseOrder: (
        content: string
    ) => string | undefined;
}

const useTransformRelevantCurrency = (ignoreActiveLocale = false): Output => {
    const { activeLocale } = useLocales();
    const { data: relevantCurrencyResponse } = useGetRelevantCurrency(() => ({
        pagePropsKey: 'relevantCurrency',
    }));

    const relevantCurrencyCode = relevantCurrencyResponse?.currency;
    const LOCALE_FOR_IGNORING_ACTIVE_LOCALE = 'en';

    const formatCurrency = useCallback(
        (currencyCode: string, amount: number) => {
            const style = ignoreActiveLocale ? undefined : 'currency';
            const currency = currencyCode.toUpperCase();
            const minimumFractionDigits = 0;
            const maximumFractionDigits = 2;

            return new Intl.NumberFormat(
                ignoreActiveLocale
                    ? LOCALE_FOR_IGNORING_ACTIVE_LOCALE
                    : activeLocale,
                {
                    style,
                    currency,
                    minimumFractionDigits,
                    maximumFractionDigits,
                }
            ).format(amount);
        },
        [activeLocale, ignoreActiveLocale]
    );

    const currencyFormatter = useCallback(
        (currencyCode: string, amount: number) => {
            switch (currencyCode) {
                case 'mbtc':
                    return `${amount} mBTC`;
                default:
                    return formatCurrency(currencyCode, amount);
            }
        },
        [formatCurrency]
    );

    const getCurrencyFromMatches = useCallback(
        (match: string, relevantCurrency: string) => {
            const explodedString = match.trim().replace(/[{}]/g, '').split('|');
            let currency = null;

            explodedString.forEach((str) => {
                const [currencyCode, amount] = str.split(':');

                if (currencyCode.trim() === relevantCurrency) {
                    currency = {
                        [currencyCode.trim()]: parseFloat(amount.trim()),
                    };
                }
            });

            if (!currency || typeof currency[relevantCurrency] === 'undefined')
                return '';

            return currencyFormatter(
                relevantCurrency,
                currency[relevantCurrency]
            );
        },
        [currencyFormatter]
    );

    const extractRelevantCurrencyFromText = useCallback(
        (currencyCode: string, text: string): string => {
            const regExp = /{{2}\s*(?:\s*\w{3,4}\s*:\s*[\d.]+\s*\|?\s*)*}{2}/g;

            return text.replace(regExp, (match) => {
                const matchLowerCase = match.toLowerCase();

                return getCurrencyFromMatches(matchLowerCase, currencyCode);
            });
        },
        [getCurrencyFromMatches]
    );

    const transformRelevantCurrency = useCallback(
        (content: string) => {
            if (!relevantCurrencyCode) return;

            const relevantCurrencyCodeLowerCase =
                relevantCurrencyCode.toLowerCase();

            return extractRelevantCurrencyFromText(
                relevantCurrencyCodeLowerCase,
                content
            );
        },
        [extractRelevantCurrencyFromText, relevantCurrencyCode]
    );

    const transformRelevantCurrencyInReverseOrder = useCallback(
        (content: string) => {
            if (!relevantCurrencyCode) return;

            const currencyCode = relevantCurrencyCode.toUpperCase();

            const value = transformRelevantCurrency(content);

            if (!value) return;

            const valueWithoutCurrencyCode = value.replace(currencyCode, '');

            return `${valueWithoutCurrencyCode} ${currencyCode}`;
        },
        [relevantCurrencyCode, transformRelevantCurrency]
    );

    return {
        transformRelevantCurrency,
        transformRelevantCurrencyInReverseOrder,
    };
};

export default useTransformRelevantCurrency;
