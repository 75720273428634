import * as GQL from 'types/gql.bet';

import * as Operations from './onUpdateTennisOverviews.bet.gql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'services/apollo/betting';
const defaultOptions = {} as const;

/**
 * __useOnUpdateTennisOverviews__
 *
 * To run a query within a React component, call `useOnUpdateTennisOverviews` and pass it any options that fit your needs.
 * When your component renders, `useOnUpdateTennisOverviews` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnUpdateTennisOverviews({
 *   variables: {
 *      sportEventIDs: // value for 'sportEventIDs'
 *   },
 * });
 */
export function useOnUpdateTennisOverviews(
    baseOptions: ApolloReactHooks.SubscriptionHookOptions<
        GQL.OnUpdateTennisOverviews,
        GQL.OnUpdateTennisOverviewsVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSubscription<
        GQL.OnUpdateTennisOverviews,
        GQL.OnUpdateTennisOverviewsVariables
    >(Operations.OnUpdateTennisOverviews, options);
}
export type OnUpdateTennisOverviewsHookResult = ReturnType<
    typeof useOnUpdateTennisOverviews
>;
export type OnUpdateTennisOverviewsSubscriptionResult =
    Apollo.SubscriptionResult<GQL.OnUpdateTennisOverviews>;
