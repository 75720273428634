import React from 'react';
import hoistNonReactStatic from 'hoist-non-react-statics';

import { useClientContext } from 'layouts/Root/ClientRenderContext';

function withClientRender<T extends React.ComponentType<any>>(
    Component: T,
    fallback?: (props: PropsFromReactComponent<T>) => React.ReactElement | null
): T {
    function WithClientRender(props: any): JSX.Element | null {
        const isClientRender = useClientContext();

        if (!isClientRender) {
            return typeof fallback === 'function' ? fallback(props) : null;
        }

        return <Component {...props} />;
    }

    hoistNonReactStatic(WithClientRender, Component);

    return WithClientRender as T;
}

export default withClientRender;
