import { Icon } from 'types';

export interface Icons {
    [key: string]: Icon;
}

interface EnumSportItem {
    name: string;
    id: string;
}

export interface EnumSportItems extends Array<EnumSportItem> {}

interface EnumPage {
    id: string;
    url: string;
}

export interface EnumPages extends Array<EnumPage> {}

export enum SportListId {
    FooterBettingSports = 'menu:footer-betting-sports',
    FooterBettingESports = 'menu:footer-betting-esports',
}
