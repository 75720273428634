import { ChangeEvent, useCallback, useEffect, useState } from 'react';

import useDebouncedValue from './useDebouncedValue';

interface Input {
    debounce?: number;
    onDebouncedChange?: (value: string) => void;
}

interface Output {
    value: string;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    onClear: VoidFunction;
}

const DEFAULT_SEARCH_DELAY = 1000;

const useInput = (props: Input | undefined = {}): Output => {
    const { onDebouncedChange, debounce = DEFAULT_SEARCH_DELAY } = props;

    const [value, setValue] = useState<string>('');
    const valueDebounced = useDebouncedValue<string>({
        value,
        delay: debounce,
    });

    useEffect(() => {
        onDebouncedChange?.(valueDebounced);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valueDebounced]);

    const onChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => setValue(e.target.value),
        []
    );

    const onClear = useCallback(() => setValue(''), []);

    return {
        value,
        onClear,
        onChange,
    };
};

export default useInput;
