import { Params } from 'react-router-dom';
import { ApolloQueryResult } from '@apollo/client';

import { AppLink, LayoutType } from 'app-constants';
import {
    convertClientRouterPathnameToNextPathname,
    createPathnameFromDynamicClientRoute,
} from 'components/HybridRouter/utils';
import getDynamicPageQuery from 'gql/cms/queries/getDynamicRouteByPath.cms.gql';
import getSeoTagsQuery from 'layouts/MainLayout/getSeoRulesByPage.cms.gql';
import { WithMainApolloClientContext } from 'services/apollo';
import { getBrowserCmsClient } from 'services/apollo/cms/CmsApolloProvider';
import { GetServerSidePageProps } from 'types';
import type {
    GetDynamicRouteByPath,
    GetDynamicRouteByPathVariables,
    GetSeoRulesByPage,
    GetSeoRulesByPageVariables,
} from 'types/gql.cms';
import getSettledResult from 'utils/getSettledResult';

interface GetDynamicPageInput {
    path: string;
    params: Params<string>;
    locale: string;
}

interface GetDynamicPageOutput {
    dynamicRouteByPath: GetDynamicRouteByPath['dynamicRouteByPath'] | null;
    redirect?: GetSeoRulesByPage['seoRulesByPage']['redirect'] | null;
}

type TupleDynamicPageResult = [
    ApolloQueryResult<GetDynamicRouteByPath>,
    ApolloQueryResult<GetSeoRulesByPage>,
];

export async function getDynamicPage({
    path,
    params,
    locale,
}: GetDynamicPageInput): Promise<GetDynamicPageOutput> {
    const pathname = createPathnameFromDynamicClientRoute({
        path,
        params,
    });
    const nextPathname = convertClientRouterPathnameToNextPathname({
        pathname,
        params,
    });

    const client = getBrowserCmsClient();

    if (!client) {
        console.error('CMS client is not defined');

        return {
            dynamicRouteByPath: null,
        };
    }

    const pageUrl = `/${locale}${pathname}`;

    const shouldGetDynamicPage =
        nextPathname === AppLink.CASINO_CATEGORY_SLUG ||
        nextPathname === AppLink.CATCHALL_PAGES ||
        !Object.values(AppLink).includes(nextPathname as AppLink);

    const getDynamicPageRequest = shouldGetDynamicPage
        ? client.query<GetDynamicRouteByPath, GetDynamicRouteByPathVariables>({
              query: getDynamicPageQuery,
              variables: { path: pageUrl },
          })
        : null;

    const [getDynamicPageResult, getSeoTagsResult] =
        await getSettledResult<TupleDynamicPageResult>({
            promises: [
                getDynamicPageRequest,
                client.query<GetSeoRulesByPage, GetSeoRulesByPageVariables>({
                    query: getSeoTagsQuery,
                    variables: { pageUrl },
                }),
            ],
            label: 'getPageRequest',
            errorMessage: 'getPageRequest request failed',
        });

    const redirect = getSeoTagsResult?.data.seoRulesByPage?.redirect || null;
    const dynamicRouteByPath =
        getDynamicPageResult?.data.dynamicRouteByPath || null;

    return {
        redirect,
        dynamicRouteByPath,
    };
}

export const getLayoutType = (
    dynamicPageProps: GetDynamicRouteByPath['dynamicRouteByPath'] | undefined
): LayoutType | null => {
    const dynamicPageEntityInfo = dynamicPageProps?.entity?.info;

    if (
        dynamicPageEntityInfo?.__typename ===
        'PageLocalizedRoutingRelatedEntity'
    ) {
        return dynamicPageEntityInfo.entity.layoutType as LayoutType;
    }

    return null;
};

export const getDynamicPagePathWithoutMatchListParams = (
    initialPath: string
): string => {
    const isPathHasMatchListParams =
        initialPath.includes(AppLink.NEXTTOGO) ||
        initialPath.includes(AppLink.LIVE);

    const replacedValue = initialPath.includes(AppLink.LIVE)
        ? AppLink.LIVE
        : AppLink.NEXTTOGO;

    return isPathHasMatchListParams
        ? initialPath.replace(replacedValue, '')
        : initialPath;
};

const MEDIA_REDIRECT_FILE_URL = '/media-redirect/file/';

export const redirectToMediaFile = (
    ctx: WithMainApolloClientContext,
    id: string
): GetServerSidePageProps => {
    const url = MEDIA_REDIRECT_FILE_URL + id;
    ctx.res.setHeader('x-accel-redirect', url);

    return { props: {} };
};

export const redirectWithLocale = (
    dynamicRouteByPath: GetDynamicRouteByPath['dynamicRouteByPath'],
    pathname: string,
    relevantLocale: string
): GetServerSidePageProps => {
    if (dynamicRouteByPath?.entity) {
        return {
            redirect: {
                destination: `/${relevantLocale}${pathname}`,
                statusCode: 302,
            },
        };
    }

    return {
        redirect: {
            destination: insertLocaleInPathname({
                locale: relevantLocale,
                pathname,
            }),
            statusCode: 302,
        },
    };
};

function insertLocaleInPathname({
    locale,
    pathname,
}: {
    locale: string;
    pathname: string;
}): string {
    const pathSegments = pathname.match(/([^/]+)/g) || [];

    // NOTE:
    // "redirectToAllowLocales" does not perform redirection due to the following specific cases.
    // The following actions should be taken after the check for dynamic page availability.
    return pathSegments.length <= 1
        ? // 1. Replace first segment of path with relevant locale if dynamic page is not exist.
          //     gg.bet/oo -> gg.bet/en;
          //     gg.bet/fake-page/sub-page -> gg.bet/en/sub-page
          //     gg.bet/deleted-page/sub-page -> gg.bet/en/sub-page

          `/${locale}`
        : // 2. Add relevant locale to path if dynamic page exist
          //     gg.bet/dota2 -> gg.bet/en/dota2;
          //     gg.bet/dota2/sub-page -> gg.bet/en/dota2/sub-page
          `/${locale}/${pathSegments.slice(1).join('/')}`;
}
