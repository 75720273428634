import { ApolloClientContext } from '../types';

export type {
    LazyQueryHookOptions,
    MutationHookOptions,
    QueryHookOptions,
    SubscriptionHookOptions,
} from '@apollo/client';
export type { ApolloClientContext };
export {
    useCmsLazyQuery as useLazyQuery,
    useCmsMutation as useMutation,
    useCmsQuery as useQuery,
    useCmsSubscription as useSubscription,
} from '../hooks';
export { useCmsApolloClient } from './CmsApolloProvider';
