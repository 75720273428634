import { ApolloLink } from '@apollo/client';

export const captchaLink = new ApolloLink((operation, forward) => {
    const context = operation.getContext();

    if (!context?.captcha) {
        return forward(operation);
    }

    const captchaToken = context?.captcha?.captchaToken;
    const contextToken = context?.captcha?.contextToken;

    operation.setContext({
        ...context,
        headers: {
            ...context.headers,
            ...(captchaToken && {
                'X-Captcha-Response': captchaToken,
            }),
            ...(contextToken && {
                'X-Captcha-Context-Token': contextToken,
            }),
        },
    });

    return forward(operation);
});
