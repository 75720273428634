import { DEFAULT_LOCALE, DynamicPageEntityType } from 'app-constants';
import { getServerPageGetDynamicRouteByPath } from 'gql/cms/queries/getDynamicRouteByPath.cms';
import { WithMainApolloClientContext } from 'services/apollo';
import { GetServerSidePageProps } from 'types';
import { GetDynamicRouteByPath } from 'types/gql.cms';
import { getPathnameFromResolvedUrl } from 'utils';
import {
    getDynamicPagePathWithoutMatchListParams,
    redirectToMediaFile,
    redirectWithLocale,
} from './utils';

interface Input {
    withExcludedLocale: boolean;
    ctx: WithMainApolloClientContext;
}

export type HandleDynamicPageOutput =
    | {
          type: 'redirect';
          data: GetServerSidePageProps;
      }
    | {
          type: 'data';
          data: GetDynamicRouteByPath;
      };

async function handleDynamicPage({
    ctx,
    withExcludedLocale,
}: Input): Promise<HandleDynamicPageOutput> {
    const { locale, resolvedUrl, res } = ctx;
    const formattedPath = getDynamicPagePathWithoutMatchListParams(resolvedUrl);
    const isDefaultLocale = locale === 'default';
    const relevantLocale =
        (isDefaultLocale
            ? (res.getHeaders()['x-default-locale'] as string)
            : locale) || DEFAULT_LOCALE;
    const pathname = getPathnameFromResolvedUrl(formattedPath);
    const pathForRequest = `/${relevantLocale}${pathname}`;

    const data = await getServerPageGetDynamicRouteByPath({
        variables: {
            path: pathForRequest,
            pathWithoutLocale: pathname,
            withExcludedLocale: isDefaultLocale && withExcludedLocale,
        },
    })(ctx);

    const { dynamicRouteByPath, dynamicRouteByPathWithoutLocale } = data;
    const info = dynamicRouteByPathWithoutLocale?.entity?.info;

    if (
        info?.entityType === DynamicPageEntityType.MEDIA &&
        !dynamicRouteByPath?.entity
    ) {
        return { type: 'redirect', data: redirectToMediaFile(ctx, info.id) };
    }

    if (isDefaultLocale) {
        return {
            type: 'redirect',
            data: redirectWithLocale(
                dynamicRouteByPath,
                pathname,
                relevantLocale
            ),
        };
    }

    if (!dynamicRouteByPath?.entity) {
        return {
            type: 'redirect',
            data: { notFound: true },
        };
    }

    return {
        type: 'data',
        data,
    };
}

export default handleDynamicPage;
