import React, { FC, memo } from 'react';

import { Action, Category } from 'services/GoogleAnalytics';
import { useGetActiveMessages } from './getActiveMessages.cms';

import styles from './broadcastBanner.module.css';

const BroadcastBanner: FC = () => {
    const { data } = useGetActiveMessages(() => ({
        pagePropsKey: 'activeMessages',
    }));

    if (!data?.activeMessages.length) return null;

    return (
        <>
            {data.activeMessages.map((message) => (
                <div
                    className={styles['broadcast-banner']}
                    key={message.id}
                    /* eslint-disable-next-line react/no-danger */
                    dangerouslySetInnerHTML={{ __html: message.content }}
                    data-category={Category.FreezedBanner}
                    data-action={Action.Click}
                />
            ))}
        </>
    );
};

export default memo(BroadcastBanner);
