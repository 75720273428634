import { FC, memo } from 'react';
import cn from 'classnames';

import { LayoutType } from 'app-constants';
import InboxCenterNotify from 'components/inboxCenter/InboxCenterNotify';
import { withClientRender } from 'hocs';
import { useResponsive } from 'layouts/MainLayout/ResponsiveLayoutContext';
import { isEuroMessage, isPopupMessage } from '../helpers';
import InboxCenterEuroNotify from '../InboxCenterEuroNotify';
import InboxCenterPreviewMessage from './InboxCenterPreviewMessage';
import useInboxCenterPreview from './useInboxCenterPreview';

import styles from './inboxCenterPreview.module.css';

interface Props {
    type: LayoutType;
}

const InboxCenterPreview: FC<Props> = ({ type }) => {
    const isMobile = useResponsive().until('md');

    const {
        previewMessage,
        offsetTop,
        previewMessageRef,
        handleMarkRead,
        isPreviewAnimating,
    } = useInboxCenterPreview({ type });

    if (!previewMessage) return null;

    if (isEuroMessage(previewMessage)) {
        return <InboxCenterEuroNotify message={previewMessage} />;
    }

    if (isPopupMessage(previewMessage, type)) {
        return <InboxCenterNotify message={previewMessage} />;
    }

    return (
        <div
            ref={previewMessageRef}
            className={cn(
                'fixed z-20 w-full max-w-[1984px] duration-500 ease-in-out',
                isPreviewAnimating ? 'translate-y-0' : 'translate-y-[-100%]',
                offsetTop
            )}
        >
            <div
                className={cn(
                    'flex justify-center pb-8 pt-2 md:justify-end md:pr-[157px]',
                    isMobile ? styles['wrapper-mob'] : styles.wrapper
                )}
            >
                <div className="w-full max-w-[90%] overflow-hidden rounded-default md:max-w-[360px]">
                    <InboxCenterPreviewMessage
                        message={previewMessage}
                        handleMarkRead={handleMarkRead}
                    />
                </div>
            </div>
        </div>
    );
};

export default memo(withClientRender(InboxCenterPreview));
