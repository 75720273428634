import { SportType } from 'app-constants';

type Output = SportType | undefined;

const getSportTypeByPath = (path: string): Output => {
    return path.match(
        new RegExp(`\\b(?:${SportType.Sports}|${SportType.Esports})\\b`)
    )?.[0] as Output;
};

export default getSportTypeByPath;
