import { useReactiveVar } from '@apollo/client';
import { getCookie } from 'cookies-next';

import { CookiesType } from 'app-constants';
import { deeplinkMatchDataVar } from 'components/betting/GamblerSettings/gamblerState';
import { DeeplinkMatchData } from 'components/betting/GamblerSettings/types';
import { useRouter } from 'components/HybridRouter';
import { useRouterQuery } from 'hooks';
import { Feature, useFeatureFlags } from 'services/features';
import { AppLink } from 'types/applink';

interface Output {
    deeplinkMatchData: DeeplinkMatchData;
    isDeeplinkMatchEnabled: boolean;
}

const useGetDeeplinkMatchData = (): Output => {
    const { pathname } = useRouter();
    const { sportId } = useRouterQuery();
    const { features } = useFeatureFlags();

    const isDeepLinkFeatureEnabled = features.isEnabled(
        Feature.AppDeeplinkForMatchData
    );
    const isDeeplinkMatchEnabled =
        pathname === AppLink.BETS && !sportId && isDeepLinkFeatureEnabled;

    const deeplinkMatchDataFromSettings = useReactiveVar(deeplinkMatchDataVar);
    const { matchId: matchIdFromSettings, recordDate: recordDateFromSettings } =
        deeplinkMatchDataFromSettings || {};

    const deeplinkMatchDataFromCookies = getParsedDeeplinkMatchData();
    const { matchId: matchIdFromCookies, recordDate: recordDateFromCookies } =
        deeplinkMatchDataFromCookies || {};

    const deeplinkMatchData = {
        matchId: matchIdFromSettings || matchIdFromCookies || '',
        recordDate: recordDateFromSettings || recordDateFromCookies || 0,
    };

    return {
        deeplinkMatchData,
        isDeeplinkMatchEnabled,
    };
};

export const getParsedDeeplinkMatchData = (): DeeplinkMatchData | undefined => {
    const deeplinkMatchDataFromCookies = getCookie(
        CookiesType.DeeplinkMatchData
    );

    if (!deeplinkMatchDataFromCookies) return;

    let parsedDeeplinkMatchData: DeeplinkMatchData | undefined;

    if (typeof deeplinkMatchDataFromCookies === 'string') {
        try {
            parsedDeeplinkMatchData = JSON.parse(deeplinkMatchDataFromCookies);
        } catch (e) {
            console.error(e);
        }
    }

    return parsedDeeplinkMatchData;
};

export default useGetDeeplinkMatchData;
