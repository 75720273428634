import { useOnSignOut } from 'gql/platform/subscriptions/onSignOut.ptm';
import { useAuthorization, useLogout } from 'hooks';

const useOnSignOutSubscription = (): void => {
    const { isAuthorized } = useAuthorization();
    const { logoutHandler } = useLogout();

    useOnSignOut({
        async onData({ data: { data } }) {
            if (!data?.signOut) return;

            logoutHandler();
        },
        skip: !isAuthorized,
    });
};

export default useOnSignOutSubscription;
