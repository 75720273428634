import { FC, memo } from 'react';
import { useTranslations } from 'next-intl';

import Button from 'components/Button';
import SmartLink from 'components/SmartLink';
import { withMessages } from 'hocs';
import useCookiesAgreements from './useCookiesAgreements';

const CookiesAgreements: FC = () => {
    const t = useTranslations('CookiesAgreements');
    const { termsUrl, isShow, closeCookiesAgreementHandler } =
        useCookiesAgreements();

    if (!isShow || !termsUrl) return null;

    return (
        <div className="fixed bottom-0 left-0 z-90 box-border flex w-full items-center justify-center bg-[rgba(28,42,56,.9)] p-[5px]">
            <p className="text-[9px] font-normal leading-[18px] text-primary-white sm:text-sm sm:font-medium">
                {t('gincms.cookies_agreements.text')}

                <SmartLink
                    href={termsUrl}
                    className="mr-[25px] whitespace-nowrap pl-[2px] text-[#9b9b9b] underline transition-colors hover:text-primary-white sm:mx-[10px] sm:pl-0 sm:text-[#076dea] sm:hover:text-[#3994ff]"
                >
                    {t('gincms.cookies_agreements.learn_more')}
                </SmartLink>
            </p>

            <Button
                color={Button.Color.Orange}
                size={Button.Size.Default}
                onClick={closeCookiesAgreementHandler}
                className="ml-[10px] h-[38px] !min-w-[76px] p-0 uppercase"
            >
                {t('gincms.cookies_agreements.ok')}
            </Button>
        </div>
    );
};

export default memo(withMessages(CookiesAgreements, ['CookiesAgreements']));
