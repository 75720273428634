import React, { useContext } from 'react';

interface MobileDetect {
    isMobile: boolean;
}

export const MobileDetectContext = React.createContext<MobileDetect>({
    isMobile: false,
});

export const useIsMobile = (): MobileDetect => useContext(MobileDetectContext);
