import * as GQL from 'types/gql.ptm';

import * as Operations from './onKycaidChangeVerificationStatus.ptm.gql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'services/apollo/platform';
const defaultOptions = {} as const;

/**
 * __useOnKycaidChangeVerificationStatus__
 *
 * To run a query within a React component, call `useOnKycaidChangeVerificationStatus` and pass it any options that fit your needs.
 * When your component renders, `useOnKycaidChangeVerificationStatus` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnKycaidChangeVerificationStatus({
 *   variables: {
 *   },
 * });
 */
export function useOnKycaidChangeVerificationStatus(
    baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
        GQL.OnKycaidChangeVerificationStatus,
        GQL.OnKycaidChangeVerificationStatusVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSubscription<
        GQL.OnKycaidChangeVerificationStatus,
        GQL.OnKycaidChangeVerificationStatusVariables
    >(Operations.OnKycaidChangeVerificationStatus, options);
}
export type OnKycaidChangeVerificationStatusHookResult = ReturnType<
    typeof useOnKycaidChangeVerificationStatus
>;
export type OnKycaidChangeVerificationStatusSubscriptionResult =
    Apollo.SubscriptionResult<GQL.OnKycaidChangeVerificationStatus>;
