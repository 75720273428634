import React from 'react';
import cn from 'classnames';

import { IconData } from 'types';

export enum SvgIconSize {
    WH_3 = '3',
    WH_4 = '4',
    WH_6 = '6',
    WH_8 = '8',
    WH_10 = '10',
    WH_12 = '12',
    WH_14 = '14',
}

const regex = /(^|\s)!?(w|h)-\[?\d+(px)?\]?/g;

interface Props extends IconData {}

const SvgIcon: React.FC<Props> = ({
    icon,
    className,
    clickHandler,
    size = SvgIconSize.WH_6,
    dataTest,
}) => {
    const iconSizeCn = `h-${size} w-${size}`;
    const filteredClassName = className
        ? className.replace(regex, '').trim()
        : className;

    return (
        <svg
            viewBox={icon.viewBox}
            className={cn('shrink-0', filteredClassName, iconSizeCn)}
            onClick={clickHandler}
            data-test={dataTest}
        >
            <use xlinkHref={`#${icon.id}`} />
        </svg>
    );
};

export default React.memo(SvgIcon);
