import { FC } from 'react';
import cn from 'classnames';
import { useTranslations } from 'next-intl';

import { WidgetNames } from 'app-constants';
import Widget from 'components/Widget';
import { useGsspData } from 'layouts/Root/PagePropsContext';

import styles from '../../footerLinks.module.css';

const FooterPartners: FC = () => {
    const t = useTranslations('Footer');
    const list = cn('grid grid-cols-1');
    const { widgets } = useGsspData();

    return (
        <div className="relative mt-8" data-test="footer-partners">
            <div
                className="mb-6 text-md font-bold text-primary-orange-toxic"
                data-test="footer-partners__title"
            >
                {t('gincms.footer.title.company_partners')}
            </div>
            <Widget
                name={WidgetNames.FOOTER_PARTNERS}
                className={cn(list, styles.links)}
                widgetData={
                    widgets?.[WidgetNames.FOOTER_PARTNERS]?.widgetByName
                        ?.template
                }
            />
        </div>
    );
};

export default FooterPartners;
