import { modals } from './modals';

export const MODAL_NODE_ID = 'modal';

export const visibleNavbarMobileModalHash: Array<keyof typeof modals> = [
    '/player/profile-data',
    '/player/invite-friend',
    '/player/invited-friends',
    '/player/profile-deposit',
    '/player/profile-account',
    '/player/profile-promocode',
    '/player/profile-casinobonuses',
    '/player/profile-rewards-history',
    '/player/profile-cashier-payment',
    '/player/bonus-program',
    '/player/profile-game-transactions',
    '/player/kyc',
    '/player/responsible-gaming',
    '/player/profile-cashier-withdraw',
    '/first-deposit-required',
];

export const visibleNavbarHeaderMobileModalHash: Array<keyof typeof modals> = [
    '/message/inbox',
    '/player/profile-bets-history',
    '/player/bets-history',
];

export const visibleHeaderDesktopModalHash: Array<keyof typeof modals> = [
    '/euro-rules',
];

export const authorizationModalsHash: Array<keyof typeof modals> = [
    '/auth/signin',
    '/auth/register',
    '/auth/password-recovery',
    '/auth/password-new',
    '/register-finish',
    '/player/confirm-email',
    '/player/verification-phone',
    '/player/referral-invite-success',
    '/player/confirm-limit',
];
