import { ApolloLink, HttpLink, HttpOptions, split } from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { createUploadLink } from 'apollo-upload-client';
import type { BatchKeys } from 'app-constants';

import { isSsr } from 'utils/isSsr';

const makeTerminalLink = (defaultLinkOptions: HttpOptions): ApolloLink => {
    const { headers } = defaultLinkOptions;

    let isEnabledSsrCmsGqlBatching =
        process.env.CMS_GQL_SSR_BATCHING_ENABLE ?? false;

    if (headers && headers['x-cms-gql-batching']) {
        isEnabledSsrCmsGqlBatching = headers['x-cms-gql-batching'] === '1';
    }

    const httpLink = new HttpLink(defaultLinkOptions);
    const uploadLink = createUploadLink(
        defaultLinkOptions
    ) as unknown as ApolloLink;

    // Disabling batching is not recommended for the client, as during the application initialization, multiple requests are executed approximately at the same time.

    // NOTE: A typeof window === 'undefined' was made here because an unexplored issue was discovered.
    // The problem was related to the variable 'isSsr' being ignored.

    const batchHttpLink = new BatchHttpLink({
        ...defaultLinkOptions,
        batchMax: isSsr() ? 7 : 5,
        batchInterval: isSsr() ? 5 : 30,
        batchKey(operation) {
            const key: BatchKeys | undefined = operation.getContext()?.batchKey;

            if (!key) return '';

            return key;
        },
    });

    if (isSsr() && !isEnabledSsrCmsGqlBatching) {
        return split(
            ({ getContext }) => {
                return !!getContext()?.batchKey && !getContext()?.important;
            },
            batchHttpLink,
            splitUpload(uploadLink, httpLink)
        );
    }

    return split(
        ({ getContext }) => !!getContext()?.important,
        httpLink,
        splitUpload(uploadLink, batchHttpLink)
    );
};

function splitUpload(
    uploadLink: ApolloLink,
    networkLink: ApolloLink
): ApolloLink {
    return split(
        ({ getContext }) => !!getContext()?.hasUpload,
        uploadLink,
        networkLink
    );
}

export default makeTerminalLink;
