export enum BannerZoneSportType {
    MIX = 'mix',
    ESPORT = 'esport',
    CLASSIC = 'classic',
}

export enum BannerZonePlatform {
    DESKTOP = 'desktop',
    MOBILE = 'mobile',
    IOS = 'ios',
    ANDROID = 'android',
}

export enum TimePeriod {
    Daily = 0,
    Weekly = 1,
    Monthly = 2,
    MonthlyFull = 3,
}

export enum BannerZoneLocation {
    TOP = 'top',
    BOTTOM = 'bottom',
    LEFT = 'left',
    RIGHT = 'right',
    RIGHT_TOP_SPORT_EVENTS = 'right_top_sport_events',
    TOP_SLIDER = 'top_slider',
    TOP_OUTRIGHTS_SLIDER = 'top_outrights_slider',
    RIGHT_SLIDER = 'right_slider',
    EVENTS_OF_THE_DAY_SLIDER = 'events_of_the_day_slider',
    TOP_SPORT_EVENTS = 'top_sport_events',
    POPULAR_SPORT_EVENTS = 'popular_sport_events',
    FAVORITES_SPORT_EVENTS = 'favorites_sport_events',
    OVERVIEW = 'overview',
    PINNED_SPORT_EVENT = 'pinned_sport_event',
    PINNED_SPORT_EVENT_BY_SPORT = 'pinned_sport_event_by_sport',
    TOP_PROMO_TOURNAMENT_SLIDER = 'top_promo_tournament_slider',
    LINKED_SPORT = 'linked_sport',
}

export enum BannerZoneSettings {
    INTERVAL = 'interval',
}

export enum SportEventBannerTheme {
    OutrightMultiple = 'outrightMultiple',
    Colorful = 'colorful',
}
