import { useGetSubLogo } from './getSubLogo.cms';

interface Output {
    title: string;
    link: string;
    imageId?: string | null;
}

const useSubLogo = (): Output | null => {
    const { data } = useGetSubLogo(() => ({
        pagePropsKey: 'subLogo',
    }));

    if (!data?.subLogo) return null;

    const { title, link, images } = data.subLogo;

    const imageId = images.find((item) => item.imageId)?.imageId;

    return {
        title,
        link,
        imageId,
    };
};

export default useSubLogo;
