import type { SubscriptionResult } from '@apollo/client';

const getStubUseSubscription = (): SubscriptionResult => {
    return {
        data: {},
        error: undefined,
        loading: false,
        variables: {},
    };
};

export default getStubUseSubscription;
