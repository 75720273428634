import { GetServerSidePropsContext, GetStaticPropsContext } from 'next';
import type { NextRouter } from 'next/router';

import getActiveLocale from './getActiveLocale';

export const getMessages = async (
    ctx: NextRouter | GetServerSidePropsContext | GetStaticPropsContext,
    namespaces: IntlMessagesNamespace[]
): Promise<{ [key: string]: {} }> => {
    const activeLocale = getActiveLocale(ctx);

    const translations = await Promise.all<{ default: {} }>(
        namespaces.map(
            (folder) => import(`translations/${folder}/${activeLocale}.json`)
        )
    );

    return namespaces.reduce<{ [key: string]: {} }>((acc, folder, index) => {
        acc[folder] = translations[index].default;

        return acc;
    }, {});
};
