import { getCookie, hasCookie } from 'cookies-next';
import { omit, sortBy } from 'lodash';
import { GetServerSideProps, GetServerSidePropsResult } from 'next';

import { CookiesType, DEFAULT_LOCALE, LayoutType } from 'app-constants';
import { FeatureFlags } from 'services/features';
import FeatureFlagsManagerSsr from 'services/features/FeatureFlagsManagerSsr';
import { settleFeatureFlags } from 'services/features/utils';
import { logError } from 'services/logger';
import { captureSentryException } from 'services/sentry';
import { lazyInjectBettingOptionsToContext } from '../betting/lazyInjectBettingOptionsToContext';
import { APOLLO_CMS_LINK } from '../constants';
import type {
    GetServerSidePropsWithClients,
    GetServerSidePropsWithMainApolloClient,
    WithApolloClientsContext,
    WithMainApolloClientContext,
} from '../types';
import setExtendedContext from '../utils/setExtendedContext';
import handleWithoutSSR from './utils/handleWithoutSSR';
import setPropsTrackersFromCookie from './utils/setPropsTrackersFromCookie';
import getCrmTrackerHeader from './utils/trackers/getCrmTrackerHeader';
import setCookiesTracker from './utils/trackers/setCookiesTracker';
import cmsApolloClientOptions from './cmsApolloClientOptions';
import makeLink from './makeCmsLink';

const withCmsClient = (
    handler: (
        pageGetServerSidePropsFn?: GetServerSidePropsWithMainApolloClient
    ) => GetServerSidePropsWithMainApolloClient,
    layoutType: LayoutType
) => {
    return <C extends WithMainApolloClientContext>(
            pageGetServerSidePropsFn?: GetServerSidePropsWithClients<C>
        ): GetServerSideProps =>
        async (context) => {
            const { locale = DEFAULT_LOCALE, res, req, query } = context;
            const ssrEnabledEnv = process.env.CMS_SSR_ENABLE || 'true';
            const isSsrEnabledBettings =
                process.env.BETTING_SSR_ENABLE === 'true';

            setCookiesTracker({ req, res, query });

            const cmsClientOptions = cmsApolloClientOptions(req, locale);

            setExtendedContext(
                context,
                APOLLO_CMS_LINK,
                makeLink({
                    ...cmsClientOptions,
                })
            );

            const crmNotificationHeader = getCrmTrackerHeader({
                query,
                cmsClientOptions,
                context: context as WithMainApolloClientContext,
            });

            const extendedContext = context as WithApolloClientsContext;
            const FFManager = FeatureFlagsManagerSsr.init();
            setExtendedContext(context, 'featureFlagManager', FFManager);

            if (ssrEnabledEnv === 'false') {
                return handleWithoutSSR({
                    cmsClientOptions,
                    context: extendedContext,
                    crmNotificationHeader: crmNotificationHeader || undefined,
                });
            }

            if (layoutType === LayoutType.Betting && isSsrEnabledBettings) {
                await lazyInjectBettingOptionsToContext({
                    context: context as WithMainApolloClientContext,
                    crmNotificationHeader,
                });
            }

            let result: GetServerSidePropsResult<Dict> = { props: {} };
            let notSupportedFeatures: string[] = [];
            let parsedFeatures: FeatureFlags = {};

            try {
                const [
                    resultInner,
                    {
                        parsedFeatures: parsedFeaturesInner,
                        notSupportedFeatures: notSupportedFeaturesInner = [],
                    },
                ] = await Promise.all([
                    handler(
                        pageGetServerSidePropsFn as GetServerSidePropsWithMainApolloClient
                    )(extendedContext),
                    settleFeatureFlags({
                        context: extendedContext,
                        headers: crmNotificationHeader || {},
                    }),
                ]);
                result = resultInner;

                if (parsedFeaturesInner) {
                    parsedFeatures = parsedFeaturesInner;
                }

                notSupportedFeatures = notSupportedFeaturesInner;
            } catch (error) {
                logError({
                    message: 'withCmsClient request failed',
                    err: error,
                    request: context.req,
                });

                captureSentryException({
                    label: 'withCmsClient',
                    message: 'withCmsClient request failed',
                    additionalData: { error },
                });
            }

            let resultProps = {};

            if ('props' in result) {
                resultProps = result.props;
            }

            const notFound = 'notFound' in result ? result.notFound : undefined;
            // NOTE: we added a priority key to indicate the main redirect, such as on the HeadSeoTags page, more information in Readme
            const redirect =
                'redirect' in result && extendedContext.redirects
                    ? sortBy(extendedContext.redirects, ['priority'])[0]
                    : undefined;

            if (redirect) {
                return { redirect };
            }

            const pageProps = {
                ...resultProps,
                cmsClientOptions: omit(cmsClientOptions, ['req', 'endpoint']),
                parsedFeatures,
                notSupportedFeatures,
                isDebug: !!getCookie(CookiesType.DebugInfo, { req }),
                isAuthorized: hasCookie(CookiesType.Paseto, { req }),
            };

            setPropsTrackersFromCookie({
                pageProps,
                req,
            });

            return {
                notFound,
                props: pageProps,
            };
        };
};

export default withCmsClient;
