import type { FC } from 'react';
import { useInView } from 'react-intersection-observer';
import cn from 'classnames';
import { useTranslations } from 'next-intl';

import { WidgetNames } from 'app-constants';
import Widget, { useWidget } from 'components/Widget';
import { withClientRender } from 'hocs';
import { Action, Category } from 'services/GoogleAnalytics';

import styles from '../footerLinks.module.css';

interface DataTests {
    containerDataTests?: string;
    titleDataTests?: string;
}

interface Props {
    widgetName: WidgetNames;
    dataTests?: DataTests;
}
const FooterSocial: FC<Props> = ({ widgetName, dataTests }) => {
    const t = useTranslations('Footer');
    const list = cn('grid grid-cols-1');

    const { ref, inView } = useInView({ triggerOnce: true });

    const { widget } = useWidget({
        name: widgetName,
        skip: !inView,
    });

    if (inView && !widget) {
        return null;
    }

    return (
        <div ref={ref}>
            <div
                className="relative mt-8"
                data-category={Category.Footer}
                data-action={Action.ClickSocialNetw}
                data-test={dataTests?.containerDataTests}
            >
                <div
                    className="mb-6 text-md font-bold text-primary-orange-toxic"
                    data-test={dataTests?.titleDataTests}
                >
                    {t('gincms.footer.title.social_networks')}
                </div>
                <Widget name={widgetName} className={cn(list, styles.links)} />
            </div>
        </div>
    );
};

export default withClientRender(FooterSocial);
