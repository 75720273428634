import { useMemo, useRef } from 'react';

import { TRADING_PROVIDER_ID } from 'app-constants';
import { Sports } from 'components/betting/Categorizer/constants';
import { AmericanFootballOverview } from 'gql/betting/fragments/scoreboard/americanFootballOverviewFragment.bet.gql';
import { BasketballOverview } from 'gql/betting/fragments/scoreboard/basketballOverviewFragment.bet.gql';
import { BrawlStarsOverview } from 'gql/betting/fragments/scoreboard/brawlStarsOverviewFragment.bet.gql';
import { CricketOverview } from 'gql/betting/fragments/scoreboard/cricketOverviewFragment.bet.gql';
import { CSGOOverview } from 'gql/betting/fragments/scoreboard/csgoOverviewFragment.bet.gql';
import { Dota2Overview } from 'gql/betting/fragments/scoreboard/dota2OverviewFragment.bet.gql';
import { EBasketballOverview } from 'gql/betting/fragments/scoreboard/ebasketballOverviewFragment.bet.gql';
import { EFootballOverview } from 'gql/betting/fragments/scoreboard/eFootballOverviewFragment.bet.gql';
import { EStreetballOverview } from 'gql/betting/fragments/scoreboard/eStreetballOverviewFragment.bet.gql';
import { ETennisOverview } from 'gql/betting/fragments/scoreboard/eTennisOverviewFragment.bet.gql';
import { EVolleyballOverview } from 'gql/betting/fragments/scoreboard/eVolleyballOverviewFragment.bet.gql';
import { FootballOverview } from 'gql/betting/fragments/scoreboard/footballOverviewFragment.bet.gql';
import { FutsalOverview } from 'gql/betting/fragments/scoreboard/futsalOverviewFragment.bet.gql';
import { HearthstoneOverview } from 'gql/betting/fragments/scoreboard/hearthstoneOverviewFragment.bet.gql';
import { IceHockeyOverview } from 'gql/betting/fragments/scoreboard/iceHockeyOverviewFragment.bet.gql';
import { IndoorSoccerOverview } from 'gql/betting/fragments/scoreboard/indoorSoccerOverviewFragment.bet.gql';
import { KingOfGloryOverview } from 'gql/betting/fragments/scoreboard/kingOfGloryOverviewFragment.bet.gql';
import { LOLOverview } from 'gql/betting/fragments/scoreboard/lolOverviewFragment.bet.gql';
import { LolWildriftOverview } from 'gql/betting/fragments/scoreboard/lolWildriftOverviewFragment.bet.gql';
import { MMAOverview } from 'gql/betting/fragments/scoreboard/mmaOverviewFragment.bet.gql';
import { OverwatchOverview } from 'gql/betting/fragments/scoreboard/overwatchOverviewFragment.bet.gql';
import { StarCraft1Overview } from 'gql/betting/fragments/scoreboard/starCraft1OverviewFragment.bet.gql';
import { StarCraftOverview } from 'gql/betting/fragments/scoreboard/starCraftOverviewFragment.bet.gql';
import { TableTennisOverview } from 'gql/betting/fragments/scoreboard/tableTennisOverviewFragment.bet.gql';
import { TennisOverview } from 'gql/betting/fragments/scoreboard/tennisOverviewFragment.bet.gql';
import { VolleyballOverview } from 'gql/betting/fragments/scoreboard/volleyballOverviewFragment.bet.gql';
import { useOnUpdateAmericanFootballOverviews } from 'gql/betting/subscriptions/scoreboard/onUpdateAmericanFootballOverviews.bet';
import { useOnUpdateBasketballOverviews } from 'gql/betting/subscriptions/scoreboard/onUpdateBasketballOverviews.bet';
import { useOnUpdateBrawlStarsOverviews } from 'gql/betting/subscriptions/scoreboard/onUpdateBrawlStarsOverviews.bet';
import { useOnUpdateCsgoOverviews } from 'gql/betting/subscriptions/scoreboard/onUpdateCSGOOverviews.bet';
import { useOnUpdateDota2Overviews } from 'gql/betting/subscriptions/scoreboard/onUpdateDota2Overviews.bet';
import { useOnUpdateEBasketballOverviews } from 'gql/betting/subscriptions/scoreboard/onUpdateEBasketballOverviews.bet';
import { useOnUpdateEFootballOverviews } from 'gql/betting/subscriptions/scoreboard/onUpdateEFootballOverviews.bet';
import { useOnUpdateEStreetballOverviews } from 'gql/betting/subscriptions/scoreboard/onUpdateEStreetballOverviews.bet';
import { useOnUpdateETennisOverviews } from 'gql/betting/subscriptions/scoreboard/onUpdateETennisOverviews.bet';
import { useOnUpdateEVolleyballOverviews } from 'gql/betting/subscriptions/scoreboard/onUpdateEVolleyballOverviews.bet';
import { useOnUpdateFootballOverviews } from 'gql/betting/subscriptions/scoreboard/onUpdateFootballOverviews.bet';
import { useOnUpdateFutsalOverviews } from 'gql/betting/subscriptions/scoreboard/onUpdateFutsalOverviews.bet';
import { useOnUpdateIceHockeyOverviews } from 'gql/betting/subscriptions/scoreboard/onUpdateIceHokeyOverviews.bet';
import { useOnUpdateIndoorSoccerOverviews } from 'gql/betting/subscriptions/scoreboard/onUpdateIndoorSoccerOverviews.bet';
import { useOnUpdateKingOfGloryOverviews } from 'gql/betting/subscriptions/scoreboard/onUpdateKingOfGloryOverviews.bet';
import { useOnUpdateLolOverviews } from 'gql/betting/subscriptions/scoreboard/onUpdateLolOverviews.bet';
import { useOnUpdateLolWildriftOverviews } from 'gql/betting/subscriptions/scoreboard/onUpdateLolWildriftOverviews.bet';
import { useOnUpdateOverwatchOverviews } from 'gql/betting/subscriptions/scoreboard/onUpdateOverwatchOverviews.bet';
import { useOnUpdateStarCraft1Overviews } from 'gql/betting/subscriptions/scoreboard/onUpdateStarCraft1Overviews.bet';
import { useOnUpdateStarCraftOverviews } from 'gql/betting/subscriptions/scoreboard/onUpdateStarCraftOverviews.bet';
import { useOnUpdateTableTennisOverviews } from 'gql/betting/subscriptions/scoreboard/onUpdateTableTennisOverviews.bet';
import { useOnUpdateTennisOverviews } from 'gql/betting/subscriptions/scoreboard/onUpdateTennisOverviews.bet';
import { useOnUpdateVolleyballOverviews } from 'gql/betting/subscriptions/scoreboard/onUpdateVolleyballOverviews.bet';
import { useBettingApolloClient } from 'services/apollo';
import { Overview } from 'types';
import { SportEventOverview } from 'types/api.bet';
import { useOnUpdateCricketOverviews } from '../gql/betting/subscriptions/scoreboard/onUpdateCricketOverviews.bet';
import { useOnUpdateHearthstoneOverviews } from '../gql/betting/subscriptions/scoreboard/onUpdateHearthstoneOverviews.bet';
import { useOnUpdateMmaOverviews } from '../gql/betting/subscriptions/scoreboard/onUpdateMMAOverviews.bet';
import { useFragment } from './useFragment';

interface Input {
    sportId: string;
    sportEventId: string;
    version: string;
    skip?: boolean;
    isEnded?: boolean;
}

interface Output {
    overview: Overview | undefined;
    loading: boolean;
}

export const SubscriptionsMap = {
    [Sports.AMERICAN_FOOTBALL]: useOnUpdateAmericanFootballOverviews,
    [Sports.FOOTBALL]: useOnUpdateFootballOverviews,
    [Sports.ESPORTS_FIFA]: useOnUpdateEFootballOverviews,
    [Sports.BASKETBALL]: useOnUpdateBasketballOverviews,
    [Sports.ESPORTS_BASKETBALL]: useOnUpdateEBasketballOverviews,
    [Sports.TENNIS]: useOnUpdateTennisOverviews,
    [Sports.ESPORTS_TENNIS]: useOnUpdateETennisOverviews,
    [Sports.TABLE_TENNIS]: useOnUpdateTableTennisOverviews,
    [Sports.ESPORTS_COUNTER_STRIKE]: useOnUpdateCsgoOverviews,
    [Sports.ESPORTS_DOTA_2]: useOnUpdateDota2Overviews,
    [Sports.VOLLEYBALL]: useOnUpdateVolleyballOverviews,
    [Sports.ESPORTS_VOLLEYBALL]: useOnUpdateEVolleyballOverviews,
    [Sports.ESPORTS_LEAGUE_OF_LEGENDS]: useOnUpdateLolOverviews,
    [Sports.ICE_HOCKEY]: useOnUpdateIceHockeyOverviews,
    [Sports.FUTSAL]: useOnUpdateFutsalOverviews,
    [Sports.ESPORTS_STARCRAFT]: useOnUpdateStarCraftOverviews,
    [Sports.ESPORTS_STARCRAFT_1]: useOnUpdateStarCraft1Overviews,
    [Sports.ESPORTS_STREETBALL]: useOnUpdateEStreetballOverviews,
    [Sports.MMA]: useOnUpdateMmaOverviews,
    [Sports.ESPORTS_KING_OF_GLORY]: useOnUpdateKingOfGloryOverviews,
    [Sports.ESPORTS_LEAGUE_OF_LEGENDS_WILD_RIFT]:
        useOnUpdateLolWildriftOverviews,
    [Sports.CRICKET]: useOnUpdateCricketOverviews,
    [Sports.INDOOR_SOCCER]: useOnUpdateIndoorSoccerOverviews,
    [Sports.ESPORTS_OVERWATCH]: useOnUpdateOverwatchOverviews,
    [Sports.ESPORTS_BRAWL_STARS]: useOnUpdateBrawlStarsOverviews,
    [Sports.ESPORTS_HEARTHSTONE]: useOnUpdateHearthstoneOverviews,
} as const;

export const SubscriptionFragments = {
    [Sports.AMERICAN_FOOTBALL]: AmericanFootballOverview,
    [Sports.FOOTBALL]: FootballOverview,
    [Sports.ESPORTS_FIFA]: EFootballOverview,
    [Sports.BASKETBALL]: BasketballOverview,
    [Sports.ESPORTS_BASKETBALL]: EBasketballOverview,
    [Sports.TENNIS]: TennisOverview,
    [Sports.ESPORTS_TENNIS]: ETennisOverview,
    [Sports.TABLE_TENNIS]: TableTennisOverview,
    [Sports.ESPORTS_COUNTER_STRIKE]: CSGOOverview,
    [Sports.ESPORTS_DOTA_2]: Dota2Overview,
    [Sports.VOLLEYBALL]: VolleyballOverview,
    [Sports.ESPORTS_VOLLEYBALL]: EVolleyballOverview,
    [Sports.ESPORTS_LEAGUE_OF_LEGENDS]: LOLOverview,
    [Sports.ESPORTS_LEAGUE_OF_LEGENDS_WILD_RIFT]: LolWildriftOverview,
    [Sports.ICE_HOCKEY]: IceHockeyOverview,
    [Sports.FUTSAL]: FutsalOverview,
    [Sports.ESPORTS_STARCRAFT]: StarCraftOverview,
    [Sports.ESPORTS_STARCRAFT_1]: StarCraft1Overview,
    [Sports.ESPORTS_STREETBALL]: EStreetballOverview,
    [Sports.MMA]: MMAOverview,
    [Sports.ESPORTS_KING_OF_GLORY]: KingOfGloryOverview,
    [Sports.CRICKET]: CricketOverview,
    [Sports.INDOOR_SOCCER]: IndoorSoccerOverview,
    [Sports.ESPORTS_OVERWATCH]: OverwatchOverview,
    [Sports.ESPORTS_BRAWL_STARS]: BrawlStarsOverview,
    [Sports.ESPORTS_HEARTHSTONE]: HearthstoneOverview,
} as const;

type OverviewSports = keyof typeof SubscriptionsMap;

const noopSubscriptions: typeof useOnUpdateFootballOverviews = () => ({
    loading: false,
    data: {
        onUpdateSportEventOverviews: {
            replace: [],
        },
    },
});

const noopFragment = () => ({
    loading: false,
    data: { overview: undefined },
});

const useOverviewSubscription = ({
    sportEventId,
    sportId,
    version,
    skip = false,
    isEnded = false,
}: Input): Output => {
    const { client } = useBettingApolloClient();

    const versionRef = useRef<string>(version);

    const sportEventIDs = useMemo(() => {
        return sportEventId.startsWith(TRADING_PROVIDER_ID)
            ? [
                  {
                      id: sportEventId.replace(TRADING_PROVIDER_ID, ''),
                      checksum: versionRef.current,
                  },
              ]
            : [];
    }, [sportEventId]);

    const useOnUpdateSubscriptions =
        SubscriptionsMap[sportId as OverviewSports] || noopSubscriptions;

    const { loading } = useOnUpdateSubscriptions({
        variables: {
            sportEventIDs,
        },
        context: {
            isScoreboard: true,
        },
        skip: skip || isEnded,
    });

    const useOverviewFragment = SubscriptionFragments[sportId as OverviewSports]
        ? useFragment
        : noopFragment;

    const { data: response } = useOverviewFragment<SportEventOverview>({
        id: sportEventId.replace(TRADING_PROVIDER_ID, ''),
        fragment: SubscriptionFragments[sportId as OverviewSports],
        typename: 'SportEventOverview',
        client,
    });

    const overview = response?.overview as Overview | undefined;

    return {
        overview,
        loading,
    };
};

export default useOverviewSubscription;
