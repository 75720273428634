type Provider<T> = { [K in keyof T]: string };
export const getTransformedContentWithoutProvider = <
    T extends Record<string, RegExp>,
>({
    content = '',
    patterns,
}: {
    content?: string;
    patterns: T;
}): { content: string; patternsValues: Provider<T> } =>
    Object.keys(patterns).reduce(
        (acc, patternKey) => {
            const pattern = patterns[patternKey];

            if (pattern) {
                return {
                    content: acc.content.replace(pattern, ''),
                    patternsValues: {
                        ...acc.patternsValues,
                        [patternKey]: acc.content.match(pattern)?.[1] || '',
                    },
                };
            }

            return acc;
        },
        { content, patternsValues: {} as Provider<T> }
    );
