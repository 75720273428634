import * as GQL from 'types/gql.bet';

import * as Operations from './changeGamblerSetting.bet.gql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'services/apollo/betting';
const defaultOptions = {} as const;

export type ChangeGamblerSettingMutationFn = Apollo.MutationFunction<
    GQL.ChangeGamblerSetting,
    GQL.ChangeGamblerSettingVariables
>;

/**
 * __useChangeGamblerSetting__
 *
 * To run a mutation, you first call `useChangeGamblerSetting` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeGamblerSetting` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeGamblerSetting, { data, loading, error }] = useChangeGamblerSetting({
 *   variables: {
 *      key: // value for 'key'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useChangeGamblerSetting(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQL.ChangeGamblerSetting,
        GQL.ChangeGamblerSettingVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        GQL.ChangeGamblerSetting,
        GQL.ChangeGamblerSettingVariables
    >(Operations.changeGamblerSetting, options);
}
export type ChangeGamblerSettingHookResult = ReturnType<
    typeof useChangeGamblerSetting
>;
export type ChangeGamblerSettingMutationResult =
    Apollo.MutationResult<GQL.ChangeGamblerSetting>;
export type ChangeGamblerSettingMutationOptions = Apollo.BaseMutationOptions<
    GQL.ChangeGamblerSetting,
    GQL.ChangeGamblerSettingVariables
>;
