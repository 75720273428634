import gql from 'graphql-tag';
import { concat, floor, forEach } from 'lodash';
import { BetslipTranslate } from 'translations/types';

import { ModalsHash, ResponsibleGamingLimitTypes } from 'app-constants';
import { RouterPush } from 'components/HybridRouter/types';
import { LIMIT_TYPES } from 'components/modals/PlayerLimitReachedModal/constants';
import type { BettingApolloClient } from 'services/apollo';
import FeatureFlagsManager from 'services/features';
import {
    BetStatus,
    BetType,
    MatchBase,
    Odd,
    PlaceBetMeta,
    Restriction,
    RestrictionType,
} from 'types/gql.bet';
import idPathParser from 'utils/idPathParser';
import {
    BetslipStatus,
    ErrorCodeMessages,
    ErrorCodes,
    PRECISION_FOR_AMOUNTS,
    RestrictionsTypenameMapping,
    RestrictionsTypenameType,
} from './constants';
import type { BetslipError } from './types';

export interface Totals {
    totalOdd: number | null;
    totalWin: number;
    totalStake: number;
}

interface ValidateStake {
    value: string;
    maxValue: number;
}

export function validateStake({
    value,
    maxValue,
}: ValidateStake): string | null {
    let nextValue: string = value;
    const [int = '', fraction = ''] = value.split('.');

    nextValue = nextValue.replace(/,/g, '.');

    if (+nextValue > maxValue) {
        nextValue = convertViewMaxStake(`${maxValue}`);
    }

    if (fraction.length > 2) {
        nextValue = `${+int}.${fraction.slice(0, 2)}`;
    }

    if (+nextValue < 0) {
        return null;
    }

    if (!/^-?\d+\.?\d*$/.test(nextValue) && nextValue !== '') {
        return null;
    }

    return nextValue;
}

export function convertViewMaxStake(maxStake: string): string {
    const maxStakeNumber = +maxStake;

    if (isNaN(maxStakeNumber)) {
        return '';
    }

    return floor(maxStakeNumber, PRECISION_FOR_AMOUNTS).toString();
}

export function getSingleTotals(
    oddValues: number[],
    totalStake: number
): Totals {
    return {
        totalOdd: Number(oddValues[0]),
        totalWin: Number(oddValues[0]) * totalStake,
        totalStake,
    };
}

export const getExpressTotals = (
    oddValues: number[],
    totalStake: number
): Totals => {
    const totalOdd = oddValues.reduce((acc, value) => {
        if (!value) {
            return acc;
        }

        return acc * value || value;
    }, 0);

    return {
        totalOdd,
        totalWin: totalOdd * totalStake,
        totalStake,
    };
};

export function getOddByFragment(
    oddId: string,
    client: BettingApolloClient
): Odd | null {
    return client.cache.readFragment<Odd>({
        fragment: gql`
            fragment BetslipOddFragment on Odd {
                id
                value
                path
            }
        `,
        id: idPathParser.addPrefix('Odd', oddId),
        fragmentName: 'BetslipOddFragment',
    });
}

export function combine<T>(arr: T[], n: number): T[][] {
    const combinations: T[][] = [];

    if (arr.length < n) return [];

    if (n === arr.length) return [arr];

    if (n === 1) return arr.map((value) => [value]);

    forEach(arr, (value, i) => {
        const newArray = arr.slice(i + 1);
        const nextCombs = combine(newArray, n - 1);
        nextCombs.forEach((c) => combinations.push(concat(value, c)));
    });

    return combinations;
}

export const getSystemTotals = (
    oddValues: number[],
    totalStake: number,
    systemSize: number
): Totals => {
    const totals = {
        totalOdd: 0,
        totalWin: 0,
        totalStake,
    };

    const combinations = combine(oddValues, systemSize);

    const systemDivider = combinations.length;

    combinations.reduce((acc, values) => {
        const { totalWin, totalOdd } = getExpressTotals(
            values,
            totalStake / systemDivider
        );

        acc.totalOdd += totalOdd || 0;
        acc.totalWin += totalWin;

        return acc;
    }, totals);

    totals.totalOdd = totals.totalWin / totals.totalStake;

    return totals;
};

export function getSportEventByFragment(
    sportEventId: string,
    client: BettingApolloClient,
    keyFields: Array<keyof MatchBase>
): MatchBase | null {
    if (!keyFields.length) return null;

    return client.cache.readFragment({
        id: idPathParser.addPrefix('SportEvent', sportEventId),
        fragment: gql`
            fragment SportEventId on SportEvent {
              ${keyFields.join('\n')}
            }
`,
        fragmentName: 'SportEventId',
    });
}

export function convertBetStatus(betStatus: BetStatus): BetslipStatus {
    switch (betStatus) {
        case BetStatus.Init:
        case BetStatus.Created:
        case BetStatus.Placed:
        case BetStatus.ArbitrageAccepted:
        case BetStatus.ArbitrageDeclined: {
            return BetslipStatus.Processing;
        }

        case BetStatus.Accepted:
        case BetStatus.Settled:
        case BetStatus.Unsettled:
        case BetStatus.RolledBack: {
            return BetslipStatus.Success;
        }

        case BetStatus.Declined:
        case BetStatus.ForcedDeclined:
        case BetStatus.PlaceError: {
            return BetslipStatus.Error;
        }

        default: {
            return BetslipStatus.Error;
        }
    }
}

export function getOddsByIds(
    oddIds: string[],
    client: BettingApolloClient
): Odd[] {
    return oddIds.reduce<Odd[]>((acc, oddId) => {
        const odd = getOddByFragment(oddId, client);

        if (odd) acc.push(odd);

        return acc;
    }, []);
}

export const formatTotals = (isFreebet: boolean, totals: Totals): Totals => ({
    totalStake: floor(totals.totalStake, 2),
    totalOdd: totals.totalOdd ? floor(totals.totalOdd, 2) : totals.totalOdd,
    totalWin: floor(
        isFreebet ? totals.totalWin - totals.totalStake : totals.totalWin,
        2
    ),
});

export const getTotals = ({
    isFreebet,
    betType,
    oddValues,
    stake,
    systemSize,
}: {
    isFreebet: boolean;
    betType: BetType;
    oddValues: number[];
    stake: string | number;
    systemSize: number;
}): Totals => {
    const defaultTotal = {
        totalOdd: null,
        totalStake: Number(stake),
        totalWin: 0,
    };

    if (!stake) return defaultTotal;

    if (oddValues[0] === undefined) return defaultTotal;

    switch (betType) {
        case BetType.Single:
            return formatTotals(
                isFreebet,
                getSingleTotals(oddValues, defaultTotal.totalStake)
            );
        case BetType.Express:
            return formatTotals(
                isFreebet,
                getExpressTotals(oddValues, defaultTotal.totalStake)
            );
        case BetType.System:
            return formatTotals(
                isFreebet,
                getSystemTotals(oddValues, defaultTotal.totalStake, systemSize)
            );

        default:
            return defaultTotal;
    }
};

export const getRestrictionForState = (
    restrictions: Restriction[],
    translate: BetslipTranslate
): BetslipError[] => {
    return restrictions.reduce<BetslipError[]>((acc, restriction) => {
        const { restrictionType, reason } = getRestrictionDetails(restriction);

        let reasonError;

        if (reason?.includes(ResponsibleGamingLimitTypes.GamingTime)) {
            reasonError = ErrorCodes.PlayerLimitCrossedGamingTime;
        }

        if (
            reason?.includes(ResponsibleGamingLimitTypes.PlayerRequiredLimits)
        ) {
            reasonError = ErrorCodes.PlayerLimitCrossedPlayerRequiredLimits;
        }

        const err: BetslipError = {
            type: restrictionType,
            ...('sportEventId' in restriction
                ? { sportEventId: restriction.sportEventId }
                : {}),
            message: translate(
                ErrorCodeMessages[reason || restrictionType] ||
                    ErrorCodeMessages[ErrorCodes.InternalError],
                restriction
            ),
            reason: reasonError,
        };

        acc.push(err);

        return acc;
    }, []);
};

export const getDeclineReason = (
    declineReason: string,
    translate: BetslipTranslate
): string => {
    const declineReasonFallback = declineReason.includes(
        `${ErrorCodes.PlayerLimitCrossed} : [`
    )
        ? translate(ErrorCodeMessages.playerLimitCrossedDefault)
        : declineReason;

    return ErrorCodeMessages[declineReason]
        ? translate(ErrorCodeMessages[declineReason])
        : declineReasonFallback;
};

export const getPlaceBetMeta = (): PlaceBetMeta => {
    const features = FeatureFlagsManager.init().getAll();

    if (typeof features !== 'object') return {};

    const featureFlags = Object.entries(features).map(([name, value]) => ({
        name,
        value: value.toString(),
    }));

    return {
        featureFlags,
    };
};

export const getRestrictionDetails = (
    restriction: Restriction
): {
    restrictionType: RestrictionType;
    reason: string | null;
} => {
    const restrictionType =
        RestrictionsTypenameMapping[
            restriction.__typename as RestrictionsTypenameType
        ];

    const reason =
        'reason' in restriction && restriction.reason
            ? restriction.reason
            : null;

    return { restrictionType, reason };
};

export const redirectByRestriction = (
    restrictionForState: BetslipError[],
    push: RouterPush['push'],
    shouldRedirect?: boolean
): void => {
    if (!shouldRedirect) return;

    const reasonRestriction = restrictionForState.find(
        (restriction) => !!restriction.reason
    );

    if (
        reasonRestriction?.reason ===
        ErrorCodes.PlayerLimitCrossedPlayerRequiredLimits
    ) {
        push({
            hash: ModalsHash.PlayerLimitReached,
            query: {
                type: LIMIT_TYPES.PlayerRequiredLimits,
                trigger: 'bet',
            },
        });
    }

    if (reasonRestriction?.reason === ErrorCodes.PlayerLimitCrossedGamingTime) {
        push({
            hash: ModalsHash.PlayerLimitReached,
            query: {
                type: LIMIT_TYPES.GamingTime,
            },
        });
    }
};
