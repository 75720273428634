import { useGetBannerZones } from 'gql/betting/queries/getBannerZones.bet';
import { useResponsive } from 'layouts/MainLayout/ResponsiveLayoutContext';
import {
    BannerZoneAttributeFilters,
    GetBannerZones,
    InputMaybe,
} from 'types/gql.bet';
import { BannerZonePlatform, BannerZoneSportType } from './constants';

type QueryOptions = ReturnType<
    NonNullable<Parameters<typeof useGetBannerZones>[0]>
>;

type Input = Omit<QueryOptions, 'variables'> & {
    variables?: Pick<
        BannerZoneAttributeFilters,
        'pageLocations' | 'indices' | 'platforms'
    >;
};
type Output = ReturnType<typeof useGetBannerZones>;

const useBannerZones = (options: Input): Output => {
    const { until } = useResponsive();

    const platforms = until('md')
        ? [BannerZonePlatform.MOBILE]
        : [BannerZonePlatform.DESKTOP];

    const {
        pageLocations,
        indices,
        platforms: platformsCustom,
    } = options.variables || {};

    const result = useGetBannerZones(() => ({
        ...options,
        fetchPolicy: options.fetchPolicy || 'cache-only',
        variables: {
            attributes: {
                pageTypes: [BannerZoneSportType.MIX],
                platforms: platformsCustom || platforms,
                pageLocations,
                indices,
            },
        },
        onCompleted: ({ bannerZones }) => {
            if (!options.onCompleted) return;

            const filteredBannerZones = getFilteredBannerZones(
                bannerZones,
                pageLocations,
                indices
            );

            options.onCompleted({ bannerZones: filteredBannerZones });
        },
    }));

    return {
        ...result,
        data: {
            bannerZones: getFilteredBannerZones(
                result.data?.bannerZones,
                pageLocations,
                indices
            ),
        },
    };
};

const getFilteredBannerZones = (
    bannerZones: GetBannerZones['bannerZones'] | undefined,
    pageLocations: InputMaybe<string[]> | undefined,
    indices: InputMaybe<number[]> | undefined
): GetBannerZones['bannerZones'] => {
    if (!bannerZones) return [];

    return bannerZones.filter((zone) => {
        const { pageLocation, index } = zone.attributes;

        return (
            (!indices || indices.includes(index)) &&
            (!pageLocations || pageLocations.includes(pageLocation))
        );
    });
};

export default useBannerZones;
