import { isSsr } from 'utils/isSsr';
import { GTMEvent } from './constants';

interface Input extends Record<string, unknown> {
    event: GTMEvent;
}

export const sendGTM = (payload: Input): void => {
    if (isSsr()) return;

    window.dataLayer?.push(payload);
};
