import { getCookie, getCookies } from 'cookies-next';
import { GetServerSidePropsContext } from 'next';

import { DEFAULT_LOCALE, StorageKey } from 'app-constants';
import { createEndpoint } from './utils/createEndpoint';
import { CmsClientOptions } from './CmsApolloClient';

export default (
    req?: GetServerSidePropsContext['req'],
    locale: string = DEFAULT_LOCALE
): CmsClientOptions => {
    const cookies = getCookies({
        req,
    });

    let headersCookieObj: Record<string | number, unknown> = {};

    if (cookies && cookies.headers) {
        try {
            const headersCookieParse = JSON.parse(cookies.headers);

            headersCookieObj =
                typeof headersCookieParse === 'object'
                    ? headersCookieParse
                    : {};
        } catch (e) {
            console.error(
                `Error: "cookies.headers" ${(e as Error).message}`,
                e
            );
        }
    }

    if (!process.env.CMS_GQL_SSR_ENDPOINT) {
        throw new Error(
            'Error! Env variable "CMS_GQL_SSR_ENDPOINT" is missing'
        );
    }

    const endpoint = createEndpoint({
        endpoint: process.env.CMS_GQL_SSR_ENDPOINT,
        host: req?.headers.host,
        protocol: 'http',
    });

    return {
        locale,
        endpoint,
        isDebugMode: !!getCookie(StorageKey.DEBUG_INFO, { req }),
        headersCookieObj,
        req,
    };
};
