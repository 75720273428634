export enum BonusBubbleType {
    HEADER = 'header',
    LOBBY = 'lobby',
    LOBBY_TOP = 'lobbyTop',
    LOBBY_COUNT = 'lobbyCount',
}

export enum BonusBubbleCategory {
    CASINO = 'casino',
}
