import { getServerPageGetCurrentMirror } from 'gql/cms/queries/getCurrentMirror.cms';
import { WithMainApolloClientContext } from 'services/apollo';
import { GetServerSidePageProps } from 'types';

async function getCurrentMirror(
    ctx: WithMainApolloClientContext,
    headers?: Record<string, any>
): Promise<GetServerSidePageProps> {
    const currentMirror = await getServerPageGetCurrentMirror({
        context: { headers },
    })(ctx);

    return {
        props: {
            gsspData: { currentMirror },
        },
    };
}

export default getCurrentMirror;
