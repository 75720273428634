import { ApolloLink, Observable } from '@apollo/client';

import { AppCache } from 'services/cache';

const makeCacheLink = (locale: string): ApolloLink => {
    return new ApolloLink((operation, forward) => {
        const { operationName, variables } = operation;
        const cache = AppCache.getInstance();
        const { headers } = operation.getContext();

        const cachedQueryOptions = cache.getCachedQueryOptions(operationName);
        const skipCacheByCrmNotification =
            headers && 'X-Crm-Notification-Hash' in headers;

        if (
            !cachedQueryOptions ||
            !cache.isEnabled() ||
            operation.getContext().skipGlobalCache ||
            skipCacheByCrmNotification
        ) {
            return forward(operation);
        }

        const jsonVariables = JSON.stringify(variables);
        const key = `${
            cachedQueryOptions.keyWithLocale ? `${locale}:` : ''
        }${operationName}:${jsonVariables}}`;
        const dataFromCache = cache.get(key);

        if (dataFromCache) {
            return Observable.of({ data: dataFromCache, hasNext: false });
        }

        return forward(operation).map((result) => {
            cache.set(key, result.data);

            return result;
        });
    });
};

export default makeCacheLink;
