import { NetworkError } from '@apollo/client/errors';

import { formatNetworkErrorResult } from 'utils';
import { isLoginBlocked } from './limits';

// First error from backend gql exception
export interface ErrorInput {
    message?: string;
    extensions?: any;
    [key: string]: any;
}

/**
 * Handle backend global exceptions like player limits.
 * Each method should return `true` if catch current error and prevent call next handlers
 */
export default function isHandledBackendLimitExceptions(
    networkError: NetworkError
): boolean {
    if (!(networkError && 'result' in networkError)) return false;

    const error = formatNetworkErrorResult(networkError.result).find(
        (r: Record<string, any>) => r?.errors
    )?.errors[0];

    return isLoginBlocked(error);
}
