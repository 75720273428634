import {
    createContext,
    FC,
    PropsWithChildren,
    useContext,
    useEffect,
    useRef,
} from 'react';
import { compact, values } from 'lodash';

import { POLLING_INTERVAL } from 'app-constants';

export enum PollerName {
    MatchList = 'matchList',
    Categorizer = 'categorizer',
}

type SyncPollingType = Record<PollerName, Function | null>;

const SyncPollingContext = createContext<SyncPollingType>({
    [PollerName.Categorizer]: null,
    [PollerName.MatchList]: null,
});

const SyncPollingProvider: FC<PropsWithChildren> = ({ children }) => {
    const refetchRefs = useRef<SyncPollingType>({
        [PollerName.Categorizer]: null,
        [PollerName.MatchList]: null,
    });

    useEffect(() => {
        const id = setInterval(() => {
            const refetchArray = compact(values(refetchRefs.current));

            if (!refetchArray.length) return;

            refetchArray.forEach((refetch) => {
                refetch();
            });
        }, POLLING_INTERVAL);

        return () => {
            clearInterval(id);
        };
    }, []);

    return (
        <SyncPollingContext.Provider value={refetchRefs.current}>
            {children}
        </SyncPollingContext.Provider>
    );
};

type SetRefetchType = ({ poller }: { poller: Function }) => void;

interface Input {
    pollerName: PollerName;
}

interface Output {
    addPoller: SetRefetchType;
    removePoller: VoidFunction;
}

export const useSyncPolling = ({ pollerName }: Input): Output => {
    const pollerMap = useContext(SyncPollingContext);

    const addPoller: SetRefetchType = ({ poller }) => {
        pollerMap[pollerName] = poller;
    };

    const removePoller = () => {
        pollerMap[pollerName] = null;
    };

    return {
        addPoller,
        removePoller,
    };
};

export default SyncPollingProvider;
