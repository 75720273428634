import type { FC } from 'react';
import cn from 'classnames';

interface Props {
    className?: string;
}

const PackIconStub: FC<Props> = ({ className }) => {
    return <div className={cn('h-6 w-6', className)} />;
};

export default PackIconStub;
