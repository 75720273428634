import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getCookie } from 'cookies-next';

import { CookiesType } from 'app-constants';
import { createEndpoint } from '../cms/utils/createEndpoint';
import { expiredTokenLink, makeLoggerLink } from '../links';
import { wsNormalizationLink } from '../utils';

interface PlatformClientOptions {
    endpoint: string;
}

class PlatformApolloClient extends ApolloClient<any> {
    constructor({ endpoint }: PlatformClientOptions) {
        const logger = makeLoggerLink(process.env.NODE_ENV === 'development');

        const link = ApolloLink.from([
            logger,
            wsNormalizationLink,
            expiredTokenLink,
            new WebSocketLink({
                uri: createEndpoint({
                    endpoint,
                    host: window.location.host,
                    protocol: 'ws',
                }),
                options: {
                    lazy: true,
                    reconnect: true,
                    connectionParams: {
                        'X-Theme': 'ggbetua',
                        'X-Auth-Token': getCookie(CookiesType.AuthToken),
                    },
                },
            }),
        ]);

        super({
            cache: new InMemoryCache(),
            connectToDevTools: true,
            link,
        });
    }
}

export { PlatformApolloClient };
