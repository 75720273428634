import { FC, memo } from 'react';
import cn from 'classnames';
import { useTranslations } from 'next-intl';

import PackIcon, { IconsPack } from 'components/PackIcon';
import type { CategoryIconsPack } from 'components/PackIcon/PackIcon';
import { GetGamesCategories } from 'types/gql.cms';
import CategoryItem from './CategoryItem';

interface Props {
    categories?: GetGamesCategories['gamesCategories'];
}

const FooterCategoriesLinksContent: FC<Props> = ({ categories }) => {
    const t = useTranslations('Footer');

    const list = cn(
        'grid grid-cols-1 fill-primary-orange-toxic sm:grid-cols-2'
    );

    if (!categories) return null;

    return (
        <div className="relative mt-8" data-test="footer-categories-links">
            <div
                className="mb-6 text-md font-bold text-primary-orange-toxic"
                data-test="footer-categories-links_title"
            >
                {t('gincms.footer.title.category')}
            </div>
            <div className={list}>
                {categories.map((category) => {
                    return (
                        <CategoryItem
                            key={category.id}
                            title={category.title}
                            linkHref={category.url}
                            imageId={category.imageId}
                            icon={
                                <PackIcon
                                    id={
                                        category.slug as keyof CategoryIconsPack
                                    }
                                    pack={IconsPack.CategoryIcons}
                                    className="mr-3 fill-primary-orange-toxic"
                                />
                            }
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default memo(FooterCategoriesLinksContent);
