import { MainLayoutType, POLLING_INTERVAL } from 'app-constants';
import { useGetFreebets } from 'gql/betting/queries/getFreebets.bet';
import type { Freebets } from '../types';

interface Input {
    mainLayoutType: MainLayoutType;
}

interface Output {
    freebets: Freebets;
}

const useHeaderBalancesFreebets = ({ mainLayoutType }: Input): Output => {
    const { data: freebetsData } = useGetFreebets(() => ({
        pollInterval: POLLING_INTERVAL,
        variables: {
            filter: {
                onlyActual: true,
            },
        },
        skip: mainLayoutType !== MainLayoutType.Betting,
    }));

    return {
        freebets: freebetsData?.freebets || [],
    };
};

export default useHeaderBalancesFreebets;
