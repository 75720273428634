import { ServerError, ServerParseError } from '@apollo/client';

import formatNetworkErrorResult from 'utils/formatNetworkErrorResult';

const excludeErrorCodes = [
    // [GINFE-5892] It's OK for requests from crawler to news for disabled localizations
    'news.not.found',
];

type ExtensionErrors = Array<{ extensions?: { code?: string } }>;

const isExcludeError = (
    error: Error | ServerError | ServerParseError
): boolean => {
    let isExclude = false;
    const isServerError = 'result' in error;

    if (!isServerError) {
        return isExclude;
    }

    const result = formatNetworkErrorResult(error.result);

    result.forEach((value) => {
        if (isExclude) return;

        const isErrors = typeof value === 'object' && 'errors' in value;

        if (isErrors) {
            const errors = (
                Array.isArray(value.errors) ? value.errors : [value.errors]
            ) as ExtensionErrors;

            isExclude = errors.some(
                ({ extensions }) =>
                    extensions &&
                    extensions.code &&
                    excludeErrorCodes.includes(extensions.code)
            );
        }
    });

    return isExclude;
};

export default isExcludeError;
