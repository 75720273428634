import { GetServerSidePropsContext } from 'next';

import { InjectedContext } from '../index';

const setExtendedContext = (
    context: GetServerSidePropsContext,
    nameProperty: string,
    property: InjectedContext[keyof InjectedContext]
): void => {
    Object.defineProperty(context, nameProperty, {
        enumerable: true,
        configurable: true,
        get() {
            return property;
        },
    });
};

export default setExtendedContext;
