import { PartialMatch } from 'types';
import { CompetitorHomeAway } from 'types/gql.bet';

const isMultiCompetitorsMatch = (
    competitors: PartialMatch['fixture']['competitors']
): boolean => {
    return (
        competitors.length > 2 &&
        competitors.every((c) => c.homeAway === CompetitorHomeAway.Unknown)
    );
};

export default isMultiCompetitorsMatch;
