import { FC } from 'react';
import { useInView } from 'react-intersection-observer';

import { BatchKeys, WidgetNames } from 'app-constants';
import withServerRender from 'hocs/withServerRender';
import {
    WithApolloClientsContext,
    WithMainApolloClientContext,
} from 'services/apollo';
import { GetServerSidePageProps } from 'types';
import { getServerPageGetWidgetByName } from './getWidgetByName.cms';
import useWidget from './useWidget';
import WidgetContent from './WidgetContent';

interface Props {
    name: WidgetNames;
    widgetData?: string | undefined;
    replace?: string;
    replaceValue?: string;
    className?: string;
    fieldKey?: string;
}

const Widget: FC<Props> = ({
    name,
    replace,
    replaceValue,
    className,
    widgetData: initialData,
    fieldKey,
}) => {
    const { ref, inView } = useInView({ triggerOnce: true });

    const { widget } = useWidget({
        name,
        replace,
        replaceValue,
        skip: !inView,
        fieldKey,
    });

    const widgetForRender = widget || initialData;

    return (
        <WidgetContent
            widgetData={widgetForRender}
            ref={ref}
            className={className}
        />
    );
};

export function makeGetServerSidePropsWidgetByName(name: WidgetNames) {
    return async function getWidget(
        ctx: WithMainApolloClientContext | WithApolloClientsContext
    ): Promise<GetServerSidePageProps> {
        const widgetResponse = await getServerPageGetWidgetByName({
            variables: {
                name,
            },
            context: {
                batchKey: BatchKeys.GetWidgetByName,
            },
        })(ctx);

        const widgets = { [name]: widgetResponse };

        return {
            props: {
                gsspData: { widgets },
            },
        };
    };
}

export default withServerRender(Widget, ({ className, widgetData }) => {
    return <WidgetContent className={className} widgetData={widgetData} />;
});
