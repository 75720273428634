import { ApolloLink } from '@apollo/client';
import { context, propagation, trace } from '@opentelemetry/api';

const makeOTELTraceLink = (): ApolloLink => {
    return new ApolloLink((operation, forward) => {
        const span = trace
            .getTracer(process.env.PUBLIC_SERVICE_NAME || 'next-ggbetua-spa')
            .startSpan(operation.operationName);

        return context.with(trace.setSpan(context.active(), span), () => {
            const carrier: Record<string, string> = {};

            propagation.inject(context.active(), carrier);
            const headers = Object.keys(carrier).reduce(
                (acc: Record<string, string>, key) => {
                    const carrierKey = carrier[key];

                    if (carrierKey) {
                        acc[key] = carrierKey;
                    }

                    return acc;
                },
                {}
            );

            operation.setContext((apolloContext: Record<string, any>) => ({
                ...apolloContext,
                headers: {
                    ...headers,
                    ...apolloContext.headers,
                },
            }));

            return forward(operation).map((result) => {
                span.end();

                return result;
            });
        });
    });
};

export default makeOTELTraceLink;
