import type { FC, PropsWithChildren } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { forEach, indexOf, slice } from 'lodash';

import { useIsMobile } from 'components/utilities/MobileDetect';
import { isSsr } from 'utils/isSsr';
import { Sizes } from './constants';
import type { Breakpoints, ResponsiveLayoutContextType } from './types';
import ResponsiveLayoutContext from './useResponsive';
import { createMediaQueries } from './utils';

const ResponsiveLayoutContextProvider: FC<PropsWithChildren> = ({
    children,
}) => {
    const { isMobile } = useIsMobile();

    const mediaQueries = useMemo(() => createMediaQueries(Sizes), []);
    const [breakpoint, setBreakpoint] = useState<Breakpoints>(
        isMobile ? 'xs' : 'lg'
    );

    const makeSetBreakpointHandle = useCallback(
        (breakpointName: Breakpoints) => (event: MediaQueryListEvent) => {
            if (!event.matches) return;

            setBreakpoint(breakpointName);
        },
        []
    );
    const breakpointNames = useMemo(
        () => mediaQueries.map((mediaQuery) => mediaQuery.breakpoint),
        [mediaQueries]
    );

    const is = useCallback(
        (targetBreakpoint: Breakpoints) => {
            return breakpoint === targetBreakpoint;
        },
        [breakpoint]
    );

    const from = useCallback(
        (targetBreakpoint: Breakpoints) => {
            const index = indexOf(breakpointNames, targetBreakpoint);

            if (index < 0) {
                console.error(
                    `${targetBreakpoint.toUpperCase()} is incorrect name of breakpoint`
                );

                return false;
            }

            return slice(breakpointNames, index).includes(breakpoint);
        },
        [breakpoint, breakpointNames]
    );

    const until = useCallback(
        (targetBreakpoint: Breakpoints) => {
            const index = indexOf(breakpointNames, targetBreakpoint);

            if (index < 0) {
                console.error(
                    `${targetBreakpoint.toUpperCase()} is incorrect name of breakpoint`
                );

                return false;
            }

            return slice(breakpointNames, 0, index).includes(breakpoint);
        },
        [breakpoint, breakpointNames]
    );

    const breakpointCompare = useCallback(
        (fromBreakpoint: Breakpoints, untilBreakpoint: Breakpoints) => {
            const fromIndex = indexOf(breakpointNames, fromBreakpoint);
            const untilIndex = indexOf(breakpointNames, untilBreakpoint);

            if (fromIndex > untilIndex) {
                console.error(
                    'fromBreakpoint can not be bigger than untilBreakpoint'
                );
            }
        },
        [breakpointNames]
    );

    const between = useCallback(
        (fromBreakpoint: Breakpoints, untilBreakpoint: Breakpoints) => {
            breakpointCompare(fromBreakpoint, untilBreakpoint);

            return from(fromBreakpoint) && until(untilBreakpoint);
        },
        [breakpointCompare, from, until]
    );

    useEffect(() => {
        if (isSsr()) return;

        forEach(mediaQueries, (mediaQuery) => {
            const mql = window.matchMedia(mediaQuery.query);

            if (mql.matches) {
                setBreakpoint(mediaQuery.breakpoint);
            }

            mql.addEventListener(
                'change',
                makeSetBreakpointHandle(mediaQuery.breakpoint)
            );
        });

        return () => {
            forEach(mediaQueries, (item) => {
                const mql = window.matchMedia(item.query);

                mql.removeEventListener(
                    'change',
                    makeSetBreakpointHandle(item.breakpoint)
                );
            });
        };
    }, [makeSetBreakpointHandle, mediaQueries]);

    const contextValue = useMemo<ResponsiveLayoutContextType>(() => {
        return {
            is,
            until,
            from,
            between,
        };
    }, [between, from, is, until]);

    return (
        <ResponsiveLayoutContext.Provider value={contextValue}>
            {children}
        </ResponsiveLayoutContext.Provider>
    );
};

export default ResponsiveLayoutContextProvider;
