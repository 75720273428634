import { FetchResult, Observable } from '@apollo/client';

export const toPromise = <D>(
    observable: Observable<FetchResult<D>>
): Promise<D> => {
    return new Promise((resolve, reject) => {
        const subscription = observable.subscribe({
            next: (result) => {
                // `as D`, a conscious type that implements an option
                // from the previous implementation of apolloClient.query.
                resolve(result.data as D);
                subscription.unsubscribe();
            },
            error: (err) => {
                reject(err);
                subscription.unsubscribe();
            },
        });
    });
};
