import React from 'react';
import cn from 'classnames';

import PackIcon, { IconsPack } from 'components/PackIcon';
import type { GetSeoRulesByPage } from 'types/gql.cms';

import styles from './footerSeo.module.css';

interface Props {
    texts: NonNullable<GetSeoRulesByPage['seoRulesByPage']['seo']>['texts'];
    pageHeader: string;
    isOpen: boolean;
    onClick?: VoidFunction;
    scrollHandler?: (e: React.SyntheticEvent<HTMLDivElement>) => void;
}

const FooterSeoContent: React.FC<Props> = ({
    texts,
    pageHeader,
    isOpen,
    onClick,
    scrollHandler,
}) => {
    return (
        <div>
            {texts.length > 0 && (
                <div className="relative my-8 font-roboto after:absolute after:-bottom-8 after:h-[1px] after:w-full after:bg-surface-middle">
                    <div
                        className="flex cursor-pointer items-center justify-between text-primary-orange-toxic hover:text-primary-orange-toxic-hover"
                        onClick={onClick}
                    >
                        {pageHeader && (
                            <h1 className="text-md font-bold">{pageHeader}</h1>
                        )}

                        <div className="ml-auto flex h-10 w-10 items-center justify-center">
                            <PackIcon
                                id="branch-expand-less"
                                pack={IconsPack.SpriteIcons}
                                className={cn(
                                    'transition duration-300',
                                    'fill-primary-orange-toxic',
                                    { 'rotate-180': !isOpen }
                                )}
                            />
                        </div>
                    </div>
                    <div
                        className={cn(
                            'mb-8  w-full overflow-y-auto pl-6 text-left transition-all duration-500 lg:max-w-792',
                            isOpen ? 'max-h-[260px]' : 'max-h-0',
                            styles.seo
                        )}
                        onScroll={scrollHandler}
                    >
                        {texts.map(({ content }, index) => (
                            <div
                                /* eslint-disable-next-line react/no-array-index-key */
                                key={index}
                                className={cn(
                                    'relative text-base text-grey-500 transition duration-300',
                                    styles['seo-inner']
                                )}
                                /* eslint-disable-next-line react/no-danger */
                                dangerouslySetInnerHTML={{ __html: content }}
                            />
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default FooterSeoContent;
