import React from 'react';
import cn from 'classnames';
import { get } from 'lodash';

import PackIcon from 'components/PackIcon';
import SmartLink from 'components/SmartLink';
import {
    ButtonIconProps,
    ButtonProps,
    Color,
    LinkIconProps,
    LinkProps,
    Props,
    Size,
    Width,
} from './types';

const colorClasses: Record<Color, { bg: string; outline: string }> = {
    [Color.Green]: {
        bg: 'bg-green-700 hover:bg-green-900 transition-colors',
        outline:
            'shadow-green-700 hover:shadow-green-900 text-green-700 hover:text-green-900 fill-green-700 hover:fill-green-900',
    },
    [Color.Grey]: {
        bg: 'bg-grey-500 hover:bg-grey-700',
        outline:
            'shadow-grey-500 hover:shadow-grey-700 text-grey-500 hover:text-grey-700 fill-grey-700 hover:fill-grey-700',
    },
    [Color.Orange]: {
        bg: 'bg-primary-orange-toxic hover:bg-primary-orange-toxic-hover transition-colors',
        outline:
            'shadow-primary-orange-toxic hover:shadow-primary-orange-toxic-hover text-primary-orange-toxic hover:text-primary-orange-toxic-hover fill-primary-orange-toxic hover:fill-primary-orange-toxic-hover',
    },
    [Color.Surface]: {
        bg: 'bg-surface-light hover:bg-surface-middle transition-colors',
        outline:
            'shadow-surface-light hover:shadow-surface-middle text-surface-light hover:text-surface-middle fill-surface-light hover:fill-surface-middle',
    },
    [Color.SurfaceMiddle]: {
        bg: 'bg-surface-middle hover:bg-surface-middle transition-colors',
        outline:
            'shadow-surface-light hover:shadow-surface-middle text-surface-light hover:text-surface-middle fill-surface-light hover:fill-surface-middle',
    },
    [Color.SurfaceDark]: {
        bg: 'bg-surface-dark hover:bg-surface-middle transition-colors',
        outline:
            'shadow-surface-dark hover:shadow-surface-middle text-surface-dark hover:text-surface-middle fill-surface-dark hover:fill-surface-middle',
    },
    [Color.Turquoise]: {
        bg: 'bg-turquoise-700 hover:bg-turquoise-900 transition-colors',
        outline:
            'shadow-turquoise-700 hover:shadow-turquoise-900 text-turquoise-700 hover:text-turquoise-900 fill-turquoise-700 hover:fill-turquoise-900',
    },
    [Color.Crimson]: {
        bg: 'bg-crimson-500 hover:bg-crimson-700 transition-colors',
        outline:
            'shadow-crimson-500 hover:shadow-crimson-700 text-crimson-500 hover:text-crimson-700 fill-crimson-500 hover:fill-crimson-700',
    },
    [Color.White]: {
        bg: 'bg-primary-white hover:bg-surface-white-middle transition-colors text-primary-black !fill-primary-black',
        outline:
            'shadow-primary-white hover:!bg-surface-light !fill-primary-white !text-primary-white',
    },
};

const widthClasses: Record<Width, string> = {
    [Width.Min]: 'min-w-[240px]',
    [Width.Full]: 'min-w-full',
    [Width.Fit]: 'min-w-fit',
};

function Button(props: ButtonIconProps): JSX.Element;
function Button(props: ButtonProps): JSX.Element;
function Button(props: LinkIconProps): JSX.Element;
function Button(props: LinkProps): JSX.Element;
function Button(props: Props): JSX.Element {
    const {
        children,
        href,
        onClick,
        type,
        color,
        size = Size.Default,
        width = Width.Fit,
        iconId,
        pack,
        iconPosition = '',
        iconClassName = '',
        iconsList,
        isDisabled = false,
        isOutlined = false,
        isLoading = false,
        isRounded = false,
        isTransparent = false,
        isTransparentIcon = false,
        className,
        classNameText,
        dataCategory,
        dataAction,
        dataTest,
        analyticsAttrs,
    } = props;

    const sizeClasses: Record<Size, string> = {
        [Size.ExtraSmall]: cn('h-6', 'px-2'),
        [Size.Small]: cn('h-8', children ? 'px-2' : 'px-1'),
        [Size.Medium]: cn('h-10 px-2'),
        [Size.Default]: cn('h-12 px-3'),
        [Size.Large]: 'h-14 px-4',
    };

    const colors = colorClasses[color || Color.Turquoise];

    const outlinedClasses = cn('!bg-transparent hover:bg-transparent', {
        [colors.outline]: !isDisabled,
    });

    const solidClasses = cn(
        'border-transparent fill-primary-white text-primary-white',
        {
            'active:text-primary-orange-toxic': color === Color.SurfaceDark,
            'hover:fill-grey-300 hover:text-grey-300':
                color === Color.Green || color === Color.Turquoise,
        }
    );

    const transparentClasses = cn(
        'border-transparent fill-turquoise-700 text-turquoise-700 hover:bg-surface-middle hover:fill-turquoise-900'
    );

    const transparentIconClasses = cn(
        'border-transparent fill-turquoise-700 hover:fill-turquoise-900'
    );

    let contentClasses = cn(
        sizeClasses[size],
        widthClasses[width],
        'box-border inline-flex cursor-pointer items-center justify-center rounded-default no-underline shadow-border',
        {
            [cn(colors.bg)]: color,
            [cn(solidClasses)]:
                !isOutlined && !isTransparent && !isTransparentIcon,
            [cn(outlinedClasses)]: isOutlined,
            [cn(transparentClasses)]: isTransparent,
            [cn(transparentIconClasses)]: isTransparentIcon,
            ['pointer-events-none cursor-default']: isLoading,
            ['!rounded-full']: isRounded,
        }
    );

    if (isDisabled) {
        const disabledClasses = cn(
            'disabled:pointer-events-none disabled:cursor-default disabled:fill-grey-700 disabled:text-grey-700',
            {
                ['disabled:bg-transparent disabled:shadow-surface-middle']:
                    isOutlined,
                ['disabled:bg-surface-middle']:
                    (!isOutlined && color) || isTransparent,
            }
        );

        contentClasses = cn(contentClasses, disabledClasses);
    }

    const iconDefaultClasses = cn(
        'order-2 box-border flex h-6 w-6 items-center justify-center'
    );

    const content = (
        <>
            {iconId && isLoading && (
                <div
                    className={cn(
                        'rounded-full border-2 border-b-transparent text-primary-white',
                        'animate-spin',
                        iconDefaultClasses,
                        getLoaderColor(isOutlined, color)
                    )}
                />
            )}

            {!iconId && isLoading && (
                <div
                    className={cn(
                        'rounded-full border-2 border-b-transparent text-primary-white',
                        'absolute animate-spin',
                        iconDefaultClasses,
                        getLoaderColor(isOutlined, color)
                    )}
                />
            )}

            {iconId && pack && !isLoading && (
                <PackIcon
                    id={iconId}
                    pack={pack}
                    className={cn(iconDefaultClasses, {
                        [iconClassName]: iconClassName,
                    })}
                />
            )}

            {iconsList}

            {children && (
                <span
                    className={cn(
                        'truncate',
                        classNameText ||
                            (isTransparent ? 'typo-sm-1' : 'typo-sm-2'),
                        {
                            ['order-3 ml-2']: iconPosition === 'left',
                            ['order-1 mr-2']: iconPosition === 'right',
                            ['text-transparent']: !iconId && isLoading,
                        },
                        classNameText
                    )}
                >
                    {children}
                </span>
            )}
        </>
    );

    if (href) {
        return (
            <SmartLink
                href={href}
                className={cn(contentClasses, className)}
                dataTest={dataTest}
                analyticsAttrs={analyticsAttrs}
            >
                {content}
            </SmartLink>
        );
    }

    if (!type) {
        return (
            <div
                className={cn(contentClasses, className)}
                onClick={onClick}
                data-category={dataCategory}
                data-action={dataAction}
                data-test={dataTest}
            >
                {content}
            </div>
        );
    }

    return (
        <button
            className={cn(contentClasses, className)}
            type={type}
            disabled={isDisabled}
            onClick={onClick}
            data-category={dataCategory}
            data-action={dataAction}
            data-test={dataTest}
        >
            {content}
        </button>
    );
}

function getLoaderColor(isOutlined: boolean, color: Color | undefined): string {
    if (!color) return 'border-surface-middle';

    if (!isOutlined) return 'border-primary-white';

    const loaderColors = {
        [Color.Green]: 'border-green-700',
        [Color.Orange]: 'border-primary-orange-toxic',
    };

    return get(loaderColors, color, 'border-primary-white');
}

Button.Color = Color;
Button.Size = Size;
Button.Width = Width;

export default Button;
