import { useEffect, useState } from 'react';

import { useRouter } from 'components/HybridRouter';
import { Category } from 'services/GoogleAnalytics';

interface Output {
    dataCategory: string;
}

const useHeaderLogo = (): Output => {
    const [dataCategory, setDataCategory] = useState('');

    const { asPath } = useRouter();

    useEffect(() => {
        const defaultCategory = Category.ToHomeFrom;

        if (asPath.includes('#!/auth/register')) {
            setDataCategory(`${defaultCategory} registration`);
        }

        if (asPath.includes('#!/auth/signin')) {
            setDataCategory(`${defaultCategory} login`);
        }
    }, [asPath]);

    return {
        dataCategory,
    };
};

export default useHeaderLogo;
