import { floor, isNaN, round } from 'lodash';

import { OddFormat } from 'app-constants';

export const formatDecimalOdd = (
    coef: string | number,
    oddFormat?: OddFormat | null
): string | number => {
    const value = +coef - 1;

    if (isNaN(value)) return coef;

    switch (oddFormat) {
        case OddFormat.Us: {
            const usValue = value >= 1 ? +(100 * value) : -100 / value;

            return (usValue > 0 ? '+' : '') + round(usValue);
        }

        case OddFormat.HongKong: {
            return floor(value, 3);
        }

        case OddFormat.Indo: {
            const result = value >= 1 ? value : (1 / value) * -1;

            return floor(result, 3);
        }

        case OddFormat.Malay: {
            const result = value <= 1 ? value : (1 / value) * -1;

            return round(result, 3);
        }

        default: {
            return floor(+coef, 2);
        }
    }
};
