import { values } from 'lodash';

import { Primitive } from '../types';

export function checkValueInObj<T extends { [key: string]: Primitive }>(
    obj: T,
    value: Primitive
): value is T[keyof T] {
    return values(obj).includes(value);
}
