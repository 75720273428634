import * as GQL from 'types/gql.bet';

import * as Operations from './onUpdateCricketOverviews.bet.gql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'services/apollo/betting';
const defaultOptions = {} as const;

/**
 * __useOnUpdateCricketOverviews__
 *
 * To run a query within a React component, call `useOnUpdateCricketOverviews` and pass it any options that fit your needs.
 * When your component renders, `useOnUpdateCricketOverviews` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnUpdateCricketOverviews({
 *   variables: {
 *      sportEventIDs: // value for 'sportEventIDs'
 *   },
 * });
 */
export function useOnUpdateCricketOverviews(
    baseOptions: ApolloReactHooks.SubscriptionHookOptions<
        GQL.OnUpdateCricketOverviews,
        GQL.OnUpdateCricketOverviewsVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSubscription<
        GQL.OnUpdateCricketOverviews,
        GQL.OnUpdateCricketOverviewsVariables
    >(Operations.OnUpdateCricketOverviews, options);
}
export type OnUpdateCricketOverviewsHookResult = ReturnType<
    typeof useOnUpdateCricketOverviews
>;
export type OnUpdateCricketOverviewsSubscriptionResult =
    Apollo.SubscriptionResult<GQL.OnUpdateCricketOverviews>;
