import { onError } from '@apollo/client/link/error';

// Client-side reload is chosen due to limitations with the "manual" fetch strategy
// (https://fetch.spec.whatwg.org/#concept-request-redirect-mode) in tracking redirects.
// Reload allows the server to handle the redirect process.

const reloadPageAfterRedirect = onError(({ networkError }) => {
    if (!networkError) return;

    if (
        'response' in networkError &&
        networkError.response.type === 'opaqueredirect'
    ) {
        window.location.reload();
        // eslint-disable-next-line no-console
        console.log(
            '%cRedirect after response',
            'color: red; font-size: 20px;'
        );
    }
});

export default reloadPageAfterRedirect;
