import React from 'react';

import { MainLayoutType, WidgetNames } from 'app-constants';
import { makeGetServerSidePropsWidgetByName } from 'components/Widget';
import { useLayoutContext } from 'layouts/Root/LayoutTypeContext';
import FooterDownloadApp from '../FooterDownloadApp';
import FooterPr, { getFooterPrServerSideProps } from '../FooterPr';
import FooterEmail from './FooterEmail';
import FooterPhones from './FooterPhones';

const FooterSupport: React.FC = () => {
    const { mainLayoutType } = useLayoutContext();

    return (
        <div className="relative mt-8">
            <FooterEmail />
            <FooterPhones />
            {mainLayoutType !== MainLayoutType.Casino && <FooterDownloadApp />}
            <FooterPr />
        </div>
    );
};

export const getFooterSupportServerSideProps = [
    makeGetServerSidePropsWidgetByName(WidgetNames.FOOTER_SUPPORT_EMAIL),
    ...getFooterPrServerSideProps,
];

export default FooterSupport;
