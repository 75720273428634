import { useCallback } from 'react';

import { mainLoaderState } from 'components/MainLoader/mainLoaderState';
import { disableScroll, enableScroll } from 'components/modals/utils';

interface Output {
    showLoader: VoidFunction;
    hideLoader: VoidFunction;
}

const useMainLoader = (): Output => {
    const showLoader = useCallback(() => {
        disableScroll();
        mainLoaderState(true);
    }, []);

    const hideLoader = useCallback(() => {
        enableScroll();
        mainLoaderState(false);
    }, []);

    return {
        showLoader,
        hideLoader,
    };
};

export default useMainLoader;
