import * as GQL from 'types/gql.ptm';

import * as Operations from './onUpdateChangeBalance.ptm.gql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'services/apollo/platform';
const defaultOptions = {} as const;

/**
 * __useOnUpdateChangeBalance__
 *
 * To run a query within a React component, call `useOnUpdateChangeBalance` and pass it any options that fit your needs.
 * When your component renders, `useOnUpdateChangeBalance` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnUpdateChangeBalance({
 *   variables: {
 *   },
 * });
 */
export function useOnUpdateChangeBalance(
    baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
        GQL.OnUpdateChangeBalance,
        GQL.OnUpdateChangeBalanceVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSubscription<
        GQL.OnUpdateChangeBalance,
        GQL.OnUpdateChangeBalanceVariables
    >(Operations.OnUpdateChangeBalance, options);
}
export type OnUpdateChangeBalanceHookResult = ReturnType<
    typeof useOnUpdateChangeBalance
>;
export type OnUpdateChangeBalanceSubscriptionResult =
    Apollo.SubscriptionResult<GQL.OnUpdateChangeBalance>;
