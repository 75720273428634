import { makeVar } from '@apollo/client';

export const isVerificationBannerVisible = makeVar<boolean>(false);
export const verificationBannerHeightVar = makeVar<number>(0);

export const setIsVerificationBannerVisible = (state: boolean): void => {
    isVerificationBannerVisible(state);
};

export const setVerificationBannerHeight = (state: number): void => {
    verificationBannerHeightVar(state);
};
