import type { PartialMatchMarket } from 'types';

import matchBaseFragment from 'gql/betting/fragments/matchBaseFragment.bet.gql';
import { useFragment } from 'hooks/useFragment';
import { useBettingApolloClient } from 'services/apollo';
import type { MatchBase, Odd } from 'types/gql.bet';
import { MarketStatus, OddStatus, SportEventStatus } from 'types/gql.bet';

interface Input {
    odd: Odd | undefined;
    market: PartialMatchMarket | undefined;
    sportEventId: string;
}

interface Output {
    isActiveOdd: boolean;
    sportEvent?: MatchBase;
}

const oddStub = {
    path: '',
    status: '',
    isActive: false,
    id: '',
};
const useCheckOddIsActive = ({ odd, market, sportEventId }: Input): Output => {
    const { client } = useBettingApolloClient();

    const { status: oddStatus, isActive } = odd || oddStub;

    const isOddStub = !odd?.id;
    const isMarketStub = !market?.id;

    const { data: sportEvent } = useFragment<MatchBase>({
        id: sportEventId,
        client,
        typename: 'SportEvent',
        fragment: matchBaseFragment,
        options: {
            skip: isOddStub || isMarketStub,
            returnPartialData: true,
        },
    });

    if (!sportEvent)
        return {
            isActiveOdd: false,
        };

    const { betStop, disabled: sportEventDisabled, fixture } = sportEvent;

    const marketStatus = market?.status;
    const sportEventStatus = fixture?.status;

    const sportEventIsActive =
        (sportEventStatus === SportEventStatus.NotStarted ||
            sportEventStatus === SportEventStatus.Live) &&
        !sportEventDisabled &&
        !betStop;

    const isActiveMarket = MarketStatus.Active === marketStatus;

    const isOddActive = oddStatus === OddStatus.NotResulted && isActive;

    return {
        isActiveOdd: sportEventIsActive && isActiveMarket && isOddActive,
        sportEvent,
    };
};

export default useCheckOddIsActive;
