import React, { memo } from 'react';
import cn from 'classnames';

import { withClientRender } from 'hocs';
import { useClickOutside, useToggle } from 'hooks';
import FooterPhonesHeader from './FooterPhonesHeader';
import FooterPhonesItem from './FooterPhonesItem';
import useFooterPhones from './useFooterPhones';

const FooterPhones: React.FC = () => {
    const { phones, activePhone } = useFooterPhones();

    const [isOpen, toggleDropdown, closeDropdown] = useToggle();

    const clickOutsideRef = useClickOutside(closeDropdown);

    if (!phones || !phones.length || !activePhone?.phone) return null;

    return (
        <div
            className="relative w-full lg:max-w-312"
            ref={clickOutsideRef}
            data-test="footer-phones"
        >
            <FooterPhonesHeader
                activePhone={activePhone}
                clicked={isOpen}
                clickHandler={toggleDropdown}
            />

            <div
                className={cn(
                    {
                        hidden: !isOpen,
                    },
                    'absolute inset-y-auto top-full z-1 mt-2 flex w-full flex-col rounded-default bg-surface-middle text-base text-grey-500'
                )}
            >
                <div
                    className="relative grid grid-cols-1"
                    data-test={`footer-phones ${
                        isOpen ? 'footer-phones--open' : ''
                    }`}
                >
                    {phones.map((p) => (
                        <FooterPhonesItem phone={p} key={p.id} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default memo(withClientRender(FooterPhones));
