import React, { FC } from 'react';
import cn from 'classnames';
import { useTranslations } from 'next-intl';

import Button from 'components/Button';
import PackIcon, { IconsPack } from 'components/PackIcon/PackIcon';
import { useResponsive } from 'layouts/MainLayout/ResponsiveLayoutContext';
import { BannerStates } from '../types';
import useVerificationBannerContent from './useVerificationBannerContent';

interface Props {
    bannerStates: BannerStates;
    closeVerificationBanner: VoidFunction;
}

const VerificationBannerContent: FC<Props> = ({
    bannerStates,
    closeVerificationBanner,
}) => {
    const isMobile = useResponsive().until('sm');
    const t = useTranslations('VerificationBanner');

    const { bannerRef, openKYC } = useVerificationBannerContent();

    const { isFullApproved, isWaitingApproval, isWaitingDocuments } =
        bannerStates;

    return (
        <div
            className={cn('relative z-1 cursor-pointer', {
                '!bg-crimson-900': isWaitingDocuments,
                '!bg-turquoise-900': isWaitingApproval,
                '!bg-green-900': isFullApproved,
            })}
            ref={bannerRef}
            onClick={openKYC}
        >
            <div className="mx-auto flex min-h-[40px] w-90 items-center justify-between py-2">
                <p className="text-base text-primary-white">
                    {isWaitingDocuments &&
                        t('gincms.verification_banner.waiting_documents')}
                    {isWaitingApproval &&
                        t('gincms.verification_banner.waiting_approval')}
                    {isFullApproved &&
                        t('gincms.verification_banner.full_approved')}
                </p>

                {isWaitingDocuments && (
                    <>
                        {isMobile ? (
                            <PackIcon
                                id="branch-chevron-right"
                                pack={IconsPack.SpriteIcons}
                                className="ml-2 shrink-0 fill-primary-white"
                            />
                        ) : (
                            <Button
                                type="button"
                                color={Button.Color.White}
                                size={Button.Size.ExtraSmall}
                                iconId="branch-chevron-right"
                                pack={IconsPack.SpriteIcons}
                                iconPosition="right"
                                className="ml-10 min-w-[128px] justify-between uppercase !text-primary-black [&>a]:text-center [&>span]:flex-1"
                            >
                                {t('gincms.verification_banner.proceed')}
                            </Button>
                        )}
                    </>
                )}
                {isFullApproved && (
                    <PackIcon
                        id="close"
                        pack={IconsPack.ActionIcons}
                        className="ml-2 shrink-0 cursor-pointer fill-primary-white sm:ml-10"
                        onClick={closeVerificationBanner}
                    />
                )}
            </div>
        </div>
    );
};

export default VerificationBannerContent;
