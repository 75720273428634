import type { FC, PropsWithChildren } from 'react';
import { memo, useEffect, useState } from 'react';

import { ClientRenderContext } from './ClientRenderContext';

interface Props extends PropsWithChildren {}

const ClientRenderProvider: FC<Props> = ({ children }) => {
    const [isClientRender, setIsClientRender] = useState<boolean>(false);

    useEffect(() => {
        setIsClientRender(true);
    }, []);

    useEffect(() => {
        if (isClientRender) return;

        document.body.classList.add('pointer-events-none');

        return () => {
            document.body.classList.remove('pointer-events-none');
        };
    }, [isClientRender]);

    return (
        <ClientRenderContext.Provider value={isClientRender}>
            {children}
        </ClientRenderContext.Provider>
    );
};

export default memo(ClientRenderProvider);
