import { useMemo } from 'react';

import { BatchKeys, WidgetNames } from 'app-constants';
import { GetWidgetByName } from '../../types/gql.cms';
import { useGetWidgetByName } from './getWidgetByName.cms';
import { formatWidget } from './utils';

interface Output {
    widget: string;
    fields?: NonNullable<GetWidgetByName['widgetByName']>['fields'];
}

interface Input {
    name: WidgetNames;
    replace?: string;
    replaceValue?: string;
    skip?: boolean;
    fieldKey?: string;
}

const useWidget = ({
    name,
    replace,
    replaceValue,
    skip,
    fieldKey,
}: Input): Output => {
    const { data } = useGetWidgetByName(() => ({
        variables: {
            name,
        },
        pagePropsKey: `widgets.${name}`,
        skip,
        context: {
            batchKey: BatchKeys.GetWidgetByName,
        },
    }));

    const fields = data?.widgetByName?.fields;
    const template = useMemo(() => {
        if (fieldKey) {
            const field = data?.widgetByName?.fields.find(
                (item) => item.key === fieldKey
            );

            return field?.value || '';
        }

        return data?.widgetByName?.template || '';
    }, [fieldKey, data]);

    return {
        widget: formatWidget({ replace, replaceValue, template }),
        fields,
    };
};

export default useWidget;
