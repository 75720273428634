import {
    RefObject,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useReactiveVar } from '@apollo/client';
import { debounce } from 'lodash';

import { useAuthorization } from 'hooks';
import { headerOffsetsVar } from '../headerState';
import { LinkOptions } from '../types';
import {
    initialDefaultMenuLinks as defaultMenuLinks,
    initialHiddenMenuLinks,
    initialMenuLinks,
} from './constants';
import useLinksWithOptions from './useLinksWithOptions';
import useTournamentByFilters from './useTournamentByFilters';
import {
    getChildNodesWidth,
    getElementWidth,
    getInitialMenuLinksWithTournament,
    getMenuLinksCount,
} from './utils';

interface Output {
    menuLinks: LinkOptions[];
    hiddenMenuLinks: LinkOptions[];
    isShowHeaderLinks: boolean;

    menuLinksRef: RefObject<HTMLDivElement>;
    moreRef: RefObject<HTMLDivElement>;
}

const useHeaderBetting = (): Output => {
    const { isAuthorized } = useAuthorization();

    const activeTournamentSlug = useTournamentByFilters();
    const initialMenuLinksWithTournament = useMemo(
        () =>
            getInitialMenuLinksWithTournament(
                initialMenuLinks,
                activeTournamentSlug
            ),
        [activeTournamentSlug]
    );

    const [menuLinks, setMenuLinks] = useState<LinkOptions[]>(
        defaultMenuLinks.concat(initialMenuLinksWithTournament)
    );
    const [hiddenMenuLinks, setHiddenMenuLinks] =
        useState<LinkOptions[]>(defaultMenuLinks);
    const [isShowHeaderLinks, setIsShowHeaderLinks] = useState<boolean>(false);

    const moreRef = useRef<HTMLDivElement>(null);
    const menuLinksRef = useRef<HTMLDivElement>(null);
    const [menuLinksWidth, setMenuLinksWidth] = useState<number[]>([]);

    useEffect(() => {
        if (!menuLinksRef.current || !moreRef.current || menuLinksWidth.length)
            return;

        setMenuLinksWidth(getChildNodesWidth(menuLinksRef.current));
    }, [menuLinksWidth]);

    const { containerOffset, logoOffset, betSettingsOffset, controlsOffset } =
        useReactiveVar(headerOffsetsVar);

    const { menuLinksWithOptions, hiddenMenuLinksWithOptions } =
        useLinksWithOptions({ isAuthorized, menuLinks, hiddenMenuLinks });

    const resizeMenuHandler = useCallback(() => {
        if (!menuLinksRef.current || !moreRef.current) return;

        const moreWidth = getElementWidth(moreRef.current);
        const diff =
            containerOffset -
            logoOffset -
            moreWidth -
            betSettingsOffset -
            controlsOffset;
        const menuLinksCount = getMenuLinksCount(menuLinksWidth, diff);

        if (menuLinksCount === 0) return;

        const absoluteMenuLinksCount = menuLinksCount - defaultMenuLinks.length;

        setMenuLinks([
            ...defaultMenuLinks,
            ...initialMenuLinksWithTournament.slice(0, absoluteMenuLinksCount),
        ]);
        setHiddenMenuLinks([
            ...initialMenuLinksWithTournament.slice(absoluteMenuLinksCount),
            ...initialHiddenMenuLinks,
        ]);
    }, [
        containerOffset,
        logoOffset,
        betSettingsOffset,
        controlsOffset,
        menuLinksWidth,
        initialMenuLinksWithTournament,
    ]);

    useEffect(() => {
        resizeMenuHandler();
        window.addEventListener('resize', resizeMenuHandler);

        return () => window.removeEventListener('resize', resizeMenuHandler);
    }, [resizeMenuHandler]);

    useEffect(() => {
        if (isShowHeaderLinks) return;

        debounce(() => setIsShowHeaderLinks(true), 1200)();
    });

    return {
        moreRef,
        menuLinksRef,
        isShowHeaderLinks,
        menuLinks: menuLinksWithOptions,
        hiddenMenuLinks: hiddenMenuLinksWithOptions,
    };
};

export default useHeaderBetting;
