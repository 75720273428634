import * as GQL from 'types/gql.cms';

import * as Operations from './messageEvent.cms.gql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'services/apollo/cms';
const defaultOptions = {} as const;

export type MessageEventMutationFn = Apollo.MutationFunction<
    GQL.MessageEvent,
    GQL.MessageEventVariables
>;

/**
 * __useMessageEvent__
 *
 * To run a mutation, you first call `useMessageEvent` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMessageEvent` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [messageEvent, { data, loading, error }] = useMessageEvent({
 *   variables: {
 *      messageId: // value for 'messageId'
 *      action: // value for 'action'
 *   },
 * });
 */
export function useMessageEvent(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQL.MessageEvent,
        GQL.MessageEventVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        GQL.MessageEvent,
        GQL.MessageEventVariables
    >(Operations.MessageEvent, options);
}
export type MessageEventHookResult = ReturnType<typeof useMessageEvent>;
export type MessageEventMutationResult =
    Apollo.MutationResult<GQL.MessageEvent>;
export type MessageEventMutationOptions = Apollo.BaseMutationOptions<
    GQL.MessageEvent,
    GQL.MessageEventVariables
>;
