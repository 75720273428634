import { useCallback } from 'react';

import { useResponsive } from 'layouts/MainLayout/ResponsiveLayoutContext';
import { MessageMediaButtonImpl } from 'types/api.cms';
import { Message } from 'types/gql.cms';
import { getMessageCardImg } from '../InboxCenterMessage/helpers';
import { setPreviewMessageVar } from '../inboxCenterState';

interface Input {
    message: Message;
}
interface Output {
    closeMessageHandler: VoidFunction;
    buttonsData: MessageMediaButtonImpl[];
    imgSrc: string;
}

const useInboxCenterNotify = ({ message }: Input): Output => {
    const isMobile = useResponsive().until('md');

    const imgSrc = getMessageCardImg({
        mainImage: message?.media?.mainImage,
        isMobile,
    });

    const buttonsData = message?.media?.buttons || [];

    const closeMessageHandler = useCallback(() => {
        setPreviewMessageVar(null);
    }, []);

    return { closeMessageHandler, imgSrc, buttonsData };
};

export default useInboxCenterNotify;
