import { forEach } from 'lodash';

import { SportEventMetaKey } from 'app-constants';
import { MatchBase } from 'types/gql.bet';

type MatchTrackerOutput = { betradarId: string; lmtCoverageLevel: string };

export const getMatchTrackerByMeta = (
    meta: MatchBase['meta'] | undefined
): MatchTrackerOutput => {
    const result: MatchTrackerOutput = {
        betradarId: '',
        lmtCoverageLevel: '',
    };

    if (!meta) return result;

    forEach(meta, (metaItem) => {
        if (metaItem.name === SportEventMetaKey.BetradarId) {
            result.betradarId = metaItem.value;
        }

        if (metaItem.name === SportEventMetaKey.BetradarLMTCoverageLevel) {
            result.lmtCoverageLevel = metaItem.value;
        }
    });

    return result;
};
