import { makeVar } from '@apollo/client';

import { isSsr } from 'utils/isSsr';

const isOnlineVar = makeVar<boolean>(!isSsr() && window.navigator.onLine);

export const setOnline = (): void => {
    isOnlineVar(true);
};

export const setOffline = (): void => {
    isOnlineVar(false);
};

export default isOnlineVar;
