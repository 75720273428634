import cn from 'classnames';

import useIsRenderBroadcastBanner from './useIsRenderBroadcastBanner';

interface Output {
    height: string;
    position: string;
}

const useGetSidebarHeightAndPositions = (): Output => {
    const isRenderBroadcastBanner = useIsRenderBroadcastBanner();

    return {
        height: cn(
            isRenderBroadcastBanner
                ? 'md:h-[calc(100vh-theme(spacing.header-group-offset)-16px)]'
                : 'md:h-[calc(100vh-theme(spacing.header-group-offset)-32px)]'
        ),
        position: cn(
            'sticky md:top-[calc(theme(spacing.header-group-offset)+16px)]'
        ),
    };
};

export default useGetSidebarHeightAndPositions;
