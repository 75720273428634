import React, { memo } from 'react';
import cn from 'classnames';
import { useTranslations } from 'next-intl';

import { BatchKeys, LayoutType } from 'app-constants';
import { getServerPageGetLocaleMenu } from 'gql/cms/queries/getLocaleMenu.cms';
import { useGsspData } from 'layouts/Root/PagePropsContext';
import { GetServerSidePropsWithMainApolloClient } from 'services/apollo';
import { GetServerSidePageProps } from 'types';
import { MENU_FOOTER_PROVIDERS_ID } from './constants';
import ProviderItem from './ProviderItem';

const FooterProvidersLinks: React.FC = () => {
    const t = useTranslations('Footer');
    const { footerProvidersListByIds } = useGsspData();

    if (!footerProvidersListByIds?.length) return null;

    const list = cn('grid grid-cols-1', {
        'sm:grid-cols-2': LayoutType.Betting,
    });

    return (
        <div className="relative mt-8" data-test="footer-providers-links">
            <div
                className="mb-6 text-md font-bold text-primary-orange-toxic"
                data-test="footer-providers-links__title"
            >
                {t('gincms.footer.title.providers')}
            </div>
            <div className={list}>
                {footerProvidersListByIds.map((item) => (
                    <ProviderItem
                        key={item.imageId}
                        imageId={item.imageId}
                        linkHref={item.url}
                        title={item.anchor}
                    />
                ))}
            </div>
        </div>
    );
};

export const getFooterProvidersLinksSideProps: GetServerSidePropsWithMainApolloClient =
    async (context): Promise<GetServerSidePageProps> => {
        let footerProvidersListByIds;

        try {
            footerProvidersListByIds = await getServerPageGetLocaleMenu(
                async (ctx) => {
                    return {
                        variables: {
                            id: MENU_FOOTER_PROVIDERS_ID,
                            locale: `${ctx.locale}`,
                        },
                        context: {
                            batchKey: BatchKeys.GetLocaleMenu,
                        },
                    };
                }
            )(context);
        } catch (e) {
            console.error(e);
        }

        return {
            props: {
                gsspData: {
                    footerProvidersListByIds:
                        footerProvidersListByIds?.localeMenu?.items || [],
                },
            },
        };
    };

export default memo(FooterProvidersLinks);
