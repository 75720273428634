import { FC } from 'react';

import Image from 'components/Image';
import PackIcon, { IconsPack } from 'components/PackIcon';
import { Message } from 'types/gql.cms';
import { NotificationSystemType } from '../../constants';
import { getMessageIcon, getMessageIconColor } from '../helpers';
import useInboxCenterMessageIcon from './useInboxCenterMessageIcon';

interface Props {
    systemType: string;
    isRead: boolean;
    mainImage: NonNullable<Message['media']>['mainImage'] | undefined;
}

const InboxCenterMessageIcon: FC<Props> = ({
    systemType,
    isRead,
    mainImage,
}) => {
    const { cardImg, messageSystemType } = useInboxCenterMessageIcon({
        systemType,
        mainImage,
    });

    return (
        <div
            className="relative mr-4 flex h-10 w-10 min-w-[40px] items-center justify-center rounded-full bg-surface-dark"
            data-test="inbox-message-icon"
        >
            {cardImg ? (
                <Image
                    src={cardImg}
                    alt="message-icon"
                    width={24}
                    height={24}
                    className="h-6 w-6 object-cover"
                />
            ) : (
                <PackIcon
                    className={getMessageIconColor(messageSystemType)}
                    id={
                        messageSystemType === NotificationSystemType.System
                            ? 'setting'
                            : getMessageIcon(messageSystemType)
                    }
                    pack={
                        messageSystemType === NotificationSystemType.System
                            ? IconsPack.ActionIcons
                            : IconsPack.SpriteIcons
                    }
                />
            )}
            {!isRead && (
                <div
                    className="absolute right-[2px] top-[2px] h-3 w-3 rounded-full border-2 border-surface-middle bg-red-700"
                    data-test="inbox-message-icon__no-read"
                />
            )}
        </div>
    );
};

export default InboxCenterMessageIcon;
