import { ApolloClientContext } from '../types';

export type {
    MutationHookOptions,
    QueryHookOptions,
    SubscriptionHookOptions,
} from '@apollo/client';
export type { ApolloClientContext };

export {
    usePlatformMutation as useMutation,
    usePlatformQuery as useQuery,
    usePlatformSubscription as useSubscription,
} from '../hooks';
export { usePtmApolloClient } from './PlatformApolloProvider';
