import { forEach } from 'lodash';

import { logError } from 'services/logger';
import { GetServerSidePageData } from 'types';
import { allowedMultipleGsspKeys } from '../constants';

const warnOnKeysDuplication = (
    currentProps: Dict,
    props: Dict,
    url: string
): void => {
    if (!currentProps.gsspData) {
        return;
    }

    forEach(currentProps.gsspData, (_, key) => {
        if (
            props?.gsspData?.[key] &&
            !allowedMultipleGsspKeys.has(key as keyof GetServerSidePageData)
        ) {
            logError({
                message: `GSSP Key Conflict: '${key}' appears more than once on '${url}'`,
            });
        }
    });
};

export default warnOnKeysDuplication;
