import { FC, HTMLAttributes, memo, useCallback } from 'react';
import cn from 'classnames';

import { ModalsHash } from 'app-constants';
import useModal from 'components/modals/useModal';
import PackIcon, { IconsPack } from 'components/PackIcon';
import { useClickOutside, useToggle } from 'hooks';
import { useResponsive } from 'layouts/MainLayout/ResponsiveLayoutContext';
import DropdownBalance from './DropdownBalance';
import useHeaderBalance from './useHeaderBalance';

const HeaderBalance: FC<HTMLAttributes<HTMLDivElement>> = ({ className }) => {
    const isMobile = useResponsive().until('md');
    const { openModal } = useModal();
    const playerBalances = useHeaderBalance();
    const [isOpen, toggleDropdown, closeDropdown] = useToggle();
    const clickOutsideRef = useClickOutside(closeDropdown);

    const isShowBalanceDropdown = playerBalances?.isShowBalanceDropdown;

    const popupBalancesHandler = useCallback(() => {
        openModal({ hash: ModalsHash.MyBalance });
    }, [openModal]);

    const toggleHandler = useCallback(() => {
        if (isMobile) {
            popupBalancesHandler();

            return;
        }

        if (!isShowBalanceDropdown) return;

        toggleDropdown();
    }, [isMobile, isShowBalanceDropdown, toggleDropdown, popupBalancesHandler]);

    if (!playerBalances) return null;

    const {
        realBalance,
        totalBalance,
        currencyCode,
        bettingBonuses,
        casinoBonuses,
        instaBonuses,
        liveCasinoBonuses,
        groupBonuses,
        insurances,
        freebets,
    } = playerBalances;

    return (
        <div
            className={cn('md:relative', className)}
            ref={isShowBalanceDropdown ? clickOutsideRef : null}
            data-test="header-balance"
        >
            <div
                className={cn(
                    'flex max-h-8 items-center rounded-default border-default border-surface-light',
                    'transition hover:bg-surface-middle',
                    isShowBalanceDropdown && !isMobile
                        ? 'cursor-pointer py-1 pl-4 pr-2'
                        : 'px-4 py-2'
                )}
                onClick={toggleHandler}
                data-test="header-balance__wrapper"
            >
                <span
                    className="whitespace-nowrap text-primary-white typo-sm-2"
                    data-test="header-balance__total"
                >
                    {totalBalance.toFixed(2)} {currencyCode}
                </span>
                {isShowBalanceDropdown && !isMobile && (
                    <PackIcon
                        id="branch-more-up"
                        key="branch-more-up"
                        className={cn(
                            'ml-1 items-center fill-primary-white transition duration-300',
                            isOpen ? 'fill-primary-orange-toxic' : 'rotate-180'
                        )}
                        pack={IconsPack.SpriteIcons}
                    />
                )}
            </div>

            {isShowBalanceDropdown && isOpen && (
                <DropdownBalance
                    realBalance={realBalance}
                    currencyCode={currencyCode}
                    bettingBonuses={bettingBonuses}
                    casinoBonuses={casinoBonuses}
                    instaBonuses={instaBonuses}
                    liveCasinoBonuses={liveCasinoBonuses}
                    groupBonuses={groupBonuses}
                    insurances={insurances}
                    freebets={freebets}
                />
            )}
        </div>
    );
};

export default memo(HeaderBalance);
