import { debounce } from 'lodash';

import { useRouter, useRouterPush } from 'components/HybridRouter';
import { AppLink } from 'types/applink';

interface Output {
    routerBack: () => void;
}

const innerRoutes: Record<string, AppLink> = {
    [AppLink.ESPORTS_RESULT_SLUG]: AppLink.RESULTS,
    [AppLink.ESPORTS_OUTRIGHT_SLUG]: AppLink.OUTRIGHTS,
    [AppLink.SPORTS_RESULT_SLUG]: AppLink.RESULTS,
    [AppLink.SPORTS_OUTRIGHT_SLUG]: AppLink.OUTRIGHTS,
};

const useRouterBack = (fallbackUrl?: string): Output => {
    const { back, pathname } = useRouter();
    const { push } = useRouterPush();
    const urlToRedirect = fallbackUrl || AppLink.ROOT;

    const routerBack = debounce(() => {
        if (window.history.state.idx) {
            back();

            return;
        }

        for (const route of Object.keys(innerRoutes)) {
            if (pathname.includes(route)) {
                push(innerRoutes[route]);

                return;
            }
        }

        push(urlToRedirect);
    }, 350);

    return {
        routerBack,
    };
};

export default useRouterBack;
