import React from 'react';

import { Feature, useFeatureFlags } from 'services/features';

const withFeatureFlag = <T extends {}>(Component: React.FC<T>, flag: Feature) =>
    function WithFeatureFlag(props: T): JSX.Element | null {
        const { features } = useFeatureFlags();
        const isRenderComponent = features.isEnabled(flag);

        if (!isRenderComponent) return null;

        return <Component {...props} />;
    };

export default withFeatureFlag;
