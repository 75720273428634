import type { PublicEnv } from 'types';

import { ENV_KEYS } from 'app-constants';
import { WithApolloClientsContext } from 'services/apollo';
import { logError } from 'services/logger';
import { captureSentryException } from 'services/sentry';

export type EnvValues = PublicEnv | { [x: string]: string | boolean };

interface Output {
    env: EnvValues;
}

export const getInitialServerSideProps = (
    context?: WithApolloClientsContext
): Output => {
    const envValues = ENV_KEYS.reduce<
        PublicEnv | { [x: string]: string | boolean }
    >((acc, key) => {
        const keyValue = process.env[key];

        if (!keyValue) {
            logError({
                message: `Missing env value for: ${key}`,
                err: key,
                request: context?.req,
            });

            captureSentryException({
                label: 'Setup Browser Env',
                message: `Missing env value for: ${key}`,
            });

            return {
                ...acc,
                [key]: false,
            };
        }

        return { ...acc, [key]: keyValue };
    }, {});

    return {
        env: envValues,
    };
};
