import { RefObject, useEffect, useRef } from 'react';
import { useReactiveVar } from '@apollo/client';
import Headroom from 'headroom.js';

import { appBannerVisibleVar } from 'components/AppBanner/appBannerState';
import { verificationBannerHeightVar } from 'components/VerificationBanner/verificationBannerState';
import { useIsRenderBroadcastBanner, useMounted } from 'hooks';
import { useResponsive } from 'layouts/MainLayout/ResponsiveLayoutContext';
import { isHeaderPinned } from './headerState';

interface Output {
    headerRef: RefObject<HTMLDivElement>;
}

// NOTE remember value of these variables are equal tailwind config but in px
export const HEADER_HEIGHT = 48;
export const BROADCAST_BANNER_HEIGHT = 40;
export const APP_BANNER_HEIGHT = 68;
export const headerHeightCssVariable = '--header-height';

const useHeader = (): Output => {
    const verificationBannerHeight = useReactiveVar(
        verificationBannerHeightVar
    );
    const headerRef = useRef<HTMLDivElement>(null);

    const isRenderBroadcastBanner = useIsRenderBroadcastBanner();
    const showAppBanner = useReactiveVar(appBannerVisibleVar);
    const headroomRef = useRef<Headroom | null>(null);
    const { from } = useResponsive();
    const isMounted = useMounted();

    useEffect(() => {
        if (!headerRef.current || !isMounted) return;

        if (from('md')) {
            if (!headroomRef.current) return;

            // NOTE This need works because init() in Headroom is executed with a setTimeout of 100ms
            // line 230 /node_modules/headroom.js/dist/headroom.js
            setTimeout(() => {
                headroomRef.current?.destroy();
            }, 101);
        } else if (!headroomRef.current) {
            headroomRef.current = new Headroom(headerRef.current, {
                tolerance: {
                    down: 10,
                    up: 20,
                },
                offset: 100,
                onPin: () => {
                    isHeaderPinned(true);
                },
                onUnpin: () => {
                    isHeaderPinned(false);
                },
            });

            headroomRef.current.init();
        }
    }, [from, isMounted]);

    useEffect(() => {
        const headerGroupHeightObj = {
            header: HEADER_HEIGHT,
            broadcastBanner: isRenderBroadcastBanner
                ? BROADCAST_BANNER_HEIGHT
                : 0,
            appBanner: showAppBanner ? APP_BANNER_HEIGHT : 0,
            verificationBanner: verificationBannerHeight,
        };

        const headerGroupHeight = Object.values(headerGroupHeightObj).reduce(
            (sum, el) => sum + el,
            0
        );

        document.documentElement.style.setProperty(
            headerHeightCssVariable,
            `${headerGroupHeight}px`
        );
    }, [isRenderBroadcastBanner, showAppBanner, verificationBannerHeight]);

    useEffect(() => {
        return () => {
            document.documentElement.style.removeProperty(
                headerHeightCssVariable
            );
        };
    }, []);

    return {
        headerRef,
    };
};

export default useHeader;
