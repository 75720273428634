import React, { forwardRef } from 'react';

type Props = {
    widgetData: string | undefined;
    className?: string;
};

const WidgetContent = forwardRef<HTMLDivElement, Props>(
    ({ className, widgetData = '' }, ref) => {
        return (
            <div
                ref={ref}
                className={className}
                /* eslint-disable-next-line react/no-danger */
                dangerouslySetInnerHTML={{ __html: widgetData }}
                data-test="widget-content"
            />
        );
    }
);

WidgetContent.displayName = 'WidgetContent';

export default WidgetContent;
