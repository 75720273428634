import { useMemo } from 'react';
import { parse, ParsedUrlQuery } from 'querystring';

import { useRouter } from 'components/HybridRouter';

interface Output {
    modalPath: string;
    pathname: string;
    query: ParsedUrlQuery;
}

const useRouterData = (): Output => {
    const { asPath, query, asPathObj } = useRouter();

    const pathname = asPathObj?.pathname || asPath.replace(/[?#].*$/, '');
    const hashWithQuery = asPath.split('#!')[1] || '';
    const [modalPath, hashQueries] = hashWithQuery.split('?');
    const parserQuery = useMemo(() => parse(hashQueries), [hashQueries]);

    return {
        modalPath,
        pathname,
        query: {
            ...query,
            ...parserQuery,
        },
    };
};

export default useRouterData;
