import { IncomingMessage } from 'http';

import { logError, ResponseError } from 'services/logger';
import { captureSentryException } from 'services/sentry';

interface Input<T extends unknown[]> {
    promises: Array<Promise<T[number]> | null>;
    label: string;
    errorMessage: string;
    request?: IncomingMessage;
    skipSentry?: boolean;
}

const getSettledResult = async <T extends unknown[] = never>({
    promises,
    label,
    errorMessage,
    request,
    skipSentry,
}: Input<T>): Promise<T | null[]> => {
    const settledResults = await Promise.allSettled(promises);

    return settledResults.map((result) => {
        if (result.status === 'fulfilled') {
            return result.value;
        }

        if (result.status === 'rejected') {
            logError<ResponseError['error']>({
                message: errorMessage,
                err: result.reason,
                request,
            });

            captureSentryException({
                label,
                message: errorMessage,
                additionalData: { reason: result.reason },
                skip: skipSentry,
            });
        }

        return null;
    }) as T | null[];
};

export default getSettledResult;
