import React, { CSSProperties, PropsWithChildren, useMemo } from 'react';
import { Link as ReactRouterLink, useInRouterContext } from 'react-router-dom';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';

import { formatUrl, useRouter } from 'components/HybridRouter';
import {
    checkLocaleInUrl,
    isLocaleDifferentFromLocalizedUrl,
    removeLocaleFromUrl,
} from 'components/HybridRouter/utils';
import { isSsr } from 'utils/isSsr';

interface Props
    extends Pick<NextLinkProps, 'href' | 'shallow' | 'scroll' | 'onClick'> {
    className?: string;
    dataTest?: string;
    linkAttributes?: React.HTMLAttributes<any>;
    analyticsAttrs?: object;
    style?: CSSProperties;
    state?: {
        fromUrl: string;
    };
}

const SmartLink: React.FC<PropsWithChildren<Props>> = (props) => {
    const {
        children,
        className,
        linkAttributes,
        analyticsAttrs,
        href,
        shallow,
        onClick,
        dataTest,
        style,
        state,
    } = props;
    // Corrected link for cases containing locale
    let purifiedHref = href;

    const { locale } = useRouter();
    // NOTE: we use the useInRouterContext here so that the logic works after the first render
    const isClientRouter = useInRouterContext();

    const isLocalizedUrl = useMemo(() => checkLocaleInUrl(href), [href]);

    if (isSsr() || !isClientRouter) {
        // Running on server or at first render with Next.js
        return (
            <NextLink
                href={href}
                shallow={shallow}
                prefetch={false}
                className={className}
                style={style}
                {...linkAttributes}
                {...analyticsAttrs}
                data-test={dataTest}
                onClick={onClick}
            >
                {children}
            </NextLink>
        );
    }

    // In the case when we receive url: string and url contains locale
    if (isLocalizedUrl) {
        // Compare the current locale with the locale in the url,
        // send the link bypassing the React Router
        if (isLocaleDifferentFromLocalizedUrl(locale, href)) {
            return (
                <a
                    href={String(href)}
                    className={className}
                    style={style}
                    {...linkAttributes}
                    {...analyticsAttrs}
                    data-test={dataTest}
                    onClick={onClick}
                >
                    {children}
                </a>
            );
        }

        // Otherwise, delete the locale that received from the server
        purifiedHref = removeLocaleFromUrl(String(href));
    }

    // Running on client with React Router
    return (
        <ReactRouterLink
            to={formatUrl(purifiedHref)}
            className={className}
            style={style}
            onClick={onClick}
            {...linkAttributes}
            {...analyticsAttrs}
            data-test={dataTest}
            state={state}
        >
            {children}
        </ReactRouterLink>
    );
};

export default SmartLink;
