interface Input {
    defaultAttribute: string;
    propsAttribute?: string;
    isActive?: boolean;
}

export const getTransformedDataTestId = ({
    defaultAttribute,
    propsAttribute,
    isActive,
}: Input): string => {
    const activeAttr = !isActive
        ? defaultAttribute
        : `${defaultAttribute} ${defaultAttribute}--active`;

    return `${activeAttr} ${propsAttribute || ''}`.trim();
};
