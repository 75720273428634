import { MutableRefObject, useEffect, useRef } from 'react';

function usePreviousRef<T>(value: T): MutableRefObject<T | undefined> {
    const ref = useRef<T>();

    useEffect(
        () => () => {
            ref.current = value;
        },
        [value]
    );

    return ref;
}

export default usePreviousRef;
