import { FC } from 'react';
import cn from 'classnames';
import { useTranslations } from 'next-intl';

import MenuTabDesktop from 'components/MenuTabDesktop/MenuTabDesktop';
import PackIcon, {
    IconsPack,
    NavigationIconsPack,
} from 'components/PackIcon/PackIcon';
import { useActiveLink } from 'hooks';
import HeaderMore from '../HeaderMore';
import useHeaderResizeObserver from '../useHeaderResizeObserver';
import { HEADER_BETTING_SETTINGS_ID } from './constants';
import useHeaderBetting from './useHeaderBetting';

const HeaderBetting: FC = () => {
    const {
        menuLinks,
        hiddenMenuLinks,
        isShowHeaderLinks,
        menuLinksRef,
        moreRef,
    } = useHeaderBetting();
    const { checkActiveLink } = useActiveLink();
    const t = useTranslations('HeaderNavigation') as any;

    const { elementRef: betSettingsRef } =
        useHeaderResizeObserver<HTMLDivElement>({
            elementName: 'betSettingsOffset',
        });

    return (
        <>
            <div
                className={cn('mr-auto flex transition-all duration-200', {
                    'opacity-0': !isShowHeaderLinks,
                })}
                data-test="header-betting"
            >
                <div
                    ref={menuLinksRef}
                    className={cn('flex', {
                        'w-[162px] overflow-auto': !isShowHeaderLinks,
                    })}
                    data-test="header-betting__menu"
                >
                    {menuLinks.map(({ textKey, link, iconId }) => (
                        <div
                            key={textKey}
                            data-test="header-betting__menu-item"
                        >
                            <MenuTabDesktop
                                dataTest="header-betting__item"
                                id={textKey}
                                actionHandler={link}
                                iconsLeft={
                                    iconId ? (
                                        <PackIcon
                                            pack={IconsPack.NavigationIcons}
                                            id={
                                                iconId as keyof NavigationIconsPack
                                            }
                                            className="mr-1"
                                        />
                                    ) : undefined
                                }
                                className="mr-2"
                                isSelected={checkActiveLink(link)}
                            >
                                {t(`gincms.menu_item.${textKey}`)}
                            </MenuTabDesktop>
                        </div>
                    ))}
                </div>
                <div
                    ref={moreRef}
                    className="flex"
                    data-test="header-betting__rest"
                >
                    <HeaderMore
                        linksArray={hiddenMenuLinks}
                        checkActiveLink={checkActiveLink}
                    />
                </div>
            </div>
            <div ref={betSettingsRef} id={HEADER_BETTING_SETTINGS_ID} />
        </>
    );
};

export default HeaderBetting;
