import { isArray } from 'lodash';

type TValue<T> = T | null | undefined;

const getArrayValue = <T>(value: TValue<T> | T[]): T[] => {
    if (!value) {
        return [];
    }

    return isArray(value) ? value : [value];
};

export default getArrayValue;
