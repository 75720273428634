import { logError } from 'services/logger';

type FetchUrl = RequestInfo | URL;

export const fetchWithTimeout = async (
    url: FetchUrl,
    options: RequestInit = {},
    timeout = 5000
): Promise<Response> => {
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), timeout);

    return fetch(url, { ...options, signal: controller.signal })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            if (error.name === 'AbortError') {
                logError({
                    message: error.name,
                    err: error,
                });
            }

            throw error;
        })
        .finally(() => clearTimeout(timeoutId));
};
