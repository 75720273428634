import { RefObject, useCallback } from 'react';

import { useResizeObserver } from 'hooks';
import { HeaderOffsets, headerOffsetsVar } from './headerState';

interface HeaderResizeObserverOptions {
    widthType: 'offsetWidth' | 'clientWidth';
}

interface Input {
    elementName: keyof HeaderOffsets;
    options?: HeaderResizeObserverOptions;
}

interface Output<T> {
    elementRef: RefObject<T>;
}

export default function useHeaderResizeObserver<T extends HTMLElement>({
    elementName,
    options,
}: Input): Output<T> {
    const { widthType = 'offsetWidth' } = options || {};

    const elementRef = useResizeObserver<T>(
        useCallback(
            (target) => {
                headerOffsetsVar({
                    ...headerOffsetsVar(),
                    [elementName]: target[widthType],
                });
            },
            [elementName, widthType]
        )
    );

    return {
        elementRef,
    };
}
