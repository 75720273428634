import { useMemo } from 'react';

import { DynamicPageEntityType, LayoutType } from 'app-constants';
import { getDynamicPagePathWithoutMatchListParams } from 'components/DynamicPage/utils';
import { BETTING_MATCH_LIST_PATTERN } from 'components/StaticPages/constants';
import { getTransformedContentWithoutProvider } from 'components/StaticPages/StaticPageCasino/utils';
import { useGetDynamicRouteByPath } from 'gql/cms/queries/getDynamicRouteByPath.cms';
import { useGetStaticPageData } from 'gql/cms/queries/getStaticPageData.cms';
import { useLocales, useRouterData, useRouterQuery } from 'hooks';

interface Output {
    isShowDynamicPageFooterSeo: boolean;
}

export const useShowDynamicPageFooterSeo = (): Output => {
    const { pages } = useRouterQuery();
    const { pathname } = useRouterData();
    const { activeLocale } = useLocales();
    const formattedPath = getDynamicPagePathWithoutMatchListParams(pathname);

    const isDynamicPage = !!pages;

    const { data } = useGetDynamicRouteByPath(() => ({
        variables: { path: `/${activeLocale}${formattedPath}` },
        fetchPolicy: 'cache-only',
        pagePropsKey: 'dynamicRouteByPath',
        skip: !isDynamicPage,
    }));

    const entityInfo = data?.dynamicRouteByPath.entity?.info;
    const infoEntityType = entityInfo?.entityType as DynamicPageEntityType;

    const { pageLayoutType, ids } = useMemo(() => {
        if (entityInfo?.__typename === 'PageLocalizedRoutingRelatedEntity') {
            return {
                pageLayoutType: entityInfo.entity.layoutType as LayoutType,
                ids: [entityInfo.id],
            };
        }

        return { ids: [] };
    }, [entityInfo]);

    const { data: staticPageData } = useGetStaticPageData(() => ({
        variables: {
            ids,
        },
        skip: !ids.length || !isDynamicPage,
        pagePropsKey: 'staticPageData',
        fetchPolicy: 'cache-only',
    }));

    const currentPage = staticPageData?.pagesByIds?.[0];

    const {
        patternsValues: { sportId },
    } = useMemo(
        () =>
            getTransformedContentWithoutProvider<{ sportId: RegExp }>({
                content: currentPage?.content,
                patterns: { sportId: BETTING_MATCH_LIST_PATTERN },
            }),
        [currentPage?.content]
    );

    const hideFooterSeo =
        isDynamicPage &&
        infoEntityType === DynamicPageEntityType.PAGE &&
        (pageLayoutType === LayoutType.Casino || !!sportId);

    return {
        isShowDynamicPageFooterSeo: !hideFooterSeo,
    };
};
