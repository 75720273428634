/* eslint-disable class-methods-use-this */
import { Feature } from 'services/features/constants';
import FeatureFlagsManager from './FeatureFlagsManager';
import {
    FeatureFlags,
    FeatureType,
    Options,
    ParticipantFeature,
} from './types';

class FeatureFlagsManagerSsr extends FeatureFlagsManager {
    private promiseFeatureFlags?: Promise<FeatureFlags>;

    private featureFlagsResolveFn?: (value: FeatureFlags) => void;

    public static init(): FeatureFlagsManagerSsr {
        return new FeatureFlagsManagerSsr();
    }

    public parseAndSetFeatures(
        input: {
            features: FeatureType[];
            participantFeatures: ParticipantFeature[];
        },
        options?: Options
    ): FeatureFlagsManager {
        super.parseAndSetFeatures(input, options);

        if (this.featureFlagsResolveFn && this.featureFlags) {
            this.featureFlagsResolveFn(this.featureFlags);
        }

        return this;
    }

    public isEnabled(): boolean {
        throw new Error(
            'For GSSP functions, the isEnabled method should be disabled; use the isEnabledSsr method instead'
        );
    }

    public async isEnabledSsr(featureName: Feature): Promise<boolean> {
        if (this.featureFlags) {
            return this.featureFlags[featureName];
        }

        if (!this.promiseFeatureFlags) {
            this.promiseFeatureFlags = new Promise((resolve) => {
                this.featureFlagsResolveFn = resolve;
            });
        }

        const featureFlags = await this.promiseFeatureFlags;

        return featureFlags[featureName];
    }
}

export default FeatureFlagsManagerSsr;
