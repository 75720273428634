import { ModalsHash } from 'app-constants';
import useModal from 'components/modals/useModal';
import { useOnUpdateBonusTransfer } from 'gql/platform/subscriptions/onUpdateBonusTransfer.ptm';
import { useAuthorization } from 'hooks';

const useOnUpdateBonusTransferSubscription = (): void => {
    const { isAuthorized } = useAuthorization();
    const { openModal } = useModal();

    useOnUpdateBonusTransfer({
        onData({ data: { data } }) {
            if (!data?.bonusTransfer) return;

            openModal({ hash: ModalsHash.BonusTransfer });
        },
        skip: !isAuthorized,
    });
};

export default useOnUpdateBonusTransferSubscription;
