import { hasCookie } from 'cookies-next';
import { isEmpty, keys, pull, reduce } from 'lodash';
import { GetServerSidePropsContext } from 'next';

import refCodeTracker from './refCodeTracker';

export enum TrackerCookieKey {
    Source = 'source',
    ClickId = 'clickId',
    RedeemCode = 'redeemCode',
    ReferInvite = 'referInvite',
    NetreferTag = 'netreferTag',
    PostmanUuid = 'postmanUuid',
    Promo = 'promo',
    TrackerAutotest = 'tracker_autotest',
    ActionPay = 'actionPay',
    AffData = 'affdata',
    LandingId = 'landingId',
    FirstEntrypoint = 'first_entrypoint',
    RefCode = 'refCode',
    CrmAuthHash = 'crm_auth_hash',
    CrmNotification = 'crm_notification',
    MarketingFlow = 'marketing_flow',
}

export enum TrackerRequestKey {
    Source = 'source',
    ClickId = 'clickid',
    RedeemCode = 'redeem_code',
    ReferInvite = 'refer_invite',
    BTag = 'btag',
    Uuid = 'uuid',
    Promo = 'promo',
    TrackerAutotest = 'tracker_autotest',
    ActionPay = 'actionpay',
    AffData = 'affdata',
    Landing = 'landing',
    LandingId = 'landingId',
    Landing_id = 'landing_id',
    LandingID = 'landingID',
    Landingid = 'Landingid',
    Ref = 'ref',
    RefCode = 'refCode',
    Ref_code = 'ref_code',
    CrmAuthHash = 'crm_auth_hash',
    CrmNotification = 'crm_notification',
    Flw = 'flw',
}

export enum BackendTrackerName {
    Uuid = 'uuid',
    FirstEntrypoint = 'first_entrypoint',
}

export const Trackers: Record<string, string> = {
    [TrackerRequestKey.Source]: TrackerCookieKey.Source,
    [TrackerRequestKey.ClickId]: TrackerCookieKey.ClickId,
    [TrackerRequestKey.RedeemCode]: TrackerCookieKey.RedeemCode,
    [TrackerRequestKey.ReferInvite]: TrackerCookieKey.ReferInvite,
    [TrackerRequestKey.BTag]: TrackerCookieKey.NetreferTag,
    [TrackerRequestKey.Uuid]: TrackerCookieKey.PostmanUuid,
    [TrackerRequestKey.Promo]: TrackerCookieKey.Promo,
    [TrackerRequestKey.ActionPay]: TrackerCookieKey.ActionPay,
    [TrackerRequestKey.AffData]: TrackerCookieKey.AffData,
    [TrackerRequestKey.Landing]: TrackerCookieKey.LandingId,
    [TrackerRequestKey.LandingID]: TrackerCookieKey.LandingId,
    [TrackerRequestKey.LandingId]: TrackerCookieKey.LandingId,
    [TrackerRequestKey.Landing_id]: TrackerCookieKey.LandingId,
    [TrackerRequestKey.Landingid]: TrackerCookieKey.LandingId,
    [TrackerRequestKey.Ref]: TrackerCookieKey.RefCode,
    [TrackerRequestKey.RefCode]: TrackerCookieKey.RefCode,
    [TrackerRequestKey.Ref_code]: TrackerCookieKey.RefCode,
    [TrackerRequestKey.CrmAuthHash]: TrackerCookieKey.CrmAuthHash,
    [TrackerRequestKey.CrmNotification]: TrackerCookieKey.CrmNotification,
    [TrackerRequestKey.Flw]: TrackerCookieKey.MarketingFlow,
};

type Data = Record<string, string>;

const createCookieFromAttachedQuery = ({
    query,
    key,
    cookieObject,
    queryKeys,
}: {
    queryKeys: string[];
    query: Data;
    key: string;
    cookieObject: Data;
}) => {
    const subKeyRegExp = /\[(.*?)\]/;
    const primaryKey = key.replace(subKeyRegExp, '');
    const match = key.match(subKeyRegExp);

    if (queryKeys.includes(primaryKey) || !match) {
        return cookieObject;
    }

    const subkey = match[1];

    const trackerKey = Trackers[primaryKey];
    const queryKey = query[key];

    const shouldSetLandingId =
        primaryKey === TrackerRequestKey.AffData &&
        [
            TrackerRequestKey.Landing,
            TrackerRequestKey.LandingID,
            TrackerRequestKey.LandingId,
            TrackerRequestKey.Landing_id,
            TrackerRequestKey.Landingid,
        ].includes(subkey as TrackerRequestKey);

    return {
        ...cookieObject,
        ...(shouldSetLandingId && { [TrackerCookieKey.LandingId]: queryKey }),
        [trackerKey]: cookieObject[trackerKey]
            ? `${cookieObject[trackerKey]}&${subkey}=${queryKey}`
            : `${subkey}=${queryKey}`,
    };
};

const processTrackerData = (query: Data, isAuth: boolean): Data => {
    if (isEmpty(query)) return {};

    const queryKeys = keys(query);

    if (isAuth) {
        pull(queryKeys, TrackerRequestKey.CrmNotification);
    }

    return reduce(
        queryKeys,
        (acc: Data, key) => {
            const trackerCookieKey = Trackers[key];

            if (!trackerCookieKey) {
                const hasSubkey = key.includes(`[`);

                if (!hasSubkey) return acc;

                return createCookieFromAttachedQuery({
                    query,
                    cookieObject: acc,
                    key,
                    queryKeys,
                });
            }

            acc[trackerCookieKey] = query[key];

            return acc;
        },
        {}
    );
};

const createCookieTracker = (
    query: Data,
    isAuth: boolean,
    req: GetServerSidePropsContext['req']
): Data => {
    const cookiesForTracker = processTrackerData(query, isAuth);

    if (
        cookiesForTracker[TrackerCookieKey.Source] &&
        !cookiesForTracker[TrackerCookieKey.RefCode]
    ) {
        cookiesForTracker[TrackerCookieKey.RefCode] =
            cookiesForTracker[TrackerCookieKey.Source];
    }

    const refCodeTrackerCookie = hasCookie(TrackerCookieKey.RefCode, { req })
        ? null
        : refCodeTracker({ referer: req.headers?.referer });

    if (!cookiesForTracker[TrackerCookieKey.RefCode] && refCodeTrackerCookie) {
        cookiesForTracker[TrackerCookieKey.RefCode] = refCodeTrackerCookie;
    }

    return cookiesForTracker;
};

export default createCookieTracker;
