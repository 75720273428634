import { useCallback, useMemo } from 'react';
import { includes } from 'lodash';

import { ModalsHash } from 'app-constants';
import useModal from 'components/modals/useModal';
import { getTransformedDataTestId } from 'utils';

export type ClickHandlerType = (id: any) => void | object;

export type IconType = JSX.Element | JSX.Element[];

interface Input {
    id?: string;
    dataTest?: string;
    link?: string | object;
    clickHandler?: ClickHandlerType;
}

interface Output {
    isModal: boolean;
    mainDataTestAttr: string;
    onClickHandler: VoidFunction;
    openModalHandler: VoidFunction;
}

const useListItemMobile = ({
    dataTest,
    link,
    clickHandler,
    id,
}: Input): Output => {
    const { openModal } = useModal();

    const onClickHandler = useCallback(() => {
        if (typeof clickHandler !== 'function') return;

        clickHandler(id);
    }, [clickHandler, id]);

    const openModalHandler = useCallback(
        () => openModal({ hash: link as ModalsHash }),
        [openModal, link]
    );

    const mainDataTestAttr = useMemo(
        () =>
            getTransformedDataTestId({
                defaultAttribute: 'list-item-mobile',
                propsAttribute: dataTest,
            }),
        [dataTest]
    );
    const isModal = useMemo(() => includes(ModalsHash, link), [link]);

    return {
        isModal,
        mainDataTestAttr,
        onClickHandler,
        openModalHandler,
    };
};

export default useListItemMobile;
