import { CSSProperties, FC } from 'react';
import cn from 'classnames';
import { includes } from 'lodash';
import { useTranslations } from 'next-intl';

import Button from 'components/Button';
import Image from 'components/Image';
import { NotificationSystemType } from 'components/inboxCenter/constants';
import useModal from 'components/modals/useModal';
import PackIcon, { IconsPack } from 'components/PackIcon/PackIcon';
import { SvgIconSize } from 'components/SvgIcon';
import withClientMessages from 'hocs/withClientMessages';
import { useClickOutside } from 'hooks';
import { useResponsive } from 'layouts/MainLayout/ResponsiveLayoutContext';
import {
    ALBERT_HELLO,
    albertPose,
    FullPopupButtonTypes,
    TransparentButtonTypes,
} from './constants';
import useEuroPopup from './useEuroPopup';

interface Props {
    type: NotificationSystemType;
    content: string;
    buttonAction?: VoidFunction;
    buttonText?: string;
    buttonUrl?: string;
    title?: string;
    image?: string;
    onClose: VoidFunction;
    onClickOutside?: VoidFunction;
    style?: CSSProperties;
}

const gradientDivider = cn(
    'after:content relative after:absolute after:inset-x-0 after:bottom-0 after:h-[1px] after:bg-gradient-to-r after:from-surface-dark after:via-primary-white after:to-surface-dark'
);

const gradientRightCn = cn(
    'after:content after:absolute after:inset-y-0 after:right-[-1px] after:z-1 after:h-full after:w-[1px] after:bg-gradient-to-b after:from-primary-white after:via-surface-dark after:to-primary-white'
);

const gradientLeftCn = cn(
    'after:content before:absolute before:inset-y-0 before:left-[-1px] before:z-1 before:h-full before:w-[1px] before:bg-gradient-to-b before:from-primary-white before:via-surface-dark before:to-primary-white'
);

const EuroPopup: FC<Props> = ({
    buttonAction,
    content,
    title,
    type,
    onClose,
    buttonText,
    buttonUrl,
    image,
    onClickOutside,
    style,
}) => {
    const { showImage, showPopup } = useEuroPopup();
    const { closeModal } = useModal();
    const modalRef = useClickOutside(onClickOutside || onClose || closeModal);

    const isMobile = useResponsive().until('sm');
    const imageSize = isMobile ? 205 : 240;
    const isOnboardingPopup = type === NotificationSystemType.EuroOnboarding;

    const t = useTranslations('betting-euro.euro');
    const titleText = title || t('modals.euro_intro.title');

    return (
        <div
            style={style}
            className={cn(
                'fixed inset-y-0 left-0 z-90 flex w-full justify-center backdrop-blur-sm'
            )}
        >
            <div
                ref={modalRef}
                className={cn(
                    'absolute bottom-8 left-1/2 z-2 w-90 max-w-[340px] -translate-x-1/2 sm:bottom-20 sm:max-w-[500px]',
                    {
                        'bottom-0': isOnboardingPopup,
                    }
                )}
            >
                {showImage && (
                    <Image
                        src={image || albertPose[type] || ALBERT_HELLO}
                        alt="underlay-img"
                        width={imageSize}
                        height={imageSize}
                        className="absolute -right-4 bottom-[calc(100%-32px)] -z-1 sm:-right-20"
                    />
                )}
                <div
                    className={cn(
                        'duration-500 ease-in-out',
                        showPopup ? 'translate-x-0' : 'translate-x-[300%]'
                    )}
                >
                    {isOnboardingPopup ? (
                        <div
                            className="absolute right-4 top-2 z-1 cursor-pointer text-grey-300 typo-sm-1 hover:text-grey-500"
                            onClick={onClose}
                        >
                            {t('modals.euro_onboarding.skipBtn')}
                        </div>
                    ) : (
                        <PackIcon
                            id="close"
                            pack={IconsPack.ActionIcons}
                            className="absolute right-1 top-1 z-1 cursor-pointer fill-primary-white backdrop-blur-none"
                            size={SvgIconSize.WH_4}
                            onClick={onClose}
                        />
                    )}
                    <div
                        className={cn(
                            'flex flex-col gap-4 border-default border-primary-white bg-surface-dark/90 p-4 text-primary-white backdrop-blur-[2px]',
                            gradientRightCn,
                            gradientLeftCn
                        )}
                    >
                        <div
                            className={cn(
                                'flex justify-center break-words pb-1 text-primary-white typo-md-2',
                                gradientDivider
                            )}
                        >
                            {titleText}
                        </div>

                        <div className="text-center text-grey-300 typo-base">
                            {content}
                        </div>
                        {includes(FullPopupButtonTypes, type) && (
                            <div onClick={onClose}>
                                <Button
                                    href={buttonUrl || ''}
                                    width={Button.Width.Full}
                                    color={Button.Color.Orange}
                                    className="h-8"
                                >
                                    {buttonText}
                                </Button>
                            </div>
                        )}
                        {includes(TransparentButtonTypes, type) && (
                            <Button
                                width={Button.Width.Full}
                                isTransparent={true}
                                className="h-8"
                                classNameText={cn('text-grey-300 typo-sm-1')}
                                onClick={onClose}
                            >
                                {buttonText}
                            </Button>
                        )}
                        {isOnboardingPopup && (
                            <Button
                                width={Button.Width.Full}
                                isTransparent={true}
                                className="h-8"
                                classNameText={cn('text-grey-300 typo-sm-1')}
                                onClick={buttonAction}
                            >
                                {buttonText}
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withClientMessages(EuroPopup, ['betting-euro']);
