import { concat, forEach } from 'lodash';

export function combine<T>(arr: T[], n: number): T[][] {
    const combinations: T[][] = [];

    if (arr.length < n) return [];

    if (n === arr.length) return [arr];

    if (n === 1) return arr.map((value) => [value]);

    forEach(arr, (value, i) => {
        const newArray = arr.slice(i + 1);
        const nextCombs = combine(newArray, n - 1);
        nextCombs.forEach((c) => combinations.push(concat(value, c)));
    });

    return combinations;
}
