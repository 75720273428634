import { useCallback, useMemo } from 'react';
import cn from 'classnames';
import { includes } from 'lodash';

import { ModalsHash } from 'app-constants';
import useModal from 'components/modals/useModal';
import { Id } from 'types';
import { getTransformedDataTestId } from 'utils';

export type ActionHandlerType = string | object | ((id: Id) => void);

export type IconType = JSX.Element | JSX.Element[];

interface Input {
    id: Id;
    className?: string;
    dataTest?: string;
    isSelected?: boolean;
    iconsLeft?: IconType;
    iconsRight?: IconType;
    actionHandler?: ActionHandlerType;
}

interface Output {
    isModal: boolean;
    menuTabClasses: string;
    dataTestAttributes: string;
    handleOnClick: VoidFunction;
    openModalHandler: VoidFunction;
}

const useMenuTabDesktop = ({
    actionHandler,
    id,
    iconsLeft,
    iconsRight,
    className,
    dataTest,
    isSelected,
}: Input): Output => {
    const { openModal } = useModal();

    const handleOnClick = useCallback(() => {
        if (typeof actionHandler !== 'function') return;

        actionHandler(id);
    }, [actionHandler, id]);

    const openModalHandler = useCallback(
        () => openModal({ hash: actionHandler as ModalsHash }),
        [openModal, actionHandler]
    );

    const getItemPaddings = useCallback(() => {
        if (iconsLeft && iconsRight) return 'p-1';

        if (iconsLeft) return 'py-1 pr-2 pl-1';

        if (iconsRight) return 'py-1 pl-2 pr-1';

        return 'p-2';
    }, [iconsLeft, iconsRight]);

    const menuTabClasses = useMemo(
        () =>
            cn(
                'flex cursor-pointer items-center rounded-default',
                'hover:bg-surface-middle active:fill-primary-orange-toxic active:text-primary-orange-toxic',
                isSelected
                    ? 'fill-primary-orange-toxic text-primary-orange-toxic'
                    : 'fill-primary-white text-primary-white',
                { [`${className}`]: className },
                getItemPaddings()
            ),
        [isSelected, className, getItemPaddings]
    );

    const dataTestAttributes = useMemo(
        () =>
            getTransformedDataTestId({
                propsAttribute: dataTest,
                defaultAttribute: 'menu-tab-desktop',
                isActive: isSelected,
            }),
        [dataTest, isSelected]
    );

    const isModal = useMemo(
        () => includes(ModalsHash, actionHandler),
        [actionHandler]
    );

    return {
        isModal,
        dataTestAttributes,
        menuTabClasses,
        handleOnClick,
        openModalHandler,
    };
};

export default useMenuTabDesktop;
