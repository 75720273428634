import { useState } from 'react';
import { getCookie } from 'cookies-next';

import { CookiesType, LayoutType } from 'app-constants';
import { useNotificationsInfo } from 'hooks';
import { Message } from 'types/gql.cms';

interface Input {
    isRegistrationFinished: boolean;
    oddIds: string[];
    hash?: string;
    setCurrentMessage: (message: Message) => void;
    type: LayoutType;
    skip?: boolean;
}

const useInboxPreviewLoginMessage = ({
    isRegistrationFinished,
    oddIds,
    hash,
    setCurrentMessage,
    type,
    skip = false,
}: Input): void => {
    const [canLoadMessagesInfo, setCanLoadMessageInfo] = useState<boolean>(() =>
        Boolean(getCookie(CookiesType.InBoxPreviewLogin))
    );

    useNotificationsInfo({
        skip: !isRegistrationFinished || !canLoadMessagesInfo || skip,
        onCompleted: ({ messagesInfo }) => {
            const { message } = messagesInfo;

            if (!message) {
                throw new Error('[useNotificationsInfo] no message');
            }

            setCanLoadMessageInfo(false);

            if (oddIds.length || hash || type === LayoutType.Game) return;

            setCurrentMessage(message);
        },
    });
};

export default useInboxPreviewLoginMessage;
