import { GetKycaidVerifications } from 'gql/cms/queries/kyc/getKycaidVerifications.cms.gql';
import { useOnKycaidChangeVerificationStatus } from 'gql/platform/subscriptions/onKycaidChangeVerificationStatus.ptm';
import { useAuthorization } from 'hooks';
import { useCmsApolloClient } from 'services/apollo';

const useOnKycaidChangeVerificationStatusSubscription = (): void => {
    const { isAuthorized } = useAuthorization();
    const { client } = useCmsApolloClient();

    useOnKycaidChangeVerificationStatus({
        onData() {
            client.refetchQueries({
                include: [GetKycaidVerifications],
            });
        },
        skip: !isAuthorized,
    });
};

export default useOnKycaidChangeVerificationStatusSubscription;
