import type { PropsWithChildren } from 'react';
import { useMemo } from 'react';
import type { PublicEnv } from 'types';

import { APP_ENV_STATUS } from 'utils/verify-environment';
import { appEnvCache } from './context';

type InputType = PropsWithChildren<{ envs: Partial<PublicEnv> }>;
type OutputType = JSX.Element;

export const AppEnvProvider = (props: InputType): OutputType => {
    const { children, envs } = props;

    const appEnv = useMemo(() => {
        return {
            APP_ENV: {
                isDevelopment: envs?.APP_ENV === APP_ENV_STATUS.DEVELOPMENT,
                isProduction: envs?.APP_ENV === APP_ENV_STATUS.PRODUCTION,
            },
        };
    }, [envs]);

    return (
        <appEnvCache.Provider value={appEnv}>{children}</appEnvCache.Provider>
    );
};
