import React, { PropsWithChildren, useContext, useMemo, useRef } from 'react';

import { PageProps } from 'types';
import { isSsr } from 'utils/isSsr';
import StubApolloClient from '../utils/stubs/StubApolloClient';
import { createEndpoint } from './utils/createEndpoint';
import { CmsApolloClient, CmsClientOptions } from './CmsApolloClient';

interface ApolloClients {
    client: CmsApolloClient;
}

const ApolloClientContext = React.createContext<ApolloClients>({
    client: undefined as any,
});

// NOTE: We need this global ref to avoid ApolloClient re-init because of Root unmount
let clientBrowserRef: CmsApolloClient | undefined;

export const getBrowserCmsClient = (): typeof clientBrowserRef =>
    clientBrowserRef;

export const CmsApolloProvider: React.FC<
    PropsWithChildren<{ pageProps: PageProps }>
> = ({ children, pageProps }) => {
    const client = useRef(
        isSsr()
            ? new StubApolloClient('Cms')
            : clientBrowserRef || createClientWithCache(pageProps)
    );

    const value = useMemo(
        () => ({
            client: client.current,
        }),
        []
    );

    return (
        <ApolloClientContext.Provider value={value}>
            {children}
        </ApolloClientContext.Provider>
    );
};

function createClientWithCache(pageProps: PageProps): CmsApolloClient {
    const { env, cmsClientOptions, host } = pageProps;

    const isBrowser = !isSsr();

    if (isBrowser) {
        if (!env.PUBLIC_CMS_GQL_CLIENT_ENDPOINT) {
            throw new Error(
                'Error! Env variable "PUBLIC_CMS_GQL_CLIENT_ENDPOINT" is missing in pageProps'
            );
        }
    }

    const options: CmsClientOptions = isBrowser
        ? {
              ...cmsClientOptions,
              endpoint: createEndpoint({
                  endpoint: env.PUBLIC_CMS_GQL_CLIENT_ENDPOINT,
                  host: window.location.host,
                  protocol: 'http',
              }),
          }
        : {
              ...cmsClientOptions,
              endpoint: createEndpoint({
                  endpoint: process.env.CMS_GQL_SSR_ENDPOINT,
                  host,
                  protocol: 'http',
              }),
          };

    const apolloClient = new CmsApolloClient(options);

    if (isBrowser) {
        clientBrowserRef = apolloClient;
    }

    return apolloClient;
}

export const useCmsApolloClient = (): ApolloClients =>
    useContext(ApolloClientContext);
