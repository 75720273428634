import { useMemo } from 'react';
import cn from 'classnames';

import SmartLink from 'components/SmartLink';
import { getTransformedDataTestId } from 'utils';
import { ButtonProps, LinkProps, Props } from './types';

const itemClasses = cn(
    'flex w-full items-center justify-between rounded-default uppercase typo-sm-2 hover:bg-surface-middle'
);

function BalanceItem(props: LinkProps): JSX.Element;
function BalanceItem(props: ButtonProps): JSX.Element;
function BalanceItem(props: Props): JSX.Element {
    const {
        balance,
        classNames,
        href,
        onClick,
        leftIcon,
        rightIcon,
        currency,
        title,
        dataTest,
    } = props;

    const balanceItemAttr = useMemo(
        () =>
            getTransformedDataTestId({
                defaultAttribute: 'balance-item',
                propsAttribute: dataTest,
            }),
        [dataTest]
    );

    const content = useMemo(() => {
        return (
            <>
                <div
                    className={cn(
                        'flex items-center fill-grey-500 p-1 text-grey-500'
                    )}
                    data-test="balance-item__info"
                >
                    {leftIcon}
                    <span
                        className={cn('ml-1', classNames?.title)}
                        data-test="balance-item__info-title"
                    >
                        {title}
                    </span>
                </div>
                <div
                    className={cn(
                        'flex items-center justify-center fill-primary-white p-2 text-primary-white',
                        classNames?.balance
                    )}
                    data-test="balance-item__balance"
                >
                    {balance} {currency} {rightIcon}
                </div>
            </>
        );
    }, [balance, classNames, currency, leftIcon, rightIcon, title]);

    if (href) {
        return (
            <SmartLink
                href={href}
                className={itemClasses}
                dataTest={balanceItemAttr}
            >
                {content}
            </SmartLink>
        );
    }

    return (
        <div
            className={itemClasses}
            onClick={onClick}
            data-test={balanceItemAttr}
        >
            {content}
        </div>
    );
}

export default BalanceItem;
