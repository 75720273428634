import React from 'react';
import cn from 'classnames';

import Image from 'components/Image';
import SmartLink from 'components/SmartLink';

interface Props {
    title: string;
    linkHref: string;
    imageId: string;
    icon?: JSX.Element | null;
}

const CategoryItem: React.FC<Props> = ({ title, linkHref, imageId, icon }) => {
    const link = cn(
        'flex max-w-fit items-center py-1 pl-1 pr-2 text-base text-primary-white hover:bg-surface-middle'
    );

    return (
        <SmartLink
            href={linkHref}
            className={link}
            dataTest={`category-item category-item--${title}`}
        >
            {imageId && (
                <div className="mr-3 h-6 w-6">
                    <Image
                        src={`/file/${imageId}`}
                        alt=""
                        unoptimized={true}
                        width={24}
                        height={24}
                    />
                </div>
            )}
            {!imageId && icon}
            {title}
        </SmartLink>
    );
};

export default CategoryItem;
