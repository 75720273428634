import { FC, useEffect } from 'react';

import { WidgetNames } from 'app-constants';
import Widget from 'components/Widget';
import { withClientRender } from 'hocs';

const FooterProviders: FC = () => {
    useEffect(() => {
        const offsetAnchor = () => {
            if (location.hash.length !== 0) {
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
        };

        window.addEventListener('hashchange', offsetAnchor);

        return () => {
            window.removeEventListener('hashchange', offsetAnchor);
        };
    }, []);

    return (
        <Widget
            name={WidgetNames.FOOTER_PROVIDERS}
            className="-mx-4 flex flex-wrap border-b-default border-solid border-surface-middle pb-10 pt-8 lg:pb-4"
        />
    );
};

export default withClientRender(FooterProviders);
