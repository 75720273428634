import { useOnKycChangeDocumentRequiredStatus } from 'gql/platform/subscriptions/onKYCChangeDocumentRequiredStatus.ptm';
import { useAuthorization } from 'hooks';
import { useCmsApolloClient } from 'services/apollo';
import { RequiredDocumentStatusTypeEnum } from 'types/gql.cms';

const useOnKycChangeDocumentRequiredStatusSubscription = (): void => {
    const { isAuthorized } = useAuthorization();
    const { client } = useCmsApolloClient();

    useOnKycChangeDocumentRequiredStatus({
        onData({ data: { data } }) {
            const { status = '' } = data?.KYCChangeDocumentRequiredStatus || {};

            client.cache.modify({
                fields: {
                    requiredDocumentsStatus(cachedRequiredDocumentsStatus) {
                        return {
                            ...cachedRequiredDocumentsStatus,
                            status: getRequiredDocumentStatus(status),
                        };
                    },
                },
            });
        },
        skip: !isAuthorized,
    });
};

const getRequiredDocumentStatus = (
    status: string
): RequiredDocumentStatusTypeEnum => {
    switch (status) {
        case 'waiting_approval':
            return RequiredDocumentStatusTypeEnum.StatusWaitingApproval;
        case 'waiting_documents':
            return RequiredDocumentStatusTypeEnum.StatusWaitingDocuments;
        case 'waiting_request':
        default:
            return RequiredDocumentStatusTypeEnum.StatusWaitingRequest;
    }
};

export default useOnKycChangeDocumentRequiredStatusSubscription;
