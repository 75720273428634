import { useRef } from 'react';
import { dequal } from 'dequal';

type Cache<T, K> = { value: T; key?: K };

/**
 *
 * This hook can be used to memoize any value (except function)
 * when used with a single argument.
 *
 * Also there is another option to provide a lazy evaluated memo function
 * that recalculates only when the cache key changes.
 * The key is passed as a second argument and can be of any type (array, object, Map etc).
 *
 */

type Fn<T = any> = () => T;

export function useDeepMemo<T extends (...args: any[]) => any>(value: T): void;
export function useDeepMemo<T extends object | undefined | null>(value: T): T;
export function useDeepMemo<T extends Fn, K>(memoFn: T, key: K): ReturnType<T>;
export function useDeepMemo<T, K>(memoFnOrValue: Fn<T> | T, key?: K): T {
    const ref = useRef<Cache<T, K>>();

    if (isMemoFn(memoFnOrValue, key)) {
        if (!ref.current || !dequal(key, ref.current.key)) {
            ref.current = { value: memoFnOrValue(), key };
        }
    } else if (!ref.current || !dequal(memoFnOrValue, ref.current.value)) {
        ref.current = { value: memoFnOrValue };
    }

    return ref.current.value;
}

function isMemoFn<T, K>(
    memoFnOrValue: Fn<T> | T,
    key?: K
): memoFnOrValue is () => T {
    return typeof memoFnOrValue === 'function' && typeof key !== 'undefined';
}

export default useDeepMemo;
