import { useCallback } from 'react';
import { removeCookies } from 'cookies-next';

import { AppLink, CookiesType } from 'app-constants';
import { useRouter } from 'components/HybridRouter';
import { removePreviewCookies } from 'components/inboxCenter/helpers';
import useAuthorization from 'hooks/useAuthorization';

interface Output {
    logoutHandler: VoidFunction;
}

const useLogout = (): Output => {
    const { handleLogout } = useAuthorization();
    const { locale } = useRouter();

    const logoutHandler = useCallback(() => {
        handleLogout();

        removeCookies(CookiesType.Paseto);
        removeCookies(CookiesType.AuthToken);
        removePreviewCookies();

        window.location.assign(`/${locale}${AppLink.ROOT}`);
    }, [handleLogout, locale]);

    return {
        logoutHandler,
    };
};

export default useLogout;
