import { ErrorCodes as AppErrorCodes } from 'app-constants';
import { BetStatus, RestrictionType } from 'types/gql.bet';

export const RestrictionBlacklist = [
    RestrictionType.BetDelay,
    RestrictionType.SelectionValue,
    RestrictionType.SportEventStatus,
    RestrictionType.SportEventBetStop,
    RestrictionType.MarketStatus,
    RestrictionType.OddStatus,
    RestrictionType.NotEnoughBalance,
];

export const ErrorStatuses = [
    BetStatus.Declined,
    BetStatus.PlaceError,
    BetStatus.ForcedDeclined,
];

type BetslipTranslateKeys = IntlMessages['betting-betslip']['betslip'];

type RestrictionErrorMessagesType =
    | {
          [
              key: string
          ]: `betslip.OddContainer.${keyof BetslipTranslateKeys['OddContainer']}`;
      }
    | {
          [key: string]:
              | `betslip.Odd.Error.${keyof BetslipTranslateKeys['Odd']['Error']}`
              | any;
      };

export enum BetslipStatus {
    Initial = 'Initial',
    Processing = 'Processing',
    Error = 'Error',
    Success = 'Success',
}

export enum ErrorCodes {
    NotEnoughBalance = 'not_enough_balance',
    TicketWasRejected = 'Ticket was rejected',
    ResponseTimedOut = 'Response timed out',
    PlayerIp = '[player_ip]: This is not a valid IP address.',
    BetOnDifferentProviders = 'Bet on different providers',
    MinimalStakeIs = '[stake]: Minimal stake is: ',
    PlaceRetryLimitReached = 'place_retry_limit_reached',
    IdenticalBetIsProcessing = 'identical_bet_is_processing',
    BetForThisPlayerIsForbidden = 'Bet for this player is forbidden.',
    PlayerLimitCrossed = 'player_limit_crossed',
    PlayerLimitCrossedBettingBlocked = 'player_limit_crossed : [betting-blocked]',
    PlayerLimitCrossedGamingTime = 'player_limit_crossed : [gaming-time]',
    PlayerLimitCrossedPlayerRequiredLimits = 'player_limit_crossed : [player-required-limits]',
    PlayerLimitCrossedCps2CanGamble = 'player_limit_crossed : [cps2-can-gamble]',
    PlayerLimitCrossedKycSimpleVerified = 'player_limit_crossed : [kyc-simple-verified]',
    PlayerLimitCrossedKycVerified = 'player_limit_crossed : [kyc-verified]',
    PlayerLimitCrossedPlayerRequiredRequisites = 'player_limit_crossed : [player-required-requisites]',
    PlayerLimitCrossedDeKycDeposit = 'player_limit_crossed : [de-kyc-deposit]',
    PlayerLimitCrossedTermsAndConditions = 'player_limit_crossed : [terms-and-conditions]',
    WagerLimitReached = 'Wager limit you have set has been already reached',
    InternalError = 'internal_error',
    FreebetAlreadyUsed = 'already_used',
    FreebetExpired = 'freebet_expired',
    FreebetInvalidStatus = 'invalid_status',
    FreebetNotFound = 'freebet_not_found',
    FreebetNotApplicableContext = 'not_applicable_context',
    PlayerLimitCrossedDefault = 'playerLimitCrossedDefault',
}

export enum RestrictionsTypename {
    BaselineSelectionExistenceRestriction = 'BaselineSelectionExistenceRestriction',
    CashOutBetSelectionsMismatchRestriction = 'CashOutBetSelectionsMismatchRestriction',
    BetDelayRestriction = 'BetDelayRestriction',
    BetIntervalRestriction = 'BetIntervalRestriction',
    BetProviderRestriction = 'BetProviderRestriction',
    BetSelectionExistenceRestriction = 'BetSelectionExistenceRestriction',
    BetStatusRestriction = 'BetStatusRestriction',
    BetTournamentDuplicateCompetitorIDsRestriction = 'BetTournamentDuplicateCompetitorIDsRestriction',
    BetTournamentIncompatibleMarketIDsRestriction = 'BetTournamentIncompatibleMarketIDsRestriction',
    BetTypeRestriction = 'BetTypeRestriction',
    CashOutAmountLimitRestriction = 'CashOutAmountLimitRestriction',
    CashOutBetTypeRestriction = 'CashOutBetTypeRestriction',
    CashOutOrderFreebetRestriction = 'CashOutOrderFreebetRestriction',
    CashOutOrderStatusRestriction = 'CashOutOrderStatusRestriction',
    CashOutRefundAmountRestriction = 'CashOutRefundAmountRestriction',
    CashOutUnavailableRestriction = 'CashOutUnavailableRestriction',
    MarketDefectiveRestriction = 'MarketDefectiveRestriction',
    MarketExistenceRestriction = 'MarketExistenceRestriction',
    MarketStatusRestriction = 'MarketStatusRestriction',
    MaxBetRestriction = 'MaxBetRestriction',
    MinBetRestriction = 'MinBetRestriction',
    NotEnoughBalanceRestriction = 'NotEnoughBalanceRestriction',
    OddExistenceRestriction = 'OddExistenceRestriction',
    OddStatusRestriction = 'OddStatusRestriction',
    PlayerLimitRestriction = 'PlayerLimitRestriction',
    SelectionValueRestriction = 'SelectionValueRestriction',
    SportEventBetStopRestriction = 'SportEventBetStopRestriction',
    SportEventExistenceRestriction = 'SportEventExistenceRestriction',
    SportEventStatusRestriction = 'SportEventStatusRestriction',
    SportEventTypeRestriction = 'SportEventTypeRestriction',
}

export type RestrictionsTypenameType = keyof typeof RestrictionsTypename;

export const RestrictionsTypenameMapping: Record<
    RestrictionsTypenameType,
    RestrictionType
> = {
    [RestrictionsTypename.BaselineSelectionExistenceRestriction]:
        RestrictionType.BaselineSelectionExistence,
    [RestrictionsTypename.CashOutBetSelectionsMismatchRestriction]:
        RestrictionType.BetCashOutSelectionsMismatch,
    [RestrictionsTypename.BetDelayRestriction]: RestrictionType.BetDelay,
    [RestrictionsTypename.BetIntervalRestriction]: RestrictionType.BetInterval,
    [RestrictionsTypename.BetProviderRestriction]: RestrictionType.BetProvider,
    [RestrictionsTypename.BetSelectionExistenceRestriction]:
        RestrictionType.BetSelectionExistence,
    [RestrictionsTypename.BetStatusRestriction]: RestrictionType.BetStatus,
    [RestrictionsTypename.BetTournamentDuplicateCompetitorIDsRestriction]:
        RestrictionType.BetTournamentDuplicateCompetitorIds,
    [RestrictionsTypename.BetTournamentIncompatibleMarketIDsRestriction]:
        RestrictionType.BetTournamentIncompatibleMarketIds,
    [RestrictionsTypename.BetTypeRestriction]: RestrictionType.BetType,
    [RestrictionsTypename.CashOutAmountLimitRestriction]:
        RestrictionType.CashOutAmountLimit,
    [RestrictionsTypename.CashOutBetTypeRestriction]:
        RestrictionType.CashOutBetType,
    [RestrictionsTypename.CashOutOrderFreebetRestriction]:
        RestrictionType.CashOutOrderFreebet,
    [RestrictionsTypename.CashOutOrderStatusRestriction]:
        RestrictionType.CashOutOrderStatus,
    [RestrictionsTypename.CashOutRefundAmountRestriction]:
        RestrictionType.CashOutRefundAmount,
    [RestrictionsTypename.CashOutUnavailableRestriction]:
        RestrictionType.CashOutUnavailable,
    [RestrictionsTypename.MarketDefectiveRestriction]:
        RestrictionType.MarketDefective,
    [RestrictionsTypename.MarketExistenceRestriction]:
        RestrictionType.MarketExistence,
    [RestrictionsTypename.MarketStatusRestriction]:
        RestrictionType.MarketStatus,
    [RestrictionsTypename.MaxBetRestriction]: RestrictionType.MaxBet,
    [RestrictionsTypename.MinBetRestriction]: RestrictionType.MinBet,
    [RestrictionsTypename.NotEnoughBalanceRestriction]:
        RestrictionType.NotEnoughBalance,
    [RestrictionsTypename.OddExistenceRestriction]:
        RestrictionType.OddExistence,
    [RestrictionsTypename.OddStatusRestriction]: RestrictionType.OddStatus,
    [RestrictionsTypename.PlayerLimitRestriction]: RestrictionType.PlayerLimit,
    [RestrictionsTypename.SelectionValueRestriction]:
        RestrictionType.SelectionValue,
    [RestrictionsTypename.SportEventBetStopRestriction]:
        RestrictionType.SportEventBetStop,
    [RestrictionsTypename.SportEventExistenceRestriction]:
        RestrictionType.SportEventExistence,
    [RestrictionsTypename.SportEventStatusRestriction]:
        RestrictionType.SportEventStatus,
    [RestrictionsTypename.SportEventTypeRestriction]:
        RestrictionType.SportEventType,
};

export const ErrorCodeMessages: RestrictionErrorMessagesType = {
    [ErrorCodes.PlayerLimitCrossedDefault]:
        'betslip.Odd.Error.playerLimitCrossedDefault',
    [ErrorCodes.NotEnoughBalance]: 'betslip.Odd.Error.insufficientFunds',
    [ErrorCodes.TicketWasRejected]: 'betslip.Odd.Error.betslipRejected',
    [ErrorCodes.ResponseTimedOut]: 'betslip.Odd.Error.responseTimedOut',
    [ErrorCodes.PlayerIp]: 'betslip.Odd.Error.playerIpThisIsNotAValidIpAddress',
    [AppErrorCodes.AuthCredentialsExpired]:
        'betslip.Odd.Error.authCredentialsExpired',
    [AppErrorCodes.AuthSessionUnknown]: 'betslip.Odd.Error.authSessionUnknown',
    [ErrorCodes.BetOnDifferentProviders]:
        'betslip.Odd.Error.betOnDifferentProviders',
    [ErrorCodes.MinimalStakeIs]: 'betslip.Odd.Error.stakeMinimalStakeIs',
    [ErrorCodes.PlaceRetryLimitReached]:
        'betslip.Odd.Error.placeRetryLimitReached',
    [ErrorCodes.IdenticalBetIsProcessing]:
        'betslip.Odd.Error.identicalBetIsProcessing',
    [ErrorCodes.BetForThisPlayerIsForbidden]:
        'betslip.Odd.Error.betIsForbidden',
    [ErrorCodes.PlayerLimitCrossed]: 'betslip.Odd.Error.playerLimitCrossed',
    [ErrorCodes.PlayerLimitCrossedCps2CanGamble]:
        'betslip.Odd.Error.playerLimitCrossed',
    [ErrorCodes.PlayerLimitCrossedKycSimpleVerified]:
        'betslip.Odd.Error.verifiedPlayerLimitCrossed',
    [ErrorCodes.PlayerLimitCrossedKycVerified]:
        'betslip.Odd.Error.verifiedPlayerLimitCrossed',
    [ErrorCodes.PlayerLimitCrossedPlayerRequiredRequisites]:
        'betslip.Odd.Error.playerRequiredRequisites',
    [ErrorCodes.PlayerLimitCrossedDeKycDeposit]:
        'betslip.Odd.Error.playerLimitCrossedDefault',
    [ErrorCodes.PlayerLimitCrossedTermsAndConditions]:
        'betslip.Odd.Error.playerLimitCrossedDefault',
    [ErrorCodes.PlayerLimitCrossedBettingBlocked]:
        'betslip.Odd.Error.playerLimitCrossedDefault',
    [ErrorCodes.WagerLimitReached]: 'betslip.Odd.Error.gamblerBlocked',
    [ErrorCodes.InternalError]: 'betslip.Odd.Error.internalError',
    [ErrorCodes.FreebetAlreadyUsed]: 'betslip.Odd.Error.freebetAlreadyUsed',
    [ErrorCodes.FreebetExpired]: 'betslip.Odd.Error.freebetExpired',
    [ErrorCodes.FreebetInvalidStatus]: 'betslip.Odd.Error.freebetInvalidStatus',
    [ErrorCodes.FreebetNotFound]: 'betslip.Odd.Error.freebetNotFound',
    [ErrorCodes.FreebetNotApplicableContext]:
        'betslip.Odd.Error.freebetNotApplicableContext',
    [RestrictionType.BetInterval]: 'betslip.OddContainer.betInterval',
    [RestrictionType.BetType]: 'betslip.OddContainer.betType',
    [RestrictionType.MarketDefective]: 'betslip.OddContainer.marketDefective',
    [RestrictionType.MarketExistence]: 'betslip.OddContainer.marketExistence',
    [RestrictionType.MarketStatus]: 'betslip.OddContainer.marketStatus',
    [RestrictionType.MaxBet]: 'betslip.OddContainer.maxBet',
    [RestrictionType.OddExistence]: 'betslip.OddContainer.oddExistence',
    [RestrictionType.OddStatus]: 'betslip.OddContainer.oddStatus',
    [RestrictionType.SelectionValue]: 'betslip.OddContainer.selectionValue',
    [RestrictionType.SportEventExistence]:
        'betslip.OddContainer.sportEventExistence',
    [RestrictionType.SportEventStatus]: 'betslip.OddContainer.sportEventStatus',
};

export const PRECISION_FOR_AMOUNTS = 2 as const;
export const DEFAULT_BET_VARIANT = [
    { amount: '3' },
    { amount: '5' },
    { amount: '10' },
    { amount: '20' },
    { amount: '50' },
    { amount: '100' },
];

export const DEFAULT_MAX_BET = 100000 as const;

export const BETSLIP_ODD_CHANGE_SESSION_KEY =
    'betslip.is-odd-change-strategy-modal-shown' as const;

export const GET_BETS_POLLING_INTERVAL = 10000 as const;
export const WAIT_FOR_START_GET_BETS_POLLING = 20000 as const;
export const RESET_STATE_INTERVAL = 90000 as const;

export const SUCCESS_DELAY = 2000 as const;
