import React, { memo } from 'react';
import cn from 'classnames';

import { IPhone } from '../useFooterPhones';

interface Props {
    phone: IPhone;
}

const FooterPhonesItem: React.FC<Props> = ({ phone }) => {
    return (
        <a
            href={`tel:${phone.phone}`}
            className={cn(
                'flex items-center justify-between px-4 py-3 text-base',
                {
                    'text-primary-white': phone.isActive,
                }
            )}
            data-test="footer-phones-item"
        >
            <span>{phone.country}</span>

            <span>{phone.phone}</span>
        </a>
    );
};

export default memo(FooterPhonesItem);
