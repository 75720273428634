import { ApolloClientContext } from '../types';

export type {
    LazyQueryHookOptions,
    MutationHookOptions,
    QueryHookOptions,
    SubscriptionHookOptions,
} from '@apollo/client';
export type { ApolloClientContext };
export {
    useBettingLazyQuery as useLazyQuery,
    useBettingMutation as useMutation,
    useBettingQuery as useQuery,
    useBettingSubscription as useSubscription,
} from '../hooks';
export { useBettingApolloClient } from './BettingApolloProvider';
