import { SubscriptionClient } from 'subscriptions-transport-ws';

/* eslint-disable no-param-reassign */
function patchSubscriptionClientForAPQ(
    client: SubscriptionClient
): SubscriptionClient {
    client.use([
        {
            applyMiddleware: (operation, next) => {
                if (operation.query) {
                    operation.setContext({ query: operation.query });
                }

                const ctx = operation.getContext();
                const includeQuery: boolean | undefined =
                    ctx?.http?.includeQuery;

                if (includeQuery) {
                    operation.query = ctx.query;
                } else {
                    delete operation.query;
                }

                next();
            },
        },
    ]);

    const c = client as unknown as Record<string, unknown>;
    const raw = c.checkOperationOptions as (...args: unknown[]) => unknown;
    c.checkOperationOptions = (...args: unknown[]) => {
        try {
            return raw(...args);
        } catch (err) {
            if (
                err instanceof Error &&
                err.message === 'Must provide a query.'
            ) {
                return;
            }

            throw err;
        }
    };

    return client;
}

export default patchSubscriptionClientForAPQ;
