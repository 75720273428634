import { ErrorLink, onError } from '@apollo/client/link/error';
import { OperationDefinitionNode } from 'graphql';

import { getRequestIdFromContext } from 'utils';
import { isSsr } from 'utils/isSsr';
import { isAuthSessionGraphQL } from './utils/isAuthSessionGraphQL';
import { isAuthSessionNetworkError } from './utils/isAuthSessionNetworkError';
import { removeAuthTokenFromOperation } from './utils/removeAuthTokenFromOperation';
import { resetClientToken } from './utils/resetClientToken';

export const errorHandler: ErrorLink.ErrorHandler = ({
    graphQLErrors,
    networkError,
    operation,
    forward,
}) => {
    const { operationName, query } = operation;
    const context = operation.getContext();
    const requestId = getRequestIdFromContext(context);

    const operationType = (query?.definitions[0] as OperationDefinitionNode)
        ?.operation;

    if (networkError || graphQLErrors) {
        if (operationType && operationType === 'subscription') return;

        if (
            isAuthSessionGraphQL(graphQLErrors, operationName, requestId) ||
            isAuthSessionNetworkError(networkError)
        ) {
            if (!isSsr()) {
                resetClientToken();

                return;
            }

            removeAuthTokenFromOperation({
                operation,
            });

            return forward(operation);
        }
    }
};

const expiredTokenLink = onError(errorHandler);

export default expiredTokenLink;
