import type { GetServerSidePageData } from 'types';

export enum ApolloClientName {
    Betting = 'betting',
    Cms = 'cms',
    Platform = 'platform',
}

export const APOLLO_CMS_LINK = 'apolloCmsLinks' as const;
export const APOLLO_BETTING_LINK = 'apolloBettingLinks' as const;

export const allowedMultipleGsspKeys = new Set<keyof GetServerSidePageData>([
    'widgets',
    'keyValue',
]);
