import { useEffect, useMemo, useState } from 'react';

import { convertSecondsToTimerFormat } from 'utils';

const ONE_SECOND = 1000 as const;

interface Input {
    time: number | undefined;
    isDecrement?: boolean;
    isTimeInMinutes?: boolean;
    isTimeAbsolute?: boolean;
    isTimerRunning?: boolean;
}

interface Output {
    timeToShow: string;
}

const useTimer = ({
    time: initialTime,
    isDecrement = false,
    isTimeInMinutes = false,
    isTimeAbsolute = false,
    isTimerRunning = true,
}: Input): Output => {
    const [time, setTime] = useState(0);

    const finalTime = isTimeAbsolute ? Math.abs(time) : Math.max(time, 0);

    const timeInSeconds = Math.trunc(finalTime / ONE_SECOND);

    const timeToShow = useMemo(
        () =>
            isTimeInMinutes
                ? `${Math.trunc((finalTime + 59000) / 60000)}\``
                : convertSecondsToTimerFormat(timeInSeconds, [60, 1]),
        [finalTime, isTimeInMinutes, timeInSeconds]
    );

    useEffect(() => {
        if (!initialTime) return;

        setTime(initialTime);
    }, [initialTime]);

    useEffect(() => {
        if ((isDecrement && !timeInSeconds) || !isTimerRunning) return;

        const addition = isDecrement ? -ONE_SECOND : ONE_SECOND;

        const timerId = setInterval(() => {
            setTime((prev) => prev + addition);
        }, ONE_SECOND);

        return () => {
            clearInterval(timerId);
        };
    }, [isDecrement, isTimerRunning, timeInSeconds]);

    return {
        timeToShow,
    };
};

export default useTimer;
