import { getName } from 'i18n-iso-countries';
import { useTranslations } from 'next-intl';
import countriesJSON from 'translations/betting-countries/en.json';

import { getIsStaticCountryCode } from 'utils';
import useLocales from './useLocales';

type Input = string;

type Output = string;

const useLocalizedCountryName = (countryCode: Input): Output => {
    const t = useTranslations('betting-countries.countries');
    const { activeLocale } = useLocales();

    if (!countryCode) return '';

    if (getIsStaticCountryCode(countryCode)) {
        return isAvailableCountryCodes(countryCode)
            ? t(countryCode)
            : countryCode;
    }

    return getName(countryCode, activeLocale) || '';
};

const isAvailableCountryCodes = (() => {
    let cache: Set<string>;

    return (countryCode: string): boolean => {
        if (cache) return cache.has(countryCode);

        // NOTE: Global scope is not used to prevent this operation in SSR
        cache = new Set(Object.keys(countriesJSON.countries));

        return cache.has(countryCode);
    };
})();

export default useLocalizedCountryName;
