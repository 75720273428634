import { SportType } from 'app-constants';
import { BettingApolloClient } from '../services/apollo';
import {
    FeaturedSportType,
    GetSports,
    GetSportsVariables,
} from '../types/gql.bet';

import getSports from '../services/apollo/betting/getSports.bet.gql';

export const selectSportTypeBySportId = (
    client: BettingApolloClient,
    sportId: string
): SportType => {
    const data = client.readQuery<GetSports, GetSportsVariables>({
        query: getSports,
    });

    const sports = data?.sports;

    const sport = sports?.find((sportItem) => sportItem.id === sportId);

    return sport?.tags.find((tag: string) => tag === FeaturedSportType.Classic)
        ? SportType.Sports
        : SportType.Esports;
};
