import { useResponsive } from 'layouts/MainLayout/ResponsiveLayoutContext';
import { Message } from 'types/gql.cms';
import { NotificationSystemType } from '../../constants';
import { getMessageCardImg } from '../helpers';

interface Input {
    systemType: string;
    mainImage: NonNullable<Message['media']>['mainImage'] | undefined;
}

interface Output {
    cardImg: string;
    messageSystemType: string;
}

const useInboxCenterMessageIcon = ({
    systemType,
    mainImage,
}: Input): Output => {
    const isMobile = useResponsive().until('md');
    const messageSystemType = systemType.toLowerCase();

    const cardImg = getMessageCardImg({
        mainImage,
        onlyDefault: messageSystemType === NotificationSystemType.Popup,
        isMobile,
    });

    return {
        cardImg,
        messageSystemType,
    };
};

export default useInboxCenterMessageIcon;
