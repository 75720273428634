import * as GQL from 'types/gql.bet';

import * as Operations from './onUpdateSportEvent.bet.gql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'services/apollo/betting';
const defaultOptions = {} as const;

/**
 * __useOnUpdateSportEvent__
 *
 * To run a query within a React component, call `useOnUpdateSportEvent` and pass it any options that fit your needs.
 * When your component renders, `useOnUpdateSportEvent` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnUpdateSportEvent({
 *   variables: {
 *      sportEventId: // value for 'sportEventId'
 *      isTopMarkets: // value for 'isTopMarkets'
 *      marketLimit: // value for 'marketLimit'
 *      marketIds: // value for 'marketIds'
 *      marketTypes: // value for 'marketTypes'
 *      marketStatuses: // value for 'marketStatuses'
 *      version: // value for 'version'
 *      skipMarkets: // value for 'skipMarkets'
 *   },
 * });
 */
export function useOnUpdateSportEvent(
    baseOptions: ApolloReactHooks.SubscriptionHookOptions<
        GQL.OnUpdateSportEvent,
        GQL.OnUpdateSportEventVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSubscription<
        GQL.OnUpdateSportEvent,
        GQL.OnUpdateSportEventVariables
    >(Operations.OnUpdateSportEvent, options);
}
export type OnUpdateSportEventHookResult = ReturnType<
    typeof useOnUpdateSportEvent
>;
export type OnUpdateSportEventSubscriptionResult =
    Apollo.SubscriptionResult<GQL.OnUpdateSportEvent>;
