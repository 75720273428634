import { BatchKeys } from 'app-constants';
import { useGetGamesCategories } from 'gql/cms/queries/getGamesCategories.cms';
import { Category } from 'types/gql.cms';
import { getFilteredGamesCategories } from './utils';

interface Output {
    categories?: Category[];
}

const useFooterCategoriesLinks = (): Output => {
    const { data } = useGetGamesCategories(() => ({
        fetchPolicy: 'cache-and-network',
        pagePropsKey: 'gamesCategories',
        context: {
            batchKey: BatchKeys.GetGamesCategories,
        },
    }));

    const filteredCategories = getFilteredGamesCategories(
        data?.gamesCategories
    );

    return {
        categories: filteredCategories,
    };
};

export default useFooterCategoriesLinks;
