import { useContext, useEffect, useMemo, useState } from 'react';
import { getCookie, setCookie } from 'cookies-next';
import addDays from 'date-fns/addDays';
import { some } from 'lodash';

import { CookiesType, LayoutType, ModalsHash } from 'app-constants';
import { useRouter } from 'components/HybridRouter';
import usePageGetsUrls from 'layouts/MainLayout/Footer/usePageUrls';
import { LayoutTypeContext } from 'layouts/Root/LayoutTypeContext';

interface Output {
    termsUrl?: string;
    isShow: boolean;
    closeCookiesAgreementHandler: VoidFunction;
}

const useCookiesAgreements = (): Output => {
    const [isShow, setIsShow] = useState<boolean>(false);
    const { layoutType } = useContext(LayoutTypeContext);
    const typePage = layoutType === LayoutType.Betting ? 'betting' : 'casino';
    const { data } = usePageGetsUrls({ typePage });
    const { asPathObj } = useRouter();

    const isModal = useMemo(
        () =>
            some(ModalsHash, (hash: string) =>
                Boolean(asPathObj?.hash?.includes(hash))
            ),
        [asPathObj?.hash]
    );

    const termsUrl: string | undefined = useMemo(() => {
        if (!data?.pagesBySlugs) return;

        return (
            data.pagesBySlugs.find((item) => item.id === 'page:rules')?.url ??
            ''
        );
    }, [data?.pagesBySlugs]);

    const closeCookiesAgreementHandler = () => {
        setCookie(CookiesType.CookiesAgreements, 1, {
            expires: addDays(new Date(), 3650),
        });
        setIsShow(false);
    };

    useEffect(() => {
        const isShowCookiesAgreements = getCookie(
            CookiesType.CookiesAgreements
        );

        if (isShowCookiesAgreements || isModal) {
            setIsShow(false);
        } else {
            setIsShow(true);
        }
    }, [isModal]);

    return {
        termsUrl,
        isShow,
        closeCookiesAgreementHandler,
    };
};

export default useCookiesAgreements;
