import * as GQL from 'types/gql.bet';

import * as Operations from './deleteGamblerSetting.bet.gql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'services/apollo/betting';
const defaultOptions = {} as const;

export type DeleteGamblerSettingMutationFn = Apollo.MutationFunction<
    GQL.DeleteGamblerSetting,
    GQL.DeleteGamblerSettingVariables
>;

/**
 * __useDeleteGamblerSetting__
 *
 * To run a mutation, you first call `useDeleteGamblerSetting` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGamblerSetting` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGamblerSetting, { data, loading, error }] = useDeleteGamblerSetting({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useDeleteGamblerSetting(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQL.DeleteGamblerSetting,
        GQL.DeleteGamblerSettingVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        GQL.DeleteGamblerSetting,
        GQL.DeleteGamblerSettingVariables
    >(Operations.deleteGamblerSetting, options);
}
export type DeleteGamblerSettingHookResult = ReturnType<
    typeof useDeleteGamblerSetting
>;
export type DeleteGamblerSettingMutationResult =
    Apollo.MutationResult<GQL.DeleteGamblerSetting>;
export type DeleteGamblerSettingMutationOptions = Apollo.BaseMutationOptions<
    GQL.DeleteGamblerSetting,
    GQL.DeleteGamblerSettingVariables
>;
