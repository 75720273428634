import { useEffect } from 'react';

interface Input {
    isBlock: boolean;
}
const useBlockScrollOnDocument = ({ isBlock }: Input): void => {
    useEffect(() => {
        if (!isBlock) return;

        document.body.classList.add('overflow-hidden');

        return () => {
            document.body.classList.remove('overflow-hidden');
        };
    }, [isBlock]);
};

export default useBlockScrollOnDocument;
