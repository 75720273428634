import * as GQL from 'types/gql.bet';

import * as Operations from './onUpdateDota2Overviews.bet.gql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'services/apollo/betting';
const defaultOptions = {} as const;

/**
 * __useOnUpdateDota2Overviews__
 *
 * To run a query within a React component, call `useOnUpdateDota2Overviews` and pass it any options that fit your needs.
 * When your component renders, `useOnUpdateDota2Overviews` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnUpdateDota2Overviews({
 *   variables: {
 *      sportEventIDs: // value for 'sportEventIDs'
 *   },
 * });
 */
export function useOnUpdateDota2Overviews(
    baseOptions: ApolloReactHooks.SubscriptionHookOptions<
        GQL.OnUpdateDota2Overviews,
        GQL.OnUpdateDota2OverviewsVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSubscription<
        GQL.OnUpdateDota2Overviews,
        GQL.OnUpdateDota2OverviewsVariables
    >(Operations.OnUpdateDota2Overviews, options);
}
export type OnUpdateDota2OverviewsHookResult = ReturnType<
    typeof useOnUpdateDota2Overviews
>;
export type OnUpdateDota2OverviewsSubscriptionResult =
    Apollo.SubscriptionResult<GQL.OnUpdateDota2Overviews>;
