import React, { PropsWithChildren } from 'react';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { useRouter as useNextRouter } from 'next/router';

import HybridRouterContext from './HybridRouterContext';
import { RouterPushContext } from './RouterPushProvider';

const ServerRouter: React.FC<PropsWithChildren> = ({ children }) => {
    const nextRouter = useNextRouter();

    const push = () =>
        console.error(
            'The client router does not support Next.js "push" function.'
        );

    return (
        <HybridRouterContext.Provider value={nextRouter}>
            <RouterPushContext.Provider
                value={
                    // eslint-disable-next-line react/jsx-no-constructed-context-values
                    { push }
                }
            >
                {children}
            </RouterPushContext.Provider>
        </HybridRouterContext.Provider>
    );
};

export default ServerRouter;
