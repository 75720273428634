import React from 'react';
import {
    createBrowserRouter,
    redirect,
    RouteObject,
    RouterProvider,
} from 'react-router-dom';
import { compact, filter, map } from 'lodash';
import type { DataLoaderPayload, PageProps } from 'types';

import {
    AppLink,
    DEFAULT_LOCALE,
    LayoutType,
    MessagesByLayoutType,
} from 'app-constants';
import { getDynamicPage } from 'components/DynamicPage/utils';
import { convertToClientRoute } from 'components/HybridRouter/utils';
import NotFoundWithMessages from 'components/NotFoundWithMasseges';
import { getMessages } from 'utils';
import { useIsEnableSSR } from './EnableSSRProvider/EnableSSRContext';
import Root from './Root';
import clientRoutes from './routes';
import { getLayoutTypeByPageEntityInfoTypename } from './utils';

interface Props {
    pageProps: PageProps;
    locale?: string;
}

// Validate the correctness of internet domain strings, exemplified by 'http://example.com/en/test'
const domainRegex =
    /(?:https?:\/\/)?(?:www\.)?([a-zA-Z0-9-]+)\.([a-zA-Z]{2,})(?:\/\S*)?/;

const ClientRouter: React.FC<Props> = ({ locale, pageProps }) => {
    const rootProps = {
        locale,
        pageProps,
    };

    const isSSREnabled = useIsEnableSSR();

    const pages = filter(AppLink, (link) => {
        return !(
            link === AppLink.ROOT ||
            link.startsWith('/_') ||
            [AppLink.N404].includes(link)
        );
    });

    const routes: RouteObject[] = compact(
        map(pages, (nextRoute) => {
            const path = convertToClientRoute(nextRoute);

            const clientPage = clientRoutes[nextRoute];

            // TODO: Avoid empty pages
            if (!clientPage) {
                console.error(
                    `Cannot find client route for "${nextRoute}" route`
                );

                return null;
            }

            const { settings } = clientPage;

            const pageMessages = settings.messages || [];

            const messageList = [...pageMessages];

            return {
                path,
                element: <Root Page={clientPage} {...rootProps} />,
                loader: async ({ params }) => {
                    // TODO: add pages validation when we'll have a list from GINBC-1761 task
                    const { dynamicRouteByPath, redirect: seoRedirect } =
                        await getDynamicPage({
                            params,
                            path,
                            locale: locale || DEFAULT_LOCALE,
                        });

                    if (seoRedirect) {
                        const match = seoRedirect?.url.match(domainRegex);

                        if (match) {
                            return redirect(
                                seoRedirect.url,
                                seoRedirect.statusCode
                            );
                        }

                        return redirect(
                            `${window.location.origin}${seoRedirect.url}`,
                            seoRedirect.statusCode
                        );
                    }

                    const layoutType: LayoutType =
                        getLayoutTypeByPageEntityInfoTypename({
                            data: dynamicRouteByPath,
                        }) ||
                        settings.layoutType ||
                        LayoutType.BettingStatic;

                    const layoutTypeMessages = MessagesByLayoutType[layoutType];
                    messageList.push(...layoutTypeMessages);

                    const responseMessages = await loadMessages({
                        messageList,
                        locale,
                    });

                    return {
                        ...responseMessages,
                    };
                },
            } as RouteObject;
        })
    );

    routes.push(
        {
            path: AppLink.ROOT,
            shouldRevalidate: () => !isSSREnabled,
            element: <Root Page={clientRoutes[AppLink.ROOT]} {...rootProps} />,
            loader: async (): Promise<DataLoaderPayload> => {
                const messages = await getMessages({ locale }, [
                    ...MessagesByLayoutType[LayoutType.BettingStatic],
                    ...(clientRoutes[AppLink.ROOT].settings.messages || []),
                ]);

                return { messages };
            },
        },
        {
            path: AppLink.N404,
            element: <NotFoundWithMessages locale={locale} />,
            loader: async () => {
                const messages = await getMessages({ locale }, ['ErrorPage']);

                return { messages };
            },
        }
    );

    const clientRouter = createBrowserRouter(routes, {
        basename: `/${locale === 'default' ? '' : locale}`,
        hydrationData: isSSREnabled ? {} : undefined,
    });

    return <RouterProvider router={clientRouter} fallbackElement={<></>} />;
};

interface MessageLoaderInput {
    messageList: IntlMessagesNamespace[];
    locale?: string;
}

async function loadMessages({
    messageList,
    locale = DEFAULT_LOCALE,
}: MessageLoaderInput): Promise<DataLoaderPayload> {
    const messages = await getMessages({ locale }, messageList);

    return { messages };
}

export default ClientRouter;
