import { useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import { deleteCookie } from 'cookies-next';
import { useTranslations } from 'next-intl';

import { CookiesType, ModalsHash } from 'app-constants';
import useAlertMessages from 'components/AlertManager/useAlertMessages';
import { removePreviewCookies } from '../inboxCenter/helpers';
import useModal from '../modals/useModal';
import { authSessionVar } from './authSessionState';

interface Input {
    setAuthenticated: (state: boolean) => void;
}

const useExpiredToken = ({ setAuthenticated }: Input): void => {
    const { errorAlert } = useAlertMessages();
    const { openModal } = useModal();

    const isAuthSessionUnknown = useReactiveVar(authSessionVar);

    const t = useTranslations('General.gincms.auth');

    useEffect(() => {
        if (!isAuthSessionUnknown) return;

        deleteCookie(CookiesType.Paseto);
        deleteCookie(CookiesType.AuthToken);
        removePreviewCookies();

        openModal({ hash: ModalsHash.Authorization });

        errorAlert(t('credentials.authCredentialsExpired'));

        authSessionVar(false);
        setAuthenticated(false);
    }, [errorAlert, isAuthSessionUnknown, openModal, setAuthenticated, t]);
};

export default useExpiredToken;
