import { useCallback } from 'react';

import { isSsr } from 'utils/isSsr';

interface Input {
    localStorageKey: string;
}

interface Output {
    getLocalStorageData: () => any;
    removeLocalStorageData: (key?: string) => void;
    setLocalStorageData: (key: string, value: any) => void;
}

const useLocalStorage = ({ localStorageKey }: Input): Output => {
    const getLocalStorageData = useCallback(() => {
        if (isSsr()) return;

        const localStorageData = window.localStorage.getItem(localStorageKey);

        if (!localStorageData) return;

        return JSON.parse(localStorageData);
    }, [localStorageKey]);

    const removeLocalStorageData = useCallback(
        (key?: string) => {
            if (isSsr()) return;

            if (!key) {
                window.localStorage.removeItem(localStorageKey);
            } else {
                const localStorageData =
                    window.localStorage.getItem(localStorageKey);

                if (localStorageData) {
                    const data = JSON.parse(localStorageData);

                    if (data.hasOwnProperty(key)) {
                        delete data[key];

                        window.localStorage.setItem(
                            localStorageKey,
                            JSON.stringify(data)
                        );
                    }
                }
            }
        },
        [localStorageKey]
    );

    const setLocalStorageData = useCallback(
        (key: string, value: any) => {
            if (isSsr()) return;

            const localStorageData =
                window.localStorage.getItem(localStorageKey);

            if (localStorageData) {
                window.localStorage.setItem(
                    localStorageKey,
                    JSON.stringify({
                        ...JSON.parse(localStorageData),
                        [key]: value,
                    })
                );
            } else {
                window.localStorage.setItem(
                    localStorageKey,
                    JSON.stringify({ [key]: value })
                );
            }
        },
        [localStorageKey]
    );

    return {
        getLocalStorageData,
        removeLocalStorageData,
        setLocalStorageData,
    };
};

export default useLocalStorage;
