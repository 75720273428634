import { ApolloLink } from '@apollo/client';
import { CookieValueTypes, getCookie } from 'cookies-next';

import { CookiesType } from 'app-constants';
import { isSsr } from 'utils/isSsr';

const authLink = new ApolloLink((operation, forward) => {
    const token: CookieValueTypes | undefined = isSsr()
        ? operation.getContext().token
        : getCookie(CookiesType.Paseto);

    operation.setContext((context: Record<string, any>) => {
        let { headers } = context;
        const paseto = token || context.headers['x-paseto'] || '';

        if (paseto) {
            headers = {
                ...headers,
                'x-paseto': paseto,
            };
        }

        return {
            ...context,
            headers,
        };
    });

    return forward(operation);
});

export default authLink;
