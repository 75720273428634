import NodeCache from 'node-cache';
import { Gauge } from 'prom-client';

import { MetricsName } from 'services/prometheus/constants';

interface CachedQueryOptions {
    keyWithLocale?: boolean;
}

interface CacheOptions {
    isEnabled?: boolean;
    cachedQueries?: Record<string, CachedQueryOptions>;
}

export class AppCache {
    private ttlInSeconds = 60;

    private nodeCache = new NodeCache({
        stdTTL: this.ttlInSeconds,
    });

    private options: CacheOptions | null = null;

    private globalCacheSizePromMetric?: Gauge;

    private globalCacheCountPromMetric?: Gauge;

    private static instance: AppCache;

    private constructor() {
        if (typeof window === 'undefined') {
            // eslint-disable-next-line
            const { register } = require('prom-client');

            this.globalCacheSizePromMetric = register.getSingleMetric(
                MetricsName.CacheSize
            );
            this.globalCacheCountPromMetric = register.getSingleMetric(
                MetricsName.CacheCount
            );

            this.nodeCache.on('set', this.updateMetrics.bind(this));
            this.nodeCache.on('del', this.updateMetrics.bind(this));
            this.nodeCache.on('expired', this.updateMetrics.bind(this));
            this.nodeCache.on('flush', this.updateMetrics.bind(this));
        }
    }

    private async updateMetrics() {
        this.globalCacheCountPromMetric?.set(this.nodeCache.stats.keys);
        this.globalCacheSizePromMetric?.set(this.nodeCache.stats.vsize);
    }

    public static getInstance(): AppCache {
        if (!AppCache.instance) {
            AppCache.instance = new AppCache();
        }

        return AppCache.instance;
    }

    public setOptions(json?: string): void {
        if (!json) {
            this.options = null;
            this.nodeCache.flushAll();

            return;
        }

        try {
            this.options = JSON.parse(json);

            if (!this.isEnabled()) {
                this.nodeCache.flushAll();
            }
        } catch (e) {
            this.options = null;
            this.nodeCache.flushAll();
            console.error('Error: cache options parse failed');
        }
    }

    public isEnabled(): boolean {
        return !!this.options?.isEnabled;
    }

    public getCachedQueryOptions(
        queryName: string
    ): CachedQueryOptions | undefined {
        return this.options?.cachedQueries?.[queryName];
    }

    public get(key: string): unknown {
        if (!this.isEnabled()) return;

        return this.nodeCache.get(key);
    }

    public set(key: string, value: unknown): void {
        if (!this.isEnabled()) return;

        this.nodeCache.set(key, value);
    }
}
