import { captureException as sentryException } from '@sentry/nextjs';

import CustomSentryError from './CustomSentryError';

interface Input {
    label?: string;
    message: string;
    additionalData?: Record<string, any>;
    fingerprint?: string[];
    skip?: boolean;
}

function captureSentryException({
    label,
    message,
    additionalData,
    fingerprint: customFingerprint,
    skip,
}: Input): void {
    if (skip) {
        return;
    }

    const runtime = typeof window === 'undefined' ? 'node' : 'client';

    const error = new CustomSentryError({
        label,
        message,
        runtime,
    });

    const fingerprint = [message, runtime];

    if (label) {
        fingerprint.unshift(label);
    }

    sentryException(error, {
        extra: { additionalData },
        fingerprint: customFingerprint || fingerprint,
    });
}

export default captureSentryException;
