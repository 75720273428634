/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-useless-constructor */
/* eslint-disable @typescript-eslint/no-empty-function */

import { Feature } from './constants';
import { FeatureFlags } from './types';

class FeatureFlagsManagerStub {
    public static init(): FeatureFlagsManagerStub {
        return new FeatureFlagsManagerStub();
    }

    public getAll(): FeatureFlags {
        return {};
    }

    public isEnabled(_featureName: Feature): boolean {
        return false;
    }
}

export default FeatureFlagsManagerStub;
