import { ApolloLink } from '@apollo/client';
import { find, includes } from 'lodash';

import {
    AppLink,
    ModalsHash,
    ResponsibleGamingLimitTypes,
} from 'app-constants';
import { Feature } from 'services/features/constants';
import FeatureFlagsManager from 'services/features/FeatureFlagsManager';
import cmsApolloRedirectVar from '../cms/errorHandler/cmsApolloRedirectVar';

interface ErrorState {
    type: string;
    crossed: boolean;
    actual_to_at: string;
    created_at: string;
    missed_fields?: string[];
}

const responsibleGamingErrorLink = new ApolloLink((operation, forward) => {
    return forward(operation).map((result) => {
        const error = result?.errors && result.errors[0];

        if (error?.message === 'player_limit_crossed') {
            const limit = find<ErrorState>(
                error.extensions.states,
                (state) =>
                    includes(
                        [
                            ResponsibleGamingLimitTypes.GamingTime,
                            ResponsibleGamingLimitTypes.PlayerRequiredLimits,
                        ],
                        state.type
                    ) && state.crossed
            );

            const { pathname, hash, search } = window.location;

            const isGamePage = includes(pathname, `${AppLink.CASINO}/game`);
            const [triggerValue = ''] = error.path || [];

            const features = FeatureFlagsManager.init();

            const isResponsibleGaming = features.isEnabled(
                Feature.AppResponsibleGaming
            );

            if (limit && isResponsibleGaming) {
                const redirect =
                    limit.type ===
                    ResponsibleGamingLimitTypes.PlayerRequiredLimits
                        ? `&r=${encodeURIComponent(
                              `${pathname}${hash}${search}`
                          )}`
                        : '';

                const trigger = triggerValue ? `&trigger=${triggerValue}` : '';

                cmsApolloRedirectVar({
                    pathname: isGamePage ? AppLink.CASINO : undefined,
                    pathTo: `${ModalsHash.PlayerLimitReached}?type=${limit.type}${redirect}${trigger}`,
                });
            }
        }

        return result;
    });
});

export default responsibleGamingErrorLink;
