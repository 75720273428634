import { FC, memo } from 'react';

import { withMessages } from 'hocs';
import useVerificationBanner from './useVerificationBanner';
import VerificationBannerContent from './VerificationBannerContent';

const VerificationBanner: FC = () => {
    const { isShowBanner, closeVerificationBanner, bannerStates } =
        useVerificationBanner();

    if (!isShowBanner) return null;

    return (
        <VerificationBannerContent
            bannerStates={bannerStates}
            closeVerificationBanner={closeVerificationBanner}
        />
    );
};

export default memo(withMessages(VerificationBanner, ['VerificationBanner']));
