import {
    refetchAllState,
    refetchAvailableState,
    refetchProcessingState,
} from 'components/Bonuses/bonusProgramSubscriptionState';
import { useOnUpdateProgramAction } from 'gql/platform/subscriptions/onUpdateProgramAction.ptm';
import { useAuthorization } from 'hooks';

const useOnUpdateProgramSubscription = (): void => {
    const { isAuthorized } = useAuthorization();

    useOnUpdateProgramAction({
        async onData({ data: subscriptionData }) {
            switch (subscriptionData.data?.programAction.__typename) {
                case 'ProgramChangeAction':
                    refetchProcessingState(true);
                    break;
                case 'ProgramCreateAction':
                    refetchAvailableState(true);
                    break;
                case 'ProgramExpireAction':
                case 'ProgramFinishAction':
                    refetchAllState(true);
                    break;
                default:
                    break;
            }
        },
        skip: !isAuthorized,
    });
};

export default useOnUpdateProgramSubscription;
