import { RefObject, useCallback, useRef } from 'react';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { captureException } from '@sentry/nextjs';
import to from 'await-to-js';
import { includes } from 'lodash';

import { LayoutType } from 'app-constants';
import useAlertMessages from 'components/AlertManager/useAlertMessages';
import { betslipVars } from 'components/betting/Betslip';
import { useRouter } from 'components/HybridRouter';
import GetMessageQuery from 'gql/cms/queries/messages/getMessage.cms.gql';
import GetMessagesQuery from 'gql/cms/queries/messages/getMessages.cms.gql';
import { useAuthorization, useIsRenderBroadcastBanner } from 'hooks';
import { useCmsApolloClient } from 'services/apollo';
import { logError } from 'services/logger';
import { captureSentryException } from 'services/sentry';
import {
    GetMessage,
    GetMessages,
    GetMessagesVariables,
    GetMessageVariables,
    Message,
} from 'types/gql.cms';
import {
    isEuroMessage,
    isPopupMessage,
    removePreviewCookies,
} from '../helpers';
import {
    isPreviewAnimatingVar,
    previewMessageVar,
    setIsProgressAnimatingVar,
    setPreviewMessageVar,
} from '../inboxCenterState';
import { useOnNewMessages } from './onNewMessages.ptm';
import useInboxPreviewEuroMessage from './useInboxPreviewEuroMessage';
import useInboxPreviewLoginMessage from './useInboxPreviewLoginMessage';

interface Input {
    type: LayoutType;
}

interface Output {
    previewMessage: Message | null;
    previewMessageRef: RefObject<HTMLDivElement>;
    offsetTop: string;
    handleMarkRead: (message: Message) => void;
    isPreviewAnimating: boolean;
}

const useInboxCenterPreview = ({ type }: Input): Output => {
    const { isRegistrationFinished } = useAuthorization();
    const { client } = useCmsApolloClient();
    const { asPathObj } = useRouter();
    const { errorAlert } = useAlertMessages();

    const isRenderBroadcastBanner = useIsRenderBroadcastBanner();
    const isPreviewAnimating = useReactiveVar(isPreviewAnimatingVar);
    const previewMessage = useReactiveVar(previewMessageVar);
    const previewMessageRef = useRef<HTMLDivElement>(null);
    const { oddIds } = useReactiveVar(betslipVars);
    const hash = asPathObj?.hash;

    const [getMessages] = useLazyQuery<GetMessages, GetMessagesVariables>(
        GetMessagesQuery,
        {
            variables: {
                isRead: false,
            },
            fetchPolicy: 'no-cache',
            client,
        }
    );

    const [getMessage] = useLazyQuery<GetMessage, GetMessageVariables>(
        GetMessageQuery,
        {
            fetchPolicy: 'no-cache',
            client,
        }
    );

    const handleMarkRead = useCallback(
        async (readMessage: Message) => {
            try {
                const [err] = await to(
                    getMessage({
                        variables: { id: readMessage.id },
                    })
                );

                if (err) {
                    logError({
                        message: '[InboxCenterPreview] read message error',
                        err,
                    });

                    errorAlert(err.message);

                    return;
                }

                setPreviewMessageVar(null);
            } catch (err) {
                if (err instanceof Error) {
                    captureSentryException({
                        label: 'InboxCenterPreview read message error',
                        message: err.message,
                    });

                    logError({
                        message: '[InboxCenterPreview] read message error',
                        err,
                    });
                }

                throw new Error(
                    '[InboxCenterPreview] error: something went wrong'
                );
            }
        },
        [errorAlert, getMessage]
    );

    const setCurrentMessage = useCallback(
        (message: Message) => {
            if (previewMessage) return;

            setPreviewMessageVar(message);

            if (
                !isEuroMessage(previewMessage) &&
                !isPopupMessage(previewMessage, type)
            ) {
                setIsProgressAnimatingVar(true);
                removePreviewCookies();
            }
        },
        [previewMessage, type]
    );

    const checkIsSupportOpen = (): boolean => {
        const supportElement = document.getElementById('frame-container');

        return Boolean(supportElement?.classList.contains('state-opened'));
    };

    useInboxPreviewLoginMessage({
        isRegistrationFinished,
        hash,
        oddIds,
        setCurrentMessage,
        type,
    });

    useInboxPreviewEuroMessage({
        hash,
        setCurrentMessage,
        type,
    });

    useOnNewMessages({
        onData({ data: { data } }) {
            const messageId = data?.newMessages?.id;

            if (!messageId || checkIsSupportOpen()) return;

            getMessages()
                .then(({ data: { messages } = {}, client: { cache } }) => {
                    cache.modify({
                        fields: {
                            messagesInfo(existingMessagesInfo) {
                                const unread = messages?.length || 0;

                                return {
                                    ...existingMessagesInfo,
                                    unread,
                                };
                            },
                        },
                    });

                    const newMessage = messages?.find(
                        (item) => item.id === messageId
                    );

                    const shouldReturnByLayoutType = !includes(
                        isEuroMessage(newMessage)
                            ? [LayoutType.Betting, LayoutType.BettingStatic]
                            : [LayoutType.Betting],
                        type
                    );

                    if (
                        oddIds.length ||
                        hash ||
                        shouldReturnByLayoutType ||
                        !newMessage
                    )
                        return;

                    setCurrentMessage(newMessage);
                })
                .catch(({ error }) => {
                    captureException(error);
                    console.error(error);
                });
        },
        skip: !isRegistrationFinished,
    });

    const offsetTop = isRenderBroadcastBanner
        ? 'top-[calc(theme(spacing.header-offset) + theme(spacing.broadcast-banner-offset))]'
        : 'top-[calc(theme(spacing.header-offset)]';

    return {
        previewMessage,
        offsetTop,
        previewMessageRef,
        handleMarkRead,
        isPreviewAnimating,
    };
};

export default useInboxCenterPreview;
