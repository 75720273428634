import { RefObject, useEffect, useRef } from 'react';
import { useReactiveVar } from '@apollo/client';

import { Message } from 'types/gql.cms';
import {
    isProgressAnimatingVar,
    setIsPreviewAnimatingVar,
    setIsProgressAnimatingVar,
} from '../inboxCenterState';

interface Input {
    message: Message;
    handleMarkRead: (message: Message) => void;
}

interface Output {
    progressRef: RefObject<HTMLDivElement>;
}

const useInboxCenterProgress = ({ message, handleMarkRead }: Input): Output => {
    const progressRef = useRef<HTMLDivElement>(null);
    const isProgressAnimation = useReactiveVar(isProgressAnimatingVar);

    useEffect(() => {
        setIsPreviewAnimatingVar(isProgressAnimation);
    }, [isProgressAnimation]);

    useEffect(() => {
        const progressLine = progressRef?.current;

        const animationEndHandler = () => {
            handleMarkRead(message);
            setIsProgressAnimatingVar(false);
        };

        progressLine?.addEventListener('animationend', animationEndHandler);

        return () => {
            progressLine?.removeEventListener(
                'animationend',
                animationEndHandler
            );
        };
    }, [progressRef, handleMarkRead, message]);

    return {
        progressRef,
    };
};

export default useInboxCenterProgress;
