import React, { PropsWithChildren, useContext, useMemo, useRef } from 'react';
import type { PageProps } from 'types';

import { isSsr } from 'utils/isSsr';
import StubApolloClient from '../utils/stubs/StubApolloClient';
import { PlatformApolloClient } from './PlatformApolloClient';

interface ApolloClients {
    client: PlatformApolloClient | undefined;
}

const ApolloClientContext = React.createContext<ApolloClients>({
    client: undefined as any,
});

type Props = { pageProps: PageProps } & PropsWithChildren;

export const PlatformApolloProvider: React.FC<Props> = ({
    children,
    pageProps,
}) => {
    const client = useRef<PlatformApolloClient>(new StubApolloClient('Cms'));

    const isBrowser = !isSsr();

    if (isBrowser) {
        if (!pageProps.env.PUBLIC_PLATFORM_GQL_CLIENT_ENDPOINT) {
            throw new Error(
                'Error! Env variable "PUBLIC_PLATFORM_GQL_CLIENT_ENDPOINT" is missing in pageProps'
            );
        }

        client.current = new PlatformApolloClient({
            endpoint: pageProps.env.PUBLIC_PLATFORM_GQL_CLIENT_ENDPOINT,
        });
    }

    const value = useMemo(
        () => ({
            client: client.current,
        }),
        []
    );

    return (
        <ApolloClientContext.Provider value={value}>
            {children}
        </ApolloClientContext.Provider>
    );
};

export const usePtmApolloClient = (): ApolloClients =>
    useContext(ApolloClientContext);
