import { getCookie } from 'cookies-next';

import { CookiesType } from 'app-constants';
import { WithApolloClientsContext } from 'services/apollo/types';
import { PageProps } from 'types';

type PropsKey = keyof Pick<PageProps, 'affData' | 'refCode' | 'uuid'>;
type SupportedCookieName =
    | CookiesType.AffData
    | CookiesType.RefCode
    | CookiesType.UUID;

export const SUPPORTED_TRACKER_COOKIE_NAME: SupportedCookieName[] = [
    CookiesType.AffData,
    CookiesType.RefCode,
    CookiesType.UUID,
] as const;

interface Input {
    pageProps: Partial<PageProps>;
    req?: WithApolloClientsContext['req'];
}

const PropsKeyMap: Record<SupportedCookieName, PropsKey> = {
    [CookiesType.AffData]: 'affData',
    [CookiesType.RefCode]: 'refCode',
    [CookiesType.UUID]: 'uuid',
};

function setPropsTrackersFromCookie({ pageProps, req }: Input): void {
    SUPPORTED_TRACKER_COOKIE_NAME.forEach((cookieName) => {
        const cookieValue = getCookie(cookieName, { req });
        const name = PropsKeyMap[cookieName];

        if (cookieValue && typeof cookieValue === 'string') {
            // eslint-disable-next-line no-param-reassign
            pageProps[name] = cookieValue;
        }
    });
}

export default setPropsTrackersFromCookie;
