import dFnsIsToday from 'date-fns/isToday';
import parseISO from 'date-fns/parseISO';

import useFormat from './useFormat';

interface Formats {
    dateFormat: string;
    timeFormat: string;
}

interface Input {
    timeStamp: string;
    formats?: Partial<Formats>;
}

interface Output {
    time: string;
    date: string;
    isToday: boolean;
}

const useFormatTimeAndDate = ({ timeStamp, formats = {} }: Input): Output => {
    const { format } = useFormat();
    const { dateFormat, timeFormat }: Formats = {
        timeFormat: 'HH:mm',
        dateFormat: 'MMM dd',
        ...formats,
    };

    return {
        date: format(new Date(timeStamp), dateFormat),
        time: format(new Date(timeStamp), timeFormat),
        isToday: dFnsIsToday(parseISO(timeStamp)),
    };
};

export default useFormatTimeAndDate;
