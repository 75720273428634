import { FC } from 'react';
import Script from 'next/script';

interface Props {
    gaKey: string;
}

const LoadScript: FC<Props> = ({ gaKey }) => {
    if (process.env.NODE_ENV !== 'production') return null;

    return (
        <>
            <Script
                id="googletagmanager"
                strategy="afterInteractive"
                defer={true}
                dangerouslySetInnerHTML={{
                    __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
             new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
             j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
             'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
             })(window,document,'script','dataLayer','${gaKey}');`,
                }}
            />
            {/* <Script */}
            {/*    id="sport-radar" */}
            {/*    async={true} */}
            {/*    strategy="afterInteractive" */}
            {/*    dangerouslySetInnerHTML={{ */}
            {/*        __html: ` */}
            {/*              ;(function (a, b, c, d, e, f) { */}
            {/*              a[e]=a[e]||[];a.x=b.createElement(c);a.y=b.getElementsByTagName(c)[0];x.src=d+"?id="+f;x.setAttribute("data-srtmn",e);y.parentNode.insertBefore(x,y); */}
            {/*              }(window, document, "script", 'https://tm.ads.sportradar.com/dist/tag-manager.js', "srtmCommands", "STM-AAAAJ2")); */}
            {/*                  `, */}
            {/*    }} */}
            {/* /> */}
        </>
    );
};

export default LoadScript;
