import type { GetServerSidePropsResult } from 'next';

import { logError } from 'services/logger';
import { captureSentryException } from 'services/sentry';
import { getRouterQuery } from 'utils';
import getSettledResult from 'utils/getSettledResult';
import setExtendedContext from './utils/setExtendedContext';
import warnOnKeysDuplication from './utils/warnOnKeysDuplication';
import type {
    GetServerSidePropsWithMainApolloClient,
    WithMainApolloClientContext,
} from './types';

const makeWithServerProps =
    <
        T extends (
            ctx: C
        ) => ReturnType<GetServerSidePropsWithMainApolloClient>,
        C extends WithMainApolloClientContext,
    >(
        sharedGetServerSidePropsFns: Array<T>
    ) =>
    (pageGetServerSidePropsFn?: T) => {
        return async (ctx: C): Promise<GetServerSidePropsResult<any>> => {
            const executedGSSPs = sharedGetServerSidePropsFns.map((f) =>
                f(ctx)
            );

            const { pages } = getRouterQuery(ctx.query);
            const isDynamicPage = !!pages?.length;

            if (pageGetServerSidePropsFn) {
                executedGSSPs.unshift(pageGetServerSidePropsFn(ctx));
            }

            try {
                const [pageGSSPResult, ...extraGSSPResults] =
                    await getSettledResult<Dict[]>({
                        promises: executedGSSPs,
                        label: 'makeWithServerProps',
                        errorMessage: 'makeWithServerProps request failed',
                        request: ctx.req,
                        skipSentry: isDynamicPage,
                    });

                const mergableResults = [
                    ...extraGSSPResults,
                    pageGSSPResult,
                ] as (GetServerSidePropsResult<any> | null)[];

                const response = mergableResults.reduce(
                    (acc: any, current) => {
                        if (current === null) {
                            return acc;
                        }

                        const { props, notFound } = acc;

                        if ('notFound' in current && !notFound) {
                            acc.notFound = current.notFound;
                        }

                        if ('redirect' in current) {
                            acc.redirect = current.redirect;

                            const redirects = ctx?.redirects || [];

                            redirects.push(current.redirect);

                            setExtendedContext(ctx, 'redirects', redirects);
                        }

                        if ('props' in current) {
                            warnOnKeysDuplication(
                                current.props,
                                props,
                                ctx.resolvedUrl
                            );

                            return {
                                ...acc,
                                props: {
                                    ...props,
                                    ...current.props,
                                    gsspData: {
                                        ...props.gsspData,
                                        ...current.props.gsspData,
                                        widgets: {
                                            ...(props.gsspData?.widgets || {}),
                                            ...(current.props.gsspData
                                                ?.widgets || {}),
                                        },
                                        keyValue: {
                                            ...(props.gsspData?.keyValue || {}),
                                            ...(current.props.gsspData
                                                ?.keyValue || {}),
                                        },
                                    },
                                    messages: {
                                        ...props.messages,
                                        ...(current.props.messages || {}),
                                    },
                                },
                            };
                        }

                        return acc;
                    },
                    {
                        props: {
                            messages: {},
                        },
                    }
                );

                return response;
            } catch (error: any) {
                logError({
                    message: 'Error "makeWithServerProps"',
                    err: error,
                    request: ctx.req,
                });

                captureSentryException({
                    label: 'makeWithServerProps',
                    message: error?.message || 'Error "makeWithServerProps"',
                    skip: isDynamicPage,
                });

                return {
                    props: {},
                };
            }
        };
    };

export default makeWithServerProps;
