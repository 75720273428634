import React, { memo } from 'react';
import cn from 'classnames';

import PackIcon, { IconsPack } from 'components/PackIcon';
import { IPhone } from '../useFooterPhones';

interface Props {
    activePhone: IPhone;
    clicked: boolean;
    clickHandler: () => void;
}

const FooterPhonesHeader: React.FC<Props> = ({
    activePhone,
    clicked,
    clickHandler,
}) => {
    return (
        <div
            className={cn(
                'flex items-center justify-between rounded-default bg-surface-middle px-2 py-3 text-base transition-colors hover:cursor-pointer',
                clicked ? 'text-primary-white' : 'text-grey-500'
            )}
            onClick={clickHandler}
            data-test="footer-phones-header"
        >
            <div className="flex items-center">
                <PackIcon
                    id="phone"
                    pack={IconsPack.ActionIcons}
                    className={cn(
                        'mr-2 text-base',
                        clicked ? 'fill-primary-white' : 'fill-grey-500'
                    )}
                />
                {activePhone.phone}
            </div>
            <PackIcon
                id="branch-more-up"
                key="branch-more-up"
                pack={IconsPack.SpriteIcons}
                className={cn(
                    'text-base transition duration-300',
                    clicked ? 'fill-primary-white' : 'rotate-180 fill-grey-500'
                )}
            />
        </div>
    );
};

export default memo(FooterPhonesHeader);
