import { execute } from '@apollo/client';
import { getCookie } from 'cookies-next';
import { ParsedUrlQuery } from 'querystring';

import { CookiesKeys } from 'types';
import { logError, ResponseError } from '../../../../logger';
import type { WithMainApolloClientContext } from '../../../types';
import { CmsClientOptions } from '../../CmsApolloClient';
import { TrackerRequestKey } from './createCookieTracker';

import setCrmTrackerMutation from './setCrmTracker.cms.gql';

interface Input {
    query: ParsedUrlQuery;
    cmsClientOptions: CmsClientOptions;
    context: WithMainApolloClientContext;
}

const getCrmTrackerHeader = ({
    query,
    cmsClientOptions,
    context,
}: Input): Record<string, string> | null => {
    const { endpoint, req } = cmsClientOptions;
    const crmTraker = query[TrackerRequestKey.CrmNotification] as string;

    if (!crmTraker) return null;

    const token = getCookie(CookiesKeys.Paseto, { req });

    if (token && typeof token === 'string' && context.apolloCmsLinks) {
        const crmTrackerMutationObservable = execute(context.apolloCmsLinks, {
            query: setCrmTrackerMutation,
            variables: {
                crmCode: crmTraker,
            },
            context: {
                headers: {
                    'Content-Type': 'application/json',
                    'x-paseto': token,
                },
            },
        });

        const subscribe = crmTrackerMutationObservable.subscribe(
            ({ errors, data }) => {
                if (errors) {
                    logError<ResponseError['error']>({
                        message: `Mutation ${endpoint}`,
                        err: {
                            ...errors,
                        },
                    });
                }

                if (data) {
                    subscribe.unsubscribe();
                }
            }
        );

        return null;
    }

    return {
        'X-Crm-Notification-Hash': crmTraker,
    };
};

export default getCrmTrackerHeader;
