import { MainLayoutType } from 'app-constants';
import usePlayerBalances from 'hooks/usePlayerBalances';
import { useLayoutContext } from 'layouts/Root/LayoutTypeContext';
import useHeaderBalancesFreebets from './hooks/useHeaderBalancesFreebets';
import useHeaderBalancesInsurance from './hooks/useHeaderBalancesInsurance';
import type { FormattedInsuranceItem, Freebets } from './types';
import useExpiredBonusBalances from './useExpiredBonusBalances';

interface Output {
    currencyCode: string;
    realBalance: number;
    isShowBalanceDropdown: boolean;
    bettingBonuses: number;
    casinoBonuses: number;
    instaBonuses: number;
    totalBalance: number;
    liveCasinoBonuses: number;
    groupBonuses: number;
    insurances: FormattedInsuranceItem[];
    freebets: Freebets;
}

const useHeaderBalance = (): Output | null => {
    useExpiredBonusBalances();
    const playerBalances = usePlayerBalances();
    const { mainLayoutType } = useLayoutContext();
    const { formattedInsurance } = useHeaderBalancesInsurance({
        mainLayoutType,
    });
    const { freebets } = useHeaderBalancesFreebets({ mainLayoutType });

    if (!playerBalances) return null;

    const {
        realBalance,
        totalBalance,
        currencyCode,
        bettingBonuses,
        casinoBonuses,
        instaBonuses,
        liveCasinoBonuses,
        groupBonuses,
        bonusBalances,
    } = playerBalances;

    const isShowBalanceDropdown =
        Boolean(bonusBalances.length) ||
        (mainLayoutType === MainLayoutType.Betting &&
            (formattedInsurance.length > 0 || freebets.length > 0));

    return {
        realBalance,
        totalBalance,
        currencyCode,
        bettingBonuses,
        casinoBonuses,
        instaBonuses,
        liveCasinoBonuses,
        groupBonuses,
        isShowBalanceDropdown,
        insurances: formattedInsurance,
        freebets,
    };
};

export default useHeaderBalance;
