import { MutationTuple } from '@apollo/client';

import StubApolloClient from 'services/apollo/utils/stubs/StubApolloClient';

const setStubUseMutation = (): MutationTuple<any, any> => {
    return [
        async () => ({}),
        {
            data: {},
            error: undefined,
            loading: false,
            client: new StubApolloClient('StubUseMutation'),
            called: false,
            reset() {},
        },
    ];
};

export default setStubUseMutation;
