import { FC, memo, useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import { setCookie } from 'cookies-next';
import { includes } from 'lodash';

import { CookiesType, ModalsHash } from 'app-constants';
import { loginTypeVar } from 'components/modals/RegistrationModal/RegistrationForm/registrationState';
import { useGetFirstDepositFlow } from 'gql/cms/queries/getFirstDepositFlow.cms';
import { useAuthorization } from 'hooks';
import { useResponsive } from 'layouts/MainLayout/ResponsiveLayoutContext';
import { useRouter, useRouterPush } from '../HybridRouter';
import useModal from '../modals/useModal';
import { useGetNewsDetails } from '../News/NewsDetails/getNewsDetails.cms';

const popupDepositSuccess = '#popup-deposit-success';

const availableModalHash = [
    ModalsHash.EmailVerification,
    ModalsHash.PhoneVerification,
    ModalsHash.TermsConditions,
    popupDepositSuccess,
];

const ID_WELCOME_BONUS = 'news:welcome-betting';

const FirstRequiredDeposit: FC = () => {
    const { isRegistrationFinished } = useAuthorization();

    const { openModal } = useModal();
    const { asPathObj } = useRouter();
    const { until } = useResponsive();
    const { push } = useRouterPush();
    const loginType = useReactiveVar(loginTypeVar);
    const isMobile = until('md');
    const { data } = useGetFirstDepositFlow(() => ({
        skip: !isRegistrationFinished,
        onCompleted: ({ firstRequiredDepositFlow }) => {
            const { firstRequiredDepositFlow: name, paseto } =
                firstRequiredDepositFlow;

            if (
                (!paseto && !name) ||
                includes(availableModalHash, asPathObj?.hash)
            ) {
                return;
            }

            if (paseto) {
                if (isMobile) {
                    setCookie(CookiesType.Paseto, paseto);

                    return;
                }

                return;
            }

            if (loginType === 'tel' && isMobile) {
                loginTypeVar(undefined);
                openModal({ hash: ModalsHash.Deposit });

                return;
            }

            openModal({ hash: ModalsHash.FirstDepositRequired });
        },
        fetchPolicy: 'cache-and-network',
    }));

    const isFirstDepositToken = !!data?.firstRequiredDepositFlow.paseto;

    const { data: newsDetail } = useGetNewsDetails(() => ({
        variables: { id: ID_WELCOME_BONUS },
        fetchPolicy: 'cache-and-network',
        context: {
            important: true,
        },
        skip: !isFirstDepositToken,
    }));

    const bonusUrl = newsDetail?.newsDetail.url;

    useEffect(() => {
        if (
            !isFirstDepositToken ||
            !bonusUrl ||
            !isMobile ||
            !asPathObj?.hash.includes(popupDepositSuccess)
        ) {
            return;
        }

        push(bonusUrl);
    }, [asPathObj?.hash, bonusUrl, isFirstDepositToken, isMobile, push]);

    return null;
};

export default memo(FirstRequiredDeposit);
