import { useCallback, useEffect, useState } from 'react';
import { includes } from 'lodash';

import { KYC_APPROVED_STATUSES } from 'app-constants';
import { useGetDocumentsStatus } from 'gql/cms/queries/kyc/getDocumentsStatus.cms';
import { useGetKyc } from 'gql/cms/queries/kyc/getKyc.cms';
import { useGetKycaidVerifications } from 'gql/cms/queries/kyc/getKycaidVerifications.cms';
import { useAuthorization } from 'hooks';
import {
    KycaidVerificationStatusGraphqlEnum,
    RequiredDocumentStatusTypeEnum,
} from 'types/gql.cms';
import { BannerStates } from './types';
import useVerificationBannerLocalStorage from './useVerificationBannerLocalStorage';
import { setIsVerificationBannerVisible } from './verificationBannerState';

interface Output {
    isShowBanner: boolean;
    bannerStates: BannerStates;
    closeVerificationBanner: VoidFunction;
}

const useVerificationBanner = (): Output => {
    const [hasClosed, setHasClosed] = useState<boolean>(true);

    const { isAuthorized, isRegistrationFinished, profileInfo } =
        useAuthorization();
    const {
        removeUserIdToVerificationBannerIds,
        isCurrentUserClosedBanner,
        addUserIdToVerificationBannerIds,
    } = useVerificationBannerLocalStorage();

    const { data: documentsStatus } = useGetDocumentsStatus(() => ({
        skip: !isRegistrationFinished,
    }));

    const { data: kyc } = useGetKyc(() => ({
        skip: !isRegistrationFinished,
    }));

    const { data: kycaidVerifications } = useGetKycaidVerifications(() => ({
        skip: !isRegistrationFinished,
    }));

    const verifications =
        kycaidVerifications?.kycaidVerifications.verifications || [];

    const isKycaidPending =
        verifications[0]?.status ===
        KycaidVerificationStatusGraphqlEnum.Pending;

    const { status } = documentsStatus?.requiredDocumentsStatus || {};

    const kycStatus = kyc?.kyc.status;

    const isKycDeclined = kycStatus === 'declined' && !isKycaidPending;

    const isFullApproved = Boolean(
        kycStatus &&
            !isKycaidPending &&
            includes(KYC_APPROVED_STATUSES, kycStatus) &&
            status === RequiredDocumentStatusTypeEnum.StatusWaitingRequest
    );

    const isWaitingApproval =
        status === RequiredDocumentStatusTypeEnum.StatusWaitingApproval ||
        isKycaidPending;

    const isWaitingDocuments =
        status === RequiredDocumentStatusTypeEnum.StatusWaitingDocuments &&
        !isKycaidPending;

    const playerId =
        profileInfo?.user?.__typename === 'Player' ? profileInfo.user.id : '';

    const isShowBanner =
        isAuthorized &&
        !isKycDeclined &&
        ((isFullApproved && !hasClosed) ||
            isWaitingDocuments ||
            isWaitingApproval);

    useEffect(() => {
        if (!status || !kycStatus) return;

        if (!isFullApproved) {
            removeUserIdToVerificationBannerIds(playerId);
        }

        setHasClosed(isCurrentUserClosedBanner(playerId));
    }, [
        kycStatus,
        status,
        playerId,
        isCurrentUserClosedBanner,
        removeUserIdToVerificationBannerIds,
        isFullApproved,
    ]);

    useEffect(() => {
        setIsVerificationBannerVisible(isShowBanner);
    }, [isShowBanner]);

    const closeVerificationBanner = useCallback(() => {
        setHasClosed(true);
        addUserIdToVerificationBannerIds(playerId);
    }, [addUserIdToVerificationBannerIds, playerId]);

    return {
        isShowBanner,
        closeVerificationBanner,
        bannerStates: {
            isFullApproved,
            isWaitingApproval,
            isWaitingDocuments,
        },
    };
};

export default useVerificationBanner;
