import type { ServerError } from '@apollo/client';
import type { NetworkError } from '@apollo/client/errors';

import formatNetworkErrorResult from 'utils/formatNetworkErrorResult';

interface ErrorInfo {
    extensions?: Record<string, any>;
    message?: string;
}

function getErrorInfo(
    networkError: NetworkError | undefined
): ErrorInfo | null {
    const errorResult = (networkError as ServerError)?.result;

    return formatNetworkErrorResult(errorResult).find(
        (r: Record<string, any>) => r?.errors
    )?.errors[0];
}

export { getErrorInfo };
