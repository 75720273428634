import * as GQL from 'types/gql.ptm';

import * as Operations from './onUpdateBonusTransfer.ptm.gql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'services/apollo/platform';
const defaultOptions = {} as const;

/**
 * __useOnUpdateBonusTransfer__
 *
 * To run a query within a React component, call `useOnUpdateBonusTransfer` and pass it any options that fit your needs.
 * When your component renders, `useOnUpdateBonusTransfer` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnUpdateBonusTransfer({
 *   variables: {
 *   },
 * });
 */
export function useOnUpdateBonusTransfer(
    baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
        GQL.OnUpdateBonusTransfer,
        GQL.OnUpdateBonusTransferVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSubscription<
        GQL.OnUpdateBonusTransfer,
        GQL.OnUpdateBonusTransferVariables
    >(Operations.OnUpdateBonusTransfer, options);
}
export type OnUpdateBonusTransferHookResult = ReturnType<
    typeof useOnUpdateBonusTransfer
>;
export type OnUpdateBonusTransferSubscriptionResult =
    Apollo.SubscriptionResult<GQL.OnUpdateBonusTransfer>;
