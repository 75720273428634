import { useCallback, useMemo } from 'react';
import omit from 'lodash/omit';

import { useGetTournamentBySlug } from 'gql/cms/queries/tournaments/getTournamentBySlug.cms';
import { useOnTournamentLeaderboardUpdate } from 'gql/platform/subscriptions/onTournamentLeaderBoardUpdate.ptm';
import { useOnTournamentParticipantUpdate } from 'gql/platform/subscriptions/onTournamentParticipantUpdate.ptm';
import { useCmsApolloClient } from 'services/apollo';
import { GetTournamentBySlug } from 'types/gql.cms';
import useActiveTournamentSlug from './useActiveTournamentSlug';
import useAuthorization from './useAuthorization';

interface Input {
    slug?: string;
    isNeedParticipantPlace?: boolean;
}

interface Output {
    isLoading: boolean;
    isActiveTournamentSlugLoading: boolean;
    isAuthorized: boolean;
    tournament: GetTournamentBySlug['tournamentBySlug'];
    description?: string;
    gamesCount: number;
    refetchCurrentTournament: VoidFunction;
}

type TournamentBySlug = NonNullable<GetTournamentBySlug['tournamentBySlug']>;

const useTournament = ({ slug, isNeedParticipantPlace }: Input): Output => {
    const { isAuthorized, profileInfo } = useAuthorization();
    const { activeTournamentSlug, isLoading: isActiveTournamentSlugLoading } =
        useActiveTournamentSlug({ skip: !!slug });

    const tournamentSlug = slug || activeTournamentSlug;
    const {
        client: { cache },
    } = useCmsApolloClient();

    const playerId =
        profileInfo?.user?.__typename === 'Player' ? profileInfo.user.id : '';

    const {
        loading: isLoading,
        refetch,
        data: { tournamentBySlug: tournament } = {},
    } = useGetTournamentBySlug(() => ({
        variables: {
            slug: String(tournamentSlug),
            isNeedParticipantPlace: Boolean(
                isNeedParticipantPlace && isAuthorized
            ),
            isAuthorized,
        },
        skip: !tournamentSlug,
    }));

    const refetchCurrentTournament = useCallback(() => {
        refetch();
    }, [refetch]);

    useOnTournamentLeaderboardUpdate({
        onData: ({ data: { data } }) => {
            if (!data) return;

            cache.modify({
                id: `TournamentLocalized:${tournament?.id}`,
                fields: {
                    participants(
                        currentValue: TournamentBySlug['participants']
                    ) {
                        const updatedParticipants = currentValue.map(
                            (participant) => {
                                const updatedParticipant =
                                    data?.TournamentLeaderboardUpdate?.participants?.find(
                                        (item) => {
                                            // TODO: participant.playerId user like ***111
                                            return (
                                                item?.playerID ===
                                                    participant?.playerId ||
                                                (item?.displayName ===
                                                    participant.displayName &&
                                                    item?.playerID.slice(-3) ===
                                                        participant.playerId.slice(
                                                            -3
                                                        ))
                                            );
                                        }
                                    );

                                const updatedParticipantWithTypename =
                                    participant?.__typename
                                        ? omit(updatedParticipant, [
                                              '__typename',
                                          ])
                                        : updatedParticipant;

                                return updatedParticipant
                                    ? {
                                          ...participant,
                                          ...updatedParticipantWithTypename,
                                      }
                                    : participant;
                            }
                        );

                        const newParticipants =
                            data?.TournamentLeaderboardUpdate?.participants?.filter(
                                (item) =>
                                    !currentValue.some(
                                        (participant) =>
                                            // TODO: participant.playerId user like ***111
                                            participant?.playerId ===
                                                item?.playerID ||
                                            (item?.displayName ===
                                                participant.displayName &&
                                                item?.playerID.slice(-3) ===
                                                    participant.playerId.slice(
                                                        -3
                                                    ))
                                    )
                            ) as any;

                        return [
                            ...updatedParticipants,
                            ...newParticipants,
                        ].sort(
                            (a, b) =>
                                parseFloat(b.points) - parseFloat(a.points)
                        );
                    },
                },
            });
        },
    });

    useOnTournamentParticipantUpdate({
        onData: ({ data: { data } }) => {
            if (!data) return;

            const newParticipantValuesWithoutTypename = omit(
                data.TournamentParticipantUpdate,
                ['__typename']
            );

            cache.modify({
                id: `TournamentLocalized:${tournament?.id}`,
                fields: {
                    participant(currentValue: TournamentBySlug['participant']) {
                        const newParticipantValues = currentValue?.__typename
                            ? newParticipantValuesWithoutTypename
                            : data.TournamentParticipantUpdate;

                        return {
                            ...currentValue,
                            ...newParticipantValues,
                        };
                    },

                    participants(
                        currentValue: TournamentBySlug['participants']
                    ) {
                        return currentValue
                            .map((participant) => {
                                if (participant.playerId === playerId) {
                                    return {
                                        ...participant,
                                        ...newParticipantValuesWithoutTypename,
                                    };
                                }

                                return participant;
                            })
                            .sort(
                                (a, b) =>
                                    parseFloat(b.points) - parseFloat(a.points)
                            );
                    },
                },
            });
        },
        skip: !isAuthorized,
    });

    const description = useMemo(
        () =>
            tournament?.texts?.find(({ type }) => type === 'description')?.text,
        [tournament]
    );

    const gamesCount = tournament?.games?.length || 0;

    return {
        isLoading,
        isActiveTournamentSlugLoading,
        isAuthorized,
        tournament,
        description,
        gamesCount,
        refetchCurrentTournament,
    };
};

export default useTournament;
