import { useCallback, useEffect, useState } from 'react';

import { KeyValue, ModalsHash } from 'app-constants';
import useModal from 'components/modals/useModal';
import { useGetGeoIpLocation } from 'gql/cms/queries/getGeoIpLocation.cms';
import getPaymentHistory from 'gql/cms/queries/getPaymentHistory.cms.gql';
import getFilesHistory from 'gql/cms/queries/kyc/getFilesHistory.cms.gql';
import { useGetKyc } from 'gql/cms/queries/kyc/getKyc.cms';
import { useLazyQuery } from 'services/apollo/cms';
import {
    GetFilesHistory,
    GetFilesHistoryVariables,
    GetPaymentHistory,
    GetPaymentHistoryVariables,
    PaymentMethodTypeEnum,
    RequiredDocumentKycTypeEnum,
} from 'types/gql.cms';
import useAuthorization from './useAuthorization';
import useKeyValue from './useKeyValue';

const KYC_REQUIRED_DOCUMENT_TYPES: Array<string> = [
    RequiredDocumentKycTypeEnum.IdPass,
    RequiredDocumentKycTypeEnum.DrivingLicence,
    RequiredDocumentKycTypeEnum.NationalIdentityCard,
];

const COUNTRY_FOR_DEPOSIT_VALIDATION = 'de';
const MAX_KYC_FILES_NUMBER_TO_SHOW = 0;
const MIN_DEPOSIT_COUNTS_TO_SHOW = 1;
const LOCAL_STORAGE_FLAG_NAME = 'deDepDocsMissingPopupShowed';
const LOCAL_STORAGE_KYC_NEEDED_COUNTRY = 'kyc-needed-country';

interface Output {
    handleKycCheck: VoidFunction;
    kycStatus?: string;
}

const useKycCheck = (): Output => {
    const [countryByIp, setCountryByIp] = useState<string>('');
    const { openModal } = useModal();
    const { isAuthorized } = useAuthorization();
    const { value: kycSkippedMethods } = useKeyValue({
        key: KeyValue.DE_KYC_SKIPPED_METHODS,
        skip: !isAuthorized,
    });
    const { value: kycDepositEnabled } = useKeyValue({
        key: KeyValue.DE_KYC_DEPOSIT_ENABLED,
        skip: !isAuthorized,
    });
    const { data: countryByUserIp } = useGetGeoIpLocation();
    const { data: kycData } = useGetKyc(() => {
        return {
            skip: !isAuthorized,
        };
    });
    const [fetchPaymentHistory] = useLazyQuery<
        GetPaymentHistory,
        GetPaymentHistoryVariables
    >(getPaymentHistory, {
        variables: {
            type: PaymentMethodTypeEnum.TypeDeposit,
            statuses: ['finished'],
        },
    });

    const [fetchFilesHistory] = useLazyQuery<
        GetFilesHistory,
        GetFilesHistoryVariables
    >(getFilesHistory);

    useEffect(() => {
        const country =
            localStorage.getItem(LOCAL_STORAGE_KYC_NEEDED_COUNTRY) ||
            countryByUserIp?.geoIpLocation.countryIsoCode ||
            '';
        setCountryByIp(country);
    }, [countryByUserIp]);

    const checkForDocumentAndPayments = useCallback(() => {
        return fetchFilesHistory()
            .then(({ data }) => {
                const requiredDocuments =
                    data?.documents.documents.filter(({ type }) =>
                        KYC_REQUIRED_DOCUMENT_TYPES.includes(type.toUpperCase())
                    ) || [];

                if (requiredDocuments.length <= MAX_KYC_FILES_NUMBER_TO_SHOW)
                    return;

                throw Error('files number irrelevant');
            })
            .then(() => fetchPaymentHistory())
            .then(({ data }) => {
                const paymentHistory = data?.paymentHistory;

                if (!paymentHistory) throw Error('payment methods empty');

                const KYCDepositSkippedMethods = kycSkippedMethods
                    .toLowerCase()
                    .split(',');

                const transactionAllowedKyc =
                    paymentHistory.transactions.filter(
                        ({ paymentMethod }) =>
                            !KYCDepositSkippedMethods.includes(
                                paymentMethod.title.toLowerCase()
                            )
                    );

                if (transactionAllowedKyc.length >= MIN_DEPOSIT_COUNTS_TO_SHOW)
                    return;

                throw Error('payment methods irrelevant');
            });
    }, [fetchFilesHistory, fetchPaymentHistory, kycSkippedMethods]);

    const handleKycCheck = useCallback(() => {
        const isPopupShowed = Boolean(
            localStorage.getItem(LOCAL_STORAGE_FLAG_NAME)
        );

        if (
            isPopupShowed ||
            !isAuthorized ||
            !kycDepositEnabled ||
            Boolean(kycData?.kyc.confirmStatus) ||
            countryByIp !== COUNTRY_FOR_DEPOSIT_VALIDATION
        ) {
            return;
        }

        checkForDocumentAndPayments()
            .then(() => {
                openModal({ hash: ModalsHash.NeedVerification });
            })
            .catch((err) => console.error(err));
    }, [
        openModal,
        checkForDocumentAndPayments,
        countryByIp,
        isAuthorized,
        kycData?.kyc.confirmStatus,
        kycDepositEnabled,
    ]);

    return {
        handleKycCheck,
        kycStatus: kycData?.kyc.status,
    };
};

export default useKycCheck;
