import { CompetitorHomeAway } from 'types/gql.bet';

export const getHomeAwayCompetitors = <
    T extends { homeAway: CompetitorHomeAway },
>(
    competitors: T[]
): [T, T] => {
    const homeCompetitor = competitors.find(
        ({ homeAway }) => homeAway === CompetitorHomeAway.Home
    );
    const awayCompetitor = competitors.find(
        ({ homeAway }) => homeAway === CompetitorHomeAway.Away
    );

    // in a match we must always have two competitors(home/away)
    // get by index competitors[number] for fallback if homeAway type UNKNOWN
    return [homeCompetitor || competitors[0], awayCompetitor || competitors[1]];
};
