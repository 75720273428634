import { ModalsHash } from 'app-constants';
import cmsApolloRedirectVar from '../cmsApolloRedirectVar';
import { ErrorInput } from '../isHandledBackendLimitExceptions';

export default function isLoginBlocked(error: ErrorInput): boolean {
    if (
        error?.message === 'player.limit.crossed' &&
        Array.isArray(error?.extensions?.types) &&
        error?.extensions?.types?.includes('login-blocked')
    ) {
        cmsApolloRedirectVar({
            pathTo: `${ModalsHash.PlayerLimitReached}?type=login-blocked`,
        });

        return true;
    }

    return false;
}
