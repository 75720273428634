import { FC, memo } from 'react';
import Script from 'next/script';

import useAnalyticsSettings from './useAnalyticsSettings';

const AnalyticsSettings: FC = () => {
    const { reportServiceUrl } = useAnalyticsSettings();

    if (!reportServiceUrl) return null;

    return (
        <Script
            id="report-service-pixel"
            src={reportServiceUrl}
            strategy="afterInteractive"
        />
    );
};

export default memo(AnalyticsSettings);
