import React, { useContext } from 'react';

import { BettingInitError } from './utils/getBettingInitError';
import { BettingApolloClient } from './BettingApolloClient';

interface BettingApollo {
    client: BettingApolloClient;
    initBettingClient: VoidFunction;
    bettingInitError?: BettingInitError;
}

export const BettingApolloClientContext = React.createContext<BettingApollo>(
    null as any
);

export const useBettingApolloClient = (): BettingApollo =>
    useContext(BettingApolloClientContext);
