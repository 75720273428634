import { FC, memo } from 'react';

import { withClientRender } from 'hocs';
import useOnKycaidChangeVerificationStatusSubscription from './useOnKycaidChangeVerificationStatusSubscription';
import useOnKycChangeDocumentRequiredStatusSubscription from './useOnKycChangeDocumentRequiredStatusSubscription';
import useOnKycConfirmStatusSubscription from './useOnKycConfirmStatusSubscription';
import useOnSignOutSubscription from './useOnSignOutSubscription';
import useOnUpdateBonusTransferSubscription from './useOnUpdateBonusTransferSubscription';
import useOnUpdateFreeSpinsSubscription from './useOnUpdateFreeSpinsSubscription';
import useOnUpdateProgramSubscription from './useOnUpdateProgramSubscription';
import useOnUpdateRechargeBalanceSubscription from './useOnUpdateRechargeBalanceSubscription';

const MainSubscriptions: FC = () => {
    useOnSignOutSubscription();
    useOnUpdateBonusTransferSubscription();
    useOnUpdateRechargeBalanceSubscription();
    useOnUpdateFreeSpinsSubscription();
    useOnKycConfirmStatusSubscription();
    useOnKycChangeDocumentRequiredStatusSubscription();
    useOnKycaidChangeVerificationStatusSubscription();
    useOnUpdateProgramSubscription();

    return null;
};

export default memo(withClientRender(MainSubscriptions));
