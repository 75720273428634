import {
    createSearchParams,
    NavigateFunction,
    NavigateOptions,
    Params,
    To,
    URLSearchParamsInit,
} from 'react-router-dom';
import { compact, findKey, keys, last, omit, replace } from 'lodash';
import type { UrlObject } from 'url';

import { AppLink } from 'app-constants';

interface CreatePathnameFromDynamicClientRouteInput {
    path: string;
    params: Record<string, string> | Params<string>;
}

const localeRegex = /^(\/)?[a-z]{2}(-[a-z]{2})?(?=\/|#|$)/;

function getLocaleFromUrl(url: string | UrlObject): string | null {
    if (typeof url !== 'string') return null;

    const [localeUrl] = localeRegex.exec(url) || [];

    return localeUrl || null;
}

export function checkLocaleInUrl(url: string | UrlObject): boolean {
    return !!getLocaleFromUrl(url);
}

export function isLocaleDifferentFromLocalizedUrl(
    locale: string | undefined,
    url: string | UrlObject
): boolean {
    if (!locale) return false;

    const localeUrl = getLocaleFromUrl(url);

    return !!(localeUrl && localeUrl !== `/${locale}`);
}

export function removeLocaleFromUrl(path: string): string {
    return replace(path, localeRegex, '');
}

export function formatUrl(url: string | UrlObject): string | To {
    if (typeof url === 'string') return url;

    const query = (url?.query || {}) as URLSearchParamsInit;

    const search = createSearchParams(query).toString();

    return {
        search,
        pathname: url?.pathname || '',
        hash: url?.hash || '',
    };
}

export function navigateLocalized(
    navigate: NavigateFunction,
    currentLocale: string | undefined,
    url: string | UrlObject,
    options?: NavigateOptions
): void {
    let purifiedUrl = url;

    const isLocalizedUrl = checkLocaleInUrl(url);

    if (isLocalizedUrl) {
        if (isLocaleDifferentFromLocalizedUrl(currentLocale, url)) {
            window.location.href = String(url);

            return;
        }

        purifiedUrl = removeLocaleFromUrl(String(url));
    }

    navigate(formatUrl(purifiedUrl), options);
}

export const removeQueryParamsFromUrl = (
    url: string | UrlObject,
    params: Params<string>
): string | UrlObject => {
    if (typeof url === 'string') {
        return url;
    }

    const query =
        typeof url.query === 'string'
            ? url.query
            : omit(url.query, keys(params));

    return {
        ...url,
        query,
    };
};

interface PathnameConverterInput {
    pathname: string;
    params: Params<string>;
}

export const convertClientRouterPathnameToNextPathname = ({
    params,
    pathname,
}: PathnameConverterInput): string => {
    const catchAllParams = params['*'] || '';

    const splittedPathname = compact(
        catchAllParams
            ? pathname.replace(catchAllParams, '').split('/')
            : pathname.split('/')
    );

    const pathSegments = splittedPathname.map((pathSegmet) => {
        const paramPathSegment = findKey(
            params,
            (value) => value === pathSegmet
        );

        return paramPathSegment ? `[${paramPathSegment}]` : pathSegmet;
    });

    if (catchAllParams) {
        const linksWithCatchAllSegment = Object.values(AppLink).filter((link) =>
            link.includes('...')
        );

        const joinedPathSegment = `/${pathSegments.join('/')}`;

        const catchAllSegment = last(
            linksWithCatchAllSegment
                .find((link) =>
                    joinedPathSegment !== '/'
                        ? link.replace(/\/\[\.{3}([^\]]+)\]/, '') ===
                          joinedPathSegment
                        : /\[\.{3}([^\]]+)\]/.test(link)
                )
                ?.split('/')
        );

        if (catchAllSegment) {
            pathSegments.push(catchAllSegment);
        }
    }

    return `/${pathSegments.join('/')}`;
};

interface QueryConverterInput {
    searchParams: URLSearchParams;
    routerParams: Params<string>;
    convertedPathname: string;
}

export const convertClientQueryToNextQuery = ({
    searchParams,
    routerParams,
    convertedPathname,
}: QueryConverterInput): Record<string, string | string[] | undefined> => {
    const isCatchAll = !!routerParams['*'];
    const catchAll: Record<string, string[]> = {};

    const query = {
        ...Object.fromEntries(searchParams.entries()),
        ...omit(routerParams, '*'),
    };

    if (!isCatchAll) return query;

    const catchAllParams = (routerParams['*'] || '').split('/');

    const catchAllSegment =
        last(convertedPathname.split('/'))?.replace(/\[|\]|\./g, '') ||
        'catchAll';

    catchAll[`${catchAllSegment}`] = catchAllParams;

    return {
        ...catchAll,
        ...query,
    };
};

// Matches all instances of [param] or [param...] in the route string
const dynamicRouteRegex = /\[(\.{3})?(\w+)\]/g;

export function convertToClientRoute(route: string): string {
    return route.replace(
        dynamicRouteRegex,
        (_, catchAllParamName, paramName) => {
            if (catchAllParamName === '...') {
                // Use * to denote catch-all routes in React Router
                return `*`;
            }

            return `:${paramName}`;
        }
    );
}

export function createPathnameFromDynamicClientRoute({
    path,
    params,
}: CreatePathnameFromDynamicClientRouteInput): string {
    const dynamicSegmentRegex = /:(\w+)|\*/g;

    if (!dynamicSegmentRegex.test(path) || !Object.keys(params).length) {
        return path;
    }

    return path.replace(dynamicSegmentRegex, (match, key) => {
        if (match === '*') {
            return params['*'] || '';
        }

        return params[key] || '';
    });
}
