import { FC } from 'react';
import { useTranslations } from 'next-intl';

import PackIcon, { IconsPack } from 'components/PackIcon/PackIcon';
import BalanceItem from '../BalanceItem/BalanceItem';
import { FormattedInsuranceItem } from '../types';

interface Props {
    insurances: FormattedInsuranceItem[];
    dataTest?: string;
}

const HeaderBalanceInsurances: FC<Props> = ({ insurances, dataTest }) => {
    const t = useTranslations('Balance');

    if (insurances.length === 0) return null;

    if (insurances.length <= 2) {
        return (
            <>
                {insurances.map((item) => {
                    return (
                        <BalanceItem
                            key={item.id}
                            title={t('gincms.header_balance.insurance')}
                            balance={item.amount}
                            classNames={{
                                title: 'text-green-700',
                                balance: 'text-green-700',
                            }}
                            leftIcon={
                                <PackIcon
                                    id="notification-centre-insurance"
                                    pack={IconsPack.SpriteIcons}
                                    className="fill-green-700"
                                />
                            }
                            dataTest={dataTest}
                        />
                    );
                })}
            </>
        );
    }

    return (
        <BalanceItem
            title={t('gincms.header_balance.insurance')}
            classNames={{ title: 'text-green-700' }}
            leftIcon={
                <PackIcon
                    id="notification-centre-insurance"
                    pack={IconsPack.SpriteIcons}
                    className="fill-green-700"
                />
            }
            rightIcon={
                <div className="flex h-4 w-4 items-center justify-center rounded-full bg-green-700 text-surface-dark">
                    {insurances.length}
                </div>
            }
            dataTest={dataTest}
        />
    );
};

export default HeaderBalanceInsurances;
