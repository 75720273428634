import React from 'react';
import parse from 'html-react-parser';
import Head from 'next/head';

import { GetCurrentMirror, GetSeoRulesByPage } from 'types/gql.cms';

interface Props {
    seo: GetSeoRulesByPage['seoRulesByPage']['seo'];
    info: GetSeoRulesByPage['seoRulesByPage']['info'];
    currentMirrorInfo?: GetCurrentMirror['currentMirror'];
}

const generateHrefLang = (href: string): string => {
    if (!/^http(s)?:/.test(href)) {
        return `https://${href}`;
    }

    return href;
};

const HeadSeoTagsContent: React.FC<Props> = ({
    seo,
    info,
    currentMirrorInfo,
}) => {
    return (
        <Head>
            {info?.title && <title>{info.title}</title>}
            {seo?.keywords && <meta name="keywords" content={seo.keywords} />}
            {info?.description && (
                <meta name="description" content={info.description} />
            )}
            {seo?.metaTags.map(({ name, content }) => (
                <meta key={name} name={name} content={content} />
            ))}
            {info?.canonical && <link rel="canonical" href={info.canonical} />}
            {info?.alternateLinkList?.map(({ href, hrefLang }) => (
                <link
                    rel="alternate"
                    hrefLang={hrefLang}
                    href={generateHrefLang(href)}
                    key={`${hrefLang}:${href}`}
                />
            ))}
            <link
                rel="icon"
                type="image/png"
                href="/spa-assets/image/gg16x16.png"
                sizes="16x16"
            />
            {/* TODO: Provide  images with different sizes for apple webpage icon */}
            {/* <link
                rel="apple-touch-icon"
                sizes="76x76"
                href="/spa-assets/image/favicon/76x76-ggbet.png"
            />
            <link
                rel="apple-touch-icon"
                sizes="120x120"
                href="/spa-assets/image/favicon/120x120-ggbet.png"
            />
            <link
                rel="apple-touch-icon"
                sizes="152x152"
                href="/spa-assets/image/favicon/152x152-ggbet.png"
            /> */}
            <link
                rel="apple-touch-icon"
                sizes="180x180"
                href="/spa-assets/image/favicon/apple-touch-icon.png"
            />
            {/* This is a dangerous code because it can contain script tags from the admin panel */}
            {currentMirrorInfo?.metaTags && parse(currentMirrorInfo.metaTags)}
        </Head>
    );
};

export default HeadSeoTagsContent;
