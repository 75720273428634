import { FC, memo } from 'react';
import cn from 'classnames';
import { useTranslations } from 'next-intl';

import { MainLayoutType } from 'app-constants';
import PackIcon, { IconsPack } from 'components/PackIcon';
import withClientMessages from 'hocs/withClientMessages';
import { useLayoutContext } from 'layouts/Root/LayoutTypeContext';
import BalanceItem from '../BalanceItem';
import FreebetsItem from '../FreebetsItem';
import HeaderBalanceInsurances from '../HeaderBalanceInsurances';
import type { FormattedInsuranceItem, Freebets } from '../types';

interface Props {
    realBalance: number;
    currencyCode: string;
    bettingBonuses: number;
    casinoBonuses: number;
    instaBonuses: number;
    liveCasinoBonuses: number;
    groupBonuses: number;
    insurances: FormattedInsuranceItem[];
    freebets: Freebets;
}

const DropdownBalance: FC<Props> = ({
    realBalance,
    currencyCode,
    bettingBonuses,
    casinoBonuses,
    instaBonuses,
    liveCasinoBonuses,
    groupBonuses,
    insurances,
    freebets,
}) => {
    const t = useTranslations('Balance');
    const { mainLayoutType } = useLayoutContext();

    return (
        <div
            className={cn(
                'absolute left-0 top-10 w-[320px] rounded-default bg-surface-dark p-1 md:left-auto md:right-0 md:top-8',
                'text-primary-white shadow-dropdown'
            )}
            data-test="dropdowm-balance"
        >
            <BalanceItem
                title={t('gincms.header_balance.real_money')}
                href="/"
                leftIcon={<PackIcon pack={IconsPack.ActionIcons} id="wallet" />}
                balance={realBalance}
                currency={currencyCode}
                dataTest="dropdowm-balance__item"
            />

            {!!casinoBonuses && (
                <BalanceItem
                    title={t('gincms.header_balance.bonus_balance_casino')}
                    href="/"
                    leftIcon={
                        <PackIcon
                            pack={IconsPack.NavigationIcons}
                            id="casino"
                        />
                    }
                    balance={casinoBonuses}
                    currency={currencyCode}
                    dataTest="dropdowm-balance__item"
                />
            )}

            {!!bettingBonuses && (
                <BalanceItem
                    title={t('gincms.header_balance.bonus_balance_betting')}
                    href="/"
                    leftIcon={
                        <PackIcon pack={IconsPack.NavigationIcons} id="bets" />
                    }
                    balance={bettingBonuses}
                    currency={currencyCode}
                    dataTest="dropdowm-balance__item"
                />
            )}

            {!!instaBonuses && (
                <BalanceItem
                    title={t(
                        'gincms.header_balance.bonus_balance_instant_games'
                    )}
                    href="/"
                    leftIcon={
                        <PackIcon
                            id="instagames"
                            pack={IconsPack.SpriteIcons}
                        />
                    }
                    balance={instaBonuses}
                    currency={currencyCode}
                    dataTest="dropdowm-balance__item"
                />
            )}

            {!!liveCasinoBonuses && (
                <BalanceItem
                    title={t('gincms.header_balance.bonus_balance_live_casino')}
                    href="/"
                    leftIcon={
                        <PackIcon
                            id="live-casino"
                            pack={IconsPack.SpriteIcons}
                        />
                    }
                    balance={liveCasinoBonuses}
                    currency={currencyCode}
                    dataTest="dropdowm-balance__item"
                />
            )}

            {!!groupBonuses && (
                <BalanceItem
                    title={t('gincms.header_balance.bonus_balance_group')}
                    leftIcon={
                        <PackIcon
                            id="promo-sub-bonus"
                            pack={IconsPack.SpriteIcons}
                        />
                    }
                    balance={groupBonuses}
                    currency={currencyCode}
                    dataTest="dropdowm-balance__item"
                />
            )}

            <BalanceItem
                title={t('gincms.header_balance.withdrawal')}
                href="/"
                leftIcon={<PackIcon pack={IconsPack.ActionIcons} id="wallet" />}
                balance={realBalance}
                currency={currencyCode}
                dataTest="dropdowm-balance__item"
            />

            {mainLayoutType === MainLayoutType.Betting && (
                <FreebetsItem
                    currency={currencyCode}
                    freebets={freebets}
                    dataTest="dropdowm-balance__item"
                />
            )}

            {mainLayoutType === MainLayoutType.Betting && (
                <HeaderBalanceInsurances
                    insurances={insurances}
                    dataTest="dropdowm-balance__item"
                />
            )}
        </div>
    );
};

export default memo(withClientMessages(DropdownBalance, ['Balance']));
