import { NotificationSystemType } from 'components/inboxCenter/constants';

export const ALBERT_HELLO = '/spa-assets/image/euro2024/albert/hello-pose.png';
export const ALBERT_IDLE = '/spa-assets/image/euro2024/albert/idle-pose.png';
export const ALBERT_WIN = '/spa-assets/image/euro2024/albert/win-pose.png';

export const FullPopupButtonTypes = [
    NotificationSystemType.EuroIntro,
    NotificationSystemType.EuroStageStart,
    NotificationSystemType.EuroRicePrizePosition,
    NotificationSystemType.EuroGotPrize,
    NotificationSystemType.EuroSemifinal,
    NotificationSystemType.EuroFinal,
];

export const TransparentButtonTypes = [
    NotificationSystemType.EuroQualified,
    NotificationSystemType.EuroFirstBet,
];

export const albertPose: Record<string, string> = {
    [NotificationSystemType.EuroIntro]: ALBERT_HELLO,
    [NotificationSystemType.EuroStageStart]: ALBERT_IDLE,
    [NotificationSystemType.EuroQualified]: ALBERT_WIN,
    [NotificationSystemType.EuroRicePrizePosition]: ALBERT_WIN,
    [NotificationSystemType.EuroFirstBet]: ALBERT_HELLO,
    [NotificationSystemType.EuroGotPrize]: ALBERT_WIN,
    [NotificationSystemType.EuroSemifinal]: ALBERT_HELLO,
    [NotificationSystemType.EuroFinal]: ALBERT_HELLO,
    [NotificationSystemType.EuroOnboarding]: ALBERT_HELLO,
};
