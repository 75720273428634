import { FC, memo } from 'react';
import cn from 'classnames';

import Time from './Time';

const Clock: FC = () => (
    <div
        className={cn(
            'flex h-8 max-h-8 items-center justify-center gap-1 p-1',
            'pr-2 text-base font-normal text-primary-white',
            'w-full min-w-[148px] bg-surface-middle md:w-[148px] md:max-w-fit'
        )}
    >
        <Time />
    </div>
);

export default memo(Clock);
