import { createContext } from 'react';
import { noop } from 'lodash';

export interface IAlertManager {
    elements: Record<string, JSX.Element>;
}

export interface AlertHandlers {
    addAlertHandler: (id: string, alert: JSX.Element) => void;
    removeAlertHandler: (id: string) => void;
}

const AlertManagerContext = createContext<IAlertManager>({
    elements: {},
});

const AlertHandlersContext = createContext<AlertHandlers>({
    addAlertHandler: noop,
    removeAlertHandler: noop,
});

export { AlertHandlersContext, AlertManagerContext };
