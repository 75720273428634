import { includes } from 'lodash';

import { LayoutType } from 'app-constants';
import { useNotificationsInfo } from 'hooks';
import { Message } from 'types/gql.cms';
import { isEuroMessage } from '../helpers';

interface Input {
    hash?: string;
    setCurrentMessage: (message: Message) => void;
    type: LayoutType;
}

const useInboxPreviewEuroMessage = ({
    hash,
    setCurrentMessage,
    type,
}: Input): void => {
    const skip = !!(
        hash ||
        includes(
            [LayoutType.Game, LayoutType.Casino, LayoutType.CasinoStatic],
            type
        )
    );

    useNotificationsInfo({
        skip,
        onCompleted: ({ messagesInfo }) => {
            const { message } = messagesInfo;

            if (!message) {
                throw new Error('[useNotificationsInfo] no message');
            }

            if (isEuroMessage(message)) {
                setCurrentMessage(message);
            }
        },
    });
};

export default useInboxPreviewEuroMessage;
