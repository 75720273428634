import { useCallback, useEffect, useState } from 'react';

import { BatchKeys, KeyValue } from 'app-constants';
import { useGetGeoIpLocation } from 'gql/cms/queries/getGeoIpLocation.cms';
import { useGetKeyValue } from 'gql/cms/queries/getKeyValue.cms';
import { isSsr } from 'utils/isSsr';

interface Output {
    phones: IPhone[];
    activePhone: IPhone | null;
}

export const DEFAULT_COUNTRY = 'uk';

export interface IPhone {
    id: number;
    country: string;
    phone: string;
    isActive: boolean;
}

const useFooterPhones = (): Output => {
    const [phones, setPhones] = useState<IPhone[]>([]);

    const { data: userGeoIpData } = useGetGeoIpLocation();

    const { data: phonesTemplate } = useGetKeyValue(() => ({
        variables: {
            key: KeyValue.FOOTER_PHONES,
            default: '',
        },
        pagePropsKey: `keyValue.${KeyValue.FOOTER_PHONES}`,
        context: {
            batchKey: BatchKeys.GetKeyValue,
        },
    }));

    const parsePhone = useCallback((phoneString: string, geo: string) => {
        let parsePhones = parseFooterPhones(phoneString, geo);

        if (geo.toLowerCase() === 'ua')
            parsePhones = removeRussianPhoneNumber(parsePhones);

        const noActivePhones = noActivePhonesArray(parsePhones);

        return noActivePhones.length !== parsePhones.length
            ? parsePhones
            : setDefaultActivePhone(noActivePhones);
    }, []);

    const parseFooterPhones = (phonesString: string, geo: string): IPhone[] => {
        const splitPhones = phonesString.split('|');

        return splitPhones.map((phone, index) => {
            const phoneInfo = phone.split(':');

            return {
                id: index,
                country: phoneInfo[0].split(',').join(', ').toUpperCase(),
                phone: phoneInfo[1],
                isActive: phoneInfo[0].includes(geo),
            };
        });
    };

    const removeRussianPhoneNumber = (phonesArray: IPhone[]): IPhone[] => {
        return phonesArray.filter(
            (phone) => !phone.country.toLowerCase().includes('ru')
        );
    };

    const noActivePhonesArray = (phonesArray: IPhone[]) => {
        return phonesArray.filter((phone) => !phone.isActive);
    };

    const setDefaultActivePhone = (phonesArray: IPhone[]): IPhone[] => {
        return phonesArray.map((phone) =>
            phone.country.toLowerCase().includes(DEFAULT_COUNTRY)
                ? Object.assign(phone, { isActive: !phone.isActive })
                : Object.assign(phone, { isActive: phone.isActive })
        );
    };

    useEffect(() => {
        let userGeoIpByLocaleStorage;

        if (!isSsr()) {
            userGeoIpByLocaleStorage =
                window.localStorage.getItem('footer-phone-ip');
        }

        const userGeoIp =
            userGeoIpByLocaleStorage ||
            userGeoIpData?.geoIpLocation.countryIsoCode;
        const phoneString = phonesTemplate?.keyValue.value;

        if (!userGeoIp || !phoneString) return;

        const phoneArray: IPhone[] = parsePhone(phoneString, userGeoIp);
        setPhones(phoneArray);
    }, [userGeoIpData, phonesTemplate, parsePhone]);

    return {
        phones,
        activePhone: phones.find((phone) => phone.isActive) || null,
    };
};

export default useFooterPhones;
