import { useRouter } from 'components/HybridRouter';
import { RouterQuery } from 'types';
import { getRouterQuery } from 'utils';

const useRouterQuery = (): RouterQuery => {
    const { query } = useRouter();

    return getRouterQuery(query);
};

export default useRouterQuery;
