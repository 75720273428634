import { FC } from 'react';
import cn from 'classnames';
import { useTranslations } from 'next-intl';

import { WidgetNames } from 'app-constants';
import Widget from 'components/Widget';
import { useGsspData } from 'layouts/Root/PagePropsContext';

import styles from '../../footerLinks.module.css';

const FooterEmail: FC = () => {
    const t = useTranslations('Footer');
    const list = cn('grid grid-cols-1');
    const { widgets } = useGsspData();

    return (
        <>
            <div
                className="mb-6 text-md font-bold text-primary-orange-toxic"
                data-test="footer-email"
            >
                {t('gincms.footer.title.support')}
            </div>

            <div className="mb-1">
                <Widget
                    name={WidgetNames.FOOTER_SUPPORT_EMAIL}
                    className={cn(list, styles.links)}
                    widgetData={
                        widgets?.[WidgetNames.FOOTER_SUPPORT_EMAIL]
                            ?.widgetByName?.template
                    }
                />
            </div>
        </>
    );
};

export default FooterEmail;
