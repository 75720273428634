import React, { FC, memo } from 'react';

import { isSsr } from 'utils/isSsr';
import FooterSportListClient from './FooterSportLinksClient';
import FooterSportListContent from './FooterSportLinksContent';
import type { FooterSportListProps as Props } from './types';

const FooterSportLinks: FC<Props> = ({ id, sportList }) => {
    if (isSsr()) {
        return <FooterSportListContent sportList={sportList} />;
    }

    return <FooterSportListClient id={id} sportList={sportList} />;
};

export default memo(FooterSportLinks);
