/** @type {import("@types/tailwindcss/tailwind-config").TailwindConfig } */
const plugin = require('tailwindcss/plugin');

module.exports = {
    content: ['./src/**/*.{ts,tsx}'],
    theme: {
        screens: {
            xs: '360px',
            sm: '760px',
            md: '1024px',
            lg: '1440px',
            xl: '1620px',
        },
        fontFamily: {
            roboto: 'Roboto',
            klapt: 'Klapt Cyrillic',
        },
        fontSize: {
            xs: ['0.625rem', { lineHeight: '1rem' }],
            sm: ['0.75rem', { lineHeight: '1rem' }],
            base: ['0.875rem', { lineHeight: '1.25rem' }],
            md: ['1rem', { lineHeight: '1.5rem' }],
            'lg-1': ['1.125rem', { lineHeight: '1.5rem' }],
            'lg-2': ['1.375rem', { lineHeight: '1.75rem' }],
            'xl-1': ['1.75rem', { lineHeight: '2rem' }],
            'xl-2': ['2.75rem', { lineHeight: '3.5rem' }],
            'xl-3': ['4rem', { lineHeight: '4.25rem' }],
        },
        lineHeight: {
            none: '1',
            4: '1rem',
            5: '1.25rem',
            6: '1.5rem',
            7: '1.75rem',
            8: '2rem',
            'xl-2': '3.5rem',
            'xl-3': '4.25rem',
        },
        fontWeight: {
            normal: '400',
            medium: '500',
            bold: '700',
        },
        spacing: {
            // !!!! IMPORTANT !!!!
            // Do not add any new spacing values without approval.
            // If a new spacing is found in the design, it is necessary
            // to agree with the designers before adding it.
            0: '0rem',
            '1/2': '0.125rem', // 2px
            1: '0.25rem', //4px
            2: '0.5rem', //8px
            3: '0.75rem', //'12px'
            4: '1rem', //'16px'
            5: '1.25rem', //'20px
            6: '1.5rem', //'24px'
            8: '2rem', //'32px'
            10: '2.5rem', //'40px'
            12: '3rem', //'48px'
            14: '3.5rem', //'56px'
            20: '5rem', // 80px

            // Custom sync CMS spacings
            'footer-offset': '3rem', //'48px'
            'header-offset': '3rem', // 48px
            'app-banner-offset': '4.25rem', //68px,
            'broadcast-banner-offset': '2.5rem', //40px
            'header-group-offset': 'var(--header-height)', // dynamic header height based on broadcast, header, app banner
        },
        gap: ({ theme }) => theme('spacing'),
        margin: ({ theme }) => ({
            auto: 'auto',
            ...theme('spacing'),
        }),
        padding: ({ theme }) => theme('spacing'),
        height: ({ theme }) => ({
            auto: 'auto',
            ...theme('spacing'),
            screen: '100vh',
            full: '100%',
        }),
        width: ({ theme }) => ({
            auto: 'auto',
            full: '100%',
            ...theme('spacing'),
        }),
        colors: {
            transparent: 'transparent',
            current: 'currentColor',
            primary: {
                'orange-toxic': '#ff6633',
                'orange-toxic-hover': '#ff5522',
                'blue-toxic': '#3994ff',
                'blue-toxic-hover': '#2777d7',
                black: '#000c10',
                white: '#ffffff',
            },
            surface: {
                dark: '#1c1c1c',
                middle: '#262626',
                light: '#3b3b3c',
                'white-dark': '#d2d2d2',
                'white-middle': '#e1e1e1',
                'white-light': '#f0f0f0',
                input: '#2C2C2C',
            },
            grey: {
                900: '#202020',
                700: '#606060',
                500: '#9d9d9d',
                300: '#dfdfdf',
                100: '#f5f5f5',
            },
            red: {
                900: '#aa0000',
                700: '#d40000',
                500: '#ff0000',
                300: '#ff6262',
                100: '#ffc0c0',
            },
            yellow: {
                900: '#cc8800',
                700: '#eea600',
                500: '#ffbb00',
                300: '#ffdd77',
                100: '#ffeebb',
            },
            crimson: {
                900: '#860d80',
                700: '#d400d1',
                500: '#ff1bfc',
                300: '#ff8bff',
                100: '#ffd2ff',
            },
            violet: {
                900: '#502f97',
                700: '#604bce',
                500: '#9758ff',
                300: '#bb9bff',
                100: '#decfff',
            },
            turquoise: {
                900: '#006a72',
                700: '#00acc0',
                500: '#00dbff',
                300: '#68f6ff',
                100: '#b1f9ff',
            },
            green: {
                900: '#125325',
                700: '#39a463',
                500: '#00ff85',
                300: '#8ffbb8',
                100: '#c5fcd6',
            },
            khaki: {
                900: '#5e6900',
                700: '#abb700',
                500: '#c6ff00',
                300: '#dcfd67',
                100: '#eefbb7',
            },
        },
        extend: {
            boxShadow: {
                underline: 'inset 0 -2px 0 0 rgb(0 0 0 / 0.1)',
                border: 'inset 0 0 1px 1px transparent',
                dropdown: '0 0 24px rgb(0 0 0 / 0.5)',
            },
            backgroundImage: {
                'radial-gradient-b-large':
                    'radial-gradient(100% 100% at 50% 100%, var(--tw-gradient-stops))',
                'radial-gradient-b-small':
                    'radial-gradient(100% 100% at 49.87% 0%, var(--tw-gradient-stops))',
                'linear-gradient-b':
                    'linear-gradient(360deg, #262626 0%, rgba(38, 38, 38, 0.463542) 53.65%, rgba(38, 38, 38, 0) 100%)',
                'linear-gradient-left':
                    'linear-gradient(270deg, rgba(0,0,0,0.4), transparent)',
            },
            keyframes: {
                'fade-in': {
                    '0%': {
                        opacity: '0',
                    },
                    '100%': {
                        opacity: '1',
                    },
                },
                'fade-out': {
                    '0%': {
                        opacity: '1',
                    },
                    '100%': {
                        opacity: '0',
                    },
                },
                'open-alert': {
                    '0%': { transform: 'translateX(100%)' },
                    '80%': { transform: 'translateX(-5px)' },
                    '100%': { transform: 'translateX(0)' },
                },
                'close-alert': {
                    '0%': { transform: 'translateX(0)' },
                    '20%': { transform: 'translateX(-5px)' },
                    '100%': { transform: 'translateX(100%)' },
                },
                flashing: {
                    '0%': { opacity: '0' },
                    '50%': { opacity: '1' },
                    '100%': { opacity: '0' },
                },
                'fill-x': {
                    '0%': { transform: 'translateX(0)' },
                    '100%': { transform: 'translateX(-100%)' },
                },
                'open-bonuses-popup-mobile': {
                    '0%': { bottom: '10%' },
                    '100%': { bottom: '48px' },
                },
                'open-bonuses-popup-desktop': {
                    '0%': { bottom: '40%' },
                    '100%': { bottom: '50%' },
                },
            },
            animation: {
                'open-alert':
                    'open-alert .5s cubic-bezier(.175, .885, .32, 1.275)',
                'close-alert':
                    'close-alert 1.1s cubic-bezier(.175, .885, .32, 1.275)',
                'fade-in': 'fade-in .2s ',
                'fade-out': 'fade-out .2s ',
                flashing: 'flashing infinite 1s',
                'fill-x': 'fill-x 6s linear 1',
                'open-bonuses-popup-mobile': 'open-bonuses-popup-mobile .3s',
                'open-bonuses-popup-desktop': 'open-bonuses-popup-desktop .3s',
            },
            width: {
                90: '90%',
                80: '80%',
            },
            maxWidth: {
                1296: '1296px',
                792: '792px',
                312: '312px',
                200: '200px',
                80: '80px',
            },
            minWidth: {
                200: '200px',
            },
            zIndex: {
                none: 'unset',
                1: '1',
                2: '2',
                10: '10', // betslip, fixed btns in tournaments
                20: '20', // preview notifications
                30: '30',
                40: '40',
                50: '50',
                60: '60',
                70: '70', // burger menu
                80: '80', // nav menu, header
                90: '90', // popups, cookies-agreements
                100: '100', // alerts
                max: '999', // loaders
            },
        },
        borderRadius: {
            none: '0px',
            default: '2px',
            small: '4px',
            medium: '8px',
            large: '16px',
            full: '9999px',
        },
        borderWidth: {
            default: '1px',
            2: '2px',
        },
    },
    plugins: [
        plugin(({ addVariant }) => {
            addVariant('last-not-first', '&:last-child:not(:first-child)');
            addVariant('first-not-last', '&:first-child:not(:last-child)');
            addVariant('not-last', '&:not(:last-child)');
        }),
        function ({ addUtilities }) {
            addUtilities({
                '.typo-xs-1': {
                    '@apply text-xs': {},
                },
                '.typo-xs-2': {
                    '@apply text-xs font-bold': {},
                },
                '.typo-sm-1': {
                    '@apply text-sm': {},
                },
                '.typo-sm-2': {
                    '@apply text-sm font-bold': {},
                },
                '.typo-md-1': {
                    '@apply text-md': {},
                },
                '.typo-md-2': {
                    '@apply text-md font-bold': {},
                },
                '.typo-base': {
                    '@apply text-base': {},
                },
                '.typo-lg-1': {
                    '@apply text-lg-1': {},
                },
                '.typo-lg-2': {
                    '@apply text-lg-2 font-medium': {},
                },
                '.typo-xl-1': {
                    '@apply text-xl-1 font-bold': {},
                },
                '.typo-xl-2': {
                    '@apply text-xl-2 font-medium': {},
                },
                '.typo-xl-3': {
                    '@apply text-xl-3': {},
                },
            });
        },
    ],
    future: {
        hoverOnlyWhenSupported: true,
    },
};
