import { FieldPolicy } from '@apollo/client/cache';
import {
    KeyArgsFunction,
    KeySpecifier,
} from '@apollo/client/cache/inmemory/policies';
import { offsetLimitPagination } from '@apollo/client/utilities';
import { concat, get, isUndefined, merge, omit, set } from 'lodash';

// * Inspired by original offsetLimitPagination function
// * https://github.com/apollographql/apollo-client/blob/main/src/utilities/policies/pagination.ts#L28

interface NestedOptions {
    nestedKeys?: string | string[];
    nestedArgs?: string | string[];
}
type Existing = Record<string, any> | Array<any> | null | undefined;
type Incoming = Record<string, any> | Array<any>;

/**
 * This function is called `nestedOffsetLimitPagination` and is used to implement offset-based pagination
 * for nested fields in GraphQL queries. It takes in two optional parameters, `nestedKeys` and `nestedArgs`,
 * which allow for pagination of nested arrays within the data being returned by a query. The function returns
 * an object with two properties, `keyArgs` and `merge`, that can be used as field policies for the Apollo cache.
 *
 * @param {object} [options={}] - An optional object that can contain `nestedKeys` and/or `nestedArgs` properties.
 * @param {string | string[]} [options.nestedKeys] - An optional string or array of strings that specifies the
 *     path to the nested array that should be paginated.
 * @param {string | string[]} [options.nestedArgs] - An optional string or array of strings that specifies the
 *     path to the arguments that control the pagination of the nested array.
 * @param {object | function | false} [keyArgs=false] - An optional object or function that specifies the arguments
 *     to be used as the cache key for the field. If set to `false`, the default keyArgs for offset-based pagination
 *     will be used.
 * @returns {object} An object with two properties, `keyArgs` and `merge`, that can be used as field policies for
 *     the Apollo cache.
 */
export default function nestedOffsetLimitPagination(
    { nestedKeys, nestedArgs }: NestedOptions = {},
    keyArgs: KeySpecifier | KeyArgsFunction | false = false
): Pick<FieldPolicy, 'keyArgs' | 'merge'> {
    if (isUndefined(nestedKeys) && isUndefined(nestedArgs)) {
        return offsetLimitPagination(keyArgs);
    }

    return {
        keyArgs,
        merge: (existing: Existing, incoming: Incoming, { args }) => {
            if (
                nestedKeys &&
                !Array.isArray(existing) &&
                !Array.isArray(incoming)
            ) {
                const parsedKeys = Array.isArray(nestedKeys)
                    ? nestedKeys
                    : nestedKeys.split('.');

                let merged = { ...existing };

                parsedKeys.reduce((acc, key, index) => {
                    if (!acc[key]) acc[key] = {};

                    if (index === parsedKeys.length - 1) {
                        acc[key] = get(existing, parsedKeys, []).slice(0);
                    }

                    return acc[key];
                }, merged);

                if (get(incoming, nestedKeys)) {
                    if (args) {
                        const { offset = 0 } =
                            (nestedArgs && get(args, nestedArgs)) || args;
                        const incomingValues = get(
                            incoming,
                            nestedKeys
                        ) as any[];

                        incomingValues.forEach((value, index) => {
                            set(merged, [...parsedKeys, offset + index], value);
                        });

                        // NOTE: this fix bug with offsetLimitPagination when incoming values is smaller than existing values in cache
                        const mergedValues = get(merged, parsedKeys, []);

                        if (
                            mergedValues.length >
                            incomingValues.length + offset
                        ) {
                            set(
                                merged,
                                parsedKeys,
                                mergedValues.slice(
                                    0,
                                    incomingValues.length + offset
                                )
                            );
                        }
                    } else {
                        set(
                            merged,
                            nestedKeys,
                            concat(
                                get(merged, nestedKeys, []),
                                get(incoming, nestedKeys, [])
                            )
                        );
                    }

                    const incomingWithoutNested = omit(
                        incoming,
                        Array.isArray(nestedKeys)
                            ? nestedKeys.join('.')
                            : nestedKeys
                    );

                    merged = merge(merged, incomingWithoutNested);
                }

                return merged;
            }

            if (
                nestedArgs &&
                (isUndefined(existing) || Array.isArray(existing)) &&
                (isUndefined(incoming) || Array.isArray(incoming))
            ) {
                let merged = existing ? [...existing] : [];

                if (incoming) {
                    if (args) {
                        const { offset = 0 } = get(args, nestedArgs);

                        incoming.forEach((value, index) => {
                            set(merged, [offset + index], value);
                        });

                        // NOTE: this fix bug with offsetLimitPagination when incoming values is smaller than existing values in cache
                        if (merged.length > incoming.length + offset) {
                            merged = merged.slice(0, incoming.length + offset);
                        }
                    } else {
                        merged = concat(merged, incoming, []);
                    }
                }

                return merged;
            }

            return concat(existing, incoming, []);
        },
    };
}
