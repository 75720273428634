import { FC } from 'react';
import { useReactiveVar } from '@apollo/client';

import { ModalsHash } from 'app-constants';
import { isPreviewAnimatingVar } from 'components/inboxCenter/inboxCenterState';
import LocaleSwitcher from 'components/LocaleSwitcher';
import Logout from 'components/Logout';
import MenuTabDesktop from 'components/MenuTabDesktop';
import PackIcon, { IconsPack } from 'components/PackIcon';
import {
    useActiveLink,
    useAuthorization,
    useKYC,
    useNotificationsInfo,
} from 'hooks';
import { useResponsive } from 'layouts/MainLayout/ResponsiveLayoutContext';
import HeaderBalance from '../HeaderBalance';
import HeaderButtons from '../HeaderButtons';
import useHeaderResizeObserver from '../useHeaderResizeObserver';

const HeaderControls: FC = () => {
    const isMobile = useResponsive().until('md');
    const { checkActiveLink } = useActiveLink();
    const { isAuthorized, profileInfo, isRegistrationFinished } =
        useAuthorization();
    const { unread } = useNotificationsInfo({ skip: !isRegistrationFinished });
    const isPreviewAnimating = useReactiveVar(isPreviewAnimatingVar);
    const { isApprovedStatus } = useKYC();

    const { elementRef: controlsRef } = useHeaderResizeObserver<HTMLDivElement>(
        {
            elementName: 'controlsOffset',
        }
    );

    const playerId: string =
        profileInfo?.user?.__typename === 'Player' ? profileInfo?.user?.id : '';

    return (
        <div
            ref={controlsRef}
            className="flex items-center"
            data-test="header-controls"
        >
            <LocaleSwitcher className="mr-2 hidden md:flex" />

            {isAuthorized && (
                <>
                    {!isMobile && (
                        <>
                            <MenuTabDesktop
                                id="Profile data"
                                actionHandler={
                                    isApprovedStatus
                                        ? ModalsHash.ProfileData
                                        : ModalsHash.KYC
                                }
                                className="mr-2 hidden text-turquoise-700 md:flex"
                                typo="typo-sm-1"
                                dataTest="header-controls__profile-link"
                            >
                                {`ID: ${playerId}`}
                            </MenuTabDesktop>

                            <MenuTabDesktop
                                id="Notifications"
                                actionHandler={ModalsHash.Notifications}
                                iconsLeft={
                                    <PackIcon
                                        id={
                                            unread
                                                ? 'notification-yes'
                                                : 'notification'
                                        }
                                        pack={IconsPack.ActionIcons}
                                    />
                                }
                                className="mr-2 hidden md:flex"
                                isSelected={
                                    checkActiveLink(ModalsHash.Notifications) ||
                                    isPreviewAnimating
                                }
                                dataTest="header-controls__notification"
                            />

                            <Logout />
                        </>
                    )}

                    <HeaderBalance className="mr-2" />
                </>
            )}

            <HeaderButtons isAuthorized={isAuthorized} />
        </div>
    );
};

export default HeaderControls;
