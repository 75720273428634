export enum AppLink {
    APP = '/app',
    BETS = '/bets',
    BETTINGPROMOTIONS = '/betting-promotions',
    BETTINGTOURNAMENTS_SLUG = '/betting-tournaments/[slug]',
    BONUSCHEST = '/bonus-chest',
    CASINO = '/casino',
    CASINOPROMOTIONS = '/casino-promotions',
    CASINOPROMOTIONS_SLUG = '/casino-promotions/[slug]',
    CASINO_CATEGORY_SLUG = '/casino/[categorySlug]',
    CASINO_GAMEDEMO_SLUG = '/casino/game-demo/[slug]',
    CASINO_GAME_SLUG = '/casino/game/[slug]',
    CATCHALL_PAGES = '/[...pages]',
    CATEGORY_FAVORITE = '/category/favorite',
    CATEGORY_LASTPLAYED = '/category/last-played',
    COLLECTIONS = '/collections',
    COLLECTION_COLLECTION_SLUG = '/collection/[collectionSlug]',
    DUMMYCOUNTRY = '/dummy-country',
    ESPORTS = '/esports',
    ESPORTS_MATCH_SLUG = '/esports/match/[slug]',
    ESPORTS_OUTRIGHT_SLUG = '/esports/outright/[slug]',
    ESPORTS_RESULT_SLUG = '/esports/result/[slug]',
    ESPORTS_SPORT_SLUG_PLAYER_SLUG = '/esports/[sportSlug]/player/[slug]',
    ESPORTS_SPORT_SLUG_TEAM_SLUG = '/esports/[sportSlug]/team/[slug]',
    ESPORTS_TOURNAMENT_SLUG = '/esports/tournament/[slug]',
    EUROCUP = '/euro-cup',
    FAVORITE_OUTRIGHTS = '/favorite/outrights',
    FAVORITE_RESULTS = '/favorite/results',
    FAVORITE_UPCOMING = '/favorite/upcoming',
    FREEBETS = '/freebets',
    INPUTS = '/inputs',
    LIVE = '/live',
    MAINTENANCE = '/maintenance',
    N404 = '/404',
    NETWORKPROMOTIONS_SLUG = '/network-promotions/[slug]',
    NEXTTOGO = '/next-to-go',
    OUTRIGHTS = '/outrights',
    PROMOTION_SLUG = '/promotion/[slug]',
    RESULTS = '/results',
    ROOT = '/',
    SENTRY = '/sentry',
    SPORTS = '/sports',
    SPORTSTATISTICS = '/sport-statistics',
    SPORTS_MATCH_SLUG = '/sports/match/[slug]',
    SPORTS_OUTRIGHT_SLUG = '/sports/outright/[slug]',
    SPORTS_RESULT_SLUG = '/sports/result/[slug]',
    SPORTS_SPORT_SLUG_PLAYER_SLUG = '/sports/[sportSlug]/player/[slug]',
    SPORTS_SPORT_SLUG_TEAM_SLUG = '/sports/[sportSlug]/team/[slug]',
    SPORTS_TOURNAMENT_SLUG = '/sports/tournament/[slug]',
    TERMSANDCONDITIONSBONUSES = '/terms-and-conditions-bonuses',
    TOURNAMENTBRACKET = '/tournament-bracket',
    TOURNAMENTBRACKET_SLUG = '/tournament-bracket/[slug]',
    TOURNAMENTS = '/tournaments',
    TOURNAMENTS_SLUG = '/tournaments/[slug]',
    WHEEL = '/wheel',
    _APP = '/_app',
    _DOCUMENT = '/_document',
    _ERROR = '/_error',
}
