import { isEqual } from 'lodash';

import { ID_PATH_DEVIDER, UNIQUE_ID_KEY } from 'app-constants';
import typePolicies from 'services/apollo/betting/typePolicies';
import type {
    AddPrefixOutput,
    IdPathParserType,
    IDs,
    ParseReturnTypes,
    Prefix,
} from './types';

type Result<T extends Array<keyof IDs>> = { [key in T[number]]: string };

const printErrorMessage = (uniqIdKey: string): void =>
    console.error('Error! Incorrect id path provided: ', uniqIdKey);

class IdPathParser implements IdPathParserType {
    uniqueIdKey: string;

    constructor({ uniqueIdKey }: { uniqueIdKey: typeof UNIQUE_ID_KEY }) {
        this.uniqueIdKey = uniqueIdKey;
    }

    parsers: {
        [P in keyof ParseReturnTypes]: {
            split: (path: string) => ParseReturnTypes[P];
            join: (ids: ParseReturnTypes[P]) => string;
        };
    } = {
        // NOTE: The order is important, provide id names starting from the top parent
        BetOdd: makeParser(['betId', 'sportEventId', 'marketId', 'oddId']),
        Odd: makeParser(['sportEventId', 'marketId', 'oddId']),
        Market: makeParser(['sportEventId', 'marketId']),
        SportEventCompetitor: makeParser(['sportEventId', 'competitorId']),
        CompetitorScore: makeParser([
            'sportEventId',
            'competitorId',
            'scoreId',
        ]),
        Stream: makeParser(['sportEventId', 'streamId']),
    };

    split<T extends keyof ParseReturnTypes>(
        typename: T,
        uniqIdKey: string
    ): ParseReturnTypes[T] {
        if (!this.parsers[typename]) {
            console.error('Error! Incorrect typename provided: ', typename);
        }

        return this.parsers[typename].split(uniqIdKey) as ParseReturnTypes[T];
    }

    join<T extends keyof ParseReturnTypes>(
        typename: T,
        ids: ParseReturnTypes[T]
    ): string {
        if (!this.parsers[typename]) {
            console.error('Error! Incorrect typename provided: ', typename);

            return '';
        }

        return this.parsers[typename].join(ids);
    }

    addPrefix(typename: Prefix, path: string): AddPrefixOutput {
        if (isEqual(typePolicies[typename]?.keyFields, [this.uniqueIdKey])) {
            return `${typename}:{"${this.uniqueIdKey}":"${path}"}`;
        }

        return `${typename}:${path}`;
    }
}

function makeParser<IdName extends keyof IDs, Names extends IdName[]>(
    names: Names
): {
    split: (path: string) => Result<Names>;
    join: (ids: Result<Names>) => string;
} {
    return {
        split: (path) => {
            const ids = path.split(ID_PATH_DEVIDER);

            if (ids.length !== names.length) {
                printErrorMessage(path);
            }

            return names.reduce<Result<Names>>((acc, name, index) => {
                acc[name] = ids[index];

                return acc;
            }, {} as Result<Names>);
        },
        join: (ids) => {
            const idsArr = names.map((name) => ids[name]);

            return idsArr.join(ID_PATH_DEVIDER);
        },
    };
}

export default new IdPathParser({ uniqueIdKey: UNIQUE_ID_KEY });
