export enum CustomAppLink {
    APP = '/app',
    NEWS_BONUSES = '/news/bonuses',
    CASINO_ALLGAMES = '/casino/all-games',
    CASINO_LIVE = '/casino/live',
    CASINO_POPULAR = '/casino/popular',
    CASINO_SLOTS = '/casino/slots',
    CASINO_TABLE_GAMES = '/casino/table-games',
    CASINO_INSTA_GAMES = '/casino/insta-games',
    AFFILIATE_PROGRAM = 'https://ggbetaff.com/',
    TEMP_EMPTY_LINK = '/',
    ABOUT_US = '/about-us',
    TERMS_AND_CONDITIONS = '/terms-and-conditions',
    GAMBLING_RULES = '/gambling-rules',
    PUBLIC_OFFER = '/public-offer',
    PRIVACY_POLICY = '/privacy-policy',
    ANTI_MONEY_LAUNDERING = '/anti-money-laundering',
    BETTING_BONUS_CONDITIONS = '/betting-bonus-conditions',
    BETS_RULES = '/bets-rules',
    CANCELLATION_POLICY = '/cancellation-policy',
    INSTA_GAMES_BONUS_TERMS = '/insta-games-bonus-terms',
    RESPONSIBLE_GAMING = '/responsible-gaming',
    CASINO_BONUS_TERMS = '/casino-bonus-terms',
    TOURNAMENT_BRACKET = '/tournament-bracket',
    NEWS = '/news',
    BATTLEGROUNDS = '/battlegrounds',
    CALLOFDUTY = '/call-of-duty',
    COUNTERSTRIKE = '/counter-strike',
    DOTA2 = '/dota2',
    FIFA = '/fifa',
    FORTNITE = '/fortnite',
    HALO = '/halo',
    HEARTHSTONE = '/hearthstone',
    HEROESOFTHESTORM = '/heroes-of-the-storm',
    LEAGUEOFLEGENDS = '/league-of-legends',
    NBA2K18 = '/nba-2k18',
    OVERWATCH = '/overwatch',
    SMITE = '/smite',
    STARCRAFT2 = '/starcraft2',
    VAINGLORY = '/vainglory',
    VALORANT = '/valorant',
    WORLDOFTANKS = '/world-of-tanks',
}
