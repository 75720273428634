import { useCallback, useState } from 'react';

type ClientNode = HTMLElement | null;

export default function useClientNode(): [
    ClientNode,
    (node: ClientNode) => void,
] {
    const [node, setNode] = useState<ClientNode>(null);

    const ref = useCallback((e: ClientNode) => {
        if (e !== null) setNode(e);
    }, []);

    return [node, ref];
}
