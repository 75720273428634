import { DefaultContext, Operation } from '@apollo/client';
import { parse, serialize } from 'cookie';
import { map, omit } from 'lodash';

import { CookiesType } from 'app-constants';

interface AuthTokenFromOperation {
    operation: Operation;
}

export const contextHandler = (prevContext: DefaultContext): DefaultContext => {
    const headers = {
        ...prevContext.headers,
        cookie: removeFromCookiesString(prevContext.headers?.cookie, [
            CookiesType.Paseto,
        ]),
    };

    if ('x-paseto' in headers) {
        delete headers['x-paseto'];
    }

    return {
        ...prevContext,
        token: '',
        headers,
    };
};

export const removeAuthTokenFromOperation = ({
    operation,
}: AuthTokenFromOperation): void => {
    operation.setContext(contextHandler);
};

const removeFromCookiesString = (
    cookie: string | undefined,
    cookiesKey: CookiesType[]
): string => {
    if (!cookie) {
        return '';
    }

    const omitCookie = omit(parse(cookie), cookiesKey);

    return map(omitCookie, (value, key) => serialize(key, value)).join(';');
};
