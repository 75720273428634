import { useCallback, useEffect, useMemo, useState } from 'react';
import { deleteCookie, getCookie, hasCookie } from 'cookies-next';

import { CookiesType, ModalsHash } from 'app-constants';
import useModal from 'components/modals/useModal';
import { useGetProfile } from 'gql/cms/queries/getProfile.cms';
import { usePagePropsContext } from 'layouts/Root/PagePropsContext';
import { IAuthorization } from './context';
import useExpiredToken from './useExpiredToken';

interface Output {
    authenticatedMemo: IAuthorization;
}

const useAuthorizationManager = (): Output => {
    const { openModal } = useModal();
    const { isAuthorized } = usePagePropsContext();
    const [authenticated, setAuthenticated] = useState(() => {
        return isAuthorized || hasCookie(CookiesType.Paseto);
    });

    const { refetch } = useGetProfile(() => ({
        onCompleted: (profileResponse) => {
            const shouldFinishRegistration = profileResponse.user
                ? !profileResponse.user.roles.find(
                      (role) => role === 'ROLE_PLAYER'
                  )
                : false;

            if (!shouldFinishRegistration) {
                if (hasCookie(CookiesType.RequisiteApple)) {
                    deleteCookie(CookiesType.RequisiteApple);
                }

                return;
            }

            openModal({ hash: ModalsHash.RegisterFinish });
        },
    }));

    useEffect(() => {
        const pasetoToken = getCookie(CookiesType.Paseto);

        if (!pasetoToken) return;

        setAuthenticated(true);
    }, [setAuthenticated]);

    useExpiredToken({ setAuthenticated });

    const handleRefetch = useCallback(() => {
        refetch();
    }, [refetch]);

    const authenticatedMemo = useMemo(
        () => ({
            authenticated,
            setAuthenticated,
            handleRefetch,
        }),
        [authenticated, setAuthenticated, handleRefetch]
    );

    useEffect(() => {
        refetch();
    }, [authenticated, refetch]);

    return {
        authenticatedMemo,
    };
};

export default useAuthorizationManager;
