import {
    GetSeoRulesRedirectData,
    RedirectStatusCode,
    SeoRulesWithLocale,
    SeoRulesWithoutLocale,
} from './types';

export const isSupportedStatusCode = (
    statusCode: number
): statusCode is RedirectStatusCode => {
    const supportedStatusCodes: RedirectStatusCode[] = [
        301, 302, 303, 307, 308,
    ];

    return supportedStatusCodes.includes(statusCode as RedirectStatusCode);
};

export const getSeoRulesRedirectData = ({
    seoRulesByPageWithoutLocale,
    seoRulesByPage,
}: {
    seoRulesByPageWithoutLocale: SeoRulesWithoutLocale;
    seoRulesByPage: SeoRulesWithLocale;
}): GetSeoRulesRedirectData => {
    const redirectWithoutLocale = seoRulesByPageWithoutLocale?.redirect;

    const redirectWithLocale = seoRulesByPage?.redirect;

    const isBothRedirectsExisted =
        !!redirectWithoutLocale && !!redirectWithLocale;

    const isWithLocaleRedirectOnly =
        !!redirectWithLocale && !redirectWithoutLocale;

    if (isBothRedirectsExisted || isWithLocaleRedirectOnly) {
        return redirectWithLocale;
    }

    return redirectWithoutLocale;
};
