import { map } from 'lodash';

import {
    favoriteMarketsIdVar,
    updateClientDeeplinkMatchData,
    updateClientGamblerSettings,
    updateClientOddAcceptStrategy,
} from 'components/betting/GamblerSettings/gamblerState';
import { GetGamblerSettings } from 'types/gql.bet';
import { GamblerSetting } from './constants';
import { AllSettings, FavoriteMarkets } from './types';

const getFavoriteMarketsFromGamblerSettings = (
    favoriteMarketsValueObject: Record<string, number[]>
): FavoriteMarkets[] => {
    return Object.keys(favoriteMarketsValueObject).map((key) => {
        return {
            sport: key,
            marketIds: favoriteMarketsValueObject[key],
        };
    });
};

export const getParsedSettings = (
    settings: NonNullable<GetGamblerSettings['gambler']>['settings']
): Partial<AllSettings> => {
    if (!settings) return {};

    return settings.reduce<Partial<AllSettings>>((acc, setting) => {
        let parsedValue;

        try {
            parsedValue = JSON.parse(setting.value);
        } catch {
            parsedValue = setting.value;
        }

        if (setting.name === GamblerSetting.FAVORITE_MARKETS_ID) {
            acc[setting.name] =
                getFavoriteMarketsFromGamblerSettings(parsedValue);
        } else {
            acc[setting.name as keyof AllSettings] = parsedValue;
        }

        return acc;
    }, {});
};

export const convertFavoriteMarketsToGamblerSettings = (
    favoriteMarketsId: FavoriteMarkets[]
): string =>
    JSON.stringify(
        favoriteMarketsId.reduce<Record<string, number[]>>(
            (acc, favoriteMarket) => {
                acc[favoriteMarket.sport] = favoriteMarket.marketIds;

                return acc;
            },
            {}
        )
    );

export const prepareSettingsToChange = (
    settings: Partial<AllSettings>
): NonNullable<NonNullable<GetGamblerSettings['gambler']>['settings']> => {
    return map(settings, (value, name) => {
        if (name === GamblerSetting.FAVORITE_MARKETS_ID) {
            return {
                name,
                value: convertFavoriteMarketsToGamblerSettings(
                    value as FavoriteMarkets[]
                ),
            };
        }

        if (name === GamblerSetting.DEEPLINK_MATCH_DATA) {
            return {
                name,
                value: JSON.stringify(value),
            };
        }

        return {
            name,
            value: String(value),
        };
    });
};

export const updateAllClientSettings = (allSettings: AllSettings): void => {
    const {
        favoriteMarketsId,
        deeplinkMatchData,
        oddAcceptStrategy,
        ...clientGamblerSettings
    } = allSettings;

    favoriteMarketsIdVar(favoriteMarketsId);
    updateClientDeeplinkMatchData(deeplinkMatchData);
    updateClientGamblerSettings(clientGamblerSettings);
    updateClientOddAcceptStrategy(oddAcceptStrategy);
};
