import { FC, memo } from 'react';
import cn from 'classnames';
import { useTranslations } from 'next-intl';

import Button from 'components/Button';
import PackIcon, { IconsPack, SvgIconSize } from 'components/PackIcon';
import withClientMessages from 'hocs/withClientMessages';
import { Action, Category } from 'services/GoogleAnalytics';
import useAppBanner from './useAppBanner';

const AppBanner: FC = () => {
    const t = useTranslations('BannerApp');
    const { hideBanner, isShowAppBanner, widget, linkToApp } = useAppBanner();

    return (
        <div
            className={cn(
                'relative z-1 box-content overflow-hidden bg-surface-dark transition-all duration-500',
                isShowAppBanner ? 'max-h-app-banner-offset' : 'max-h-0'
            )}
        >
            <div
                className="mx-auto mb-2 mt-1 flex w-90 flex-nowrap items-center rounded-[8px] border-default border-surface-light bg-surface-middle py-2 pl-2 pr-4"
                data-test="app-banner"
            >
                <div className="mr-2" onClick={hideBanner}>
                    <PackIcon
                        id="close"
                        pack={IconsPack.ActionIcons}
                        className="fill-grey-700"
                    />
                </div>

                <div className="mr-2">
                    <PackIcon
                        id="app-logo"
                        pack={IconsPack.ColoredIcons}
                        size={SvgIconSize.WH_10}
                    />
                </div>

                <div
                    className="mr-2 line-clamp-3 max-h-[44px] text-primary-white typo-sm-1"
                    /* eslint-disable-next-line react/no-danger */
                    dangerouslySetInnerHTML={{ __html: widget }}
                />

                <Button
                    size={Button.Size.Small}
                    width={Button.Width.Fit}
                    color={Button.Color.Orange}
                    className="ml-auto px-4 uppercase"
                    href={linkToApp}
                    dataTest="app-banner__download-btn"
                    analyticsAttrs={{
                        'data-category': Category.Applend,
                        'data-action': Action.DownloadFromBanner,
                    }}
                >
                    {t(`gincms.banner_app.download`)}
                </Button>
            </div>
        </div>
    );
};

export default memo(withClientMessages(AppBanner, ['BannerApp']));
