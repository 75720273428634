import { FC, useEffect, useState } from 'react';
import format from 'date-fns/format';

import ClientRender from 'components/ClientRender';
import PackIcon, { IconsPack } from 'components/PackIcon';
import { getTimeZone } from 'utils/dates/getTimeZone';

const Time: FC = () => {
    const [time, setTime] = useState<string | null>(null);

    useEffect(() => {
        function updateTime() {
            const now = new Date();
            const formattedTime = format(now, 'HH:mm:ss');

            setTime(formattedTime);
        }

        updateTime();

        const interval = setInterval(updateTime, 1000);

        return () => clearInterval(interval);
    }, []);

    return (
        <>
            <div className="flex items-center justify-center gap-1">
                <PackIcon
                    id="timer"
                    pack={IconsPack.ActionIcons}
                    className="max-h-4 max-w-[16px] fill-primary-white"
                />
                <ClientRender>{getTimeZone()}</ClientRender>
            </div>

            <time>{time}</time>
        </>
    );
};

export default Time;
