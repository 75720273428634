import { FC } from 'react';
import type { ImageLoader, ImageProps } from 'next/image';
import NextImage from 'next/image';

const loader: ImageLoader = ({ src, width, quality = 75 }) =>
    `${src}?w=${width}&q=${quality}`;

const Image: FC<ImageProps> = (props) => {
    return <NextImage {...props} loader={loader} />;
};

export default Image;
