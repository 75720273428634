import { useContext, useEffect, useState } from 'react';

import AnalyticAttrsCtx from './AnalyticAttrsCtx';
import type { AnalyticAttrs } from './types';
import { getIsTurnOnDebugAnalytic } from './utils';

export function useAnalyticAttrs(): Partial<AnalyticAttrs>;
export function useAnalyticAttrs<T extends Partial<AnalyticAttrs>>(obj: {
    defaultAttrs: T;
}): Partial<AnalyticAttrs> & T;
export function useAnalyticAttrs<P extends Partial<AnalyticAttrs>>(obj: {
    priorityAttrs: P;
}): Partial<AnalyticAttrs> & P;
export function useAnalyticAttrs<
    T extends Partial<AnalyticAttrs>,
    P extends Partial<AnalyticAttrs>,
>(obj: { defaultAttrs: T; priorityAttrs: P }): Partial<AnalyticAttrs> & T & P;
export function useAnalyticAttrs<
    T extends Partial<AnalyticAttrs>,
    P extends Partial<AnalyticAttrs>,
>(obj?: {
    defaultAttrs?: T;
    priorityAttrs?: P;
}):
    | Partial<AnalyticAttrs>
    | (Partial<AnalyticAttrs> & T)
    | (Partial<AnalyticAttrs> & P)
    | (Partial<AnalyticAttrs> & T & P) {
    const analytic = useContext(AnalyticAttrsCtx);

    if (!obj) return analytic;

    const { defaultAttrs, priorityAttrs } = obj;

    return { ...defaultAttrs, ...analytic, ...priorityAttrs };
}

export const useDebugAnalytic = (): boolean => {
    const [isEnable, setIsEnable] = useState<boolean>(false);

    useEffect(() => {
        setIsEnable(getIsTurnOnDebugAnalytic());
    }, []);

    return isEnable;
};

export const useToggleDebugAnalytic = (): void => {
    const isEnable = useDebugAnalytic();

    useEffect(() => {
        if (!isEnable) return;

        document.body.classList.add('analytic-debug');

        return () => {
            document.body.classList.remove('analytic-debug');
        };
    }, [isEnable]);
};
