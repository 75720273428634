export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
    [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
};

export type AmericanFootballOverview = {
    __typename?: 'AmericanFootballOverview';
    attackSide: TeamHomeAway;
    currentPeriod: Scalars['Int'];
    periods?: Maybe<Array<AmericanFootballOverviewPeriod>>;
    teams: AmericanFootballOverviewTeams;
    timer: Timer;
};

export type AmericanFootballOverviewPeriod = {
    __typename?: 'AmericanFootballOverviewPeriod';
    number: Scalars['Int'];
    totalPointsAway: Scalars['Int'];
    totalPointsHome: Scalars['Int'];
};

export type AmericanFootballOverviewTeam = {
    __typename?: 'AmericanFootballOverviewTeam';
    score: Scalars['Int'];
};

export type AmericanFootballOverviewTeams = {
    __typename?: 'AmericanFootballOverviewTeams';
    away: AmericanFootballOverviewTeam;
    home: AmericanFootballOverviewTeam;
};

export type BadmintonOverview = {
    __typename?: 'BadmintonOverview';
    currentServer: TeamHomeAway;
    currentSet: Scalars['Int'];
    sets?: Maybe<Array<BadmintonOverviewSet>>;
    teams: BadmintonOverviewTeams;
};

export type BadmintonOverviewSet = {
    __typename?: 'BadmintonOverviewSet';
    number: Scalars['Int'];
    pointsAway: Scalars['Int'];
    pointsHome: Scalars['Int'];
    winner: TeamHomeAway;
};

export type BadmintonOverviewTeam = {
    __typename?: 'BadmintonOverviewTeam';
    score: Scalars['Int'];
};

export type BadmintonOverviewTeams = {
    __typename?: 'BadmintonOverviewTeams';
    away: BadmintonOverviewTeam;
    home: BadmintonOverviewTeam;
};

export type Banner = {
    type: BannerType;
};

export type BannerBettingLinkedSport = Banner & {
    __typename?: 'BannerBettingLinkedSport';
    linkedSport: Sport;
    sport: Sport;
    type: BannerType;
};

export type BannerBettingLiveOfTheDay = Banner & {
    __typename?: 'BannerBettingLiveOfTheDay';
    type: BannerType;
};

export type BannerBettingMatchOfTheDay = Banner & {
    __typename?: 'BannerBettingMatchOfTheDay';
    type: BannerType;
};

export type BannerBettingSportEvent = Banner & {
    __typename?: 'BannerBettingSportEvent';
    group?: Maybe<BannerGroup>;
    id: Scalars['String'];
    marketTypeIds?: Maybe<Array<Scalars['Int']>>;
    sportevent: SportEvent;
    theme: Scalars['String'];
    type: BannerType;
};

export type BannerBettingSportEventGeo = Banner & {
    __typename?: 'BannerBettingSportEventGeo';
    group?: Maybe<BannerGroup>;
    id: Scalars['String'];
    marketTypeIds?: Maybe<Array<Scalars['Int']>>;
    sportevent: SportEvent;
    theme: Scalars['String'];
    type: BannerType;
};

export type BannerBettingTournament = Banner & {
    __typename?: 'BannerBettingTournament';
    id: Scalars['String'];
    tournament: Tournament;
    type: BannerType;
};

export type BannerBettingTournamentGeo = Banner & {
    __typename?: 'BannerBettingTournamentGeo';
    id: Scalars['String'];
    tournament: Tournament;
    type: BannerType;
};

export type BannerCms = Banner & {
    __typename?: 'BannerCMS';
    id: Scalars['String'];
    type: BannerType;
};

export type BannerGroup = {
    __typename?: 'BannerGroup';
    groupBy: Scalars['String'];
    priority: Scalars['Int'];
};

export enum BannerType {
    BettingLinkedSport = 'betting_linked_sport',
    BettingLiveOfTheDay = 'betting_live_of_the_day',
    BettingMatchOfTheDay = 'betting_match_of_the_day',
    BettingSportEvent = 'betting_sport_event',
    BettingSportEventGeo = 'betting_sport_event_geo',
    BettingTournament = 'betting_tournament',
    BettingTournamentGeo = 'betting_tournament_geo',
    CmsBanner = 'cms_banner',
}

export type BannerZone = {
    __typename?: 'BannerZone';
    attributes: BannerZoneAttributes;
    id: Scalars['String'];
    settings?: Maybe<Array<Pair>>;
};

export type BannerZoneAttributeFilters = {
    indices?: InputMaybe<Array<Scalars['Int']>>;
    pageLocations?: InputMaybe<Array<Scalars['String']>>;
    pageTypes?: InputMaybe<Array<Scalars['String']>>;
    platforms?: InputMaybe<Array<Scalars['String']>>;
};

export type BannerZoneAttributes = {
    __typename?: 'BannerZoneAttributes';
    index: Scalars['Int'];
    pageLocation: Scalars['String'];
    pageType: Scalars['String'];
    platform: Scalars['String'];
};

export type BannerZoneAttributesInput = {
    index: Scalars['Int'];
    pageLocation: Scalars['String'];
    pageType: Scalars['String'];
    platform: Scalars['String'];
};

export type BaseballBase = {
    __typename?: 'BaseballBase';
    busy: Scalars['Boolean'];
    number: Scalars['Int'];
};

export enum BaseballGameFormat {
    NineInning_1Extra = 'NINE_INNING_1_EXTRA',
    NineInning_3Extra = 'NINE_INNING_3_EXTRA',
    NineInningOnly = 'NINE_INNING_ONLY',
    NineInningUnlimitedExtra = 'NINE_INNING_UNLIMITED_EXTRA',
    Unknown = 'UNKNOWN',
}

export type BaseballInning = {
    __typename?: 'BaseballInning';
    balls: Scalars['Int'];
    bases?: Maybe<Array<BaseballBase>>;
    halfNumber: Scalars['Int'];
    homeRuns: Scalars['Int'];
    number: Scalars['Int'];
    outs: Scalars['Int'];
    points: Scalars['Int'];
    strikes: Scalars['Int'];
    team: TeamHomeAway;
    type: BaseballInningType;
};

export enum BaseballInningType {
    Extra = 'EXTRA',
    Regular = 'REGULAR',
}

export type BaseballOverview = {
    __typename?: 'BaseballOverview';
    awayPoints: Scalars['Int'];
    currentHalf: Scalars['Int'];
    currentInning: Scalars['Int'];
    gameFormat: BaseballGameFormat;
    homePoints: Scalars['Int'];
    innings?: Maybe<Array<BaseballInning>>;
};

/** deprecated - use OddExistenceRestriction */
export type BaselineSelectionExistenceRestriction = {
    __typename?: 'BaselineSelectionExistenceRestriction';
    marketId: Scalars['String'];
    oddId: Scalars['String'];
    sportEventId: Scalars['String'];
    type: RestrictionType;
};

export type BasketballOverview = {
    __typename?: 'BasketballOverview';
    currentQuarter: Scalars['Int'];
    pause: Scalars['Boolean'];
    quarterFormat: Scalars['Int'];
    quarters?: Maybe<Array<BasketballOverviewQuarter>>;
    teams: BasketballOverviewTeams;
    timer: Timer;
};

export type BasketballOverviewQuarter = {
    __typename?: 'BasketballOverviewQuarter';
    away: BasketballQuarterTeam;
    home: BasketballQuarterTeam;
    number: Scalars['Int'];
};

export type BasketballOverviewTeam = {
    __typename?: 'BasketballOverviewTeam';
    fouls: Scalars['Int'];
    score: Scalars['Int'];
};

export type BasketballOverviewTeams = {
    __typename?: 'BasketballOverviewTeams';
    away: BasketballOverviewTeam;
    home: BasketballOverviewTeam;
};

export type BasketballQuarterTeam = {
    __typename?: 'BasketballQuarterTeam';
    score: Scalars['Int'];
};

export type BattlegroundsGame = {
    __typename?: 'BattlegroundsGame';
    kills: Scalars['Int'];
    number: Scalars['Int'];
    players: Scalars['Int'];
    top: Scalars['Int'];
};

export type BattlegroundsMultiCompetitiveCompetitor = {
    __typename?: 'BattlegroundsMultiCompetitiveCompetitor';
    id: Scalars['String'];
    statistics: BattlegroundsMultiCompetitiveStatistics;
    status: Status;
    team: Team;
};

export type BattlegroundsMultiCompetitiveCompetitorTeamArgs = {
    locale?: InputMaybe<Scalars['String']>;
};

export type BattlegroundsMultiCompetitiveOverview = {
    __typename?: 'BattlegroundsMultiCompetitiveOverview';
    currentMap: Scalars['Int'];
    maps: Array<BattlegroundsMultiCompetitiveOverviewMap>;
};

export type BattlegroundsMultiCompetitiveOverviewMap = {
    __typename?: 'BattlegroundsMultiCompetitiveOverviewMap';
    competitors: Array<BattlegroundsMultiCompetitiveCompetitor>;
    number: Scalars['Int'];
};

export type BattlegroundsMultiCompetitiveStatistics = {
    __typename?: 'BattlegroundsMultiCompetitiveStatistics';
    active: Scalars['Int'];
    kills: Scalars['Int'];
    total: Scalars['Int'];
};

export type BattlegroundsOverview = {
    __typename?: 'BattlegroundsOverview';
    currentGame: Scalars['Int'];
    games?: Maybe<Array<BattlegroundsGame>>;
};

export type BeachVolleyballOverview = {
    __typename?: 'BeachVolleyballOverview';
    currentSet: Scalars['Int'];
    server: TeamHomeAway;
    sets?: Maybe<Array<BeachVolleyballOverviewSet>>;
    teams: BeachVolleyballOverviewTeams;
};

export type BeachVolleyballOverviewSet = {
    __typename?: 'BeachVolleyballOverviewSet';
    number: Scalars['Int'];
    totalPointsAway: Scalars['Int'];
    totalPointsHome: Scalars['Int'];
    type: BeachVolleyballSetType;
    winner: TeamHomeAway;
};

export type BeachVolleyballOverviewTeam = {
    __typename?: 'BeachVolleyballOverviewTeam';
    score: Scalars['Int'];
};

export type BeachVolleyballOverviewTeams = {
    __typename?: 'BeachVolleyballOverviewTeams';
    away: BeachVolleyballOverviewTeam;
    home: BeachVolleyballOverviewTeam;
};

export enum BeachVolleyballSetType {
    Golden = 'GOLDEN',
    Regular = 'REGULAR',
}

export type Bet = {
    __typename?: 'Bet';
    betBuilderOdds: Array<BetBuilderBetOdd>;
    /** if the bet has been cashed out, you can find more information about the cash out orders here */
    cashOutOrders: Array<CashOutOrder>;
    /** date and time in ISO 8601 (RFC 3339) format */
    createdAt: Scalars['String'];
    /** ISO 4217 (alfa-3) */
    currencyCode: Scalars['String'];
    /** if the bet has been declined, you can find more information about the reasons here */
    declineContext: Array<BetDeclineContextItem>;
    declineReason: Scalars['String'];
    freebetId: Scalars['String'];
    id: Scalars['String'];
    /** if the bet is insured, you can find more information about the insurance here */
    insurance?: Maybe<BetInsurance>;
    odds: Array<BetOdd>;
    playerId: Scalars['String'];
    potentialWin: Scalars['String'];
    /**
     * the amount for which the refundSum is calculated
     * for example, if the bet is cashed out in full, refundBase will be 0$
     */
    refundBase: Scalars['String'];
    refundBaseInUsd: Scalars['String'];
    /** the amount of winnings credited to the player in case of winning the bet */
    refundSum: Scalars['String'];
    refundSumInUsd: Scalars['String'];
    /** bet amount */
    stake: Scalars['String'];
    stakeInUsd: Scalars['String'];
    status: BetStatus;
    /**
     * every element in the array defines odds grouping.
     * For singles it`s always [1], for express - [{odds_count}], for system - the player defines the size.
     */
    systemSizes: Array<Scalars['Int']>;
    totalOddsValue: Scalars['String'];
    type: BetType;
    /** date and time in ISO 8601 (RFC 3339) format */
    updatedAt: Scalars['String'];
};

export type BetBuilderBetOdd = {
    __typename?: 'BetBuilderBetOdd';
    matchId: Scalars['String'];
    odds: Array<SimpleBetOdd>;
    ratio: Scalars['String'];
};

export type BetBuilderMarket = {
    __typename?: 'BetBuilderMarket';
    id: Scalars['String'];
    odds: Array<BetBuilderOdd>;
    status: MarketStatus;
};

export type BetBuilderOdd = {
    __typename?: 'BetBuilderOdd';
    id: Scalars['String'];
    isActive: Scalars['Boolean'];
    status: OddStatus;
    value: Scalars['String'];
};

export type BetBuilderSelection = {
    matchId: Scalars['String'];
    selections: Array<Selection>;
};

export type BetBuilderTab = {
    __typename?: 'BetBuilderTab';
    markets: Array<BetBuilderMarket>;
    /** can be empty */
    restrictions: Array<Restriction>;
};

export type BetBuilderTabConfig = {
    __typename?: 'BetBuilderTabConfig';
    enabled: Scalars['Boolean'];
};

/** Number of combinations in a bet of the system type (values `max`, `min`) */
export type BetCombinationsCountBetDetailsCondition = BetDetailCondition & {
    __typename?: 'BetCombinationsCountBetDetailsCondition';
    max?: Maybe<Scalars['Int']>;
    min?: Maybe<Scalars['Int']>;
    shouldMatchAllOdds: Scalars['Boolean'];
    type: BetDetailsConditionType;
};

/** IDs of competitors in sport events */
export type BetCompetitorBetDetailsCondition = BetDetailCondition & {
    __typename?: 'BetCompetitorBetDetailsCondition';
    competitors: Array<SportEventCompetitor>;
    shouldMatchAllOdds: Scalars['Boolean'];
    type: BetDetailsConditionType;
};

/** deprecated, use `BetCompetitorBetDetailsCondition` instead */
export type BetCompetitorCondition = BetCondition & {
    __typename?: 'BetCompetitorCondition';
    competitors: Array<SportEventCompetitor>;
    type: BetConditionType;
};

export type BetCondition = {
    type: BetConditionType;
};

/** Set of bet details conditions by bet types */
export type BetConditionDetails = {
    __typename?: 'BetConditionDetails';
    conditions: Array<BetDetailCondition>;
    type: BetConditionDetailsType;
};

/** Bet types for bet details conditions */
export enum BetConditionDetailsType {
    Express = 'EXPRESS',
    Single = 'SINGLE',
    System = 'SYSTEM',
}

/** Types of bet conditions available for filtering insurance / free bets */
export enum BetConditionType {
    BetDetails = 'bet_details',
    /** @deprecated Use `bet_details` instead */
    BetType = 'bet_type',
    /** @deprecated Use `bet_details` instead */
    Competitors = 'competitors',
    /** @deprecated Use outdated */
    Country = 'country',
    /** @deprecated Use `bet_details` instead */
    EventMatchIds = 'event_match_ids',
    /** @deprecated Use `bet_details` instead */
    KindOfSportId = 'kind_of_sport_id',
    /** @deprecated Use `bet_details` instead */
    MarketTypes = 'market_types',
    /** @deprecated Use `bet_details` instead */
    OddRatio = 'odd_ratio',
    /** @deprecated Use `bet_details` instead */
    SportKindIds = 'sport_kind_ids',
    /** @deprecated Use `bet_details` instead */
    Tournaments = 'tournaments',
}

/** deprecated */
export type BetCountryCondition = BetCondition & {
    __typename?: 'BetCountryCondition';
    country: Scalars['String'];
    type: BetConditionType;
};

export type BetDeclineContextItem = {
    type: Scalars['String'];
};

export type BetDeclineContextRestrictions = BetDeclineContextItem & {
    __typename?: 'BetDeclineContextRestrictions';
    restrictions: Array<Restriction>;
    type: Scalars['String'];
};

/**
 * Indicates the processing time of the bet"
 * deprecated - will never be present in the bet
 */
export type BetDelayRestriction = {
    __typename?: 'BetDelayRestriction';
    delay: Scalars['Float'];
    sportEventId: Scalars['String'];
    type: RestrictionType;
};

/** BetDetails condition interface */
export type BetDetailCondition = {
    type: BetDetailsConditionType;
};

/** Bet conditions by bet properties, for example odd count */
export type BetDetailsConditionAggregate = BetCondition & {
    __typename?: 'BetDetailsConditionAggregate';
    betDetails: Array<BetConditionDetails>;
    type: BetConditionType;
};

/** Types of conditions of bet details */
export enum BetDetailsConditionType {
    CombinationsCount = 'COMBINATIONS_COUNT',
    Competitor = 'COMPETITOR',
    MarketType = 'MARKET_TYPE',
    OddsCoefficient = 'ODDS_COEFFICIENT',
    OddsCount = 'ODDS_COUNT',
    Sport = 'SPORT',
    SportEvent = 'SPORT_EVENT',
    StakeAmountRange = 'STAKE_AMOUNT_RANGE',
    SystemSize = 'SYSTEM_SIZE',
    TotalOddsCoefficient = 'TOTAL_ODDS_COEFFICIENT',
    Tournament = 'TOURNAMENT',
}

export type BetInsurance = {
    __typename?: 'BetInsurance';
    id: Scalars['String'];
    refundAmount: Scalars['String'];
    status: BetInsuranceStatus;
};

export enum BetInsuranceStatus {
    Accepted = 'ACCEPTED',
    Declined = 'DECLINED',
    Pending = 'PENDING',
}

/** Occurs when the interval between bets has exceeded the limit */
export type BetIntervalRestriction = {
    __typename?: 'BetIntervalRestriction';
    sportEventId: Scalars['String'];
    timeToWait: Scalars['Float'];
    type: RestrictionType;
};

export type BetList = {
    __typename?: 'BetList';
    bets?: Maybe<Array<Bet>>;
    count: Scalars['Int'];
};

/** IDs of market types */
export type BetMarketTypeBetDetailsCondition = BetDetailCondition & {
    __typename?: 'BetMarketTypeBetDetailsCondition';
    marketTypes: Array<Scalars['String']>;
    shouldMatchAllOdds: Scalars['Boolean'];
    type: BetDetailsConditionType;
};

/** deprecated, use `BetMarketTypeBetDetailsCondition` instead */
export type BetMarketTypeCondition = BetCondition & {
    __typename?: 'BetMarketTypeCondition';
    marketTypes: Array<Scalars['String']>;
    type: BetConditionType;
};

export type BetOdd = {
    __typename?: 'BetOdd';
    market: Market;
    /** @deprecated Use id from market entity */
    marketId: Scalars['String'];
    /** @deprecated Use id from sportEvent entity */
    matchId: Scalars['String'];
    odd: Odd;
    /** @deprecated Use id from odd entity */
    oddId: Scalars['String'];
    ratio: Scalars['Float'];
    sportEvent: SportEvent;
    sportEventState: SportEventState;
    updatedAt: Scalars['String'];
};

/** deprecated, use `BetConditionDetails` instead */
export type BetOddValueCondition = BetCondition & {
    __typename?: 'BetOddValueCondition';
    max: Scalars['String'];
    min: Scalars['String'];
    type: BetConditionType;
};

/** Range of the odd coefficient on the outcome of the event (values `max`, `min`) */
export type BetOddsCoefficientBetDetailsCondition = BetDetailCondition & {
    __typename?: 'BetOddsCoefficientBetDetailsCondition';
    max?: Maybe<Scalars['String']>;
    min?: Maybe<Scalars['String']>;
    shouldMatchAllOdds: Scalars['Boolean'];
    type: BetDetailsConditionType;
};

/** Range of odds count in a bet (values `max`, `min`) */
export type BetOddsCountBetDetailsCondition = BetDetailCondition & {
    __typename?: 'BetOddsCountBetDetailsCondition';
    max?: Maybe<Scalars['Int']>;
    min?: Maybe<Scalars['Int']>;
    type: BetDetailsConditionType;
};

/** Appears when requesting a cash out on a bet for which it is forbidden by the provider */
export type BetProviderRestriction = {
    __typename?: 'BetProviderRestriction';
    betProvider: Scalars['String'];
    type: RestrictionType;
};

/** Occurs if selections in the bet and in the cash out do not match */
export type BetSelectionExistenceRestriction = {
    __typename?: 'BetSelectionExistenceRestriction';
    marketId: Scalars['String'];
    oddId: Scalars['String'];
    sportEventId: Scalars['String'];
    type: RestrictionType;
};

export enum BetSettlementStatus {
    Accepted = 'ACCEPTED',
    Declined = 'DECLINED',
    Lost = 'LOST',
    Processing = 'PROCESSING',
    Redeemed = 'REDEEMED',
    Refunded = 'REFUNDED',
    Won = 'WON',
}

export type BetSort = {
    direction: SortDirection;
    field: BetSortFields;
};

export enum BetSortFields {
    CreatedAt = 'CREATED_AT',
    StatusUpdatedAt = 'STATUS_UPDATED_AT',
}

/** IDs of sport kinds */
export type BetSportBetDetailsCondition = BetDetailCondition & {
    __typename?: 'BetSportBetDetailsCondition';
    shouldMatchAllOdds: Scalars['Boolean'];
    sports: Array<Sport>;
    type: BetDetailsConditionType;
};

/** deprecated, use `BetSportBetDetailsCondition` instead */
export type BetSportCondition = BetCondition & {
    __typename?: 'BetSportCondition';
    /** @deprecated Use `sports` instead */
    sportIds: Array<Scalars['String']>;
    sports: Array<Sport>;
    type: BetConditionType;
};

/** IDs of sport events */
export type BetSportEventBetDetailsCondition = BetDetailCondition & {
    __typename?: 'BetSportEventBetDetailsCondition';
    shouldMatchAllOdds: Scalars['Boolean'];
    sportEvents: Array<SportEvent>;
    type: BetDetailsConditionType;
};

/** deprecated, use `BetSportEventBetDetailsCondition` instead */
export type BetSportEventCondition = BetCondition & {
    __typename?: 'BetSportEventCondition';
    /** @deprecated Use sportEvents instead */
    sportEventIds: Array<Scalars['String']>;
    sportEvents: Array<SportEvent>;
    type: BetConditionType;
};

/** Range of the bet amount (values `max`, `min`) */
export type BetStakeAmountRangeBetDetailsCondition = BetDetailCondition & {
    __typename?: 'BetStakeAmountRangeBetDetailsCondition';
    max?: Maybe<Scalars['String']>;
    min?: Maybe<Scalars['String']>;
    type: BetDetailsConditionType;
};

export enum BetStatus {
    /** the bet is successful and waiting for settlement */
    Accepted = 'ACCEPTED',
    ArbitrageAccepted = 'ARBITRAGE_ACCEPTED',
    ArbitrageDeclined = 'ARBITRAGE_DECLINED',
    Created = 'CREATED',
    /** the bet is rejected */
    Declined = 'DECLINED',
    ForcedDeclined = 'FORCED_DECLINED',
    Init = 'INIT',
    Placed = 'PLACED',
    PlaceError = 'PLACE_ERROR',
    RolledBack = 'ROLLED_BACK',
    /** final status, if the bet has won, the winnings will be paid out */
    Settled = 'SETTLED',
    /** cancellation of settlement and waiting for resettlement */
    Unsettled = 'UNSETTLED',
}

/**
 * Occurs if a player attempts to make a cash out on a bet that is in one of the forbidden statuses.
 * Cash outs are allowed for bets in **ACCEPTED** and **UNSETTLED** bets statuses.
 */
export type BetStatusRestriction = {
    __typename?: 'BetStatusRestriction';
    betStatus: BetStatus;
    type: RestrictionType;
};

/** Number of elements in a bet of the system type (values `max`, `min`) */
export type BetSystemSizeBetDetailsCondition = BetDetailCondition & {
    __typename?: 'BetSystemSizeBetDetailsCondition';
    max?: Maybe<Scalars['Int']>;
    min?: Maybe<Scalars['Int']>;
    type: BetDetailsConditionType;
};

export type BetTip = {
    __typename?: 'BetTip';
    id: Scalars['String'];
    locale: Scalars['String'];
    market: Market;
    marketId: Scalars['String'];
    name: Scalars['String'];
    oddId: Scalars['String'];
    priority: Scalars['Int'];
    sportEventID: Scalars['String'];
    text: Scalars['String'];
};

export type BetTips = {
    __typename?: 'BetTips';
    sportEvent: SportEvent;
    tips: Array<BetTip>;
};

/** Range of the total odds coefficient of a bet (values `max`, `min`) */
export type BetTotalOddsCoefficientBetDetailsCondition = BetDetailCondition & {
    __typename?: 'BetTotalOddsCoefficientBetDetailsCondition';
    max?: Maybe<Scalars['String']>;
    min?: Maybe<Scalars['String']>;
    type: BetDetailsConditionType;
};

/** Tournament IDs */
export type BetTournamentBetDetailsCondition = BetDetailCondition & {
    __typename?: 'BetTournamentBetDetailsCondition';
    shouldMatchAllOdds: Scalars['Boolean'];
    tournaments: Array<Tournament>;
    type: BetDetailsConditionType;
};

/** deprecated, use `BetTournamentBetDetailsCondition` instead */
export type BetTournamentCondition = BetCondition & {
    __typename?: 'BetTournamentCondition';
    /** @deprecated Use `tournaments` instead */
    tournamentIds: Array<Scalars['String']>;
    tournaments: Array<Tournament>;
    type: BetConditionType;
};

/** The express bet was placed on the outcome of the same team in related tournaments */
export type BetTournamentDuplicateCompetitorIDsRestriction = {
    __typename?: 'BetTournamentDuplicateCompetitorIDsRestriction';
    type: RestrictionType;
};

/** Occurs when there are problems with the data in a bet or sport event */
export type BetTournamentIncompatibleMarketIDsRestriction = {
    __typename?: 'BetTournamentIncompatibleMarketIDsRestriction';
    type: RestrictionType;
};

export enum BetType {
    Express = 'EXPRESS',
    Single = 'SINGLE',
    System = 'SYSTEM',
}

/** deprecated, use `BetConditionDetailsType` instead */
export type BetTypeCondition = BetCondition & {
    __typename?: 'BetTypeCondition';
    betType: BetType;
    type: BetConditionType;
};

/** This type of bet is not allowed for this match */
export type BetTypeRestriction = {
    __typename?: 'BetTypeRestriction';
    betType: BetType;
    /** can be empty */
    sportEventId: Scalars['String'];
    type: RestrictionType;
};

export type BetsInput = {
    dateRange?: InputMaybe<DateRange>;
    ids?: InputMaybe<Array<Scalars['String']>>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    /** specific domain statuses */
    settlementStatuses?: InputMaybe<Array<BetSettlementStatus>>;
    sort?: InputMaybe<BetSort>;
    statuses?: InputMaybe<Array<BetStatus>>;
    types?: InputMaybe<Array<BetType>>;
};

export type BetsMarketOddPercentage = {
    __typename?: 'BetsMarketOddPercentage';
    id: Scalars['String'];
    odds: Array<BetsOddPercentage>;
};

export type BetsOddPercentage = {
    __typename?: 'BetsOddPercentage';
    id: Scalars['String'];
    percent: Scalars['Float'];
};

export type BetsSportEventOddPercentage = {
    __typename?: 'BetsSportEventOddPercentage';
    id: Scalars['String'];
    markets: Array<BetsMarketOddPercentage>;
};

export type Betslip = {
    __typename?: 'Betslip';
    mode: BetType;
    oddIds: Array<Scalars['String']>;
};

/** BettingSlip represents the betslip state */
export type BettingSlip = {
    __typename?: 'BettingSlip';
    /** list of odds in the betslip, name - oddPath (5:21ccdb66-a99f-4543-b8f8-cfc0317ac0d7~300m1t27_5~2), value - stake (1.78) */
    oddPaths: Array<Pair>;
    /** bet amount */
    stake?: Maybe<Scalars['String']>;
    /** system size if the bet type is SYSTEM */
    systemSize?: Maybe<Array<Scalars['Int']>>;
    /** type of bet (SINGLE, EXPRESS, SYSTEM) */
    type: BetType;
};

export type BrawlStarsOverview = {
    __typename?: 'BrawlStarsOverview';
    bestOf: Scalars['Int'];
    currentMap: Scalars['Int'];
    maps?: Maybe<Array<BrawlStarsOverviewMap>>;
    teams: BrawlStarsOverviewTeams;
};

export type BrawlStarsOverviewMap = {
    __typename?: 'BrawlStarsOverviewMap';
    firstBlood: WinnerHomeAway;
    number: Scalars['Int'];
    winner: TeamHomeAway;
};

export type BrawlStarsOverviewTeam = {
    __typename?: 'BrawlStarsOverviewTeam';
    score: Scalars['Int'];
};

export type BrawlStarsOverviewTeams = {
    __typename?: 'BrawlStarsOverviewTeams';
    away: BrawlStarsOverviewTeam;
    home: BrawlStarsOverviewTeam;
};

export type CsgoBomb = {
    __typename?: 'CSGOBomb';
    isPlanted: Scalars['Boolean'];
    time?: Maybe<Scalars['Int']>;
};

export enum CsgoMap {
    DeAncient = 'DE_ANCIENT',
    DeAnubis = 'DE_ANUBIS',
    DeCache = 'DE_CACHE',
    DeCobblestone = 'DE_COBBLESTONE',
    DeDust2 = 'DE_DUST2',
    DeInferno = 'DE_INFERNO',
    DeMirage = 'DE_MIRAGE',
    DeNuke = 'DE_NUKE',
    DeOverpass = 'DE_OVERPASS',
    DeTrain = 'DE_TRAIN',
    DeVertigo = 'DE_VERTIGO',
    Unknown = 'UNKNOWN',
}

export type CsgoMapOverviewTeamInfo = {
    __typename?: 'CSGOMapOverviewTeamInfo';
    score: Scalars['Int'];
};

export enum CsgoMatchFormat {
    AwayAdvMr12 = 'AWAY_ADV_MR12',
    AwayAdvMr15 = 'AWAY_ADV_MR15',
    HomeAdvMr12 = 'HOME_ADV_MR12',
    HomeAdvMr15 = 'HOME_ADV_MR15',
    Mr8Ot1 = 'MR8_OT1',
    Mr12Ot3 = 'MR12_OT3',
    Mr15 = 'MR15',
    Mr15WithDraw = 'MR15_WITH_DRAW',
}

export type CsgoOverview = {
    __typename?: 'CSGOOverview';
    bestOf: Scalars['Int'];
    bomb: CsgoBomb;
    currentMap: Scalars['Int'];
    currentRound: Scalars['Int'];
    gameState: Scalars['String'];
    mapName: Scalars['String'];
    maps?: Maybe<Array<CsgoOverviewMap>>;
    matchFormat: CsgoMatchFormat;
    pause: Scalars['Boolean'];
    teams: CsgoOverviewTeams;
    timer: Timer;
};

export type CsgoOverviewMap = {
    __typename?: 'CSGOOverviewMap';
    away: CsgoMapOverviewTeamInfo;
    home: CsgoMapOverviewTeamInfo;
    map: CsgoMap;
    number: Scalars['Int'];
    winner: TeamHomeAway;
};

export type CsgoOverviewTeam = {
    __typename?: 'CSGOOverviewTeam';
    currentSide: CsgoTeamSide;
    score: Scalars['Int'];
};

export type CsgoOverviewTeams = {
    __typename?: 'CSGOOverviewTeams';
    away: CsgoOverviewTeam;
    home: CsgoOverviewTeam;
};

export enum CsgoTeamSide {
    Ct = 'CT',
    T = 'T',
    Unknown = 'UNKNOWN',
}

export enum CacheStaleStrategy {
    Remove = 'REMOVE',
    Return = 'RETURN',
}

/** The bet with actualized data */
export type CalculatedBet = {
    __typename?: 'CalculatedBet';
    /** bet builder odds with actual coefficients */
    betBuilderOdds?: Maybe<Array<BetBuilderBetOdd>>;
    odds: Array<SimpleBetOdd>;
    systemSizes: Array<Scalars['Int']>;
    totalOddsValue: Scalars['String'];
    type: BetType;
};

export type CalculatedBetResult = {
    __typename?: 'CalculatedBetResult';
    calculatedBet?: Maybe<CalculatedBet>;
    /** can be empty */
    restrictions: Array<Restriction>;
};

export type CalculatedCashOut = {
    __typename?: 'CalculatedCashOut';
    /** calculated cashout info */
    amount: CalculatedCashOutAmount;
    /** if count restrictions > 0 - calculation not possible */
    restrictions: Array<CashOutRestriction>;
};

export type CalculatedCashOutAmount = {
    __typename?: 'CalculatedCashOutAmount';
    /**
     * the maximum part of the bet stake that can be cashed out (cash out is currently supported only for whole bets -
     * max_amount always equals to a bet stake
     */
    maxAmount: Scalars['String'];
    /**
     * the amount of money that can be credited to the player`s balance for cash out,
     * in which the amount equals to the max amount
     */
    maxRefundAmount: Scalars['String'];
    /**
     * the minimum part of the stake that can be cashed out (cash out is currently supported only for whole bets -
     * min_amount always equals to the bet stake
     */
    minAmount: Scalars['String'];
    /**
     * the amount of money that can be credited to the player`s balance for cash out,
     * the amount of which equals the min amount
     */
    minRefundAmount: Scalars['String'];
    /**
     * ranges are required when you can make a partial cash out
     * you can do quick cash out calculation without additional requests: if the amount is between from_amount
     * and to_amount values - you can calculate refund_amount = amount*refund_ratio
     */
    ranges: Array<CashOutRange>;
    /** the amount of money that will be credited to the player`s balance */
    refundAmount: Scalars['String'];
};

export enum CallOfDutyWarzoneFormatPlayers {
    Players150 = 'PLAYERS150',
    Players200 = 'PLAYERS200',
    Unknown = 'UNKNOWN',
}

export type CallOfDutyWarzoneGame = {
    __typename?: 'CallOfDutyWarzoneGame';
    format: CallOfDutyWarzoneFormatPlayers;
    hasBuyback: Scalars['Boolean'];
    hasGulag: Scalars['Boolean'];
    hasSelfReviveKit: Scalars['Boolean'];
    kills: Scalars['Int'];
    number: Scalars['Int'];
    partyTeams: Scalars['Int'];
    players: Scalars['Int'];
    squadSize: CallOfDutyWarzoneSquadSizeType;
    top: Scalars['Int'];
};

export type CallOfDutyWarzoneOverview = {
    __typename?: 'CallOfDutyWarzoneOverview';
    currentGame: Scalars['Int'];
    formatPlayers: CallOfDutyWarzoneFormatPlayers;
    games?: Maybe<Array<CallOfDutyWarzoneGame>>;
    squadSizeType: CallOfDutyWarzoneSquadSizeType;
};

export enum CallOfDutyWarzoneSquadSizeType {
    Double = 'DOUBLE',
    Single = 'SINGLE',
    Triple = 'TRIPLE',
    Unknown = 'UNKNOWN',
}

export enum CardType {
    Red = 'RED',
    Yellow = 'YELLOW',
    YellowRed = 'YELLOW_RED',
}

/**
 * Occurs when the amount of the bet and the amount that the player wants to return using
 * the cash out functionality do not match (one of them is larger or smaller than the other).
 */
export type CashOutAmountLimitRestriction = {
    __typename?: 'CashOutAmountLimitRestriction';
    cashOutAmount: Scalars['String'];
    cashOutMaxAmount: Scalars['String'];
    cashOutMinAmount: Scalars['String'];
    type: RestrictionType;
};

/** Occurs when the number of selections (a set of outcomes with fixed odds) in the bet and the cash out do not match. */
export type CashOutBetSelectionsMismatchRestriction = {
    __typename?: 'CashOutBetSelectionsMismatchRestriction';
    betSelections: Scalars['Int'];
    cashOutSelections: Scalars['Int'];
    type: RestrictionType;
};

/**
 * Appears when requesting a cash out on the bet of the express or system type.
 * Now the cash out is allowed only for single bets.
 */
export type CashOutBetTypeRestriction = {
    __typename?: 'CashOutBetTypeRestriction';
    betType?: Maybe<BetType>;
    /** deprecated, reason: use `betType` instead */
    cashOutBetType: Scalars['String'];
    type: RestrictionType;
};

export type CashOutContext = {
    __typename?: 'CashOutContext';
    /** Cash out is not possible due to business rules */
    restrictions: Array<CashOutRestriction>;
};

/**
 * The `cash out order` is a player's opportunity to cancel their bet and get the money back before a sport event ends.
 * Cash out is calculated based on a player's chance to win or lose
 * and changes throughout the entire sport event in Live or Prematch mode.
 * The lower the odds of an outcome are, the higher a player's **chance** to win is,
 * and the greater the cash out amount is.
 * The cash out function is available for all types of sports, single bets in Live and Prematch modes of sporting events.
 * But you can also fine-tune the cash out and disable it for certain sport events if necessary.
 *
 * **Chance** is the probability of a player's bet to win or lose in a sport event; it depends on outcome coefficients.
 */
export type CashOutOrder = {
    __typename?: 'CashOutOrder';
    /** The part of the stake that was cashed out */
    amount: Scalars['String'];
    /** ID of the bet that was cashed out */
    betId: Scalars['String'];
    /** The object with additional information about the cash out order */
    context: CashOutContext;
    /** Date and time when the player requested the cash out in ISO 8601 (RFC 3339) format */
    createdAt: Scalars['String'];
    /** Currency code in ISO 4217 (alfa-3) */
    currencyCode: Scalars['String'];
    id: Scalars['String'];
    /** The amount of money that should be credited to the player`s balance */
    refundAmount: Scalars['String'];
    /**
     * The state of selections at the moment of the cash out - must contain all selections
     * in the bet but have actual value (coefficients)
     */
    selections: Array<CashOutSelection>;
    /** The status of the cash out order */
    status: CashOutOrderStatus;
    /** Date and time of the last cash out update in ISO 8601 (RFC 3339) format */
    updatedAt: Scalars['String'];
};

/** Appears when trying to place  the cash out on a freebet */
export type CashOutOrderFreebetRestriction = {
    __typename?: 'CashOutOrderFreebetRestriction';
    type: RestrictionType;
};

export type CashOutOrderStatus = {
    __typename?: 'CashOutOrderStatus';
    code: CashOutOrderStatusCode;
    reason: Scalars['String'];
};

export enum CashOutOrderStatusCode {
    Accepted = 'accepted',
    Declined = 'declined',
    Pending = 'pending',
}

/** Occurs when a player requests a repeated cash out on the same bet, and the previous one is still being processed. */
export type CashOutOrderStatusRestriction = {
    __typename?: 'CashOutOrderStatusRestriction';
    cashOutOrderId: Scalars['String'];
    cashOutOrderStatus: CashOutOrderStatusCode;
    type: RestrictionType;
};

/** if the amount is between fromAmount and refundRatio - you can calculate refundAmount = amount*refundRatio */
export type CashOutRange = {
    __typename?: 'CashOutRange';
    /** the amount for fast calculation of refund_amount without additional request must be >= from_amount */
    fromAmount: Scalars['String'];
    /**
     * ratio for calculation of refund_amount for the amount that is between the from_amount and to_amount
     * example: refund_amount = amount * refund_ratio"
     */
    refundRatio: Scalars['String'];
    /** the amount for fast calculation of refund_amount without additional request must be <= to_amount */
    toAmount: Scalars['String'];
};

/** Appears if the coefficients have changed while processing the cash out and refund_amount changes as the result. */
export type CashOutRefundAmountRestriction = {
    __typename?: 'CashOutRefundAmountRestriction';
    cashOutCalculatedRefundAmount: Scalars['String'];
    cashOutRefundAmount: Scalars['String'];
    type: RestrictionType;
};

export type CashOutRestriction =
    | BaselineSelectionExistenceRestriction
    | BetProviderRestriction
    | BetSelectionExistenceRestriction
    | BetStatusRestriction
    | CashOutAmountLimitRestriction
    | CashOutBetSelectionsMismatchRestriction
    | CashOutBetTypeRestriction
    | CashOutOrderFreebetRestriction
    | CashOutOrderStatusRestriction
    | CashOutRefundAmountRestriction
    | CashOutUnavailableRestriction
    | MarketDefectiveRestriction
    | MarketExistenceRestriction
    | MarketStatusRestriction
    | OddExistenceRestriction
    | OddStatusRestriction
    | PlayerLimitRestriction
    | SelectionValueRestriction
    | SportEventBetStopRestriction
    | SportEventExistenceRestriction
    | SportEventStatusRestriction
    | SportEventTypeRestriction;

export type CashOutSelection = {
    __typename?: 'CashOutSelection';
    marketId: Scalars['String'];
    oddId: Scalars['String'];
    sportEventId: Scalars['String'];
    /** odd value at the moment of cash out */
    value: Scalars['String'];
};

/**
 * Occurs when:
 * - The cash out functionality is deactivated manually.
 * - The match result is known, but the bet settlement has not completed yet.
 */
export type CashOutUnavailableRestriction = {
    __typename?: 'CashOutUnavailableRestriction';
    reason: Scalars['String'];
    type: RestrictionType;
};

export type CategorizerExpand = {
    countLiveEventsBySport?: InputMaybe<Scalars['Boolean']>;
    countMatchEventsBySport?: InputMaybe<Scalars['Boolean']>;
    countOutrightEventsBySport?: InputMaybe<Scalars['Boolean']>;
    countPreMatchEventsBySport?: InputMaybe<Scalars['Boolean']>;
};

export type CategorizerItem = {
    __typename?: 'CategorizerItem';
    children: Array<CategorizerItem>;
    count: Scalars['Int'];
    field: Scalars['String'];
    meta: Array<Pair>;
    name: Scalars['String'];
    value: Scalars['String'];
    weight?: Maybe<Scalars['Int']>;
};

export enum CategoryTreeType {
    TreeTypeCountry = 'TREE_TYPE_COUNTRY',
    TreeTypeSport = 'TREE_TYPE_SPORT',
    TreeTypeTournament = 'TREE_TYPE_TOURNAMENT',
}

export type CharacterInfo = {
    __typename?: 'CharacterInfo';
    fatalBlow: Scalars['Boolean'];
    health: Scalars['Float'];
};

export type Competitor = Player | Team;

export enum CompetitorHomeAway {
    Away = 'AWAY',
    Home = 'HOME',
    Unknown = 'UNKNOWN',
}

export type CompetitorScore = {
    __typename?: 'CompetitorScore';
    id: Scalars['String'];
    number: Scalars['Int'];
    path: Scalars['String'];
    points: Scalars['String'];
    type: Scalars['String'];
};

export enum CompetitorType {
    Other = 'OTHER',
    Person = 'PERSON',
    Team = 'TEAM',
}

export type CompiledMarketsTab = {
    __typename?: 'CompiledMarketsTab';
    id: Scalars['String'];
    marketIds: Array<Scalars['String']>;
    /** @deprecated please, query markets on sport event instead */
    markets: Array<Market>;
    name: Scalars['String'];
    sportEvent: SportEvent;
};

export type CompiledMarketsTabMarketIdsArgs = {
    statuses?: InputMaybe<Array<MarketStatus>>;
};

export type CompiledMarketsTabMarketsArgs = {
    statuses?: InputMaybe<Array<MarketStatus>>;
};

export type CompiledMarketsTabs = {
    __typename?: 'CompiledMarketsTabs';
    sportEvent: SportEvent;
    tabs: Array<CompiledMarketsTab>;
};

export type CompiledMarketsTabsDiff = {
    __typename?: 'CompiledMarketsTabsDiff';
    added: Array<CompiledMarketsTab>;
    removed: Array<CompiledMarketsTab>;
    sportEvent: SportEvent;
};

export enum CricketGameFormat {
    InningAndOneDayInternational = 'INNING_AND_ONE_DAY_INTERNATIONAL',
    TestMatch = 'TEST_MATCH',
    Twenty = 'TWENTY',
    Unknown = 'UNKNOWN',
}

export type CricketInning = {
    __typename?: 'CricketInning';
    halfNumber: Scalars['Int'];
    homeRuns: Scalars['Int'];
    number: Scalars['Int'];
    outs: Scalars['Int'];
    points: Scalars['Int'];
    strikes: Scalars['Int'];
    team: TeamHomeAway;
    type: CricketInningType;
};

export enum CricketInningType {
    Extra = 'EXTRA',
    Regular = 'REGULAR',
}

export type CricketOverview = {
    __typename?: 'CricketOverview';
    awayPoints: Scalars['Int'];
    currentInning: Scalars['Int'];
    currentInningHalf: Scalars['Int'];
    gameFormat: CricketGameFormat;
    homePoints: Scalars['Int'];
    innings?: Maybe<Array<CricketInning>>;
};

export type DateRange = {
    from: Scalars['String'];
    to: Scalars['String'];
};

export type Dota2MapOverviewTeam = {
    __typename?: 'Dota2MapOverviewTeam';
    kills: Scalars['Int'];
    side: Dota2TeamSide;
};

export type Dota2MapOverviewTeams = {
    __typename?: 'Dota2MapOverviewTeams';
    away: Dota2MapOverviewTeam;
    home: Dota2MapOverviewTeam;
};

export type Dota2Overview = {
    __typename?: 'Dota2Overview';
    bestOf: Scalars['Int'];
    currentGoldLead: Scalars['Int'];
    currentMap: Scalars['Int'];
    maps?: Maybe<Array<Dota2OverviewMap>>;
    pause: Scalars['Boolean'];
    teams: Dota2OverviewTeams;
    timer: Timer;
};

export type Dota2OverviewMap = {
    __typename?: 'Dota2OverviewMap';
    goldLead: Scalars['Int'];
    number: Scalars['Int'];
    teams: Dota2MapOverviewTeams;
};

export type Dota2OverviewTeam = {
    __typename?: 'Dota2OverviewTeam';
    score: Scalars['Int'];
};

export type Dota2OverviewTeams = {
    __typename?: 'Dota2OverviewTeams';
    away: Dota2OverviewTeam;
    home: Dota2OverviewTeam;
};

export enum Dota2TeamSide {
    Dire = 'DIRE',
    Radiant = 'RADIANT',
    Unknown = 'UNKNOWN',
}

export type DoubleEliminationMatch = {
    __typename?: 'DoubleEliminationMatch';
    bracketMatchID: Scalars['Int'];
    fixture?: Maybe<TournamentBracketFixture>;
    nextLostMatch: Scalars['Int'];
    nextWinMatch: Scalars['Int'];
    prevMatch1: Scalars['Int'];
    prevMatch2: Scalars['Int'];
    round: Scalars['Int'];
    roundType: DoubleEliminationRoundType;
};

export enum DoubleEliminationRoundType {
    GrandFinalBracket = 'GRAND_FINAL_BRACKET',
    LowerBracket = 'LOWER_BRACKET',
    UpperBracket = 'UPPER_BRACKET',
}

export type DoubleEliminationTournamentBracket = TournamentBracket & {
    __typename?: 'DoubleEliminationTournamentBracket';
    bracketType: TournamentBracketType;
    matches: Array<DoubleEliminationMatch>;
};

export type EBasketballOverview = {
    __typename?: 'EBasketballOverview';
    currentQuarter: Scalars['Int'];
    pause: Scalars['Boolean'];
    quarterFormat: Scalars['Int'];
    quarters: Array<EBasketballOverviewQuarter>;
    teams: EBasketballOverviewTeams;
    timer: Timer;
};

export type EBasketballOverviewQuarter = {
    __typename?: 'EBasketballOverviewQuarter';
    away: EBasketballQuarterTeam;
    home: EBasketballQuarterTeam;
    number: Scalars['Int'];
};

export type EBasketballOverviewTeam = {
    __typename?: 'EBasketballOverviewTeam';
    fouls: Scalars['Int'];
    score: Scalars['Int'];
};

export type EBasketballOverviewTeams = {
    __typename?: 'EBasketballOverviewTeams';
    away: EBasketballOverviewTeam;
    home: EBasketballOverviewTeam;
};

export type EBasketballQuarterTeam = {
    __typename?: 'EBasketballQuarterTeam';
    score: Scalars['Int'];
};

export type EFootballOverview = {
    __typename?: 'EFootballOverview';
    currentPeriod: EFootballPeriod;
    isOvertime: Scalars['Boolean'];
    isPenaltyTime: Scalars['Boolean'];
    pause: Scalars['Boolean'];
    periods?: Maybe<Array<EFootballOverviewPeriod>>;
    teams: EFootballOverviewTeams;
    timer: Timer;
};

export type EFootballOverviewPenalties = {
    __typename?: 'EFootballOverviewPenalties';
    matchTime: Scalars['Int'];
    number: Scalars['Int'];
    status: PenaltyStatus;
    team: TeamHomeAway;
};

export type EFootballOverviewPeriod = {
    __typename?: 'EFootballOverviewPeriod';
    away: EFootballOverviewPeriodTeam;
    home: EFootballOverviewPeriodTeam;
    period: EFootballPeriod;
};

export type EFootballOverviewPeriodTeam = {
    __typename?: 'EFootballOverviewPeriodTeam';
    penalties?: Maybe<Array<EFootballOverviewPenalties>>;
    score: Scalars['Int'];
};

export type EFootballOverviewTeamTotal = {
    __typename?: 'EFootballOverviewTeamTotal';
    corners: Scalars['Int'];
    redCards: Scalars['Int'];
    score: Scalars['Int'];
    yellowCards: Scalars['Int'];
};

export type EFootballOverviewTeams = {
    __typename?: 'EFootballOverviewTeams';
    away: EFootballOverviewTeamTotal;
    home: EFootballOverviewTeamTotal;
};

export enum EFootballPeriod {
    FirstExtra = 'FIRST_EXTRA',
    FirstHalf = 'FIRST_HALF',
    Last = 'LAST',
    Penalties = 'PENALTIES',
    SecondExtra = 'SECOND_EXTRA',
    SecondHalf = 'SECOND_HALF',
    Unknown = 'UNKNOWN',
}

export type EIceHockeyOverview = {
    __typename?: 'EIceHockeyOverview';
    currentPeriod: Scalars['Int'];
    isOvertime: Scalars['Boolean'];
    isShootout: Scalars['Boolean'];
    pause: Scalars['Boolean'];
    periods?: Maybe<Array<EIceHockeyOverviewPeriod>>;
    teams: EIceHockeyOverviewTeams;
    timer: Timer;
};

export type EIceHockeyOverviewBulletThrow = {
    __typename?: 'EIceHockeyOverviewBulletThrow';
    active: Scalars['Boolean'];
    canceled: Scalars['Boolean'];
    number: Scalars['Int'];
    scored: Scalars['Boolean'];
};

export type EIceHockeyOverviewPeriod = {
    __typename?: 'EIceHockeyOverviewPeriod';
    away: EIceHockeyOverviewPeriodTeam;
    home: EIceHockeyOverviewPeriodTeam;
    number: Scalars['Int'];
    periodType: EIceHockeyPeriodType;
};

export type EIceHockeyOverviewPeriodTeam = {
    __typename?: 'EIceHockeyOverviewPeriodTeam';
    bulletThrows?: Maybe<Array<EIceHockeyOverviewBulletThrow>>;
    score: Scalars['Int'];
};

export type EIceHockeyOverviewTeam = {
    __typename?: 'EIceHockeyOverviewTeam';
    bulletThrows: Scalars['Int'];
    score: Scalars['Int'];
};

export type EIceHockeyOverviewTeams = {
    __typename?: 'EIceHockeyOverviewTeams';
    away: EIceHockeyOverviewTeam;
    home: EIceHockeyOverviewTeam;
};

export enum EIceHockeyPeriodType {
    Bullets = 'BULLETS',
    Overtime = 'OVERTIME',
    Regular = 'REGULAR',
}

export type EStreetballOverview = {
    __typename?: 'EStreetballOverview';
    awayPoints: Scalars['Int'];
    ballPossessionTeam: TeamHomeAway;
    homePoints: Scalars['Int'];
    winner: TeamHomeAway;
};

export type ETennisOverview = {
    __typename?: 'ETennisOverview';
    currentSet: Scalars['Int'];
    pause: Scalars['Boolean'];
    server: TeamHomeAway;
    sets?: Maybe<Array<ETennisOverviewSet>>;
    teams: ETennisOverviewTeams;
};

export type ETennisOverviewGame = {
    __typename?: 'ETennisOverviewGame';
    score: Scalars['Int'];
};

export type ETennisOverviewGamePoint = {
    __typename?: 'ETennisOverviewGamePoint';
    gamePoint: TennisGamePoint;
};

export type ETennisOverviewSet = {
    __typename?: 'ETennisOverviewSet';
    gameScore: ETennisOverviewSetGameScore;
    number: Scalars['Int'];
    state: TennisSetStatus;
    tieBreakScore: ETennisOverviewSetTieBreakScore;
    winner: TeamHomeAway;
};

export type ETennisOverviewSetGameScore = {
    __typename?: 'ETennisOverviewSetGameScore';
    away: Scalars['Int'];
    home: Scalars['Int'];
};

export type ETennisOverviewSetTieBreakScore = {
    __typename?: 'ETennisOverviewSetTieBreakScore';
    away: Scalars['Int'];
    home: Scalars['Int'];
};

export type ETennisOverviewTeam = {
    __typename?: 'ETennisOverviewTeam';
    game: ETennisOverviewGame;
    gamePoint: ETennisOverviewGamePoint;
    set: ETennisOverviewTeamSet;
    tieBreak: ETennisOverviewTieBreak;
};

export type ETennisOverviewTeamSet = {
    __typename?: 'ETennisOverviewTeamSet';
    score: Scalars['Int'];
};

export type ETennisOverviewTeams = {
    __typename?: 'ETennisOverviewTeams';
    away: ETennisOverviewTeam;
    home: ETennisOverviewTeam;
};

export type ETennisOverviewTieBreak = {
    __typename?: 'ETennisOverviewTieBreak';
    score: Scalars['Int'];
};

export type EufcOverview = {
    __typename?: 'EUFCOverview';
    result: EufcResult;
    round: Scalars['Int'];
    timer: Timer;
};

export enum EufcReason {
    D = 'D',
    Dq = 'DQ',
    Ko = 'KO',
    O = 'O',
    Sub = 'SUB',
    Td = 'TD',
    Tko = 'TKO',
    U = 'U',
}

export type EufcResult = {
    __typename?: 'EUFCResult';
    reason: EufcReason;
    roundNumber?: Maybe<Scalars['Int']>;
    time?: Maybe<Scalars['Int']>;
    winner: TeamHomeAway;
};

export type EVolleyballOverview = {
    __typename?: 'EVolleyballOverview';
    currentSet: Scalars['Int'];
    server: TeamHomeAway;
    sets?: Maybe<Array<EVolleyballOverviewSet>>;
    teams: EVolleyballOverviewTeams;
};

export type EVolleyballOverviewSet = {
    __typename?: 'EVolleyballOverviewSet';
    number: Scalars['Int'];
    totalPointsAway: Scalars['Int'];
    totalPointsHome: Scalars['Int'];
    type: EVolleyballSetType;
    winner: TeamHomeAway;
};

export type EVolleyballOverviewTeam = {
    __typename?: 'EVolleyballOverviewTeam';
    score: Scalars['Int'];
};

export type EVolleyballOverviewTeams = {
    __typename?: 'EVolleyballOverviewTeams';
    away: EVolleyballOverviewTeam;
    home: EVolleyballOverviewTeam;
};

export enum EVolleyballSetType {
    Golden = 'GOLDEN',
    Regular = 'REGULAR',
}

export type EmbeddedVideoLink = {
    __typename?: 'EmbeddedVideoLink';
    url: Scalars['String'];
};

/** EmbeddedVideoLinkForbiddenError is returned when it's forbidden to generate Grid token */
export type EmbeddedVideoLinkForbiddenError = {
    __typename?: 'EmbeddedVideoLinkForbiddenError';
    code: Scalars['String'];
};

/** EmbeddedVideoLinkResult represents the result (success/failure) of the Databet video link generation operation */
export type EmbeddedVideoLinkResult =
    | EmbeddedVideoLink
    | EmbeddedVideoLinkForbiddenError;

export type FeaturedCustomList = {
    __typename?: 'FeaturedCustomList';
    config?: Maybe<FeaturedZoneCustomListProvider>;
    sportEventsList: SportEventList;
};

export type FeaturedCustomListSportEventsListArgs = {
    limit?: Scalars['Int'];
    marketStatuses?: InputMaybe<Array<MarketStatus>>;
    matchStatuses?: InputMaybe<Array<SportEventStatus>>;
    offset?: Scalars['Int'];
};

export enum FeaturedSportType {
    Classic = 'CLASSIC',
    Esport = 'ESPORT',
}

export type FeaturedZoneConfigProvider =
    | FeaturedZoneCustomListProvider
    | FeaturedZoneTournamentProvider;

export type FeaturedZoneCustomListProvider = {
    __typename?: 'FeaturedZoneCustomListProvider';
    competitorIds?: Maybe<Array<Scalars['String']>>;
    description: Scalars['String'];
    endedAt: Scalars['String'];
    id: Scalars['String'];
    image: Scalars['String'];
    priority: Scalars['Int'];
    slug: Scalars['String'];
    sportEventIds?: Maybe<Array<Scalars['String']>>;
    sportEventStartFrom: Scalars['String'];
    sportEventStartTo: Scalars['String'];
    sportType: FeaturedSportType;
    startedAt: Scalars['String'];
    title: Scalars['String'];
    tournamentIds?: Maybe<Array<Scalars['String']>>;
};

export type FeaturedZoneTournamentProvider = {
    __typename?: 'FeaturedZoneTournamentProvider';
    endedAt: Scalars['String'];
    id: Scalars['String'];
    priority: Scalars['Int'];
    sportEventCount: Scalars['Int'];
    startedAt: Scalars['String'];
    tournament?: Maybe<Tournament>;
};

export type FootballOverview = {
    __typename?: 'FootballOverview';
    currentPeriod: FootballPeriod;
    isVarActive: Scalars['Boolean'];
    pause: Scalars['Boolean'];
    periods?: Maybe<Array<FootballOverviewPeriod>>;
    teams: FootballOverviewTeams;
    timer: Timer;
};

export type FootballOverviewPenalties = {
    __typename?: 'FootballOverviewPenalties';
    matchTime: Scalars['Int'];
    number: Scalars['Int'];
    player: FootballOverviewPlayer;
    /** @deprecated use PenaltyStatus */
    status: FootballPenaltyStatus;
};

export type FootballOverviewPeriod = {
    __typename?: 'FootballOverviewPeriod';
    away: FootballOverviewPeriodTeam;
    home: FootballOverviewPeriodTeam;
    period: FootballPeriod;
};

export type FootballOverviewPeriodTeam = {
    __typename?: 'FootballOverviewPeriodTeam';
    fouls: Scalars['Int'];
    goalKicks: Scalars['Int'];
    offsides: Scalars['Int'];
    penalties?: Maybe<Array<FootballOverviewPenalties>>;
    score: Scalars['Int'];
    shots: Scalars['Int'];
    shotsOnGoals: Scalars['Int'];
    throwIns: Scalars['Int'];
};

export type FootballOverviewPlayer = {
    __typename?: 'FootballOverviewPlayer';
    id: Scalars['String'];
    name: Scalars['String'];
};

export type FootballOverviewTeamTotal = {
    __typename?: 'FootballOverviewTeamTotal';
    cornerKicks: Scalars['Int'];
    fouls: Scalars['Int'];
    goalKicks: Scalars['Int'];
    offsides: Scalars['Int'];
    redCards: Scalars['Int'];
    score: Scalars['Int'];
    shots: Scalars['Int'];
    shotsOnGoals: Scalars['Int'];
    throwIns: Scalars['Int'];
    yellowCards: Scalars['Int'];
};

export type FootballOverviewTeams = {
    __typename?: 'FootballOverviewTeams';
    away: FootballOverviewTeamTotal;
    home: FootballOverviewTeamTotal;
};

export enum FootballPenaltyStatus {
    Awarded = 'AWARDED',
    Canceled = 'CANCELED',
    Ended = 'ENDED',
    Missed = 'MISSED',
    Unknown = 'UNKNOWN',
}

export enum FootballPeriod {
    FirstExtra = 'FIRST_EXTRA',
    FirstHalf = 'FIRST_HALF',
    Last = 'LAST',
    Penalties = 'PENALTIES',
    SecondExtra = 'SECOND_EXTRA',
    SecondHalf = 'SECOND_HALF',
    Unknown = 'UNKNOWN',
}

export type FortniteGame = {
    __typename?: 'FortniteGame';
    kills: Scalars['Int'];
    number: Scalars['Int'];
    players: Scalars['Int'];
    top: Scalars['Int'];
};

export type FortniteOverview = {
    __typename?: 'FortniteOverview';
    currentGame: Scalars['Int'];
    games?: Maybe<Array<FortniteGame>>;
};

export type Freebet = {
    __typename?: 'Freebet';
    amount: Scalars['String'];
    /** conditions under which a freebet can be applied to a bet */
    betConditions: Array<BetCondition>;
    /** @deprecated Use `betConditions` instead */
    conditions: Array<FreebetCondition>;
    expiresAt: Scalars['String'];
    id: Scalars['String'];
    usedAt: Scalars['String'];
};

/** The action for the freebet with given value not allowed, maybe the freebet was changed after place bet */
export type FreebetAmountRestriction = {
    __typename?: 'FreebetAmountRestriction';
    betCurrency: Scalars['String'];
    betStake: Scalars['String'];
    freebetAmount: Scalars['String'];
    freebetCurrency: Scalars['String'];
    type: RestrictionType;
};

/** deprecated - use DetailCondition */
export type FreebetBetDetailCondition = {
    type: FreebetBetDetailsConditionType;
};

/** deprecated - use BetDetails */
export type FreebetBetDetails = {
    __typename?: 'FreebetBetDetails';
    conditions: Array<FreebetBetDetailCondition>;
    type: FreebetBetDetailsType;
};

/** deprecated - use BetDetailsConditionAggregate */
export type FreebetBetDetailsConditionAggregate = FreebetCondition & {
    __typename?: 'FreebetBetDetailsConditionAggregate';
    betDetails: Array<FreebetBetDetails>;
    type: FreebetConditionType;
};

/** deprecated - use BetDetailsConditionType */
export enum FreebetBetDetailsConditionType {
    CombinationsCount = 'COMBINATIONS_COUNT',
    Competitor = 'COMPETITOR',
    MarketType = 'MARKET_TYPE',
    OddsCoefficient = 'ODDS_COEFFICIENT',
    OddsCount = 'ODDS_COUNT',
    Sport = 'SPORT',
    SportEvent = 'SPORT_EVENT',
    SystemSize = 'SYSTEM_SIZE',
    TotalOddsCoefficient = 'TOTAL_ODDS_COEFFICIENT',
    Tournament = 'TOURNAMENT',
}

/** deprecated - use BetDetailsType */
export enum FreebetBetDetailsType {
    Express = 'EXPRESS',
    Single = 'SINGLE',
    System = 'SYSTEM',
}

/** deprecated - use BetTypeCondition */
export type FreebetBetTypeCondition = FreebetCondition & {
    __typename?: 'FreebetBetTypeCondition';
    betType: BetType;
    type: FreebetConditionType;
};

/** deprecated - use BetCombinationsCountBetDetailsCondition */
export type FreebetCombinationsCountBetDetailsCondition =
    FreebetBetDetailCondition & {
        __typename?: 'FreebetCombinationsCountBetDetailsCondition';
        max?: Maybe<Scalars['Int']>;
        min?: Maybe<Scalars['Int']>;
        shouldMatchAllOdds: Scalars['Boolean'];
        type: FreebetBetDetailsConditionType;
    };

/** deprecated - use BetCompetitorBetDetailsCondition */
export type FreebetCompetitorBetDetailsCondition = FreebetBetDetailCondition & {
    __typename?: 'FreebetCompetitorBetDetailsCondition';
    competitors: Array<SportEventCompetitor>;
    shouldMatchAllOdds: Scalars['Boolean'];
    type: FreebetBetDetailsConditionType;
};

/** deprecated - use BetCompetitorCondition */
export type FreebetCompetitorCondition = FreebetCondition & {
    __typename?: 'FreebetCompetitorCondition';
    competitors: Array<SportEventCompetitor>;
    type: FreebetConditionType;
};

/** deprecated - use BetCondition */
export type FreebetCondition = {
    type: FreebetConditionType;
};

/** deprecated - use BetConditionType */
export enum FreebetConditionType {
    BetDetails = 'bet_details',
    BetType = 'bet_type',
    Competitors = 'competitors',
    /** @deprecated Use sport_kind_ids instead */
    Country = 'country',
    EventMatchIds = 'event_match_ids',
    /** @deprecated Use sport_kind_ids instead */
    KindOfSportId = 'kind_of_sport_id',
    MarketTypes = 'market_types',
    OddRatio = 'odd_ratio',
    SportKindIds = 'sport_kind_ids',
    Tournaments = 'tournaments',
}

/** deprecated - use BetCountryCondition */
export type FreebetCountryCondition = FreebetCondition & {
    __typename?: 'FreebetCountryCondition';
    country: Scalars['String'];
    type: FreebetConditionType;
};

/** deprecated - use BetMarketTypeBetDetailsCondition */
export type FreebetMarketTypeBetDetailsCondition = FreebetBetDetailCondition & {
    __typename?: 'FreebetMarketTypeBetDetailsCondition';
    marketTypes: Array<Scalars['String']>;
    shouldMatchAllOdds: Scalars['Boolean'];
    type: FreebetBetDetailsConditionType;
};

/** deprecated - use BetMarketTypeCondition */
export type FreebetMarketTypeCondition = FreebetCondition & {
    __typename?: 'FreebetMarketTypeCondition';
    marketTypes: Array<Scalars['String']>;
    type: FreebetConditionType;
};

export enum FreebetNotApplicableReason {
    Expired = 'EXPIRED',
    NotApplicableForCurrentBet = 'NOT_APPLICABLE_FOR_CURRENT_BET',
    NotFound = 'NOT_FOUND',
    Unknown = 'UNKNOWN',
}

/** The requested freebet is not applicable */
export type FreebetNotApplicableRestriction = {
    __typename?: 'FreebetNotApplicableRestriction';
    reason: FreebetNotApplicableReason;
    type: RestrictionType;
};

/** deprecated - use BetOddValueCondition */
export type FreebetOddValueCondition = FreebetCondition & {
    __typename?: 'FreebetOddValueCondition';
    max: Scalars['String'];
    min: Scalars['String'];
    type: FreebetConditionType;
};

/** deprecated - use BetOddsCoefficientBetDetailsCondition */
export type FreebetOddsCoefficientBetDetailsCondition =
    FreebetBetDetailCondition & {
        __typename?: 'FreebetOddsCoefficientBetDetailsCondition';
        max?: Maybe<Scalars['String']>;
        min?: Maybe<Scalars['String']>;
        shouldMatchAllOdds: Scalars['Boolean'];
        type: FreebetBetDetailsConditionType;
    };

/** deprecated - use BetOddsCountBetDetailsCondition */
export type FreebetOddsCountBetDetailsCondition = FreebetBetDetailCondition & {
    __typename?: 'FreebetOddsCountBetDetailsCondition';
    max?: Maybe<Scalars['Int']>;
    min?: Maybe<Scalars['Int']>;
    type: FreebetBetDetailsConditionType;
};

/** deprecated - use BetSportBetDetailsCondition */
export type FreebetSportBetDetailsCondition = FreebetBetDetailCondition & {
    __typename?: 'FreebetSportBetDetailsCondition';
    shouldMatchAllOdds: Scalars['Boolean'];
    sports: Array<Sport>;
    type: FreebetBetDetailsConditionType;
};

/** deprecated - use BetSportCondition */
export type FreebetSportCondition = FreebetCondition & {
    __typename?: 'FreebetSportCondition';
    /** @deprecated Use `sports` instead */
    sportIds: Array<Scalars['String']>;
    sports: Array<Sport>;
    type: FreebetConditionType;
};

/** deprecated - use BetSportEventBetDetailsCondition */
export type FreebetSportEventBetDetailsCondition = FreebetBetDetailCondition & {
    __typename?: 'FreebetSportEventBetDetailsCondition';
    shouldMatchAllOdds: Scalars['Boolean'];
    sportEvents: Array<SportEvent>;
    type: FreebetBetDetailsConditionType;
};

/** deprecated - use BetSportEventCondition */
export type FreebetSportEventCondition = FreebetCondition & {
    __typename?: 'FreebetSportEventCondition';
    /** @deprecated Use sportEvents instead */
    sportEventIds: Array<Scalars['String']>;
    sportEvents: Array<SportEvent>;
    type: FreebetConditionType;
};

export enum FreebetStatusInRestriction {
    Canceled = 'CANCELED',
    Unknown = 'UNKNOWN',
    Used = 'USED',
}

/** The action for the freebet in this status is not allowed */
export type FreebetStatusRestriction = {
    __typename?: 'FreebetStatusRestriction';
    status: FreebetStatusInRestriction;
    type: RestrictionType;
};

/** deprecated - use BetSystemSizeBetDetailsCondition */
export type FreebetSystemSizeBetDetailsCondition = FreebetBetDetailCondition & {
    __typename?: 'FreebetSystemSizeBetDetailsCondition';
    max?: Maybe<Scalars['Int']>;
    min?: Maybe<Scalars['Int']>;
    type: FreebetBetDetailsConditionType;
};

/** deprecated - use BetTotalOddsCoefficientBetDetailsCondition */
export type FreebetTotalOddsCoefficientBetDetailsCondition =
    FreebetBetDetailCondition & {
        __typename?: 'FreebetTotalOddsCoefficientBetDetailsCondition';
        max?: Maybe<Scalars['String']>;
        min?: Maybe<Scalars['String']>;
        type: FreebetBetDetailsConditionType;
    };

/** deprecated - use BetTournamentBetDetailsCondition */
export type FreebetTournamentBetDetailsCondition = FreebetBetDetailCondition & {
    __typename?: 'FreebetTournamentBetDetailsCondition';
    shouldMatchAllOdds: Scalars['Boolean'];
    tournaments: Array<Tournament>;
    type: FreebetBetDetailsConditionType;
};

/** deprecated - use BetTournamentCondition */
export type FreebetTournamentCondition = FreebetCondition & {
    __typename?: 'FreebetTournamentCondition';
    /** @deprecated Use `tournaments` instead */
    tournamentIds: Array<Scalars['String']>;
    tournaments: Array<Tournament>;
    type: FreebetConditionType;
};

export type FreebetsFilter = {
    onlyActual?: InputMaybe<Scalars['Boolean']>;
};

export type FutsalOverview = {
    __typename?: 'FutsalOverview';
    currentPeriod: FutsalPeriod;
    isOvertime: Scalars['Boolean'];
    isPenaltyTime: Scalars['Boolean'];
    periods?: Maybe<Array<FutsalOverviewPeriod>>;
    teams: FutsalOverviewTeams;
    timer: Timer;
};

export type FutsalOverviewPenalties = {
    __typename?: 'FutsalOverviewPenalties';
    matchTime: Scalars['Int'];
    number: Scalars['Int'];
    status: PenaltyStatus;
    team: TeamHomeAway;
};

export type FutsalOverviewPeriod = {
    __typename?: 'FutsalOverviewPeriod';
    away: FutsalOverviewPeriodTeam;
    home: FutsalOverviewPeriodTeam;
    period: FutsalPeriod;
};

export type FutsalOverviewPeriodTeam = {
    __typename?: 'FutsalOverviewPeriodTeam';
    penalties?: Maybe<Array<FutsalOverviewPenalties>>;
    score: Scalars['Int'];
};

export type FutsalOverviewTeamTotal = {
    __typename?: 'FutsalOverviewTeamTotal';
    redCards: Scalars['Int'];
    score: Scalars['Int'];
    yellowCards: Scalars['Int'];
};

export type FutsalOverviewTeams = {
    __typename?: 'FutsalOverviewTeams';
    away: FutsalOverviewTeamTotal;
    home: FutsalOverviewTeamTotal;
};

export enum FutsalPeriod {
    FirstExtra = 'FIRST_EXTRA',
    FirstHalf = 'FIRST_HALF',
    Last = 'LAST',
    Penalties = 'PENALTIES',
    SecondExtra = 'SECOND_EXTRA',
    SecondHalf = 'SECOND_HALF',
    Unknown = 'UNKNOWN',
}

/**
 * The `Gambler` type represents personal UI settings of the bettor.
 * These settings are represented as key-value pairs and allow storing any key-value pair.
 * It is used by the front-end application to keep the bettor's UI preferences (keep some modals open/closed,
 * favorite markets, show odds statistics, etc.)
 * There are CRUD operations to manage the bettor's UI preferences.
 */
export type Gambler = {
    __typename?: 'Gambler';
    /** @deprecated technical details, useless for gql client */
    createdAt: Scalars['String'];
    /** @deprecated technical details, useless for gql client */
    foreignId: Scalars['String'];
    /** bettor identity in the betting system */
    id: Scalars['String'];
    /** @deprecated technical details, useless for gql client */
    platformBrandId: Scalars['String'];
    /** array of the bettor's UI preferences, `key` is a name of preference, `value` is a value of preference */
    settings?: Maybe<Array<Pair>>;
    /** @deprecated technical details, useless for gql client */
    updatedAt: Scalars['String'];
};

/** GamblerSetting represents the bettor's UI preferences (keep some modals open/closed, favorite markets, show odds statistics, etc.) */
export type GamblerSetting = {
    /** name of the preference (favoriteMarketsId, isOddsChangeModalShown, etc.) */
    name: Scalars['String'];
    /** value of the preference (`true` for `isOddsChangeModalShown`, [`123`, `12`] for `favoriteMarketsId`, etc.) */
    value: Scalars['String'];
};

export type GameEvent = {
    __typename?: 'GameEvent';
    data: Scalars['String'];
    id: Scalars['String'];
    tickEnd: Scalars['Int'];
    tickStart: Scalars['Int'];
    timeEnd: Scalars['Int'];
    timeStart: Scalars['Int'];
    type: Scalars['String'];
};

export type GameMeta = {
    __typename?: 'GameMeta';
    teamSide: Array<Pair>;
};

export type GameStream = {
    __typename?: 'GameStream';
    id: Scalars['String'];
    locale: Scalars['String'];
    start: Scalars['Int'];
    type: Scalars['String'];
    url: Scalars['String'];
};

/** GridEmbedVideoToken is a token for the Grid embedded video player for a specific seriesId */
export type GridEmbedVideoToken = {
    __typename?: 'GridEmbedVideoToken';
    /** seriesId is an identifier of the match within Grid API */
    seriesId: Scalars['String'];
    /** a token for the Grid embedded video player */
    token: Scalars['String'];
};

/** GridEmbedVideoTokenForbiddenError is returned when it's forbidden to generate Grid token */
export type GridEmbedVideoTokenForbiddenError = {
    __typename?: 'GridEmbedVideoTokenForbiddenError';
    /** code duplicate a type of the error */
    code: Scalars['String'];
};

/** GridEmbedVideoTokenResult represents the result (success/failure) of the Grid token generation operation */
export type GridEmbedVideoTokenResult =
    | GridEmbedVideoToken
    | GridEmbedVideoTokenForbiddenError;

export type HandballOverview = {
    __typename?: 'HandballOverview';
    ballSide: TeamHomeAway;
    currentPeriod: HandballPeriod;
    periods?: Maybe<Array<HandballOverviewPeriod>>;
    teams: HandballOverviewTeams;
    timer: Timer;
};

export type HandballOverviewPenalties = {
    __typename?: 'HandballOverviewPenalties';
    matchTime: Scalars['Int'];
    number: Scalars['Int'];
    status: HandballPenaltyStatus;
};

export type HandballOverviewPeriod = {
    __typename?: 'HandballOverviewPeriod';
    away: HandballOverviewPeriodTeam;
    home: HandballOverviewPeriodTeam;
    period: HandballPeriod;
};

export type HandballOverviewPeriodTeam = {
    __typename?: 'HandballOverviewPeriodTeam';
    penalties?: Maybe<Array<HandballOverviewPenalties>>;
    redCards: Scalars['Int'];
    score: Scalars['Int'];
    yellowCards: Scalars['Int'];
};

export type HandballOverviewTeamTotal = {
    __typename?: 'HandballOverviewTeamTotal';
    redCards: Scalars['Int'];
    score: Scalars['Int'];
    yellowCards: Scalars['Int'];
};

export type HandballOverviewTeams = {
    __typename?: 'HandballOverviewTeams';
    away: HandballOverviewTeamTotal;
    home: HandballOverviewTeamTotal;
};

export enum HandballPenaltyStatus {
    Awarded = 'AWARDED',
    Canceled = 'CANCELED',
    Ended = 'ENDED',
    Missed = 'MISSED',
    Unknown = 'UNKNOWN',
}

export enum HandballPeriod {
    FirstExtra = 'FIRST_EXTRA',
    FirstHalf = 'FIRST_HALF',
    Penalties = 'PENALTIES',
    SecondExtra = 'SECOND_EXTRA',
    SecondHalf = 'SECOND_HALF',
    Unknown = 'UNKNOWN',
}

export type HearthstoneOverview = {
    __typename?: 'HearthstoneOverview';
    bestOf: Scalars['Int'];
    currentMap: Scalars['Int'];
    maps?: Maybe<Array<HearthstoneOverviewMap>>;
    teams: HearthstoneOverviewTeams;
};

export type HearthstoneOverviewMap = {
    __typename?: 'HearthstoneOverviewMap';
    number: Scalars['Int'];
    winner: TeamHomeAway;
};

export type HearthstoneOverviewTeam = {
    __typename?: 'HearthstoneOverviewTeam';
    score: Scalars['Int'];
};

export type HearthstoneOverviewTeams = {
    __typename?: 'HearthstoneOverviewTeams';
    away: HearthstoneOverviewTeam;
    home: HearthstoneOverviewTeam;
};

export type IdChecksum = {
    checksum?: InputMaybe<Scalars['String']>;
    id: Scalars['String'];
};

export type IceHockeyOverview = {
    __typename?: 'IceHockeyOverview';
    currentPeriod: Scalars['Int'];
    isOvertime: Scalars['Boolean'];
    isShootout: Scalars['Boolean'];
    pause: Scalars['Boolean'];
    periods?: Maybe<Array<IceHockeyOverviewPeriod>>;
    teams: IceHockeyOverviewTeams;
    timer: Timer;
};

export type IceHockeyOverviewBulletThrow = {
    __typename?: 'IceHockeyOverviewBulletThrow';
    active: Scalars['Boolean'];
    canceled: Scalars['Boolean'];
    number: Scalars['Int'];
    scored: Scalars['Boolean'];
};

export type IceHockeyOverviewPeriod = {
    __typename?: 'IceHockeyOverviewPeriod';
    away: IceHockeyOverviewPeriodTeam;
    home: IceHockeyOverviewPeriodTeam;
    number: Scalars['Int'];
    periodType: IceHockeyPeriodType;
};

export type IceHockeyOverviewPeriodTeam = {
    __typename?: 'IceHockeyOverviewPeriodTeam';
    bulletThrows?: Maybe<Array<IceHockeyOverviewBulletThrow>>;
    score: Scalars['Int'];
};

export type IceHockeyOverviewTeam = {
    __typename?: 'IceHockeyOverviewTeam';
    bulletThrows: Scalars['Int'];
    score: Scalars['Int'];
};

export type IceHockeyOverviewTeams = {
    __typename?: 'IceHockeyOverviewTeams';
    away: IceHockeyOverviewTeam;
    home: IceHockeyOverviewTeam;
};

export enum IceHockeyPeriodType {
    Bullets = 'BULLETS',
    Overtime = 'OVERTIME',
    Regular = 'REGULAR',
}

export type IndoorSoccerOverview = {
    __typename?: 'IndoorSoccerOverview';
    currentPeriod: IndoorSoccerPeriodType;
    periods?: Maybe<Array<IndoorSoccerOverviewPeriod>>;
    teams: IndoorSoccerOverviewTeams;
    timeFormat: Scalars['Int'];
    timer: Timer;
};

export type IndoorSoccerOverviewCard = {
    __typename?: 'IndoorSoccerOverviewCard';
    cancelled: Scalars['Boolean'];
    cardType: CardType;
    number: Scalars['Int'];
    team: TeamHomeAway;
};

export type IndoorSoccerOverviewGoal = {
    __typename?: 'IndoorSoccerOverviewGoal';
    cancelled: Scalars['Boolean'];
    number: Scalars['Int'];
    team: TeamHomeAway;
};

export type IndoorSoccerOverviewPeriod = {
    __typename?: 'IndoorSoccerOverviewPeriod';
    cards?: Maybe<Array<IndoorSoccerOverviewCard>>;
    goals?: Maybe<Array<IndoorSoccerOverviewGoal>>;
    number: Scalars['Int'];
    teams: IndoorSoccerOverviewTeams;
    type: IndoorSoccerPeriodType;
};

export type IndoorSoccerOverviewTeamTotal = {
    __typename?: 'IndoorSoccerOverviewTeamTotal';
    redCards: Scalars['Int'];
    score: Scalars['Int'];
    yellowCards: Scalars['Int'];
    yellowRedCards: Scalars['Int'];
};

export type IndoorSoccerOverviewTeams = {
    __typename?: 'IndoorSoccerOverviewTeams';
    away: IndoorSoccerOverviewTeamTotal;
    home: IndoorSoccerOverviewTeamTotal;
};

export enum IndoorSoccerPeriodType {
    FirstHalf = 'FIRST_HALF',
    SecondHalf = 'SECOND_HALF',
    Unknown = 'UNKNOWN',
}

export type InputPair = {
    name: Scalars['String'];
    value: Scalars['String'];
};

export type Insurance = {
    __typename?: 'Insurance';
    /** conditions under which insurance can be applied to a bet */
    conditions: Array<BetCondition>;
    currencyCode: Scalars['String'];
    expiresAt: Scalars['String'];
    id: Scalars['String'];
    refundRules: InsuranceRefundRules;
    usedAt: Scalars['String'];
    version: Scalars['String'];
};

export enum InsuranceNotApplicableReason {
    Expired = 'EXPIRED',
    InsuranceMustBeWithoutFreebet = 'INSURANCE_MUST_BE_WITHOUT_FREEBET',
    NotApplicableForCurrentBet = 'NOT_APPLICABLE_FOR_CURRENT_BET',
    NotFound = 'NOT_FOUND',
    Unknown = 'UNKNOWN',
}

/** The requested insurance is not applicable */
export type InsuranceNotApplicableRestriction = {
    __typename?: 'InsuranceNotApplicableRestriction';
    reason: InsuranceNotApplicableReason;
    type: RestrictionType;
};

/** Type of insurance: a fixed amount or a percent of the bet amount. Value of the field = `amount` or `percent` respectively */
export type InsuranceRefundRules = {
    __typename?: 'InsuranceRefundRules';
    amount: RefundAmountRule;
    strategies?: Maybe<Array<RefundStrategyRule>>;
};

export enum InsuranceStatusInRestriction {
    Canceled = 'CANCELED',
    Unknown = 'UNKNOWN',
    Used = 'USED',
}

/** The action for the insurance in this status is not allowed */
export type InsuranceStatusRestriction = {
    __typename?: 'InsuranceStatusRestriction';
    status: InsuranceStatusInRestriction;
    type: RestrictionType;
};

/** The action for the insurance with given value not allowed, maybe the insurance was changed after place bet */
export type InsuranceValueRestriction = {
    __typename?: 'InsuranceValueRestriction';
    actualVersion: Scalars['String'];
    betCurrency: Scalars['String'];
    givenVersion: Scalars['String'];
    insuranceCurrency: Scalars['String'];
    type: RestrictionType;
};

export type InsurancesFilter = {
    onlyActual?: InputMaybe<Scalars['Boolean']>;
};

/** System internal error */
export type InternalErrorRestriction = {
    __typename?: 'InternalErrorRestriction';
    code: Scalars['String'];
    type: RestrictionType;
};

export enum KabaddiFormat {
    Format_4X4 = 'FORMAT_4X4',
    Format_5X5 = 'FORMAT_5X5',
    Format_6X6 = 'FORMAT_6X6',
    Format_7X7 = 'FORMAT_7X7',
    Unknown = 'UNKNOWN',
}

export type KabaddiOverview = {
    __typename?: 'KabaddiOverview';
    currentHalf: Scalars['Int'];
    currentRaider: TeamHomeAway;
    format: KabaddiFormat;
    halves?: Maybe<Array<KabaddiOverviewHalve>>;
    teams: KabaddiOverviewTeams;
    timer: Timer;
};

export type KabaddiOverviewHalve = {
    __typename?: 'KabaddiOverviewHalve';
    away: KabaddiOverviewTeam;
    home: KabaddiOverviewTeam;
    number: Scalars['Int'];
};

export type KabaddiOverviewTeam = {
    __typename?: 'KabaddiOverviewTeam';
    players: Scalars['Int'];
    points: Scalars['Int'];
};

export type KabaddiOverviewTeams = {
    __typename?: 'KabaddiOverviewTeams';
    away: KabaddiOverviewTeam;
    home: KabaddiOverviewTeam;
};

export type KingOfGloryMapOverviewTeam = {
    __typename?: 'KingOfGloryMapOverviewTeam';
    kills: Scalars['Int'];
};

export type KingOfGloryMapOverviewTeams = {
    __typename?: 'KingOfGloryMapOverviewTeams';
    away: KingOfGloryMapOverviewTeam;
    home: KingOfGloryMapOverviewTeam;
};

export type KingOfGloryOverview = {
    __typename?: 'KingOfGloryOverview';
    bestOf: Scalars['Int'];
    currentMap: Scalars['Int'];
    maps?: Maybe<Array<KingOfGloryOverviewMap>>;
    teams: KingOfGloryOverviewTeams;
    timer: Timer;
};

export type KingOfGloryOverviewMap = {
    __typename?: 'KingOfGloryOverviewMap';
    awayKills: Scalars['Int'];
    firstBlood: WinnerHomeAway;
    homeKills: Scalars['Int'];
    number: Scalars['Int'];
    winner: TeamHomeAway;
};

export type KingOfGloryOverviewTeam = {
    __typename?: 'KingOfGloryOverviewTeam';
    score: Scalars['Int'];
};

export type KingOfGloryOverviewTeams = {
    __typename?: 'KingOfGloryOverviewTeams';
    away: KingOfGloryOverviewTeam;
    home: KingOfGloryOverviewTeam;
};

export type LolMapOverviewTeam = {
    __typename?: 'LOLMapOverviewTeam';
    kills: Scalars['Int'];
};

export type LolMapOverviewTeams = {
    __typename?: 'LOLMapOverviewTeams';
    away: LolMapOverviewTeam;
    home: LolMapOverviewTeam;
};

export type LolOverview = {
    __typename?: 'LOLOverview';
    bestOf: Scalars['Int'];
    currentGoldLead: Scalars['Int'];
    currentMap: Scalars['Int'];
    maps?: Maybe<Array<LolOverviewMap>>;
    pause: Scalars['Boolean'];
    teams: LolOverviewTeams;
    timer: Timer;
};

export type LolOverviewMap = {
    __typename?: 'LOLOverviewMap';
    goldLead: Scalars['Int'];
    number: Scalars['Int'];
    teams: LolMapOverviewTeams;
};

export type LolOverviewTeam = {
    __typename?: 'LOLOverviewTeam';
    score: Scalars['Int'];
};

export type LolOverviewTeams = {
    __typename?: 'LOLOverviewTeams';
    away: LolOverviewTeam;
    home: LolOverviewTeam;
};

export type LolWildriftMapOverviewTeam = {
    __typename?: 'LOLWildriftMapOverviewTeam';
    kills: Scalars['Int'];
};

export type LolWildriftMapOverviewTeams = {
    __typename?: 'LOLWildriftMapOverviewTeams';
    away: LolWildriftMapOverviewTeam;
    home: LolWildriftMapOverviewTeam;
};

export type LolWildriftOverview = {
    __typename?: 'LOLWildriftOverview';
    bestOf: Scalars['Int'];
    currentMap: Scalars['Int'];
    maps?: Maybe<Array<LolWildriftOverviewMap>>;
    teams: LolWildriftOverviewTeams;
    timer: Timer;
};

export type LolWildriftOverviewMap = {
    __typename?: 'LOLWildriftOverviewMap';
    awayKills: Scalars['Int'];
    firstBlood: WinnerHomeAway;
    homeKills: Scalars['Int'];
    number: Scalars['Int'];
    winner: TeamHomeAway;
};

export type LolWildriftOverviewTeam = {
    __typename?: 'LOLWildriftOverviewTeam';
    score: Scalars['Int'];
};

export type LolWildriftOverviewTeams = {
    __typename?: 'LOLWildriftOverviewTeams';
    away: LolWildriftOverviewTeam;
    home: LolWildriftOverviewTeam;
};

export enum LogLevel {
    Debug = 'DEBUG',
    Error = 'ERROR',
    Info = 'INFO',
    Warning = 'WARNING',
}

export enum MmaFormat {
    Five = 'FIVE',
    Three = 'THREE',
    Unknown = 'UNKNOWN',
}

export type MmaOverview = {
    __typename?: 'MMAOverview';
    format: MmaFormat;
    result: MmaResult;
    round: Scalars['Int'];
    timer: Timer;
};

export enum MmaReason {
    D = 'D',
    Dq = 'DQ',
    Drw = 'DRW',
    Ko = 'KO',
    Sub = 'SUB',
    Td = 'TD',
    Tko = 'TKO',
    U = 'U',
}

export type MmaResult = {
    __typename?: 'MMAResult';
    reason: MmaReason;
    roundNumber?: Maybe<Scalars['Int']>;
    time?: Maybe<Scalars['Int']>;
    winner: MmaWinner;
};

export enum MmaWinner {
    Away = 'AWAY',
    Draw = 'DRAW',
    Home = 'HOME',
    Unknown = 'UNKNOWN',
}

export type Market = {
    __typename?: 'Market';
    id: Scalars['String'];
    name: Scalars['String'];
    odds: Array<Odd>;
    path: Scalars['String'];
    priority: Scalars['Int'];
    specifiers: Array<Pair>;
    status: MarketStatus;
    tags: Array<Scalars['String']>;
    typeId: Scalars['Int'];
};

/** The market is incorrect */
export type MarketDefectiveRestriction = {
    __typename?: 'MarketDefectiveRestriction';
    marketId: Scalars['String'];
    sportEventId: Scalars['String'];
    type: RestrictionType;
};

/** The market does not exist */
export type MarketExistenceRestriction = {
    __typename?: 'MarketExistenceRestriction';
    marketId: Scalars['String'];
    sportEventId: Scalars['String'];
    type: RestrictionType;
};

export enum MarketStatus {
    Active = 'ACTIVE',
    Cancelled = 'CANCELLED',
    Deactivated = 'DEACTIVATED',
    Resulted = 'RESULTED',
    Suspended = 'SUSPENDED',
}

/** Occurs if the market has a forbidden status. Allowed market`s status - `ACTIVE` */
export type MarketStatusRestriction = {
    __typename?: 'MarketStatusRestriction';
    marketId: Scalars['String'];
    sportEventId: Scalars['String'];
    status: MarketStatus;
    type: RestrictionType;
};

export type MarketTemplate = {
    __typename?: 'MarketTemplate';
    id: Scalars['Int'];
    outcomes: Array<Pair>;
    template: Scalars['String'];
};

export enum MarketType {
    Match = 'MATCH',
    Outright = 'OUTRIGHT',
}

export type MarketsTabLocalization = {
    __typename?: 'MarketsTabLocalization';
    locale: Scalars['String'];
    name: Scalars['String'];
};

/** Describes a configuration by which markets should be grouped, i.e. tabbed. */
export type MarketsTabsConfig = {
    __typename?: 'MarketsTabsConfig';
    /**
     * Declares a specifier by which tabs will be grouped. For example, there is a market for Dota2 with a mapnr specifier,
     * which has three values (1, 2, 3) representing specifiers Map 1, Map 2, and Map 3, respectively. By default, a
     * single tab should be generated with markets for a tab id. This parameter, equal to mapnr, means that a unique tab
     * should be generated for each specifier value. In this example, the tabs are [Map 1, Map 2, Map 3].
     */
    autoGeneratedBy: Scalars['String'];
    /** JSON filter for tabs to be generated, that is described in JsonLogic format, see https://jsonlogic.com/ */
    filter: Scalars['String'];
    /**
     * Additional filter for markets based on the active tab marker. For example, there is a mapnr specifier, which has three
     * values (1, 2, 3) representing specifiers Map 1, Map 2, and Map 3, respectively. We want to render the `Active Map`
     * tab, where, based on the current state of the match, markets for Map 1, then for Map 2, and then for Map 3 should
     * appear throughout the match life cycle. If true, the markets should be filtered based on the current state of the
     * event. It is mutually exclusive with autoGeneratedBy: if this property is true, then autoGeneratedBy must be empty.
     */
    filterCurrentPart: Scalars['Boolean'];
    id: Scalars['String'];
    localizations: Array<MarketsTabLocalization>;
};

export type MarketsTabsList = {
    __typename?: 'MarketsTabsList';
    tabs: Array<CompiledMarketsTab>;
};

export type MatchGame = {
    __typename?: 'MatchGame';
    events: Array<GameEvent>;
    id: Scalars['String'];
    metadata: GameMeta;
    streams: Array<GameStream>;
};

export type MatchLog = {
    __typename?: 'MatchLog';
    games: Array<MatchGame>;
    id: Scalars['String'];
    metadata?: Maybe<Array<Pair>>;
    sportId: Scalars['String'];
    startTime: Scalars['String'];
    streams: Array<MatchStream>;
    teamIds: Array<Scalars['String']>;
    tournamentId: Scalars['String'];
    updatedAt: Scalars['String'];
    version: Scalars['String'];
};

export type MatchStream = {
    __typename?: 'MatchStream';
    id: Scalars['String'];
    locale: Scalars['String'];
    type: Scalars['String'];
    url: Scalars['String'];
};

export type MaxBet = {
    __typename?: 'MaxBet';
    /** @deprecated Use `MaxBetRestriction` instead */
    maxBet?: Maybe<Scalars['Float']>;
};

export type MaxBetOddType = {
    marketId: Scalars['String'];
    matchId: Scalars['String'];
    oddId: Scalars['String'];
    oddRatio: Scalars['Float'];
    providerId?: InputMaybe<Scalars['String']>;
};

/** Occurs when the bet`s stake has exceeded the limit */
export type MaxBetRestriction = {
    __typename?: 'MaxBetRestriction';
    maxBet: Scalars['Float'];
    type: RestrictionType;
};

/** The maximum number of outcomes of a sport event in which a player can lose but still receive insurance payout. For example, a player loses in 4 outcomes, maxOddsCountLose = 3, the insurance amount will not be paid. If a player loses in 2 outcomes, the insurance payout will be maid */
export type MaxOddsCountLoseStrategyRule = RefundStrategyRule & {
    __typename?: 'MaxOddsCountLoseStrategyRule';
    maxOddsCountLose: Scalars['Int'];
    type: RefundStrategyRuleType;
};

export type MetaTeam = {
    __typename?: 'MetaTeam';
    lastTimeParticipation: Scalars['String'];
    team: Team;
};

export type MinBet = {
    __typename?: 'MinBet';
    minBet: Scalars['String'];
};

/** Occurs when the bet`s stake has exceeded the limit */
export type MinBetRestriction = {
    __typename?: 'MinBetRestriction';
    minBet: Scalars['Float'];
    type: RestrictionType;
};

export enum MortalKombatCharacter {
    Baraka = 'BARAKA',
    CassieCage = 'CASSIE_CAGE',
    Cetrion = 'CETRION',
    Dvorah = 'DVORAH',
    ErronBlack = 'ERRON_BLACK',
    Frost = 'FROST',
    Fujin = 'FUJIN',
    Geras = 'GERAS',
    JacquiBriggs = 'JACQUI_BRIGGS',
    Jade = 'JADE',
    JaxBriggs = 'JAX_BRIGGS',
    JohnnyCage = 'JOHNNY_CAGE',
    Joker = 'JOKER',
    Kabal = 'KABAL',
    Kano = 'KANO',
    Kitana = 'KITANA',
    Kollector = 'KOLLECTOR',
    KotalKahn = 'KOTAL_KAHN',
    KungLao = 'KUNG_LAO',
    LiuKang = 'LIU_KANG',
    Nightwolf = 'NIGHTWOLF',
    NoobSaibot = 'NOOB_SAIBOT',
    Raiden = 'RAIDEN',
    Robocop = 'ROBOCOP',
    Scorpion = 'SCORPION',
    ShangTsung = 'SHANG_TSUNG',
    ShaoKahn = 'SHAO_KAHN',
    Sheeva = 'SHEEVA',
    Sindel = 'SINDEL',
    Skarlet = 'SKARLET',
    SonyaBlade = 'SONYA_BLADE',
    Spawn = 'SPAWN',
    SubZero = 'SUB_ZERO',
    TerminatorT_800 = 'TERMINATOR_T_800',
    Unknown = 'UNKNOWN',
}

export type MortalKombatFightOverview = {
    __typename?: 'MortalKombatFightOverview';
    score: Scalars['Int'];
};

export enum MortalKombatMap {
    BlackDragonFightClub = 'BLACK_DRAGON_FIGHT_CLUB',
    BlackMarketAlley = 'BLACK_MARKET_ALLEY',
    CyberLinKueiAssembly = 'CYBER_LIN_KUEI_ASSEMBLY',
    GorosLair = 'GOROS_LAIR',
    KharonsShip = 'KHARONS_SHIP',
    KoliseumBeastPen = 'KOLISEUM_BEAST_PEN',
    KotalKoliseum = 'KOTAL_KOLISEUM',
    KronikasKeep = 'KRONIKAS_KEEP',
    LostHiveOfTheKytinn = 'LOST_HIVE_OF_THE_KYTINN',
    SeaOfBlood = 'SEA_OF_BLOOD',
    ShangTsungsIslandRuins = 'SHANG_TSUNGS_ISLAND_RUINS',
    ShaolinTrapDungeon = 'SHAOLIN_TRAP_DUNGEON',
    ShinnoksBoneTemple = 'SHINNOKS_BONE_TEMPLE',
    ShiraiRyuFireGarden = 'SHIRAI_RYU_FIRE_GARDEN',
    SpecialForcesDesertCommand = 'SPECIAL_FORCES_DESERT_COMMAND',
    TankGarageBunker = 'TANK_GARAGE_BUNKER',
    TarkatanKamp = 'TARKATAN_KAMP',
    Tournament = 'TOURNAMENT',
    Unknown = 'UNKNOWN',
    WuShiDragonGrotto = 'WU_SHI_DRAGON_GROTTO',
}

export type MortalKombatOverview = {
    __typename?: 'MortalKombatOverview';
    bestOf: Scalars['Int'];
    bestOfRounds: Scalars['Int'];
    currentCharacterAway: MortalKombatCharacter;
    currentCharacterHome: MortalKombatCharacter;
    currentFight: Scalars['Int'];
    currentMap: MortalKombatMap;
    currentRound: Scalars['Int'];
    fights?: Maybe<Array<MortalKombatOverviewFight>>;
    teams: MortalKombatOverviewTeams;
    timer: Timer;
};

export type MortalKombatOverviewFight = {
    __typename?: 'MortalKombatOverviewFight';
    away: MortalKombatFightOverview;
    home: MortalKombatFightOverview;
    map: MortalKombatMap;
    number: Scalars['Int'];
    winReason: MortalKombatWinReason;
    winner: TeamHomeAway;
};

export type MortalKombatOverviewTeam = {
    __typename?: 'MortalKombatOverviewTeam';
    characterInfo: CharacterInfo;
    score: Scalars['Int'];
};

export type MortalKombatOverviewTeams = {
    __typename?: 'MortalKombatOverviewTeams';
    away: MortalKombatOverviewTeam;
    home: MortalKombatOverviewTeam;
};

export enum MortalKombatWinReason {
    Animality = 'ANIMALITY',
    Babality = 'BABALITY',
    Brutality = 'BRUTALITY',
    Common = 'COMMON',
    Fatality = 'FATALITY',
    Friendship = 'FRIENDSHIP',
    Unknown = 'UNKNOWN',
}

export type Mutation = {
    __typename?: 'Mutation';
    /** marks sport events as a favorite, returns list of favorite sport events */
    addFavoriteSportEvents: Array<Scalars['String']>;
    /** tracks the current state of the betslip, can be useful for tracking unfinished bettor's stakes */
    changeGamblerBettingSlip: Scalars['Boolean'];
    /** sets or updates the bettor's UI preferences identified by the `key` */
    changeGamblerSetting: Gambler;
    /** sets or updates the multiple bettor's UI preferences identified by the `keys` */
    changeGamblerSettings: Gambler;
    /** deletes sport events from the list of favorites, returns a list of favorite sport events */
    deleteFavoriteSportEvents: Array<Scalars['String']>;
    /** deletes the bettor's UI preferences */
    deleteGamblerSetting: Gambler;
    generateEmbeddedVideoLink: EmbeddedVideoLinkResult;
    /**
     * generates token for the Grid embedded video player for gamblers that have at least one bet
     * @deprecated Use generateGridEmbedVideoTokenV2
     */
    generateGridEmbedVideoToken: GridEmbedVideoToken;
    generateGridEmbedVideoTokenV2: GridEmbedVideoTokenResult;
    /**
     * Use this mutation when a player places a bet.
     * **usage pattern:** a player has chosen valid selection
     * (the get_restrictions request returns only max_bet restrictions) and clicked the Place bet button
     */
    placeBet: Bet;
    /**
     * Use this mutation to notify the platform about a cash out on the bet
     * **usage pattern:** the player has seen the conditions of the bet's cash out that were calculated by the
     * cash out order calculate query and clicked the button to place a cash out
     */
    placeCashOutOrder: CashOutOrder;
    predictTournamentBracket: TournamentBracketPrediction;
};

export type MutationAddFavoriteSportEventsArgs = {
    ids: Array<Scalars['String']>;
};

export type MutationChangeGamblerBettingSlipArgs = {
    betType: BetType;
    oddPaths: Array<OddPath>;
    stake?: InputMaybe<Scalars['String']>;
    systemSize?: InputMaybe<Array<Scalars['Int']>>;
};

export type MutationChangeGamblerSettingArgs = {
    key: Scalars['String'];
    value: Scalars['String'];
};

export type MutationChangeGamblerSettingsArgs = {
    keys: Array<GamblerSetting>;
};

export type MutationDeleteFavoriteSportEventsArgs = {
    ids: Array<Scalars['String']>;
};

export type MutationDeleteGamblerSettingArgs = {
    key: Scalars['String'];
};

export type MutationGenerateEmbeddedVideoLinkArgs = {
    sportEventId: Scalars['String'];
};

export type MutationGenerateGridEmbedVideoTokenArgs = {
    sportEventId: Scalars['String'];
};

export type MutationGenerateGridEmbedVideoTokenV2Args = {
    sportEventId: Scalars['String'];
};

export type MutationPlaceBetArgs = {
    betBuilderOdds?: InputMaybe<Array<PlaceBetBuilderOdd>>;
    freebetId?: InputMaybe<Scalars['String']>;
    idempotencyId?: InputMaybe<Scalars['String']>;
    insurance?: InputMaybe<PlaceBetInsurance>;
    meta?: InputMaybe<PlaceBetMeta>;
    oddAcceptStrategy?: InputMaybe<OddAcceptStrategy>;
    odds: Array<PlaceBetOdd>;
    selections?: InputMaybe<Array<InputMaybe<Selection>>>;
    stake: Scalars['Float'];
    stakeMode?: InputMaybe<StakeMode>;
    systemSize: Array<Scalars['Int']>;
    type: BetType;
};

export type MutationPlaceCashOutOrderArgs = {
    amount: Scalars['Float'];
    betId: Scalars['String'];
    refundAmount: Scalars['Float'];
    selections: Array<PlaceCashOutSelection>;
};

export type MutationPredictTournamentBracketArgs = {
    tournamentPrediction: TournamentBracketPredictionInput;
};

export type NotEnoughBalanceRestriction = {
    __typename?: 'NotEnoughBalanceRestriction';
    actualBalance: Scalars['String'];
    type: RestrictionType;
};

export type Odd = {
    __typename?: 'Odd';
    /** @deprecated Use `competitorIds` instead */
    competitorId: Scalars['String'];
    competitorIds: Array<Scalars['String']>;
    id: Scalars['String'];
    isActive: Scalars['Boolean'];
    name: Scalars['String'];
    path: Scalars['String'];
    status: OddStatus;
    value: Scalars['String'];
};

export enum OddAcceptStrategy {
    /**
     * all odd coefficients are allowed if the odd changes during acceptance process
     * coefficient in the bet is updated if it has changed
     */
    AlwaysAllowed = 'ALWAYS_ALLOWED',
    /**
     * default strategy, only slight odd coefficient changes are allowed if the odd changes during acceptance process,
     * coefficient in the bet does not change
     */
    DeviationAllowed = 'DEVIATION_ALLOWED',
    /**
     * greater odd coefficients are allowed if the odd changes during acceptance process,
     * coefficient in the bet is updated if it has changed
     */
    GreaterAllowed = 'GREATER_ALLOWED',
}

export type OddDynamicByInterval = {
    __typename?: 'OddDynamicByInterval';
    dynamics: Array<OddDynamicValue>;
    marketId: Scalars['String'];
    oddId: Scalars['String'];
    sportEventId: Scalars['String'];
};

export type OddDynamicValue = {
    __typename?: 'OddDynamicValue';
    timestamp: Scalars['String'];
    value: Scalars['Float'];
};

/** The odd in the sport event does not exist */
export type OddExistenceRestriction = {
    __typename?: 'OddExistenceRestriction';
    marketId: Scalars['String'];
    oddId: Scalars['String'];
    sportEventId: Scalars['String'];
    type: RestrictionType;
};

export type OddPath = {
    /** odd path: sport event ID + ~ + market ID + ~ + odd ID, for example 5:21ccdb66-a99f-4543-b8f8-cfc0317ac0d7~300m1t27_5~2 */
    path: Scalars['String'];
    /** bet amount for the SINGLE bet type, in all other cases — an empty string */
    stake: Scalars['String'];
};

export enum OddStatus {
    Cancelled = 'CANCELLED',
    HalfLoss = 'HALF_LOSS',
    HalfWin = 'HALF_WIN',
    Loss = 'LOSS',
    NotResulted = 'NOT_RESULTED',
    Refunded = 'REFUNDED',
    Win = 'WIN',
}

/** Occurs if the odd has a forbidden status. Allowed odd`s status - `NOT_RESULTED` */
export type OddStatusRestriction = {
    __typename?: 'OddStatusRestriction';
    marketId: Scalars['String'];
    oddId: Scalars['String'];
    sportEventId: Scalars['String'];
    status: OddStatus;
    type: RestrictionType;
};

export type Organization = {
    __typename?: 'Organization';
    id: Scalars['String'];
    logo: Scalars['String'];
    name: Scalars['String'];
    sportIds: Array<Scalars['String']>;
};

export type Overview =
    | AmericanFootballOverview
    | BadmintonOverview
    | BaseballOverview
    | BasketballOverview
    | BattlegroundsMultiCompetitiveOverview
    | BattlegroundsOverview
    | BeachVolleyballOverview
    | BrawlStarsOverview
    | CsgoOverview
    | CallOfDutyWarzoneOverview
    | CricketOverview
    | Dota2Overview
    | EBasketballOverview
    | EFootballOverview
    | EIceHockeyOverview
    | EStreetballOverview
    | ETennisOverview
    | EufcOverview
    | EVolleyballOverview
    | FootballOverview
    | FortniteOverview
    | FutsalOverview
    | HandballOverview
    | HearthstoneOverview
    | IceHockeyOverview
    | IndoorSoccerOverview
    | KabaddiOverview
    | KingOfGloryOverview
    | LolOverview
    | LolWildriftOverview
    | MmaOverview
    | MortalKombatOverview
    | OverwatchOverview
    | RainbowSixOverview
    | RocketLeagueOverview
    | StarCraft1Overview
    | StarCraftOverview
    | TableTennisOverview
    | TennisOverview
    | ValorantOverview
    | VolleyballOverview;

export type OverwatchOverview = {
    __typename?: 'OverwatchOverview';
    bestOf: Scalars['Int'];
    currentMap: Scalars['Int'];
    maps?: Maybe<Array<OverwatchOverviewMap>>;
    teams: OverwatchOverviewTeams;
};

export type OverwatchOverviewMap = {
    __typename?: 'OverwatchOverviewMap';
    number: Scalars['Int'];
    winner: TeamHomeAway;
};

export type OverwatchOverviewTeam = {
    __typename?: 'OverwatchOverviewTeam';
    score: Scalars['Int'];
};

export type OverwatchOverviewTeams = {
    __typename?: 'OverwatchOverviewTeams';
    away: OverwatchOverviewTeam;
    home: OverwatchOverviewTeam;
};

/** A pair is a generic structure for representing data, [] pair is usually used to replace a dictionary of string key-value pairs (metadata, parameters, etc.) */
export type Pair = {
    __typename?: 'Pair';
    name: Scalars['String'];
    value: Scalars['String'];
};

export enum PenaltyStatus {
    Awarded = 'AWARDED',
    Canceled = 'CANCELED',
    Ended = 'ENDED',
    Missed = 'MISSED',
    Unknown = 'UNKNOWN',
}

export type PlaceBetBuilderOdd = {
    matchId: Scalars['String'];
    odds: Array<PlaceBetOdd>;
    ratio: Scalars['Float'];
};

export type PlaceBetInsurance = {
    id: Scalars['String'];
    version: Scalars['String'];
};

export type PlaceBetMeta = {
    featureFlags?: InputMaybe<Array<InputPair>>;
};

export type PlaceBetOdd = {
    marketId: Scalars['String'];
    matchId: Scalars['String'];
    oddId: Scalars['String'];
    /** coefficient */
    ratio: Scalars['Float'];
};

export type PlaceCashOutSelection = {
    marketId: Scalars['String'];
    oddId: Scalars['String'];
    sportEventId: Scalars['String'];
    /** odd value in market at the moment of cash out */
    value: Scalars['Float'];
};

export type Player = {
    __typename?: 'Player';
    countryCode: Scalars['String'];
    id: Scalars['String'];
    keywords: Array<Scalars['String']>;
    logo: Scalars['String'];
    metaTeams: Array<MetaTeam>;
    name: Scalars['String'];
    nickname: Scalars['String'];
    slug: Scalars['String'];
    sport: Sport;
    statistics: Array<PlayerStatistic>;
};

/** The player is blocked */
export type PlayerLimitRestriction = {
    __typename?: 'PlayerLimitRestriction';
    reason?: Maybe<Scalars['String']>;
    type: RestrictionType;
};

export type PlayerStatistic =
    | PlayerStatisticCsgo
    | PlayerStatisticDota2
    | PlayerStatisticHots
    | PlayerStatisticHearthstone
    | PlayerStatisticLol
    | PlayerStatisticOverwatch
    | PlayerStatisticPubg
    | PlayerStatisticTotal;

export type PlayerStatisticCsgo = {
    __typename?: 'PlayerStatisticCSGO';
    role: Scalars['Int'];
};

export type PlayerStatisticDota2 = {
    __typename?: 'PlayerStatisticDota2';
    exp: Scalars['Int'];
    fights: Scalars['Int'];
    form: Scalars['Int'];
    gold: Scalars['Int'];
    lane: Scalars['Int'];
    mvp: Scalars['Int'];
    role: Scalars['Int'];
    stat: Scalars['Int'];
    strength: Scalars['Int'];
};

export type PlayerStatisticHots = {
    __typename?: 'PlayerStatisticHOTS';
    role: Scalars['Int'];
};

export type PlayerStatisticHearthstone = {
    __typename?: 'PlayerStatisticHearthstone';
    role: Scalars['Int'];
};

export type PlayerStatisticLol = {
    __typename?: 'PlayerStatisticLOL';
    role: Scalars['Int'];
};

export type PlayerStatisticOverwatch = {
    __typename?: 'PlayerStatisticOverwatch';
    role: Scalars['Int'];
};

export type PlayerStatisticPubg = {
    __typename?: 'PlayerStatisticPUBG';
    role: Scalars['Int'];
};

export type PlayerStatisticTotal = {
    __typename?: 'PlayerStatisticTotal';
    money: Scalars['Int'];
    place: Scalars['Int'];
    rate: Scalars['Int'];
};

export enum PredictionStatus {
    InProgress = 'IN_PROGRESS',
    NotStarted = 'NOT_STARTED',
    Settled = 'SETTLED',
}

export type Query = {
    __typename?: 'Query';
    /** gets a list of freebets that can be applied to the bet with the parameters that were passed */
    applicableFreebets: Array<Freebet>;
    /** gets a list of insurances that can be applied to the bet with the parameters that were passed */
    applicableInsurances: Array<Insurance>;
    bannerZones: Array<BannerZone>;
    banners: Array<Banner>;
    /** Returns markets for bet builder with the calculated coefficients based on the current selections for this match */
    betBuilderTab: BetBuilderTab;
    betBuilderTabConfig: BetBuilderTabConfig;
    /** finds bets by filter, response includes the total count */
    betList: BetList;
    /** calculates recommended bet's stake for the player */
    betStakeSuggest: Array<StakeSuggest>;
    betTips?: Maybe<BetTips>;
    /** finds bets by filter */
    bets: Array<Bet>;
    /** returns the percentage of the distribution of bets by odds in sports events */
    betsBiddingCalculation: Array<BetsSportEventOddPercentage>;
    /** finds bets by the IDs filter */
    betsByIds: Array<Bet>;
    betslip: Betslip;
    calculateBet: CalculatedBetResult;
    /**
     * use this endpoint to calculate cash out refund amount for the specific bet
     * **usage pattern:** the player has accepted a bet and you want to offer them to cash out prematurely
     */
    calculateCashOut: CalculatedCashOut;
    /**
     * Provides a tree of categories for sport events. Default grouping is sport -> tournament, example below:
     * ├── Football
     * │  ├── Africa Cup of Nations. Qualification
     * │  ├── CAFA Nations Cup 2023
     * │  ├── U21 UEFA European Championship, Qualification
     * ├── Ice Hockey
     * │  ├── World Championship 2023
     * ├  ├── World Championship 2024
     */
    categorizer: Array<CategorizerItem>;
    comboOfTheDay?: Maybe<Array<TrebleOdd>>;
    /** Returns sport event markets group by id. */
    compiledMarketsTab: CompiledMarketsTab;
    /** Returns sport event markets grouped by configuration defined for given sport. */
    compiledMarketsTabs: CompiledMarketsTabs;
    empty: Scalars['Boolean'];
    /**
     * returns a list of favorite sports events (which were marked as favorite by the `addFavoriteSportEvents mutation)`
     * that match the given filters (sport event filters are described in `sportEventsByFilters` query)
     */
    favoriteSportEventsIds?: Maybe<Array<Scalars['String']>>;
    featuredCustomList: FeaturedCustomList;
    featuredZoneConfigs: Array<FeaturedZoneConfigProvider>;
    filterSportEvents: Array<SportEvent>;
    /** finds a list of freebets */
    freebets: Array<Freebet>;
    /** returns the bettor's UI preferences (keep some modals open/closed, favorite markets, show odds statistics, etc.) */
    gambler?: Maybe<Gambler>;
    /** returns the last saved state of the betslip (made by `changeGamblerBettingSlip`) */
    gamblerBettingSlip?: Maybe<BettingSlip>;
    /** returns the last saved state of the betslip (made by `changeGamblerBettingSlip`) and deletes it */
    gamblerBettingSlipAndDelete?: Maybe<BettingSlip>;
    /** checks if the bettor has any favorite sport events (marked as favorite by the `addFavoriteSportEvents` mutation) */
    hasFavoriteSportEvents: Scalars['Boolean'];
    hasMatchLogs?: Maybe<Array<Pair>>;
    /** finds a list of insurances */
    insurances: Array<Insurance>;
    /** @deprecated Use `sportEventLiveOfTheDay` instead */
    liveOfTheDay?: Maybe<SportEventId>;
    marketTemplates: Array<MarketTemplate>;
    matchLog?: Maybe<MatchLog>;
    /** @deprecated Use `sportEventOfTheDay` instead */
    matchOfTheDay?: Maybe<SportEventId>;
    /** @deprecated Use `restrictions` query instead */
    maxBet?: Maybe<MaxBet>;
    /** gets min bet`s stake allowed for current player */
    minBet: MinBet;
    oddDynamicsByInterval: OddDynamicByInterval;
    playerBySlug?: Maybe<Player>;
    players: Array<Player>;
    /**
     * use this query to retrieve restrictions that could arise when you try to place a bet with the same parameters
     * **usage pattern:** it is necessary to make this request when the player has made selections for the bet -
     * if received restrictions have only the max_bet type - the player can place a bet,
     * if received restrictions have other type - the request for placing a bet will be rejected
     * @deprecated Use `calculateBet` query instead
     */
    restrictions: Array<Restriction>;
    /** Fetches sport event by its id. */
    sportEvent: SportEvent;
    /** Fetches sport event by its slug, which is unique for a sport event. */
    sportEventBySlug?: Maybe<SportEvent>;
    /**
     * Allows to fetch/paginate/sort sport events list by given filters. If passed, all filter arguments are linked via
     * the implied logical AND.
     */
    sportEventListByFilters: SportEventList;
    sportEventLiveOfTheDay?: Maybe<SportEvent>;
    sportEventOfTheDay?: Maybe<SportEvent>;
    /** @deprecated Use sportEventListByFilters instead. */
    sportEvents: Array<SportEvent>;
    /**
     * Allows you to fetch sport events list grouped by sport. You can use it for the custom view for sport -> sport event,
     * as sportEventsByFilters would imply N requests, where N is a set length of sport ids.
     */
    sportEventsAggsBySport: Array<SportEventListBySport>;
    /** @deprecated Use sportEventListByFilters instead. */
    sportEventsByFilters: Array<SportEvent>;
    /** Allows to fetch sport events list by ids. */
    sportEventsByIds: Array<SportEvent>;
    /** Returns configuration for grouping markets by sport. See the type for details. */
    sportMarketsTabConfigs: Array<SportMarketsTabsConfig>;
    sports: Array<Sport>;
    /** Allows You to get list of suitable sport event ids for forming express bet for user. */
    suggestedExpresses: Array<SportEvent>;
    teamBySlug?: Maybe<Team>;
    teams: Array<Team>;
    topTournaments: Array<TopTournament>;
    tournamentBracket?: Maybe<TournamentBracketPrediction>;
    tournamentBySlug?: Maybe<Tournament>;
    tournamentTable: TournamentTable;
    tournaments: Array<Tournament>;
    whitelabelSettings?: Maybe<WhiteLabelSettings>;
};

export type QueryApplicableFreebetsArgs = {
    betType: BetType;
    selections: Array<Selection>;
};

export type QueryApplicableInsurancesArgs = {
    betStake: Scalars['String'];
    betType: BetType;
    selections: Array<Selection>;
};

export type QueryBannerZonesArgs = {
    attributes?: InputMaybe<BannerZoneAttributeFilters>;
};

export type QueryBannersArgs = {
    attributes?: InputMaybe<BannerZoneAttributesInput>;
    sportIds?: InputMaybe<Array<Scalars['String']>>;
    zoneId?: InputMaybe<Scalars['String']>;
};

export type QueryBetBuilderTabArgs = {
    selections: Array<Selection>;
    sportEventID: Scalars['String'];
};

export type QueryBetBuilderTabConfigArgs = {
    sportEventID: Scalars['String'];
};

export type QueryBetListArgs = {
    input: BetsInput;
};

export type QueryBetStakeSuggestArgs = {
    algorithm: StakeSuggesterAlgorithm;
    maxStake?: InputMaybe<Scalars['String']>;
    minStake: Scalars['String'];
    suggestsCountLimit?: InputMaybe<Scalars['Int']>;
};

export type QueryBetTipsArgs = {
    sportEventID: Scalars['String'];
};

export type QueryBetsArgs = {
    input: BetsInput;
};

export type QueryBetsBiddingCalculationArgs = {
    sportEventIds: Array<Scalars['String']>;
};

export type QueryBetsByIdsArgs = {
    ids?: InputMaybe<Array<Scalars['String']>>;
};

export type QueryCalculateBetArgs = {
    betBuilderSelections?: InputMaybe<Array<BetBuilderSelection>>;
    betType: BetType;
    selections?: InputMaybe<Array<Selection>>;
    systemSizes: Array<Scalars['Int']>;
};

export type QueryCalculateCashOutArgs = {
    amount: Scalars['Float'];
    betId: Scalars['String'];
    selections: Array<PlaceCashOutSelection>;
};

export type QueryCategorizerArgs = {
    categoryTree?: InputMaybe<Array<CategoryTreeType>>;
    countryCodes?: InputMaybe<Array<Scalars['String']>>;
    dateFrom?: InputMaybe<Scalars['String']>;
    dateTo?: InputMaybe<Scalars['String']>;
    expand?: InputMaybe<CategorizerExpand>;
    hasStreams?: InputMaybe<Scalars['Boolean']>;
    marketStatuses?: InputMaybe<Array<MarketStatus>>;
    matchStatuses?: InputMaybe<Array<SportEventStatus>>;
    providerIds?: InputMaybe<Array<Scalars['Int']>>;
    sportEventTypes?: InputMaybe<Array<SportEventType>>;
    sportIds?: InputMaybe<Array<Scalars['String']>>;
};

export type QueryComboOfTheDayArgs = {
    sportIds?: InputMaybe<Array<Scalars['String']>>;
};

export type QueryCompiledMarketsTabArgs = {
    marketStatuses?: InputMaybe<Array<MarketStatus>>;
    marketTabID: Scalars['String'];
    sportEventID: Scalars['String'];
};

export type QueryCompiledMarketsTabsArgs = {
    marketStatuses?: InputMaybe<Array<MarketStatus>>;
    sportEventID: Scalars['String'];
};

export type QueryFavoriteSportEventsIdsArgs = {
    competitorIds?: InputMaybe<Array<Scalars['String']>>;
    dateFrom?: InputMaybe<Scalars['String']>;
    dateSortAscending?: InputMaybe<Scalars['Boolean']>;
    dateTo?: InputMaybe<Scalars['String']>;
    marketStatuses?: InputMaybe<Array<MarketStatus>>;
    matchStatuses?: InputMaybe<Array<SportEventStatus>>;
    providerIds?: InputMaybe<Array<Scalars['Int']>>;
    searchString?: InputMaybe<Scalars['String']>;
    sportEventTypes?: InputMaybe<Array<SportEventType>>;
    sportIds?: InputMaybe<Array<Scalars['String']>>;
    tournamentIds?: InputMaybe<Array<Scalars['String']>>;
};

export type QueryFeaturedCustomListArgs = {
    slug: Scalars['String'];
};

export type QueryFeaturedZoneConfigsArgs = {
    range?: InputMaybe<DateRange>;
    zoneId: Scalars['String'];
};

export type QueryFilterSportEventsArgs = {
    dateFrom?: InputMaybe<Scalars['String']>;
    dateSortAscending?: InputMaybe<Scalars['Boolean']>;
    dateTo?: InputMaybe<Scalars['String']>;
    limit?: InputMaybe<Scalars['Int']>;
    marketStatuses?: InputMaybe<Array<MarketStatus>>;
    marketTypes?: InputMaybe<Array<Scalars['Int']>>;
    matchStatuses?: InputMaybe<Array<SportEventStatus>>;
    offset?: InputMaybe<Scalars['Int']>;
    providerIds?: InputMaybe<Array<Scalars['Int']>>;
    searchString?: InputMaybe<Scalars['String']>;
    sportEventIds: Array<Scalars['String']>;
    sportEventTypes?: InputMaybe<Array<SportEventType>>;
    sportIds?: InputMaybe<Array<Scalars['String']>>;
    tournamentIds?: InputMaybe<Array<Scalars['String']>>;
};

export type QueryFreebetsArgs = {
    filter?: InputMaybe<FreebetsFilter>;
};

export type QueryHasMatchLogsArgs = {
    sportEventIds: Array<Scalars['String']>;
};

export type QueryInsurancesArgs = {
    filter?: InputMaybe<InsurancesFilter>;
};

export type QueryLiveOfTheDayArgs = {
    sportIds?: InputMaybe<Array<Scalars['String']>>;
};

export type QueryMarketTemplatesArgs = {
    deprecated?: InputMaybe<Scalars['Boolean']>;
    marketTemplateIds?: InputMaybe<Array<Scalars['Int']>>;
    marketType?: InputMaybe<MarketType>;
    sportIds?: InputMaybe<Array<Scalars['String']>>;
};

export type QueryMatchLogArgs = {
    sportEventId: Scalars['String'];
};

export type QueryMatchOfTheDayArgs = {
    sportIds?: InputMaybe<Array<Scalars['String']>>;
};

export type QueryMaxBetArgs = {
    odds: Array<MaxBetOddType>;
};

export type QueryOddDynamicsByIntervalArgs = {
    from?: InputMaybe<Scalars['String']>;
    marketId: Scalars['String'];
    oddId: Scalars['String'];
    pointCount?: InputMaybe<Scalars['Int']>;
    sportEventId: Scalars['String'];
    to?: InputMaybe<Scalars['String']>;
};

export type QueryPlayerBySlugArgs = {
    slug: Scalars['String'];
};

export type QueryPlayersArgs = {
    ids: Array<Scalars['String']>;
};

export type QueryRestrictionsArgs = {
    betBuilderSelections?: InputMaybe<Array<BetBuilderSelection>>;
    betType: BetType;
    selections: Array<Selection>;
    systemSizes: Array<Scalars['Int']>;
};

export type QuerySportEventArgs = {
    eventId: Scalars['String'];
    marketLimit?: InputMaybe<Scalars['Int']>;
    marketStatuses?: InputMaybe<Array<MarketStatus>>;
    marketTypes?: InputMaybe<Array<Scalars['Int']>>;
};

export type QuerySportEventBySlugArgs = {
    marketLimit?: InputMaybe<Scalars['Int']>;
    marketStatuses?: InputMaybe<Array<MarketStatus>>;
    marketTypes?: InputMaybe<Array<Scalars['Int']>>;
    slug: Scalars['String'];
};

export type QuerySportEventListByFiltersArgs = {
    competitorIds?: InputMaybe<Array<Scalars['String']>>;
    countryCodes?: InputMaybe<Array<Scalars['String']>>;
    dateFrom?: InputMaybe<Scalars['String']>;
    dateSortAscending?: InputMaybe<Scalars['Boolean']>;
    dateTo?: InputMaybe<Scalars['String']>;
    favorite?: InputMaybe<Scalars['Boolean']>;
    hasStreams?: InputMaybe<Scalars['Boolean']>;
    limit: Scalars['Int'];
    marketIds?: InputMaybe<Array<Scalars['String']>>;
    marketLimit?: InputMaybe<Scalars['Int']>;
    marketStatuses?: InputMaybe<Array<MarketStatus>>;
    marketTypes?: InputMaybe<Array<Scalars['Int']>>;
    matchStatuses?: InputMaybe<Array<SportEventStatus>>;
    offset: Scalars['Int'];
    order?: InputMaybe<SportEventOrder>;
    providerIds?: InputMaybe<Array<Scalars['Int']>>;
    searchString?: InputMaybe<Scalars['String']>;
    sportEventTypes?: InputMaybe<Array<SportEventType>>;
    sportIds?: InputMaybe<Array<Scalars['String']>>;
    tournamentIds?: InputMaybe<Array<Scalars['String']>>;
};

export type QuerySportEventLiveOfTheDayArgs = {
    sportIds?: InputMaybe<Array<Scalars['String']>>;
};

export type QuerySportEventOfTheDayArgs = {
    sportIds?: InputMaybe<Array<Scalars['String']>>;
};

export type QuerySportEventsArgs = {
    competitorIds?: InputMaybe<Array<Scalars['String']>>;
    countryCodes?: InputMaybe<Array<Scalars['String']>>;
    dateFrom?: InputMaybe<Scalars['String']>;
    dateSortAscending?: InputMaybe<Scalars['Boolean']>;
    dateTo?: InputMaybe<Scalars['String']>;
    favorite?: InputMaybe<Scalars['Boolean']>;
    hasStreams?: InputMaybe<Scalars['Boolean']>;
    limit?: InputMaybe<Scalars['Int']>;
    marketIds?: InputMaybe<Array<Scalars['String']>>;
    marketLimit?: InputMaybe<Scalars['Int']>;
    marketStatuses?: InputMaybe<Array<MarketStatus>>;
    marketTypes?: InputMaybe<Array<Scalars['Int']>>;
    matchStatuses?: InputMaybe<Array<SportEventStatus>>;
    offset?: InputMaybe<Scalars['Int']>;
    order?: InputMaybe<SportEventOrder>;
    providerIds?: InputMaybe<Array<Scalars['Int']>>;
    searchString?: InputMaybe<Scalars['String']>;
    sportEventIds?: InputMaybe<Array<Scalars['String']>>;
    sportEventTypes?: InputMaybe<Array<SportEventType>>;
    sportIds?: InputMaybe<Array<Scalars['String']>>;
    tournamentIds?: InputMaybe<Array<Scalars['String']>>;
};

export type QuerySportEventsAggsBySportArgs = {
    categoryTree?: InputMaybe<Array<CategoryTreeType>>;
    filters: SportEventFiltersInput;
    idsLimit: Scalars['Int'];
    order?: InputMaybe<SportEventOrder>;
    sportsLimit?: InputMaybe<Scalars['Int']>;
};

export type QuerySportEventsByFiltersArgs = {
    competitorIds?: InputMaybe<Array<Scalars['String']>>;
    countryCodes?: InputMaybe<Array<Scalars['String']>>;
    dateFrom?: InputMaybe<Scalars['String']>;
    dateSortAscending?: InputMaybe<Scalars['Boolean']>;
    dateTo?: InputMaybe<Scalars['String']>;
    favorite?: InputMaybe<Scalars['Boolean']>;
    hasStreams?: InputMaybe<Scalars['Boolean']>;
    limit: Scalars['Int'];
    marketIds?: InputMaybe<Array<Scalars['String']>>;
    marketLimit?: InputMaybe<Scalars['Int']>;
    marketStatuses?: InputMaybe<Array<MarketStatus>>;
    marketTypes?: InputMaybe<Array<Scalars['Int']>>;
    matchStatuses?: InputMaybe<Array<SportEventStatus>>;
    offset: Scalars['Int'];
    order?: InputMaybe<SportEventOrder>;
    providerIds?: InputMaybe<Array<Scalars['Int']>>;
    searchString?: InputMaybe<Scalars['String']>;
    sportEventTypes?: InputMaybe<Array<SportEventType>>;
    sportIds?: InputMaybe<Array<Scalars['String']>>;
    tournamentIds?: InputMaybe<Array<Scalars['String']>>;
};

export type QuerySportEventsByIdsArgs = {
    marketLimit?: InputMaybe<Scalars['Int']>;
    marketStatuses?: InputMaybe<Array<MarketStatus>>;
    marketTypes?: InputMaybe<Array<Scalars['Int']>>;
    sportEventsIds: Array<Scalars['String']>;
};

export type QuerySportMarketsTabConfigsArgs = {
    sportIds?: InputMaybe<Array<Scalars['String']>>;
};

export type QuerySportsArgs = {
    sportIds?: InputMaybe<Array<Scalars['String']>>;
};

export type QuerySuggestedExpressesArgs = {
    filters: SuggestedExpressesInput;
};

export type QueryTeamBySlugArgs = {
    slug: Scalars['String'];
};

export type QueryTeamsArgs = {
    ids: Array<Scalars['String']>;
};

export type QueryTopTournamentsArgs = {
    count?: InputMaybe<Scalars['Int']>;
    zoneId: Scalars['String'];
};

export type QueryTournamentBracketArgs = {
    tournamentID: Scalars['String'];
};

export type QueryTournamentBySlugArgs = {
    slug: Scalars['String'];
};

export type QueryTournamentTableArgs = {
    tournamentId: Scalars['String'];
};

export type QueryTournamentsArgs = {
    ids: Array<Scalars['String']>;
};

export type RainbowSixMapOverview = {
    __typename?: 'RainbowSixMapOverview';
    score: Scalars['Int'];
};

export type RainbowSixOverview = {
    __typename?: 'RainbowSixOverview';
    bestOf: Scalars['Int'];
    currentMap: Scalars['Int'];
    currentRound: Scalars['Int'];
    maps?: Maybe<Array<RainbowSixOverviewMap>>;
    teams: RainbowSixOverviewTeams;
};

export type RainbowSixOverviewMap = {
    __typename?: 'RainbowSixOverviewMap';
    away: RainbowSixMapOverview;
    home: RainbowSixMapOverview;
    number: Scalars['Int'];
    winner: TeamHomeAway;
};

export type RainbowSixOverviewTeam = {
    __typename?: 'RainbowSixOverviewTeam';
    currentSide: RainbowSixTeamSide;
    livePlayers: Scalars['Int'];
    score: Scalars['Int'];
};

export type RainbowSixOverviewTeams = {
    __typename?: 'RainbowSixOverviewTeams';
    away: RainbowSixOverviewTeam;
    home: RainbowSixOverviewTeam;
};

export enum RainbowSixTeamSide {
    Attack = 'ATTACK',
    Defend = 'DEFEND',
    Unknown = 'UNKNOWN',
}

/** Rule of the amount of insurance refund according to which a fixed amount is paid out */
export type RefundAmountAmountRule = RefundAmountRule & {
    __typename?: 'RefundAmountAmountRule';
    amount: Scalars['String'];
    type: RefundAmountRuleType;
};

/** Rule of the amount of insurance refund according to which a percent of the amount is paid out, but not more that the value of the maxAmount field */
export type RefundAmountPercentRule = RefundAmountRule & {
    __typename?: 'RefundAmountPercentRule';
    maxAmount: Scalars['String'];
    percent: Scalars['String'];
    type: RefundAmountRuleType;
};

export type RefundAmountRule = {
    type: RefundAmountRuleType;
};

/** Types of insurance refund rules */
export enum RefundAmountRuleType {
    Amount = 'AMOUNT',
    Percent = 'PERCENT',
}

export type RefundStrategyRule = {
    type: RefundStrategyRuleType;
};

/** Rule of the insurance refund strategy */
export enum RefundStrategyRuleType {
    MaxOddsCountLose = 'MAX_ODDS_COUNT_LOSE',
}

export type Restriction =
    | BetDelayRestriction
    | BetIntervalRestriction
    | BetProviderRestriction
    | BetTournamentDuplicateCompetitorIDsRestriction
    | BetTournamentIncompatibleMarketIDsRestriction
    | BetTypeRestriction
    | FreebetAmountRestriction
    | FreebetNotApplicableRestriction
    | FreebetStatusRestriction
    | InsuranceNotApplicableRestriction
    | InsuranceStatusRestriction
    | InsuranceValueRestriction
    | InternalErrorRestriction
    | MarketDefectiveRestriction
    | MarketExistenceRestriction
    | MarketStatusRestriction
    | MaxBetRestriction
    | MinBetRestriction
    | NotEnoughBalanceRestriction
    | OddExistenceRestriction
    | OddStatusRestriction
    | PlayerLimitRestriction
    | SelectionValueRestriction
    | SelectionsCountRestriction
    | SportEventBetStopRestriction
    | SportEventExistenceRestriction
    | SportEventStatusRestriction
    | WlDefinedRestriction;

export enum RestrictionType {
    BaselineSelectionExistence = 'baseline_selection_existence',
    BetCashOutSelectionsMismatch = 'bet_cash_out_selections_mismatch',
    /** @deprecated will never be present in the bet */
    BetDelay = 'bet_delay',
    BetInterval = 'bet_interval',
    BetProvider = 'bet_provider',
    BetSelectionExistence = 'bet_selection_existence',
    BetStatus = 'bet_status',
    BetTournamentDuplicateCompetitorIds = 'bet_tournament_duplicate_competitor_ids',
    BetTournamentIncompatibleMarketIds = 'bet_tournament_incompatible_market_ids',
    BetType = 'bet_type',
    CashOutAmountLimit = 'cash_out_amount_limit',
    CashOutBetType = 'cash_out_bet_type',
    CashOutOrderFreebet = 'cash_out_order_freebet',
    CashOutOrderStatus = 'cash_out_order_status',
    CashOutRefundAmount = 'cash_out_refund_amount',
    CashOutUnavailable = 'cash_out_unavailable',
    FreebetAmount = 'freebet_amount',
    FreebetNotApplicable = 'freebet_not_applicable',
    FreebetStatus = 'freebet_status',
    InsuranceNotApplicable = 'insurance_not_applicable',
    InsuranceStatus = 'insurance_status',
    InsuranceValue = 'insurance_value',
    InternalError = 'internal_error',
    MarketDefective = 'market_defective',
    MarketExistence = 'market_existence',
    MarketStatus = 'market_status',
    MaxBet = 'max_bet',
    MaxOddBet = 'max_odd_bet',
    MinBet = 'min_bet',
    NotEnoughBalance = 'not_enough_balance',
    OddExistence = 'odd_existence',
    OddStatus = 'odd_status',
    PlayerLimit = 'player_limit',
    SelectionValue = 'selection_value',
    SelectionsCount = 'selections_count',
    SportEventBetStop = 'sport_event_bet_stop',
    SportEventExistence = 'sport_event_existence',
    SportEventStatus = 'sport_event_status',
    SportEventType = 'sport_event_type',
    WlDefined = 'wl_defined',
}

export type RocketLeagueOverview = {
    __typename?: 'RocketLeagueOverview';
    ballSide: TeamHomeAway;
    bestOf: Scalars['Int'];
    currentMap: Scalars['Int'];
    maps?: Maybe<Array<RocketLeagueOverviewMap>>;
    sportType: RocketLeagueSportType;
    teams: RocketLeagueOverviewTeams;
    timer: Timer;
};

export type RocketLeagueOverviewMap = {
    __typename?: 'RocketLeagueOverviewMap';
    awayScore: Scalars['Int'];
    homeScore: Scalars['Int'];
    number: Scalars['Int'];
    winner: TeamHomeAway;
};

export type RocketLeagueOverviewTeam = {
    __typename?: 'RocketLeagueOverviewTeam';
    score: Scalars['Int'];
};

export type RocketLeagueOverviewTeams = {
    __typename?: 'RocketLeagueOverviewTeams';
    away: RocketLeagueOverviewTeam;
    home: RocketLeagueOverviewTeam;
};

export enum RocketLeagueSportType {
    Basketball = 'BASKETBALL',
    Football = 'FOOTBALL',
    Hockey = 'HOCKEY',
    Unknown = 'UNKNOWN',
}

export type Selection = {
    marketId: Scalars['String'];
    matchId: Scalars['String'];
    oddId: Scalars['String'];
    providerId?: InputMaybe<Scalars['String']>;
    ratio: Scalars['String'];
};

/**
 * Occurs if the selection value in the bet differs from the selection value in the sport event.
 * For example: the player makes a bet on the lead.
 * There are different betting strategies,
 * for example, the betting platform will accept the bet regardless of a slight deviation
 * in coefficients of the sport event and the bet.
 */
export type SelectionValueRestriction = {
    __typename?: 'SelectionValueRestriction';
    marketId: Scalars['String'];
    oddId: Scalars['String'];
    sportEventId: Scalars['String'];
    type: RestrictionType;
    value: Scalars['Float'];
};

/** Maximum allowed number of selections for a bet */
export type SelectionsCountRestriction = {
    __typename?: 'SelectionsCountRestriction';
    maxCount: Scalars['Int'];
    type: RestrictionType;
};

export type SimpleBetOdd = {
    __typename?: 'SimpleBetOdd';
    marketId: Scalars['String'];
    matchId: Scalars['String'];
    oddId: Scalars['String'];
    ratio: Scalars['String'];
};

export type SingleEliminationMatch = {
    __typename?: 'SingleEliminationMatch';
    bracketMatchID: Scalars['Int'];
    fixture?: Maybe<TournamentBracketFixture>;
    nextWinMatch: Scalars['Int'];
    prevMatch1: Scalars['Int'];
    prevMatch2: Scalars['Int'];
    round: Scalars['Int'];
};

export type SingleEliminationTournamentBracket = TournamentBracket & {
    __typename?: 'SingleEliminationTournamentBracket';
    bracketType: TournamentBracketType;
    matches: Array<SingleEliminationMatch>;
};

export enum SortDirection {
    Asc = 'ASC',
    Desc = 'DESC',
}

export type Spike = {
    __typename?: 'Spike';
    isPlanted: Scalars['Boolean'];
    time?: Maybe<Scalars['Int']>;
};

export type Sport = {
    __typename?: 'Sport';
    id: Scalars['String'];
    name: Scalars['String'];
    slug: Scalars['String'];
    tags: Array<SportTag>;
};

export type SportEvent = {
    __typename?: 'SportEvent';
    betStop: Scalars['Boolean'];
    disabled: Scalars['Boolean'];
    fixture: SportEventFixture;
    hasMatchLog: Scalars['Boolean'];
    id: Scalars['String'];
    markets: Array<Market>;
    marketsCount: Scalars['Int'];
    meta: Array<Pair>;
    providerId: Scalars['String'];
    slug: Scalars['String'];
    streams: Array<Stream>;
    updatedAt: Scalars['String'];
    version: Scalars['String'];
};

export type SportEventMarketsArgs = {
    limit?: InputMaybe<Scalars['Int']>;
    marketIds?: InputMaybe<Array<Scalars['String']>>;
    statuses?: InputMaybe<Array<MarketStatus>>;
    top?: InputMaybe<Scalars['Boolean']>;
    typeIds?: InputMaybe<Array<Scalars['Int']>>;
};

export type SportEventMarketsCountArgs = {
    statuses?: InputMaybe<Array<MarketStatus>>;
    typeIds?: InputMaybe<Array<Scalars['Int']>>;
};

/** Accepting bets and cash outs for the sport event is suspended */
export type SportEventBetStopRestriction = {
    __typename?: 'SportEventBetStopRestriction';
    sportEventId: Scalars['String'];
    type: RestrictionType;
};

export type SportEventCompetitor = {
    __typename?: 'SportEventCompetitor';
    homeAway: CompetitorHomeAway;
    id: Scalars['String'];
    logo: Scalars['String'];
    masterId: Scalars['String'];
    name: Scalars['String'];
    path: Scalars['String'];
    score: Array<CompetitorScore>;
    slug: Scalars['String'];
    templatePosition: Scalars['Int'];
    type: CompetitorType;
};

/** The sport event does not exist */
export type SportEventExistenceRestriction = {
    __typename?: 'SportEventExistenceRestriction';
    sportEventId: Scalars['String'];
    type: RestrictionType;
};

export type SportEventFiltersInput = {
    competitorIds?: InputMaybe<Array<Scalars['String']>>;
    countryCodes?: InputMaybe<Array<Scalars['String']>>;
    dateFrom?: InputMaybe<Scalars['String']>;
    dateSortAscending?: InputMaybe<Scalars['Boolean']>;
    dateTo?: InputMaybe<Scalars['String']>;
    favorite?: InputMaybe<Scalars['Boolean']>;
    hasStreams?: InputMaybe<Scalars['Boolean']>;
    isLive?: InputMaybe<Scalars['Boolean']>;
    marketIds?: InputMaybe<Array<Scalars['String']>>;
    marketLimit?: InputMaybe<Scalars['Int']>;
    marketStatuses?: InputMaybe<Array<MarketStatus>>;
    marketTypes?: InputMaybe<Array<Scalars['Int']>>;
    matchStatuses?: InputMaybe<Array<SportEventStatus>>;
    providerIds?: InputMaybe<Array<Scalars['Int']>>;
    searchString?: InputMaybe<Scalars['String']>;
    sportEventTypes?: InputMaybe<Array<SportEventType>>;
    sportIds?: InputMaybe<Array<Scalars['String']>>;
    tournamentIds?: InputMaybe<Array<Scalars['String']>>;
};

export type SportEventFixture = {
    __typename?: 'SportEventFixture';
    competitors: Array<SportEventCompetitor>;
    liveCoverage: Scalars['Boolean'];
    matchType: SportEventMatchType;
    score: Scalars['String'];
    sport: Sport;
    sportId: Scalars['String'];
    startTime: Scalars['String'];
    status: SportEventStatus;
    /** @deprecated Use SportEvent.streams instead */
    streams: Array<Stream>;
    title: Scalars['String'];
    tournament: Tournament;
    type: SportEventType;
    venue: Venue;
};

export type SportEventId = {
    __typename?: 'SportEventId';
    id?: Maybe<Scalars['String']>;
};

export type SportEventList = {
    __typename?: 'SportEventList';
    count: Scalars['Int'];
    sportEvents: Array<SportEvent>;
};

export type SportEventListBySport = {
    __typename?: 'SportEventListBySport';
    count: Scalars['Int'];
    sportEvents: Array<SportEvent>;
    sportId: Scalars['String'];
};

export enum SportEventMatchType {
    MultiCompetitive = 'MULTI_COMPETITIVE',
    Versus = 'VERSUS',
}

export enum SportEventOrder {
    /** Doesn't apply any sorting */
    None = 'NONE',
    /** @deprecated Use `RANK_DEFAULT`. */
    Rank = 'RANK',
    /** One of the types, that specified as default in ranking service */
    RankDefault = 'RANK_DEFAULT',
    /** Ranker that specified on backend for 'live' page */
    RankLivePage = 'RANK_LIVE_PAGE',
    /** Ranker that uses ds model to rank sport events by player bet history */
    RankPersonalized = 'RANK_PERSONALIZED',
    /** Sort by sport events statistics */
    RankProfit = 'RANK_PROFIT',
    /** Ranker that is recommended for each whitelabel (configurable) */
    RankRecommended = 'RANK_RECOMMENDED',
    /** Ranker that uses ds model to rank sport events by player country */
    RankRegional = 'RANK_REGIONAL',
    /** Sort by live flag and start time of sport events */
    RankStartTime = 'RANK_START_TIME',
    /** Sort by 'top' flag */
    RankTop = 'RANK_TOP',
}

export type SportEventOverview = {
    __typename?: 'SportEventOverview';
    checksum: Scalars['String'];
    id: Scalars['String'];
    overview?: Maybe<Overview>;
};

export enum SportEventState {
    Live = 'LIVE',
    Prematch = 'PREMATCH',
    Unknown = 'UNKNOWN',
}

export enum SportEventStatus {
    Abandoned = 'ABANDONED',
    Cancelled = 'CANCELLED',
    Closed = 'CLOSED',
    Delayed = 'DELAYED',
    Ended = 'ENDED',
    Live = 'LIVE',
    NotStarted = 'NOT_STARTED',
    Suspended = 'SUSPENDED',
    Unknown = 'UNKNOWN',
}

/** Occurs if the sport event has a forbidden status. Allowed sport event statuses - `LIVE, NOT_STARTED` */
export type SportEventStatusRestriction = {
    __typename?: 'SportEventStatusRestriction';
    sportEventId: Scalars['String'];
    status: SportEventStatus;
    type: RestrictionType;
};

export enum SportEventType {
    Match = 'MATCH',
    Outright = 'OUTRIGHT',
}

/**
 * Occurs if a cash out is placed for the type of a sport event for which it is forbidden
 * `(currently, cash outs are allowed only for the MATCH type of sport events)`
 */
export type SportEventTypeRestriction = {
    __typename?: 'SportEventTypeRestriction';
    sportEventId: Scalars['String'];
    sportEventType: SportEventType;
    type: RestrictionType;
};

/** Describes tabs configuration for a given sport. */
export type SportMarketsTabsConfig = {
    __typename?: 'SportMarketsTabsConfig';
    marketTabs: Array<MarketsTabsConfig>;
    sportId: Scalars['String'];
};

export enum SportTag {
    Classic = 'CLASSIC',
    Esport = 'ESPORT',
}

/**
 * is used for the system type of bets
 * if mode chunk - bet`s stake will be calculated as stake*system combinations count
 * for example:  stake 1;, system bet on 3 odds (we can only have 3 combinations)
 * - mode chunk: 1$ * 3 = 3$; 3$ - will be withdrawn from the player`s balance
 * - mode total: 1$ - will be withdrawn from the player`s balance
 */
export enum StakeMode {
    Chunk = 'chunk',
    Total = 'total',
}

export type StakeSuggest = {
    __typename?: 'StakeSuggest';
    amount: Scalars['String'];
};

export enum StakeSuggesterAlgorithm {
    /** The algorithm uses default stake suggestions for each Currency. */
    ByCurrency = 'BY_CURRENCY',
    /** The algorithm uses default stake suggestions for each Currency and sets MinBet as the first element in the array. */
    ByCurrency_2 = 'BY_CURRENCY_2',
    /**
     * The algorithm uses MaxBet, MinBet, and SuggestsCountLimit to find the Step: Step = MaxBet-MinBet/SuggestsCountLimit.
     * If MinBet != 0, it will be the first element in the array.
     * MaxBet will be the last element in the array.
     */
    ByMaxBet = 'BY_MAX_BET',
    /** Algorithm use the configuration from the back office for current white label (wl) */
    ByWl = 'BY_WL',
}

export type StarCraft1Overview = {
    __typename?: 'StarCraft1Overview';
    bestOf: Scalars['Int'];
    currentMap: Scalars['Int'];
    maps?: Maybe<Array<StarCraft1OverviewMap>>;
    teams: StarCraft1OverviewTeams;
};

export type StarCraft1OverviewMap = {
    __typename?: 'StarCraft1OverviewMap';
    number: Scalars['Int'];
    winner: TeamHomeAway;
};

export type StarCraft1OverviewTeam = {
    __typename?: 'StarCraft1OverviewTeam';
    score: Scalars['Int'];
};

export type StarCraft1OverviewTeams = {
    __typename?: 'StarCraft1OverviewTeams';
    away: StarCraft1OverviewTeam;
    home: StarCraft1OverviewTeam;
};

export type StarCraftOverview = {
    __typename?: 'StarCraftOverview';
    bestOf: Scalars['Int'];
    currentMap: Scalars['Int'];
    maps?: Maybe<Array<StarCraftOverviewMap>>;
    teams: StarCraftOverviewTeams;
};

export type StarCraftOverviewMap = {
    __typename?: 'StarCraftOverviewMap';
    number: Scalars['Int'];
    winner: TeamHomeAway;
};

export type StarCraftOverviewTeam = {
    __typename?: 'StarCraftOverviewTeam';
    score: Scalars['Int'];
};

export type StarCraftOverviewTeams = {
    __typename?: 'StarCraftOverviewTeams';
    away: StarCraftOverviewTeam;
    home: StarCraftOverviewTeam;
};

export enum Status {
    Active = 'ACTIVE',
    Inactive = 'INACTIVE',
}

export type Stream = {
    __typename?: 'Stream';
    id: Scalars['String'];
    locale: Scalars['String'];
    path: Scalars['String'];
    platforms: Array<StreamPlatform>;
    priority: Scalars['Int'];
    url: Scalars['String'];
};

export type StreamPlatform = {
    __typename?: 'StreamPlatform';
    allowedCountries: Array<Scalars['String']>;
    enabled: Scalars['Boolean'];
    type: Scalars['String'];
};

export type Subscription = {
    __typename?: 'Subscription';
    /** Provides updates whenever there's a change in a markets tabs list, i.e. markets tab has been added on deleted. */
    onTabsListChanged: MarketsTabsList;
    onUpdateBet: Bet;
    onUpdateBetTips: BetTips;
    /** Provides updates for sport event whenever there's a change in fixture or markets. */
    onUpdateSportEvent: SportEvent;
    onUpdateSportEventOverviews: UpdateSportEventOverviewsResponse;
    /** Provides updates whenever there's a change in a markets tab, i.e. market has changed. */
    onUpdateTab: CompiledMarketsTab;
    /** @deprecated Doesn't provide desired level of flexibility, thus deprecated and will be removed. Underlying implementation is not supported anymore */
    onUpdateTabs: CompiledMarketsTabsDiff;
};

export type SubscriptionOnTabsListChangedArgs = {
    sportEventId: Scalars['String'];
};

export type SubscriptionOnUpdateBetTipsArgs = {
    sportEventId: Scalars['String'];
};

export type SubscriptionOnUpdateSportEventArgs = {
    marketIds?: InputMaybe<Array<Scalars['String']>>;
    marketLimit?: InputMaybe<Scalars['Int']>;
    marketStatuses?: InputMaybe<Array<MarketStatus>>;
    marketTypes?: InputMaybe<Array<Scalars['Int']>>;
    sportEventId: Scalars['String'];
    version?: InputMaybe<Scalars['String']>;
};

export type SubscriptionOnUpdateSportEventOverviewsArgs = {
    sportEventIDs: Array<IdChecksum>;
};

export type SubscriptionOnUpdateTabArgs = {
    marketStatuses?: InputMaybe<Array<MarketStatus>>;
    marketTabId: Scalars['String'];
    sportEventId: Scalars['String'];
};

export type SubscriptionOnUpdateTabsArgs = {
    marketStatuses?: InputMaybe<Array<MarketStatus>>;
    sportEventId: Scalars['String'];
};

export type SuggestedExpressesInput = {
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
};

export type TableTennisOverview = {
    __typename?: 'TableTennisOverview';
    currentSet: Scalars['Int'];
    server: TeamHomeAway;
    sets?: Maybe<Array<TableTennisOverviewSet>>;
    teams: TableTennisOverviewTeams;
};

export type TableTennisOverviewSet = {
    __typename?: 'TableTennisOverviewSet';
    number: Scalars['Int'];
    totalPointsAway: Scalars['Int'];
    totalPointsHome: Scalars['Int'];
    type: TableTennisSetType;
    winner: TeamHomeAway;
};

export type TableTennisOverviewTeam = {
    __typename?: 'TableTennisOverviewTeam';
    score: Scalars['Int'];
};

export type TableTennisOverviewTeams = {
    __typename?: 'TableTennisOverviewTeams';
    away: TableTennisOverviewTeam;
    home: TableTennisOverviewTeam;
};

export enum TableTennisSetType {
    Golden = 'GOLDEN',
    Regular = 'REGULAR',
}

export type Team = {
    __typename?: 'Team';
    countryCode: Scalars['String'];
    id: Scalars['String'];
    keywords: Array<Scalars['String']>;
    logo: Scalars['String'];
    name: Scalars['String'];
    playerIds: Array<Scalars['String']>;
    players: Array<Player>;
    slug: Scalars['String'];
    sport: Sport;
    statistics: Array<TeamStatistic>;
};

export enum TeamHomeAway {
    Away = 'AWAY',
    Home = 'HOME',
    Unknown = 'UNKNOWN',
}

export type TeamStatistic =
    | TeamStatisticCsgo
    | TeamStatisticDota2
    | TeamStatisticHearthstone
    | TeamStatisticLol
    | TeamStatisticOverwatch
    | TeamStatisticPubg
    | TeamStatisticTotal;

export type TeamStatisticCsgo = {
    __typename?: 'TeamStatisticCSGO';
    stability: Scalars['Int'];
    strength: Scalars['Int'];
};

export type TeamStatisticDota2 = {
    __typename?: 'TeamStatisticDota2';
    aggression: Scalars['Int'];
    carry: Scalars['Int'];
    form: Scalars['Int'];
    lane: Scalars['Int'];
    mid: Scalars['Int'];
    offlane: Scalars['Int'];
    roshan: Scalars['Int'];
    rune: Scalars['Int'];
    stability: Scalars['Int'];
    strength: Scalars['Int'];
    support: Scalars['Int'];
    ward: Scalars['Int'];
};

export type TeamStatisticHearthstone = {
    __typename?: 'TeamStatisticHearthstone';
    stability: Scalars['Int'];
    strength: Scalars['Int'];
};

export type TeamStatisticLol = {
    __typename?: 'TeamStatisticLOL';
    stability: Scalars['Int'];
    strength: Scalars['Int'];
};

export type TeamStatisticOverwatch = {
    __typename?: 'TeamStatisticOverwatch';
    stability: Scalars['Int'];
    strength: Scalars['Int'];
};

export type TeamStatisticPubg = {
    __typename?: 'TeamStatisticPUBG';
    stability: Scalars['Int'];
    strength: Scalars['Int'];
};

export type TeamStatisticTotal = {
    __typename?: 'TeamStatisticTotal';
    money: Scalars['Int'];
    place: Scalars['Int'];
    rate: Scalars['Int'];
};

export enum TennisGamePoint {
    Point0 = 'POINT0',
    Point15 = 'POINT15',
    Point30 = 'POINT30',
    Point40 = 'POINT40',
    PointAbove = 'POINT_ABOVE',
}

export type TennisOverview = {
    __typename?: 'TennisOverview';
    currentSet: Scalars['Int'];
    pause: Scalars['Boolean'];
    server: TeamHomeAway;
    sets?: Maybe<Array<TennisOverviewSet>>;
    teams: TennisOverviewTeams;
};

export type TennisOverviewGame = {
    __typename?: 'TennisOverviewGame';
    score: Scalars['Int'];
};

export type TennisOverviewGamePoint = {
    __typename?: 'TennisOverviewGamePoint';
    gamePoint: TennisGamePoint;
};

export type TennisOverviewSet = {
    __typename?: 'TennisOverviewSet';
    gameScore: TennisOverviewSetGameScore;
    number: Scalars['Int'];
    state: TennisSetStatus;
    tieBreakScore: TennisOverviewSetTieBreakScore;
    winner: TeamHomeAway;
};

export type TennisOverviewSetGameScore = {
    __typename?: 'TennisOverviewSetGameScore';
    away: Scalars['Int'];
    home: Scalars['Int'];
};

export type TennisOverviewSetTieBreakScore = {
    __typename?: 'TennisOverviewSetTieBreakScore';
    away: Scalars['Int'];
    home: Scalars['Int'];
};

export type TennisOverviewTeam = {
    __typename?: 'TennisOverviewTeam';
    game: TennisOverviewGame;
    gamePoint: TennisOverviewGamePoint;
    set: TennisOverviewTeamSet;
    tieBreak: TennisOverviewTieBreak;
};

export type TennisOverviewTeamSet = {
    __typename?: 'TennisOverviewTeamSet';
    score: Scalars['Int'];
};

export type TennisOverviewTeams = {
    __typename?: 'TennisOverviewTeams';
    away: TennisOverviewTeam;
    home: TennisOverviewTeam;
};

export type TennisOverviewTieBreak = {
    __typename?: 'TennisOverviewTieBreak';
    score: Scalars['Int'];
};

export enum TennisSetStatus {
    Common = 'COMMON',
    TieBreak = 'TIE_BREAK',
}

export type Timer = {
    __typename?: 'Timer';
    isActive: Scalars['Boolean'];
    time: Scalars['Int'];
};

export type TopTournament = {
    __typename?: 'TopTournament';
    endedAt: Scalars['String'];
    id: Scalars['String'];
    priority: Scalars['Int'];
    startedAt: Scalars['String'];
    tournament: Tournament;
};

export type Tournament = {
    __typename?: 'Tournament';
    countryCode: Scalars['String'];
    dateEnd: Scalars['String'];
    dateStart: Scalars['String'];
    description: Scalars['String'];
    id: Scalars['String'];
    isLocalizationOverridden?: Maybe<Scalars['Boolean']>;
    logo: Scalars['String'];
    masterId: Scalars['String'];
    name: Scalars['String'];
    /** @deprecated Use organizer instead */
    organization: Scalars['String'];
    organizationId: Scalars['String'];
    organizer?: Maybe<Organization>;
    prizePool: Scalars['String'];
    showTournamentInfo?: Maybe<Scalars['Boolean']>;
    slug: Scalars['String'];
    sportId: Scalars['String'];
};

export type TournamentBracket = {
    bracketType: TournamentBracketType;
};

export type TournamentBracketCompetitor = {
    __typename?: 'TournamentBracketCompetitor';
    competitor: Competitor;
    isMatched: Scalars['Boolean'];
    isSelected: Scalars['Boolean'];
};

export type TournamentBracketFixture = {
    __typename?: 'TournamentBracketFixture';
    competitors: Array<TournamentBracketCompetitor>;
    isFinished: Scalars['Boolean'];
};

export type TournamentBracketPrediction = {
    __typename?: 'TournamentBracketPrediction';
    bracket: TournamentBracket;
    id: Scalars['String'];
    startedAt: Scalars['String'];
    status: PredictionStatus;
};

export type TournamentBracketPredictionCompetitorInput = {
    competitorID: Scalars['String'];
    isSelected: Scalars['Boolean'];
};

export type TournamentBracketPredictionInput = {
    matches: Array<TournamentBracketPredictionMatchInput>;
    tournamentID: Scalars['String'];
};

export type TournamentBracketPredictionMatchInput = {
    bracketMatchID: Scalars['Int'];
    competitors: Array<TournamentBracketPredictionCompetitorInput>;
};

export enum TournamentBracketType {
    DoubleElimination = 'DOUBLE_ELIMINATION',
    SingleElimination = 'SINGLE_ELIMINATION',
}

export type TournamentFixture = {
    __typename?: 'TournamentFixture';
    id: Scalars['String'];
    position: Scalars['Int'];
    resultFixtureId: Scalars['String'];
    round: Scalars['Int'];
    sportEventLiveId: Scalars['String'];
    sportEventPrematchId: Scalars['String'];
    startDate: Scalars['String'];
};

export type TournamentTable = {
    __typename?: 'TournamentTable';
    competitorsNumber: Scalars['String'];
    fixtures?: Maybe<Array<TournamentFixture>>;
    id: Scalars['String'];
    type: TournamentTableType;
};

export enum TournamentTableType {
    KnockoutSingle = 'KNOCKOUT_SINGLE',
}

export type TrebleOdd = {
    __typename?: 'TrebleOdd';
    marketId: Scalars['String'];
    oddId: Scalars['String'];
    sportEventId: Scalars['String'];
};

export type UpdateSportEventOverviewsResponse = {
    __typename?: 'UpdateSportEventOverviewsResponse';
    replace: Array<Maybe<SportEventOverview>>;
};

export enum ValorantGameState {
    AfterEndTime = 'AFTER_END_TIME',
    FreezeTime = 'FREEZE_TIME',
    LiveTime = 'LIVE_TIME',
    NotStarted = 'NOT_STARTED',
    Unknown = 'UNKNOWN',
}

export enum ValorantMap {
    Ascent = 'ASCENT',
    Bind = 'BIND',
    Breeze = 'BREEZE',
    Fracture = 'FRACTURE',
    Haven = 'HAVEN',
    Icebox = 'ICEBOX',
    Lotus = 'LOTUS',
    Pearl = 'PEARL',
    Split = 'SPLIT',
    Sunset = 'SUNSET',
    Unknown = 'UNKNOWN',
}

export type ValorantMapOverview = {
    __typename?: 'ValorantMapOverview';
    score: Scalars['Int'];
};

export type ValorantOverview = {
    __typename?: 'ValorantOverview';
    bestOf: Scalars['Int'];
    currentMap: Scalars['Int'];
    currentRound: Scalars['Int'];
    gameState: ValorantGameState;
    mapName: ValorantMap;
    maps?: Maybe<Array<ValorantOverviewMap>>;
    spike: Spike;
    teams: ValorantOverviewTeams;
    timer: Timer;
};

export type ValorantOverviewMap = {
    __typename?: 'ValorantOverviewMap';
    away: ValorantMapOverview;
    home: ValorantMapOverview;
    map: ValorantMap;
    number: Scalars['Int'];
    winner: TeamHomeAway;
};

export type ValorantOverviewTeam = {
    __typename?: 'ValorantOverviewTeam';
    currentSide: ValorantTeamSide;
    score: Scalars['Int'];
};

export type ValorantOverviewTeams = {
    __typename?: 'ValorantOverviewTeams';
    away: ValorantOverviewTeam;
    home: ValorantOverviewTeam;
};

export enum ValorantTeamSide {
    Attack = 'ATTACK',
    Defend = 'DEFEND',
    Unknown = 'UNKNOWN',
}

export type Venue = {
    __typename?: 'Venue';
    city: Scalars['String'];
    country: Scalars['String'];
    id: Scalars['String'];
    name: Scalars['String'];
};

export type VolleyballOverview = {
    __typename?: 'VolleyballOverview';
    currentSet: Scalars['Int'];
    server: TeamHomeAway;
    sets?: Maybe<Array<VolleyballOverviewSet>>;
    teams: VolleyballOverviewTeams;
};

export type VolleyballOverviewSet = {
    __typename?: 'VolleyballOverviewSet';
    number: Scalars['Int'];
    totalPointsAway: Scalars['Int'];
    totalPointsHome: Scalars['Int'];
    type: VolleyballSetType;
    winner: TeamHomeAway;
};

export type VolleyballOverviewTeam = {
    __typename?: 'VolleyballOverviewTeam';
    score: Scalars['Int'];
};

export type VolleyballOverviewTeams = {
    __typename?: 'VolleyballOverviewTeams';
    away: VolleyballOverviewTeam;
    home: VolleyballOverviewTeam;
};

export enum VolleyballSetType {
    Golden = 'GOLDEN',
    Regular = 'REGULAR',
}

/** Custom restriction given from WL */
export type WlDefinedRestriction = {
    __typename?: 'WLDefinedRestriction';
    code: Scalars['String'];
    context: Array<Pair>;
    type: RestrictionType;
};

export type WhiteLabelSettings = {
    __typename?: 'WhiteLabelSettings';
    betSlipEnabled: Scalars['Boolean'];
    cashoutEnabled: Scalars['Boolean'];
    sportEventRanking: WhiteLabelSportEventRankingSettings;
};

export type WhiteLabelSportEventRankerSettings = {
    __typename?: 'WhiteLabelSportEventRankerSettings';
    ranker: WhiteLabelSportEventRankerType;
    topEnabled: Scalars['Boolean'];
};

export enum WhiteLabelSportEventRankerType {
    Default = 'DEFAULT',
    Personalized = 'PERSONALIZED',
    Profit = 'PROFIT',
}

export type WhiteLabelSportEventRankingSettings = {
    __typename?: 'WhiteLabelSportEventRankingSettings';
    authenticated: WhiteLabelSportEventRankerSettings;
    unauthenticated: WhiteLabelSportEventRankerSettings;
};

export enum WinnerHomeAway {
    Away = 'AWAY',
    Home = 'HOME',
    Noone = 'NOONE',
    Unknown = 'UNKNOWN',
}
