import { useContext } from 'react';

import type { AppEnvCacheType } from '../context';
import { appEnvCache } from '../context';

type OutputType = AppEnvCacheType;

export const useAppEnv = (): OutputType => {
    const context = useContext(appEnvCache);

    if (!context) {
        throw new Error(
            'useAppEnv should only be called inside AppEnvProvider'
        );
    }

    return context;
};
