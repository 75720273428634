import {
    MouseEvent,
    RefObject,
    useCallback,
    useLayoutEffect,
    useRef,
    useState,
} from 'react';

import { useToggle } from 'hooks';
import { captureSentryException } from 'services/sentry';
import { useMessageEvent } from './messageEvent.cms';
import useMessageTimeLeft from './useMessageTimeLeft';

interface Input {
    id: string;
    createdAt: string;
    isRead: boolean;
    isPreview?: boolean;
    maxSubtitleHeight: number;
    handleMessageClick?: (payload: { id: string; isRead: boolean }) => void;
    handleMessageClose?: (id: string) => void;
}

interface Output {
    isOpen: boolean;
    isTextLarge: boolean;
    toggleMessage: VoidFunction;
    timeLeft: string;
    subtitleRef: RefObject<HTMLDivElement>;
    onMediaButton: (e: MouseEvent, url: string) => void;
    onMessageDelete: (e: MouseEvent) => void;
}

const useInboxCenterMessage = ({
    id,
    isRead,
    isPreview,
    maxSubtitleHeight,
    createdAt,
    handleMessageClick,
    handleMessageClose,
}: Input): Output => {
    const subtitleRef = useRef<HTMLDivElement>(null);
    const [isTextLarge, setIsTextLarge] = useState<boolean>(false);

    const [isOpen, toggleIsOpen] = useToggle();
    const [messageEvent] = useMessageEvent();
    const { timeLeft } = useMessageTimeLeft({ createdAt });

    useLayoutEffect(() => {
        if (!subtitleRef.current) return;

        setIsTextLarge(subtitleRef.current.clientHeight > maxSubtitleHeight);
    }, [maxSubtitleHeight]);

    const onMediaButton = useCallback(
        async (e: MouseEvent, url: string) => {
            e.preventDefault();
            e.stopPropagation();

            handleMessageClick?.({ id, isRead });

            try {
                await messageEvent({
                    variables: {
                        messageId: id,
                        action: 'button_click',
                    },
                });
            } catch (err) {
                if (err instanceof Error) {
                    captureSentryException({
                        label: 'InboxMessage send event error',
                        message: err.message,
                    });
                }
            } finally {
                window.location.assign(url);
            }
        },
        [handleMessageClick, id, isRead, messageEvent]
    );

    const toggleMessage = useCallback(() => {
        handleMessageClick?.({ id, isRead });

        if (isPreview) return;

        toggleIsOpen();
    }, [isRead, isPreview, id, toggleIsOpen, handleMessageClick]);

    const onMessageDelete = useCallback(
        (e: MouseEvent) => {
            e.stopPropagation();

            handleMessageClose?.(id);
        },
        [id, handleMessageClose]
    );

    return {
        isOpen,
        isTextLarge,
        toggleMessage,
        timeLeft,
        subtitleRef,
        onMediaButton,
        onMessageDelete,
    };
};

export default useInboxCenterMessage;
