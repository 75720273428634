import { useGetTournamentByFilters } from 'gql/cms/queries/tournaments/getTournamentByFilters.cms';

const useTournamentByFilters = (): string | undefined => {
    const { data } = useGetTournamentByFilters(() => ({
        variables: {
            types: ['betting'],
            active: true,
            isNeedPrize: false,
        },
        pagePropsKey: 'tournamentsByFilters',
    }));

    return data?.tournamentsByFilters?.[0]?.slug;
};

export default useTournamentByFilters;
