import React, { ErrorInfo, PropsWithChildren } from 'react';

import Button from 'components/Button';
import { Color } from '../Button/types';

class ErrorBoundary extends React.Component<PropsWithChildren<{}>> {
    state = { hasError: false };

    constructor(props: any) {
        super(props);

        this.state = { hasError: false };
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // eslint-disable-next-line no-console
        console.log({ error, errorInfo });
    }

    onClickHandler = (): void => this.setState({ hasError: false });

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,react/sort-comp
    static getDerivedStateFromError() {
        return { hasError: true };
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    render() {
        if (this.state.hasError) {
            return (
                <div className="flex flex-col items-center py-6">
                    <h2 className="mb-4 text-primary-white typo-sm-2">
                        Oops, there is an error!
                    </h2>
                    <Button color={Color.Orange} onClick={this.onClickHandler}>
                        Try again?
                    </Button>
                </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
