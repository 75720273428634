import { FC } from 'react';

import withServerRender from 'hocs/withServerRender';
import { GetSeoRulesByPage } from 'types/gql.cms';
import FooterSeoContent from './FooterSeoContent';
import useFooterSeo from './useFooterSeo';

interface Props {
    seoRulesByPage?: GetSeoRulesByPage;
}

const FooterSeo: FC<Props> = () => {
    const footerSeo = useFooterSeo();

    if (!footerSeo) return null;

    const { pageHeader, texts, isOpen, toggleSeoBlock } = footerSeo;

    return (
        <FooterSeoContent
            texts={texts}
            isOpen={isOpen}
            onClick={toggleSeoBlock}
            pageHeader={pageHeader}
            scrollHandler={footerSeo.scrollHandler}
        />
    );
};

export default withServerRender(FooterSeo, ({ seoRulesByPage }) => {
    if (!seoRulesByPage?.seoRulesByPage.seo) return null;

    const texts = seoRulesByPage.seoRulesByPage.seo?.texts || [];
    const pageHeader = seoRulesByPage.seoRulesByPage.seo?.pageHeader || '';

    return (
        <FooterSeoContent texts={texts} pageHeader={pageHeader} isOpen={true} />
    );
});
