import { useCallback } from 'react';

import { useLocalStorage } from 'hooks';

const LOCAL_STORAGE_KEY = 'verificationBannerConfirmedIds';

interface Output {
    isCurrentUserClosedBanner: (playerId: string) => boolean;
    addUserIdToVerificationBannerIds: (playerId: string) => void;
    removeUserIdToVerificationBannerIds: (playerId: string) => void;
}
const useVerificationBannerLocalStorage = (): Output => {
    const { getLocalStorageData, setLocalStorageData } = useLocalStorage({
        localStorageKey: LOCAL_STORAGE_KEY,
    });

    const getConfirmedIds = useCallback((): string[] => {
        const ids = getLocalStorageData()?.confirmedIds;

        return Array.isArray(ids) ? ids : [];
    }, [getLocalStorageData]);

    const isCurrentUserClosedBanner = useCallback(
        (playerId: string): boolean => {
            return getConfirmedIds().some((id) => playerId === id);
        },
        [getConfirmedIds]
    );

    const addUserIdToVerificationBannerIds = useCallback(
        (playerId: string): void => {
            const ids = getConfirmedIds();

            if (isCurrentUserClosedBanner(playerId)) return;

            ids.push(playerId);

            setLocalStorageData('confirmedIds', ids);
        },
        [getConfirmedIds, isCurrentUserClosedBanner, setLocalStorageData]
    );

    const removeUserIdToVerificationBannerIds = useCallback(
        (playerId: string): void => {
            const ids = getConfirmedIds();
            const filteredArray = ids.filter((id) => id !== playerId);

            setLocalStorageData('confirmedIds', filteredArray);
        },
        [getConfirmedIds, setLocalStorageData]
    );

    return {
        isCurrentUserClosedBanner,
        addUserIdToVerificationBannerIds,
        removeUserIdToVerificationBannerIds,
    };
};

export default useVerificationBannerLocalStorage;
