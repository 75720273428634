import { makeVar } from '@apollo/client';

interface IRedirectVar {
    pathname?: string;
    pathTo?: string;
    shouldRedirect?: boolean;
}

const cmsApolloRedirectVar = makeVar<IRedirectVar>({});

export default cmsApolloRedirectVar;
