import { ComponentType } from 'react';
import { ScrollRestoration } from 'react-router-dom';
import { registerLocale } from 'i18n-iso-countries';
import type { NextPage } from 'next';
import dynamic from 'next/dynamic';

import { LayoutType } from 'app-constants';
import { HybridRouterProvider } from 'components/HybridRouter';
import LoadScript from 'components/LoadScript';
import MobileDetect from 'components/utilities/MobileDetect';
import { withClientRender } from 'hocs';
import {
    ApolloClientsProvider,
    LazyBettingApolloProvider,
} from 'services/apollo';
import { PageProps } from 'types';
import {
    LoadCountrySelectMessages,
    MessagesProvider,
    RegisterCountryTranslations,
} from '../IntlMessages';
import MainLayout from '../MainLayout';
import ResponsiveLayoutContext from '../MainLayout/ResponsiveLayoutContext';
import { useClientContext } from './ClientRenderContext';
import LayoutTypeContextProvider from './LayoutTypeProvider';

interface Props {
    Page: NextPage<PageProps>;
    pageProps: PageProps;
    locale?: string;
}

const Root: React.FC<Props> = ({ pageProps, locale, Page }) => {
    const layoutType: LayoutType =
        Page.settings.layoutType ||
        pageProps.gsspData?.layoutType ||
        LayoutType.BettingStatic;
    const hasCountryTranslations = !!pageProps.gsspData?.countryTranslations;

    const isClientRouter = useClientContext();

    if (pageProps.gsspData?.countryTranslations) {
        registerLocale(pageProps.gsspData.countryTranslations);
    }

    return (
        <>
            <LoadScript gaKey={pageProps.env.PUBLIC_NEXT_GA_KEY} />
            <MobileDetect isMobile={pageProps.isMobile}>
                {isClientRouter && <ScrollRestoration />}
                <MessagesProvider
                    locale={locale}
                    initialMessages={pageProps.messages}
                >
                    {isClientRouter && (
                        <RegisterCountryTranslations
                            shouldLoadTranslations={!hasCountryTranslations}
                            locale={locale}
                        />
                    )}
                    <ApolloClientsProvider pageProps={pageProps}>
                        <LayoutTypeContextProvider layoutType={layoutType}>
                            <ResponsiveLayoutContext>
                                <LazyBettingApolloProvider
                                    pageProps={pageProps}
                                >
                                    <MainLayout>
                                        <LoadCountrySelectMessages />
                                        {getLayoutByType(
                                            layoutType,
                                            <Page {...pageProps} />
                                        )}
                                    </MainLayout>
                                </LazyBettingApolloProvider>
                            </ResponsiveLayoutContext>
                        </LayoutTypeContextProvider>
                    </ApolloClientsProvider>
                </MessagesProvider>
            </MobileDetect>
        </>
    );
};

function withHybridRouter<TProps extends { locale?: string }>(
    WrappedComponent: ComponentType<TProps>
): ComponentType<TProps> {
    const WithHybridRouter: ComponentType<TProps> = (props: TProps) => (
        <HybridRouterProvider locale={props.locale}>
            <WrappedComponent {...props} />
        </HybridRouterProvider>
    );

    return WithHybridRouter;
}

const BettingLayout = dynamic(
    () =>
        import(
            /* webpackChunkName: "BettingLayout" */ 'layouts/BettingLayout/BettingLayout'
        )
);

const CasinoLayout = dynamic(
    () =>
        import(
            /* webpackChunkName: "CasinoLayout" */ 'layouts/CasinoLayout/CasinoLayout'
        )
);

const getLayoutByType = (layoutType: LayoutType, page: any): JSX.Element => {
    switch (layoutType) {
        case LayoutType.Betting: {
            if (process.env.BETTING_SSR_ENABLE === 'false') {
                const ClientBettingLayout = withClientRender(BettingLayout);

                return <ClientBettingLayout>{page}</ClientBettingLayout>;
            }

            return <BettingLayout>{page}</BettingLayout>;
        }

        case LayoutType.Casino:
        case LayoutType.Game:
        case LayoutType.CasinoStatic: {
            return <CasinoLayout>{page}</CasinoLayout>;
        }

        default:
            return page;
    }
};

export default withHybridRouter(Root);
