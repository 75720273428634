import { fillLink } from 'cl-fill-link';
import type { PublicEnv } from 'types';

import { AppLink } from 'types/applink';
import { CustomAppLink } from 'types/customApplink';
import { MarketStatus, SportEventStatus } from 'types/gql.bet';

export { AppLink, CustomAppLink };

export enum FetchStatus {
    INITIAL = 'INITIAL',
    LOADING = 'LOADING',
    LOADED = 'LOADED',
    FAILED = 'FAILED',
}

export const ENV_KEYS: Array<keyof PublicEnv> = [
    'PUBLIC_CMS_GQL_CLIENT_ENDPOINT',
    'PUBLIC_PLATFORM_GQL_CLIENT_ENDPOINT',
    'PUBLIC_NEXT_GA_KEY',
    'APP_VERSION',
    'APP_ENV',
    'SENTRY_ENVIRONMENT',
    'CMS_GQL_SSR_BATCHING_ENABLE',
    'CMS_SSR_ENABLE',
    'CMS_SSR_CASINO_ENABLE',
] as const;

export enum SportEventMetaKey {
    BetradarId = 'betradar_id',
    BetradarLMTCoverageLevel = 'betradar_lmt_coverage_level',
    Bo = 'bo',
    Inning = 'inning',
    GameState = 'game_state',
    GoldenSet = 'golden_set',
    Map = 'map',
    OriginalId = 'original_id',
    Pause = 'pause',
    Period = 'period',
    PeriodNumber = 'period_number',
    Round = 'round',
    Server = 'server',
    Set = 'set',
    SetState = 'set_state',
    SideAway = 'side_away',
    SideHome = 'side_home',
    StateNumber = 'state_number',
    Time = 'time',
    TimerRunning = 'timer_running',
    Var = 'var',
}

export enum ScoreTypes {
    TYPE_BULLETS = 'bullets',
    TYPE_INNING_POINTS = 'inning_points',
    TYPE_INNING_OUTS = 'inning_outs',
    TYPE_GAME_KILLS = 'game_kills',
    TYPE_GAME_PLAYERS = 'game_players',
    TYPE_GAME_POINTS = 'game_points',
    TYPE_GOLDEN_SET = 'golden_set',
    TYPE_MAP = 'map',
    TYPE_QUARTER = 'quarter',
    TYPE_QUARTER_OVERTIME = 'quarter_overtime',
    TYPE_OVERTIME = 'overtime',
    TYPE_PERIOD_1ST_HALF = 'period_1st_half',
    TYPE_PERIOD_2ND_HALF = 'period_2nd_half',
    TYPE_PERIOD_1ST_EXTRA = 'period_1st_extra',
    TYPE_PERIOD_2ND_EXTRA = 'period_2nd_extra',
    TYPE_PERIOD_BULLETS = 'period_bullets',
    TYPE_PERIOD_OVERTIME = 'period_overtime',
    TYPE_PERIOD_PENALTIES = 'period_penalties',
    TYPE_PERIOD_REGULAR = 'period_regular',
    TYPE_RED_CARD = 'red_card',
    TYPE_ROUND_OVERTIME = 'round_overtime',
    TYPE_SET = 'set',
    TYPE_TOTAL = 'total',
    TYPE_TIE_BREAK = 'tie_break',
    TYPE_YELLOW_CARD = 'yellow_card',
    TYPE_YELLOW_RED_CARD = 'yellow_red_card',
}

export enum SportType {
    Sports = 'sports',
    Esports = 'esports',
}

export const SportTypeLink = {
    Sports: fillLink(AppLink.SPORTS, {}) as '/sports',
    Esports: fillLink(AppLink.ESPORTS, {}) as 'esports',
} as const;

export enum ModalsHash {
    Bonuses = '#!/player/profile-promocode',
    MyBets = '#!/player/profile-bets-history',
    Notifications = '#!/message/inbox',
    Authorization = '#!/auth/signin',
    Registration = '#!/auth/register',
    Deposit = '#!/player/profile-deposit',
    PasswordRecovery = '#!/auth/password-recovery',
    RewardsHistory = '#!/player/profile-rewards-history',
    ProfileData = '#!/player/profile-data',
    InviteFriends = '#!/player/invite-friend',
    InvitedFriends = '#!/player/invited-friends',
    CasinoBonuses = '#!/player/profile-casinobonuses',
    MyBalance = '#!/player/profile-account',
    GameTransactions = '#!/player/profile-game-transactions',
    Transactions = '#!/player/profile-cashier-payment',
    KYC = '#!/player/kyc',
    PhoneVerification = '#!/player/verification-phone',
    EmailVerification = '#!/player/confirm-email',
    RegisterFinish = '#!/register-finish',
    PasswordNew = '#!/auth/password-new',
    PasswordChange = '#!/auth/password-change',
    BonusProgram = '#!/player/bonus-program',
    WarningRequiredRequisites = '#!/warning/required-requisites',
    GamesFreeSpin = '#!/games/free-spin',
    BonusTransfer = '#!/player/bonus-transfer',
    AppError = '#!/app/error',
    NeedVerification = '#!/need-verification',
    NotEnoughDocs = '#!/not-enough-docs',
    PlayOnReal = '#!/game/play-on-real',
    ProfileInfo = '#!/player/profile-info',
    PlayerBirthday = '#!/player/birthday',
    PlayerLimitReached = '#!/player/limit-reached',
    Withdraw = '#!/player/profile-cashier-withdraw',
    TransactionError = '#!/player/transaction-error',
    TransactionSuccess = '#!/player/transaction-success',
    TermsConditions = '#!/terms-conditions/request-accept',
    FirstDepositRequired = '#!/first-deposit-required',
    MyBetsBetting = '#!/player/bets-history',
    ReferralInviteSuccess = '#!/player/referral-invite-success',
    EuroIntro = '#!/euro-intro',
    EuroRules = '#!/euro-rules',
    ResponsibleGaming = '#!/player/responsible-gaming',
    ConfirmLimit = '#!/player/confirm-limit',
    FavoriteGames = '#!/games/favorite',
    CasinoMoreGames = '#!/player/casino-more-games',
    LastPlayedGames = '#!/player/last-played-games',
}

export enum CookiesType {
    AffData = 'affdata',
    AppBanner = 'hide-app-banner',
    Paseto = 'paseto',
    AuthToken = 'auth-token',
    RecoveryPasswordContinue = 'start-recovery-password',
    MessageInfo = 'message-info',
    RequisiteSocialNetwork = 'requisite-social-network',
    RequisiteApple = 'requisite-apple',
    InBoxPreviewLogin = 'inbox-preview-login',
    CookiesAgreements = 'cookie-agreement',
    HidePromoTournamentBanner = 'hide-promo-tournament-banner',
    Features = 'features',
    DebugInfo = 'debug-info',
    RefCode = 'refCode',
    Locale = '_spa-locale',
    DeeplinkMatchData = 'deeplink-match-data',
    LinkParams = 'link_params',
    UUID = 'uuid',
}

export enum LayoutType {
    None = 'none',
    Casino = 'casino',
    Betting = 'betting',
    BettingStatic = 'betting-static',
    BettingSeo = 'betting-seo',
    Game = 'game',
    CasinoStatic = 'casino-static',
}

export enum MainLayoutType {
    Casino = 'casino',
    Betting = 'betting',
}

export const POLLING_INTERVAL = 30000 as const;

export const ID_PATH_DEVIDER = '~' as const;
export const UNIQUE_ID_KEY = 'path' as const;

export enum OverlayType {
    NONE = 'NONE',
    TOURNAMENT_LIST = 'TOURNAMENT_LIST',
    FEATURED_TOURNAMENTS_LIST = 'FEATURED_TOURNAMENTS_LIST',
}

export enum CompareStatus {
    Greater = 'greater',
    Smaller = 'smaller',
    Equal = 'equal',
}

export const ACTIVE_MARKET_STATUSES = [
    MarketStatus.Active,
    MarketStatus.Suspended,
];

export const LIVE_SPORTEVENT_STATUSES = [
    SportEventStatus.Live,
    SportEventStatus.Suspended,
];

export const ACTIVE_SPORTEVENT_STATUSES = [
    SportEventStatus.Live,
    SportEventStatus.Suspended,
    SportEventStatus.NotStarted,
];

export const RESULT_SPORT_EVENT_STATUSES = [
    SportEventStatus.Ended,
    SportEventStatus.Closed,
];

export const SCORE_TYPES_TITLE_MAPPING: Record<ScoreTypes, string> = {
    [ScoreTypes.TYPE_TOTAL]: 'Score',
    [ScoreTypes.TYPE_GAME_POINTS]: 'GP',
    [ScoreTypes.TYPE_SET]: 'S',
    [ScoreTypes.TYPE_TIE_BREAK]: 'TBP',
    [ScoreTypes.TYPE_GOLDEN_SET]: 'GS',
    [ScoreTypes.TYPE_YELLOW_CARD]: 'YC',
    [ScoreTypes.TYPE_RED_CARD]: 'RC',
    [ScoreTypes.TYPE_YELLOW_RED_CARD]: 'YRC',
    [ScoreTypes.TYPE_PERIOD_1ST_HALF]: '1H',
    [ScoreTypes.TYPE_PERIOD_1ST_EXTRA]: '1E',
    [ScoreTypes.TYPE_PERIOD_2ND_HALF]: '2H',
    [ScoreTypes.TYPE_PERIOD_2ND_EXTRA]: '2E',
    [ScoreTypes.TYPE_PERIOD_PENALTIES]: 'P',
    [ScoreTypes.TYPE_QUARTER]: 'Q',
    [ScoreTypes.TYPE_QUARTER_OVERTIME]: 'OT',
    [ScoreTypes.TYPE_PERIOD_REGULAR]: 'P',
    [ScoreTypes.TYPE_OVERTIME]: 'OT',
    [ScoreTypes.TYPE_PERIOD_OVERTIME]: 'OVT',
    [ScoreTypes.TYPE_BULLETS]: 'B',
    [ScoreTypes.TYPE_PERIOD_BULLETS]: 'B',
    [ScoreTypes.TYPE_GAME_KILLS]: 'K',
    [ScoreTypes.TYPE_GAME_PLAYERS]: 'Players',
    [ScoreTypes.TYPE_MAP]: 'Map',
    [ScoreTypes.TYPE_ROUND_OVERTIME]: 'OT',
    [ScoreTypes.TYPE_INNING_POINTS]: 'IN',
    [ScoreTypes.TYPE_INNING_OUTS]: 'INO',
};

export enum SidebarTabId {
    Betslip = 'betslip',
    Promo = 'promo',
    BetHistory = 'bet_history',
    Quest = 'quest',
}

export const TRADING_PROVIDER_ID = '13:';

export enum KeyValue {
    FOOTER_PHONES = 'footer-phones',
    MAIN_MIRRORS = 'main_mirrors',
    WHEEL_OF_FORTUNE = 'wheel-of-fortune',
    DE_KYC_DEPOSIT_ENABLED = 'de-kyc-deposit-enabled',
    DE_KYC_SKIPPED_METHODS = 'de-kyc-skipped-methods',
    SHOW_BANNER_ANDROID_APP = 'show_banner_android_app',
    SHOW_BANNER_IOS_APP = 'show_banner_ios_app',
    SHOW_CHEST = 'show_chest',
    SENTRY_CONFIG = 'sentry_config',
    EXCLUDED_GAME_PAGE_SEO_CATEGORIES_IDS = 'excluded_categories_ids',
    SHOW_INVITE_FRIENDS_LINK = 'show_invite_friends_link',
    SHOW_MENU_APP_BUTTON = 'show_menu_app_button',
    EURO_TOURNAMENT_SLUGS = 'euro-tournament-slugs',
    CACHE_OPTIONS = 'cache_options',
    DEFAULT_PLAYER_LIMITS = 'default_player_limits',
}

export enum KYC_STATUS {
    ApprovedFull = 'approved_full',
    ApprovedSimple = 'approved_simple',
    AwaitingApproval = 'awaiting_approval',
    Declined = 'declined',
    Unknown = 'unknown',
}

export const KYC_APPROVED_STATUSES = [
    KYC_STATUS.ApprovedFull,
    KYC_STATUS.ApprovedSimple,
];

export enum OddFormat {
    Decimal = 'Decimal',
    HongKong = 'HongKong',
    Indo = 'Indo',
    Malay = 'Malay',
    Us = 'US',
}

export enum StorageKey {
    DEBUG_INFO = 'debug-info',
    IS_PROD = 'is-prod',
    FEATURE_FLAGS = 'feature-flags',
    BETTING_PINED_SPORT_EVENT_IDS = 'betting-pined-sport-event-ids',
    HEADERS = 'headers',
    SPORT_EVENT_ORDER = 'sport-event-order',
    ANALYTIC = 'app-analytic',
    EURO_ONBOARDING = 'euro-onboarding',
    EURO_TEAM_LIST = 'euro-team-list',
}

export enum WidgetNames {
    REGISTRATION_AGREEMENT_TEXT_BETTING = 'registration-agreement-text-betting',
    REGISTRATION_AGREEMENT_TEXT_CASINO = 'registration-agreement-text-casino',
    REGISTRATION_NEWS_AGREEMENTS = 'registration-checkbox-receive-news',
    REGISTRATION_AGREEMENTS = 'registration-agreements',
    AUTHORIZATION_AGREEMENTS_FOR_THE_GERMANS = 'login-agreement',
    FOOTER_PARTNERS = 'footer-company-partners',
    FOOTER_PROVIDERS = 'footer-casino-providers',
    FOOTER_SOCIAL = 'footer-social',
    FOOTER_CASINO_SOCIAL = 'footer-casino-social',
    FOOTER_SUPPORT_EMAIL = 'footer-support-email',
    FOOTER_PAYMENTS = 'footer-payments',
    FOOTER_CASINO_PAYMENTS = 'footer-casino-payments',
    FOOTER_APP_LINKS = 'footer-app-links',
    FOOTER_PR_BETTING = 'footer-support-betting-pr',
    FOOTER_PR_CASINO = 'footer-support-casino-pr',
    CASINO_WIDGET_LICENCE_TEXT = 'footer-casino-licence-text',
    DEFAULT_WIDGET_LICNCE_TEXT = 'footer-licence-text',
    APP_BANNER_TEXT = 'banner-text',
    EXCEPTION_GAMES = 'exception-games',
    MAIN_APP = 'main-app',
    WHEEL_TERMS = 'wheel-terms',
    EURO_2024 = 'euro-2024',
    EURO_RULES = 'euro-rules',
    EURO_TEAM_ANIMATION_BANNER = 'euro-team-animation-banner',
    EURO_TOURNAMENT_SLUGS = 'euro-tournaments-slugs',
}

export enum SliderNames {
    REGISTRATION_WELCOME_BONUS_BETTING = 'welcome-bonus-registration-betting',
    REGISTRATION_WELCOME_BONUS_CASINO = 'welcome-bonus-registration-casino',
    REGISTRATION_WELCOME_BONUS_HOME = 'welcome-bonus-registration-home',
    REGISTRATION_FINISH_BETTING_SLIDER = 'registration-finish-betting-slider',
    REGISTRATION_FINISH_CASINO_SLIDER = 'registration-finish-casino-slider',
    AUTHORIZATION_BETTING_SLIDER = 'authorization-betting-slider',
    AUTHORIZATION_CASINO_SLIDER = 'authorization-casino-slider',
    PHONE_VERIFICATION_BETTING_SLIDER = 'phone-verification-betting-slider',
    PHONE_VERIFICATION_CASINO_SLIDER = 'phone-verification-casino-slider',
    EMAIL_VERIFICATION_CASINO_SLIDER = 'verification-email-casino-slider',
    EMAIL_VERIFICATION_BETTING_SLIDER = 'verification-email-betting-slider',
    RECOVERY_PASSWORD_BETTING_SLIDER = 'recovery-password-betting-slider',
    RECOVERY_PASSWORD_CASINO_SLIDER = 'recovery-password-casino-slider',
    NEW_PASSWORD_BETTING_SLIDER = 'new-password-betting-slider',
    NEW_PASSWORD_CASINO_SLIDER = 'new-password-casino-slider',
}

export enum ErrorCodes {
    AuthCredentialsExpired = 'auth.credentials.expired',
    AuthSessionUnknown = 'auth.session.unknown',
    AuthUserNotLoggedIn = 'user is not logged in',
    AuthUserNotAccessField = 'You need to be logged to access this field',
}

export const AUTH_SESSION_UNKNOWN_MESSAGES: string[] = [
    ErrorCodes.AuthSessionUnknown,
    ErrorCodes.AuthCredentialsExpired,
    ErrorCodes.AuthUserNotLoggedIn,
    ErrorCodes.AuthUserNotAccessField,
];

export interface IModalData {
    data?: any;
    layoutType: LayoutType;
    isEnabledFirstDepositFlow?: boolean;
}

export const PRODUCT_NAME = 'GGBET.UA' as const;

export enum ApiNames {
    VERIFICATION_EMAIL = '/api/player/confirm-email-success',
    RECOVERY_PASSWORD_BY_EMAIL = '/api/auth/confirm-password-recovery',
    SOCIAL_AUTHORIZATION = '/api/auth/social/login',
}

export const Alpha2CmsCodes: Record<string, string> = {
    'uk-ua': 'UA',
    'en-ca': 'CA',
    'fr-ca': 'FR',
    hu: 'HU',
    ro: 'RO',
    ru: 'RU',
    en: 'GB',
    ja: 'JP',
    cn: 'CN',
    es: 'ES',
    de: 'DE',
    pl: 'PL',
    'tl-ph': 'PH',
    vi: 'VN',
    th: 'TH',
    hi: 'IN',
    pt: 'BR',
};

export const BETTING_LAYOUT_MESSAGES: (keyof Messages)[] = [
    'betting-global',
    'betting-components',
    'betting-bonusProgram',
    'betting-specifiers',
    'BettingBonusProgram',
];

export const CASINO_LAYOUT_MESSAGES: (keyof Messages)[] = [
    'CasinoNavigation',
    'WinnersList',
    'GamesList',
];
export const GAME_LAYOUT_MESSAGES: (keyof Messages)[] = [
    'GamePage',
    'GameFooter',
    'PreGame',
    'GameHeader',
    'WinnersList',
    'PromotionSidebar',
    'RecommendedGames',
    'TournamentSidebar',
];

export const BETTING_SEO_LAYOUT_MESSAGES: (keyof Messages)[] = [
    'betting-global',
    'betting-components',
    'betting-specifiers',
    'betting-filters',
];

export const MAIN_LAYOUT_MESSAGES: (keyof Messages)[] = [
    'General',
    'Footer',
    'HeaderNavigation',
    'MobileNavbar',
    'MobileMenu',
    'betting-global',
];

export const MessagesByLayoutType = {
    [LayoutType.Betting]: BETTING_LAYOUT_MESSAGES,
    [LayoutType.BettingStatic]: [],
    [LayoutType.BettingSeo]: BETTING_SEO_LAYOUT_MESSAGES,
    [LayoutType.Casino]: CASINO_LAYOUT_MESSAGES,
    [LayoutType.Game]: GAME_LAYOUT_MESSAGES,
    [LayoutType.None]: [],
    [LayoutType.CasinoStatic]: [],
};

export enum DynamicPageEntityType {
    FILE = 'file',
    MEDIA = 'media',
    CATEGORY = 'category',
    PAGE = 'page',
    PROMOTION = 'promotion',
    TOURNAMENT = 'tournament',
    NEWS = 'news',
    NEWS_CATEGORY = 'news-category',
    POLL = 'poll',
    LOOTBOX = 'lootbox',
}

export const REGEXP_FOR_HASH = /#([!/a-z-]+)/gi;

export const EMAIL_REGEX =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const NON_RUSSIAN_EMAIL_REGEX =
    /^(?!.*@(?:yandex\.ru|rambler\.ru|mail\.ru|list\.ru|bk\.ru|inbox\.ru|ya\.ru)$).*$/i;

export const DEFAULT_NEXT_LOCALE = 'default' as const;
export const DEFAULT_LOCALE = 'uk-ua' as const;

export const TRANSACTION_ID_SEARCH_NAME = 'transaction_id';

export enum BatchKeys {
    GetWidgetByName = 'getWidgetByName',
    GetKeyValue = 'getKeyValue',
    InitBetting = 'initBetting',
    GetCasinoGames = 'getCasinoGames',
    GetGamesCategories = 'getGamesCategories',
    GetNewsDetails = 'getNewsDetails',
    GetLocaleMenu = 'getLocaleMenu',
    GetCmsBanners = 'getCmsBanners',
    GetPrevNextNews = 'getPrevNextNews',
}

export enum ResponsibleGamingLimitTypes {
    PlayerRequiredLimits = 'player-required-limits',
    Deposit = 'deposit',
    GamingTime = 'gaming-time',
}
