import { WatchQueryFetchPolicy } from '@apollo/client/core/watchQueryOptions';

import { BatchKeys, KeyValue } from 'app-constants';
import { useGetKeyValue } from 'gql/cms/queries/getKeyValue.cms';

interface Input {
    key: KeyValue;
    skip?: boolean;
    fetchPolicy?: WatchQueryFetchPolicy;
}

type Output = { value: string };

const useKeyValue = ({
    key,
    skip = false,
    fetchPolicy = 'cache-and-network',
}: Input): Output => {
    const { data } = useGetKeyValue(() => ({
        fetchPolicy,
        skip,
        variables: {
            key,
            default: '',
        },
        pagePropsKey: `keyValue.${key}`,
        context: {
            batchKey: BatchKeys.GetKeyValue,
        },
    }));

    if (!data?.keyValue) return { value: '' };

    const { value } = data.keyValue;

    return {
        value,
    };
};

export default useKeyValue;
