export function getByKey<T extends object, U extends string>(
    obj: Exclude<T, VoidFunction> | null | undefined,
    key: U
): T extends null | undefined
    ? undefined
    : U extends keyof T
      ? T[U]
      : T[keyof T] | undefined {
    if (!obj) return undefined as any;

    return (obj as any)[key];
}
