import { EffectCallback, useEffect, useRef } from 'react';

import useMount from './useMount';

type NonEmptyDependencyList<T> = Readonly<[T, ...T[]]>;

function useDidUpdate(
    effect: EffectCallback,
    deps?: NonEmptyDependencyList<any>
): void {
    const mount = useRef<boolean>();

    useEffect(() => {
        if (mount.current) return effect();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);

    useMount(() => {
        mount.current = true;
    });
}

export default useDidUpdate;
