import { createContext } from 'react';

export type AppEnvCacheType = {
    APP_ENV: {
        isDevelopment: boolean;
        isProduction: boolean;
    };
};

export const appEnvCache = createContext<AppEnvCacheType>({
    APP_ENV: {
        isDevelopment: true,
        isProduction: false,
    },
});
