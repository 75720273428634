import { FC, memo } from 'react';
import cn from 'classnames';

import Image from 'components/Image';
import SmartLink from 'components/SmartLink';
import { GetServerSidePropsWithMainApolloClient } from 'services/apollo';
import { getServerPageGetSubLogo } from './getSubLogo.cms';
import useSubLogo from './useSubLogo';

const SubLogo: FC = () => {
    const subLogoData = useSubLogo();

    if (!subLogoData?.imageId) return null;

    const { title, link, imageId } = subLogoData;
    const subLogoClassName = cn(
        'relative ml-2 flex h-6 w-auto min-w-[24px] items-center justify-center'
    );

    const image: JSX.Element = (
        <Image
            src={`/file/${imageId}`}
            alt={title}
            title={title}
            unoptimized={true}
            className={cn('!relative !w-auto')}
            fill={true}
            data-test="sub-logo__image"
        />
    );

    if (link) {
        return (
            <SmartLink
                href={link}
                className={subLogoClassName}
                dataTest="sub-logo"
            >
                {image}
            </SmartLink>
        );
    }

    return (
        <div className={subLogoClassName} data-test="sub-logo">
            {image}
        </div>
    );
};

export const getSubLogoServerSideProps: GetServerSidePropsWithMainApolloClient =
    async (ctx) => {
        try {
            const subLogo = await getServerPageGetSubLogo()(ctx);

            return {
                props: {
                    gsspData: { subLogo },
                },
            };
        } catch (e) {
            console.error(e);
        }

        return {
            props: {},
        };
    };

export default memo(SubLogo);
