import { StrictTypedTypePolicies } from 'types/typePolicies.cms';
import { QueryFieldPolicyWithGetFragment, TypePolicies } from '../types';
import nestedOffsetLimitPagination from '../utils/nestedOffsetLimitPagination';

const typePolicies: TypePolicies<StrictTypedTypePolicies> &
    QueryFieldPolicyWithGetFragment = {
    Query: {
        fields: {
            news: nestedOffsetLimitPagination(
                { nestedKeys: 'news', nestedArgs: 'input' },
                ['input', ['title', 'categorySlugs']]
            ),
            games: nestedOffsetLimitPagination({ nestedKeys: 'games' }, [
                'categorySlug',
                'vendorIds',
                'search',
                'filterByThemeSlug',
            ]),
            recommendedGames: nestedOffsetLimitPagination(
                { nestedKeys: 'games' },
                false
            ),
            favoriteGames: nestedOffsetLimitPagination(
                { nestedKeys: 'games' },
                ['vendorIds', 'search']
            ),
            lastPlayedGames: nestedOffsetLimitPagination(
                { nestedKeys: 'games' },
                ['search']
            ),
            gamesByIds: nestedOffsetLimitPagination({ nestedKeys: 'games' }, [
                'ids',
            ]),
            publicBonusPrograms: nestedOffsetLimitPagination(
                { nestedKeys: 'publicBonusPrograms' },
                ['filter', ['category']]
            ),
            bonusProgramsFullArchived: nestedOffsetLimitPagination(
                { nestedKeys: 'list' },
                false
            ),
        },
    },
};

export default typePolicies;
