import { createContext, useContext } from 'react';

import type { ResponsiveLayoutContextType } from './types';

type UseResponsiveType = () => ResponsiveLayoutContextType;

const ResponsiveLayoutContext = createContext<ResponsiveLayoutContextType>({
    is: () => false,
    from: () => false,
    until: () => false,
    between: () => false,
});

export const useResponsive: UseResponsiveType = () => {
    return useContext(ResponsiveLayoutContext);
};

export default ResponsiveLayoutContext;
