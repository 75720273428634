import { ApolloLink, HttpLink, split } from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { WebSocketLink } from '@apollo/client/link/ws';
import { sha256 } from 'crypto-hash';
import { SubscriptionClient } from 'subscriptions-transport-ws';

import makeFetchWithPromMetrics from 'services/prometheus/makeFetchWithPromMetrics';
import { isSsr } from '../../../utils/isSsr';
import makeOTELTraceLink from '../../otel/makeOTELTraceLink';
import { createEndpoint } from '../cms/utils/createEndpoint';
import { makeHeadersLink, makeLoggerLink, uidLink } from '../links';
import ssrOperationAdapterLink from '../links/ssrOperationAdapterLink';
import { getHeadersFromRequest, wsNormalizationLink } from '../utils';
import patchSubscriptionClientForAPQ from './utils/patchSubscriptionClientForAPQ';
import { BettingApolloClientOptions } from './BettingApolloClient';

type WsLinkOptions = Omit<
    ConstructorParameters<typeof WebSocketLink>['0'],
    'uri'
>;

export function makeBettingLink({
    endpoint,
    token,
    scoreboardEndpoint,
    isDebugMode = false,
    req,
}: BettingApolloClientOptions): ApolloLink {
    if (!token || !endpoint || !scoreboardEndpoint) {
        throw new Error(
            '[makeBettingLink] Error! Missing some betting options required for client initialization'
        );
    }

    const persistedQueriesLink = createPersistedQueryLink({ sha256 });

    const links: ApolloLink[] = [
        makeLoggerLink(process.env.NODE_ENV === 'development' || isDebugMode),
        uidLink,
    ];

    const publicGqlEndpoint = `${endpoint}/graphql`;
    const headers = {
        'X-Auth-Token': token,
        ...getHeadersFromRequest(req),
    };

    if (isSsr()) {
        const defaultLinkOptions = {
            fetch: makeFetchWithPromMetrics('betting_apollo_client'),
            uri: createEndpoint({
                endpoint: publicGqlEndpoint,
                protocol: 'http',
            }),
            headers: {
                ...headers,
                // For SSR avoid applying ratelimits defence
                // [GINFE-5861] ToDo: bypass them from envs.
                // [DBS-62] ToDo: provide valid credentials for UA
                // 'X-App-Id': '22',
                // 'X-App-Access-Token': '09930e13e8006cb4946b3a3675235183',
            },
        };
        const batchHttpLink = new BatchHttpLink({
            ...defaultLinkOptions,
            headers: {
                ...headers,
                'X-Batching': 'true',
                // For SSR avoid applying ratelimits defence
                // [GINFE-5861] ToDo: bypass them from envs.
                // [DBS-62] ToDo: provide valid credentials for UA
                // 'X-App-Id': '22',
                // 'X-App-Access-Token': '09930e13e8006cb4946b3a3675235183',
            },
            batchMax: 5,
            batchInterval: 5,
        });

        const httpLink = new HttpLink(defaultLinkOptions);

        links.push(
            ssrOperationAdapterLink,
            makeOTELTraceLink(),
            persistedQueriesLink,
            makeHeadersLink(defaultLinkOptions.headers),
            ApolloLink.split(
                (operation) =>
                    operation.getContext().isScoreboard ||
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    operation.query.definitions[0].operation === 'subscription',
                // NOTE: Avoid scoreboard request on server because it provides only subscriptions operation
                ApolloLink.empty(),
                split(
                    (operation) => !!operation.getContext()?.important,
                    httpLink,
                    batchHttpLink
                )
            )
        );
    } else {
        const wsLinkOptions: WsLinkOptions = {
            reconnect: true,
            lazy: true,
            connectionParams: {
                headers,
            },
        };

        const scoreboardClient = new SubscriptionClient(
            createEndpoint({
                endpoint: `${scoreboardEndpoint}/graphql`,
                protocol: 'ws',
            }),
            wsLinkOptions
        );

        const client = patchSubscriptionClientForAPQ(
            new SubscriptionClient(
                createEndpoint({
                    endpoint: publicGqlEndpoint,
                    protocol: 'ws',
                }),
                wsLinkOptions
            )
        );

        links.push(
            makeHeadersLink(headers),
            wsNormalizationLink,
            ApolloLink.split(
                (operation) => !!operation.getContext()?.isScoreboard,
                new WebSocketLink(scoreboardClient),
                ApolloLink.from([
                    persistedQueriesLink,
                    new WebSocketLink(client),
                ])
            )
        );
    }

    return ApolloLink.from(links);
}
