import { ApolloLink } from '@apollo/client';
import {
    addTypenameToDocument,
    hasAnyDirectives,
    removeClientSetsFromDocument,
} from '@apollo/client/utilities';

const ssrOperationAdapterLink = new ApolloLink((operation, forward) => {
    const hasClientDirectives = hasAnyDirectives(['client'], operation.query);

    if (hasClientDirectives) {
        // NOTE Need mutate to not distract deep operation object
        // eslint-disable-next-line no-param-reassign
        operation.query =
            removeClientSetsFromDocument(operation.query) || operation.query;
    }

    // NOTE Need mutate to not distract deep operation object
    // eslint-disable-next-line no-param-reassign
    operation.query = addTypenameToDocument(operation.query);

    return forward(operation);
});

export default ssrOperationAdapterLink;
