import * as GQL from 'types/gql.ptm';

import * as Operations from './onNewMessages.ptm.gql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'services/apollo/platform';
const defaultOptions = {} as const;

/**
 * __useOnNewMessages__
 *
 * To run a query within a React component, call `useOnNewMessages` and pass it any options that fit your needs.
 * When your component renders, `useOnNewMessages` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnNewMessages({
 *   variables: {
 *   },
 * });
 */
export function useOnNewMessages(
    baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
        GQL.OnNewMessages,
        GQL.OnNewMessagesVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSubscription<
        GQL.OnNewMessages,
        GQL.OnNewMessagesVariables
    >(Operations.OnNewMessages, options);
}
export type OnNewMessagesHookResult = ReturnType<typeof useOnNewMessages>;
export type OnNewMessagesSubscriptionResult =
    Apollo.SubscriptionResult<GQL.OnNewMessages>;
