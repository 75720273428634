import { useEffect, useState } from 'react';

interface Input<T> {
    value: T;
    delay?: number;
}

const useDebouncedValue = <T>({ value, delay = 100 }: Input<T>): T => {
    const [debouncedValue, setDebouncedValue] = useState<T>(value);

    useEffect(() => {
        const timer = setTimeout(() => setDebouncedValue(value), delay);

        return () => {
            clearTimeout(timer);
        };
    }, [value, delay]);

    return debouncedValue;
};

export default useDebouncedValue;
