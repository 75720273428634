import { isSsr } from 'utils/isSsr';

const HOST_TEMPLATE = '{host}';
const SLASHES = '//' as const;

export interface Input {
    endpoint: string;
    host?: string | undefined;
    protocol?: 'http' | 'ws' | undefined;
}

export const createEndpoint = ({ endpoint, host, protocol }: Input): string => {
    let uri = endpoint;

    if (uri.includes(HOST_TEMPLATE)) {
        if (!host) {
            throw new Error(
                'The requested endpoint contains {host} template, but host is undefined'
            );
        }

        uri = uri.replace(HOST_TEMPLATE, host);
    }

    if (!/^(http|ws)/.test(uri)) {
        uri = uri.startsWith(SLASHES) ? uri : `${SLASHES}${uri}`;

        return isSsr() ? `${protocol}:${uri}` : `${protocol}s:${uri}`;
    }

    return uri;
};
