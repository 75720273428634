import type { ScoreTypes, SportEventMetaKey } from 'app-constants';

import type { CategorizerMetaKey } from 'components/betting/Categorizer/constants';
import type { Pair } from 'types/api.bet';

type MetaName = CategorizerMetaKey | ScoreTypes | SportEventMetaKey;

export const getMetaValueByName = (
    meta: Array<Pair> | undefined,
    fieldName: MetaName
): string | undefined => {
    if (!meta) return undefined;

    return meta.find(({ name }) => name === fieldName)?.value;
};
