import { BatchKeys } from 'app-constants';
import { useGetLocaleMenu } from 'gql/cms/queries/getLocaleMenu.cms';
import { useLocales } from 'hooks';
import type { GetLocaleMenu } from 'types/gql.cms';
import { SportListId } from '../types';

interface Input {
    id: SportListId;
}

interface Output {
    sportLinks: NonNullable<GetLocaleMenu['localeMenu']>['items'] | undefined;
}

const useFooterSportLinks = ({ id }: Input): Output => {
    const { activeLocale } = useLocales();
    const { data } = useGetLocaleMenu(() => ({
        variables: {
            id,
            locale: activeLocale,
        },
        context: {
            batchKey: BatchKeys.GetLocaleMenu,
        },
    }));

    return {
        sportLinks: data?.localeMenu?.items,
    };
};

export default useFooterSportLinks;
