import { ComponentType } from 'react';
import hoistNonReactStatic from 'hoist-non-react-statics';

import { isSsr } from 'utils/isSsr';

const withServerRender = <T extends {}>(
    Component: ComponentType<T>,
    serverRender?: (props: T) => React.ReactElement | null
): ((props: T) => JSX.Element | null) => {
    const ComponentWithSsrFlow = (props: T) => {
        if (isSsr()) {
            return typeof serverRender === 'function'
                ? serverRender(props)
                : null;
        }

        return <Component {...props} />;
    };

    hoistNonReactStatic(ComponentWithSsrFlow, Component);

    return ComponentWithSsrFlow;
};

export default withServerRender;
