import { getCookie, hasCookie, setCookie } from 'cookies-next';
import { forEach } from 'lodash';
import type { GetServerSidePropsContext } from 'next';
import { v4 as createUuid } from 'uuid';

import { CookiesType } from 'app-constants';
import createCookieTracker, {
    BackendTrackerName,
    TrackerCookieKey,
    TrackerRequestKey,
} from './createCookieTracker';

interface Input {
    req: GetServerSidePropsContext['req'];
    res: GetServerSidePropsContext['res'];
    query: GetServerSidePropsContext['query'];
}

// TODO: Need research trouble with request middleware cookie!!!!!!!
// Now we can not provide cookies from middleware request to getInitialProps => ctx.req.
// Because of this, we cannot move the tracker logic to middleware

const setCookiesTracker = ({ query, req, res }: Input): void => {
    const isAuth = hasCookie(CookiesType.Paseto, { req });
    const refCode = getCookie(TrackerCookieKey.RefCode, { req });
    const uuid = hasCookie(BackendTrackerName.Uuid, { req });

    const cookiesForTracker = query
        ? createCookieTracker(query as Record<string, string>, isAuth, req)
        : {};

    if (
        ![refCode, cookiesForTracker[TrackerCookieKey.RefCode]].includes(
            TrackerRequestKey.ActionPay
        )
    ) {
        delete cookiesForTracker[TrackerCookieKey.ActionPay];
    }

    if (isAuth) {
        delete cookiesForTracker[TrackerCookieKey.CrmNotification];
    }

    forEach(cookiesForTracker, (value, key) => {
        setCookie(key, value, { req, res, httpOnly: true });
    });

    if (!uuid) {
        setCookie(BackendTrackerName.Uuid, createUuid(), {
            req,
            res,
            httpOnly: true,
        });
    }
};

export default setCookiesTracker;
