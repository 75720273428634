import { ApolloError } from '@apollo/client';

import getServerBettingOptions from '../cms/utils/getServerBettingOptions';
import { WithMainApolloClientContext } from '../types';
import { getBettingInitError } from './utils/getBettingInitError';

interface InjectBettingClientToContext {
    context: WithMainApolloClientContext;
    crmNotificationHeader?: Record<string, string> | null;
}

export const lazyInjectBettingOptionsToContext = async ({
    context,
    crmNotificationHeader,
}: InjectBettingClientToContext): Promise<void> => {
    const bettingOptions = await getServerBettingOptions({
        context,
        headers: crmNotificationHeader || {},
    }).catch((e) => getBettingInitError(e as ApolloError));

    Object.defineProperty(context, 'bettingOptions', {
        enumerable: true,
        configurable: true,
        get() {
            return bettingOptions;
        },
    });
};
