import { IPhone } from 'types';

export const phoneMaskValidate = (
    value: string,
    phoneData: IPhone
): boolean => {
    const intPhoneMin = Number(phoneData.phone.min);
    const intPhoneMax = Number(phoneData.phone.max);

    const unmaskedValue = value ? value.replace(/[^\d+]/g, '') : '';
    const unmaskedMask = phoneData.phone.mask.replace(/[^X]/g, '');

    const intPhoneMinWithoutCode =
        intPhoneMin - (intPhoneMax - unmaskedMask.length);
    const intPhoneMaxWithoutCode =
        intPhoneMax - (intPhoneMax - unmaskedMask.length);

    const rangeOfNumbers =
        intPhoneMinWithoutCode <= unmaskedValue.length &&
        intPhoneMaxWithoutCode >= unmaskedValue.length;
    const validByMask = unmaskedMask.length === value?.length || 0;

    return Boolean(
        intPhoneMinWithoutCode && intPhoneMaxWithoutCode
            ? rangeOfNumbers
            : validByMask
    );
};
