import { ApolloLink, FetchResult } from '@apollo/client';
import { forEach, get, map, pickBy, reduce } from 'lodash';
import traverse, { TraverseContext } from 'traverse';

import { UNIQUE_ID_KEY } from 'app-constants';
import { getArrayValue } from 'utils';
import typePolicies from '../betting/typePolicies';
import { TypePolicyTypeParent } from '../types';
import { joinIdPath } from '../utils';

type ParentConfig = TypePolicyTypeParent<any, string>;

interface IdObject {
    [UNIQUE_ID_KEY]?: string;
    id?: string;
}

// NOTE: Merge type policies from other client here
const typePoliciesWithParents = pickBy(typePolicies, ({ parents }) =>
    Boolean(parents)
);

const uidLink = new ApolloLink((operation, forward) => {
    return forward(operation).map((resultValue: FetchResult) => {
        // NOTE: traverse-js API rely on execution context ("this")
        // eslint-disable-next-line array-callback-return

        traverse(resultValue).forEach(function walk(item) {
            if (!item || !item.__typename) return;

            const typePolicy = typePoliciesWithParents[item.__typename];

            if (!typePolicy) return;

            // TODO: Optimize to early bail out
            forEach(typePolicy.parents, (parentConfig) => {
                const config = normalizationConfig(parentConfig as any);
                const parent = findParent(this, config.typename);

                if (!parent) return;

                attachUniqueId(this, parent, config);
            });
        });

        return resultValue;
    });
});

const normalizationConfig = (
    parentConfig: string | ParentConfig
): ParentConfig => {
    return (
        typeof parentConfig === 'string'
            ? {
                  typename: parentConfig,
                  nodePath: '',
                  injectParentTypename: false,
              }
            : parentConfig
    ) as ParentConfig;
};

function attachUniqueId(
    item: TraverseContext,
    parent: TraverseContext,
    config: ParentConfig
): void {
    if ('genPath' in config) {
        item.update({
            ...item.node,
            [UNIQUE_ID_KEY]: config.genPath(item),
        });

        return;
    }

    const uid = makeUniqueId(
        item.node,
        makeNodePath(config.nodePath, parent),
        config
    );

    item.update({
        ...item.node,
        [UNIQUE_ID_KEY]: uid,
    });
}

const makeNodePath = (
    nodePath: string | string[],
    parent: TraverseContext
): IdObject[] => {
    return nodePath
        ? map(getArrayValue(nodePath), (path) => get(parent.node, path))
        : getArrayValue(parent.node);
};

function findParent(
    item: TraverseContext,
    parentTypename: string
): TraverseContext | null {
    if (item.isRoot || !item.parent) return null;

    if (item.node.__typename === parentTypename) return item;

    return findParent(item.parent, parentTypename);
}

function makeUniqueId(
    value: IdObject,
    parents: IdObject[],
    config: ParentConfig
): string {
    const args = reduce<IdObject, string[]>(
        [...parents, value],
        (acc, parent) => {
            const id = parent[UNIQUE_ID_KEY] || parent.id;

            if (!id) {
                console.error(
                    'Error! Failed to generate unique id for combination:',
                    value,
                    parent
                );

                return acc;
            }

            acc.push(id);

            return acc;
        },
        []
    );

    if (config.injectParentTypename) {
        args.unshift(config.typename);
    }

    return joinIdPath(args);
}

export default uidLink;
