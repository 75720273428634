import { FC } from 'react';
import { useReactiveVar } from '@apollo/client';

import withClientMessages from 'hocs/withClientMessages';
import { useClickOutside } from 'hooks';
import { Message } from 'types/gql.cms';
import InboxCenterMessage from '../../InboxCenterMessage';
import InboxCenterProgress from '../../InboxCenterProgress';
import { isPreviewAnimatingVar } from '../../inboxCenterState';
import useInboxCenterPreviewMessage from './useInboxCenterPreviewMessage';

interface Props {
    message: Message;
    handleMarkRead: (message: Message) => void;
}

const InboxCenterPreviewMessage: FC<Props> = ({ message, handleMarkRead }) => {
    const { openMessageHandler, closeMessageHandler } =
        useInboxCenterPreviewMessage({ handleMarkRead, message });

    const isPreviewAnimating = useReactiveVar(isPreviewAnimatingVar);

    const closeMessageOutsideAfterShowing = () => {
        if (!isPreviewAnimating) return;

        closeMessageHandler();
    };

    const clickOutsideRef = useClickOutside(closeMessageOutsideAfterShowing);

    return (
        <div ref={clickOutsideRef}>
            <InboxCenterMessage
                message={message}
                isPreview={true}
                maxSubtitleHeight={32}
                handleMessageClose={closeMessageHandler}
                handleMessageClick={openMessageHandler}
            />
            <InboxCenterProgress
                handleMarkRead={handleMarkRead}
                message={message}
            />
        </div>
    );
};

export default withClientMessages(InboxCenterPreviewMessage, ['InboxCenter']);
