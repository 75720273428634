import { useReportWebVitals as reportWebVitals } from 'next/web-vitals';

import { isDebugEnabled } from 'components/betting/DebugInfo';
import { logMetrics } from 'services/logger';

const useReportWebVitals = (): void => {
    if (!isDebugEnabled()) return;

    reportWebVitals((metric) => {
        const { name, value } = metric;

        switch (name) {
            case 'FCP': {
                return logMetrics('FCP:', value);
            }

            case 'TTFB': {
                return logMetrics('TTFB:', value);
            }

            case 'FID': {
                return logMetrics('FID:', value);
            }

            case 'Next.js-hydration': {
                return logMetrics('Hydration duration::', value);
            }

            case 'LCP': {
                return logMetrics('LCP:', value);
            }

            case 'Next.js-route-change-to-render': {
                return logMetrics('Change route duration::', value);
            }

            case 'Next.js-render': {
                return logMetrics('Render duration:', value);
            }

            default:
        }
    });
};

export default useReportWebVitals;
