import { FC, memo, PropsWithChildren } from 'react';

import { EnableSSRContext } from './EnableSSRContext';

interface Props extends PropsWithChildren {
    isSSREnabled: boolean;
}

const EnableSSRProvider: FC<Props> = ({ isSSREnabled, children }) => {
    return (
        <EnableSSRContext.Provider value={isSSREnabled}>
            {children}
        </EnableSSRContext.Provider>
    );
};

export default memo(EnableSSRProvider);
