import { SyntheticEvent, useEffect, useState } from 'react';
import copy from 'copy-to-clipboard';

interface Output {
    onClickHandler: (event: SyntheticEvent) => void;
    isCopied: boolean;
}

interface Input {
    text: string | undefined;
}

const useDebugInfo = ({ text }: Input): Output => {
    const [isCopied, setIsCopied] = useState<boolean>(false);

    useEffect(() => {
        if (!isCopied) return;

        const timeout = setTimeout(() => {
            setIsCopied(false);
        }, 1000);

        return () => clearTimeout(timeout);
    }, [isCopied]);

    const onClickHandler = (event: SyntheticEvent) => {
        event.preventDefault();

        if (!text) return;

        if (/^([0-9]:)/.test(text)) {
            const copyText = text.split(':').pop();

            if (!copyText) return;

            copy(copyText);
        } else {
            copy(text);
        }

        setIsCopied(true);
    };

    return {
        isCopied,
        onClickHandler,
    };
};

export default useDebugInfo;
