import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { getCookie } from 'cookies-next';
import type { BettingOptions } from 'types';

import { StorageKey } from 'app-constants';
import getBettingSettings from 'gql/betting/queries/getBettingSettings.cms.gql';
import { usePagePropsContext } from 'layouts/Root/PagePropsContext';
import type {
    GetBettingData,
    GetBettingDataVariables,
    GetBettingSettings,
    GetBettingSettingsVariables,
} from 'types/gql.cms';
import { useCmsApolloClient } from '../cms';
import { getBettingOptionsResult } from '../utils';
import {
    BettingInitError,
    getBettingInitError,
} from './utils/getBettingInitError';

import getBettingData from '../cms/utils/getBettingData.cms.gql';

type OmittedBettingOptions = Omit<BettingOptions, 'features'>;
interface Input {
    shouldInitClient: boolean;
}

interface Output {
    bettingInitializationError: BettingInitError | undefined;
    bettingOptions: OmittedBettingOptions;
}
const useGetBettingOptions = ({ shouldInitClient }: Input): Output => {
    const { client } = useCmsApolloClient();

    const { bettingClientOptions, bettingInitError, sportIds } =
        usePagePropsContext();

    const [bettingInitializationError, setBettingInitializationError] =
        useState<BettingInitError | undefined>(bettingInitError);

    const [bettingOptions, setBettingOptions] = useState<OmittedBettingOptions>(
        {
            sportIds,
            bettingClientOptions,
        }
    );

    const isSkipRequestOptions = !!(
        bettingClientOptions ||
        !shouldInitClient ||
        bettingInitError
    );

    const [getBettingDataLazy] = useLazyQuery<
        GetBettingData,
        GetBettingDataVariables
    >(getBettingData, { client });
    const [getBettingSettingsLazy] = useLazyQuery<
        GetBettingSettings,
        GetBettingSettingsVariables
    >(getBettingSettings, { client });

    useEffect(() => {
        if (isSkipRequestOptions) {
            return;
        }

        const isDebugMode = !!getCookie(StorageKey.DEBUG_INFO);

        Promise.all([getBettingDataLazy(), getBettingSettingsLazy()])
            .then(([{ data: bettingData }, { data: bettingSettings }]) => {
                if (!bettingData || !bettingSettings) return;

                setBettingOptions(
                    getBettingOptionsResult(
                        bettingData,
                        bettingSettings,
                        isDebugMode
                    )
                );
            })
            .catch((err) => {
                const error = getBettingInitError(err);

                setBettingInitializationError(error);

                console.error(err);
            });
    }, [getBettingDataLazy, getBettingSettingsLazy, isSkipRequestOptions]);

    return {
        bettingOptions,
        bettingInitializationError,
    };
};

export default useGetBettingOptions;
