const pino = require('pino');
// https://getpino.io/#/docs/help?id=mapping-pino-log-levels-to-google-cloud-logging-stackdriver-severity-levels
// https://cloud.google.com/logging/docs/reference/v2/rest/v2/LogEntry#logseverity
const PinoLevelToSeverityLookup = {
    trace: 'DEBUG',
    debug: 'DEBUG',
    info: 'INFO',
    warn: 'WARNING',
    error: 'ERROR',
    fatal: 'CRITICAL',
};

const logger = (defaultConfig) =>
    pino({
        ...defaultConfig,
        messageKey: 'message',
        timestamp: () =>
            `,"created_at":"${new Date(Date.now()).toISOString()}"`,
        formatters: {
            level(label, number) {
                return {
                    severity:
                        PinoLevelToSeverityLookup[label] ||
                        PinoLevelToSeverityLookup['info'],
                    level: number,
                };
            },
            // disable not necessary bindings
            bindings: (bindings) => {
                delete bindings.hostname;
                delete bindings.pid;

                return bindings;
            },
        },
    });

module.exports = { logger };
