import { createContext, useContext } from 'react';
import { noop } from 'lodash';

interface MessagesContextType {
    setMessages: Function;
    messageKeys: IntlMessagesNamespace[];
}
type UseMessagesContextType = () => MessagesContextType;

const MessagesContext = createContext<MessagesContextType>({
    setMessages: noop,
    messageKeys: [],
});

export const useMessages: UseMessagesContextType = () => {
    return useContext(MessagesContext);
};

export default MessagesContext;
