import { fillLink } from 'cl-fill-link';

import { AppLink, ModalsHash } from 'app-constants';
import { LinkOptions } from '../types';

export const linksWithCheckedOptions = ({
    links,
    options,
}: {
    links: LinkOptions[];
    options: { isAuthorized: boolean };
}): LinkOptions[] => {
    const { isAuthorized } = options;

    return links.map((menuLink) => {
        if (!menuLink.onlyForAuthorized) return menuLink;

        return {
            ...menuLink,
            link: isAuthorized ? menuLink.link : ModalsHash.Authorization,
        };
    });
};

export function getInitialMenuLinksWithTournament(
    links: LinkOptions[],
    activeTournamentSlug?: string
): LinkOptions[] {
    if (activeTournamentSlug) {
        return links.map((item) =>
            item.textKey === 'tournament'
                ? {
                      ...item,
                      link: fillLink(AppLink.BETTINGTOURNAMENTS_SLUG, {
                          slug: activeTournamentSlug,
                      }),
                  }
                : item
        );
    }

    return links.filter((item) => item.textKey !== 'tournament');
}

export function getElementWidth(element?: Element): number {
    return element?.getBoundingClientRect().width || 0;
}

export function getChildNodesWidth(element: HTMLElement): number[] {
    const offsetWidthArray: number[] = [];

    element?.childNodes.forEach((childNode) => {
        if (childNode.nodeType !== Node.ELEMENT_NODE) return;

        const childElement = childNode as HTMLElement;
        offsetWidthArray.push(getElementWidth(childElement));
    });

    return offsetWidthArray;
}

export function getMenuLinksCount(
    menuLinksWidth: number[],
    difference: number
): number {
    let menuLinksCount = 0;
    let sum = 0;

    for (let i = 0; i < menuLinksWidth.length; i += 1) {
        if (sum + menuLinksWidth[i] > difference) break;

        menuLinksCount += 1;
        sum += menuLinksWidth[i];
    }

    return menuLinksCount;
}
