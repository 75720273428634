import type { BettingOptions } from 'types';

import { GetBettingData, GetBettingSettings } from 'types/gql.cms';
import { BettingApolloClientOptions } from '../betting/BettingApolloClient';

const getBettingOptionsResult = (
    bettingData: GetBettingData,
    bettingSettings: GetBettingSettings,
    isDebugMode: boolean
): BettingOptions => {
    const { endpoint, token } = bettingData.bettingData;

    const { scoreboardEndpoint, sports: sportsList } =
        bettingSettings.bettingSettings;

    const bettingClientOptions: BettingApolloClientOptions = {
        token,
        endpoint,
        scoreboardEndpoint,
        isDebugMode,
    };

    const sportIds = sportsList.map(({ id }) => id);

    return {
        bettingClientOptions,
        sportIds,
    };
};

export default getBettingOptionsResult;
