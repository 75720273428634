import { useCallback, useEffect, useRef, useState } from 'react';

import { useGetArchivedBonusProgram } from 'gql/cms/queries/bonus-program/getArchivedBonusProgram.cms';
import { GetArchivedBonusProgram } from 'types/gql.cms';
import useAuthorization from './useAuthorization';

interface Input {
    category: 'betting' | 'casino';
    displayIn?: string;
    limit?: number;
    offset?: number;
    step?: number;
}

export interface Output {
    loading: boolean;
    archivedBonusPrograms: GetArchivedBonusProgram['bonusProgramsFullArchived']['list'];
    refetch: ReturnType<typeof useGetArchivedBonusProgram>['refetch'];
    loadMoreArchivedBonusProgramHandler: VoidFunction;
    isFetchMoreArchivedBonusProgram: boolean;
    isLoadedAllArchivedBonusProgram: boolean;
}

const useArchivedBonusProgram = ({
    category,
    displayIn = 'profile',
    limit = 10,
    offset = 0,
    step = limit,
}: Input): Output => {
    const { isRegistrationFinished } = useAuthorization();

    const [
        isFetchMoreArchivedBonusProgram,
        setIsFetchMoreArchivedBonusProgram,
    ] = useState<boolean>(false);
    const stepArchivedBonusProgram = useRef<number>(limit);
    const countArchivedBonusProgram = useRef<number>(0);
    const [
        isLoadedAllArchivedBonusProgram,
        setIsLoadedAllArchivedBonusProgram,
    ] = useState<boolean>(false);

    const { data, refetch, loading, fetchMore } = useGetArchivedBonusProgram(
        () => ({
            variables: {
                filter: {
                    category,
                    displayInType: displayIn,
                },
                limit,
                offset,
            },
            fetchPolicy: 'cache-and-network',
            skip: !isRegistrationFinished,
        })
    );

    useEffect(() => {
        const count = data?.bonusProgramsFullArchived.list.length || 0;
        const nextOffset = data?.bonusProgramsFullArchived.nextOffset || 0;

        if (count >= stepArchivedBonusProgram.current && nextOffset > count) {
            setIsLoadedAllArchivedBonusProgram(false);
        } else {
            setIsLoadedAllArchivedBonusProgram(true);
        }
    }, [data]);

    const loadMoreArchivedBonusProgramHandler = useCallback(async () => {
        if (!data?.bonusProgramsFullArchived.nextOffset) return;

        setIsFetchMoreArchivedBonusProgram(true);
        stepArchivedBonusProgram.current += step;

        const response = await fetchMore({
            variables: {
                filter: {
                    category,
                    displayInType: displayIn,
                },
                limit: step,
                offset: data.bonusProgramsFullArchived.nextOffset,
            },
        });
        countArchivedBonusProgram.current =
            response?.data?.bonusProgramsFullArchived.list.length || 0;

        if (
            countArchivedBonusProgram.current < stepArchivedBonusProgram.current
        ) {
            setIsLoadedAllArchivedBonusProgram(true);
        }

        setIsFetchMoreArchivedBonusProgram(false);
    }, [category, data, displayIn, fetchMore, step]);

    return {
        loading: loading || (!loading && !data),
        archivedBonusPrograms: data?.bonusProgramsFullArchived.list || [],
        refetch,
        loadMoreArchivedBonusProgramHandler,
        isFetchMoreArchivedBonusProgram,
        isLoadedAllArchivedBonusProgram,
    };
};

export default useArchivedBonusProgram;
