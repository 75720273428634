import { useEffect } from 'react';

import {
    AppLink,
    LayoutType,
    MainLayoutType,
    ModalsHash,
    REGEXP_FOR_HASH,
} from 'app-constants';
import { useRouter } from 'components/HybridRouter';
import { useGetParticipantFeatures } from 'gql/cms/queries/getPartisipantFeatures.cms';
import { useAuthorization } from 'hooks';
import { useLayoutContext } from 'layouts/Root/LayoutTypeContext';
import { Feature, useFeatureFlags } from 'services/features';
import {
    formatFeaturesData,
    participantFeaturesState,
} from 'services/GoogleAnalytics';
import { GetProfile } from 'types/gql.cms';
import { setOffline, setOnline } from './isOnlineVar';
import { useResponsive } from './ResponsiveLayoutContext';

interface Output {
    isBlur: boolean;
    isMobile: boolean;
    isAuthorized: boolean;
    isBonusProgram: boolean;
    isMobileGameLayout: boolean;
    layoutType: LayoutType;
    mainLayoutType: MainLayoutType;
    profileInfo?: GetProfile;
}

const useMainLayout = (): Output => {
    const { asPath, query, pathname } = useRouter();
    const { layoutType, mainLayoutType } = useLayoutContext();
    const { isAuthorized, profileInfo } = useAuthorization();
    const { features } = useFeatureFlags();
    const { until } = useResponsive();
    const isMobile = until('md');

    const isMobileGameLayout = layoutType === LayoutType.Game && isMobile;
    const hash = asPath.match(REGEXP_FOR_HASH)?.[0];
    const isBlur = hash === ModalsHash.Notifications;
    const isBonusProgram =
        (layoutType === LayoutType.Betting || pathname === AppLink.ROOT) &&
        isMobile &&
        features.isEnabled(Feature.BonusProgram) &&
        query.isDailyQuest === 'true';

    useGetParticipantFeatures(() => ({
        fetchPolicy: 'cache-only',
        onCompleted: ({ featureFlags = [] }) => {
            if (!featureFlags.length) return;

            participantFeaturesState(formatFeaturesData(featureFlags));
        },
    }));

    useEffect(() => {
        window.addEventListener('online', setOnline);
        window.addEventListener('offline', setOffline);

        return () => {
            window.removeEventListener('online', setOnline);
            window.removeEventListener('offline', setOffline);
        };
    }, []);

    return {
        isBlur,
        isMobile,
        isAuthorized,
        isBonusProgram,
        layoutType,
        mainLayoutType,
        isMobileGameLayout,
        profileInfo,
    };
};

export default useMainLayout;
