import { makeVar } from '@apollo/client';

export const isHeaderPinned = makeVar(true);

export interface HeaderOffsets {
    containerOffset: number;
    logoOffset: number;
    betSettingsOffset: number;
    controlsOffset: number;
}

const initialHeaderOffsets: HeaderOffsets = {
    containerOffset: 0,
    logoOffset: 0,
    betSettingsOffset: 0,
    controlsOffset: 0,
};

export const headerOffsetsVar = makeVar<HeaderOffsets>(initialHeaderOffsets);
