import { has } from 'lodash';

import { SportType } from 'app-constants';
import { Sports } from 'components/betting/Categorizer/constants';

const colorMap: Record<string, string> = {
    [Sports.BASKETBALL]: 'from-yellow-500',
    [Sports.TENNIS]: 'from-khaki-500',
    [Sports.FOOTBALL]: 'from-green-700',
    [Sports.ICE_HOCKEY]: 'from-turquoise-500',
    [SportType.Esports]: 'from-[#FF4800]',
    [SportType.Sports]: 'from-violet-500',
    BannerBettingSportEvent: 'from-primary-orange-toxic',
    SportEvent: 'bg-surface-middle',
};

export const getBackgroundColor = (
    sportId: string,
    sportType: SportType,
    typeName?: 'BannerBettingSportEvent' | 'SportEvent'
): string => {
    if (typeName === 'SportEvent') {
        return colorMap[typeName];
    }

    const isExistSportId = has(colorMap, sportId);

    if (isExistSportId) {
        return colorMap[sportId];
    }

    // TODO: remove this check after unifying the colors in the design
    if (
        typeName === 'BannerBettingSportEvent' &&
        sportType === SportType.Esports
    ) {
        return colorMap[typeName];
    }

    return colorMap[sportType];
};
