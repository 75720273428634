import React, { PropsWithChildren } from 'react';

import { withClientRender } from 'hocs';

interface Props extends PropsWithChildren {}

const ClientRender: React.FC<Props> = ({ children }) => {
    return <>{children}</>;
};

export default withClientRender(ClientRender);
