import { useCallback, useEffect, useRef, useState } from 'react';

import { POLLING_INTERVAL } from 'app-constants';

interface Input {
    poller: Function;
    interval?: number;
    incrementByTick?: number;
}

interface Output {
    stopPolling: Function;
    startPolling: Function;
}

const usePolling = ({
    poller,
    interval = POLLING_INTERVAL,
    incrementByTick = 0,
}: Input): Output => {
    const [isPolling, setIsPolling] = useState<boolean>(false);
    const [incrementalInterval, setIncrementalInterval] =
        useState<number>(interval);

    const intervalRef = useRef<number | null>(null);
    const pollerRef = useRef<Function>(poller);

    useEffect(() => {
        pollerRef.current = poller;
    }, [poller]);

    useEffect(() => {
        if (isPolling) {
            if (incrementByTick) {
                intervalRef.current = window.setTimeout(() => {
                    pollerRef.current();
                    setIncrementalInterval((p) => p + incrementByTick);
                }, incrementalInterval);

                return;
            }

            intervalRef.current = window.setInterval(() => {
                pollerRef.current();
            }, incrementalInterval);

            return;
        }

        if (!intervalRef.current) return;

        clearInterval(intervalRef.current);
    }, [incrementByTick, incrementalInterval, isPolling]);

    useEffect(
        () => () => {
            if (!intervalRef.current) return;

            clearInterval(intervalRef.current);
        },
        []
    );

    const startPolling = useCallback(() => {
        setIsPolling(true);
    }, []);

    const stopPolling = useCallback(() => {
        setIsPolling(false);

        if (incrementByTick) {
            setIncrementalInterval(incrementByTick);
        }
    }, [incrementByTick]);

    return {
        startPolling,
        stopPolling,
    };
};

export default usePolling;
