import { omit } from 'lodash';

import { AppLink } from 'app-constants';

export enum Sports {
    NASCAR = 'nascar',
    FOOTBALL = 'football',
    BASKETBALL = 'basketball',
    INDOOR_SOCCER = 'indoor_soccer',
    TENNIS = 'tennis',
    ICE_HOCKEY = 'ice_hockey',
    VOLLEYBALL = 'volleyball',
    BASEBALL = 'baseball',
    BEACH_VOLLEYBALL = 'beach_volleyball',
    BOXING = 'boxing',
    FUTSAL = 'futsal',
    HANDBALL = 'handball',
    MMA = 'mma',
    SNOOKER = 'snooker',
    MOTORSPORT = 'motorsport',
    AMERICAN_FOOTBALL = 'american_football',
    BEACH_SOCCER = 'beach_soccer',
    AUSTRALIAN_RULES = 'australian_rules',
    CHESS = 'chess',
    DARTS = 'darts',
    RUGBY_LEAGUE = 'rugby_league',
    RUGBY = 'rugby',
    SPECIALS = 'specials',
    TABLE_TENNIS = 'table_tennis',
    WATER_POLO = 'water_polo',
    CYCLING = 'cycling',
    BADMINTON = 'badminton',
    FORMULA_1 = 'formula_1',
    BIATHLON = 'biathlon',
    BANDY = 'bandy',
    CROSS_COUNTRY = 'cross_country',
    SKI_JUMPING = 'ski_jumping',
    ALPINE_SKIING = 'alpine_skiing',
    CURLING = 'curling',
    HOCKEY = 'hockey',
    OLYMPICS = 'olympics',
    WRESTLING = 'wrestling',
    ROWING = 'rowing',
    CANOE_SLALOM = 'canoe_slalom',
    CANOE_SPRINT = 'canoe_sprint',
    JUDO = 'judo',
    KARATE = 'karate',
    EQUESTRIAN = 'equestrian',
    SAILING = 'sailing',
    SWIMMING = 'swimming',
    DIVING = 'diving',
    TRAMPOLINE = 'trampoline',
    SURFING = 'surfing',
    SYNCHRONIZED_SWIMMING = 'synchronized_swimming',
    SKATEBOARDING = 'skateboarding',
    MODERN_PENTATHLON = 'modern_pentathlon',
    SOFTBALL = 'softball',
    ARTISTIC_GYMNASTICS = 'artistic_gymnastics',
    SPORT_CLIMBING = 'sport_climbing',
    SHOOTING = 'shooting',
    ARCHERY = 'archery',
    TRIATHLON = 'triathlon',
    TAEKWONDO = 'taekwondo',
    WEIGHTLIFTING = 'weightlifting',
    FENCING = 'fencing',
    RHYTHMIC_GYMNASTICS = 'rhythmic_gymnastics',
    RUGBY_SEVENS = 'rugby_sevens',
    ATHLETICS = 'athletics',
    GOLF = 'golf',
    CANOEING = 'canoeing',
    BASKETBALL_3X3 = 'basketball_3x3',
    ESPORTS_ARTIFACT = 'esports_artifact',
    ESPORTS_DOTA_AUTO_CHESS = 'esports_dota_auto_chess',
    ESPORTS_DOTA_UNDERLORDS = 'esports_dota_underlords',
    ESPORTS_FORMULA_1 = 'esports_formula_1',
    ESPORTS_ICE_HOCKEY = 'esports_ice_hockey',
    ESPORTS_MORTAL_KOMBAT = 'esports_mortal_kombat',
    ESPORTS_RACING = 'esports_racing',
    ESPORTS_SPECIALS = 'esports_specials',
    ESPORTS_TENNIS = 'esports_tennis',
    ESPORTS_BOXING = 'esports_boxing',
    ESPORTS_VOLLEYBALL = 'esports_volleyball',
    ESPORTS_LEAGUE_OF_LEGENDS_WILD_RIFT = 'esports_league_of_legends_wild_rift',
    ESPORTS_CALL_OF_DUTY = 'esports_call_of_duty',
    ESPORTS_COUNTER_STRIKE = 'esports_counter_strike',
    ESPORTS_DOTA_2 = 'esports_dota_2',
    ESPORTS_HEARTHSTONE = 'esports_hearthstone',
    ESPORTS_HEROES_OF_THE_STORM = 'esports_heroes_of_the_storm',
    ESPORTS_LEAGUE_OF_LEGENDS = 'esports_league_of_legends',
    ESPORTS_OVERWATCH = 'esports_overwatch',
    ESPORTS_STARCRAFT = 'esports_starcraft',
    ESPORTS_WORLD_OF_TANKS = 'esports_world_of_tanks',
    ESPORTS_STREET_FIGHTER_5 = 'esports_street_fighter_5',
    ESPORTS_VAINGLORY = 'esports_vainglory',
    ESPORTS_WARCRAFT_3 = 'esports_warcraft_3',
    ESPORTS_RAINBOW_SIX = 'esports_rainbow_six',
    ESPORTS_ROCKET_LEAGUE = 'esports_rocket_league',
    ESPORTS_SMITE = 'esports_smite',
    ESPORTS_SOCCER_MYTHICAL = 'esports_soccer_mythical',
    ESPORTS_HALO = 'esports_halo',
    ESPORTS_CROSSFIRE = 'esports_crossfire',
    ESPORTS_BATTLEGROUNDS = 'esports_battlegrounds',
    ESPORTS_FIFA = 'esports_fifa',
    ESPORTS_STARCRAFT_1 = 'esports_starcraft_1',
    ESPORTS_KING_OF_GLORY = 'esports_king_of_glory',
    ESPORTS_NBA_2K18 = 'esports_nba_2k18',
    ESPORTS_APEX_LEGENDS = 'esports_apex_legends',
    ESPORTS_FORTNITE = 'esports_fortnite',
    ESPORTS_BASKETBALL = 'esports_basketball',
    ESPORTS_VALORANT = 'esports_valorant',
    ESPORTS_CALL_OF_DUTY_WARZONE = 'esports_call_of_duty_warzone',
    ESPORTS_MOBILE_LEGENDS_BANG_BANG = 'esports_mobile_legends_bang_bang',
    ESPORTS_CLASH_ROYALE = 'esports_clash_royale',
    ESPORTS_AGE_OF_EMPIRES = 'esports_age_of_empires',
    ESPORTS_BRAWL_STARS = 'esports_brawl_stars',
    ESPORTS_FREE_FIRE = 'esports_free_fire',
    ESPORTS_QUAKE = 'esports_quake',
    ESPORTS_WORLD_OF_WARCRAFT = 'esports_world_of_warcraft',
    ESPORTS_CALL_OF_DUTY_MOBILE = 'esports_call_of_duty_mobile',
    ESPORTS_BASEBALL = 'esports_baseball',
    HORSE_RACING = 'horse_racing',
    TRAMPOLINE_GYMNASTICS = 'trampoline_gymnastics',
    CRICKET = 'cricket',
    ESPORTS_VOLTA_FOOTBALL = 'esports_volta_football',
    WORLD_LOTTERY = 'world_lottery',
    ESPORTS_STREETBALL = 'esports_streetball',
    ESPORTS_UFC = 'esports_ufc',
    KABADDI = 'kabaddi',
    ESPORTS_CRICKET = 'esports_cricket',
    ESPORTS_OSU = 'esports_osu',
    ESPORTS_TEAMFIGHT_TACTICS = 'esports_teamfight_tactics',
    LACROSSE = 'lacrosse',
    ESPORTS_DRONE_RACING = 'esports_drone_racing',
    SHORT_TRACK_SPEED_SKATING = 'short_track_speed_skating',
    SPEED_SKATING = 'speed_skating',
    SKELETON = 'skeleton',
    SNOWBOARD = 'snowboard',
    NORDIC_COMBINED = 'nordic_combined',
    LUGE = 'luge',
    FIGURE_SKATING = 'figure_skating',
    FREESTYLE_SKIING = 'freestyle_skiing',
    BOBSLEIGH = 'bobsleigh',
    BOWLS = 'bowls',
    SQUASH = 'squash',
    FLOORBALL = 'floorball',
    RUGBY_UNION = 'rugby_union',
}

export enum CategorizerMetaKey {
    CountryCode = 'country_code',
    Logo = 'logo',
    Slug = 'slug',
    OrganizationLocalized = 'organization_localized',
    OrganizationLogo = 'organization_logo',
    IsTop = 'is_top',
    LiveEventsCount = 'live_events_count',
    OutrightCount = 'outright_type_events_count',
    PrematchEventCount = 'prematch_events_count',
    MatchTypeEventsCount = 'match_type_events_count',
}

export enum AlignsForCategorizerRow {
    CENTER = 'center',
    START = 'start',
}

export const UNKNOWN = 'unknown' as const;

export const RoutingMap: Record<string, AppLink> = {
    [AppLink.ROOT]: AppLink.ROOT,
    [AppLink.BETS]: AppLink.BETS,
    [AppLink.NEXTTOGO]: AppLink.NEXTTOGO,
    [AppLink.LIVE]: AppLink.LIVE,
    [AppLink.RESULTS]: AppLink.RESULTS,
    [AppLink.ESPORTS_RESULT_SLUG]: AppLink.RESULTS,
    [AppLink.SPORTS_RESULT_SLUG]: AppLink.RESULTS,
    [AppLink.OUTRIGHTS]: AppLink.OUTRIGHTS,
    [AppLink.SPORTS_OUTRIGHT_SLUG]: AppLink.SPORTS_OUTRIGHT_SLUG,
    [AppLink.ESPORTS_OUTRIGHT_SLUG]: AppLink.ESPORTS_OUTRIGHT_SLUG,
    [AppLink.SPORTS]: AppLink.SPORTS,
    [AppLink.ESPORTS]: AppLink.ESPORTS,
};
export const MobileCategorizerRoutingMap: Record<string, AppLink> = omit(
    RoutingMap,
    [
        AppLink.OUTRIGHTS,
        AppLink.ESPORTS_OUTRIGHT_SLUG,
        AppLink.SPORTS_OUTRIGHT_SLUG,
        AppLink.ESPORTS_RESULT_SLUG,
        AppLink.SPORTS_RESULT_SLUG,
    ]
);

export enum AnalyticLabel {
    Sport = "parent: 'sport'",
    Subgroup = "parent: 'subgroup'",
}

export const ORDER_CATEGORIZER_ITEMS_MAPPING: Record<string, string[]> = {
    [Sports.FOOTBALL]: ['UA', 'WW-EUR', 'GB-ENG', 'ES', 'DE', 'IT', 'FR', 'RU'],
    [Sports.BASKETBALL]: ['US', 'CN', 'RU', 'ES', 'DE', 'IT', 'FR', 'PH', 'UA'],
    [Sports.ICE_HOCKEY]: ['US', 'RU', 'FI', 'SE', 'CH', 'BY', 'KZ', 'UA'],
    [Sports.VOLLEYBALL]: ['US', 'ES', 'IT', 'TR', 'PL', 'RU'],
    [Sports.TABLE_TENNIS]: ['RU', 'UA'],
    [Sports.HANDBALL]: ['DE', 'ES', 'FR', 'DK', 'RU', 'NO', 'HU', 'PL', 'UA'],
};
