import { useGetActiveMessages } from 'components/BroadcastBanner/getActiveMessages.cms';

const useIsRenderBroadcastBanner = (): boolean => {
    const { data } = useGetActiveMessages(() => ({
        pagePropsKey: 'activeMessages',
    }));

    return !!data?.activeMessages.length;
};

export default useIsRenderBroadcastBanner;
