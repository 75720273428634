import { FC } from 'react';
import cn from 'classnames';

import { WidgetNames } from 'app-constants';
import { useIsEnableSSR } from 'layouts/Root/EnableSSRProvider/EnableSSRContext';
import { useGsspData } from 'layouts/Root/PagePropsContext';
import FooterInfoLinks from '../FooterInfoLinks';
import FooterSocial from '../FooterSocial';
import FooterSupport from '../FooterSupport';
import FooterPartners from './FooterPartners';

const FooterBettingInfo: FC = () => {
    const container = cn(
        'grid w-full grid-cols-1 gap-1 border-b-default border-solid border-surface-middle pb-8 sm:grid-cols-2 lg:grid-cols-4'
    );
    const isSSREnable = useIsEnableSSR();

    const { footerInfoLinks } = useGsspData();

    const links = !isSSREnable ? { pagesBySlugs: [] } : footerInfoLinks;

    return (
        <div className={container}>
            {links && <FooterInfoLinks pages={links.pagesBySlugs} />}
            <FooterSocial
                widgetName={WidgetNames.FOOTER_SOCIAL}
                dataTests={{
                    containerDataTests: 'footer-social',
                    titleDataTests: 'footer-social__title',
                }}
            />
            <FooterPartners />
            <FooterSupport />
        </div>
    );
};

export default FooterBettingInfo;
