import React, { PropsWithChildren } from 'react';
import type { PageProps } from 'types';

import { CmsApolloProvider } from './cms/CmsApolloProvider';
import { PlatformApolloProvider } from './platform/PlatformApolloProvider';

export const ApolloClientsProvider: React.FC<
    PropsWithChildren<{ pageProps: PageProps }>
> = ({ children, pageProps }) => {
    return (
        <CmsApolloProvider pageProps={pageProps}>
            <PlatformApolloProvider pageProps={pageProps}>
                {children}
            </PlatformApolloProvider>
        </CmsApolloProvider>
    );
};
