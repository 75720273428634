import { includes, reduce } from 'lodash';

import { ACTIVE_SPORTEVENT_STATUSES } from 'app-constants';
import { Banner } from 'components/betting/Promo/Banners/types';
import { SportEventsInList } from 'types';
import { SportEventStatus, SportEventType } from 'types/gql.bet';

type GroupBanners = {
    tournamentIds: string[];
    sportEvents: SportEventsInList;
};

export const groupBannersByType = (
    banners: Banner[],
    matchStatuses: SportEventStatus[] = ACTIVE_SPORTEVENT_STATUSES
): GroupBanners => {
    return reduce<Banner, GroupBanners>(
        banners,
        (acc, banner) => {
            switch (banner.__typename) {
                case 'BannerBettingSportEventGeo':
                case 'BannerBettingSportEvent': {
                    const { type, status } = banner?.sportevent?.fixture || {};

                    if (
                        type === SportEventType.Match &&
                        includes(matchStatuses, status)
                    ) {
                        acc.sportEvents.push(banner?.sportevent);
                    }

                    return acc;
                }

                case 'BannerBettingTournamentGeo':
                case 'BannerBettingTournament': {
                    acc.tournamentIds.push(banner.id);

                    return acc;
                }

                default: {
                    return acc;
                }
            }
        },
        {
            sportEvents: [],
            tournamentIds: [],
        }
    );
};
