import { useGetTournamentByFilters } from 'gql/cms/queries/tournaments/getTournamentByFilters.cms';

interface Input {
    skip?: boolean;
}

interface Output {
    activeTournamentSlug?: string;
    isLoading: boolean;
}

const useActiveTournamentSlug = ({ skip }: Input): Output => {
    const { data: activeTournaments, loading: isLoading } =
        useGetTournamentByFilters(() => ({
            variables: {
                active: true,
                started: true,
                finished: false,
                isNeedPrize: false,
            },
            skip,
        }));

    const activeTournamentSlug =
        activeTournaments?.tournamentsByFilters?.[0]?.slug;

    return {
        activeTournamentSlug,
        isLoading,
    };
};

export default useActiveTournamentSlug;
