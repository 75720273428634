import { FC, useMemo } from 'react';
import cn from 'classnames';
import { useTranslations } from 'next-intl';
import type { FooterPageType } from 'translations/types';

import SmartLink from 'components/SmartLink';
import { GetPagesUrlBySlug } from 'types/gql.cms';
import { getMessageKeyWithReplace } from 'utils';
import { FooterInfoLinks, footerInfoLinks } from '../constants';

const listCn = cn('grid grid-cols-1');
const linkCn = cn(
    'flex max-w-fit items-center px-2 py-1 text-base text-primary-white hover:bg-surface-middle'
);

interface Props {
    pages: GetPagesUrlBySlug['pagesBySlugs'];
}

const FooterInfoLinksContent: FC<Props> = ({ pages }) => {
    const t = useTranslations('Footer');
    const hrefMap = useMemo(() => getHrefs(pages), [pages]);

    return (
        <div className="relative mt-8" data-test="footer-info-links">
            <div
                className="mb-6 text-md font-bold text-primary-orange-toxic"
                data-test="footer-info-links__title"
            >
                {t('gincms.footer.title.information')}
            </div>
            <div className={listCn}>
                {footerInfoLinks.map((info) => (
                    <SmartLink
                        key={info}
                        href={hrefMap[info]}
                        className={linkCn}
                        dataTest={`footer-info-links__item footer-info-links__item--${info}`}
                    >
                        {t(
                            `gincms.footer.page.${getMessageKeyWithReplace<FooterPageType>(
                                info === FooterInfoLinks.TermAndConditions
                                    ? 'common_rules'
                                    : info
                            )}`
                        )}
                    </SmartLink>
                ))}
            </div>
        </div>
    );
};

function getHrefs(
    arrayPages: GetPagesUrlBySlug['pagesBySlugs']
): Record<string, string> {
    const hrefs = footerInfoLinks.reduce((acc: Record<string, string>, id) => {
        const idWithDashes = id.replaceAll('_', '-');
        const idWithUnderscores = id.replaceAll('-', '_');

        if (FooterInfoLinks.TermAndConditions === id) {
            return acc;
        }

        const url = arrayPages.find((page) => {
            const pageWithoutPrefix = page.id.replace('page:', '');

            return [idWithDashes, idWithUnderscores].includes(
                pageWithoutPrefix
            );
        })?.url;

        return {
            ...acc,
            [id]: url || '',
        };
    }, {});

    return {
        ...hrefs,
        [FooterInfoLinks.TermAndConditions]:
            arrayPages.find((item) => item.id === 'page:rules')?.url ?? '',
        [FooterInfoLinks.PublicOffer]:
            arrayPages.find((item) => item.id === 'page:offer')?.url ?? '',
    };
}

export default FooterInfoLinksContent;
