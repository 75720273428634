export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
    [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
};

export enum BannerType {
    BettingLinkedSport = 'betting_linked_sport',
    BettingLiveOfTheDay = 'betting_live_of_the_day',
    BettingMatchOfTheDay = 'betting_match_of_the_day',
    BettingSportEvent = 'betting_sport_event',
    BettingSportEventGeo = 'betting_sport_event_geo',
    BettingTournament = 'betting_tournament',
    BettingTournamentGeo = 'betting_tournament_geo',
    CmsBanner = 'cms_banner',
}

export type BannerZoneAttributeFilters = {
    indices?: InputMaybe<Array<Scalars['Int']>>;
    pageLocations?: InputMaybe<Array<Scalars['String']>>;
    pageTypes?: InputMaybe<Array<Scalars['String']>>;
    platforms?: InputMaybe<Array<Scalars['String']>>;
};

export type BannerZoneAttributesInput = {
    index: Scalars['Int'];
    pageLocation: Scalars['String'];
    pageType: Scalars['String'];
    platform: Scalars['String'];
};

export enum BaseballGameFormat {
    NineInning_1Extra = 'NINE_INNING_1_EXTRA',
    NineInning_3Extra = 'NINE_INNING_3_EXTRA',
    NineInningOnly = 'NINE_INNING_ONLY',
    NineInningUnlimitedExtra = 'NINE_INNING_UNLIMITED_EXTRA',
    Unknown = 'UNKNOWN',
}

export enum BaseballInningType {
    Extra = 'EXTRA',
    Regular = 'REGULAR',
}

export enum BeachVolleyballSetType {
    Golden = 'GOLDEN',
    Regular = 'REGULAR',
}

export type BetBuilderSelection = {
    matchId: Scalars['String'];
    selections: Array<Selection>;
};

/** Bet types for bet details conditions */
export enum BetConditionDetailsType {
    Express = 'EXPRESS',
    Single = 'SINGLE',
    System = 'SYSTEM',
}

/** Types of bet conditions available for filtering insurance / free bets */
export enum BetConditionType {
    BetDetails = 'bet_details',
    /** @deprecated Use `bet_details` instead */
    BetType = 'bet_type',
    /** @deprecated Use `bet_details` instead */
    Competitors = 'competitors',
    /** @deprecated Use outdated */
    Country = 'country',
    /** @deprecated Use `bet_details` instead */
    EventMatchIds = 'event_match_ids',
    /** @deprecated Use `bet_details` instead */
    KindOfSportId = 'kind_of_sport_id',
    /** @deprecated Use `bet_details` instead */
    MarketTypes = 'market_types',
    /** @deprecated Use `bet_details` instead */
    OddRatio = 'odd_ratio',
    /** @deprecated Use `bet_details` instead */
    SportKindIds = 'sport_kind_ids',
    /** @deprecated Use `bet_details` instead */
    Tournaments = 'tournaments',
}

/** Types of conditions of bet details */
export enum BetDetailsConditionType {
    CombinationsCount = 'COMBINATIONS_COUNT',
    Competitor = 'COMPETITOR',
    MarketType = 'MARKET_TYPE',
    OddsCoefficient = 'ODDS_COEFFICIENT',
    OddsCount = 'ODDS_COUNT',
    Sport = 'SPORT',
    SportEvent = 'SPORT_EVENT',
    StakeAmountRange = 'STAKE_AMOUNT_RANGE',
    SystemSize = 'SYSTEM_SIZE',
    TotalOddsCoefficient = 'TOTAL_ODDS_COEFFICIENT',
    Tournament = 'TOURNAMENT',
}

export enum BetInsuranceStatus {
    Accepted = 'ACCEPTED',
    Declined = 'DECLINED',
    Pending = 'PENDING',
}

export enum BetSettlementStatus {
    Accepted = 'ACCEPTED',
    Declined = 'DECLINED',
    Lost = 'LOST',
    Processing = 'PROCESSING',
    Redeemed = 'REDEEMED',
    Refunded = 'REFUNDED',
    Won = 'WON',
}

export type BetSort = {
    direction: SortDirection;
    field: BetSortFields;
};

export enum BetSortFields {
    CreatedAt = 'CREATED_AT',
    StatusUpdatedAt = 'STATUS_UPDATED_AT',
}

export enum BetStatus {
    /** the bet is successful and waiting for settlement */
    Accepted = 'ACCEPTED',
    ArbitrageAccepted = 'ARBITRAGE_ACCEPTED',
    ArbitrageDeclined = 'ARBITRAGE_DECLINED',
    Created = 'CREATED',
    /** the bet is rejected */
    Declined = 'DECLINED',
    ForcedDeclined = 'FORCED_DECLINED',
    Init = 'INIT',
    Placed = 'PLACED',
    PlaceError = 'PLACE_ERROR',
    RolledBack = 'ROLLED_BACK',
    /** final status, if the bet has won, the winnings will be paid out */
    Settled = 'SETTLED',
    /** cancellation of settlement and waiting for resettlement */
    Unsettled = 'UNSETTLED',
}

export enum BetType {
    Express = 'EXPRESS',
    Single = 'SINGLE',
    System = 'SYSTEM',
}

export type BetsInput = {
    dateRange?: InputMaybe<DateRange>;
    ids?: InputMaybe<Array<Scalars['String']>>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    /** specific domain statuses */
    settlementStatuses?: InputMaybe<Array<BetSettlementStatus>>;
    sort?: InputMaybe<BetSort>;
    statuses?: InputMaybe<Array<BetStatus>>;
    types?: InputMaybe<Array<BetType>>;
};

export enum CsgoMap {
    DeAncient = 'DE_ANCIENT',
    DeAnubis = 'DE_ANUBIS',
    DeCache = 'DE_CACHE',
    DeCobblestone = 'DE_COBBLESTONE',
    DeDust2 = 'DE_DUST2',
    DeInferno = 'DE_INFERNO',
    DeMirage = 'DE_MIRAGE',
    DeNuke = 'DE_NUKE',
    DeOverpass = 'DE_OVERPASS',
    DeTrain = 'DE_TRAIN',
    DeVertigo = 'DE_VERTIGO',
    Unknown = 'UNKNOWN',
}

export enum CsgoMatchFormat {
    AwayAdvMr12 = 'AWAY_ADV_MR12',
    AwayAdvMr15 = 'AWAY_ADV_MR15',
    HomeAdvMr12 = 'HOME_ADV_MR12',
    HomeAdvMr15 = 'HOME_ADV_MR15',
    Mr8Ot1 = 'MR8_OT1',
    Mr12Ot3 = 'MR12_OT3',
    Mr15 = 'MR15',
    Mr15WithDraw = 'MR15_WITH_DRAW',
}

export enum CsgoTeamSide {
    Ct = 'CT',
    T = 'T',
    Unknown = 'UNKNOWN',
}

export enum CacheStaleStrategy {
    Remove = 'REMOVE',
    Return = 'RETURN',
}

export enum CallOfDutyWarzoneFormatPlayers {
    Players150 = 'PLAYERS150',
    Players200 = 'PLAYERS200',
    Unknown = 'UNKNOWN',
}

export enum CallOfDutyWarzoneSquadSizeType {
    Double = 'DOUBLE',
    Single = 'SINGLE',
    Triple = 'TRIPLE',
    Unknown = 'UNKNOWN',
}

export enum CardType {
    Red = 'RED',
    Yellow = 'YELLOW',
    YellowRed = 'YELLOW_RED',
}

export enum CashOutOrderStatusCode {
    Accepted = 'accepted',
    Declined = 'declined',
    Pending = 'pending',
}

export type CategorizerExpand = {
    countLiveEventsBySport?: InputMaybe<Scalars['Boolean']>;
    countMatchEventsBySport?: InputMaybe<Scalars['Boolean']>;
    countOutrightEventsBySport?: InputMaybe<Scalars['Boolean']>;
    countPreMatchEventsBySport?: InputMaybe<Scalars['Boolean']>;
};

export enum CategoryTreeType {
    TreeTypeCountry = 'TREE_TYPE_COUNTRY',
    TreeTypeSport = 'TREE_TYPE_SPORT',
    TreeTypeTournament = 'TREE_TYPE_TOURNAMENT',
}

export enum CompetitorHomeAway {
    Away = 'AWAY',
    Home = 'HOME',
    Unknown = 'UNKNOWN',
}

export enum CompetitorType {
    Other = 'OTHER',
    Person = 'PERSON',
    Team = 'TEAM',
}

export enum CricketGameFormat {
    InningAndOneDayInternational = 'INNING_AND_ONE_DAY_INTERNATIONAL',
    TestMatch = 'TEST_MATCH',
    Twenty = 'TWENTY',
    Unknown = 'UNKNOWN',
}

export enum CricketInningType {
    Extra = 'EXTRA',
    Regular = 'REGULAR',
}

export type DateRange = {
    from: Scalars['String'];
    to: Scalars['String'];
};

export enum Dota2TeamSide {
    Dire = 'DIRE',
    Radiant = 'RADIANT',
    Unknown = 'UNKNOWN',
}

export enum DoubleEliminationRoundType {
    GrandFinalBracket = 'GRAND_FINAL_BRACKET',
    LowerBracket = 'LOWER_BRACKET',
    UpperBracket = 'UPPER_BRACKET',
}

export enum EFootballPeriod {
    FirstExtra = 'FIRST_EXTRA',
    FirstHalf = 'FIRST_HALF',
    Last = 'LAST',
    Penalties = 'PENALTIES',
    SecondExtra = 'SECOND_EXTRA',
    SecondHalf = 'SECOND_HALF',
    Unknown = 'UNKNOWN',
}

export enum EIceHockeyPeriodType {
    Bullets = 'BULLETS',
    Overtime = 'OVERTIME',
    Regular = 'REGULAR',
}

export enum EufcReason {
    D = 'D',
    Dq = 'DQ',
    Ko = 'KO',
    O = 'O',
    Sub = 'SUB',
    Td = 'TD',
    Tko = 'TKO',
    U = 'U',
}

export enum EVolleyballSetType {
    Golden = 'GOLDEN',
    Regular = 'REGULAR',
}

export enum FeaturedSportType {
    Classic = 'CLASSIC',
    Esport = 'ESPORT',
}

export enum FootballPenaltyStatus {
    Awarded = 'AWARDED',
    Canceled = 'CANCELED',
    Ended = 'ENDED',
    Missed = 'MISSED',
    Unknown = 'UNKNOWN',
}

export enum FootballPeriod {
    FirstExtra = 'FIRST_EXTRA',
    FirstHalf = 'FIRST_HALF',
    Last = 'LAST',
    Penalties = 'PENALTIES',
    SecondExtra = 'SECOND_EXTRA',
    SecondHalf = 'SECOND_HALF',
    Unknown = 'UNKNOWN',
}

/** deprecated - use BetDetailsConditionType */
export enum FreebetBetDetailsConditionType {
    CombinationsCount = 'COMBINATIONS_COUNT',
    Competitor = 'COMPETITOR',
    MarketType = 'MARKET_TYPE',
    OddsCoefficient = 'ODDS_COEFFICIENT',
    OddsCount = 'ODDS_COUNT',
    Sport = 'SPORT',
    SportEvent = 'SPORT_EVENT',
    SystemSize = 'SYSTEM_SIZE',
    TotalOddsCoefficient = 'TOTAL_ODDS_COEFFICIENT',
    Tournament = 'TOURNAMENT',
}

/** deprecated - use BetDetailsType */
export enum FreebetBetDetailsType {
    Express = 'EXPRESS',
    Single = 'SINGLE',
    System = 'SYSTEM',
}

/** deprecated - use BetConditionType */
export enum FreebetConditionType {
    BetDetails = 'bet_details',
    BetType = 'bet_type',
    Competitors = 'competitors',
    /** @deprecated Use sport_kind_ids instead */
    Country = 'country',
    EventMatchIds = 'event_match_ids',
    /** @deprecated Use sport_kind_ids instead */
    KindOfSportId = 'kind_of_sport_id',
    MarketTypes = 'market_types',
    OddRatio = 'odd_ratio',
    SportKindIds = 'sport_kind_ids',
    Tournaments = 'tournaments',
}

export enum FreebetNotApplicableReason {
    Expired = 'EXPIRED',
    NotApplicableForCurrentBet = 'NOT_APPLICABLE_FOR_CURRENT_BET',
    NotFound = 'NOT_FOUND',
    Unknown = 'UNKNOWN',
}

export enum FreebetStatusInRestriction {
    Canceled = 'CANCELED',
    Unknown = 'UNKNOWN',
    Used = 'USED',
}

export type FreebetsFilter = {
    onlyActual?: InputMaybe<Scalars['Boolean']>;
};

export enum FutsalPeriod {
    FirstExtra = 'FIRST_EXTRA',
    FirstHalf = 'FIRST_HALF',
    Last = 'LAST',
    Penalties = 'PENALTIES',
    SecondExtra = 'SECOND_EXTRA',
    SecondHalf = 'SECOND_HALF',
    Unknown = 'UNKNOWN',
}

/** GamblerSetting represents the bettor's UI preferences (keep some modals open/closed, favorite markets, show odds statistics, etc.) */
export type GamblerSetting = {
    /** name of the preference (favoriteMarketsId, isOddsChangeModalShown, etc.) */
    name: Scalars['String'];
    /** value of the preference (`true` for `isOddsChangeModalShown`, [`123`, `12`] for `favoriteMarketsId`, etc.) */
    value: Scalars['String'];
};

export enum HandballPenaltyStatus {
    Awarded = 'AWARDED',
    Canceled = 'CANCELED',
    Ended = 'ENDED',
    Missed = 'MISSED',
    Unknown = 'UNKNOWN',
}

export enum HandballPeriod {
    FirstExtra = 'FIRST_EXTRA',
    FirstHalf = 'FIRST_HALF',
    Penalties = 'PENALTIES',
    SecondExtra = 'SECOND_EXTRA',
    SecondHalf = 'SECOND_HALF',
    Unknown = 'UNKNOWN',
}

export type IdChecksum = {
    checksum?: InputMaybe<Scalars['String']>;
    id: Scalars['String'];
};

export enum IceHockeyPeriodType {
    Bullets = 'BULLETS',
    Overtime = 'OVERTIME',
    Regular = 'REGULAR',
}

export enum IndoorSoccerPeriodType {
    FirstHalf = 'FIRST_HALF',
    SecondHalf = 'SECOND_HALF',
    Unknown = 'UNKNOWN',
}

export type InputPair = {
    name: Scalars['String'];
    value: Scalars['String'];
};

export enum InsuranceNotApplicableReason {
    Expired = 'EXPIRED',
    InsuranceMustBeWithoutFreebet = 'INSURANCE_MUST_BE_WITHOUT_FREEBET',
    NotApplicableForCurrentBet = 'NOT_APPLICABLE_FOR_CURRENT_BET',
    NotFound = 'NOT_FOUND',
    Unknown = 'UNKNOWN',
}

export enum InsuranceStatusInRestriction {
    Canceled = 'CANCELED',
    Unknown = 'UNKNOWN',
    Used = 'USED',
}

export type InsurancesFilter = {
    onlyActual?: InputMaybe<Scalars['Boolean']>;
};

export enum KabaddiFormat {
    Format_4X4 = 'FORMAT_4X4',
    Format_5X5 = 'FORMAT_5X5',
    Format_6X6 = 'FORMAT_6X6',
    Format_7X7 = 'FORMAT_7X7',
    Unknown = 'UNKNOWN',
}

export enum LogLevel {
    Debug = 'DEBUG',
    Error = 'ERROR',
    Info = 'INFO',
    Warning = 'WARNING',
}

export enum MmaFormat {
    Five = 'FIVE',
    Three = 'THREE',
    Unknown = 'UNKNOWN',
}

export enum MmaReason {
    D = 'D',
    Dq = 'DQ',
    Drw = 'DRW',
    Ko = 'KO',
    Sub = 'SUB',
    Td = 'TD',
    Tko = 'TKO',
    U = 'U',
}

export enum MmaWinner {
    Away = 'AWAY',
    Draw = 'DRAW',
    Home = 'HOME',
    Unknown = 'UNKNOWN',
}

export enum MarketStatus {
    Active = 'ACTIVE',
    Cancelled = 'CANCELLED',
    Deactivated = 'DEACTIVATED',
    Resulted = 'RESULTED',
    Suspended = 'SUSPENDED',
}

export enum MarketType {
    Match = 'MATCH',
    Outright = 'OUTRIGHT',
}

export type MaxBetOddType = {
    marketId: Scalars['String'];
    matchId: Scalars['String'];
    oddId: Scalars['String'];
    oddRatio: Scalars['Float'];
    providerId?: InputMaybe<Scalars['String']>;
};

export enum MortalKombatCharacter {
    Baraka = 'BARAKA',
    CassieCage = 'CASSIE_CAGE',
    Cetrion = 'CETRION',
    Dvorah = 'DVORAH',
    ErronBlack = 'ERRON_BLACK',
    Frost = 'FROST',
    Fujin = 'FUJIN',
    Geras = 'GERAS',
    JacquiBriggs = 'JACQUI_BRIGGS',
    Jade = 'JADE',
    JaxBriggs = 'JAX_BRIGGS',
    JohnnyCage = 'JOHNNY_CAGE',
    Joker = 'JOKER',
    Kabal = 'KABAL',
    Kano = 'KANO',
    Kitana = 'KITANA',
    Kollector = 'KOLLECTOR',
    KotalKahn = 'KOTAL_KAHN',
    KungLao = 'KUNG_LAO',
    LiuKang = 'LIU_KANG',
    Nightwolf = 'NIGHTWOLF',
    NoobSaibot = 'NOOB_SAIBOT',
    Raiden = 'RAIDEN',
    Robocop = 'ROBOCOP',
    Scorpion = 'SCORPION',
    ShangTsung = 'SHANG_TSUNG',
    ShaoKahn = 'SHAO_KAHN',
    Sheeva = 'SHEEVA',
    Sindel = 'SINDEL',
    Skarlet = 'SKARLET',
    SonyaBlade = 'SONYA_BLADE',
    Spawn = 'SPAWN',
    SubZero = 'SUB_ZERO',
    TerminatorT_800 = 'TERMINATOR_T_800',
    Unknown = 'UNKNOWN',
}

export enum MortalKombatMap {
    BlackDragonFightClub = 'BLACK_DRAGON_FIGHT_CLUB',
    BlackMarketAlley = 'BLACK_MARKET_ALLEY',
    CyberLinKueiAssembly = 'CYBER_LIN_KUEI_ASSEMBLY',
    GorosLair = 'GOROS_LAIR',
    KharonsShip = 'KHARONS_SHIP',
    KoliseumBeastPen = 'KOLISEUM_BEAST_PEN',
    KotalKoliseum = 'KOTAL_KOLISEUM',
    KronikasKeep = 'KRONIKAS_KEEP',
    LostHiveOfTheKytinn = 'LOST_HIVE_OF_THE_KYTINN',
    SeaOfBlood = 'SEA_OF_BLOOD',
    ShangTsungsIslandRuins = 'SHANG_TSUNGS_ISLAND_RUINS',
    ShaolinTrapDungeon = 'SHAOLIN_TRAP_DUNGEON',
    ShinnoksBoneTemple = 'SHINNOKS_BONE_TEMPLE',
    ShiraiRyuFireGarden = 'SHIRAI_RYU_FIRE_GARDEN',
    SpecialForcesDesertCommand = 'SPECIAL_FORCES_DESERT_COMMAND',
    TankGarageBunker = 'TANK_GARAGE_BUNKER',
    TarkatanKamp = 'TARKATAN_KAMP',
    Tournament = 'TOURNAMENT',
    Unknown = 'UNKNOWN',
    WuShiDragonGrotto = 'WU_SHI_DRAGON_GROTTO',
}

export enum MortalKombatWinReason {
    Animality = 'ANIMALITY',
    Babality = 'BABALITY',
    Brutality = 'BRUTALITY',
    Common = 'COMMON',
    Fatality = 'FATALITY',
    Friendship = 'FRIENDSHIP',
    Unknown = 'UNKNOWN',
}

export enum OddAcceptStrategy {
    /**
     * all odd coefficients are allowed if the odd changes during acceptance process
     * coefficient in the bet is updated if it has changed
     */
    AlwaysAllowed = 'ALWAYS_ALLOWED',
    /**
     * default strategy, only slight odd coefficient changes are allowed if the odd changes during acceptance process,
     * coefficient in the bet does not change
     */
    DeviationAllowed = 'DEVIATION_ALLOWED',
    /**
     * greater odd coefficients are allowed if the odd changes during acceptance process,
     * coefficient in the bet is updated if it has changed
     */
    GreaterAllowed = 'GREATER_ALLOWED',
}

export type OddPath = {
    /** odd path: sport event ID + ~ + market ID + ~ + odd ID, for example 5:21ccdb66-a99f-4543-b8f8-cfc0317ac0d7~300m1t27_5~2 */
    path: Scalars['String'];
    /** bet amount for the SINGLE bet type, in all other cases — an empty string */
    stake: Scalars['String'];
};

export enum OddStatus {
    Cancelled = 'CANCELLED',
    HalfLoss = 'HALF_LOSS',
    HalfWin = 'HALF_WIN',
    Loss = 'LOSS',
    NotResulted = 'NOT_RESULTED',
    Refunded = 'REFUNDED',
    Win = 'WIN',
}

export enum PenaltyStatus {
    Awarded = 'AWARDED',
    Canceled = 'CANCELED',
    Ended = 'ENDED',
    Missed = 'MISSED',
    Unknown = 'UNKNOWN',
}

export type PlaceBetBuilderOdd = {
    matchId: Scalars['String'];
    odds: Array<PlaceBetOdd>;
    ratio: Scalars['Float'];
};

export type PlaceBetInsurance = {
    id: Scalars['String'];
    version: Scalars['String'];
};

export type PlaceBetMeta = {
    featureFlags?: InputMaybe<Array<InputPair>>;
};

export type PlaceBetOdd = {
    marketId: Scalars['String'];
    matchId: Scalars['String'];
    oddId: Scalars['String'];
    /** coefficient */
    ratio: Scalars['Float'];
};

export type PlaceCashOutSelection = {
    marketId: Scalars['String'];
    oddId: Scalars['String'];
    sportEventId: Scalars['String'];
    /** odd value in market at the moment of cash out */
    value: Scalars['Float'];
};

export enum PredictionStatus {
    InProgress = 'IN_PROGRESS',
    NotStarted = 'NOT_STARTED',
    Settled = 'SETTLED',
}

export enum RainbowSixTeamSide {
    Attack = 'ATTACK',
    Defend = 'DEFEND',
    Unknown = 'UNKNOWN',
}

/** Types of insurance refund rules */
export enum RefundAmountRuleType {
    Amount = 'AMOUNT',
    Percent = 'PERCENT',
}

/** Rule of the insurance refund strategy */
export enum RefundStrategyRuleType {
    MaxOddsCountLose = 'MAX_ODDS_COUNT_LOSE',
}

export enum RestrictionType {
    BaselineSelectionExistence = 'baseline_selection_existence',
    BetCashOutSelectionsMismatch = 'bet_cash_out_selections_mismatch',
    /** @deprecated will never be present in the bet */
    BetDelay = 'bet_delay',
    BetInterval = 'bet_interval',
    BetProvider = 'bet_provider',
    BetSelectionExistence = 'bet_selection_existence',
    BetStatus = 'bet_status',
    BetTournamentDuplicateCompetitorIds = 'bet_tournament_duplicate_competitor_ids',
    BetTournamentIncompatibleMarketIds = 'bet_tournament_incompatible_market_ids',
    BetType = 'bet_type',
    CashOutAmountLimit = 'cash_out_amount_limit',
    CashOutBetType = 'cash_out_bet_type',
    CashOutOrderFreebet = 'cash_out_order_freebet',
    CashOutOrderStatus = 'cash_out_order_status',
    CashOutRefundAmount = 'cash_out_refund_amount',
    CashOutUnavailable = 'cash_out_unavailable',
    FreebetAmount = 'freebet_amount',
    FreebetNotApplicable = 'freebet_not_applicable',
    FreebetStatus = 'freebet_status',
    InsuranceNotApplicable = 'insurance_not_applicable',
    InsuranceStatus = 'insurance_status',
    InsuranceValue = 'insurance_value',
    InternalError = 'internal_error',
    MarketDefective = 'market_defective',
    MarketExistence = 'market_existence',
    MarketStatus = 'market_status',
    MaxBet = 'max_bet',
    MaxOddBet = 'max_odd_bet',
    MinBet = 'min_bet',
    NotEnoughBalance = 'not_enough_balance',
    OddExistence = 'odd_existence',
    OddStatus = 'odd_status',
    PlayerLimit = 'player_limit',
    SelectionValue = 'selection_value',
    SelectionsCount = 'selections_count',
    SportEventBetStop = 'sport_event_bet_stop',
    SportEventExistence = 'sport_event_existence',
    SportEventStatus = 'sport_event_status',
    SportEventType = 'sport_event_type',
    WlDefined = 'wl_defined',
}

export enum RocketLeagueSportType {
    Basketball = 'BASKETBALL',
    Football = 'FOOTBALL',
    Hockey = 'HOCKEY',
    Unknown = 'UNKNOWN',
}

export type Selection = {
    marketId: Scalars['String'];
    matchId: Scalars['String'];
    oddId: Scalars['String'];
    providerId?: InputMaybe<Scalars['String']>;
    ratio: Scalars['String'];
};

export enum SortDirection {
    Asc = 'ASC',
    Desc = 'DESC',
}

export type SportEventFiltersInput = {
    competitorIds?: InputMaybe<Array<Scalars['String']>>;
    countryCodes?: InputMaybe<Array<Scalars['String']>>;
    dateFrom?: InputMaybe<Scalars['String']>;
    dateSortAscending?: InputMaybe<Scalars['Boolean']>;
    dateTo?: InputMaybe<Scalars['String']>;
    favorite?: InputMaybe<Scalars['Boolean']>;
    hasStreams?: InputMaybe<Scalars['Boolean']>;
    isLive?: InputMaybe<Scalars['Boolean']>;
    marketIds?: InputMaybe<Array<Scalars['String']>>;
    marketLimit?: InputMaybe<Scalars['Int']>;
    marketStatuses?: InputMaybe<Array<MarketStatus>>;
    marketTypes?: InputMaybe<Array<Scalars['Int']>>;
    matchStatuses?: InputMaybe<Array<SportEventStatus>>;
    providerIds?: InputMaybe<Array<Scalars['Int']>>;
    searchString?: InputMaybe<Scalars['String']>;
    sportEventTypes?: InputMaybe<Array<SportEventType>>;
    sportIds?: InputMaybe<Array<Scalars['String']>>;
    tournamentIds?: InputMaybe<Array<Scalars['String']>>;
};

export enum SportEventMatchType {
    MultiCompetitive = 'MULTI_COMPETITIVE',
    Versus = 'VERSUS',
}

export enum SportEventOrder {
    /** Doesn't apply any sorting */
    None = 'NONE',
    /** @deprecated Use `RANK_DEFAULT`. */
    Rank = 'RANK',
    /** One of the types, that specified as default in ranking service */
    RankDefault = 'RANK_DEFAULT',
    /** Ranker that specified on backend for 'live' page */
    RankLivePage = 'RANK_LIVE_PAGE',
    /** Ranker that uses ds model to rank sport events by player bet history */
    RankPersonalized = 'RANK_PERSONALIZED',
    /** Sort by sport events statistics */
    RankProfit = 'RANK_PROFIT',
    /** Ranker that is recommended for each whitelabel (configurable) */
    RankRecommended = 'RANK_RECOMMENDED',
    /** Ranker that uses ds model to rank sport events by player country */
    RankRegional = 'RANK_REGIONAL',
    /** Sort by live flag and start time of sport events */
    RankStartTime = 'RANK_START_TIME',
    /** Sort by 'top' flag */
    RankTop = 'RANK_TOP',
}

export enum SportEventState {
    Live = 'LIVE',
    Prematch = 'PREMATCH',
    Unknown = 'UNKNOWN',
}

export enum SportEventStatus {
    Abandoned = 'ABANDONED',
    Cancelled = 'CANCELLED',
    Closed = 'CLOSED',
    Delayed = 'DELAYED',
    Ended = 'ENDED',
    Live = 'LIVE',
    NotStarted = 'NOT_STARTED',
    Suspended = 'SUSPENDED',
    Unknown = 'UNKNOWN',
}

export enum SportEventType {
    Match = 'MATCH',
    Outright = 'OUTRIGHT',
}

export enum SportTag {
    Classic = 'CLASSIC',
    Esport = 'ESPORT',
}

/**
 * is used for the system type of bets
 * if mode chunk - bet`s stake will be calculated as stake*system combinations count
 * for example:  stake 1;, system bet on 3 odds (we can only have 3 combinations)
 * - mode chunk: 1$ * 3 = 3$; 3$ - will be withdrawn from the player`s balance
 * - mode total: 1$ - will be withdrawn from the player`s balance
 */
export enum StakeMode {
    Chunk = 'chunk',
    Total = 'total',
}

export enum StakeSuggesterAlgorithm {
    /** The algorithm uses default stake suggestions for each Currency. */
    ByCurrency = 'BY_CURRENCY',
    /** The algorithm uses default stake suggestions for each Currency and sets MinBet as the first element in the array. */
    ByCurrency_2 = 'BY_CURRENCY_2',
    /**
     * The algorithm uses MaxBet, MinBet, and SuggestsCountLimit to find the Step: Step = MaxBet-MinBet/SuggestsCountLimit.
     * If MinBet != 0, it will be the first element in the array.
     * MaxBet will be the last element in the array.
     */
    ByMaxBet = 'BY_MAX_BET',
    /** Algorithm use the configuration from the back office for current white label (wl) */
    ByWl = 'BY_WL',
}

export enum Status {
    Active = 'ACTIVE',
    Inactive = 'INACTIVE',
}

export type SuggestedExpressesInput = {
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
};

export enum TableTennisSetType {
    Golden = 'GOLDEN',
    Regular = 'REGULAR',
}

export enum TeamHomeAway {
    Away = 'AWAY',
    Home = 'HOME',
    Unknown = 'UNKNOWN',
}

export enum TennisGamePoint {
    Point0 = 'POINT0',
    Point15 = 'POINT15',
    Point30 = 'POINT30',
    Point40 = 'POINT40',
    PointAbove = 'POINT_ABOVE',
}

export enum TennisSetStatus {
    Common = 'COMMON',
    TieBreak = 'TIE_BREAK',
}

export type TournamentBracketPredictionCompetitorInput = {
    competitorID: Scalars['String'];
    isSelected: Scalars['Boolean'];
};

export type TournamentBracketPredictionInput = {
    matches: Array<TournamentBracketPredictionMatchInput>;
    tournamentID: Scalars['String'];
};

export type TournamentBracketPredictionMatchInput = {
    bracketMatchID: Scalars['Int'];
    competitors: Array<TournamentBracketPredictionCompetitorInput>;
};

export enum TournamentBracketType {
    DoubleElimination = 'DOUBLE_ELIMINATION',
    SingleElimination = 'SINGLE_ELIMINATION',
}

export enum TournamentTableType {
    KnockoutSingle = 'KNOCKOUT_SINGLE',
}

export enum ValorantGameState {
    AfterEndTime = 'AFTER_END_TIME',
    FreezeTime = 'FREEZE_TIME',
    LiveTime = 'LIVE_TIME',
    NotStarted = 'NOT_STARTED',
    Unknown = 'UNKNOWN',
}

export enum ValorantMap {
    Ascent = 'ASCENT',
    Bind = 'BIND',
    Breeze = 'BREEZE',
    Fracture = 'FRACTURE',
    Haven = 'HAVEN',
    Icebox = 'ICEBOX',
    Lotus = 'LOTUS',
    Pearl = 'PEARL',
    Split = 'SPLIT',
    Sunset = 'SUNSET',
    Unknown = 'UNKNOWN',
}

export enum ValorantTeamSide {
    Attack = 'ATTACK',
    Defend = 'DEFEND',
    Unknown = 'UNKNOWN',
}

export enum VolleyballSetType {
    Golden = 'GOLDEN',
    Regular = 'REGULAR',
}

export enum WhiteLabelSportEventRankerType {
    Default = 'DEFAULT',
    Personalized = 'PERSONALIZED',
    Profit = 'PROFIT',
}

export enum WinnerHomeAway {
    Away = 'AWAY',
    Home = 'HOME',
    Noone = 'NOONE',
    Unknown = 'UNKNOWN',
}

export type GetBetsVariables = Exact<{
    input: BetsInput;
}>;

export type GetBets = {
    __typename?: 'Query';
    bets: Array<{ __typename?: 'Bet' } & Bet>;
};

export type GetApplicableFreebetsVariables = Exact<{
    betType: BetType;
    selections: Array<Selection> | Selection;
}>;

export type GetApplicableFreebets = {
    __typename?: 'Query';
    applicableFreebets: Array<{ __typename?: 'Freebet' } & FreebetFragment>;
};

export type GetApplicableInsurancesVariables = Exact<{
    betType: BetType;
    selections: Array<Selection> | Selection;
    betStake: Scalars['String'];
}>;

export type GetApplicableInsurances = {
    __typename?: 'Query';
    applicableInsurances: Array<{
        __typename?: 'Insurance';
        id: string;
        version: string;
        currencyCode: string;
        refundRules: {
            __typename?: 'InsuranceRefundRules';
            amount:
                | {
                      __typename: 'RefundAmountAmountRule';
                      type: RefundAmountRuleType;
                      amount: string;
                  }
                | {
                      __typename: 'RefundAmountPercentRule';
                      type: RefundAmountRuleType;
                      percent: string;
                      maxAmount: string;
                  };
            strategies?: Array<{
                __typename?: 'MaxOddsCountLoseStrategyRule';
                type: RefundStrategyRuleType;
            }> | null;
        };
    }>;
};

export type PlaceBetVariables = Exact<{
    type: BetType;
    odds: Array<PlaceBetOdd> | PlaceBetOdd;
    selections?: InputMaybe<
        Array<InputMaybe<Selection>> | InputMaybe<Selection>
    >;
    stake: Scalars['Float'];
    stakeMode?: InputMaybe<StakeMode>;
    systemSize: Array<Scalars['Int']> | Scalars['Int'];
    freebetId?: InputMaybe<Scalars['String']>;
    oddAcceptStrategy?: InputMaybe<OddAcceptStrategy>;
    meta?: InputMaybe<PlaceBetMeta>;
    insurance?: InputMaybe<PlaceBetInsurance>;
    idempotencyId?: InputMaybe<Scalars['String']>;
}>;

export type PlaceBet = {
    __typename?: 'Mutation';
    placeBet: { __typename?: 'Bet' } & Bet;
};

export type GetBetSuggestVariables = Exact<{
    maxStake?: InputMaybe<Scalars['String']>;
    minStake: Scalars['String'];
    suggestsCountLimit?: InputMaybe<Scalars['Int']>;
    algorithm: StakeSuggesterAlgorithm;
}>;

export type GetBetSuggest = {
    __typename?: 'Query';
    betStakeSuggest: Array<{ __typename?: 'StakeSuggest'; amount: string }>;
};

export type GetBetsStakeVariables = Exact<{
    input: BetsInput;
}>;

export type GetBetsStake = {
    __typename?: 'Query';
    bets: Array<{ __typename?: 'Bet'; stake: string }>;
};

export type GetBeslipModeVariables = Exact<{ [key: string]: never }>;

export type GetBeslipMode = {
    __typename?: 'Query';
    betslip: { __typename?: 'Betslip'; mode: BetType };
};

export type ChangeGamblerBettingSlipVariables = Exact<{
    betType: BetType;
    oddPaths: Array<OddPath> | OddPath;
    stake?: InputMaybe<Scalars['String']>;
    systemSize?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;

export type ChangeGamblerBettingSlip = {
    __typename?: 'Mutation';
    changeGamblerBettingSlip: boolean;
};

export type GetBetslipOddIdsVariables = Exact<{ [key: string]: never }>;

export type GetBetslipOddIds = {
    __typename?: 'Query';
    betslip: { __typename?: 'Betslip'; oddIds: Array<string> };
};

export type OnUpdateSportEventByOddVariables = Exact<{
    sportEventId: Scalars['String'];
    marketLimit?: InputMaybe<Scalars['Int']>;
    marketIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
    marketStatuses?: InputMaybe<Array<MarketStatus> | MarketStatus>;
    version?: InputMaybe<Scalars['String']>;
}>;

export type OnUpdateSportEventByOdd = {
    __typename?: 'Subscription';
    onUpdateSportEvent: {
        __typename?: 'SportEvent';
        id: string;
        slug: string;
        disabled: boolean;
        version: string;
        betStop: boolean;
        markets: Array<{ __typename?: 'Market' } & Market>;
        fixture: { __typename?: 'SportEventFixture'; status: SportEventStatus };
    };
};

export type GetCalculateCashOutVariables = Exact<{
    betId: Scalars['String'];
    amount: Scalars['Float'];
    selections: Array<PlaceCashOutSelection> | PlaceCashOutSelection;
}>;

export type GetCalculateCashOut = {
    __typename?: 'Query';
    calculateCashOut: {
        __typename?: 'CalculatedCashOut';
        amount: {
            __typename?: 'CalculatedCashOutAmount';
            maxRefundAmount: string;
        };
        restrictions: Array<
            | ({
                  __typename?: 'BaselineSelectionExistenceRestriction';
              } & CashOutRestrictions_BaselineSelectionExistenceRestriction_)
            | ({
                  __typename?: 'BetProviderRestriction';
              } & CashOutRestrictions_BetProviderRestriction_)
            | ({
                  __typename?: 'BetSelectionExistenceRestriction';
              } & CashOutRestrictions_BetSelectionExistenceRestriction_)
            | ({
                  __typename?: 'BetStatusRestriction';
              } & CashOutRestrictions_BetStatusRestriction_)
            | ({
                  __typename?: 'CashOutAmountLimitRestriction';
              } & CashOutRestrictions_CashOutAmountLimitRestriction_)
            | ({
                  __typename?: 'CashOutBetSelectionsMismatchRestriction';
              } & CashOutRestrictions_CashOutBetSelectionsMismatchRestriction_)
            | ({
                  __typename?: 'CashOutBetTypeRestriction';
              } & CashOutRestrictions_CashOutBetTypeRestriction_)
            | ({
                  __typename?: 'CashOutOrderFreebetRestriction';
              } & CashOutRestrictions_CashOutOrderFreebetRestriction_)
            | ({
                  __typename?: 'CashOutOrderStatusRestriction';
              } & CashOutRestrictions_CashOutOrderStatusRestriction_)
            | ({
                  __typename?: 'CashOutRefundAmountRestriction';
              } & CashOutRestrictions_CashOutRefundAmountRestriction_)
            | ({
                  __typename?: 'CashOutUnavailableRestriction';
              } & CashOutRestrictions_CashOutUnavailableRestriction_)
            | ({
                  __typename?: 'MarketDefectiveRestriction';
              } & CashOutRestrictions_MarketDefectiveRestriction_)
            | ({
                  __typename?: 'MarketExistenceRestriction';
              } & CashOutRestrictions_MarketExistenceRestriction_)
            | ({
                  __typename?: 'MarketStatusRestriction';
              } & CashOutRestrictions_MarketStatusRestriction_)
            | ({
                  __typename?: 'OddExistenceRestriction';
              } & CashOutRestrictions_OddExistenceRestriction_)
            | ({
                  __typename?: 'OddStatusRestriction';
              } & CashOutRestrictions_OddStatusRestriction_)
            | ({
                  __typename?: 'PlayerLimitRestriction';
              } & CashOutRestrictions_PlayerLimitRestriction_)
            | ({
                  __typename?: 'SelectionValueRestriction';
              } & CashOutRestrictions_SelectionValueRestriction_)
            | ({
                  __typename?: 'SportEventBetStopRestriction';
              } & CashOutRestrictions_SportEventBetStopRestriction_)
            | ({
                  __typename?: 'SportEventExistenceRestriction';
              } & CashOutRestrictions_SportEventExistenceRestriction_)
            | ({
                  __typename?: 'SportEventStatusRestriction';
              } & CashOutRestrictions_SportEventStatusRestriction_)
            | ({
                  __typename?: 'SportEventTypeRestriction';
              } & CashOutRestrictions_SportEventTypeRestriction_)
        >;
    };
};

export type GetMatchCashOutByFiltersVariables = Exact<{
    matchStatuses?: InputMaybe<Array<SportEventStatus> | SportEventStatus>;
    marketStatuses?: InputMaybe<Array<MarketStatus> | MarketStatus>;
    sportIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
    tournamentIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
    sportEventTypes?: InputMaybe<Array<SportEventType> | SportEventType>;
    marketTypes?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
    marketLimit?: InputMaybe<Scalars['Int']>;
    isTopMarkets?: InputMaybe<Scalars['Boolean']>;
    marketIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
    limit: Scalars['Int'];
    offset: Scalars['Int'];
}>;

export type GetMatchCashOutByFilters = {
    __typename?: 'Query';
    matches: Array<{ __typename?: 'SportEvent' } & Match>;
};

export type PlaceCashOutOrderVariables = Exact<{
    betId: Scalars['String'];
    amount: Scalars['Float'];
    refundAmount: Scalars['Float'];
    selections: Array<PlaceCashOutSelection> | PlaceCashOutSelection;
}>;

export type PlaceCashOutOrder = {
    __typename?: 'Mutation';
    placeCashOutOrder: {
        __typename?: 'CashOutOrder';
        id: string;
        amount: string;
        refundAmount: string;
        betId: string;
        status: {
            __typename?: 'CashOutOrderStatus';
            code: CashOutOrderStatusCode;
            reason: string;
        };
        selections: Array<{
            __typename?: 'CashOutSelection';
            oddId: string;
            marketId: string;
            sportEventId: string;
            value: string;
        }>;
        context: {
            __typename?: 'CashOutContext';
            restrictions: Array<
                | ({
                      __typename?: 'BaselineSelectionExistenceRestriction';
                  } & CashOutRestrictions_BaselineSelectionExistenceRestriction_)
                | ({
                      __typename?: 'BetProviderRestriction';
                  } & CashOutRestrictions_BetProviderRestriction_)
                | ({
                      __typename?: 'BetSelectionExistenceRestriction';
                  } & CashOutRestrictions_BetSelectionExistenceRestriction_)
                | ({
                      __typename?: 'BetStatusRestriction';
                  } & CashOutRestrictions_BetStatusRestriction_)
                | ({
                      __typename?: 'CashOutAmountLimitRestriction';
                  } & CashOutRestrictions_CashOutAmountLimitRestriction_)
                | ({
                      __typename?: 'CashOutBetSelectionsMismatchRestriction';
                  } & CashOutRestrictions_CashOutBetSelectionsMismatchRestriction_)
                | ({
                      __typename?: 'CashOutBetTypeRestriction';
                  } & CashOutRestrictions_CashOutBetTypeRestriction_)
                | ({
                      __typename?: 'CashOutOrderFreebetRestriction';
                  } & CashOutRestrictions_CashOutOrderFreebetRestriction_)
                | ({
                      __typename?: 'CashOutOrderStatusRestriction';
                  } & CashOutRestrictions_CashOutOrderStatusRestriction_)
                | ({
                      __typename?: 'CashOutRefundAmountRestriction';
                  } & CashOutRestrictions_CashOutRefundAmountRestriction_)
                | ({
                      __typename?: 'CashOutUnavailableRestriction';
                  } & CashOutRestrictions_CashOutUnavailableRestriction_)
                | ({
                      __typename?: 'MarketDefectiveRestriction';
                  } & CashOutRestrictions_MarketDefectiveRestriction_)
                | ({
                      __typename?: 'MarketExistenceRestriction';
                  } & CashOutRestrictions_MarketExistenceRestriction_)
                | ({
                      __typename?: 'MarketStatusRestriction';
                  } & CashOutRestrictions_MarketStatusRestriction_)
                | ({
                      __typename?: 'OddExistenceRestriction';
                  } & CashOutRestrictions_OddExistenceRestriction_)
                | ({
                      __typename?: 'OddStatusRestriction';
                  } & CashOutRestrictions_OddStatusRestriction_)
                | ({
                      __typename?: 'PlayerLimitRestriction';
                  } & CashOutRestrictions_PlayerLimitRestriction_)
                | ({
                      __typename?: 'SelectionValueRestriction';
                  } & CashOutRestrictions_SelectionValueRestriction_)
                | ({
                      __typename?: 'SportEventBetStopRestriction';
                  } & CashOutRestrictions_SportEventBetStopRestriction_)
                | ({
                      __typename?: 'SportEventExistenceRestriction';
                  } & CashOutRestrictions_SportEventExistenceRestriction_)
                | ({
                      __typename?: 'SportEventStatusRestriction';
                  } & CashOutRestrictions_SportEventStatusRestriction_)
                | ({
                      __typename?: 'SportEventTypeRestriction';
                  } & CashOutRestrictions_SportEventTypeRestriction_)
            >;
        };
    };
};

export type GetCategorizerVariables = Exact<{
    marketStatuses?: InputMaybe<Array<MarketStatus> | MarketStatus>;
    matchStatuses?: InputMaybe<Array<SportEventStatus> | SportEventStatus>;
    sportEventTypes?: InputMaybe<Array<SportEventType> | SportEventType>;
    sportIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
    activeSportId?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
    withMeta?: InputMaybe<Scalars['Boolean']>;
    expand?: InputMaybe<CategorizerExpand>;
    skipSportList?: InputMaybe<Scalars['Boolean']>;
    dateFrom?: InputMaybe<Scalars['String']>;
    skipTournaments?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetCategorizer = {
    __typename?: 'Query';
    categorizerSportList: Array<{
        __typename?: 'CategorizerItem';
        value: string;
        name: string;
        meta?: Array<{ __typename?: 'Pair'; name: string; value: string }>;
    }>;
    categorizerChildren: Array<{
        __typename?: 'CategorizerItem';
        value: string;
        children: Array<{
            __typename?: 'CategorizerItem';
            value: string;
            name: string;
            count: number;
            meta: Array<{ __typename?: 'Pair'; name: string; value: string }>;
        }>;
    }>;
};

export type AddFavoriteSportEventsVariables = Exact<{
    ids: Array<Scalars['String']> | Scalars['String'];
}>;

export type AddFavoriteSportEvents = {
    __typename?: 'Mutation';
    favorite: Array<string>;
};

export type DeleteFavoriteSportEventsVariables = Exact<{
    ids: Array<Scalars['String']> | Scalars['String'];
}>;

export type DeleteFavoriteSportEvents = {
    __typename?: 'Mutation';
    favorite: Array<string>;
};

export type GetFavoriteSportEventsIdsVariables = Exact<{
    matchStatuses?: InputMaybe<Array<SportEventStatus> | SportEventStatus>;
    sportIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
    sportEventTypes?: InputMaybe<Array<SportEventType> | SportEventType>;
}>;

export type GetFavoriteSportEventsIds = {
    __typename?: 'Query';
    favorites?: Array<string> | null;
};

export type GetFavoriteSportEventsCountVariables = Exact<{
    sportEventIds: Array<Scalars['String']> | Scalars['String'];
    matchStatuses?: InputMaybe<Array<SportEventStatus> | SportEventStatus>;
    marketStatuses?: InputMaybe<Array<MarketStatus> | MarketStatus>;
}>;

export type GetFavoriteSportEventsCount = {
    __typename?: 'Query';
    matches: Array<{
        __typename?: 'SportEvent';
        id: string;
        fixture: { __typename?: 'SportEventFixture'; type: SportEventType };
    }>;
};

export type GetFeaturedTournamentsVariables = Exact<{
    zoneId: Scalars['String'];
    range?: InputMaybe<DateRange>;
    isClient?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetFeaturedTournaments = {
    __typename?: 'Query';
    featuredZoneConfigs: Array<
        | {
              __typename?: 'FeaturedZoneCustomListProvider';
              description: string;
              image: string;
              priority: number;
              slug: string;
              sportType: FeaturedSportType;
              title: string;
          }
        | {
              __typename?: 'FeaturedZoneTournamentProvider';
              priority: number;
              sportEventCount?: number;
              tournament?: ({ __typename?: 'Tournament' } & Tournament) | null;
          }
    >;
};

export type GetFreebetMarketTemplatesVariables = Exact<{
    marketTemplateIds?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;

export type GetFreebetMarketTemplates = {
    __typename?: 'Query';
    marketTemplates: Array<{
        __typename?: 'MarketTemplate';
        id: number;
        template: string;
    }>;
};

export type GetMatchFreebetByFiltersVariables = Exact<{
    matchStatuses?: InputMaybe<Array<SportEventStatus> | SportEventStatus>;
    sportIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
    tournamentIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
    sportEventTypes?: InputMaybe<Array<SportEventType> | SportEventType>;
    limit: Scalars['Int'];
    offset: Scalars['Int'];
    isTopMarkets?: InputMaybe<Scalars['Boolean']>;
    marketLimit?: InputMaybe<Scalars['Int']>;
    marketIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
    marketStatuses?: InputMaybe<Array<MarketStatus> | MarketStatus>;
    marketTypes?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;

export type GetMatchFreebetByFilters = {
    __typename?: 'Query';
    matches: Array<{ __typename?: 'SportEvent' } & Match>;
};

export type GetFreebetsCountVariables = Exact<{
    filter?: InputMaybe<FreebetsFilter>;
}>;

export type GetFreebetsCount = {
    __typename?: 'Query';
    freebets: Array<{ __typename?: 'Freebet'; id: string }>;
};

export type ChangeGamblerSettingVariables = Exact<{
    key: Scalars['String'];
    value: Scalars['String'];
}>;

export type ChangeGamblerSetting = {
    __typename?: 'Mutation';
    changeGamblerSetting: {
        __typename?: 'Gambler';
        settings?: Array<{
            __typename?: 'Pair';
            name: string;
            value: string;
        }> | null;
    };
};

export type ChangeGamblerSettingsVariables = Exact<{
    keys: Array<GamblerSetting> | GamblerSetting;
}>;

export type ChangeGamblerSettings = {
    __typename?: 'Mutation';
    changeGamblerSettings: {
        __typename?: 'Gambler';
        settings?: Array<{
            __typename?: 'Pair';
            name: string;
            value: string;
        }> | null;
    };
};

export type DeleteGamblerSettingVariables = Exact<{
    key: Scalars['String'];
}>;

export type DeleteGamblerSetting = {
    __typename?: 'Mutation';
    deleteGamblerSetting: {
        __typename?: 'Gambler';
        settings?: Array<{
            __typename?: 'Pair';
            name: string;
            value: string;
        }> | null;
    };
};

export type GetBetBoosterVariables = Exact<{
    sportEventId: Scalars['String'];
}>;

export type GetBetBooster = {
    __typename?: 'Query';
    betTips?: ({ __typename?: 'BetTips' } & BetTips) | null;
};

export type OnUpdateBetTipsVariables = Exact<{
    sportEventId: Scalars['String'];
}>;

export type OnUpdateBetTips = {
    __typename?: 'Subscription';
    onUpdateBetTips: { __typename?: 'BetTips' } & BetTips;
};

export type GetSportEventFavoritesMarketListVariables = Exact<{
    sportEventsIds: Array<Scalars['String']> | Scalars['String'];
    marketStatuses?: InputMaybe<Array<MarketStatus> | MarketStatus>;
    marketTypes?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;

export type GetSportEventFavoritesMarketList = {
    __typename?: 'Query';
    sportEventsByIds: Array<{
        __typename?: 'SportEvent';
        id: string;
        markets: Array<{ __typename?: 'Market' } & Market>;
    }>;
};

export type GetMarketsTabVariables = Exact<{
    sportEventID: Scalars['String'];
    marketStatuses?: InputMaybe<Array<MarketStatus> | MarketStatus>;
    marketTabID: Scalars['String'];
}>;

export type GetMarketsTab = {
    __typename?: 'Query';
    compiledMarketsTab: {
        __typename?: 'CompiledMarketsTab';
        marketIds: Array<string>;
        sportEvent: { __typename?: 'SportEvent'; id: string };
    };
};

export type OnUpdateTabVariables = Exact<{
    sportEventId: Scalars['String'];
    marketTabId: Scalars['String'];
    marketStatuses?: InputMaybe<Array<MarketStatus> | MarketStatus>;
}>;

export type OnUpdateTab = {
    __typename?: 'Subscription';
    onUpdateTab: {
        __typename?: 'CompiledMarketsTab';
        marketIds: Array<string>;
        sportEvent: { __typename?: 'SportEvent'; id: string };
    };
};

export type GetMarketsTabsVariables = Exact<{
    sportEvent: Scalars['String'];
    marketStatuses?: InputMaybe<Array<MarketStatus> | MarketStatus>;
}>;

export type GetMarketsTabs = {
    __typename?: 'Query';
    compiledMarketsTabs: {
        __typename?: 'CompiledMarketsTabs';
        tabs: Array<{
            __typename?: 'CompiledMarketsTab';
            name: string;
            id: string;
        }>;
    };
};

export type OnUpdateTabsVariables = Exact<{
    sportEventId: Scalars['String'];
    marketStatuses?: InputMaybe<Array<MarketStatus> | MarketStatus>;
}>;

export type OnUpdateTabs = {
    __typename?: 'Subscription';
    onUpdateTabs: {
        __typename?: 'CompiledMarketsTabsDiff';
        sportEvent: { __typename?: 'SportEvent'; id: string };
    };
};

export type GetMarketTemplatesVariables = Exact<{
    sportIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
    marketType?: InputMaybe<MarketType>;
}>;

export type GetMarketTemplates = {
    __typename?: 'Query';
    marketTemplates: Array<{
        __typename?: 'MarketTemplate';
        id: number;
        template: string;
        outcomes: Array<{ __typename?: 'Pair'; name: string; value: string }>;
    }>;
};

export type GetMatchBySlugVariables = Exact<{
    slug: Scalars['String'];
    isClient?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetMatchBySlug = {
    __typename?: 'Query';
    match?: ({ __typename?: 'SportEvent' } & MatchBaseWithOptionalMeta) | null;
};

export type GetPlayersSlugVariables = Exact<{
    ids: Array<Scalars['String']> | Scalars['String'];
}>;

export type GetPlayersSlug = {
    __typename?: 'Query';
    players: Array<{ __typename?: 'Player'; slug: string; id: string }>;
};

export type GetTeamsSlugVariables = Exact<{
    ids: Array<Scalars['String']> | Scalars['String'];
}>;

export type GetTeamsSlug = {
    __typename?: 'Query';
    teams: Array<{ __typename?: 'Team'; slug: string; id: string }>;
};

export type GetSportEventListByFiltersVariables = Exact<{
    offset: Scalars['Int'];
    limit: Scalars['Int'];
    matchStatuses?: InputMaybe<Array<SportEventStatus> | SportEventStatus>;
    sportIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
    competitorIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
    marketStatusesForSportEvent?: InputMaybe<
        Array<MarketStatus> | MarketStatus
    >;
    marketStatuses?: InputMaybe<Array<MarketStatus> | MarketStatus>;
    marketLimit?: InputMaybe<Scalars['Int']>;
    isTopMarkets?: InputMaybe<Scalars['Boolean']>;
    marketTypes?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
    sportEventTypes?: InputMaybe<Array<SportEventType> | SportEventType>;
    marketIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
    isClient?: InputMaybe<Scalars['Boolean']>;
    dateSortAscending?: InputMaybe<Scalars['Boolean']>;
    order?: InputMaybe<SportEventOrder>;
    search?: InputMaybe<Scalars['String']>;
    dateFrom?: InputMaybe<Scalars['String']>;
    dateTo?: InputMaybe<Scalars['String']>;
    tournamentIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
    hasStreams?: InputMaybe<Scalars['Boolean']>;
    favorite?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetSportEventListByFilters = {
    __typename?: 'Query';
    matches: {
        __typename?: 'SportEventList';
        count: number;
        sportEvents: Array<{ __typename?: 'SportEvent' } & SportEvent>;
    };
};

export type GetOddDynamicsByIntervalVariables = Exact<{
    sportEventId: Scalars['String'];
    marketId: Scalars['String'];
    oddId: Scalars['String'];
    from?: InputMaybe<Scalars['String']>;
    pointCount?: InputMaybe<Scalars['Int']>;
}>;

export type GetOddDynamicsByInterval = {
    __typename?: 'Query';
    oddDynamicsByInterval: {
        __typename?: 'OddDynamicByInterval';
        dynamics: Array<{ __typename?: 'OddDynamicValue'; value: number }>;
    };
};

export type GetOutrightBySlugVariables = Exact<{
    slug: Scalars['String'];
    marketStatuses?: InputMaybe<Array<MarketStatus> | MarketStatus>;
    isClient?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetOutrightBySlug = {
    __typename?: 'Query';
    outright?: ({ __typename?: 'SportEvent' } & OutrightSportEvent) | null;
};

export type OutrightSportEvent = {
    __typename?: 'SportEvent';
    id: string;
    version: string;
    fixture: {
        __typename?: 'SportEventFixture';
        score: string;
        title: string;
        status: SportEventStatus;
        type: SportEventType;
        startTime: string;
        sportId: string;
        tournament: {
            __typename?: 'Tournament';
            id: string;
            logo: string;
            name: string;
            prizePool: string;
            description: string;
        };
        competitors: Array<{
            __typename?: 'SportEventCompetitor';
            name: string;
            type: CompetitorType;
            homeAway: CompetitorHomeAway;
            logo: string;
            path: string;
            id: string;
            score?: Array<{ __typename?: 'CompetitorScore' } & CompetitorScore>;
        }>;
    };
    markets: Array<{
        __typename?: 'Market';
        id: string;
        name: string;
        status: MarketStatus;
        typeId: number;
        priority: number;
        tags?: Array<string>;
        path: string;
        specifiers?: Array<{
            __typename?: 'Pair';
            name: string;
            value: string;
        }>;
        odds?: Array<{ __typename?: 'Odd' } & Odd>;
    }>;
};

export type GetOutrightSportsListVariables = Exact<{
    sportEventTypes?: InputMaybe<Array<SportEventType> | SportEventType>;
    sportIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
    matchStatuses?: InputMaybe<Array<SportEventStatus> | SportEventStatus>;
    marketStatuses?: InputMaybe<Array<MarketStatus> | MarketStatus>;
}>;

export type GetOutrightSportsList = {
    __typename?: 'Query';
    categorizer: Array<{
        __typename?: 'CategorizerItem';
        value: string;
        count: number;
        name: string;
    }>;
};

export type GetOutrightsVariables = Exact<{
    sportIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
    sportEventTypes?: InputMaybe<Array<SportEventType> | SportEventType>;
    marketStatuses?: InputMaybe<Array<MarketStatus> | MarketStatus>;
    matchStatuses?: InputMaybe<Array<SportEventStatus> | SportEventStatus>;
    offset: Scalars['Int'];
    limit: Scalars['Int'];
    isTournamentPage?: InputMaybe<Scalars['Boolean']>;
    tournamentIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
    isClient?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetOutrights = {
    __typename?: 'Query';
    sportEventsByFilters: Array<{
        __typename?: 'SportEvent';
        id: string;
        version: string;
        slug: string;
        fixture: {
            __typename?: 'SportEventFixture';
            sportId: string;
            status: SportEventStatus;
            title: string;
            type: SportEventType;
            tournament?: {
                __typename?: 'Tournament';
                id: string;
                logo: string;
                name: string;
                dateStart: string;
                slug: string;
            };
            sport: { __typename?: 'Sport'; tags: Array<SportTag> };
            competitors: Array<{
                __typename?: 'SportEventCompetitor';
                name: string;
                type: CompetitorType;
                homeAway: CompetitorHomeAway;
                logo: string;
                path: string;
                id: string;
                score?: Array<
                    { __typename?: 'CompetitorScore' } & CompetitorScore
                >;
            }>;
        };
        markets: Array<{
            __typename?: 'Market';
            id: string;
            name: string;
            status: MarketStatus;
            typeId: number;
            priority: number;
            tags?: Array<string>;
            path: string;
            specifiers?: Array<{
                __typename?: 'Pair';
                name: string;
                value: string;
            }>;
            odds?: Array<{ __typename?: 'Odd' } & Odd>;
        }>;
    }>;
};

export type GetPlayerBySlugVariables = Exact<{
    slug: Scalars['String'];
}>;

export type GetPlayerBySlug = {
    __typename?: 'Query';
    playerBySlug?: {
        __typename?: 'Player';
        id: string;
        name: string;
        nickname: string;
        logo: string;
        metaTeams: Array<{
            __typename?: 'MetaTeam';
            lastTimeParticipation: string;
            team: {
                __typename?: 'Team';
                countryCode: string;
                logo: string;
                slug: string;
                name: string;
                id: string;
            };
        }>;
    } | null;
};

export type GetBannersVariables = Exact<{
    zoneId?: InputMaybe<Scalars['String']>;
    sportIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
    marketLimit?: InputMaybe<Scalars['Int']>;
    attributes?: InputMaybe<BannerZoneAttributesInput>;
    isActiveBannerTypeGeo?: InputMaybe<Scalars['Boolean']>;
    isTopMarkets?: InputMaybe<Scalars['Boolean']>;
    marketStatuses?: InputMaybe<Array<MarketStatus> | MarketStatus>;
    marketIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
    marketTypes?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
    isClient?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetBanners = {
    __typename?: 'Query';
    banners: Array<
        | {
              __typename: 'BannerBettingLinkedSport';
              type: BannerType;
              sport: { __typename?: 'Sport'; id: string };
              linkedSport: { __typename?: 'Sport'; id: string };
          }
        | { __typename: 'BannerBettingLiveOfTheDay'; type: BannerType }
        | { __typename: 'BannerBettingMatchOfTheDay'; type: BannerType }
        | {
              __typename: 'BannerBettingSportEvent';
              id: string;
              type: BannerType;
              theme: string;
              sportevent: { __typename?: 'SportEvent' } & SportEvent;
          }
        | {
              __typename: 'BannerBettingSportEventGeo';
              id: string;
              type: BannerType;
              theme: string;
              sportevent: { __typename?: 'SportEvent' } & SportEvent;
          }
        | {
              __typename: 'BannerBettingTournament';
              id: string;
              type: BannerType;
              tournament: {
                  __typename?: 'Tournament';
                  slug: string;
                  sportId: string;
              };
          }
        | {
              __typename: 'BannerBettingTournamentGeo';
              id: string;
              type: BannerType;
              tournament: {
                  __typename?: 'Tournament';
                  slug: string;
                  sportId: string;
              };
          }
        | { __typename: 'BannerCMS'; id: string; type: BannerType }
    >;
};

export type GetSearchSportEventListByFiltersVariables = Exact<{
    offset: Scalars['Int'];
    limit: Scalars['Int'];
    search?: InputMaybe<Scalars['String']>;
    matchStatuses?: InputMaybe<Array<SportEventStatus> | SportEventStatus>;
    marketStatusesForSportEvent?: InputMaybe<
        Array<MarketStatus> | MarketStatus
    >;
    sportEventTypes?: InputMaybe<Array<SportEventType> | SportEventType>;
    order?: InputMaybe<SportEventOrder>;
    withSportEvents?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetSearchSportEventListByFilters = {
    __typename?: 'Query';
    matches: {
        __typename?: 'SportEventList';
        count: number;
        sportEvents?: Array<{
            __typename?: 'SportEvent';
            id: string;
            slug: string;
            fixture: {
                __typename?: 'SportEventFixture';
                sportId: string;
                startTime: string;
                status: SportEventStatus;
                sport: {
                    __typename?: 'Sport';
                    tags: Array<SportTag>;
                    id: string;
                    name: string;
                };
                competitors: Array<{
                    __typename?: 'SportEventCompetitor';
                    id: string;
                    logo: string;
                    name: string;
                    homeAway: CompetitorHomeAway;
                }>;
            };
        }>;
    };
};

export type GenerateGridEmbedVideoTokenVariables = Exact<{
    sportEventId: Scalars['String'];
}>;

export type GenerateGridEmbedVideoToken = {
    __typename?: 'Mutation';
    generateGridEmbedVideoTokenV2:
        | {
              __typename?: 'GridEmbedVideoToken';
              token: string;
              seriesId: string;
          }
        | { __typename?: 'GridEmbedVideoTokenForbiddenError'; code: string };
};

export type GetTeamBySlugVariables = Exact<{
    slug: Scalars['String'];
}>;

export type GetTeamBySlug = {
    __typename?: 'Query';
    teamBySlug?: {
        __typename?: 'Team';
        name: string;
        logo: string;
        id: string;
        countryCode: string;
        players: Array<{
            __typename?: 'Player';
            logo: string;
            name: string;
            nickname: string;
            id: string;
            slug: string;
        }>;
    } | null;
};

export type GetTournamentBySlugVariables = Exact<{
    slug: Scalars['String'];
}>;

export type GetTournamentBySlug = {
    __typename?: 'Query';
    tournament?: ({ __typename?: 'Tournament' } & Tournament) | null;
};

export type DoubleElimination = {
    __typename?: 'DoubleEliminationTournamentBracket';
    bracketType: TournamentBracketType;
    matches: Array<{
        __typename?: 'DoubleEliminationMatch';
        bracketMatchID: number;
        roundType: DoubleEliminationRoundType;
        round: number;
        prevMatch1: number;
        prevMatch2: number;
        nextWinMatch: number;
        nextLostMatch: number;
        fixture?:
            | ({
                  __typename: 'TournamentBracketFixture';
              } & TournamentBracketFixtureFragment)
            | null;
    }>;
};

export type GetTournamentBracketVariables = Exact<{
    tournamentID: Scalars['String'];
}>;

export type GetTournamentBracket = {
    __typename?: 'Query';
    tournamentBracket?: {
        __typename?: 'TournamentBracketPrediction';
        id: string;
        status: PredictionStatus;
        startedAt: string;
        bracket:
            | ({
                  __typename: 'DoubleEliminationTournamentBracket';
              } & DoubleElimination)
            | ({
                  __typename: 'SingleEliminationTournamentBracket';
              } & SingleElimination);
    } | null;
};

export type PlayerBaseFragment = {
    __typename?: 'Player';
    id: string;
    name: string;
    nickname: string;
    logo: string;
    slug: string;
};

export type PredictTournamentBracketVariables = Exact<{
    tournamentPrediction: TournamentBracketPredictionInput;
}>;

export type PredictTournamentBracket = {
    __typename?: 'Mutation';
    predictTournamentBracket: {
        __typename?: 'TournamentBracketPrediction';
        id: string;
        status: PredictionStatus;
        startedAt: string;
        bracket:
            | ({
                  __typename: 'DoubleEliminationTournamentBracket';
              } & DoubleElimination)
            | ({
                  __typename: 'SingleEliminationTournamentBracket';
              } & SingleElimination);
    };
};

export type SingleElimination = {
    __typename?: 'SingleEliminationTournamentBracket';
    bracketType: TournamentBracketType;
    matches: Array<{
        __typename?: 'SingleEliminationMatch';
        bracketMatchID: number;
        round: number;
        prevMatch1: number;
        prevMatch2: number;
        nextWinMatch: number;
        fixture?:
            | ({
                  __typename: 'TournamentBracketFixture';
              } & TournamentBracketFixtureFragment)
            | null;
    }>;
};

export type TeamBaseFragment = {
    __typename?: 'Team';
    id: string;
    name: string;
    logo: string;
    slug: string;
    countryCode: string;
};

export type TournamentBracketFixtureFragment = {
    __typename?: 'TournamentBracketFixture';
    isFinished: boolean;
    competitors: Array<{
        __typename?: 'TournamentBracketCompetitor';
        isSelected: boolean;
        isMatched: boolean;
        competitor:
            | ({ __typename: 'Player' } & PlayerBaseFragment)
            | ({ __typename: 'Team' } & TeamBaseFragment);
    }>;
};

export type GetOutrightRacingTabsVariables = Exact<{
    limit: Scalars['Int'];
    offset: Scalars['Int'];
    matchStatuses?: InputMaybe<Array<SportEventStatus> | SportEventStatus>;
    marketStatuses?: InputMaybe<Array<MarketStatus> | MarketStatus>;
    tournamentIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
    sportEventTypes?: InputMaybe<Array<SportEventType> | SportEventType>;
}>;

export type GetOutrightRacingTabs = {
    __typename?: 'Query';
    sportEventsByFilters: Array<{
        __typename?: 'SportEvent';
        id: string;
        fixture: {
            __typename?: 'SportEventFixture';
            title: string;
            status: SportEventStatus;
        };
    }>;
};

export type GetCategorizerTestVariables = Exact<{
    sportIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetCategorizerTest = {
    __typename?: 'Query';
    categorizer: Array<{ __typename?: 'CategorizerItem'; value: string }>;
};

export type GetMatchBySlugTestVariables = Exact<{
    slug: Scalars['String'];
}>;

export type GetMatchBySlugTest = {
    __typename?: 'Query';
    sportEventBySlug?: {
        __typename?: 'SportEvent';
        id: string;
        fixture: {
            __typename?: 'SportEventFixture';
            title: string;
            status: SportEventStatus;
            sportId: string;
            competitors: Array<{
                __typename?: 'SportEventCompetitor';
                id: string;
                name: string;
                type: CompetitorType;
                homeAway: CompetitorHomeAway;
                masterId: string;
                logo: string;
                score: Array<{
                    __typename?: 'CompetitorScore';
                    id: string;
                    type: string;
                    points: string;
                    number: number;
                }>;
            }>;
        };
    } | null;
};

export type GetMatchListTestVariables = Exact<{
    offset: Scalars['Int'];
    limit: Scalars['Int'];
}>;

export type GetMatchListTest = {
    __typename?: 'Query';
    matchList: {
        __typename?: 'SportEventList';
        count: number;
        sportEvents: Array<{
            __typename?: 'SportEvent';
            id: string;
            slug: string;
            fixture: {
                __typename?: 'SportEventFixture';
                score: string;
                title: string;
                status: SportEventStatus;
                type: SportEventType;
                competitors: Array<{
                    __typename?: 'SportEventCompetitor';
                    name: string;
                    type: CompetitorType;
                    homeAway: CompetitorHomeAway;
                    logo: string;
                    id: string;
                    score: Array<{
                        __typename?: 'CompetitorScore';
                        id: string;
                        type: string;
                        points: string;
                        number: number;
                    }>;
                }>;
            };
        }>;
    };
};

export type BetDeclineContextItem = {
    __typename?: 'BetDeclineContextRestrictions';
    type: string;
    restrictions: Array<
        | ({
              __typename: 'BetDelayRestriction';
          } & Restriction_BetDelayRestriction_)
        | ({
              __typename: 'BetIntervalRestriction';
          } & Restriction_BetIntervalRestriction_)
        | ({
              __typename: 'BetProviderRestriction';
          } & Restriction_BetProviderRestriction_)
        | ({
              __typename: 'BetTournamentDuplicateCompetitorIDsRestriction';
          } & Restriction_BetTournamentDuplicateCompetitorIDsRestriction_)
        | ({
              __typename: 'BetTournamentIncompatibleMarketIDsRestriction';
          } & Restriction_BetTournamentIncompatibleMarketIDsRestriction_)
        | ({
              __typename: 'BetTypeRestriction';
          } & Restriction_BetTypeRestriction_)
        | ({
              __typename: 'FreebetAmountRestriction';
          } & Restriction_FreebetAmountRestriction_)
        | ({
              __typename: 'FreebetNotApplicableRestriction';
          } & Restriction_FreebetNotApplicableRestriction_)
        | ({
              __typename: 'FreebetStatusRestriction';
          } & Restriction_FreebetStatusRestriction_)
        | ({
              __typename: 'InsuranceNotApplicableRestriction';
          } & Restriction_InsuranceNotApplicableRestriction_)
        | ({
              __typename: 'InsuranceStatusRestriction';
          } & Restriction_InsuranceStatusRestriction_)
        | ({
              __typename: 'InsuranceValueRestriction';
          } & Restriction_InsuranceValueRestriction_)
        | ({
              __typename: 'InternalErrorRestriction';
          } & Restriction_InternalErrorRestriction_)
        | ({
              __typename: 'MarketDefectiveRestriction';
          } & Restriction_MarketDefectiveRestriction_)
        | ({
              __typename: 'MarketExistenceRestriction';
          } & Restriction_MarketExistenceRestriction_)
        | ({
              __typename: 'MarketStatusRestriction';
          } & Restriction_MarketStatusRestriction_)
        | ({ __typename: 'MaxBetRestriction' } & Restriction_MaxBetRestriction_)
        | ({ __typename: 'MinBetRestriction' } & Restriction_MinBetRestriction_)
        | ({
              __typename: 'NotEnoughBalanceRestriction';
          } & Restriction_NotEnoughBalanceRestriction_)
        | ({
              __typename: 'OddExistenceRestriction';
          } & Restriction_OddExistenceRestriction_)
        | ({
              __typename: 'OddStatusRestriction';
          } & Restriction_OddStatusRestriction_)
        | ({
              __typename: 'PlayerLimitRestriction';
          } & Restriction_PlayerLimitRestriction_)
        | ({
              __typename: 'SelectionValueRestriction';
          } & Restriction_SelectionValueRestriction_)
        | ({
              __typename: 'SelectionsCountRestriction';
          } & Restriction_SelectionsCountRestriction_)
        | ({
              __typename: 'SportEventBetStopRestriction';
          } & Restriction_SportEventBetStopRestriction_)
        | ({
              __typename: 'SportEventExistenceRestriction';
          } & Restriction_SportEventExistenceRestriction_)
        | ({
              __typename: 'SportEventStatusRestriction';
          } & Restriction_SportEventStatusRestriction_)
        | ({
              __typename: 'WLDefinedRestriction';
          } & Restriction_WlDefinedRestriction_)
    >;
};

export type Bet = {
    __typename?: 'Bet';
    id: string;
    type: BetType;
    status: BetStatus;
    stake: string;
    stakeInUsd: string;
    refundSum: string;
    refundSumInUsd: string;
    systemSizes: Array<number>;
    playerId: string;
    createdAt: string;
    updatedAt: string;
    currencyCode: string;
    freebetId: string;
    declineReason: string;
    odds: Array<{ __typename?: 'BetOdd' } & BetOdd>;
    declineContext: Array<
        { __typename?: 'BetDeclineContextRestrictions' } & BetDeclineContextItem
    >;
    cashOutOrders: Array<{
        __typename?: 'CashOutOrder';
        id: string;
        betId: string;
        amount: string;
        refundAmount: string;
        currencyCode: string;
        status: {
            __typename?: 'CashOutOrderStatus';
            code: CashOutOrderStatusCode;
            reason: string;
        };
    }>;
    insurance?: {
        __typename?: 'BetInsurance';
        id: string;
        status: BetInsuranceStatus;
        refundAmount: string;
    } | null;
};

export type BetOdd = {
    __typename?: 'BetOdd';
    sportEventState: SportEventState;
    odd: { __typename?: 'Odd' } & Odd;
    market: { __typename?: 'Market' } & MarketBase;
    sportEvent: { __typename?: 'SportEvent' } & MatchBase;
};

export type BetTips = {
    __typename?: 'BetTips';
    sportEvent: { __typename?: 'SportEvent'; id: string };
    tips: Array<{
        __typename?: 'BetTip';
        id: string;
        sportEventID: string;
        marketId: string;
        oddId: string;
        name: string;
        text: string;
        locale: string;
        priority: number;
        market: {
            __typename?: 'Market';
            odds: Array<{ __typename?: 'Odd' } & Odd>;
        } & MarketBase;
    }>;
};

export type CategorizerChild = {
    __typename?: 'CategorizerItem';
    field: string;
    value: string;
    count: number;
    name: string;
    meta?: Array<{ __typename?: 'Pair'; name: string; value: string }>;
};

export type Categorizer = {
    __typename?: 'CategorizerItem';
    field: string;
    value: string;
    name: string;
    children?: Array<{
        __typename?: 'CategorizerItem';
        field: string;
        value: string;
        count: number;
        name: string;
        meta?: Array<{ __typename?: 'Pair'; name: string; value: string }>;
    }>;
    meta?: Array<{ __typename?: 'Pair'; name: string; value: string }>;
};

export type Competitor = {
    __typename?: 'SportEventCompetitor';
    name: string;
    type: CompetitorType;
    homeAway: CompetitorHomeAway;
    logo: string;
    path: string;
    templatePosition: number;
    slug: string;
    id: string;
    score: Array<{ __typename?: 'CompetitorScore' } & CompetitorScore>;
};

export type CompetitorScore = {
    __typename?: 'CompetitorScore';
    id: string;
    type: string;
    points: string;
    number: number;
    path: string;
};

export type FreebetConditionDetailsFragment = {
    __typename?: 'BetConditionDetails';
    type: BetConditionDetailsType;
    conditions: Array<
        | {
              __typename?: 'BetCombinationsCountBetDetailsCondition';
              type: BetDetailsConditionType;
              shouldMatchAllOdds: boolean;
              min?: number | null;
              max?: number | null;
          }
        | {
              __typename?: 'BetCompetitorBetDetailsCondition';
              type: BetDetailsConditionType;
              shouldMatchAllOdds: boolean;
              competitors: Array<{
                  __typename?: 'SportEventCompetitor';
                  id: string;
                  name: string;
              }>;
          }
        | {
              __typename?: 'BetMarketTypeBetDetailsCondition';
              type: BetDetailsConditionType;
              shouldMatchAllOdds: boolean;
              marketTypes: Array<string>;
          }
        | {
              __typename?: 'BetOddsCoefficientBetDetailsCondition';
              type: BetDetailsConditionType;
              shouldMatchAllOdds: boolean;
              minOddCoef?: string | null;
              maxOddCoef?: string | null;
          }
        | {
              __typename?: 'BetOddsCountBetDetailsCondition';
              type: BetDetailsConditionType;
              min?: number | null;
              max?: number | null;
          }
        | {
              __typename?: 'BetSportBetDetailsCondition';
              type: BetDetailsConditionType;
              shouldMatchAllOdds: boolean;
              sports: Array<{ __typename?: 'Sport'; id: string; name: string }>;
          }
        | {
              __typename?: 'BetSportEventBetDetailsCondition';
              type: BetDetailsConditionType;
              shouldMatchAllOdds: boolean;
              sportEvents: Array<{
                  __typename?: 'SportEvent';
                  id: string;
                  slug: string;
                  fixture: {
                      __typename?: 'SportEventFixture';
                      title: string;
                      sportId: string;
                      status: SportEventStatus;
                  };
              }>;
          }
        | { __typename?: 'BetStakeAmountRangeBetDetailsCondition' }
        | {
              __typename?: 'BetSystemSizeBetDetailsCondition';
              type: BetDetailsConditionType;
              min?: number | null;
              max?: number | null;
          }
        | {
              __typename?: 'BetTotalOddsCoefficientBetDetailsCondition';
              type: BetDetailsConditionType;
              minTotalOddsCoef?: string | null;
              maxTotalOddsCoef?: string | null;
          }
        | {
              __typename?: 'BetTournamentBetDetailsCondition';
              type: BetDetailsConditionType;
              shouldMatchAllOdds: boolean;
              tournaments: Array<{
                  __typename?: 'Tournament';
                  id: string;
                  name: string;
                  slug: string;
                  sportId: string;
              }>;
          }
    >;
};

export type FreebetFragment = {
    __typename?: 'Freebet';
    id: string;
    amount: string;
    expiresAt: string;
    betConditions: Array<
        | { __typename?: 'BetCompetitorCondition' }
        | { __typename?: 'BetCountryCondition' }
        | {
              __typename?: 'BetDetailsConditionAggregate';
              betDetails: Array<
                  {
                      __typename?: 'BetConditionDetails';
                  } & FreebetConditionDetailsFragment
              >;
          }
        | { __typename?: 'BetMarketTypeCondition' }
        | { __typename?: 'BetOddValueCondition' }
        | { __typename?: 'BetSportCondition' }
        | { __typename?: 'BetSportEventCondition' }
        | { __typename?: 'BetTournamentCondition' }
        | { __typename?: 'BetTypeCondition' }
    >;
};

export type SportEvent = {
    __typename?: 'SportEvent';
    id: string;
    disabled: boolean;
    providerId: string;
    hasMatchLog: boolean;
    slug: string;
    betStop: boolean;
    version: string;
    meta?: Array<{ __typename?: 'Pair'; name: string; value: string }>;
    fixture: {
        __typename?: 'SportEventFixture';
        score: string;
        title: string;
        status: SportEventStatus;
        type: SportEventType;
        startTime: string;
        sportId: string;
        liveCoverage: boolean;
        sport: {
            __typename?: 'Sport';
            id: string;
            name: string;
            tags: Array<SportTag>;
            slug: string;
        };
        tournament: {
            __typename?: 'Tournament';
            name: string;
            logo: string;
            showTournamentInfo?: boolean | null;
            dateStart: string;
            dateEnd: string;
            isLocalizationOverridden?: boolean | null;
            slug: string;
            sportId: string;
            masterId?: string;
            countryCode: string;
            description?: string;
            prizePool?: string;
            id: string;
        };
        competitors: Array<{
            __typename?: 'SportEventCompetitor';
            name: string;
            type: CompetitorType;
            homeAway: CompetitorHomeAway;
            logo: string;
            path: string;
            id: string;
            score?: Array<{ __typename?: 'CompetitorScore' } & CompetitorScore>;
        }>;
    };
    streams?: Array<{ __typename?: 'Stream' } & Stream>;
    markets: Array<{
        __typename?: 'Market';
        id: string;
        name: string;
        status: MarketStatus;
        typeId: number;
        priority: number;
        tags?: Array<string>;
        path: string;
        specifiers?: Array<{
            __typename?: 'Pair';
            name: string;
            value: string;
        }>;
        odds?: Array<{ __typename?: 'Odd' } & Odd>;
    }>;
};

export type MarketBase = {
    __typename?: 'Market';
    id: string;
    name: string;
    status: MarketStatus;
    typeId: number;
    priority: number;
    tags: Array<string>;
    path: string;
    specifiers: Array<{ __typename?: 'Pair'; name: string; value: string }>;
};

export type Market = {
    __typename?: 'Market';
    path: string;
    odds: Array<{ __typename?: 'Odd' } & Odd>;
} & MarketBase;

export type MatchBase = {
    __typename?: 'SportEvent';
    id: string;
    disabled: boolean;
    providerId: string;
    hasMatchLog: boolean;
    slug: string;
    betStop: boolean;
    version: string;
    meta: Array<{ __typename?: 'Pair'; name: string; value: string }>;
    fixture: { __typename?: 'SportEventFixture' } & MatchFixture;
};

export type MatchBaseWithOptionalMeta = {
    __typename?: 'SportEvent';
    id: string;
    disabled: boolean;
    providerId: string;
    hasMatchLog: boolean;
    slug: string;
    betStop: boolean;
    version: string;
    meta?: Array<{ __typename?: 'Pair'; name: string; value: string }>;
    fixture: { __typename?: 'SportEventFixture' } & MatchFixture;
    streams: Array<{ __typename?: 'Stream' } & Stream>;
};

export type MatchFixture = {
    __typename?: 'SportEventFixture';
    score: string;
    title: string;
    status: SportEventStatus;
    type: SportEventType;
    startTime: string;
    sportId: string;
    liveCoverage: boolean;
    sport: {
        __typename?: 'Sport';
        id: string;
        name: string;
        tags: Array<SportTag>;
        slug: string;
    };
    tournament: { __typename?: 'Tournament' } & Tournament;
    competitors: Array<{ __typename?: 'SportEventCompetitor' } & Competitor>;
};

export type Match = {
    __typename?: 'SportEvent';
    markets: Array<{ __typename?: 'Market' } & Market>;
} & MatchBase;

export type MatchWithOptionalMeta = {
    __typename?: 'SportEvent';
    markets: Array<{ __typename?: 'Market' } & Market>;
} & MatchBaseWithOptionalMeta;

export type Odd = {
    __typename?: 'Odd';
    id: string;
    name: string;
    value: string;
    isActive: boolean;
    status: OddStatus;
    competitorIds: Array<string>;
    path: string;
};

export type BetDelayRestriction = {
    __typename: 'BetDelayRestriction';
    type: RestrictionType;
    sportEventId: string;
    delay: number;
};

export type BetIntervalRestriction = {
    __typename: 'BetIntervalRestriction';
    type: RestrictionType;
    sportEventId: string;
    timeToWait: number;
};

export type BetProviderRestriction = {
    __typename: 'BetProviderRestriction';
    type: RestrictionType;
    betProvider: string;
};

export type BetSelectionExistenceRestriction = {
    __typename?: 'BetSelectionExistenceRestriction';
    type: RestrictionType;
    marketId: string;
    oddId: string;
    sportEventId: string;
};

export type BetStatusRestriction = {
    __typename?: 'BetStatusRestriction';
    type: RestrictionType;
    betStatus: BetStatus;
};

export type BetTournamentDuplicateCompetitorIDsRestriction = {
    __typename: 'BetTournamentDuplicateCompetitorIDsRestriction';
    type: RestrictionType;
};

export type BetTournamentIncompatibleMarketIDsRestriction = {
    __typename: 'BetTournamentIncompatibleMarketIDsRestriction';
    type: RestrictionType;
};

export type BetTypeRestriction = {
    __typename: 'BetTypeRestriction';
    type: RestrictionType;
    sportEventId: string;
    betType: BetType;
};

export type CashOutAmountLimitRestriction = {
    __typename?: 'CashOutAmountLimitRestriction';
    type: RestrictionType;
    cashOutAmount: string;
    cashOutMaxAmount: string;
    cashOutMinAmount: string;
};

export type CashOutBetSelectionsMismatchRestriction = {
    __typename?: 'CashOutBetSelectionsMismatchRestriction';
    type: RestrictionType;
    cashOutSelections: number;
    betSelections: number;
};

export type CashOutBetTypeRestriction = {
    __typename?: 'CashOutBetTypeRestriction';
    type: RestrictionType;
    cashOutBetType: string;
};

export type CashOutOrderStatusRestriction = {
    __typename?: 'CashOutOrderStatusRestriction';
    type: RestrictionType;
    cashOutOrderId: string;
    cashOutOrderStatus: CashOutOrderStatusCode;
};

export type CashOutRefundAmountRestriction = {
    __typename?: 'CashOutRefundAmountRestriction';
    type: RestrictionType;
    cashOutCalculatedRefundAmount: string;
    cashOutRefundAmount: string;
};

type CashOutRestrictions_BaselineSelectionExistenceRestriction_ = {
    __typename?: 'BaselineSelectionExistenceRestriction';
};

type CashOutRestrictions_BetProviderRestriction_ = {
    __typename?: 'BetProviderRestriction';
} & BetProviderRestriction;

type CashOutRestrictions_BetSelectionExistenceRestriction_ = {
    __typename?: 'BetSelectionExistenceRestriction';
} & BetSelectionExistenceRestriction;

type CashOutRestrictions_BetStatusRestriction_ = {
    __typename?: 'BetStatusRestriction';
} & BetStatusRestriction;

type CashOutRestrictions_CashOutAmountLimitRestriction_ = {
    __typename?: 'CashOutAmountLimitRestriction';
} & CashOutAmountLimitRestriction;

type CashOutRestrictions_CashOutBetSelectionsMismatchRestriction_ = {
    __typename?: 'CashOutBetSelectionsMismatchRestriction';
} & CashOutBetSelectionsMismatchRestriction;

type CashOutRestrictions_CashOutBetTypeRestriction_ = {
    __typename?: 'CashOutBetTypeRestriction';
} & CashOutBetTypeRestriction;

type CashOutRestrictions_CashOutOrderFreebetRestriction_ = {
    __typename?: 'CashOutOrderFreebetRestriction';
};

type CashOutRestrictions_CashOutOrderStatusRestriction_ = {
    __typename?: 'CashOutOrderStatusRestriction';
} & CashOutOrderStatusRestriction;

type CashOutRestrictions_CashOutRefundAmountRestriction_ = {
    __typename?: 'CashOutRefundAmountRestriction';
} & CashOutRefundAmountRestriction;

type CashOutRestrictions_CashOutUnavailableRestriction_ = {
    __typename?: 'CashOutUnavailableRestriction';
} & CashOutUnavailableRestriction;

type CashOutRestrictions_MarketDefectiveRestriction_ = {
    __typename?: 'MarketDefectiveRestriction';
} & MarketDefectiveRestriction;

type CashOutRestrictions_MarketExistenceRestriction_ = {
    __typename?: 'MarketExistenceRestriction';
} & MarketExistenceRestriction;

type CashOutRestrictions_MarketStatusRestriction_ = {
    __typename?: 'MarketStatusRestriction';
} & MarketStatusRestriction;

type CashOutRestrictions_OddExistenceRestriction_ = {
    __typename?: 'OddExistenceRestriction';
} & OddExistenceRestriction;

type CashOutRestrictions_OddStatusRestriction_ = {
    __typename?: 'OddStatusRestriction';
} & OddStatusRestriction;

type CashOutRestrictions_PlayerLimitRestriction_ = {
    __typename?: 'PlayerLimitRestriction';
};

type CashOutRestrictions_SelectionValueRestriction_ = {
    __typename?: 'SelectionValueRestriction';
} & SelectionValueRestriction;

type CashOutRestrictions_SportEventBetStopRestriction_ = {
    __typename?: 'SportEventBetStopRestriction';
};

type CashOutRestrictions_SportEventExistenceRestriction_ = {
    __typename?: 'SportEventExistenceRestriction';
} & SportEventExistenceRestriction;

type CashOutRestrictions_SportEventStatusRestriction_ = {
    __typename?: 'SportEventStatusRestriction';
} & SportEventStatusRestriction;

type CashOutRestrictions_SportEventTypeRestriction_ = {
    __typename?: 'SportEventTypeRestriction';
};

export type CashOutRestrictions =
    | CashOutRestrictions_BaselineSelectionExistenceRestriction_
    | CashOutRestrictions_BetProviderRestriction_
    | CashOutRestrictions_BetSelectionExistenceRestriction_
    | CashOutRestrictions_BetStatusRestriction_
    | CashOutRestrictions_CashOutAmountLimitRestriction_
    | CashOutRestrictions_CashOutBetSelectionsMismatchRestriction_
    | CashOutRestrictions_CashOutBetTypeRestriction_
    | CashOutRestrictions_CashOutOrderFreebetRestriction_
    | CashOutRestrictions_CashOutOrderStatusRestriction_
    | CashOutRestrictions_CashOutRefundAmountRestriction_
    | CashOutRestrictions_CashOutUnavailableRestriction_
    | CashOutRestrictions_MarketDefectiveRestriction_
    | CashOutRestrictions_MarketExistenceRestriction_
    | CashOutRestrictions_MarketStatusRestriction_
    | CashOutRestrictions_OddExistenceRestriction_
    | CashOutRestrictions_OddStatusRestriction_
    | CashOutRestrictions_PlayerLimitRestriction_
    | CashOutRestrictions_SelectionValueRestriction_
    | CashOutRestrictions_SportEventBetStopRestriction_
    | CashOutRestrictions_SportEventExistenceRestriction_
    | CashOutRestrictions_SportEventStatusRestriction_
    | CashOutRestrictions_SportEventTypeRestriction_;

export type CashOutUnavailableRestriction = {
    __typename?: 'CashOutUnavailableRestriction';
    type: RestrictionType;
    reason: string;
};

export type MarketDefectiveRestriction = {
    __typename: 'MarketDefectiveRestriction';
    type: RestrictionType;
    sportEventId: string;
    marketId: string;
};

export type MarketExistenceRestriction = {
    __typename: 'MarketExistenceRestriction';
    type: RestrictionType;
    sportEventId: string;
    marketId: string;
};

export type MarketStatusRestriction = {
    __typename: 'MarketStatusRestriction';
    type: RestrictionType;
    sportEventId: string;
    marketId: string;
    marketStatus: MarketStatus;
};

export type MaxBetRestriction = {
    __typename: 'MaxBetRestriction';
    type: RestrictionType;
    maxBet: number;
};

export type MinBetRestriction = {
    __typename: 'MinBetRestriction';
    type: RestrictionType;
    minBet: number;
};

export type NotEnoughBalanceRestriction = {
    __typename: 'NotEnoughBalanceRestriction';
    type: RestrictionType;
    actualBalance: string;
};

export type OddExistenceRestriction = {
    __typename: 'OddExistenceRestriction';
    type: RestrictionType;
    sportEventId: string;
    marketId: string;
    oddId: string;
};

export type OddStatusRestriction = {
    __typename: 'OddStatusRestriction';
    type: RestrictionType;
    sportEventId: string;
    marketId: string;
    oddId: string;
    status: OddStatus;
};

export type PlayerLimitRestriction = {
    __typename: 'PlayerLimitRestriction';
    type: RestrictionType;
    reason?: string | null;
};

type Restriction_BetDelayRestriction_ = {
    __typename?: 'BetDelayRestriction';
} & BetDelayRestriction;

type Restriction_BetIntervalRestriction_ = {
    __typename?: 'BetIntervalRestriction';
} & BetIntervalRestriction;

type Restriction_BetProviderRestriction_ = {
    __typename?: 'BetProviderRestriction';
} & BetProviderRestriction;

type Restriction_BetTournamentDuplicateCompetitorIDsRestriction_ = {
    __typename?: 'BetTournamentDuplicateCompetitorIDsRestriction';
} & BetTournamentDuplicateCompetitorIDsRestriction;

type Restriction_BetTournamentIncompatibleMarketIDsRestriction_ = {
    __typename?: 'BetTournamentIncompatibleMarketIDsRestriction';
} & BetTournamentIncompatibleMarketIDsRestriction;

type Restriction_BetTypeRestriction_ = {
    __typename?: 'BetTypeRestriction';
} & BetTypeRestriction;

type Restriction_FreebetAmountRestriction_ = {
    __typename?: 'FreebetAmountRestriction';
};

type Restriction_FreebetNotApplicableRestriction_ = {
    __typename?: 'FreebetNotApplicableRestriction';
};

type Restriction_FreebetStatusRestriction_ = {
    __typename?: 'FreebetStatusRestriction';
};

type Restriction_InsuranceNotApplicableRestriction_ = {
    __typename?: 'InsuranceNotApplicableRestriction';
};

type Restriction_InsuranceStatusRestriction_ = {
    __typename?: 'InsuranceStatusRestriction';
};

type Restriction_InsuranceValueRestriction_ = {
    __typename?: 'InsuranceValueRestriction';
};

type Restriction_InternalErrorRestriction_ = {
    __typename?: 'InternalErrorRestriction';
};

type Restriction_MarketDefectiveRestriction_ = {
    __typename?: 'MarketDefectiveRestriction';
} & MarketDefectiveRestriction;

type Restriction_MarketExistenceRestriction_ = {
    __typename?: 'MarketExistenceRestriction';
} & MarketExistenceRestriction;

type Restriction_MarketStatusRestriction_ = {
    __typename?: 'MarketStatusRestriction';
} & MarketStatusRestriction;

type Restriction_MaxBetRestriction_ = {
    __typename?: 'MaxBetRestriction';
} & MaxBetRestriction;

type Restriction_MinBetRestriction_ = {
    __typename?: 'MinBetRestriction';
} & MinBetRestriction;

type Restriction_NotEnoughBalanceRestriction_ = {
    __typename?: 'NotEnoughBalanceRestriction';
} & NotEnoughBalanceRestriction;

type Restriction_OddExistenceRestriction_ = {
    __typename?: 'OddExistenceRestriction';
} & OddExistenceRestriction;

type Restriction_OddStatusRestriction_ = {
    __typename?: 'OddStatusRestriction';
} & OddStatusRestriction;

type Restriction_PlayerLimitRestriction_ = {
    __typename?: 'PlayerLimitRestriction';
} & PlayerLimitRestriction;

type Restriction_SelectionValueRestriction_ = {
    __typename?: 'SelectionValueRestriction';
} & SelectionValueRestriction;

type Restriction_SelectionsCountRestriction_ = {
    __typename?: 'SelectionsCountRestriction';
};

type Restriction_SportEventBetStopRestriction_ = {
    __typename?: 'SportEventBetStopRestriction';
} & SportEventBetStopRestriction;

type Restriction_SportEventExistenceRestriction_ = {
    __typename?: 'SportEventExistenceRestriction';
} & SportEventExistenceRestriction;

type Restriction_SportEventStatusRestriction_ = {
    __typename?: 'SportEventStatusRestriction';
} & SportEventStatusRestriction;

type Restriction_WlDefinedRestriction_ = {
    __typename?: 'WLDefinedRestriction';
};

export type Restriction =
    | Restriction_BetDelayRestriction_
    | Restriction_BetIntervalRestriction_
    | Restriction_BetProviderRestriction_
    | Restriction_BetTournamentDuplicateCompetitorIDsRestriction_
    | Restriction_BetTournamentIncompatibleMarketIDsRestriction_
    | Restriction_BetTypeRestriction_
    | Restriction_FreebetAmountRestriction_
    | Restriction_FreebetNotApplicableRestriction_
    | Restriction_FreebetStatusRestriction_
    | Restriction_InsuranceNotApplicableRestriction_
    | Restriction_InsuranceStatusRestriction_
    | Restriction_InsuranceValueRestriction_
    | Restriction_InternalErrorRestriction_
    | Restriction_MarketDefectiveRestriction_
    | Restriction_MarketExistenceRestriction_
    | Restriction_MarketStatusRestriction_
    | Restriction_MaxBetRestriction_
    | Restriction_MinBetRestriction_
    | Restriction_NotEnoughBalanceRestriction_
    | Restriction_OddExistenceRestriction_
    | Restriction_OddStatusRestriction_
    | Restriction_PlayerLimitRestriction_
    | Restriction_SelectionValueRestriction_
    | Restriction_SelectionsCountRestriction_
    | Restriction_SportEventBetStopRestriction_
    | Restriction_SportEventExistenceRestriction_
    | Restriction_SportEventStatusRestriction_
    | Restriction_WlDefinedRestriction_;

export type SelectionValueRestriction = {
    __typename: 'SelectionValueRestriction';
    type: RestrictionType;
    sportEventId: string;
    marketId: string;
    oddId: string;
    value: number;
};

export type SportEventBetStopRestriction = {
    __typename: 'SportEventBetStopRestriction';
    type: RestrictionType;
    sportEventId: string;
};

export type SportEventExistenceRestriction = {
    __typename: 'SportEventExistenceRestriction';
    type: RestrictionType;
    sportEventId: string;
};

export type SportEventStatusRestriction = {
    __typename: 'SportEventStatusRestriction';
    type: RestrictionType;
    sportEventId: string;
    sportEventStatus: SportEventStatus;
};

export type AmericanFootballOverview = {
    __typename?: 'SportEventOverview';
    id: string;
    checksum: string;
    overview?:
        | ({ __typename?: 'AmericanFootballOverview' } & AmericanFootball)
        | { __typename?: 'BadmintonOverview' }
        | { __typename?: 'BaseballOverview' }
        | { __typename?: 'BasketballOverview' }
        | { __typename?: 'BattlegroundsMultiCompetitiveOverview' }
        | { __typename?: 'BattlegroundsOverview' }
        | { __typename?: 'BeachVolleyballOverview' }
        | { __typename?: 'BrawlStarsOverview' }
        | { __typename?: 'CSGOOverview' }
        | { __typename?: 'CallOfDutyWarzoneOverview' }
        | { __typename?: 'CricketOverview' }
        | { __typename?: 'Dota2Overview' }
        | { __typename?: 'EBasketballOverview' }
        | { __typename?: 'EFootballOverview' }
        | { __typename?: 'EIceHockeyOverview' }
        | { __typename?: 'EStreetballOverview' }
        | { __typename?: 'ETennisOverview' }
        | { __typename?: 'EUFCOverview' }
        | { __typename?: 'EVolleyballOverview' }
        | { __typename?: 'FootballOverview' }
        | { __typename?: 'FortniteOverview' }
        | { __typename?: 'FutsalOverview' }
        | { __typename?: 'HandballOverview' }
        | { __typename?: 'HearthstoneOverview' }
        | { __typename?: 'IceHockeyOverview' }
        | { __typename?: 'IndoorSoccerOverview' }
        | { __typename?: 'KabaddiOverview' }
        | { __typename?: 'KingOfGloryOverview' }
        | { __typename?: 'LOLOverview' }
        | { __typename?: 'LOLWildriftOverview' }
        | { __typename?: 'MMAOverview' }
        | { __typename?: 'MortalKombatOverview' }
        | { __typename?: 'OverwatchOverview' }
        | { __typename?: 'RainbowSixOverview' }
        | { __typename?: 'RocketLeagueOverview' }
        | { __typename?: 'StarCraft1Overview' }
        | { __typename?: 'StarCraftOverview' }
        | { __typename?: 'TableTennisOverview' }
        | { __typename?: 'TennisOverview' }
        | { __typename?: 'ValorantOverview' }
        | { __typename?: 'VolleyballOverview' }
        | null;
};

export type AmericanFootball = {
    __typename?: 'AmericanFootballOverview';
    currentPeriod: number;
    attackSide: TeamHomeAway;
    timer: { __typename?: 'Timer'; isActive: boolean; time: number };
    teams: {
        __typename?: 'AmericanFootballOverviewTeams';
        home: { __typename?: 'AmericanFootballOverviewTeam'; score: number };
        away: { __typename?: 'AmericanFootballOverviewTeam'; score: number };
    };
    periods?: Array<{
        __typename?: 'AmericanFootballOverviewPeriod';
        number: number;
        totalPointsHome: number;
        totalPointsAway: number;
    }> | null;
};

export type BasketballOverview = {
    __typename?: 'SportEventOverview';
    id: string;
    checksum: string;
    overview?:
        | { __typename?: 'AmericanFootballOverview' }
        | { __typename?: 'BadmintonOverview' }
        | { __typename?: 'BaseballOverview' }
        | ({ __typename?: 'BasketballOverview' } & Basketball)
        | { __typename?: 'BattlegroundsMultiCompetitiveOverview' }
        | { __typename?: 'BattlegroundsOverview' }
        | { __typename?: 'BeachVolleyballOverview' }
        | { __typename?: 'BrawlStarsOverview' }
        | { __typename?: 'CSGOOverview' }
        | { __typename?: 'CallOfDutyWarzoneOverview' }
        | { __typename?: 'CricketOverview' }
        | { __typename?: 'Dota2Overview' }
        | { __typename?: 'EBasketballOverview' }
        | { __typename?: 'EFootballOverview' }
        | { __typename?: 'EIceHockeyOverview' }
        | { __typename?: 'EStreetballOverview' }
        | { __typename?: 'ETennisOverview' }
        | { __typename?: 'EUFCOverview' }
        | { __typename?: 'EVolleyballOverview' }
        | { __typename?: 'FootballOverview' }
        | { __typename?: 'FortniteOverview' }
        | { __typename?: 'FutsalOverview' }
        | { __typename?: 'HandballOverview' }
        | { __typename?: 'HearthstoneOverview' }
        | { __typename?: 'IceHockeyOverview' }
        | { __typename?: 'IndoorSoccerOverview' }
        | { __typename?: 'KabaddiOverview' }
        | { __typename?: 'KingOfGloryOverview' }
        | { __typename?: 'LOLOverview' }
        | { __typename?: 'LOLWildriftOverview' }
        | { __typename?: 'MMAOverview' }
        | { __typename?: 'MortalKombatOverview' }
        | { __typename?: 'OverwatchOverview' }
        | { __typename?: 'RainbowSixOverview' }
        | { __typename?: 'RocketLeagueOverview' }
        | { __typename?: 'StarCraft1Overview' }
        | { __typename?: 'StarCraftOverview' }
        | { __typename?: 'TableTennisOverview' }
        | { __typename?: 'TennisOverview' }
        | { __typename?: 'ValorantOverview' }
        | { __typename?: 'VolleyballOverview' }
        | null;
};

export type Basketball = {
    __typename?: 'BasketballOverview';
    quarterFormat: number;
    currentQuarter: number;
    pause: boolean;
    timer: { __typename?: 'Timer'; isActive: boolean; time: number };
    quarters?: Array<{
        __typename?: 'BasketballOverviewQuarter';
        number: number;
        home: { __typename?: 'BasketballQuarterTeam'; score: number };
        away: { __typename?: 'BasketballQuarterTeam'; score: number };
    }> | null;
    teams: {
        __typename?: 'BasketballOverviewTeams';
        home: {
            __typename?: 'BasketballOverviewTeam';
            score: number;
            fouls: number;
        };
        away: {
            __typename?: 'BasketballOverviewTeam';
            score: number;
            fouls: number;
        };
    };
};

export type BrawlStarsOverview = {
    __typename?: 'SportEventOverview';
    id: string;
    checksum: string;
    overview?:
        | { __typename?: 'AmericanFootballOverview' }
        | { __typename?: 'BadmintonOverview' }
        | { __typename?: 'BaseballOverview' }
        | { __typename?: 'BasketballOverview' }
        | { __typename?: 'BattlegroundsMultiCompetitiveOverview' }
        | { __typename?: 'BattlegroundsOverview' }
        | { __typename?: 'BeachVolleyballOverview' }
        | ({ __typename?: 'BrawlStarsOverview' } & BrawlStars)
        | { __typename?: 'CSGOOverview' }
        | { __typename?: 'CallOfDutyWarzoneOverview' }
        | { __typename?: 'CricketOverview' }
        | { __typename?: 'Dota2Overview' }
        | { __typename?: 'EBasketballOverview' }
        | { __typename?: 'EFootballOverview' }
        | { __typename?: 'EIceHockeyOverview' }
        | { __typename?: 'EStreetballOverview' }
        | { __typename?: 'ETennisOverview' }
        | { __typename?: 'EUFCOverview' }
        | { __typename?: 'EVolleyballOverview' }
        | { __typename?: 'FootballOverview' }
        | { __typename?: 'FortniteOverview' }
        | { __typename?: 'FutsalOverview' }
        | { __typename?: 'HandballOverview' }
        | { __typename?: 'HearthstoneOverview' }
        | { __typename?: 'IceHockeyOverview' }
        | { __typename?: 'IndoorSoccerOverview' }
        | { __typename?: 'KabaddiOverview' }
        | { __typename?: 'KingOfGloryOverview' }
        | { __typename?: 'LOLOverview' }
        | { __typename?: 'LOLWildriftOverview' }
        | { __typename?: 'MMAOverview' }
        | { __typename?: 'MortalKombatOverview' }
        | { __typename?: 'OverwatchOverview' }
        | { __typename?: 'RainbowSixOverview' }
        | { __typename?: 'RocketLeagueOverview' }
        | { __typename?: 'StarCraft1Overview' }
        | { __typename?: 'StarCraftOverview' }
        | { __typename?: 'TableTennisOverview' }
        | { __typename?: 'TennisOverview' }
        | { __typename?: 'ValorantOverview' }
        | { __typename?: 'VolleyballOverview' }
        | null;
};

export type BrawlStars = {
    __typename?: 'BrawlStarsOverview';
    currentMap: number;
    bestOf: number;
    teams: {
        __typename?: 'BrawlStarsOverviewTeams';
        home: { __typename?: 'BrawlStarsOverviewTeam'; score: number };
        away: { __typename?: 'BrawlStarsOverviewTeam'; score: number };
    };
    maps?: Array<{
        __typename?: 'BrawlStarsOverviewMap';
        number: number;
        winner: TeamHomeAway;
        firstBlood: WinnerHomeAway;
    }> | null;
};

export type CricketOverview = {
    __typename?: 'SportEventOverview';
    id: string;
    checksum: string;
    overview?:
        | { __typename?: 'AmericanFootballOverview' }
        | { __typename?: 'BadmintonOverview' }
        | { __typename?: 'BaseballOverview' }
        | { __typename?: 'BasketballOverview' }
        | { __typename?: 'BattlegroundsMultiCompetitiveOverview' }
        | { __typename?: 'BattlegroundsOverview' }
        | { __typename?: 'BeachVolleyballOverview' }
        | { __typename?: 'BrawlStarsOverview' }
        | { __typename?: 'CSGOOverview' }
        | { __typename?: 'CallOfDutyWarzoneOverview' }
        | ({ __typename?: 'CricketOverview' } & Cricket)
        | { __typename?: 'Dota2Overview' }
        | { __typename?: 'EBasketballOverview' }
        | { __typename?: 'EFootballOverview' }
        | { __typename?: 'EIceHockeyOverview' }
        | { __typename?: 'EStreetballOverview' }
        | { __typename?: 'ETennisOverview' }
        | { __typename?: 'EUFCOverview' }
        | { __typename?: 'EVolleyballOverview' }
        | { __typename?: 'FootballOverview' }
        | { __typename?: 'FortniteOverview' }
        | { __typename?: 'FutsalOverview' }
        | { __typename?: 'HandballOverview' }
        | { __typename?: 'HearthstoneOverview' }
        | { __typename?: 'IceHockeyOverview' }
        | { __typename?: 'IndoorSoccerOverview' }
        | { __typename?: 'KabaddiOverview' }
        | { __typename?: 'KingOfGloryOverview' }
        | { __typename?: 'LOLOverview' }
        | { __typename?: 'LOLWildriftOverview' }
        | { __typename?: 'MMAOverview' }
        | { __typename?: 'MortalKombatOverview' }
        | { __typename?: 'OverwatchOverview' }
        | { __typename?: 'RainbowSixOverview' }
        | { __typename?: 'RocketLeagueOverview' }
        | { __typename?: 'StarCraft1Overview' }
        | { __typename?: 'StarCraftOverview' }
        | { __typename?: 'TableTennisOverview' }
        | { __typename?: 'TennisOverview' }
        | { __typename?: 'ValorantOverview' }
        | { __typename?: 'VolleyballOverview' }
        | null;
};

export type Cricket = {
    __typename?: 'CricketOverview';
    gameFormat: CricketGameFormat;
    currentInning: number;
    homePoints: number;
    awayPoints: number;
    innings?: Array<{
        __typename?: 'CricketInning';
        type: CricketInningType;
        number: number;
        halfNumber: number;
        team: TeamHomeAway;
        points: number;
        outs: number;
        strikes: number;
        homeRuns: number;
    }> | null;
};

export type CsgoOverview = {
    __typename?: 'SportEventOverview';
    id: string;
    checksum: string;
    overview?:
        | { __typename?: 'AmericanFootballOverview' }
        | { __typename?: 'BadmintonOverview' }
        | { __typename?: 'BaseballOverview' }
        | { __typename?: 'BasketballOverview' }
        | { __typename?: 'BattlegroundsMultiCompetitiveOverview' }
        | { __typename?: 'BattlegroundsOverview' }
        | { __typename?: 'BeachVolleyballOverview' }
        | { __typename?: 'BrawlStarsOverview' }
        | ({ __typename?: 'CSGOOverview' } & Csgo)
        | { __typename?: 'CallOfDutyWarzoneOverview' }
        | { __typename?: 'CricketOverview' }
        | { __typename?: 'Dota2Overview' }
        | { __typename?: 'EBasketballOverview' }
        | { __typename?: 'EFootballOverview' }
        | { __typename?: 'EIceHockeyOverview' }
        | { __typename?: 'EStreetballOverview' }
        | { __typename?: 'ETennisOverview' }
        | { __typename?: 'EUFCOverview' }
        | { __typename?: 'EVolleyballOverview' }
        | { __typename?: 'FootballOverview' }
        | { __typename?: 'FortniteOverview' }
        | { __typename?: 'FutsalOverview' }
        | { __typename?: 'HandballOverview' }
        | { __typename?: 'HearthstoneOverview' }
        | { __typename?: 'IceHockeyOverview' }
        | { __typename?: 'IndoorSoccerOverview' }
        | { __typename?: 'KabaddiOverview' }
        | { __typename?: 'KingOfGloryOverview' }
        | { __typename?: 'LOLOverview' }
        | { __typename?: 'LOLWildriftOverview' }
        | { __typename?: 'MMAOverview' }
        | { __typename?: 'MortalKombatOverview' }
        | { __typename?: 'OverwatchOverview' }
        | { __typename?: 'RainbowSixOverview' }
        | { __typename?: 'RocketLeagueOverview' }
        | { __typename?: 'StarCraft1Overview' }
        | { __typename?: 'StarCraftOverview' }
        | { __typename?: 'TableTennisOverview' }
        | { __typename?: 'TennisOverview' }
        | { __typename?: 'ValorantOverview' }
        | { __typename?: 'VolleyballOverview' }
        | null;
};

export type Csgo = {
    __typename?: 'CSGOOverview';
    currentMap: number;
    currentRound: number;
    mapName: string;
    bestOf: number;
    gameState: string;
    pause: boolean;
    matchFormat: CsgoMatchFormat;
    bomb: { __typename?: 'CSGOBomb'; isPlanted: boolean; time?: number | null };
    timer: { __typename?: 'Timer'; isActive: boolean; time: number };
    teams: {
        __typename?: 'CSGOOverviewTeams';
        home: {
            __typename?: 'CSGOOverviewTeam';
            currentSide: CsgoTeamSide;
            score: number;
        };
        away: {
            __typename?: 'CSGOOverviewTeam';
            currentSide: CsgoTeamSide;
            score: number;
        };
    };
    maps?: Array<{
        __typename?: 'CSGOOverviewMap';
        number: number;
        map: CsgoMap;
        winner: TeamHomeAway;
        home: { __typename?: 'CSGOMapOverviewTeamInfo'; score: number };
        away: { __typename?: 'CSGOMapOverviewTeamInfo'; score: number };
    }> | null;
};

export type Dota2Overview = {
    __typename?: 'SportEventOverview';
    id: string;
    checksum: string;
    overview?:
        | { __typename?: 'AmericanFootballOverview' }
        | { __typename?: 'BadmintonOverview' }
        | { __typename?: 'BaseballOverview' }
        | { __typename?: 'BasketballOverview' }
        | { __typename?: 'BattlegroundsMultiCompetitiveOverview' }
        | { __typename?: 'BattlegroundsOverview' }
        | { __typename?: 'BeachVolleyballOverview' }
        | { __typename?: 'BrawlStarsOverview' }
        | { __typename?: 'CSGOOverview' }
        | { __typename?: 'CallOfDutyWarzoneOverview' }
        | { __typename?: 'CricketOverview' }
        | ({ __typename?: 'Dota2Overview' } & Dota2)
        | { __typename?: 'EBasketballOverview' }
        | { __typename?: 'EFootballOverview' }
        | { __typename?: 'EIceHockeyOverview' }
        | { __typename?: 'EStreetballOverview' }
        | { __typename?: 'ETennisOverview' }
        | { __typename?: 'EUFCOverview' }
        | { __typename?: 'EVolleyballOverview' }
        | { __typename?: 'FootballOverview' }
        | { __typename?: 'FortniteOverview' }
        | { __typename?: 'FutsalOverview' }
        | { __typename?: 'HandballOverview' }
        | { __typename?: 'HearthstoneOverview' }
        | { __typename?: 'IceHockeyOverview' }
        | { __typename?: 'IndoorSoccerOverview' }
        | { __typename?: 'KabaddiOverview' }
        | { __typename?: 'KingOfGloryOverview' }
        | { __typename?: 'LOLOverview' }
        | { __typename?: 'LOLWildriftOverview' }
        | { __typename?: 'MMAOverview' }
        | { __typename?: 'MortalKombatOverview' }
        | { __typename?: 'OverwatchOverview' }
        | { __typename?: 'RainbowSixOverview' }
        | { __typename?: 'RocketLeagueOverview' }
        | { __typename?: 'StarCraft1Overview' }
        | { __typename?: 'StarCraftOverview' }
        | { __typename?: 'TableTennisOverview' }
        | { __typename?: 'TennisOverview' }
        | { __typename?: 'ValorantOverview' }
        | { __typename?: 'VolleyballOverview' }
        | null;
};

export type Dota2 = {
    __typename?: 'Dota2Overview';
    currentMap: number;
    currentGoldLead: number;
    bestOf: number;
    timer: { __typename?: 'Timer'; isActive: boolean; time: number };
    teams: {
        __typename?: 'Dota2OverviewTeams';
        home: { __typename?: 'Dota2OverviewTeam'; score: number };
        away: { __typename?: 'Dota2OverviewTeam'; score: number };
    };
    maps?: Array<{
        __typename?: 'Dota2OverviewMap';
        number: number;
        goldLead: number;
        teams: {
            __typename?: 'Dota2MapOverviewTeams';
            home: {
                __typename?: 'Dota2MapOverviewTeam';
                side: Dota2TeamSide;
                kills: number;
            };
            away: {
                __typename?: 'Dota2MapOverviewTeam';
                side: Dota2TeamSide;
                kills: number;
            };
        };
    }> | null;
};

export type EFootballOverview = {
    __typename?: 'SportEventOverview';
    id: string;
    checksum: string;
    overview?:
        | { __typename?: 'AmericanFootballOverview' }
        | { __typename?: 'BadmintonOverview' }
        | { __typename?: 'BaseballOverview' }
        | { __typename?: 'BasketballOverview' }
        | { __typename?: 'BattlegroundsMultiCompetitiveOverview' }
        | { __typename?: 'BattlegroundsOverview' }
        | { __typename?: 'BeachVolleyballOverview' }
        | { __typename?: 'BrawlStarsOverview' }
        | { __typename?: 'CSGOOverview' }
        | { __typename?: 'CallOfDutyWarzoneOverview' }
        | { __typename?: 'CricketOverview' }
        | { __typename?: 'Dota2Overview' }
        | { __typename?: 'EBasketballOverview' }
        | ({ __typename?: 'EFootballOverview' } & EFootball)
        | { __typename?: 'EIceHockeyOverview' }
        | { __typename?: 'EStreetballOverview' }
        | { __typename?: 'ETennisOverview' }
        | { __typename?: 'EUFCOverview' }
        | { __typename?: 'EVolleyballOverview' }
        | { __typename?: 'FootballOverview' }
        | { __typename?: 'FortniteOverview' }
        | { __typename?: 'FutsalOverview' }
        | { __typename?: 'HandballOverview' }
        | { __typename?: 'HearthstoneOverview' }
        | { __typename?: 'IceHockeyOverview' }
        | { __typename?: 'IndoorSoccerOverview' }
        | { __typename?: 'KabaddiOverview' }
        | { __typename?: 'KingOfGloryOverview' }
        | { __typename?: 'LOLOverview' }
        | { __typename?: 'LOLWildriftOverview' }
        | { __typename?: 'MMAOverview' }
        | { __typename?: 'MortalKombatOverview' }
        | { __typename?: 'OverwatchOverview' }
        | { __typename?: 'RainbowSixOverview' }
        | { __typename?: 'RocketLeagueOverview' }
        | { __typename?: 'StarCraft1Overview' }
        | { __typename?: 'StarCraftOverview' }
        | { __typename?: 'TableTennisOverview' }
        | { __typename?: 'TennisOverview' }
        | { __typename?: 'ValorantOverview' }
        | { __typename?: 'VolleyballOverview' }
        | null;
};

export type EFootball = {
    __typename?: 'EFootballOverview';
    pause: boolean;
    currentEFootballMatchPart: EFootballPeriod;
    timer: { __typename?: 'Timer'; isActive: boolean; time: number };
    teams: {
        __typename?: 'EFootballOverviewTeams';
        home: {
            __typename?: 'EFootballOverviewTeamTotal';
            score: number;
            yellowCards: number;
            redCards: number;
        };
        away: {
            __typename?: 'EFootballOverviewTeamTotal';
            score: number;
            yellowCards: number;
            redCards: number;
        };
    };
    periods?: Array<{
        __typename?: 'EFootballOverviewPeriod';
        periodEFootball: EFootballPeriod;
        home: {
            __typename?: 'EFootballOverviewPeriodTeam';
            score: number;
            penalties?: Array<{
                __typename?: 'EFootballOverviewPenalties';
                number: number;
                statusEFootball: PenaltyStatus;
            }> | null;
        };
        away: {
            __typename?: 'EFootballOverviewPeriodTeam';
            score: number;
            penalties?: Array<{
                __typename?: 'EFootballOverviewPenalties';
                number: number;
                statusEFootball: PenaltyStatus;
            }> | null;
        };
    }> | null;
};

export type EStreetballOverview = {
    __typename?: 'SportEventOverview';
    id: string;
    checksum: string;
    overview?:
        | { __typename?: 'AmericanFootballOverview' }
        | { __typename?: 'BadmintonOverview' }
        | { __typename?: 'BaseballOverview' }
        | { __typename?: 'BasketballOverview' }
        | { __typename?: 'BattlegroundsMultiCompetitiveOverview' }
        | { __typename?: 'BattlegroundsOverview' }
        | { __typename?: 'BeachVolleyballOverview' }
        | { __typename?: 'BrawlStarsOverview' }
        | { __typename?: 'CSGOOverview' }
        | { __typename?: 'CallOfDutyWarzoneOverview' }
        | { __typename?: 'CricketOverview' }
        | { __typename?: 'Dota2Overview' }
        | { __typename?: 'EBasketballOverview' }
        | { __typename?: 'EFootballOverview' }
        | { __typename?: 'EIceHockeyOverview' }
        | ({ __typename?: 'EStreetballOverview' } & EStreetball)
        | { __typename?: 'ETennisOverview' }
        | { __typename?: 'EUFCOverview' }
        | { __typename?: 'EVolleyballOverview' }
        | { __typename?: 'FootballOverview' }
        | { __typename?: 'FortniteOverview' }
        | { __typename?: 'FutsalOverview' }
        | { __typename?: 'HandballOverview' }
        | { __typename?: 'HearthstoneOverview' }
        | { __typename?: 'IceHockeyOverview' }
        | { __typename?: 'IndoorSoccerOverview' }
        | { __typename?: 'KabaddiOverview' }
        | { __typename?: 'KingOfGloryOverview' }
        | { __typename?: 'LOLOverview' }
        | { __typename?: 'LOLWildriftOverview' }
        | { __typename?: 'MMAOverview' }
        | { __typename?: 'MortalKombatOverview' }
        | { __typename?: 'OverwatchOverview' }
        | { __typename?: 'RainbowSixOverview' }
        | { __typename?: 'RocketLeagueOverview' }
        | { __typename?: 'StarCraft1Overview' }
        | { __typename?: 'StarCraftOverview' }
        | { __typename?: 'TableTennisOverview' }
        | { __typename?: 'TennisOverview' }
        | { __typename?: 'ValorantOverview' }
        | { __typename?: 'VolleyballOverview' }
        | null;
};

export type EStreetball = {
    __typename?: 'EStreetballOverview';
    homePoints: number;
    awayPoints: number;
    ballPossessionTeam: TeamHomeAway;
};

export type ETennisOverview = {
    __typename?: 'SportEventOverview';
    id: string;
    checksum: string;
    overview?:
        | { __typename?: 'AmericanFootballOverview' }
        | { __typename?: 'BadmintonOverview' }
        | { __typename?: 'BaseballOverview' }
        | { __typename?: 'BasketballOverview' }
        | { __typename?: 'BattlegroundsMultiCompetitiveOverview' }
        | { __typename?: 'BattlegroundsOverview' }
        | { __typename?: 'BeachVolleyballOverview' }
        | { __typename?: 'BrawlStarsOverview' }
        | { __typename?: 'CSGOOverview' }
        | { __typename?: 'CallOfDutyWarzoneOverview' }
        | { __typename?: 'CricketOverview' }
        | { __typename?: 'Dota2Overview' }
        | { __typename?: 'EBasketballOverview' }
        | { __typename?: 'EFootballOverview' }
        | { __typename?: 'EIceHockeyOverview' }
        | { __typename?: 'EStreetballOverview' }
        | ({ __typename?: 'ETennisOverview' } & ETennis)
        | { __typename?: 'EUFCOverview' }
        | { __typename?: 'EVolleyballOverview' }
        | { __typename?: 'FootballOverview' }
        | { __typename?: 'FortniteOverview' }
        | { __typename?: 'FutsalOverview' }
        | { __typename?: 'HandballOverview' }
        | { __typename?: 'HearthstoneOverview' }
        | { __typename?: 'IceHockeyOverview' }
        | { __typename?: 'IndoorSoccerOverview' }
        | { __typename?: 'KabaddiOverview' }
        | { __typename?: 'KingOfGloryOverview' }
        | { __typename?: 'LOLOverview' }
        | { __typename?: 'LOLWildriftOverview' }
        | { __typename?: 'MMAOverview' }
        | { __typename?: 'MortalKombatOverview' }
        | { __typename?: 'OverwatchOverview' }
        | { __typename?: 'RainbowSixOverview' }
        | { __typename?: 'RocketLeagueOverview' }
        | { __typename?: 'StarCraft1Overview' }
        | { __typename?: 'StarCraftOverview' }
        | { __typename?: 'TableTennisOverview' }
        | { __typename?: 'TennisOverview' }
        | { __typename?: 'ValorantOverview' }
        | { __typename?: 'VolleyballOverview' }
        | null;
};

export type ETennis = {
    __typename?: 'ETennisOverview';
    currentSet: number;
    server: TeamHomeAway;
    pause: boolean;
    teams: {
        __typename?: 'ETennisOverviewTeams';
        home: {
            __typename?: 'ETennisOverviewTeam';
            set: { __typename?: 'ETennisOverviewTeamSet'; score: number };
            game: { __typename?: 'ETennisOverviewGame'; score: number };
            gamePoint: {
                __typename?: 'ETennisOverviewGamePoint';
                gamePoint: TennisGamePoint;
            };
            tieBreak: { __typename?: 'ETennisOverviewTieBreak'; score: number };
        };
        away: {
            __typename?: 'ETennisOverviewTeam';
            set: { __typename?: 'ETennisOverviewTeamSet'; score: number };
            game: { __typename?: 'ETennisOverviewGame'; score: number };
            gamePoint: {
                __typename?: 'ETennisOverviewGamePoint';
                gamePoint: TennisGamePoint;
            };
            tieBreak: { __typename?: 'ETennisOverviewTieBreak'; score: number };
        };
    };
    sets?: Array<{ __typename?: 'ETennisOverviewSet' } & ETennisSet> | null;
};

export type ETennisSet = {
    __typename?: 'ETennisOverviewSet';
    number: number;
    winner: TeamHomeAway;
    state: TennisSetStatus;
    gameScore: {
        __typename?: 'ETennisOverviewSetGameScore';
        home: number;
        away: number;
    };
    tieBreakScore: {
        __typename?: 'ETennisOverviewSetTieBreakScore';
        home: number;
        away: number;
    };
};

export type EVolleyballOverview = {
    __typename?: 'SportEventOverview';
    id: string;
    checksum: string;
    overview?:
        | { __typename?: 'AmericanFootballOverview' }
        | { __typename?: 'BadmintonOverview' }
        | { __typename?: 'BaseballOverview' }
        | { __typename?: 'BasketballOverview' }
        | { __typename?: 'BattlegroundsMultiCompetitiveOverview' }
        | { __typename?: 'BattlegroundsOverview' }
        | { __typename?: 'BeachVolleyballOverview' }
        | { __typename?: 'BrawlStarsOverview' }
        | { __typename?: 'CSGOOverview' }
        | { __typename?: 'CallOfDutyWarzoneOverview' }
        | { __typename?: 'CricketOverview' }
        | { __typename?: 'Dota2Overview' }
        | { __typename?: 'EBasketballOverview' }
        | { __typename?: 'EFootballOverview' }
        | { __typename?: 'EIceHockeyOverview' }
        | { __typename?: 'EStreetballOverview' }
        | { __typename?: 'ETennisOverview' }
        | { __typename?: 'EUFCOverview' }
        | ({ __typename?: 'EVolleyballOverview' } & EVolleyball)
        | { __typename?: 'FootballOverview' }
        | { __typename?: 'FortniteOverview' }
        | { __typename?: 'FutsalOverview' }
        | { __typename?: 'HandballOverview' }
        | { __typename?: 'HearthstoneOverview' }
        | { __typename?: 'IceHockeyOverview' }
        | { __typename?: 'IndoorSoccerOverview' }
        | { __typename?: 'KabaddiOverview' }
        | { __typename?: 'KingOfGloryOverview' }
        | { __typename?: 'LOLOverview' }
        | { __typename?: 'LOLWildriftOverview' }
        | { __typename?: 'MMAOverview' }
        | { __typename?: 'MortalKombatOverview' }
        | { __typename?: 'OverwatchOverview' }
        | { __typename?: 'RainbowSixOverview' }
        | { __typename?: 'RocketLeagueOverview' }
        | { __typename?: 'StarCraft1Overview' }
        | { __typename?: 'StarCraftOverview' }
        | { __typename?: 'TableTennisOverview' }
        | { __typename?: 'TennisOverview' }
        | { __typename?: 'ValorantOverview' }
        | { __typename?: 'VolleyballOverview' }
        | null;
};

export type EVolleyball = {
    __typename?: 'EVolleyballOverview';
    currentSet: number;
    server: TeamHomeAway;
    teams: {
        __typename?: 'EVolleyballOverviewTeams';
        home: { __typename?: 'EVolleyballOverviewTeam'; score: number };
        away: { __typename?: 'EVolleyballOverviewTeam'; score: number };
    };
    sets?: Array<{
        __typename?: 'EVolleyballOverviewSet';
        number: number;
        winner: TeamHomeAway;
        type: EVolleyballSetType;
        totalPointsHome: number;
        totalPointsAway: number;
    }> | null;
};

export type EBasketballOverview = {
    __typename?: 'SportEventOverview';
    id: string;
    checksum: string;
    overview?:
        | { __typename?: 'AmericanFootballOverview' }
        | { __typename?: 'BadmintonOverview' }
        | { __typename?: 'BaseballOverview' }
        | { __typename?: 'BasketballOverview' }
        | { __typename?: 'BattlegroundsMultiCompetitiveOverview' }
        | { __typename?: 'BattlegroundsOverview' }
        | { __typename?: 'BeachVolleyballOverview' }
        | { __typename?: 'BrawlStarsOverview' }
        | { __typename?: 'CSGOOverview' }
        | { __typename?: 'CallOfDutyWarzoneOverview' }
        | { __typename?: 'CricketOverview' }
        | { __typename?: 'Dota2Overview' }
        | ({ __typename?: 'EBasketballOverview' } & EBasketball)
        | { __typename?: 'EFootballOverview' }
        | { __typename?: 'EIceHockeyOverview' }
        | { __typename?: 'EStreetballOverview' }
        | { __typename?: 'ETennisOverview' }
        | { __typename?: 'EUFCOverview' }
        | { __typename?: 'EVolleyballOverview' }
        | { __typename?: 'FootballOverview' }
        | { __typename?: 'FortniteOverview' }
        | { __typename?: 'FutsalOverview' }
        | { __typename?: 'HandballOverview' }
        | { __typename?: 'HearthstoneOverview' }
        | { __typename?: 'IceHockeyOverview' }
        | { __typename?: 'IndoorSoccerOverview' }
        | { __typename?: 'KabaddiOverview' }
        | { __typename?: 'KingOfGloryOverview' }
        | { __typename?: 'LOLOverview' }
        | { __typename?: 'LOLWildriftOverview' }
        | { __typename?: 'MMAOverview' }
        | { __typename?: 'MortalKombatOverview' }
        | { __typename?: 'OverwatchOverview' }
        | { __typename?: 'RainbowSixOverview' }
        | { __typename?: 'RocketLeagueOverview' }
        | { __typename?: 'StarCraft1Overview' }
        | { __typename?: 'StarCraftOverview' }
        | { __typename?: 'TableTennisOverview' }
        | { __typename?: 'TennisOverview' }
        | { __typename?: 'ValorantOverview' }
        | { __typename?: 'VolleyballOverview' }
        | null;
};

export type EBasketball = {
    __typename?: 'EBasketballOverview';
    quarterFormat: number;
    currentQuarter: number;
    pause: boolean;
    timer: { __typename?: 'Timer'; isActive: boolean; time: number };
    quarters: Array<{
        __typename?: 'EBasketballOverviewQuarter';
        number: number;
        home: { __typename?: 'EBasketballQuarterTeam'; score: number };
        away: { __typename?: 'EBasketballQuarterTeam'; score: number };
    }>;
    teams: {
        __typename?: 'EBasketballOverviewTeams';
        home: {
            __typename?: 'EBasketballOverviewTeam';
            score: number;
            fouls: number;
        };
        away: {
            __typename?: 'EBasketballOverviewTeam';
            score: number;
            fouls: number;
        };
    };
};

export type FootballOverview = {
    __typename?: 'SportEventOverview';
    id: string;
    checksum: string;
    overview?:
        | { __typename?: 'AmericanFootballOverview' }
        | { __typename?: 'BadmintonOverview' }
        | { __typename?: 'BaseballOverview' }
        | { __typename?: 'BasketballOverview' }
        | { __typename?: 'BattlegroundsMultiCompetitiveOverview' }
        | { __typename?: 'BattlegroundsOverview' }
        | { __typename?: 'BeachVolleyballOverview' }
        | { __typename?: 'BrawlStarsOverview' }
        | { __typename?: 'CSGOOverview' }
        | { __typename?: 'CallOfDutyWarzoneOverview' }
        | { __typename?: 'CricketOverview' }
        | { __typename?: 'Dota2Overview' }
        | { __typename?: 'EBasketballOverview' }
        | { __typename?: 'EFootballOverview' }
        | { __typename?: 'EIceHockeyOverview' }
        | { __typename?: 'EStreetballOverview' }
        | { __typename?: 'ETennisOverview' }
        | { __typename?: 'EUFCOverview' }
        | { __typename?: 'EVolleyballOverview' }
        | ({ __typename?: 'FootballOverview' } & Football)
        | { __typename?: 'FortniteOverview' }
        | { __typename?: 'FutsalOverview' }
        | { __typename?: 'HandballOverview' }
        | { __typename?: 'HearthstoneOverview' }
        | { __typename?: 'IceHockeyOverview' }
        | { __typename?: 'IndoorSoccerOverview' }
        | { __typename?: 'KabaddiOverview' }
        | { __typename?: 'KingOfGloryOverview' }
        | { __typename?: 'LOLOverview' }
        | { __typename?: 'LOLWildriftOverview' }
        | { __typename?: 'MMAOverview' }
        | { __typename?: 'MortalKombatOverview' }
        | { __typename?: 'OverwatchOverview' }
        | { __typename?: 'RainbowSixOverview' }
        | { __typename?: 'RocketLeagueOverview' }
        | { __typename?: 'StarCraft1Overview' }
        | { __typename?: 'StarCraftOverview' }
        | { __typename?: 'TableTennisOverview' }
        | { __typename?: 'TennisOverview' }
        | { __typename?: 'ValorantOverview' }
        | { __typename?: 'VolleyballOverview' }
        | null;
};

export type Football = {
    __typename?: 'FootballOverview';
    isVarActive: boolean;
    pause: boolean;
    currentFootballMatchPart: FootballPeriod;
    timer: { __typename?: 'Timer'; isActive: boolean; time: number };
    teams: {
        __typename?: 'FootballOverviewTeams';
        home: {
            __typename?: 'FootballOverviewTeamTotal';
            score: number;
            yellowCards: number;
            redCards: number;
            cornerKicks: number;
        };
        away: {
            __typename?: 'FootballOverviewTeamTotal';
            score: number;
            yellowCards: number;
            redCards: number;
            cornerKicks: number;
        };
    };
    periods?: Array<{
        __typename?: 'FootballOverviewPeriod';
        period: FootballPeriod;
        home: {
            __typename?: 'FootballOverviewPeriodTeam';
            score: number;
            penalties?: Array<{
                __typename?: 'FootballOverviewPenalties';
                number: number;
                status: FootballPenaltyStatus;
            }> | null;
        };
        away: {
            __typename?: 'FootballOverviewPeriodTeam';
            score: number;
            penalties?: Array<{
                __typename?: 'FootballOverviewPenalties';
                number: number;
                status: FootballPenaltyStatus;
            }> | null;
        };
    }> | null;
};

export type FutsalOverview = {
    __typename?: 'SportEventOverview';
    id: string;
    checksum: string;
    overview?:
        | { __typename?: 'AmericanFootballOverview' }
        | { __typename?: 'BadmintonOverview' }
        | { __typename?: 'BaseballOverview' }
        | { __typename?: 'BasketballOverview' }
        | { __typename?: 'BattlegroundsMultiCompetitiveOverview' }
        | { __typename?: 'BattlegroundsOverview' }
        | { __typename?: 'BeachVolleyballOverview' }
        | { __typename?: 'BrawlStarsOverview' }
        | { __typename?: 'CSGOOverview' }
        | { __typename?: 'CallOfDutyWarzoneOverview' }
        | { __typename?: 'CricketOverview' }
        | { __typename?: 'Dota2Overview' }
        | { __typename?: 'EBasketballOverview' }
        | { __typename?: 'EFootballOverview' }
        | { __typename?: 'EIceHockeyOverview' }
        | { __typename?: 'EStreetballOverview' }
        | { __typename?: 'ETennisOverview' }
        | { __typename?: 'EUFCOverview' }
        | { __typename?: 'EVolleyballOverview' }
        | { __typename?: 'FootballOverview' }
        | { __typename?: 'FortniteOverview' }
        | ({ __typename?: 'FutsalOverview' } & Futsal)
        | { __typename?: 'HandballOverview' }
        | { __typename?: 'HearthstoneOverview' }
        | { __typename?: 'IceHockeyOverview' }
        | { __typename?: 'IndoorSoccerOverview' }
        | { __typename?: 'KabaddiOverview' }
        | { __typename?: 'KingOfGloryOverview' }
        | { __typename?: 'LOLOverview' }
        | { __typename?: 'LOLWildriftOverview' }
        | { __typename?: 'MMAOverview' }
        | { __typename?: 'MortalKombatOverview' }
        | { __typename?: 'OverwatchOverview' }
        | { __typename?: 'RainbowSixOverview' }
        | { __typename?: 'RocketLeagueOverview' }
        | { __typename?: 'StarCraft1Overview' }
        | { __typename?: 'StarCraftOverview' }
        | { __typename?: 'TableTennisOverview' }
        | { __typename?: 'TennisOverview' }
        | { __typename?: 'ValorantOverview' }
        | { __typename?: 'VolleyballOverview' }
        | null;
};

export type Futsal = {
    __typename?: 'FutsalOverview';
    isPenaltyTime: boolean;
    currentPeriod: FutsalPeriod;
    timer: { __typename?: 'Timer'; isActive: boolean; time: number };
    periods?: Array<{
        __typename?: 'FutsalOverviewPeriod';
        period: FutsalPeriod;
        home: {
            __typename?: 'FutsalOverviewPeriodTeam';
            score: number;
            penalties?: Array<{
                __typename?: 'FutsalOverviewPenalties';
                number: number;
                status: PenaltyStatus;
            }> | null;
        };
        away: {
            __typename?: 'FutsalOverviewPeriodTeam';
            score: number;
            penalties?: Array<{
                __typename?: 'FutsalOverviewPenalties';
                number: number;
                status: PenaltyStatus;
            }> | null;
        };
    }> | null;
    teams: {
        __typename?: 'FutsalOverviewTeams';
        home: {
            __typename?: 'FutsalOverviewTeamTotal';
            score: number;
            yellowCards: number;
            redCards: number;
        };
        away: {
            __typename?: 'FutsalOverviewTeamTotal';
            score: number;
            yellowCards: number;
            redCards: number;
        };
    };
};

export type HearthstoneOverview = {
    __typename?: 'SportEventOverview';
    id: string;
    checksum: string;
    overview?:
        | { __typename?: 'AmericanFootballOverview' }
        | { __typename?: 'BadmintonOverview' }
        | { __typename?: 'BaseballOverview' }
        | { __typename?: 'BasketballOverview' }
        | { __typename?: 'BattlegroundsMultiCompetitiveOverview' }
        | { __typename?: 'BattlegroundsOverview' }
        | { __typename?: 'BeachVolleyballOverview' }
        | { __typename?: 'BrawlStarsOverview' }
        | { __typename?: 'CSGOOverview' }
        | { __typename?: 'CallOfDutyWarzoneOverview' }
        | { __typename?: 'CricketOverview' }
        | { __typename?: 'Dota2Overview' }
        | { __typename?: 'EBasketballOverview' }
        | { __typename?: 'EFootballOverview' }
        | { __typename?: 'EIceHockeyOverview' }
        | { __typename?: 'EStreetballOverview' }
        | { __typename?: 'ETennisOverview' }
        | { __typename?: 'EUFCOverview' }
        | { __typename?: 'EVolleyballOverview' }
        | { __typename?: 'FootballOverview' }
        | { __typename?: 'FortniteOverview' }
        | { __typename?: 'FutsalOverview' }
        | { __typename?: 'HandballOverview' }
        | ({ __typename?: 'HearthstoneOverview' } & Hearthstone)
        | { __typename?: 'IceHockeyOverview' }
        | { __typename?: 'IndoorSoccerOverview' }
        | { __typename?: 'KabaddiOverview' }
        | { __typename?: 'KingOfGloryOverview' }
        | { __typename?: 'LOLOverview' }
        | { __typename?: 'LOLWildriftOverview' }
        | { __typename?: 'MMAOverview' }
        | { __typename?: 'MortalKombatOverview' }
        | { __typename?: 'OverwatchOverview' }
        | { __typename?: 'RainbowSixOverview' }
        | { __typename?: 'RocketLeagueOverview' }
        | { __typename?: 'StarCraft1Overview' }
        | { __typename?: 'StarCraftOverview' }
        | { __typename?: 'TableTennisOverview' }
        | { __typename?: 'TennisOverview' }
        | { __typename?: 'ValorantOverview' }
        | { __typename?: 'VolleyballOverview' }
        | null;
};

export type Hearthstone = {
    __typename?: 'HearthstoneOverview';
    currentMap: number;
    bestOf: number;
    teams: {
        __typename?: 'HearthstoneOverviewTeams';
        home: { __typename?: 'HearthstoneOverviewTeam'; score: number };
        away: { __typename?: 'HearthstoneOverviewTeam'; score: number };
    };
    maps?: Array<{
        __typename?: 'HearthstoneOverviewMap';
        number: number;
        winner: TeamHomeAway;
    }> | null;
};

export type IceHockeyOverview = {
    __typename?: 'SportEventOverview';
    id: string;
    checksum: string;
    overview?:
        | { __typename?: 'AmericanFootballOverview' }
        | { __typename?: 'BadmintonOverview' }
        | { __typename?: 'BaseballOverview' }
        | { __typename?: 'BasketballOverview' }
        | { __typename?: 'BattlegroundsMultiCompetitiveOverview' }
        | { __typename?: 'BattlegroundsOverview' }
        | { __typename?: 'BeachVolleyballOverview' }
        | { __typename?: 'BrawlStarsOverview' }
        | { __typename?: 'CSGOOverview' }
        | { __typename?: 'CallOfDutyWarzoneOverview' }
        | { __typename?: 'CricketOverview' }
        | { __typename?: 'Dota2Overview' }
        | { __typename?: 'EBasketballOverview' }
        | { __typename?: 'EFootballOverview' }
        | { __typename?: 'EIceHockeyOverview' }
        | { __typename?: 'EStreetballOverview' }
        | { __typename?: 'ETennisOverview' }
        | { __typename?: 'EUFCOverview' }
        | { __typename?: 'EVolleyballOverview' }
        | { __typename?: 'FootballOverview' }
        | { __typename?: 'FortniteOverview' }
        | { __typename?: 'FutsalOverview' }
        | { __typename?: 'HandballOverview' }
        | { __typename?: 'HearthstoneOverview' }
        | ({ __typename?: 'IceHockeyOverview' } & IceHockey)
        | { __typename?: 'IndoorSoccerOverview' }
        | { __typename?: 'KabaddiOverview' }
        | { __typename?: 'KingOfGloryOverview' }
        | { __typename?: 'LOLOverview' }
        | { __typename?: 'LOLWildriftOverview' }
        | { __typename?: 'MMAOverview' }
        | { __typename?: 'MortalKombatOverview' }
        | { __typename?: 'OverwatchOverview' }
        | { __typename?: 'RainbowSixOverview' }
        | { __typename?: 'RocketLeagueOverview' }
        | { __typename?: 'StarCraft1Overview' }
        | { __typename?: 'StarCraftOverview' }
        | { __typename?: 'TableTennisOverview' }
        | { __typename?: 'TennisOverview' }
        | { __typename?: 'ValorantOverview' }
        | { __typename?: 'VolleyballOverview' }
        | null;
};

export type IceHockey = {
    __typename?: 'IceHockeyOverview';
    currentPeriod: number;
    pause: boolean;
    isOvertime: boolean;
    isShootout: boolean;
    timer: { __typename?: 'Timer'; isActive: boolean; time: number };
    teams: {
        __typename?: 'IceHockeyOverviewTeams';
        home: {
            __typename?: 'IceHockeyOverviewTeam';
            score: number;
            bulletThrows: number;
        };
        away: {
            __typename?: 'IceHockeyOverviewTeam';
            score: number;
            bulletThrows: number;
        };
    };
    periods?: Array<{
        __typename?: 'IceHockeyOverviewPeriod';
        number: number;
        periodType: IceHockeyPeriodType;
        home: {
            __typename?: 'IceHockeyOverviewPeriodTeam';
            score: number;
            bulletThrows?: Array<{
                __typename?: 'IceHockeyOverviewBulletThrow';
                number: number;
                scored: boolean;
                active: boolean;
                canceled: boolean;
            }> | null;
        };
        away: {
            __typename?: 'IceHockeyOverviewPeriodTeam';
            score: number;
            bulletThrows?: Array<{
                __typename?: 'IceHockeyOverviewBulletThrow';
                number: number;
                scored: boolean;
                active: boolean;
                canceled: boolean;
            }> | null;
        };
    }> | null;
};

export type IndoorSoccerOverview = {
    __typename?: 'SportEventOverview';
    id: string;
    checksum: string;
    overview?:
        | { __typename?: 'AmericanFootballOverview' }
        | { __typename?: 'BadmintonOverview' }
        | { __typename?: 'BaseballOverview' }
        | { __typename?: 'BasketballOverview' }
        | { __typename?: 'BattlegroundsMultiCompetitiveOverview' }
        | { __typename?: 'BattlegroundsOverview' }
        | { __typename?: 'BeachVolleyballOverview' }
        | { __typename?: 'BrawlStarsOverview' }
        | { __typename?: 'CSGOOverview' }
        | { __typename?: 'CallOfDutyWarzoneOverview' }
        | { __typename?: 'CricketOverview' }
        | { __typename?: 'Dota2Overview' }
        | { __typename?: 'EBasketballOverview' }
        | { __typename?: 'EFootballOverview' }
        | { __typename?: 'EIceHockeyOverview' }
        | { __typename?: 'EStreetballOverview' }
        | { __typename?: 'ETennisOverview' }
        | { __typename?: 'EUFCOverview' }
        | { __typename?: 'EVolleyballOverview' }
        | { __typename?: 'FootballOverview' }
        | { __typename?: 'FortniteOverview' }
        | { __typename?: 'FutsalOverview' }
        | { __typename?: 'HandballOverview' }
        | { __typename?: 'HearthstoneOverview' }
        | { __typename?: 'IceHockeyOverview' }
        | ({ __typename?: 'IndoorSoccerOverview' } & IndoorSoccer)
        | { __typename?: 'KabaddiOverview' }
        | { __typename?: 'KingOfGloryOverview' }
        | { __typename?: 'LOLOverview' }
        | { __typename?: 'LOLWildriftOverview' }
        | { __typename?: 'MMAOverview' }
        | { __typename?: 'MortalKombatOverview' }
        | { __typename?: 'OverwatchOverview' }
        | { __typename?: 'RainbowSixOverview' }
        | { __typename?: 'RocketLeagueOverview' }
        | { __typename?: 'StarCraft1Overview' }
        | { __typename?: 'StarCraftOverview' }
        | { __typename?: 'TableTennisOverview' }
        | { __typename?: 'TennisOverview' }
        | { __typename?: 'ValorantOverview' }
        | { __typename?: 'VolleyballOverview' }
        | null;
};

export type IndoorSoccer = {
    __typename?: 'IndoorSoccerOverview';
    currentPeriod: IndoorSoccerPeriodType;
    timer: { __typename?: 'Timer'; isActive: boolean; time: number };
    teams: {
        __typename?: 'IndoorSoccerOverviewTeams';
        home: {
            __typename?: 'IndoorSoccerOverviewTeamTotal';
            score: number;
            redCards: number;
            yellowCards: number;
        };
        away: {
            __typename?: 'IndoorSoccerOverviewTeamTotal';
            score: number;
            redCards: number;
            yellowCards: number;
        };
    };
    periods?: Array<{
        __typename?: 'IndoorSoccerOverviewPeriod';
        number: number;
        type: IndoorSoccerPeriodType;
        teams: {
            __typename?: 'IndoorSoccerOverviewTeams';
            home: {
                __typename?: 'IndoorSoccerOverviewTeamTotal';
                score: number;
            };
            away: {
                __typename?: 'IndoorSoccerOverviewTeamTotal';
                score: number;
            };
        };
    }> | null;
};

export type KingOfGloryOverview = {
    __typename?: 'SportEventOverview';
    id: string;
    checksum: string;
    overview?:
        | { __typename?: 'AmericanFootballOverview' }
        | { __typename?: 'BadmintonOverview' }
        | { __typename?: 'BaseballOverview' }
        | { __typename?: 'BasketballOverview' }
        | { __typename?: 'BattlegroundsMultiCompetitiveOverview' }
        | { __typename?: 'BattlegroundsOverview' }
        | { __typename?: 'BeachVolleyballOverview' }
        | { __typename?: 'BrawlStarsOverview' }
        | { __typename?: 'CSGOOverview' }
        | { __typename?: 'CallOfDutyWarzoneOverview' }
        | { __typename?: 'CricketOverview' }
        | { __typename?: 'Dota2Overview' }
        | { __typename?: 'EBasketballOverview' }
        | { __typename?: 'EFootballOverview' }
        | { __typename?: 'EIceHockeyOverview' }
        | { __typename?: 'EStreetballOverview' }
        | { __typename?: 'ETennisOverview' }
        | { __typename?: 'EUFCOverview' }
        | { __typename?: 'EVolleyballOverview' }
        | { __typename?: 'FootballOverview' }
        | { __typename?: 'FortniteOverview' }
        | { __typename?: 'FutsalOverview' }
        | { __typename?: 'HandballOverview' }
        | { __typename?: 'HearthstoneOverview' }
        | { __typename?: 'IceHockeyOverview' }
        | { __typename?: 'IndoorSoccerOverview' }
        | { __typename?: 'KabaddiOverview' }
        | ({ __typename?: 'KingOfGloryOverview' } & KingOfGlory)
        | { __typename?: 'LOLOverview' }
        | { __typename?: 'LOLWildriftOverview' }
        | { __typename?: 'MMAOverview' }
        | { __typename?: 'MortalKombatOverview' }
        | { __typename?: 'OverwatchOverview' }
        | { __typename?: 'RainbowSixOverview' }
        | { __typename?: 'RocketLeagueOverview' }
        | { __typename?: 'StarCraft1Overview' }
        | { __typename?: 'StarCraftOverview' }
        | { __typename?: 'TableTennisOverview' }
        | { __typename?: 'TennisOverview' }
        | { __typename?: 'ValorantOverview' }
        | { __typename?: 'VolleyballOverview' }
        | null;
};

export type KingOfGlory = {
    __typename?: 'KingOfGloryOverview';
    currentMap: number;
    bestOf: number;
    timer: { __typename?: 'Timer'; isActive: boolean; time: number };
    teams: {
        __typename?: 'KingOfGloryOverviewTeams';
        home: { __typename?: 'KingOfGloryOverviewTeam'; score: number };
        away: { __typename?: 'KingOfGloryOverviewTeam'; score: number };
    };
    maps?: Array<{
        __typename?: 'KingOfGloryOverviewMap';
        number: number;
        winner: TeamHomeAway;
        firstBlood: WinnerHomeAway;
        homeKills: number;
        awayKills: number;
    }> | null;
};

export type LolOverview = {
    __typename?: 'SportEventOverview';
    id: string;
    checksum: string;
    overview?:
        | { __typename?: 'AmericanFootballOverview' }
        | { __typename?: 'BadmintonOverview' }
        | { __typename?: 'BaseballOverview' }
        | { __typename?: 'BasketballOverview' }
        | { __typename?: 'BattlegroundsMultiCompetitiveOverview' }
        | { __typename?: 'BattlegroundsOverview' }
        | { __typename?: 'BeachVolleyballOverview' }
        | { __typename?: 'BrawlStarsOverview' }
        | { __typename?: 'CSGOOverview' }
        | { __typename?: 'CallOfDutyWarzoneOverview' }
        | { __typename?: 'CricketOverview' }
        | { __typename?: 'Dota2Overview' }
        | { __typename?: 'EBasketballOverview' }
        | { __typename?: 'EFootballOverview' }
        | { __typename?: 'EIceHockeyOverview' }
        | { __typename?: 'EStreetballOverview' }
        | { __typename?: 'ETennisOverview' }
        | { __typename?: 'EUFCOverview' }
        | { __typename?: 'EVolleyballOverview' }
        | { __typename?: 'FootballOverview' }
        | { __typename?: 'FortniteOverview' }
        | { __typename?: 'FutsalOverview' }
        | { __typename?: 'HandballOverview' }
        | { __typename?: 'HearthstoneOverview' }
        | { __typename?: 'IceHockeyOverview' }
        | { __typename?: 'IndoorSoccerOverview' }
        | { __typename?: 'KabaddiOverview' }
        | { __typename?: 'KingOfGloryOverview' }
        | ({ __typename?: 'LOLOverview' } & Lol)
        | { __typename?: 'LOLWildriftOverview' }
        | { __typename?: 'MMAOverview' }
        | { __typename?: 'MortalKombatOverview' }
        | { __typename?: 'OverwatchOverview' }
        | { __typename?: 'RainbowSixOverview' }
        | { __typename?: 'RocketLeagueOverview' }
        | { __typename?: 'StarCraft1Overview' }
        | { __typename?: 'StarCraftOverview' }
        | { __typename?: 'TableTennisOverview' }
        | { __typename?: 'TennisOverview' }
        | { __typename?: 'ValorantOverview' }
        | { __typename?: 'VolleyballOverview' }
        | null;
};

export type Lol = {
    __typename?: 'LOLOverview';
    currentMap: number;
    currentGoldLead: number;
    bestOf: number;
    timer: { __typename?: 'Timer'; isActive: boolean; time: number };
    teams: {
        __typename?: 'LOLOverviewTeams';
        home: { __typename?: 'LOLOverviewTeam'; score: number };
        away: { __typename?: 'LOLOverviewTeam'; score: number };
    };
    maps?: Array<{
        __typename?: 'LOLOverviewMap';
        number: number;
        goldLead: number;
        teams: {
            __typename?: 'LOLMapOverviewTeams';
            home: { __typename?: 'LOLMapOverviewTeam'; kills: number };
            away: { __typename?: 'LOLMapOverviewTeam'; kills: number };
        };
    }> | null;
};

export type LolWildriftOverview = {
    __typename?: 'SportEventOverview';
    id: string;
    checksum: string;
    overview?:
        | { __typename?: 'AmericanFootballOverview' }
        | { __typename?: 'BadmintonOverview' }
        | { __typename?: 'BaseballOverview' }
        | { __typename?: 'BasketballOverview' }
        | { __typename?: 'BattlegroundsMultiCompetitiveOverview' }
        | { __typename?: 'BattlegroundsOverview' }
        | { __typename?: 'BeachVolleyballOverview' }
        | { __typename?: 'BrawlStarsOverview' }
        | { __typename?: 'CSGOOverview' }
        | { __typename?: 'CallOfDutyWarzoneOverview' }
        | { __typename?: 'CricketOverview' }
        | { __typename?: 'Dota2Overview' }
        | { __typename?: 'EBasketballOverview' }
        | { __typename?: 'EFootballOverview' }
        | { __typename?: 'EIceHockeyOverview' }
        | { __typename?: 'EStreetballOverview' }
        | { __typename?: 'ETennisOverview' }
        | { __typename?: 'EUFCOverview' }
        | { __typename?: 'EVolleyballOverview' }
        | { __typename?: 'FootballOverview' }
        | { __typename?: 'FortniteOverview' }
        | { __typename?: 'FutsalOverview' }
        | { __typename?: 'HandballOverview' }
        | { __typename?: 'HearthstoneOverview' }
        | { __typename?: 'IceHockeyOverview' }
        | { __typename?: 'IndoorSoccerOverview' }
        | { __typename?: 'KabaddiOverview' }
        | { __typename?: 'KingOfGloryOverview' }
        | { __typename?: 'LOLOverview' }
        | ({ __typename?: 'LOLWildriftOverview' } & LolWildrift)
        | { __typename?: 'MMAOverview' }
        | { __typename?: 'MortalKombatOverview' }
        | { __typename?: 'OverwatchOverview' }
        | { __typename?: 'RainbowSixOverview' }
        | { __typename?: 'RocketLeagueOverview' }
        | { __typename?: 'StarCraft1Overview' }
        | { __typename?: 'StarCraftOverview' }
        | { __typename?: 'TableTennisOverview' }
        | { __typename?: 'TennisOverview' }
        | { __typename?: 'ValorantOverview' }
        | { __typename?: 'VolleyballOverview' }
        | null;
};

export type LolWildrift = {
    __typename?: 'LOLWildriftOverview';
    currentMap: number;
    bestOf: number;
    timer: { __typename?: 'Timer'; isActive: boolean; time: number };
    teams: {
        __typename?: 'LOLWildriftOverviewTeams';
        home: { __typename?: 'LOLWildriftOverviewTeam'; score: number };
        away: { __typename?: 'LOLWildriftOverviewTeam'; score: number };
    };
    maps?: Array<{
        __typename?: 'LOLWildriftOverviewMap';
        number: number;
        winner: TeamHomeAway;
        firstBlood: WinnerHomeAway;
        homeKills: number;
        awayKills: number;
    }> | null;
};

export type MmaOverview = {
    __typename?: 'SportEventOverview';
    id: string;
    checksum: string;
    overview?:
        | { __typename?: 'AmericanFootballOverview' }
        | { __typename?: 'BadmintonOverview' }
        | { __typename?: 'BaseballOverview' }
        | { __typename?: 'BasketballOverview' }
        | { __typename?: 'BattlegroundsMultiCompetitiveOverview' }
        | { __typename?: 'BattlegroundsOverview' }
        | { __typename?: 'BeachVolleyballOverview' }
        | { __typename?: 'BrawlStarsOverview' }
        | { __typename?: 'CSGOOverview' }
        | { __typename?: 'CallOfDutyWarzoneOverview' }
        | { __typename?: 'CricketOverview' }
        | { __typename?: 'Dota2Overview' }
        | { __typename?: 'EBasketballOverview' }
        | { __typename?: 'EFootballOverview' }
        | { __typename?: 'EIceHockeyOverview' }
        | { __typename?: 'EStreetballOverview' }
        | { __typename?: 'ETennisOverview' }
        | { __typename?: 'EUFCOverview' }
        | { __typename?: 'EVolleyballOverview' }
        | { __typename?: 'FootballOverview' }
        | { __typename?: 'FortniteOverview' }
        | { __typename?: 'FutsalOverview' }
        | { __typename?: 'HandballOverview' }
        | { __typename?: 'HearthstoneOverview' }
        | { __typename?: 'IceHockeyOverview' }
        | { __typename?: 'IndoorSoccerOverview' }
        | { __typename?: 'KabaddiOverview' }
        | { __typename?: 'KingOfGloryOverview' }
        | { __typename?: 'LOLOverview' }
        | { __typename?: 'LOLWildriftOverview' }
        | ({ __typename?: 'MMAOverview' } & Mma)
        | { __typename?: 'MortalKombatOverview' }
        | { __typename?: 'OverwatchOverview' }
        | { __typename?: 'RainbowSixOverview' }
        | { __typename?: 'RocketLeagueOverview' }
        | { __typename?: 'StarCraft1Overview' }
        | { __typename?: 'StarCraftOverview' }
        | { __typename?: 'TableTennisOverview' }
        | { __typename?: 'TennisOverview' }
        | { __typename?: 'ValorantOverview' }
        | { __typename?: 'VolleyballOverview' }
        | null;
};

export type Mma = {
    __typename?: 'MMAOverview';
    format: MmaFormat;
    round: number;
    timer: { __typename?: 'Timer'; isActive: boolean; time: number };
    result: {
        __typename?: 'MMAResult';
        roundNumber?: number | null;
        time?: number | null;
        reason: MmaReason;
        winner: MmaWinner;
    };
};

export type MortalKombatOverview = {
    __typename?: 'SportEventOverview';
    id: string;
    checksum: string;
    overview?:
        | { __typename?: 'AmericanFootballOverview' }
        | { __typename?: 'BadmintonOverview' }
        | { __typename?: 'BaseballOverview' }
        | { __typename?: 'BasketballOverview' }
        | { __typename?: 'BattlegroundsMultiCompetitiveOverview' }
        | { __typename?: 'BattlegroundsOverview' }
        | { __typename?: 'BeachVolleyballOverview' }
        | { __typename?: 'BrawlStarsOverview' }
        | { __typename?: 'CSGOOverview' }
        | { __typename?: 'CallOfDutyWarzoneOverview' }
        | { __typename?: 'CricketOverview' }
        | { __typename?: 'Dota2Overview' }
        | { __typename?: 'EBasketballOverview' }
        | { __typename?: 'EFootballOverview' }
        | { __typename?: 'EIceHockeyOverview' }
        | { __typename?: 'EStreetballOverview' }
        | { __typename?: 'ETennisOverview' }
        | { __typename?: 'EUFCOverview' }
        | { __typename?: 'EVolleyballOverview' }
        | { __typename?: 'FootballOverview' }
        | { __typename?: 'FortniteOverview' }
        | { __typename?: 'FutsalOverview' }
        | { __typename?: 'HandballOverview' }
        | { __typename?: 'HearthstoneOverview' }
        | { __typename?: 'IceHockeyOverview' }
        | { __typename?: 'IndoorSoccerOverview' }
        | { __typename?: 'KabaddiOverview' }
        | { __typename?: 'KingOfGloryOverview' }
        | { __typename?: 'LOLOverview' }
        | { __typename?: 'LOLWildriftOverview' }
        | { __typename?: 'MMAOverview' }
        | ({ __typename?: 'MortalKombatOverview' } & MortalKombat)
        | { __typename?: 'OverwatchOverview' }
        | { __typename?: 'RainbowSixOverview' }
        | { __typename?: 'RocketLeagueOverview' }
        | { __typename?: 'StarCraft1Overview' }
        | { __typename?: 'StarCraftOverview' }
        | { __typename?: 'TableTennisOverview' }
        | { __typename?: 'TennisOverview' }
        | { __typename?: 'ValorantOverview' }
        | { __typename?: 'VolleyballOverview' }
        | null;
};

export type MortalKombat = {
    __typename?: 'MortalKombatOverview';
    currentFight: number;
    currentRound: number;
    currentCharacterHome: MortalKombatCharacter;
    currentCharacterAway: MortalKombatCharacter;
    currentMap: MortalKombatMap;
    bestOf: number;
    bestOfRounds: number;
    teams: {
        __typename?: 'MortalKombatOverviewTeams';
        home: {
            __typename?: 'MortalKombatOverviewTeam';
            score: number;
            characterInfo: {
                __typename?: 'CharacterInfo';
                health: number;
                fatalBlow: boolean;
            };
        };
        away: {
            __typename?: 'MortalKombatOverviewTeam';
            score: number;
            characterInfo: {
                __typename?: 'CharacterInfo';
                health: number;
                fatalBlow: boolean;
            };
        };
    };
    fights?: Array<{
        __typename?: 'MortalKombatOverviewFight';
        number: number;
        map: MortalKombatMap;
        winner: TeamHomeAway;
        winReason: MortalKombatWinReason;
        home: { __typename?: 'MortalKombatFightOverview'; score: number };
        away: { __typename?: 'MortalKombatFightOverview'; score: number };
    }> | null;
    timer: { __typename?: 'Timer'; isActive: boolean; time: number };
};

export type OverwatchOverview = {
    __typename?: 'SportEventOverview';
    id: string;
    checksum: string;
    overview?:
        | { __typename?: 'AmericanFootballOverview' }
        | { __typename?: 'BadmintonOverview' }
        | { __typename?: 'BaseballOverview' }
        | { __typename?: 'BasketballOverview' }
        | { __typename?: 'BattlegroundsMultiCompetitiveOverview' }
        | { __typename?: 'BattlegroundsOverview' }
        | { __typename?: 'BeachVolleyballOverview' }
        | { __typename?: 'BrawlStarsOverview' }
        | { __typename?: 'CSGOOverview' }
        | { __typename?: 'CallOfDutyWarzoneOverview' }
        | { __typename?: 'CricketOverview' }
        | { __typename?: 'Dota2Overview' }
        | { __typename?: 'EBasketballOverview' }
        | { __typename?: 'EFootballOverview' }
        | { __typename?: 'EIceHockeyOverview' }
        | { __typename?: 'EStreetballOverview' }
        | { __typename?: 'ETennisOverview' }
        | { __typename?: 'EUFCOverview' }
        | { __typename?: 'EVolleyballOverview' }
        | { __typename?: 'FootballOverview' }
        | { __typename?: 'FortniteOverview' }
        | { __typename?: 'FutsalOverview' }
        | { __typename?: 'HandballOverview' }
        | { __typename?: 'HearthstoneOverview' }
        | { __typename?: 'IceHockeyOverview' }
        | { __typename?: 'IndoorSoccerOverview' }
        | { __typename?: 'KabaddiOverview' }
        | { __typename?: 'KingOfGloryOverview' }
        | { __typename?: 'LOLOverview' }
        | { __typename?: 'LOLWildriftOverview' }
        | { __typename?: 'MMAOverview' }
        | { __typename?: 'MortalKombatOverview' }
        | ({ __typename?: 'OverwatchOverview' } & Overwatch)
        | { __typename?: 'RainbowSixOverview' }
        | { __typename?: 'RocketLeagueOverview' }
        | { __typename?: 'StarCraft1Overview' }
        | { __typename?: 'StarCraftOverview' }
        | { __typename?: 'TableTennisOverview' }
        | { __typename?: 'TennisOverview' }
        | { __typename?: 'ValorantOverview' }
        | { __typename?: 'VolleyballOverview' }
        | null;
};

export type Overwatch = {
    __typename?: 'OverwatchOverview';
    currentMap: number;
    bestOf: number;
    teams: {
        __typename?: 'OverwatchOverviewTeams';
        home: { __typename?: 'OverwatchOverviewTeam'; score: number };
        away: { __typename?: 'OverwatchOverviewTeam'; score: number };
    };
    maps?: Array<{
        __typename?: 'OverwatchOverviewMap';
        number: number;
        winner: TeamHomeAway;
    }> | null;
};

export type StarCraft1Overview = {
    __typename?: 'SportEventOverview';
    id: string;
    checksum: string;
    overview?:
        | { __typename?: 'AmericanFootballOverview' }
        | { __typename?: 'BadmintonOverview' }
        | { __typename?: 'BaseballOverview' }
        | { __typename?: 'BasketballOverview' }
        | { __typename?: 'BattlegroundsMultiCompetitiveOverview' }
        | { __typename?: 'BattlegroundsOverview' }
        | { __typename?: 'BeachVolleyballOverview' }
        | { __typename?: 'BrawlStarsOverview' }
        | { __typename?: 'CSGOOverview' }
        | { __typename?: 'CallOfDutyWarzoneOverview' }
        | { __typename?: 'CricketOverview' }
        | { __typename?: 'Dota2Overview' }
        | { __typename?: 'EBasketballOverview' }
        | { __typename?: 'EFootballOverview' }
        | { __typename?: 'EIceHockeyOverview' }
        | { __typename?: 'EStreetballOverview' }
        | { __typename?: 'ETennisOverview' }
        | { __typename?: 'EUFCOverview' }
        | { __typename?: 'EVolleyballOverview' }
        | { __typename?: 'FootballOverview' }
        | { __typename?: 'FortniteOverview' }
        | { __typename?: 'FutsalOverview' }
        | { __typename?: 'HandballOverview' }
        | { __typename?: 'HearthstoneOverview' }
        | { __typename?: 'IceHockeyOverview' }
        | { __typename?: 'IndoorSoccerOverview' }
        | { __typename?: 'KabaddiOverview' }
        | { __typename?: 'KingOfGloryOverview' }
        | { __typename?: 'LOLOverview' }
        | { __typename?: 'LOLWildriftOverview' }
        | { __typename?: 'MMAOverview' }
        | { __typename?: 'MortalKombatOverview' }
        | { __typename?: 'OverwatchOverview' }
        | { __typename?: 'RainbowSixOverview' }
        | { __typename?: 'RocketLeagueOverview' }
        | ({ __typename?: 'StarCraft1Overview' } & StarCraft1)
        | { __typename?: 'StarCraftOverview' }
        | { __typename?: 'TableTennisOverview' }
        | { __typename?: 'TennisOverview' }
        | { __typename?: 'ValorantOverview' }
        | { __typename?: 'VolleyballOverview' }
        | null;
};

export type StarCraft1 = {
    __typename?: 'StarCraft1Overview';
    currentMap: number;
    bestOf: number;
    teams: {
        __typename?: 'StarCraft1OverviewTeams';
        home: { __typename?: 'StarCraft1OverviewTeam'; score: number };
        away: { __typename?: 'StarCraft1OverviewTeam'; score: number };
    };
    maps?: Array<{
        __typename?: 'StarCraft1OverviewMap';
        number: number;
        winner: TeamHomeAway;
    }> | null;
};

export type StarCraftOverview = {
    __typename?: 'SportEventOverview';
    id: string;
    checksum: string;
    overview?:
        | { __typename?: 'AmericanFootballOverview' }
        | { __typename?: 'BadmintonOverview' }
        | { __typename?: 'BaseballOverview' }
        | { __typename?: 'BasketballOverview' }
        | { __typename?: 'BattlegroundsMultiCompetitiveOverview' }
        | { __typename?: 'BattlegroundsOverview' }
        | { __typename?: 'BeachVolleyballOverview' }
        | { __typename?: 'BrawlStarsOverview' }
        | { __typename?: 'CSGOOverview' }
        | { __typename?: 'CallOfDutyWarzoneOverview' }
        | { __typename?: 'CricketOverview' }
        | { __typename?: 'Dota2Overview' }
        | { __typename?: 'EBasketballOverview' }
        | { __typename?: 'EFootballOverview' }
        | { __typename?: 'EIceHockeyOverview' }
        | { __typename?: 'EStreetballOverview' }
        | { __typename?: 'ETennisOverview' }
        | { __typename?: 'EUFCOverview' }
        | { __typename?: 'EVolleyballOverview' }
        | { __typename?: 'FootballOverview' }
        | { __typename?: 'FortniteOverview' }
        | { __typename?: 'FutsalOverview' }
        | { __typename?: 'HandballOverview' }
        | { __typename?: 'HearthstoneOverview' }
        | { __typename?: 'IceHockeyOverview' }
        | { __typename?: 'IndoorSoccerOverview' }
        | { __typename?: 'KabaddiOverview' }
        | { __typename?: 'KingOfGloryOverview' }
        | { __typename?: 'LOLOverview' }
        | { __typename?: 'LOLWildriftOverview' }
        | { __typename?: 'MMAOverview' }
        | { __typename?: 'MortalKombatOverview' }
        | { __typename?: 'OverwatchOverview' }
        | { __typename?: 'RainbowSixOverview' }
        | { __typename?: 'RocketLeagueOverview' }
        | { __typename?: 'StarCraft1Overview' }
        | ({ __typename?: 'StarCraftOverview' } & StarCraft)
        | { __typename?: 'TableTennisOverview' }
        | { __typename?: 'TennisOverview' }
        | { __typename?: 'ValorantOverview' }
        | { __typename?: 'VolleyballOverview' }
        | null;
};

export type StarCraft = {
    __typename?: 'StarCraftOverview';
    currentMap: number;
    bestOf: number;
    teams: {
        __typename?: 'StarCraftOverviewTeams';
        home: { __typename?: 'StarCraftOverviewTeam'; score: number };
        away: { __typename?: 'StarCraftOverviewTeam'; score: number };
    };
    maps?: Array<{
        __typename?: 'StarCraftOverviewMap';
        number: number;
        winner: TeamHomeAway;
    }> | null;
};

export type TableTennisOverview = {
    __typename?: 'SportEventOverview';
    id: string;
    checksum: string;
    overview?:
        | { __typename?: 'AmericanFootballOverview' }
        | { __typename?: 'BadmintonOverview' }
        | { __typename?: 'BaseballOverview' }
        | { __typename?: 'BasketballOverview' }
        | { __typename?: 'BattlegroundsMultiCompetitiveOverview' }
        | { __typename?: 'BattlegroundsOverview' }
        | { __typename?: 'BeachVolleyballOverview' }
        | { __typename?: 'BrawlStarsOverview' }
        | { __typename?: 'CSGOOverview' }
        | { __typename?: 'CallOfDutyWarzoneOverview' }
        | { __typename?: 'CricketOverview' }
        | { __typename?: 'Dota2Overview' }
        | { __typename?: 'EBasketballOverview' }
        | { __typename?: 'EFootballOverview' }
        | { __typename?: 'EIceHockeyOverview' }
        | { __typename?: 'EStreetballOverview' }
        | { __typename?: 'ETennisOverview' }
        | { __typename?: 'EUFCOverview' }
        | { __typename?: 'EVolleyballOverview' }
        | { __typename?: 'FootballOverview' }
        | { __typename?: 'FortniteOverview' }
        | { __typename?: 'FutsalOverview' }
        | { __typename?: 'HandballOverview' }
        | { __typename?: 'HearthstoneOverview' }
        | { __typename?: 'IceHockeyOverview' }
        | { __typename?: 'IndoorSoccerOverview' }
        | { __typename?: 'KabaddiOverview' }
        | { __typename?: 'KingOfGloryOverview' }
        | { __typename?: 'LOLOverview' }
        | { __typename?: 'LOLWildriftOverview' }
        | { __typename?: 'MMAOverview' }
        | { __typename?: 'MortalKombatOverview' }
        | { __typename?: 'OverwatchOverview' }
        | { __typename?: 'RainbowSixOverview' }
        | { __typename?: 'RocketLeagueOverview' }
        | { __typename?: 'StarCraft1Overview' }
        | { __typename?: 'StarCraftOverview' }
        | ({ __typename?: 'TableTennisOverview' } & TableTennis)
        | { __typename?: 'TennisOverview' }
        | { __typename?: 'ValorantOverview' }
        | { __typename?: 'VolleyballOverview' }
        | null;
};

export type TableTennis = {
    __typename?: 'TableTennisOverview';
    currentSet: number;
    server: TeamHomeAway;
    teams: {
        __typename?: 'TableTennisOverviewTeams';
        home: { __typename?: 'TableTennisOverviewTeam'; score: number };
        away: { __typename?: 'TableTennisOverviewTeam'; score: number };
    };
    sets?: Array<{
        __typename?: 'TableTennisOverviewSet';
        number: number;
        winner: TeamHomeAway;
        type: TableTennisSetType;
        totalPointsHome: number;
        totalPointsAway: number;
    }> | null;
};

export type TennisOverview = {
    __typename?: 'SportEventOverview';
    id: string;
    checksum: string;
    overview?:
        | { __typename?: 'AmericanFootballOverview' }
        | { __typename?: 'BadmintonOverview' }
        | { __typename?: 'BaseballOverview' }
        | { __typename?: 'BasketballOverview' }
        | { __typename?: 'BattlegroundsMultiCompetitiveOverview' }
        | { __typename?: 'BattlegroundsOverview' }
        | { __typename?: 'BeachVolleyballOverview' }
        | { __typename?: 'BrawlStarsOverview' }
        | { __typename?: 'CSGOOverview' }
        | { __typename?: 'CallOfDutyWarzoneOverview' }
        | { __typename?: 'CricketOverview' }
        | { __typename?: 'Dota2Overview' }
        | { __typename?: 'EBasketballOverview' }
        | { __typename?: 'EFootballOverview' }
        | { __typename?: 'EIceHockeyOverview' }
        | { __typename?: 'EStreetballOverview' }
        | { __typename?: 'ETennisOverview' }
        | { __typename?: 'EUFCOverview' }
        | { __typename?: 'EVolleyballOverview' }
        | { __typename?: 'FootballOverview' }
        | { __typename?: 'FortniteOverview' }
        | { __typename?: 'FutsalOverview' }
        | { __typename?: 'HandballOverview' }
        | { __typename?: 'HearthstoneOverview' }
        | { __typename?: 'IceHockeyOverview' }
        | { __typename?: 'IndoorSoccerOverview' }
        | { __typename?: 'KabaddiOverview' }
        | { __typename?: 'KingOfGloryOverview' }
        | { __typename?: 'LOLOverview' }
        | { __typename?: 'LOLWildriftOverview' }
        | { __typename?: 'MMAOverview' }
        | { __typename?: 'MortalKombatOverview' }
        | { __typename?: 'OverwatchOverview' }
        | { __typename?: 'RainbowSixOverview' }
        | { __typename?: 'RocketLeagueOverview' }
        | { __typename?: 'StarCraft1Overview' }
        | { __typename?: 'StarCraftOverview' }
        | { __typename?: 'TableTennisOverview' }
        | ({ __typename?: 'TennisOverview' } & Tennis)
        | { __typename?: 'ValorantOverview' }
        | { __typename?: 'VolleyballOverview' }
        | null;
};

export type Tennis = {
    __typename?: 'TennisOverview';
    currentSet: number;
    server: TeamHomeAway;
    pause: boolean;
    teams: {
        __typename?: 'TennisOverviewTeams';
        home: {
            __typename?: 'TennisOverviewTeam';
            set: { __typename?: 'TennisOverviewTeamSet'; score: number };
            game: { __typename?: 'TennisOverviewGame'; score: number };
            gamePoint: {
                __typename?: 'TennisOverviewGamePoint';
                gamePoint: TennisGamePoint;
            };
            tieBreak: { __typename?: 'TennisOverviewTieBreak'; score: number };
        };
        away: {
            __typename?: 'TennisOverviewTeam';
            set: { __typename?: 'TennisOverviewTeamSet'; score: number };
            game: { __typename?: 'TennisOverviewGame'; score: number };
            gamePoint: {
                __typename?: 'TennisOverviewGamePoint';
                gamePoint: TennisGamePoint;
            };
            tieBreak: { __typename?: 'TennisOverviewTieBreak'; score: number };
        };
    };
    sets?: Array<{ __typename?: 'TennisOverviewSet' } & TennisSet> | null;
};

export type TennisSet = {
    __typename?: 'TennisOverviewSet';
    number: number;
    winner: TeamHomeAway;
    state: TennisSetStatus;
    gameScore: {
        __typename?: 'TennisOverviewSetGameScore';
        home: number;
        away: number;
    };
    tieBreakScore: {
        __typename?: 'TennisOverviewSetTieBreakScore';
        home: number;
        away: number;
    };
};

export type VolleyballOverview = {
    __typename?: 'SportEventOverview';
    id: string;
    checksum: string;
    overview?:
        | { __typename?: 'AmericanFootballOverview' }
        | { __typename?: 'BadmintonOverview' }
        | { __typename?: 'BaseballOverview' }
        | { __typename?: 'BasketballOverview' }
        | { __typename?: 'BattlegroundsMultiCompetitiveOverview' }
        | { __typename?: 'BattlegroundsOverview' }
        | { __typename?: 'BeachVolleyballOverview' }
        | { __typename?: 'BrawlStarsOverview' }
        | { __typename?: 'CSGOOverview' }
        | { __typename?: 'CallOfDutyWarzoneOverview' }
        | { __typename?: 'CricketOverview' }
        | { __typename?: 'Dota2Overview' }
        | { __typename?: 'EBasketballOverview' }
        | { __typename?: 'EFootballOverview' }
        | { __typename?: 'EIceHockeyOverview' }
        | { __typename?: 'EStreetballOverview' }
        | { __typename?: 'ETennisOverview' }
        | { __typename?: 'EUFCOverview' }
        | { __typename?: 'EVolleyballOverview' }
        | { __typename?: 'FootballOverview' }
        | { __typename?: 'FortniteOverview' }
        | { __typename?: 'FutsalOverview' }
        | { __typename?: 'HandballOverview' }
        | { __typename?: 'HearthstoneOverview' }
        | { __typename?: 'IceHockeyOverview' }
        | { __typename?: 'IndoorSoccerOverview' }
        | { __typename?: 'KabaddiOverview' }
        | { __typename?: 'KingOfGloryOverview' }
        | { __typename?: 'LOLOverview' }
        | { __typename?: 'LOLWildriftOverview' }
        | { __typename?: 'MMAOverview' }
        | { __typename?: 'MortalKombatOverview' }
        | { __typename?: 'OverwatchOverview' }
        | { __typename?: 'RainbowSixOverview' }
        | { __typename?: 'RocketLeagueOverview' }
        | { __typename?: 'StarCraft1Overview' }
        | { __typename?: 'StarCraftOverview' }
        | { __typename?: 'TableTennisOverview' }
        | { __typename?: 'TennisOverview' }
        | { __typename?: 'ValorantOverview' }
        | ({ __typename?: 'VolleyballOverview' } & Volleyball)
        | null;
};

export type Volleyball = {
    __typename?: 'VolleyballOverview';
    currentSet: number;
    server: TeamHomeAway;
    teams: {
        __typename?: 'VolleyballOverviewTeams';
        home: { __typename?: 'VolleyballOverviewTeam'; score: number };
        away: { __typename?: 'VolleyballOverviewTeam'; score: number };
    };
    sets?: Array<{
        __typename?: 'VolleyballOverviewSet';
        number: number;
        winner: TeamHomeAway;
        type: VolleyballSetType;
        totalPointsHome: number;
        totalPointsAway: number;
    }> | null;
};

export type Stream = {
    __typename?: 'Stream';
    id: string;
    locale: string;
    url: string;
    priority: number;
    path: string;
    platforms: Array<{
        __typename?: 'StreamPlatform';
        type: string;
        enabled: boolean;
        allowedCountries: Array<string>;
    }>;
};

export type Tournament = {
    __typename?: 'Tournament';
    name: string;
    masterId: string;
    countryCode: string;
    logo: string;
    description: string;
    showTournamentInfo?: boolean | null;
    prizePool: string;
    dateStart: string;
    dateEnd: string;
    isLocalizationOverridden?: boolean | null;
    slug: string;
    sportId: string;
    id: string;
};

export type GetBannerZonesVariables = Exact<{
    attributes?: InputMaybe<BannerZoneAttributeFilters>;
}>;

export type GetBannerZones = {
    __typename?: 'Query';
    bannerZones: Array<{
        __typename?: 'BannerZone';
        id: string;
        attributes: {
            __typename?: 'BannerZoneAttributes';
            pageType: string;
            pageLocation: string;
            platform: string;
            index: number;
        };
        settings?: Array<{
            __typename?: 'Pair';
            name: string;
            value: string;
        }> | null;
    }>;
};

export type GetFilterSportEventsVariables = Exact<{
    sportEventIds: Array<Scalars['String']> | Scalars['String'];
    offset?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    matchStatuses?: InputMaybe<Array<SportEventStatus> | SportEventStatus>;
    sportIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
    sportEventTypes?: InputMaybe<Array<SportEventType> | SportEventType>;
    isTopMarkets?: InputMaybe<Scalars['Boolean']>;
    marketLimit?: InputMaybe<Scalars['Int']>;
    dateSortAscending?: InputMaybe<Scalars['Boolean']>;
    marketStatuses?: InputMaybe<Array<MarketStatus> | MarketStatus>;
    marketIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
    marketTypes?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;

export type GetFilterSportEvents = {
    __typename?: 'Query';
    matches: Array<{ __typename?: 'SportEvent' } & Match>;
};

export type GetFreebetsVariables = Exact<{
    filter?: InputMaybe<FreebetsFilter>;
}>;

export type GetFreebets = {
    __typename?: 'Query';
    freebets: Array<{ __typename?: 'Freebet' } & FreebetFragment>;
};

export type GetGamblerSettingsVariables = Exact<{ [key: string]: never }>;

export type GetGamblerSettings = {
    __typename?: 'Query';
    gambler?: {
        __typename?: 'Gambler';
        settings?: Array<{
            __typename?: 'Pair';
            name: string;
            value: string;
        }> | null;
    } | null;
};

export type GetInsurancesVariables = Exact<{
    filter?: InputMaybe<InsurancesFilter>;
}>;

export type GetInsurances = {
    __typename?: 'Query';
    insurances: Array<{
        __typename?: 'Insurance';
        id: string;
        currencyCode: string;
        refundRules: {
            __typename?: 'InsuranceRefundRules';
            amount:
                | {
                      __typename?: 'RefundAmountAmountRule';
                      amount: string;
                      type: RefundAmountRuleType;
                  }
                | {
                      __typename?: 'RefundAmountPercentRule';
                      percent: string;
                      type: RefundAmountRuleType;
                  };
        };
    }>;
};

export type GetMatchesByIdsVariables = Exact<{
    sportEventsIds: Array<Scalars['String']> | Scalars['String'];
    marketStatuses?: InputMaybe<Array<MarketStatus> | MarketStatus>;
    marketLimit?: InputMaybe<Scalars['Int']>;
    isTopMarkets?: InputMaybe<Scalars['Boolean']>;
    marketTypes?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
    marketIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
    isClient?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetMatchesByIds = {
    __typename?: 'Query';
    matches: Array<{ __typename?: 'SportEvent' } & MatchWithOptionalMeta>;
};

export type GetMinBetVariables = Exact<{ [key: string]: never }>;

export type GetMinBet = {
    __typename?: 'Query';
    minBet: { __typename?: 'MinBet'; minBet: string };
};

export type GetRestrictionsVariables = Exact<{
    betType: BetType;
    selections: Array<Selection> | Selection;
    systemSizes: Array<Scalars['Int']> | Scalars['Int'];
}>;

export type GetRestrictions = {
    __typename?: 'Query';
    restrictions: Array<
        | ({
              __typename: 'BetDelayRestriction';
          } & Restriction_BetDelayRestriction_)
        | ({
              __typename: 'BetIntervalRestriction';
          } & Restriction_BetIntervalRestriction_)
        | ({
              __typename: 'BetProviderRestriction';
          } & Restriction_BetProviderRestriction_)
        | ({
              __typename: 'BetTournamentDuplicateCompetitorIDsRestriction';
          } & Restriction_BetTournamentDuplicateCompetitorIDsRestriction_)
        | ({
              __typename: 'BetTournamentIncompatibleMarketIDsRestriction';
          } & Restriction_BetTournamentIncompatibleMarketIDsRestriction_)
        | ({
              __typename: 'BetTypeRestriction';
          } & Restriction_BetTypeRestriction_)
        | ({
              __typename: 'FreebetAmountRestriction';
          } & Restriction_FreebetAmountRestriction_)
        | ({
              __typename: 'FreebetNotApplicableRestriction';
          } & Restriction_FreebetNotApplicableRestriction_)
        | ({
              __typename: 'FreebetStatusRestriction';
          } & Restriction_FreebetStatusRestriction_)
        | ({
              __typename: 'InsuranceNotApplicableRestriction';
          } & Restriction_InsuranceNotApplicableRestriction_)
        | ({
              __typename: 'InsuranceStatusRestriction';
          } & Restriction_InsuranceStatusRestriction_)
        | ({
              __typename: 'InsuranceValueRestriction';
          } & Restriction_InsuranceValueRestriction_)
        | ({
              __typename: 'InternalErrorRestriction';
          } & Restriction_InternalErrorRestriction_)
        | ({
              __typename: 'MarketDefectiveRestriction';
          } & Restriction_MarketDefectiveRestriction_)
        | ({
              __typename: 'MarketExistenceRestriction';
          } & Restriction_MarketExistenceRestriction_)
        | ({
              __typename: 'MarketStatusRestriction';
          } & Restriction_MarketStatusRestriction_)
        | ({ __typename: 'MaxBetRestriction' } & Restriction_MaxBetRestriction_)
        | ({ __typename: 'MinBetRestriction' } & Restriction_MinBetRestriction_)
        | ({
              __typename: 'NotEnoughBalanceRestriction';
          } & Restriction_NotEnoughBalanceRestriction_)
        | ({
              __typename: 'OddExistenceRestriction';
          } & Restriction_OddExistenceRestriction_)
        | ({
              __typename: 'OddStatusRestriction';
          } & Restriction_OddStatusRestriction_)
        | ({
              __typename: 'PlayerLimitRestriction';
          } & Restriction_PlayerLimitRestriction_)
        | ({
              __typename: 'SelectionValueRestriction';
          } & Restriction_SelectionValueRestriction_)
        | ({
              __typename: 'SelectionsCountRestriction';
          } & Restriction_SelectionsCountRestriction_)
        | ({
              __typename: 'SportEventBetStopRestriction';
          } & Restriction_SportEventBetStopRestriction_)
        | ({
              __typename: 'SportEventExistenceRestriction';
          } & Restriction_SportEventExistenceRestriction_)
        | ({
              __typename: 'SportEventStatusRestriction';
          } & Restriction_SportEventStatusRestriction_)
        | ({
              __typename: 'WLDefinedRestriction';
          } & Restriction_WlDefinedRestriction_)
    >;
};

export type OnUpdateBetVariables = Exact<{ [key: string]: never }>;

export type OnUpdateBet = {
    __typename?: 'Subscription';
    onUpdateBet: { __typename?: 'Bet' } & Bet;
};

export type OnUpdateSportEventVariables = Exact<{
    sportEventId: Scalars['String'];
    isTopMarkets?: InputMaybe<Scalars['Boolean']>;
    marketLimit?: InputMaybe<Scalars['Int']>;
    marketIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
    marketTypes?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
    marketStatuses?: InputMaybe<Array<MarketStatus> | MarketStatus>;
    version?: InputMaybe<Scalars['String']>;
    skipMarkets?: InputMaybe<Scalars['Boolean']>;
}>;

export type OnUpdateSportEvent = {
    __typename?: 'Subscription';
    onUpdateSportEvent: {
        __typename?: 'SportEvent';
        id: string;
        slug: string;
        disabled: boolean;
        version: string;
        betStop: boolean;
        markets?: Array<{ __typename?: 'Market' } & Market>;
        meta: Array<{ __typename?: 'Pair'; name: string; value: string }>;
        fixture: {
            __typename?: 'SportEventFixture';
            score: string;
            status: SportEventStatus;
            competitors: Array<{
                __typename?: 'SportEventCompetitor';
                id: string;
                score: Array<{
                    __typename?: 'CompetitorScore';
                    id: string;
                    type: string;
                    points: string;
                    number: number;
                }>;
            }>;
        };
        streams: Array<{
            __typename?: 'Stream';
            id: string;
            locale: string;
            url: string;
            priority: number;
            platforms: Array<{
                __typename?: 'StreamPlatform';
                type: string;
                allowedCountries: Array<string>;
                enabled: boolean;
            }>;
        }>;
    };
};

export type OnUpdateAmericanFootballOverviewsVariables = Exact<{
    sportEventIDs: Array<IdChecksum> | IdChecksum;
}>;

export type OnUpdateAmericanFootballOverviews = {
    __typename?: 'Subscription';
    onUpdateSportEventOverviews: {
        __typename?: 'UpdateSportEventOverviewsResponse';
        replace: Array<
            | ({ __typename?: 'SportEventOverview' } & AmericanFootballOverview)
            | null
        >;
    };
};

export type OnUpdateBasketballOverviewsVariables = Exact<{
    sportEventIDs: Array<IdChecksum> | IdChecksum;
}>;

export type OnUpdateBasketballOverviews = {
    __typename?: 'Subscription';
    onUpdateSportEventOverviews: {
        __typename?: 'UpdateSportEventOverviewsResponse';
        replace: Array<
            ({ __typename?: 'SportEventOverview' } & BasketballOverview) | null
        >;
    };
};

export type OnUpdateBrawlStarsOverviewsVariables = Exact<{
    sportEventIDs: Array<IdChecksum> | IdChecksum;
}>;

export type OnUpdateBrawlStarsOverviews = {
    __typename?: 'Subscription';
    onUpdateSportEventOverviews: {
        __typename?: 'UpdateSportEventOverviewsResponse';
        replace: Array<
            ({ __typename?: 'SportEventOverview' } & BrawlStarsOverview) | null
        >;
    };
};

export type OnUpdateCsgoOverviewsVariables = Exact<{
    sportEventIDs: Array<IdChecksum> | IdChecksum;
}>;

export type OnUpdateCsgoOverviews = {
    __typename?: 'Subscription';
    onUpdateSportEventOverviews: {
        __typename?: 'UpdateSportEventOverviewsResponse';
        replace: Array<
            ({ __typename?: 'SportEventOverview' } & CsgoOverview) | null
        >;
    };
};

export type OnUpdateCricketOverviewsVariables = Exact<{
    sportEventIDs: Array<IdChecksum> | IdChecksum;
}>;

export type OnUpdateCricketOverviews = {
    __typename?: 'Subscription';
    onUpdateSportEventOverviews: {
        __typename?: 'UpdateSportEventOverviewsResponse';
        replace: Array<
            ({ __typename?: 'SportEventOverview' } & CricketOverview) | null
        >;
    };
};

export type OnUpdateDota2OverviewsVariables = Exact<{
    sportEventIDs: Array<IdChecksum> | IdChecksum;
}>;

export type OnUpdateDota2Overviews = {
    __typename?: 'Subscription';
    onUpdateSportEventOverviews: {
        __typename?: 'UpdateSportEventOverviewsResponse';
        replace: Array<
            ({ __typename?: 'SportEventOverview' } & Dota2Overview) | null
        >;
    };
};

export type OnUpdateEBasketballOverviewsVariables = Exact<{
    sportEventIDs: Array<IdChecksum> | IdChecksum;
}>;

export type OnUpdateEBasketballOverviews = {
    __typename?: 'Subscription';
    onUpdateSportEventOverviews: {
        __typename?: 'UpdateSportEventOverviewsResponse';
        replace: Array<
            ({ __typename?: 'SportEventOverview' } & EBasketballOverview) | null
        >;
    };
};

export type OnUpdateEFootballOverviewsVariables = Exact<{
    sportEventIDs: Array<IdChecksum> | IdChecksum;
}>;

export type OnUpdateEFootballOverviews = {
    __typename?: 'Subscription';
    onUpdateSportEventOverviews: {
        __typename?: 'UpdateSportEventOverviewsResponse';
        replace: Array<
            ({ __typename?: 'SportEventOverview' } & EFootballOverview) | null
        >;
    };
};

export type OnUpdateEStreetballOverviewsVariables = Exact<{
    sportEventIDs: Array<IdChecksum> | IdChecksum;
}>;

export type OnUpdateEStreetballOverviews = {
    __typename?: 'Subscription';
    onUpdateSportEventOverviews: {
        __typename?: 'UpdateSportEventOverviewsResponse';
        replace: Array<
            ({ __typename?: 'SportEventOverview' } & EStreetballOverview) | null
        >;
    };
};

export type OnUpdateETennisOverviewsVariables = Exact<{
    sportEventIDs: Array<IdChecksum> | IdChecksum;
}>;

export type OnUpdateETennisOverviews = {
    __typename?: 'Subscription';
    onUpdateSportEventOverviews: {
        __typename?: 'UpdateSportEventOverviewsResponse';
        replace: Array<
            ({ __typename?: 'SportEventOverview' } & ETennisOverview) | null
        >;
    };
};

export type OnUpdateEVolleyballOverviewsVariables = Exact<{
    sportEventIDs: Array<IdChecksum> | IdChecksum;
}>;

export type OnUpdateEVolleyballOverviews = {
    __typename?: 'Subscription';
    onUpdateSportEventOverviews: {
        __typename?: 'UpdateSportEventOverviewsResponse';
        replace: Array<
            ({ __typename?: 'SportEventOverview' } & EVolleyballOverview) | null
        >;
    };
};

export type OnUpdateFootballOverviewsVariables = Exact<{
    sportEventIDs: Array<IdChecksum> | IdChecksum;
}>;

export type OnUpdateFootballOverviews = {
    __typename?: 'Subscription';
    onUpdateSportEventOverviews: {
        __typename?: 'UpdateSportEventOverviewsResponse';
        replace: Array<
            ({ __typename?: 'SportEventOverview' } & FootballOverview) | null
        >;
    };
};

export type OnUpdateFutsalOverviewsVariables = Exact<{
    sportEventIDs: Array<IdChecksum> | IdChecksum;
}>;

export type OnUpdateFutsalOverviews = {
    __typename?: 'Subscription';
    onUpdateSportEventOverviews: {
        __typename?: 'UpdateSportEventOverviewsResponse';
        replace: Array<
            ({ __typename?: 'SportEventOverview' } & FutsalOverview) | null
        >;
    };
};

export type OnUpdateHearthstoneOverviewsVariables = Exact<{
    sportEventIDs: Array<IdChecksum> | IdChecksum;
}>;

export type OnUpdateHearthstoneOverviews = {
    __typename?: 'Subscription';
    onUpdateSportEventOverviews: {
        __typename?: 'UpdateSportEventOverviewsResponse';
        replace: Array<
            ({ __typename?: 'SportEventOverview' } & HearthstoneOverview) | null
        >;
    };
};

export type OnUpdateIceHockeyOverviewsVariables = Exact<{
    sportEventIDs: Array<IdChecksum> | IdChecksum;
}>;

export type OnUpdateIceHockeyOverviews = {
    __typename?: 'Subscription';
    onUpdateSportEventOverviews: {
        __typename?: 'UpdateSportEventOverviewsResponse';
        replace: Array<
            ({ __typename?: 'SportEventOverview' } & IceHockeyOverview) | null
        >;
    };
};

export type OnUpdateIndoorSoccerOverviewsVariables = Exact<{
    sportEventIDs: Array<IdChecksum> | IdChecksum;
}>;

export type OnUpdateIndoorSoccerOverviews = {
    __typename?: 'Subscription';
    onUpdateSportEventOverviews: {
        __typename?: 'UpdateSportEventOverviewsResponse';
        replace: Array<
            | ({ __typename?: 'SportEventOverview' } & IndoorSoccerOverview)
            | null
        >;
    };
};

export type OnUpdateKingOfGloryOverviewsVariables = Exact<{
    sportEventIDs: Array<IdChecksum> | IdChecksum;
}>;

export type OnUpdateKingOfGloryOverviews = {
    __typename?: 'Subscription';
    onUpdateSportEventOverviews: {
        __typename?: 'UpdateSportEventOverviewsResponse';
        replace: Array<{ __typename?: 'SportEventOverview' } | null>;
    };
};

export type OnUpdateLolOverviewsVariables = Exact<{
    sportEventIDs: Array<IdChecksum> | IdChecksum;
}>;

export type OnUpdateLolOverviews = {
    __typename?: 'Subscription';
    onUpdateSportEventOverviews: {
        __typename?: 'UpdateSportEventOverviewsResponse';
        replace: Array<
            ({ __typename?: 'SportEventOverview' } & LolOverview) | null
        >;
    };
};

export type OnUpdateLolWildriftOverviewsVariables = Exact<{
    sportEventIDs: Array<IdChecksum> | IdChecksum;
}>;

export type OnUpdateLolWildriftOverviews = {
    __typename?: 'Subscription';
    onUpdateSportEventOverviews: {
        __typename?: 'UpdateSportEventOverviewsResponse';
        replace: Array<
            ({ __typename?: 'SportEventOverview' } & LolWildriftOverview) | null
        >;
    };
};

export type OnUpdateMmaOverviewsVariables = Exact<{
    sportEventIDs: Array<IdChecksum> | IdChecksum;
}>;

export type OnUpdateMmaOverviews = {
    __typename?: 'Subscription';
    onUpdateSportEventOverviews: {
        __typename?: 'UpdateSportEventOverviewsResponse';
        replace: Array<
            ({ __typename?: 'SportEventOverview' } & MmaOverview) | null
        >;
    };
};

export type OnUpdateMortalKombatOverviewsVariables = Exact<{
    sportEventIDs: Array<IdChecksum> | IdChecksum;
}>;

export type OnUpdateMortalKombatOverviews = {
    __typename?: 'Subscription';
    onUpdateSportEventOverviews: {
        __typename?: 'UpdateSportEventOverviewsResponse';
        replace: Array<
            | ({ __typename?: 'SportEventOverview' } & MortalKombatOverview)
            | null
        >;
    };
};

export type OnUpdateOverwatchOverviewsVariables = Exact<{
    sportEventIDs: Array<IdChecksum> | IdChecksum;
}>;

export type OnUpdateOverwatchOverviews = {
    __typename?: 'Subscription';
    onUpdateSportEventOverviews: {
        __typename?: 'UpdateSportEventOverviewsResponse';
        replace: Array<
            ({ __typename?: 'SportEventOverview' } & OverwatchOverview) | null
        >;
    };
};

export type OnUpdateStarCraft1OverviewsVariables = Exact<{
    sportEventIDs: Array<IdChecksum> | IdChecksum;
}>;

export type OnUpdateStarCraft1Overviews = {
    __typename?: 'Subscription';
    onUpdateSportEventOverviews: {
        __typename?: 'UpdateSportEventOverviewsResponse';
        replace: Array<
            ({ __typename?: 'SportEventOverview' } & StarCraft1Overview) | null
        >;
    };
};

export type OnUpdateStarCraftOverviewsVariables = Exact<{
    sportEventIDs: Array<IdChecksum> | IdChecksum;
}>;

export type OnUpdateStarCraftOverviews = {
    __typename?: 'Subscription';
    onUpdateSportEventOverviews: {
        __typename?: 'UpdateSportEventOverviewsResponse';
        replace: Array<
            ({ __typename?: 'SportEventOverview' } & StarCraftOverview) | null
        >;
    };
};

export type OnUpdateTableTennisOverviewsVariables = Exact<{
    sportEventIDs: Array<IdChecksum> | IdChecksum;
}>;

export type OnUpdateTableTennisOverviews = {
    __typename?: 'Subscription';
    onUpdateSportEventOverviews: {
        __typename?: 'UpdateSportEventOverviewsResponse';
        replace: Array<
            ({ __typename?: 'SportEventOverview' } & TableTennisOverview) | null
        >;
    };
};

export type OnUpdateTennisOverviewsVariables = Exact<{
    sportEventIDs: Array<IdChecksum> | IdChecksum;
}>;

export type OnUpdateTennisOverviews = {
    __typename?: 'Subscription';
    onUpdateSportEventOverviews: {
        __typename?: 'UpdateSportEventOverviewsResponse';
        replace: Array<
            ({ __typename?: 'SportEventOverview' } & TennisOverview) | null
        >;
    };
};

export type OnUpdateVolleyballOverviewsVariables = Exact<{
    sportEventIDs: Array<IdChecksum> | IdChecksum;
}>;

export type OnUpdateVolleyballOverviews = {
    __typename?: 'Subscription';
    onUpdateSportEventOverviews: {
        __typename?: 'UpdateSportEventOverviewsResponse';
        replace: Array<
            ({ __typename?: 'SportEventOverview' } & VolleyballOverview) | null
        >;
    };
};

export type GetOddsStatisticVariables = Exact<{
    sportEventIds: Array<Scalars['String']> | Scalars['String'];
}>;

export type GetOddsStatistic = {
    __typename?: 'Query';
    betsBiddingCalculation: Array<{
        __typename?: 'BetsSportEventOddPercentage';
        id: string;
        markets: Array<{
            __typename?: 'BetsMarketOddPercentage';
            id: string;
            odds: Array<{
                __typename?: 'BetsOddPercentage';
                id: string;
                percent: number;
            }>;
        }>;
    }>;
};

export type GetSportsVariables = Exact<{
    sportIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetSports = {
    __typename?: 'Query';
    sports: Array<{
        __typename?: 'Sport';
        id: string;
        name: string;
        slug: string;
        tags: Array<SportTag>;
    }>;
};
