import { FC, memo, useMemo } from 'react';
import cn from 'classnames';

import { withClientRender } from 'hocs';
import { getTransformedDataTestId } from 'utils';
import { bonusBubbleClasses } from './styles';
import { BonusBubbleCategory, BonusBubbleType } from './types';
import useBonusBubble from './useBonusBubble';

interface Props {
    type: BonusBubbleType;
    category: BonusBubbleCategory;
    isCountBlock?: boolean;
    showCount?: boolean;
    dataTest?: string;
}

const BonusBubble: FC<Props> = ({
    type,
    category,
    isCountBlock = false,
    showCount = true,
    dataTest,
}) => {
    const { isAuthorized, count } = useBonusBubble({ category });

    const bonusBubbleAttr = useMemo(
        () =>
            getTransformedDataTestId({
                defaultAttribute: 'bonus-bubble',
                propsAttribute: dataTest,
            }),
        [dataTest]
    );

    if (count <= 0 || !isAuthorized) return null;

    return (
        <div
            className={cn(type ? bonusBubbleClasses[type] : 'bg-red-500')}
            data-test={bonusBubbleAttr}
        >
            {showCount && <span>{count}</span>}
            {isCountBlock && <span>&nbsp;({count})</span>}
        </div>
    );
};

export default memo(withClientRender(BonusBubble));
