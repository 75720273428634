export const LEADER_PLACE_THRESHOLD = 5;
export const MAX_REWARDS_NUMBER = 5;

export enum EuroRulesWidgetFields {
    Title = 'title',
    Rewards = 'rewards',
    Reward = 'reward_',
    RewardCurrency = 'reward_currency_',
    TeamInfoPlaceCurrency = 'team_info_place_currency_',
    StageCurrency = 'stage_currency_',
    StageReward = 'stage_reward_',
    HowToPlay = 'how_to_play',
    BonusDescription = 'bonus_description',
    RulesAndConditions = 'rules_and_conditions',
}

export enum EuroTeamAnimationBannerWidgetFields {
    Animation1024 = 'animation_1024',
    Animation760 = 'animation_760',
    Animation360 = 'animation_360',
}

export const flagEmojiMap: Record<string, string> = {
    'GB-ENG': '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
    'GB-SCT': '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
};

export enum OnBoardingSteps {
    InitialValue,
    Step_1,
    Step_2,
    Step_3,
    Step_4,
}

export const EuroCampaignId = 'euro_2024';

export enum EuroState {
    Initial = 'initial',
    ChooseTeam = 'choose_team',
    Leaderboard = 'leaderboard',
}
