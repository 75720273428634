import { FC, memo, PropsWithChildren, useMemo } from 'react';

import { LayoutType, MainLayoutType } from 'app-constants';
import { getDynamicPagePathWithoutMatchListParams } from 'components/DynamicPage/utils';
import { useGetDynamicRouteByPath } from 'gql/cms/queries/getDynamicRouteByPath.cms';
import { useLocales, useRouterData } from 'hooks';
import { LayoutTypeContext } from './LayoutTypeContext';
import { getLayoutTypeByPageEntityInfoTypename } from './utils';

const { Betting, BettingStatic, Casino, Game, CasinoStatic, BettingSeo } =
    LayoutType;

interface Props extends PropsWithChildren {
    layoutType: LayoutType;
}

const LayoutTypeContextProvider: FC<Props> = ({ children, layoutType }) => {
    const { activeLocale } = useLocales();
    const { pathname } = useRouterData();
    const formattedPath = getDynamicPagePathWithoutMatchListParams(pathname);

    const { data } = useGetDynamicRouteByPath(() => ({
        variables: { path: `/${activeLocale}${formattedPath}` },
        pagePropsKey: 'dynamicRouteByPath',
    }));

    const routeLayoutType =
        getLayoutTypeByPageEntityInfoTypename({
            data: data?.dynamicRouteByPath,
        }) || layoutType;

    const value = useMemo(
        () => ({
            layoutType: routeLayoutType,
            mainLayoutType: getMainLayoutType(layoutType),
        }),
        [layoutType, routeLayoutType]
    );

    return (
        <LayoutTypeContext.Provider value={value}>
            {children}
        </LayoutTypeContext.Provider>
    );
};

function getMainLayoutType(type: LayoutType): MainLayoutType {
    switch (type) {
        case Betting:
        case BettingStatic:
        case BettingSeo:
            return MainLayoutType.Betting;
        case Game:
        case Casino:
        case CasinoStatic:
            return MainLayoutType.Casino;
        default:
            return MainLayoutType.Betting;
    }
}

export default memo(LayoutTypeContextProvider);
