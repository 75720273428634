import { useCallback } from 'react';

import { useAuthorization } from 'hooks';
import { OddAcceptStrategy } from 'types/gql.bet';
import { useChangeGamblerSetting } from './changeGamblerSetting.bet';
import { GamblerSetting } from './constants';
import {
    favoriteMarketsIdVar,
    gamblerSettingsVar,
    oddAcceptStrategyVar,
    updateClientDeeplinkMatchData,
    updateClientGamblerSettings,
    updateClientOddAcceptStrategy,
} from './gamblerState';
import {
    AllSettings,
    ChangeSettingAction,
    DeeplinkMatchData,
    FavoriteMarkets,
    GamblerSettingsVar,
} from './types';
import { convertFavoriteMarketsToGamblerSettings } from './utils';

interface Output {
    changeSettingAction: ChangeSettingAction;
}

const useChangeSettingAction = (): Output => {
    const { isAuthorized } = useAuthorization();

    const [changeGamblerSetting] = useChangeGamblerSetting();

    const changeSetting = useCallback(
        (key: keyof AllSettings, value: string) => {
            if (isAuthorized) {
                changeGamblerSetting({
                    variables: {
                        key,
                        value,
                    },
                });
            }
        },
        [changeGamblerSetting, isAuthorized]
    );

    const changeSettingAction = useCallback(
        <T extends keyof AllSettings>(key: T, value: AllSettings[T]) => {
            switch (key) {
                case GamblerSetting.ODD_FORMAT:
                case GamblerSetting.ODD_STATISTIC_VIEW:
                case GamblerSetting.SHOW_ODDS_STATISTIC:
                case GamblerSetting.SHOW_ODD_HISTORY:
                    if (
                        gamblerSettingsVar()[
                            key as keyof GamblerSettingsVar
                        ] === value
                    ) {
                        return;
                    }

                    changeSetting(key, String(value));
                    updateClientGamblerSettings({ [key]: value });

                    return;
                case GamblerSetting.ODD_ACCEPT_STRATEGY:
                    if (oddAcceptStrategyVar() === value) {
                        return;
                    }

                    changeSetting(
                        GamblerSetting.ODD_ACCEPT_STRATEGY,
                        value as OddAcceptStrategy
                    );
                    updateClientOddAcceptStrategy(value as OddAcceptStrategy);

                    return;
                case GamblerSetting.DEEPLINK_MATCH_DATA:
                    changeSetting(
                        GamblerSetting.DEEPLINK_MATCH_DATA,
                        JSON.stringify(value)
                    );
                    updateClientDeeplinkMatchData(value as DeeplinkMatchData);

                    return;
                case GamblerSetting.FAVORITE_MARKETS_ID:
                    changeSetting(
                        GamblerSetting.FAVORITE_MARKETS_ID,
                        convertFavoriteMarketsToGamblerSettings(
                            value as FavoriteMarkets[]
                        )
                    );
                    favoriteMarketsIdVar(value as FavoriteMarkets[]);

                    return;
                default:
                    throw new Error('Gambler settings invalid key');
            }
        },
        [changeSetting]
    );

    return {
        changeSettingAction,
    };
};

export default useChangeSettingAction;
