import React from 'react';
import cn from 'classnames';

import { useGsspData } from 'layouts/Root/PagePropsContext';
import { getFilteredGamesCategories } from './FooterCategoriesLinks/utils';
import FooterCategoriesLinks, {
    getFooterGamesLinksSideProps,
} from './FooterCategoriesLinks';
import FooterProvidersLinks, {
    getFooterProvidersLinksSideProps,
} from './FooterProvidersLinks';

const FooterGamesList: React.FC = () => {
    const { gamesCategories } = useGsspData();

    const filteredGamesCategories = getFilteredGamesCategories(
        gamesCategories?.gamesCategories
    );

    const container = cn(
        'grid w-full grid-cols-1 border-b-default border-solid border-surface-middle pb-8 md:grid-cols-2'
    );

    return (
        <div className={container}>
            <FooterCategoriesLinks categories={filteredGamesCategories} />
            <FooterProvidersLinks />
        </div>
    );
};

export const getFooterGamesListSideProps = [
    getFooterGamesLinksSideProps,
    getFooterProvidersLinksSideProps,
];

export default FooterGamesList;
