import { RefObject, useEffect, useState } from 'react';
import { throttle } from 'lodash';

interface Input {
    ref: RefObject<HTMLElement>;
}

interface Output {
    width: number;
    height: number;
}
const useElementResize = ({ ref }: Input): Output => {
    const [size, setSize] = useState({ width: 0, height: 0 });

    useEffect(() => {
        const element = ref.current;

        if (!element) return;

        const handleResize = throttle((entries: ResizeObserverEntry[]) => {
            const { width, height } = entries[0].contentRect;
            setSize({ width, height });
        }, 400);

        const observer = new ResizeObserver(handleResize);

        observer.observe(element);

        return () => {
            observer.unobserve(element);
        };
    }, [ref]);

    return {
        ...size,
    };
};

export default useElementResize;
