import { LayoutType } from 'app-constants';
import { GetDynamicRouteByPath } from 'types/gql.cms';

interface GetLayoutTypeByPageEntityInfoTypenameInput {
    data: GetDynamicRouteByPath['dynamicRouteByPath'] | undefined | null;
}
export const getLayoutTypeByPageEntityInfoTypename = ({
    data,
}: GetLayoutTypeByPageEntityInfoTypenameInput): LayoutType | null => {
    switch (data?.entity?.info.__typename) {
        case 'PageLocalizedRoutingRelatedEntity': {
            return (
                getLayoutType(data?.entity.info.entity.layoutType) ||
                LayoutType.BettingStatic
            );
        }

        default:
            return null;
    }
};

function getLayoutType(pageLayout: string): LayoutType {
    switch (pageLayout) {
        case 'casino':
            return LayoutType.CasinoStatic;
        default:
            return pageLayout as LayoutType;
    }
}
