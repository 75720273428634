import { FC, useCallback } from 'react';
import { useTranslations } from 'next-intl';

import { ModalsHash } from 'app-constants';
import Button from 'components/Button';
import useModal from 'components/modals/useModal';
import { Action, Category } from 'services/GoogleAnalytics';

const HeaderGuestButtons: FC = () => {
    const t = useTranslations('HeaderButtons');
    const { openModal } = useModal();

    const openLogin = useCallback(() => {
        openModal({ hash: ModalsHash.Authorization });
    }, [openModal]);

    const openRegistration = useCallback(() => {
        openModal({ hash: ModalsHash.Registration });
    }, [openModal]);

    return (
        <>
            <div className="mr-2 whitespace-nowrap">
                <Button
                    color={Button.Color.SurfaceDark}
                    size={Button.Size.Small}
                    onClick={openLogin}
                    dataCategory={Category.HeaderLine}
                    dataAction={Action.OpenSignIn}
                    dataTest="header-login"
                >
                    {t('gincms.header_login').toUpperCase()}
                </Button>
            </div>

            <Button
                color={Button.Color.Orange}
                size={Button.Size.Small}
                onClick={openRegistration}
                dataCategory={Category.HeaderLine}
                dataAction={Action.OpenSignUp}
                dataTest="header-register"
            >
                {t('gincms.header_registration').toUpperCase()}
            </Button>
        </>
    );
};

export default HeaderGuestButtons;
