import { FC } from 'react';
import cn from 'classnames';
import { useTranslations } from 'next-intl';

import { WidgetNames } from 'app-constants';
import Widget from 'components/Widget';
import { useGsspData } from 'layouts/Root/PagePropsContext';

import styles from './footerDownloadApp.module.css';

const FooterDownloadApp: FC = () => {
    const t = useTranslations('Footer');
    const { widgets } = useGsspData();

    return (
        <div className="mt-8">
            <div className="mt-4 flex flex-col sm:mt-0 sm:justify-between">
                <div className="mb-6 text-md font-bold text-primary-orange-toxic">
                    {t('gincms.footer.download_app')}:
                </div>
                <Widget
                    name={WidgetNames.FOOTER_APP_LINKS}
                    className={cn(styles.app)}
                    widgetData={
                        widgets?.[WidgetNames.FOOTER_APP_LINKS]?.widgetByName
                            ?.template
                    }
                />
            </div>
        </div>
    );
};

export default FooterDownloadApp;
