import { makeVar } from '@apollo/client';

import { BetType, PlaceBetInsurance } from 'types/gql.bet';
import { QueryFieldPolicy } from 'types/typePolicies.bet';
import { BetslipStatus } from './constants';
import { BetErrors, ValidationErrors } from './types';

export const betslipQueryStateTypePolicy: QueryFieldPolicy = {
    betslip: {
        read() {
            return betslipVars();
        },
    },
};

export const activeBetslipFreebetIdVar = makeVar<string | undefined>(undefined);

export const activeBetslipInsuranceVar = makeVar<PlaceBetInsurance | null>(
    null
);

export const initialState = {
    oddIds: [],
    mode: BetType.Single,
    stake: '',
    betId: '',
    status: BetslipStatus.Initial,
    betErrors: null,
    validationErrors: {
        minStakeError: null,
        maxStakeError: null,
        restrictionError: null,
    },
    systemSize: 1,
    minBet: 0,
    inactiveOdds: [],
};

export const betslipVars = makeVar<{
    oddIds: string[];
    mode: BetType;
    stake: string;
    betId: string;
    status: BetslipStatus;
    betErrors: BetErrors | null;
    validationErrors: ValidationErrors;
    inactiveOdds: string[];
    systemSize: number;
    minBet: number;
}>(initialState);
