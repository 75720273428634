import { getCookie } from 'cookies-next';

import { CookiesType } from 'app-constants';
import { getServerPageGetOwnFeatureFlags } from 'gql/cms/queries/getOwnFeatureFlags.cms';
import { getServerPageGetParticipantFeatures } from 'gql/cms/queries/getPartisipantFeatures.cms';
import { GetOwnFeatureFlags } from 'types/gql.cms';
import getSettledResult from 'utils/getSettledResult';
import { WithApolloClientsContext } from '../apollo';
import type { FeatureFlags, ParticipantFeature } from './types';

interface GetParticipantFeaturesInput {
    context: WithApolloClientsContext;
}

export async function getParticipantFeatures({
    context,
}: GetParticipantFeaturesInput): Promise<ParticipantFeature[]> {
    const token = getCookie(CookiesType.Paseto, {
        req: context.req,
    });

    try {
        const responce = await getServerPageGetParticipantFeatures({
            context: { token },
        })(context);

        return responce?.featureFlags;
    } catch (e) {
        console.error(e);

        return [];
    }
}

interface SettleFeatureFlagsInput {
    context: WithApolloClientsContext;
    headers?: Record<string, string | undefined>;
}

interface SettleFeatureFlagsOutput {
    parsedFeatures?: FeatureFlags;
    notSupportedFeatures?: string[];
}

export async function settleFeatureFlags({
    context,
    headers,
}: SettleFeatureFlagsInput): Promise<SettleFeatureFlagsOutput> {
    const { req } = context;
    const isDebug = !!getCookie(CookiesType.DebugInfo, { req });

    const executedGetOwnFeatureFlags = getServerPageGetOwnFeatureFlags({
        context: {
            important: true,
            headers,
        },
    })(context);

    // const executedGetParticipantFeatures = getParticipantFeatures({
    //     context,
    // });

    const [ownFeatureFlags, participantFeatures] = await getSettledResult<
        [GetOwnFeatureFlags, ParticipantFeature[]]
    >({
        promises: [executedGetOwnFeatureFlags],
        label: 'settleFeatureFlags',
        errorMessage: 'settleFeatureFlags request failed',
        request: req,
    });

    const featuresForClient = context?.featureFlagManager
        ?.parseAndSetFeatures(
            {
                features: ownFeatureFlags?.ownFeatureFlags || [],
                participantFeatures: participantFeatures || [],
            },
            { isDebug, req }
        )
        .getAll();

    return {
        ...(featuresForClient && { parsedFeatures: featuresForClient }),
        notSupportedFeatures: Array.from(
            context?.featureFlagManager?.getNotSupportedFeatures() || []
        ),
    };
}
