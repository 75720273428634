import { FC, memo, useMemo } from 'react';

import Button from 'components/Button';
import Image from 'components/Image';
import ModalBaseLayout from 'components/modals/ModalBaseLayout';
import { Message } from 'types/gql.cms';
import useInboxCenterNotify from './useInboxCenterNotify';

interface Props {
    message: Message;
}

const InboxCenterNotify: FC<Props> = ({ message }) => {
    const { closeMessageHandler, imgSrc, buttonsData } = useInboxCenterNotify({
        message,
    });

    const image = useMemo(
        () => (
            <div className="relative pt-[100%]">
                <Image
                    src={imgSrc}
                    fill={true}
                    style={{ objectFit: 'cover' }}
                    alt="Notify image"
                />
            </div>
        ),
        [imgSrc]
    );

    const buttons = useMemo(() => {
        return buttonsData.slice(0, 2).map((button, index) => {
            const isWhite = index === 0 && Boolean(buttonsData[1]);

            return (
                <Button
                    href={button.url}
                    key={button.text}
                    width={Button.Width.Full}
                    color={isWhite ? Button.Color.White : Button.Color.Orange}
                    isOutlined={isWhite}
                    className="uppercase"
                >
                    {button.text}
                </Button>
            );
        });
    }, [buttonsData]);

    return (
        <ModalBaseLayout
            onClose={closeMessageHandler}
            image={image}
            buttons={buttons}
        >
            {message.title && (
                <div className="mb-4 typo-lg-2">{message.title}</div>
            )}

            {message.content && (
                <div className="typo-md-1">{message.content}</div>
            )}
        </ModalBaseLayout>
    );
};

export default memo(InboxCenterNotify);
