interface ErrorData {
    label?: string;
    message: string;
    runtime: 'node' | 'client';
}
class CustomSentryError extends Error {
    private _label?: string;

    constructor({ message, label, runtime }: ErrorData) {
        super(message);
        this._label = label;

        this.name = `${this._label || ''}(${runtime})`;

        Object.setPrototypeOf(this, new.target.prototype);
    }
}

export default CustomSentryError;
