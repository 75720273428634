var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"CIO8N8pXlKEwbEJ1ZpvgO"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/app-static/2_17_2";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { getCurrentHub, init as SentryInit } from '@sentry/nextjs';
import type { PageProps } from 'types';

import { Feature } from 'services/features';

const pageProps: PageProps | undefined =
    // eslint-disable-next-line
    window?.__NEXT_DATA__?.props?.pageProps;

if (pageProps && pageProps.parsedFeatures?.[Feature.AppEnableSentry]) {
    const { sentryConfig } = pageProps;

    const defaultSampleRate =
        pageProps.env.SENTRY_ENVIRONMENT === 'production' ? 1.0 : 0.1;

    const validateSampleRate = (sampleRate?: any): number => {
        if (!sampleRate) return defaultSampleRate;

        const convertedSampleRate = Number(sampleRate);

        if (isNaN(convertedSampleRate)) return defaultSampleRate;

        if (convertedSampleRate > 1 || convertedSampleRate < 0)
            return defaultSampleRate;

        return convertedSampleRate;
    };

    const replaysSessionSampleRate = validateSampleRate(
        sentryConfig?.replaysSessionSampleRate
    );

    SentryInit({
        dsn: 'https://0dc46d82e14054238274aa6fed40badb@o4506031042461696.ingest.sentry.io/4506036948238336',

        // Adjust this value in production, or use tracesSampler for greater control
        sampleRate: validateSampleRate(sentryConfig?.sampleRate),
        // Setting this option to true will print useful information to the console while you're setting up Sentry.
        debug: true,
        replaysOnErrorSampleRate: validateSampleRate(
            sentryConfig?.replaysOnErrorSampleRate
        ),
        // This sets the sample rate to be 10%. You may want this to be 100% while
        // in development and sample at a lower rate in production
        replaysSessionSampleRate,

        // You can remove this option if you're not planning to use the Sentry Session Replay feature:
        integrations: [],
        environment: pageProps.env.SENTRY_ENVIRONMENT,
        release: pageProps.env.APP_VERSION,
    });

    if (replaysSessionSampleRate) {
        setTimeout(() => {
            import(/* webpackChunkName: "sentry-replay" */ '@sentry/replay')
                .then(({ replayIntegration }) => {
                    const client = getCurrentHub().getClient();

                    if (!client) return;

                    client.addIntegration?.(
                        replayIntegration({
                            // Additional Replay configuration goes in here, for example:
                            maskAllText: true,
                            blockAllMedia: true,
                        })
                    );
                })
                .catch((err) => console.error(err));
        }, 2000);
    }
}
