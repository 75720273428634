import { deleteCookie, getCookie, setCookie } from 'cookies-next';
import { OptionsType } from 'cookies-next/src/types';

import { removeLocaleFromUrl } from 'components/HybridRouter/utils';

interface IRedirectData {
    pathTo: string;
    nextRedirect?: string;
}
export const APP_MODAL_REDIRECT_KEY = 'modal_redirect';

export const setAppModalRedirect = (
    { pathTo, nextRedirect = '' }: IRedirectData,
    req?: OptionsType['req'],
    res?: OptionsType['res']
): void => {
    const pathWithoutLocale = removeLocaleFromUrl(pathTo);
    const redirect = encodeURIComponent(nextRedirect);
    const transformedPath = !redirect
        ? pathWithoutLocale
        : `${pathWithoutLocale}?r=${redirect}`;

    setCookie(APP_MODAL_REDIRECT_KEY, transformedPath, {
        req,
        res,
    });
};

export const getAppModalRedirect = (
    req?: OptionsType['req']
): undefined | string => {
    const cookieData = getCookie(APP_MODAL_REDIRECT_KEY, { req });

    if (!cookieData || typeof cookieData !== 'string') {
        return;
    }

    deleteCookie(APP_MODAL_REDIRECT_KEY);

    return cookieData;
};
