import { hasCookie } from 'cookies-next';
import omit from 'lodash/omit';
import { GetServerSidePropsResult } from 'next';

import { CookiesType, MAIN_LAYOUT_MESSAGES } from 'app-constants';
import handleDynamicPage, {
    HandleDynamicPageOutput,
} from 'components/DynamicPage/handleDynamicPage';
import getCurrentMirror from 'layouts/MainLayout/HeadSeoTags/gssp/getCurrentMirror';
import getSeoRules from 'layouts/MainLayout/HeadSeoTags/gssp/getSeoRules';
import { WithApolloClientsContext } from 'services/apollo/types';
import {
    GetServerSidePageData,
    GetServerSidePageProps,
    PageProps,
} from 'types';
import { getMessages, getSettledResult } from 'utils';
import { CmsClientOptions } from '../CmsApolloClient';
import setPropsTrackersFromCookie from './setPropsTrackersFromCookie';

interface Input {
    context: WithApolloClientsContext;
    cmsClientOptions: CmsClientOptions;
    crmNotificationHeader?: Record<string, string>;
}

interface Props extends Partial<PageProps> {
    gsspData: NonNullable<PageProps['gsspData']>;
}

async function handleWithoutSSR({
    context,
    cmsClientOptions,
    crmNotificationHeader,
}: Input): Promise<GetServerSidePropsResult<{ [key: string]: any }>> {
    const { req, params = {} } = context;

    const promises = [
        getMessages(context, [
            ...MAIN_LAYOUT_MESSAGES,
            'HeaderButtons',
            'HomePage',
            'GamesThemeCard',
            'GamesThemes',
            'betting-specifiers',
            'betting-global',
            'betting-countries',
            'betting-euro',
        ]),
        getSeoRules(context, crmNotificationHeader),
        getCurrentMirror(context, crmNotificationHeader),
    ];

    const pageProps: Props = {
        cmsClientOptions: omit(cmsClientOptions, ['req', 'endpoint']),
        isAuthorized: hasCookie(CookiesType.Paseto, { req }),
        gsspData: {},
    };

    setPropsTrackersFromCookie({
        pageProps,
        req,
    });

    const isCatchAllPage = 'pages' in params;

    if (isCatchAllPage) {
        promises.push(
            handleDynamicPage({
                withExcludedLocale: true,
                ctx: context,
            })
        );
    }

    const [
        messages,
        getSeoRulesResponse,
        getCurrentMirrorResponse,
        dynamicPageResponse,
    ] = await getSettledResult<
        [
            Partial<Messages>,
            GetServerSidePageProps,
            GetServerSidePageProps,
            HandleDynamicPageOutput | undefined,
        ]
    >({
        promises,
        label: 'handleWithoutSSR',
        errorMessage: 'handleWithoutSSR request failed',
        request: req,
    });

    pageProps.messages = messages || {};

    if (getSeoRulesResponse && 'redirect' in getSeoRulesResponse) {
        return getSeoRulesResponse;
    }

    assignPagePropsGsspData({
        response: getSeoRulesResponse,
        gsspKey: 'seoRulesByPage',
        pageProps,
    });

    assignPagePropsGsspData({
        response: getCurrentMirrorResponse,
        gsspKey: 'currentMirror',
        pageProps,
    });

    if (dynamicPageResponse) {
        const { data, type } = dynamicPageResponse;

        if (type === 'redirect') {
            return { ...data, props: pageProps };
        }
    }

    return {
        props: pageProps,
    };
}

interface InputAssignGsspData<T extends keyof GetServerSidePageData> {
    response: GetServerSidePageProps | null;
    gsspKey: T;
    pageProps: Props;
}

function assignPagePropsGsspData<T extends keyof GetServerSidePageData>({
    response,
    pageProps,
    gsspKey,
}: InputAssignGsspData<T>) {
    if (!response) return;

    if ('props' in response && !(response.props instanceof Promise)) {
        const data = response.props.gsspData?.[gsspKey];

        if (!data) return;

        // eslint-disable-next-line no-param-reassign
        pageProps.gsspData[gsspKey] = data;
    }
}

export default handleWithoutSSR;
