export const providers: { [key: string]: string } = {
    betsoft: 'Betsoft',
    netent: 'NetEnt',
    yggdrasil: 'Yggdrasil',
    playngo: 'Play`n Go',
    microgaming: 'Microgaming',
    tripleedgestudio: 'Triple Edge Studios',
    foxium: 'Foxium',
    wazdan: 'Wazdan',
    btg: 'BTG',
    isoftbet: 'iSoftBetF',
};

const getProviderIds = (providerObj: object): Array<string> => {
    return Object.keys(providerObj);
};

export const providerIds = getProviderIds(providers);
export enum FooterInfoLinks {
    TermAndConditions = 'terms-and-conditions',
    GamblingRules = 'gambling-rules',
    PublicOffer = 'public-offer',
    TermsAndConditionsBonuses = 'terms-and-conditions-bonuses',
    AboutUs = 'about-us',
    ResponsibleGaming = 'responsible-gaming',
    PrivacyPolicy = 'privacy-policy',
}

export const footerInfoLinks = [
    FooterInfoLinks.AboutUs,
    FooterInfoLinks.TermAndConditions,
    FooterInfoLinks.GamblingRules,
    FooterInfoLinks.PublicOffer,
    FooterInfoLinks.PrivacyPolicy,
    FooterInfoLinks.ResponsibleGaming,
    FooterInfoLinks.TermsAndConditionsBonuses,
];

export const footerInfoLinksPages: Record<string, string> = {
    'terms-and-conditions': 'page:rules',
    'public-offer': 'page:offer',
};
