import { FC, PropsWithChildren } from 'react';

import { AuthorizationContext } from './context';
import useAuthorizationManager from './useAuthorizationManager';

const AuthorizationManager: FC<PropsWithChildren<{}>> = ({ children }) => {
    const { authenticatedMemo } = useAuthorizationManager();

    return (
        <AuthorizationContext.Provider value={authenticatedMemo}>
            {children}
        </AuthorizationContext.Provider>
    );
};

export default AuthorizationManager;
