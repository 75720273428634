import { getPageUrl } from 'components/utilities/GetPageUrl';
import { getServerPageGetSeoRulesByPage } from 'layouts/MainLayout/getSeoRulesByPage.cms';
import { WithMainApolloClientContext } from 'services/apollo';
import { GetServerSidePageProps } from 'types';
import { getSeoRulesRedirectData, isSupportedStatusCode } from '../utils';

async function getSeoRules(
    context: WithMainApolloClientContext,
    headers?: Record<string, any>
): Promise<GetServerSidePageProps> {
    const seoRulesResponse = await getServerPageGetSeoRulesByPage(
        async (ctx) => {
            const { resolvedUrl, locale } = ctx;

            return {
                variables: {
                    pageUrl: getPageUrl(resolvedUrl, locale),
                    pageUrlWithoutLocale: resolvedUrl,
                },
                fetchPolicy: 'no-cache',
                context: {
                    headers,
                },
            };
        }
    )(context);

    if (seoRulesResponse) {
        const { seoRulesByPageWithoutLocale, seoRulesByPage } =
            seoRulesResponse;

        const redirectData = getSeoRulesRedirectData({
            seoRulesByPageWithoutLocale,
            seoRulesByPage,
        });

        if (redirectData) {
            const { url: destination, statusCode } = redirectData;

            return {
                redirect: {
                    destination,
                    statusCode: isSupportedStatusCode(statusCode)
                        ? statusCode
                        : 301,
                    priority: 1,
                },
            };
        }
    }

    return {
        props: {
            gsspData: { seoRulesByPage: seoRulesResponse },
        },
    };
}

export default getSeoRules;
